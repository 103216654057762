import { useContext } from 'react'

import { useRequest } from '@byted/hooks'

import api from '@/services'
import { ParamsContext } from '@/stores'

export const useBillInfo = () => {
  const {
    urlQuery: { token },
  } = useContext(ParamsContext)

  const {
    data: billInfoRes,
    loading: billInfoLoading,
    refresh: refreshBillInfo,
  } = useRequest(
    () =>
      api.postPayoutInfo({
        token,
      }),
    {
      auto: true,
    },
  )

  return { billInfo: billInfoRes?.data, billInfoLoading, refreshBillInfo }
}
