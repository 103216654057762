import { PaymentMethodItem } from '../../types'

const KLARNA_PAYNOW_PAYMENT_METHOD: PaymentMethodItem = {
  paymentMethodType: {
    configuration: {},
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/klarna_333753.png',
    },
    starling_key: 'pipo_payin_cashier_dropin_PImanagement_Klarna_title_paynnow', // TODO & UNUSED
    paymentMethodType: 'INTERNET_BANKING',
    displayName: 'Internet Banking',
    title: {
      payment: 'pipo_payin_cashier_dropin_payment_internetbanking',
    },
  },
  paymentMethod: {
    configuration: {
      png_icon_url: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/klarna_333753.png',
      dark_mode_png_icon_url: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/klarna-dark_333753.png',
    },
    paymentMethod: 'KLARNA_PAY_NOW',
    paymentMethodId: 'pm_pi_ib_klarnapaynow_c_r',
    displayName: 'Klarna',
    starling_key: 'pipo_payin_checkout_cashier_paymentmethodname_Klarna',
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/klarna_333753.png',
      pngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/klarna_333753.png',
      darkModePngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/klarna-dark_333753.png',
      detailIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/klarnaicon_4f18fb.png',
    },
  },
}

const KLARNA_PAYLATER_PAYMENT_METHOD: PaymentMethodItem = {
  paymentMethodType: {
    configuration: {},
    icon: {
      iconUrl: '',
    },
    starling_key: 'pipo_payin_cashier_dropin_PImanagement_Klarna_title_paylater', // TODO & UNUSED
    paymentMethodType: 'PAY_LATER',
  },
  paymentMethod: {
    configuration: {
      png_icon_url: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/klarna_333753.png',
      dark_mode_png_icon_url: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/klarna-dark_333753.png',
    },
    paymentMethod: 'KLARNA_PAY_LATER',
    paymentMethodId: 'pm_pi_pl_klarnapaylater_c_r',
    displayName: 'Klarna',
    starling_key: 'pipo_payin_checkout_cashier_paymentmethodname_Klarna_paylater',
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/klarna_333753.png',
      pngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/klarna_333753.png',
      darkModePngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/klarna-dark_333753.png',
      detailIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/klarnaicon_4f18fb.png',
    },
  },
}

const KLARNA_PAYOVERTIME_PAYMENT_METHOD: PaymentMethodItem = {
  paymentMethodType: {
    configuration: {},
    icon: {
      iconUrl: '',
    },
    starling_key: 'pipo_payin_cashier_dropin_PImanagement_Klarna_title_payovertime', // TODO & UNUSED
    paymentMethodType: 'INSTALLMENT',
  },
  paymentMethod: {
    configuration: {
      png_icon_url: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/klarna_333753.png',
      dark_mode_png_icon_url: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/klarna-dark_333753.png',
    },
    paymentMethod: 'KLARNA_PAY_OVER_TIME',
    paymentMethodId: 'pm_pi_ins_klarnapayovertime_c_r',
    displayName: 'Klarna',
    starling_key: 'pipo_payin_checkout_cashier_paymentmethodname_Klarna_payovertime',
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/klarna_333753.png',
      pngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/klarna_333753.png',
      darkModePngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/klarna-dark_333753.png',
      detailIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/klarnaicon_4f18fb.png',
    },
  },
}

const KLARNA_PAY_LATER_PAYMENT_METHOD: PaymentMethodItem = {
  paymentMethodType: {
    configuration: {},
    icon: {
      iconUrl: '',
    },
    starling_key: 'pipo_payin_cashier_dropin_PImanagement_Klarna_title_paylater', // TODO & UNUSED
    paymentMethodType: 'AGGREGATOR',
  },
  paymentMethod: {
    configuration: {
      png_icon_url: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/klarna_333753.png',
      dark_mode_png_icon_url: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/klarna-dark_333753.png',
    },
    paymentMethod: 'KLARNA',
    paymentMethodId: 'pm_pi_agg_klarna_c_r',
    displayName: 'Klarna',
    starling_key: 'pipo_payin_checkout_cashier_Klarna',
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/klarna_333753.png',
      pngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/klarna_333753.png',
      darkModePngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/klarna-dark_333753.png',
      detailIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/klarnaicon_4f18fb.png',
    },
  },
}

export const SUPPORT_KLARNA_PAYMENT_METHODS = [
  KLARNA_PAYNOW_PAYMENT_METHOD,
  KLARNA_PAYLATER_PAYMENT_METHOD,
  KLARNA_PAYOVERTIME_PAYMENT_METHOD,
  KLARNA_PAY_LATER_PAYMENT_METHOD,
]
