import React, { useEffect } from 'react'
import { FieldError } from 'react-hook-form'

import { useControlled } from '@byted/hooks'

import { CustomInput, ItemBlock } from '../index'

interface IProps {
  error?: FieldError
  value?: string
  title?: string
  len?: 'half' | 'full'
  maxLength?: number
  placeholder?: string
  errorMessage?: string
  onChange: (val: string) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void
}

export const NationalID = React.forwardRef((props: IProps, ref: any) => {
  const { title, placeholder, maxLength = 11, error, errorMessage, onBlur, len = 'half' } = props
  const [value, onChange] = useControlled<string>(props)

  useEffect(() => {
    onChange(value)
  }, [value])

  return (
    <ItemBlock style={len} title={title} className="pipo-form-item-national-title" errorState={Boolean(error)} errorMessage={errorMessage}>
      <CustomInput
        {...props}
        ref={ref}
        size="large"
        type="number"
        allowClear
        value={value}
        error={Boolean(error)}
        placeholder={placeholder}
        onChange={(val) => onChange(val)}
        onBlur={onBlur}
        maxLength={maxLength}
      />
    </ItemBlock>
  )
})
