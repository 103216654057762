import React, { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'

import { useRequest } from '@byted/hooks'

import { PAYMENT, PAY_WAY_TYPE } from '@/constant'
import { IPayinMethodItem, PaymentSubmitMethodEnum } from '@/pages/PayNext/interface'
import { DirectDebitEU } from '@/pipo/pc'
import { PipoComponentRef } from '@/pipo/utils'
import api from '@/services'
import { ParamsContext } from '@/stores'
import GlobalInfo from '@/stores/GlobalInfo'
import SafeJSON from '@/utils/SafeJSON'
import slardar from '@/utils/slardar'

import { IPaymentMethodsProps, IPaymentsMethodRef } from '../../interface'

const DirectDebitForm = forwardRef<IPaymentsMethodRef, IPaymentMethodsProps>((props, ref): React.ReactElement => {
  const { paymentSubmitMethod, changeNewPaymentStatus, channel } = props
  const {
    PIPOApi: { fetchDistrict },
    utils: { getContractAddressAsync },
  } = useContext(GlobalInfo)
  const { urlQuery } = useContext(ParamsContext)
  const [directDebitValid, setDirectDebitValid] = useState(false)
  const ddRef = useRef<PipoComponentRef>(null)

  // TODO: 暂时兼容两种逻辑，后续新UI全量后可梳理移除，由后端维护是否显示
  const showSavingBox = useMemo(() => {
    // 仅支付场景展示SaveBox
    if (paymentSubmitMethod !== PaymentSubmitMethodEnum.PAYMENT) return false

    // 若后端返回 以后端为准 老逻辑未返回排除后付费
    const supportFromBE = (channel as IPayinMethodItem).supportPaymentAndBind
    return typeof supportFromBE === 'boolean' ? supportFromBE : urlQuery.type !== PAYMENT.POST_PAY
  }, [channel, paymentSubmitMethod, urlQuery.type])

  const { data: contractAddressList } = useRequest(getContractAddressAsync, { auto: true })

  // 同步验证状态
  useEffect(() => {
    changeNewPaymentStatus(PAY_WAY_TYPE.DIRECT_DEBIT_EU, { valid: directDebitValid })
  }, [changeNewPaymentStatus, directDebitValid])

  useImperativeHandle(ref, () => ({
    // 一步支付走3M后端提交
    processSubmitData: async (submitParams) => {
      if (ddRef.current?.validateSubmitData) {
        const { paymentMethod, paymentParams, extraParams, storeCard, fillAddressState } = await ddRef.current?.validateSubmitData()

        fillAddressState &&
          slardar.timer('fillAddressSubmitEvent', fillAddressState.metrics, {
            from: 'EU DD',
            ...fillAddressState.categories,
          })

        const paymentMethodObj = {
          method_id: paymentMethod.paymentMethodId,
          payment_elements: paymentParams,
          mandate_info: extraParams?.mandateInfo,
          storeToken: storeCard,
        }
        submitParams.channelParameter = SafeJSON.stringify(paymentMethodObj)
        // 后付费不展示绑卡
        submitParams.bindAndPay = storeCard && urlQuery.type !== PAYMENT.POST_PAY
      }
      return submitParams
    },
  }))

  const onDirectDebitValid = useCallback((value: boolean) => {
    setDirectDebitValid(value)
  }, [])

  const fetchMandate = useCallback(
    async (params: any) => {
      try {
        const res = await api.getMandate({ ...params, token: urlQuery.token })
        if (res?.data) {
          const list = SafeJSON.parse(res.data) as any[]
          return list?.map((item) => {
            return {
              ...item,
              attribute_object: SafeJSON.parse(item.attribute_object),
            }
          })
        } else {
          throw new Error('Fetch mandate info error')
        }
      } catch {
        return []
      }
    },
    [urlQuery.token],
  )

  return (
    <DirectDebitEU
      ref={ddRef}
      showSavingBox={showSavingBox}
      onFetchDistrict={fetchDistrict}
      onFetchMandate={fetchMandate}
      onValidate={onDirectDebitValid}
      contractAddressList={contractAddressList}
    />
  )
})

export default DirectDebitForm
