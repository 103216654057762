import React from 'react'

import { Logger } from '@upay/utils'

import { IParams, IPubSub, IUrlQuery } from '@/typings'
import { logger } from '@/utils/slardar'

import { IParamsContext } from './interface'

/**
 * 全局透传参数
 */
export const ParamsContext = React.createContext<IParamsContext>({
  params: {} as IParams,
  urlQuery: {} as IUrlQuery,
  uPubSub: {} as IPubSub,
  unique: '',
  initStartTime: 0,
  initPageId: 'pay',
  pid: '',
})

export enum ChangeStatusEnum {
  /** 第一步，卡支付方式变更 */
  Init = '1',
  /** 第二步，账户支付方式变更 */
  Pause = '2',
}

export const globalInfoLogger = new Logger('SDK_GLOBAL_INFO', logger)
