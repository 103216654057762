import { IntegrationMethod, PaymentMethodItem } from './types'

/**
 * 该函数用于判断一个CCDC支付方式，是否属于通用的CCDC处理逻辑，如在收银台合并为一个支付选项、使用标准的卡信息填写表单。
 * 对于paymentMethodType为CCDC，且IntegrationMethod为redirect的支付方式，在收银台上需要单独展示支付选项。
 * 在其被选中并点击支付后，跳至第三方页面（例如填写卡信息）
 * @param paymentMethodType
 * @returns {boolean}
 */
export function isGeneralCCDC(paymentMethod: PaymentMethodItem) {
  return paymentMethod.paymentMethodType.paymentMethodType === 'CCDC' && paymentMethod.paymentMethod.integrationMethod !== IntegrationMethod.redirect
}
