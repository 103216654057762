import React from 'react'

import { CDN_DOMAIN } from '@/constant'
import { VoucherBoardData } from '@/pipo/hooks/use-ui-voucher'
import starling from '@/utils/starling'

import { IDetailInfoItem, PaymentDetailInfo } from '../payment-detail-info'

import './index.less'

const classPrefix = 'pipo-pc-voucher-us-banktransfer'
interface USBankTransferCodeProps {
  data: VoucherBoardData
  currency: string
}

const USBankTransferCode = (props: USBankTransferCodeProps) => {
  const { data } = props
  const { type, simple, shopperDetails } = data
  const infoList: IDetailInfoItem[] = [
    {
      title: starling('funds.refund.comm.system_bankaccount_routingcode_US'),
      content: shopperDetails?.routing_number,
    },
    {
      title: starling('funds.refund.comm.system_bankaccount_account_number'),
      content: shopperDetails?.payment_code,
    },
    {
      title: starling('funds.refund.comm.bank_name'),
      content: shopperDetails?.bank_name,
    },
    {
      title: 'Business Name',
      content: 'TikTok Inc',
    },
    {
      title: 'Street Address',
      content: '5800 Bristol Pkwy',
    },
    {
      title: 'City',
      content: 'Culver City',
    },
    {
      title: 'State',
      content: 'California (CA)',
    },
    {
      title: 'ZIP Code',
      content: '90230',
      notice: (
        <div className="code-notice">
          <p className="code-notice-title">{starling('cg.upay.exception.comm.fault_prompt_banner_title')}:</p>
          <div className="code-notice-list">
            <div>{starling('cg.upay.exception.channel_BankTransfer_US_instruction_3')}</div>
            <div>{starling('cg.upay.exception.channel_BankTransfer_US_instruction_1')}</div>
            <div>{starling('cg.upay.exception.channel_BankTransfer_US_instruction_2')}</div>
          </div>
        </div>
      ),
    },
  ]
  return (
    <div className={classPrefix}>
      <div className={`${classPrefix}-dashboard `}>
        <div className={`${classPrefix}-dashboard-left`}>
          <img className={`${classPrefix}-dashboard-logo`} src={`${CDN_DOMAIN}/obj/cg-fe-sg/upay/imgs/stripe_ach_bank_transfer_icon.png`} />
          <div className={`${classPrefix}-dashboard-name`}>{starling('ffuncg.upay.exception.channel_BankTransfer_US_instruction')}</div>
        </div>
        {type === 'simple' && (
          <div className={`${classPrefix}-dashboard-right`}>
            <div className={`${classPrefix}-dashboard-right-amount`}>
              <span>{starling('funds.refund.comm.total_amount')}</span>
            </div>
            <div className={`${classPrefix}-dashboard-right-no`}>
              <span>{simple.amount}</span>
            </div>
          </div>
        )}
      </div>
      <PaymentDetailInfo infoList={infoList} />
    </div>
  )
}

export { USBankTransferCode }
