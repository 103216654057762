import { useContext, useEffect } from 'react'

import { StatusActionTypeEnum } from '@upay/utils/es/types'

import { FROM_PAGE_ENUM, PUBSUB_TYPES } from '@/constant'
import useRecorder, { RECORDER_EVENT_HUB } from '@/hooks/recorder'
import { ParamsContext } from '@/stores'

const useBindRecorder = ({ from, visible }: { from: FROM_PAGE_ENUM; visible: boolean }) => {
  const { urlQuery, uPubSub } = useContext(ParamsContext)
  const { accountId } = urlQuery
  const { recorderStart, recorderStopAndSend } = useRecorder()
  useEffect(() => {
    // 从 manager 模块打开的 create 页面再次关闭后  需要重新手动开启录制
    visible && from === FROM_PAGE_ENUM.CREATE_IN_MANAGER && recorderStart(accountId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])
  useEffect(() => {
    if (from === FROM_PAGE_ENUM.CREATE_IN_PAY) return
    try {
      const subId = uPubSub.subscribe(PUBSUB_TYPES.MODAL_CANCEL, () => recorderStopAndSend(RECORDER_EVENT_HUB.USER_LEAVE))
      const subId1 = uPubSub.subscribe(PUBSUB_TYPES.STATUS_RESULT, (msg, data: Record<string, any>) => {
        let sendMsg = ''
        //只对展示完结果后用户主动点击或者倒计时三秒后进行处理
        if (!data?.clickBtn) return
        if (data?.actionType === StatusActionTypeEnum.BIND) {
          //只有在独立绑卡页面绑卡成功的时候上报录制
          sendMsg = data?.status ? RECORDER_EVENT_HUB.BIND_SUCCESS : data?.retry ? '' : RECORDER_EVENT_HUB.BIND_FAILL
        }

        sendMsg && recorderStopAndSend(sendMsg)
      })
      return () => {
        uPubSub.unsubscribe(subId)
        uPubSub.unsubscribe(subId1)
      }
    } catch {
      console.log('Recorder error!')
    }
  })
}
export default useBindRecorder
