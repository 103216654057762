import React, { FC, useMemo } from 'react'

import { Button, Modal } from '@arco-design/web-react'
import { StatusTypeEnum } from '@upay/utils/es/types'

import { HELP_CENTER_LINK } from '@/constant'
import { oneTimeHoc } from '@/utils/oneTimeHoc'
import starling from '@/utils/starling'

import { CONTENT_MAP, IMAGE_SRC_MAP, StatusT } from './config'

import './style.less'

const Status: FC<StatusT> = (props) => {
  const { status, actionType, errMsg, visible = true, primaryButtonInfo, cancelButtonInfo, showNeedHelp } = props
  const CONTENTS = useMemo(() => CONTENT_MAP(), [])

  return (
    <Modal className="upay-modal upay-modal-simple upay-status-modal" visible={visible} footer={null} closable={false}>
      <div className="upay-status">
        <img src={IMAGE_SRC_MAP.get(status as StatusTypeEnum)} className="upay-status-img" id={`upay-status-${status}`} />

        <div className="upay-status-content">{CONTENTS[`${actionType}-${status}`]?.statusTitle}</div>
        <div className="upay-status-error">{errMsg}</div>

        <div className="upay-status-btn-line">
          <div className="upay-status-btn-actions">
            {cancelButtonInfo && (
              <Button className="upay-status-btn" onClick={() => cancelButtonInfo.handleClick(false)} disabled={cancelButtonInfo.disabled}>
                {cancelButtonInfo.buttonText}
              </Button>
            )}
            <Button type="primary" className="upay-status-btn" onClick={primaryButtonInfo.handleClick} disabled={primaryButtonInfo.disabled}>
              {primaryButtonInfo.buttonText}
            </Button>
          </div>
          {showNeedHelp && (
            <a className="upay-status-help" href={HELP_CENTER_LINK} target="_blank" rel="noopener noreferrer">
              {starling('funds.refund.comm.system_need_help')}
            </a>
          )}
        </div>
      </div>
    </Modal>
  )
}
export default oneTimeHoc(Status)
