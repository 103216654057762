"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.getStatusScenes = getStatusScenes;
function getStatusScenes() {
  var _this = this;
  return {
    statusPageView: function statusPageView(params) {
      _this.info('statusPageView', params);
    },
    statusPageClose: function statusPageClose() {
      return _this.info('statusPageClose');
    },
    resultEvent: function resultEvent(params) {
      _this.info('resultEvent', params);
    },
    /** 状态页点击 */
    statusPageClick: function statusPageClick(_ref) {
      var clickItem = _ref.clickItem;
      _this.info("statusPageClick_".concat(clickItem));
    }
  };
}