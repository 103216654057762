import { StatusActionTypeEnum, StatusTypeEnum } from '@upay/utils/es/types'

import Fail from '@/static/svg/failed.svg'
import Paying from '@/static/svg/paying.svg'
import Success from '@/static/svg/successful.svg'
import starling from '@/utils/starling'

export const CONTENT_MAP: () => Record<
  string,
  {
    statusTitle: string
    trackPrefix: string
  }
> = () => ({
  'pay-success': {
    statusTitle: starling('funds.refund.comm.payment_successful'),
    trackPrefix: '支付',
  },
  'bind-success': {
    statusTitle: starling('funds.refund.comm.binding_card_successful'),
    trackPrefix: '绑卡',
  },
  'change-success': {
    statusTitle: starling('funds.refund.comm.change_results_successful'),
    trackPrefix: '变更支付方式',
  },
  'pay-succeed': {
    statusTitle: starling('funds.refund.comm.payment_successful'),
    trackPrefix: '支付',
  },
  'bind-succeed': {
    statusTitle: starling('funds.refund.comm.binding_card_successful'),
    trackPrefix: '绑卡',
  },
  'change-succeed': {
    statusTitle: starling('funds.refund.comm.change_results_successful'),
    trackPrefix: '变更支付方式',
  },
  'pay-failed': {
    statusTitle: starling('funds.refund.comm.payment_failed'),
    trackPrefix: '支付',
  },
  'bind-failed': {
    statusTitle: starling('funds.refund.comm.binding_card_failed'),
    trackPrefix: '绑卡',
  },
  'change-failed': {
    statusTitle: starling('funds.refund.comm.change_results_failed'),
    trackPrefix: '变更支付方式',
  },
  'pay-pending': {
    statusTitle: starling('funds.refund.comm.payment_failed'),
    trackPrefix: '支付',
  },
  'bind-pending': {
    statusTitle: starling('funds.refund.comm.binding_card_failed'),
    trackPrefix: '绑卡',
  },
  'change-pending': {
    statusTitle: starling('funds.refund.comm.change_results_failed'),
    trackPrefix: '变更支付方式',
  },
  'pay-fail': {
    statusTitle: starling('funds.refund.comm.payment_failed'),
    trackPrefix: '支付',
  },
  'bind-fail': {
    statusTitle: starling('funds.refund.comm.binding_card_failed'),
    trackPrefix: '绑卡',
  },
  'change-fail': {
    statusTitle: starling('funds.refund.comm.change_results_failed'),
    trackPrefix: '变更支付方式',
  },
  'change-wait': {
    statusTitle: starling('funds.refund.comm.change_results_unknown'),
    trackPrefix: '变更支付方式',
  },
  'pay-processing': {
    statusTitle: starling('funds.check_out.comm.status_payment_processing'),
    trackPrefix: '支付处理中（PaymentCode）',
  },
  'bind-processing': {
    statusTitle: starling('funds.check_out.comm.status_payment_processing'),
    trackPrefix: '绑卡处理中',
  },
  'payout-processing': {
    statusTitle: starling('funds.refund.comm.status_withdraw_processing'),
    trackPrefix: 'Payout请求中',
  },
  'payout-wait': {
    statusTitle: starling('funds.refund.comm.status_withdraw_processing'),
    trackPrefix: 'Payout请求中',
  },
  'payout-success': {
    statusTitle: starling('funds.refund.comm.payment_successful'),
    trackPrefix: 'Payout成功',
  },
  'payout-failed': {
    statusTitle: starling('funds.refund.comm.payment_failed'),
    trackPrefix: 'Payout失败',
  },
  'update-processing': {
    statusTitle: starling('cg.upay.comm.update_pending'),
    trackPrefix: 'Update请求中',
  },
  'update-wait': {
    statusTitle: starling('cg.upay.comm.update_pending'),
    trackPrefix: 'Update请求中',
  },
  'update-success': {
    statusTitle: starling('cg.upay.comm.update_success'),
    trackPrefix: 'Update成功',
  },
  'update-failed': {
    statusTitle: starling('cg.upay.comm.update_failed'),
    trackPrefix: 'Update失败',
  },
})

export const IMAGE_SRC_MAP = new Map([
  [StatusTypeEnum.SUCCESS, Success],
  [StatusTypeEnum.SUCCEED, Success],
  [StatusTypeEnum.FAILED, Fail],
  [StatusTypeEnum.FAIL, Fail],
  [StatusTypeEnum.PENDING, Fail],
  [StatusTypeEnum.TIMEOUT, Paying],
  [StatusTypeEnum.PROCESSING, Paying],
  [StatusTypeEnum.INIT, Paying],
])

export type StatusT = {
  /**
   * @description change: 变更支付方式 bind: 绑卡 pay: 支付
   * @type {('change' | 'bind' | 'pay' | 'update' | 'payout' | 'transfer')}
   */
  actionType: StatusActionTypeEnum
  /**
   * @description wait: 超时 failed: 失败 success,succeed: 成功 processing: 处理中
   * @type {('wait' | 'failed' | 'succeed' | 'success'| 'processing')}
   */
  status: StatusTypeEnum
  errMsg?: string
  visible?: boolean
  primaryButtonInfo: {
    buttonText: string
    handleClick: any
    disabled: boolean
  }
  cancelButtonInfo?: {
    buttonText: string
    handleClick: any
    disabled: boolean
  }
  showNeedHelp: boolean
}

export interface IUseStatus {
  statusProps: {
    actionType: '' | StatusActionTypeEnum
    status: '' | StatusTypeEnum
    errMsg: string
    visible: boolean
  }
}

export interface IOpenStatusProps {
  hideModal?: boolean
  //传了该参数表示不需要在结果页面轮询接口
  noPoll?: boolean
  actionType: StatusActionTypeEnum
  status: StatusTypeEnum
  errMsg?: string
  /**
   * 用于埋点时发送payWay
   */
  payWay?: number
  /**
   * 如传入pipoErrorCode将向后端请求转译报错信息
   * 异步操作建议处理好loading细节
   */
  pipoErrorCode?: string
  /**
   * 关闭状态页的回调
   */
  onClose?: (props: IStatusCloseCallbackProps) => void
  onCloseExtraPramas?: any
  recordNo?: string
  combination?: boolean
  handleBindSuccess?: () => void
}

/**
 * status关闭时传给回调的参数
 */
export interface IStatusCloseCallbackProps {
  actionType: StatusActionTypeEnum
  status: StatusTypeEnum
  errMsg?: string
  payWay?: number | null
  /**
   * openStatue时传入的onCloseExtraPramas参数
   * 可在回调中依赖
   */
  extraParams?: any
  retry?: boolean
  combination?: boolean
}
export enum PullStatusEnum {
  SUCCESS = 1,
  PENDING = 2,
  FAIL = 3,
}

export interface IResultExtraInfo {
  mandateUrl?: string
}
