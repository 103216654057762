import React, { forwardRef, useEffect, useImperativeHandle } from 'react'
import { Controller, ControllerFieldState, useForm } from 'react-hook-form'

import { Empty } from '@arco-design/web-react'
import { splitHolderName } from '@upay/utils/es/payout-utils'

import FormBuilder from '@/components/FormBuilder'
import { PAYOUT_WAY_TYPE } from '@/constant/payout'
import { useTouchedErrors } from '@/pipo/hooks'
import { AccountType } from '@/pipo/pc/inner-components'
import { PipoProvider, pickNumeric } from '@/pipo/utils'
import slardar from '@/utils/slardar'
import starling from '@/utils/starling'
import teaClient from '@/utils/tea'

import { PayoutComponentRef, PayoutSubmitParams } from '../../types'
import { getLocalBankTransferFormConfig } from './config/index'
import { LocalBankTransferFormData, LocalBankTransferFormProps } from './utils'

const clsPrefix = 'pipo-bindcard-pc'

export const LocalBankTransfer = forwardRef((props: LocalBankTransferFormProps, ref: React.ForwardedRef<PayoutComponentRef>) => {
  const { bankList, onChange, cardHolderInfo, refreshBankList } = props
  const form = useForm<LocalBankTransferFormData>({
    mode: 'all',
  })
  const {
    control,
    watch,
    getValues,
    setValue,
    formState: { errors: formErrors, isValid, touchedFields },
  } = form
  const errors = useTouchedErrors<LocalBankTransferFormData>({
    touchedFields,
    errors: formErrors,
  })
  const {
    state: { countryOrRegion = '' },
  } = PipoProvider.useContext()

  // 在表单状态和勾选绑卡时触发onChange
  useEffect(() => {
    onChange?.({
      isValid,
      storeCard: true,
    })
  }, [isValid, onChange])

  const accountType = watch('account_type') === '0' ? 'b' : 'c'
  const formConfig = getLocalBankTransferFormConfig({
    countryCode: countryOrRegion,
    bankList,
    cardHolderInfo,
  })

  // 银行列表联动刷新
  useEffect(() => {
    if (formConfig?.needInfo?.includes('bank_list')) {
      refreshBankList(accountType)
      setValue('bank_code', undefined)
    }
  }, [accountType])

  const getSubmitData = (): PayoutSubmitParams => {
    const formData = getValues()
    const isBusiness = accountType === 'b'
    const [firstName, lastName] = splitHolderName(formData.holder_name, countryOrRegion)

    if (!isBusiness && !lastName) {
      slardar.timer('personalAccountWithoutLastName', 1, { payWay: PAYOUT_WAY_TYPE.LOCAL_BANK_TRANSFER })
    }
    if (formData.phone_country_code && formData.phone) {
      formData.mobile_phone = `${formData.phone_country_code}-${formData.phone}`
    }

    const postFormData = {
      ...formData,
      account_no: formData.account_no ? pickNumeric(formData.account_no) : undefined,
      holder_first_name: firstName,
      holder_last_name: lastName || firstName, // name不包空格时lastName = firstName，过接口验证
    }

    return {
      formData: postFormData,
      userType: accountType,
    }
  }

  useImperativeHandle(ref, () => ({
    getSubmitData,
  }))

  const handleBlur = (scenario: string, fieldState: ControllerFieldState) => {
    const params = {
      pay_way: PAYOUT_WAY_TYPE.LOCAL_BANK_TRANSFER,
      scenario,
      is_valid: !fieldState.error,
      invalid_reason: fieldState.error?.type,
    }
    teaClient.sendPayoutPayPageFillIn(params)
  }

  if (!formConfig) {
    return (
      <div className="pipo-pc">
        <div className={`${clsPrefix}-flex-container`}>
          <Empty description="Sorry, the current scene is not supported for withdrawal" />
        </div>
      </div>
    )
  }

  const acceptAccountType = formConfig.allowAccountType || []
  const formSchema = accountType === 'c' && formConfig.toCFormSchema ? formConfig.toCFormSchema : formConfig.formSchema

  return (
    <div className="pipo-pc">
      <div className={`${clsPrefix}-flex-container`}>
        <div className={`${clsPrefix}-form-wrapper`}>
          <div className={`${clsPrefix}-subform-wrapper`}>
            <Controller
              rules={{ required: true }}
              name="account_type"
              defaultValue={'1'}
              control={control}
              render={({ field }) => (
                <AccountType {...field} title={starling('funds.refund.comm.system_bankaccounttype')} enableOption={acceptAccountType} />
              )}
            />

            <FormBuilder form={form} schema={formSchema} errors={errors} handleBlur={handleBlur} />
          </div>
        </div>
      </div>
    </div>
  )
})
