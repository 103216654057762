import React from 'react'

function TransferSuccess(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M53.2546 12.4269C53.5548 11.6173 54.4544 11.2043 55.2641 11.5044L88.1495 23.6966C88.9591 23.9968 89.3721 24.8965 89.0719 25.7061L76.8797 58.5915C76.5796 59.4011 75.6799 59.8141 74.8703 59.514L41.9849 47.3218C41.1752 47.0216 40.7622 46.1219 41.0624 45.3123L53.2546 12.4269Z"
        fill="#25F4EE"
      />
      <path
        d="M10.4047 29.5375C10.1812 28.7035 10.6761 27.8462 11.5102 27.6227L37.0115 20.7896C37.8456 20.5661 38.7029 21.0611 38.9264 21.8952L45.7594 47.3965C45.9829 48.2305 45.4879 49.0878 44.6539 49.3113L19.1526 56.1444C18.3185 56.3679 17.4612 55.8729 17.2377 55.0388L10.4047 29.5375Z"
        fill="#E5E5E5"
      />
      <path
        d="M29.6004 54.3308C29.7232 53.4761 30.5156 52.8828 31.3703 53.0056L59.9287 57.1087C60.7834 57.2315 61.3767 58.0239 61.2539 58.8786L57.1508 87.4369C57.028 88.2916 56.2356 88.885 55.3809 88.7622L26.8225 84.659C25.9678 84.5362 25.3745 83.7438 25.4973 82.8891L29.6004 54.3308Z"
        fill="#FE5274"
      />
      <path
        d="M61.9558 64.2899C61.7734 63.4459 62.3098 62.6139 63.1538 62.4315L80.9454 58.5876C81.7894 58.4053 82.6214 58.9416 82.8038 59.7856L86.6477 77.5772C86.8301 78.4212 86.2937 79.2533 85.4497 79.4356L67.6581 83.2796C66.8141 83.4619 65.9821 82.9255 65.7997 82.0815L61.9558 64.2899Z"
        fill="#E5E5E5"
      />
      <path
        d="M52.1104 16.2051L50.3547 15.6668C50.2906 15.654 50.2522 15.6028 50.2266 15.5387L49.6883 13.783C49.6371 13.6036 49.3807 13.6036 49.3295 13.783L48.7912 15.5387C48.7784 15.6028 48.7272 15.6412 48.6631 15.6668L46.9074 16.2051C46.728 16.2564 46.728 16.5127 46.9074 16.5639L48.6631 17.1022C48.7272 17.115 48.7656 17.1662 48.7912 17.2303L49.3295 18.986C49.3807 19.1654 49.6371 19.1654 49.6883 18.986L50.2266 17.2303C50.2394 17.1662 50.2906 17.1278 50.3547 17.1022L52.1104 16.5639C52.2898 16.5127 52.2898 16.2564 52.1104 16.2051Z"
        fill="#386CF4"
      />
      <path
        d="M78.5452 69.0244L74.9084 67.9095C74.7756 67.883 74.696 67.7768 74.6429 67.644L73.528 64.0072C73.4218 63.6356 72.8909 63.6356 72.7847 64.0072L71.6698 67.644C71.6432 67.7768 71.537 67.8564 71.4043 67.9095L67.7675 69.0244C67.3959 69.1306 67.3959 69.6615 67.7675 69.7677L71.4043 70.8827C71.537 70.9092 71.6167 71.0154 71.6698 71.1481L72.7847 74.7849C72.8909 75.1566 73.4218 75.1566 73.528 74.7849L74.6429 71.1481C74.6695 71.0154 74.7756 70.9358 74.9084 70.8827L78.5452 69.7677C78.9168 69.6615 78.9168 69.1306 78.5452 69.0244Z"
        fill="white"
      />
      <path
        d="M21.0754 69.3472L18.364 69.611C18.2689 69.6299 18.1871 69.5817 18.1154 69.5085L16.3774 67.4108C16.2049 67.1944 15.8538 67.3405 15.8859 67.6154L16.1497 70.3268C16.1687 70.4218 16.1204 70.5037 16.0472 70.5754L13.9496 72.3134C13.7331 72.4859 13.8792 72.8369 14.1541 72.8049L16.8655 72.5411C16.9606 72.5221 17.0425 72.5704 17.1141 72.6435L18.8522 74.7412C19.0246 74.9577 19.3757 74.8116 19.3436 74.5366L19.0798 71.8253C19.0608 71.7302 19.1091 71.6483 19.1823 71.5767L21.2799 69.8386C21.4964 69.6661 21.3503 69.3151 21.0754 69.3472Z"
        fill="#386CF4"
      />
      <path
        d="M36.1139 78.6358L34.7344 78.2128C34.684 78.2028 34.6538 78.1625 34.6337 78.1122L34.2108 76.7327C34.1705 76.5917 33.9691 76.5917 33.9288 76.7327L33.5059 78.1122C33.4959 78.1625 33.4556 78.1927 33.4052 78.2128L32.0258 78.6358C31.8848 78.676 31.8848 78.8774 32.0258 78.9177L33.4052 79.3406C33.4556 79.3507 33.4858 79.3909 33.5059 79.4413L33.9288 80.8208C33.9691 80.9617 34.1705 80.9617 34.2108 80.8208L34.6337 79.4413C34.6438 79.3909 34.684 79.3607 34.7344 79.3406L36.1139 78.9177C36.2548 78.8774 36.2548 78.676 36.1139 78.6358Z"
        fill="white"
      />
      <path
        d="M50.1532 75.3844C64.088 75.3844 75.3844 64.088 75.3844 50.1532C75.3844 36.2183 64.088 24.9219 50.1532 24.9219C36.2183 24.9219 24.9219 36.2183 24.9219 50.1532C24.9219 64.088 36.2183 75.3844 50.1532 75.3844Z"
        fill="#3955F6"
      />
      <rect x="34.2638" y="51.8066" width="7.56938" height="16.2397" rx="1.00925" transform="rotate(-45 34.2638 51.8066)" fill="white" />
      <rect x="62.6405" y="35.9141" width="7.56938" height="31.0925" rx="1.00925" transform="rotate(45 62.6405 35.9141)" fill="white" />
    </svg>
  )
}

export default TransferSuccess
