import React from 'react'

import { Skeleton } from '@arco-design/web-react'

import { ModeTypeEnum } from '@/typings'

interface IProps {
  mode: ModeTypeEnum
}
const ManagerSkeleton: React.FC<IProps> = (props) => {
  const { mode } = props

  return (
    <div className="upay-root">
      <div className={`upay-layout ${mode === ModeTypeEnum.HostedPage ? 'upay-mode-hosted' : ''}`}>
        <div className="upay-manager">
          <div className="upay-manager-header">
            <div className="upay-manager-title title-skeleton">
              <div className="title-skeleton-content" />
            </div>
          </div>
          <Skeleton text={{ rows: 5 }} style={{ height: 143, overflow: 'hidden' }} />
        </div>
      </div>
    </div>
  )
}

export default ManagerSkeleton
