"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault")["default"];
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.chunk = chunk;
exports.formatAmount = formatAmount;
exports.formatDate = formatDate;
exports.getCardIdentityNo = exports.formatExpiryDate = void 0;
exports.pickNumeric = pickNumeric;
exports.pickNumericAlphabet = pickNumericAlphabet;
exports.splitExpiryDate = void 0;
var _slice = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/slice"));
var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));
var _indexOf = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/index-of"));
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/slicedToArray"));
var _createForOfIteratorHelper2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/createForOfIteratorHelper"));
function chunk(list, sizes) {
  var start = 0;
  var chunks = [];
  var _iterator = (0, _createForOfIteratorHelper2["default"])(sizes),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var size = _step.value;
      chunks.push((0, _slice["default"])(list).call(list, start, start + size));
      start += size;
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
  return chunks;
}
// pick numeric characters
function pickNumeric(value) {
  return value.replace(/[^\d]/g, '');
}
// to replace padStart due to the compatibility on Android 5
function fillZero(str) {
  if (str.length === 1) {
    return '0' + str;
  } else {
    return str;
  }
}
function formatDate(num) {
  var _context, _context2;
  var data = new Date(num * 1000);
  var year = data.getFullYear().toString().substring(2);
  var month = fillZero((data.getMonth() + 1).toString());
  var date = fillZero(data.getDate().toString());
  return (0, _concat["default"])(_context = (0, _concat["default"])(_context2 = "".concat(date, "/")).call(_context2, month, "/")).call(_context, year);
}
function formatAmount(params) {
  var amount = params.amount,
    currency = params.currency,
    _params$locale = params.locale,
    locale = _params$locale === void 0 ? 'en' : _params$locale;
  if (amount && !currency) return amount;
  if (!amount && currency) return currency;
  var str = '';
  try {
    str = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency
    }).format(Number(amount));
  } catch (error) {
    var _context3;
    if (amount && currency) str = (0, _concat["default"])(_context3 = "".concat(currency, " ")).call(_context3, amount);
  }
  return str;
}
// pick numeric and alphabet
function pickNumericAlphabet(value) {
  return value.replace(/[^\da-zA-Z]/g, '');
}
function dateReplacer(match, p1, p2) {
  var month = (0, _slice["default"])(p1).call(p1, 0, 2);
  var year = (0, _slice["default"])(p1).call(p1, 2, p1.length) + p2;
  var date = month + '/' + (0, _slice["default"])(year).call(year, 0, 2);
  return date;
}
/**
 * Returns a formatted date
 * @param value -
 * @example
 * formatExpiryDate('1222');
 * // '12/22'
 */
var formatExpiryDate = function formatExpiryDate(value) {
  var date = value.replace(/[^\d/]/g, '') // Digits only
  .replace(/^(00)(.*)?/, '01$2') // 00 -> 01
  .replace(/^([0-9]{3})\/(.*)+/, dateReplacer) // 013/3 -> 01/33，132/5 -> 13/25
  .replace(/^(1[3-9])(.*)?/, '0$1$2') // 13 -> 013，13/25 -> 013/25
  .replace(/^([0-9]{3})\/(.*)+/, dateReplacer) // 013/2 -> 01/32
  .replace(/^([2-9])(.*)?/, '0$1') // 2 -> 02
  .replace(/^([0-9]{2})$/, '$1/') // 12 -> 12/
  .replace(/^([0-9]{2})([0-9])/, '$1/$2') // 2211 -> 22/11
  .replace(/^(1)\/([0-9])/, '01/$2') // 1/35 -> 01/35
  .replace(/^(0)\/([0-9])*/, '0'); // 0/35 -> 0
  return date;
};
/**
 * Receives a formatted date and returns it as the API expects it
 * @param value -
 * @example
 * unformatDate('12/25');
 * // { month: 12, year: 25 }
 */
exports.formatExpiryDate = formatExpiryDate;
var splitExpiryDate = function splitExpiryDate() {
  var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var defaultValue = {
    month: '',
    year: ''
  };
  if ((0, _indexOf["default"])(value).call(value, '/') === -1) {
    // no input
    if (!value) return defaultValue;
    // case: "123" => "12" & "3", cause format is after onChange
    if (value.length === 3) {
      var _month = (0, _slice["default"])(value).call(value, 0, 2);
      var _year = (0, _slice["default"])(value).call(value, 2, 3);
      return {
        month: _month,
        year: _year
      };
    }
  }
  var _value$split = value.split('/'),
    _value$split2 = (0, _slicedToArray2["default"])(_value$split, 2),
    _value$split2$ = _value$split2[0],
    month = _value$split2$ === void 0 ? '' : _value$split2$,
    _value$split2$2 = _value$split2[1],
    year = _value$split2$2 === void 0 ? '' : _value$split2$2;
  var validMonth = (0, _slice["default"])(month).call(month, 0, 2);
  var validYear = (0, _slice["default"])(year).call(year, 0, 2);
  return {
    month: validMonth,
    year: validYear
  };
};
/** 卡号前六后四中间掩码 */
exports.splitExpiryDate = splitExpiryDate;
var getCardIdentityNo = function getCardIdentityNo(cardNo) {
  if (cardNo && cardNo.length) {
    var reg = /^(\d{6})(\d*)(\d{4})$/;
    var str = cardNo.replace(reg, function (a, b, c, d) {
      return b + c.replace(/\d/g, '*') + d;
    });
    return str;
  } else {
    return cardNo;
  }
};
exports.getCardIdentityNo = getCardIdentityNo;