import React, { ReactElement, forwardRef, useEffect, useState } from 'react'
import { Controller, ControllerFieldState, useForm } from 'react-hook-form'

import { PAY_WAY_TYPE } from '@/constant'
import { usePipoRouter, useTouchedErrors } from '@/pipo/hooks'
import { PaymentParamsItem, PipoComponentRef, PipoProvider, PipoSubmitParams, generateCommonPaymentParams, validator } from '@/pipo/utils'
import { PAYCELL_PAYMENT_METHOD, PAYCELL_PAYMENT_PARAMS_MAP, PaycellFormData, PaycellFormProps } from '@/pipo/utils/payment-methods-config/paycell'
import starling from '@/utils/starling'
import teaClient from '@/utils/tea'

import { NationalID, PhoneNumber } from '../../inner-components'

const clsPrefix = 'pipo-bindcard-pc'

export const PaycellForm = forwardRef((props: PaycellFormProps, ref: React.ForwardedRef<PipoComponentRef>): ReactElement => {
  const {
    onValidate = () => {
      // do nothing
    },
  } = props
  const { getErrorText, getPublicKeyAsync } = PipoProvider.useContext()

  const {
    control,
    getValues,
    formState: { errors: formErrors, isValid, touchedFields },
  } = useForm<PaycellFormData>({
    mode: 'all',
  })

  const errors = useTouchedErrors<PaycellFormData>({
    touchedFields,
    errors: formErrors,
  })

  useEffect(() => {
    isValid && onValidate(true)
  }, [isValid, onValidate])

  /**
   * 处理PIPO提交所需数据
   * @returns PipoSubmitParams
   */
  const processSubmitData = async (formData: PaycellFormData): Promise<PipoSubmitParams> => {
    formData.phone_country_code = '90'
    formData.mobile_phone = '' + formData.phone_country_code + formData.phone

    const publicKey = await getPublicKeyAsync()
    const paymentParams: PaymentParamsItem[] = generateCommonPaymentParams(formData, PAYCELL_PAYMENT_PARAMS_MAP, publicKey)

    const { paymentMethodType, paymentMethod } = PAYCELL_PAYMENT_METHOD
    return {
      formData,
      paymentMethod: {
        paymentMethod: paymentMethod.paymentMethod,
        paymentMethodId: paymentMethod.paymentMethodId,
      },
      paymentMethodType: paymentMethodType.paymentMethodType,
      paymentParams,
      storeCard: true,
    }
  }

  /**
   * 校验后获取PIPO提交所需数据
   * @returns PipoSubmitParams
   */
  const validateSubmitData = () => {
    return new Promise(async (resolve: (value: PipoSubmitParams) => void, reject: (reason: typeof errors) => void) => {
      if (!isValid) {
        reject(errors)
      } else {
        const formData = getValues()
        resolve(await processSubmitData(formData))
      }
    })
  }

  usePipoRouter(ref, {
    initParams: {
      onValidate,
      isValid,
    },
    instanceMethods: {
      validateSubmitData,
    },
  })

  const handleBlur = (scenario: string, fieldState: ControllerFieldState) => {
    const params = {
      pay_way: PAY_WAY_TYPE.PAYCELL,
      scenario,
      is_valid: !fieldState.error,
      invalid_reason: fieldState.error?.type,
    }
    teaClient.sendPayPageFillIn(params)
  }

  return (
    <div className="pipo-pc" id="upay-form-paycell">
      <div className={`${clsPrefix}-flex-container`}>
        <div className={`${clsPrefix}-form-wrapper`}>
          <Controller
            rules={{ required: true, pattern: validator.paycellPhoneRegex }}
            name="phone"
            control={control}
            render={({ field, fieldState }) => (
              <PhoneNumber
                {...field}
                error={errors.phone}
                phoneNumberStyle="full"
                addBefore="TR +90"
                title="Paycell ID"
                placeholder={starling('funds.refund.comm.system_phonenumber_placeholder')}
                errorMessage={getErrorText(errors.phone?.type)}
                onBlur={() => {
                  field.onBlur()
                  handleBlur('phone', fieldState)
                }}
              />
            )}
          />
          <Controller
            rules={{ required: true, pattern: validator.nationalIDRegex }}
            name="identity"
            control={control}
            render={({ field, fieldState }) => (
              <NationalID
                {...field}
                len="full"
                error={errors.identity}
                title={starling('funds.refund.comm.system_bankaccount_idnumber')}
                placeholder={starling('cg.upay.exception.channel_id_num_hint')}
                errorMessage={getErrorText(errors.identity?.type)}
                onBlur={() => {
                  field.onBlur()
                  handleBlur('identity', fieldState)
                }}
              />
            )}
          />
        </div>
      </div>
    </div>
  )
})
