import React, { useContext, useState } from 'react'

import { Message } from '@arco-design/web-react'
import { pick } from 'lodash'

import { FROM_PAGE_ENUM, NEW_SDK_TERMINAL_EQUIP, PAY_WAY_TYPE } from '@/constant'
import { useFormModal } from '@/hooks'
import { IPayinMethodItem } from '@/pages/PayNext/interface'
import { BoletoUpdate } from '@/pipo/pc'
import { BOLETO_PAYMENT_METHOD, BOLETO_PAYMENT_PARAMS_MAP, generateCommonPaymentParams } from '@/pipo/utils'
import api from '@/services'
import { CardListRes } from '@/services/types'
import { ParamsContext } from '@/stores'
import GlobalInfo from '@/stores/GlobalInfo'
import SafeJSON from '@/utils/SafeJSON'
import starling from '@/utils/starling'

import Update from '..'

interface IProps {
  from: FROM_PAGE_ENUM
  onClose: (needRefresh?: boolean) => void
}
export const useUpdate = (props?: IProps) => {
  const { from, onClose } = props || {}
  const [updateModalVisible, setUpdateModalVisible] = useState(false)
  const [card, setCard] = useState<CardListRes | IPayinMethodItem>({} as CardListRes)

  const {
    urlQuery: { token },
  } = useContext(ParamsContext)
  const {
    utils: { getPublicKeyAsync },
  } = useContext(GlobalInfo)
  const { formModal, content } = useFormModal()

  const handleOpenUpdate = (card: CardListRes | IPayinMethodItem) => {
    if (card.payWay === PAY_WAY_TYPE.EXISTED_CARD) {
      setCard(card)
      setUpdateModalVisible(true)
      return
    } else if (card.payWay === PAY_WAY_TYPE.BOLETO) {
      formModal.open({
        className: 'upay-modal upay-modal-simple',
        style: { width: 600, maxWidth: 600 },
        title: starling('cg.upay.comm.update_boleto_add_email_title'),
        content: <BoletoUpdate />,
        okText: starling('funds.refund.comm.confirm_button'),
        cancelText: starling('funds.refund.comm.cancel'),
        focusLock: false,
        autoFocus: false,
        closable: false,
        maskClosable: false,
        onOk: async (values) => {
          const _card = card as any
          const publicKey = await getPublicKeyAsync()
          const paymentParams = generateCommonPaymentParams(values, pick(BOLETO_PAYMENT_PARAMS_MAP, 'email'), publicKey)
          await api.updateCard({
            token,
            cardId: _card.id || _card.cardId || '',
            channelParameter: SafeJSON.stringify({
              method_id: BOLETO_PAYMENT_METHOD.paymentMethod.paymentMethodId,
              payment_elements: paymentParams,
            }),
            terminalEquip: NEW_SDK_TERMINAL_EQUIP,
          })

          Message.success(starling('cg.upay.comm.update_boleto_add_email_success'))
          onClose?.(true)
        },
        onCancel: onClose,
      })
    }
  }

  const closeUpdateModal = (needRefresh = false) => {
    setUpdateModalVisible(false)
    onClose?.(needRefresh)
  }

  const renderUpdate = () => {
    if (!card || !from) return null

    const _card = card as any
    const cardId = _card.id || _card.cardId

    return <Update cardId={cardId} payWay={card.payWay} visible={updateModalVisible} onClose={closeUpdateModal} from={from} />
  }

  return { handleOpenUpdate, contentHolder: content || renderUpdate() }
}
