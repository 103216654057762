import { BillingAddress, Email, HolderName, ItemInput, ItemInputNumber } from '@/pipo/pc/inner-components'
import { validator } from '@/pipo/utils'
import starling from '@/utils/starling'

import { IBankAccountFormConfigItem, IGetBankAccountFormConfigProps } from '../utils'

export const getCAFormConfig = ({
  cardHolderInfo,
  postalCodeProps,
  billingAddressProps,
}: IGetBankAccountFormConfigProps): IBankAccountFormConfigItem => {
  const baseFormSchema = [
    {
      field: 'holder_name',
      widget: HolderName,
      widgetProps: {
        title: starling('funds.refund.comm.beneficiary_name'),
        placeholder: starling('funds.refund.comm.system_name_description'),
        maxLength: 35,
        selectList: cardHolderInfo?.holderNameList,
      },
      rules: { required: true, pattern: validator.caHolderName },
    },
    {
      field: 'routing_no',
      widget: ItemInputNumber,
      widgetProps: {
        title: starling('funds.refund.comm.system_bankaccount_routingcode_US'),
        placeholder: 'e.g. 000000000',
        maxLength: 9,
      },
      rules: { required: true, pattern: validator.routingNo },
      ruleMessages: { pattern: starling('cg.upay.exception.channel_id_num_error_message', { num: 9 }) },
    },
    {
      field: 'account_no',
      widget: ItemInputNumber,
      widgetProps: {
        title: starling('funds.refund.comm.system_bankaccount_account_number'),
        placeholder: 'e.g. 0123456789',
        maxLength: 12,
      },
      rules: { required: true, pattern: validator.caBankAccountNo },
    },
    {
      field: 'email',
      widget: Email,
      widgetProps: {
        title: starling('funds.refund.comm.system_email'),
        placeholder: starling('funds.refund.comm.system_email_placeholder'),
      },
      rules: { required: true, pattern: validator.emailRegex },
    },
  ]

  const toCExtraSchema = [
    {
      field: 'billing_address',
      widget: BillingAddress,
      noController: true,
      widgetProps: {
        title: starling('funds.refund.comm.system_Billing_address'),
        postalCodeProps,
        ...billingAddressProps,
        onlyCurrentCountry: true,
        rules: { street: { required: true, pattern: validator.caAddress } },
        streetProps: { maxLength: 70 },
      },
    },
    {
      field: 'billing_building_number',
      widget: ItemInput,
      widgetProps: {
        title: starling('funds.refund.comm.building_num'),
        placeholder: 'Address',
        maxLength: 16,
      },
      rules: { required: true, pattern: validator.caAddress },
    },
  ]

  return {
    allowAccountType: ['b', 'c'],
    needInfo: ['contract_address_list'],
    formSchema: baseFormSchema,
    toCFormSchema: [...baseFormSchema, ...toCExtraSchema],
  }
}
