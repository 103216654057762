import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'

import { IBindCardRes, StatusActionTypeEnum, StatusTypeEnum } from '@upay/utils/es/types'

import { PAYMENT, PAY_WAY_TYPE } from '@/constant'
import { IPayinMethodItem, PaymentSubmitMethodEnum } from '@/pages/PayNext/interface'
import { IPaymentMethodsProps, IPaymentsMethodRef } from '@/payments/interface'
import { PaycellForm } from '@/pipo/pc'
import { PipoComponentRef } from '@/pipo/utils'
import { IBindCardSupplementParams } from '@/services/types'
import utils from '@/utils'
import SafeJSON from '@/utils/SafeJSON'

const Paycell = forwardRef<IPaymentsMethodRef, IPaymentMethodsProps>((props, ref): React.ReactElement => {
  const {
    changeNewPaymentStatus,
    openCheckOTPModal,
    linkOpen,
    handleBillStatus,
    paymentSubmitMethod,
    urlQuery: { type },
    channel,
  } = props

  const paycellRef = useRef<PipoComponentRef>(null)
  const [isValid, setIsValid] = useState(false)

  // 同步验证状态
  useEffect(() => {
    changeNewPaymentStatus(PAY_WAY_TYPE.PAYCELL, { valid: isValid })
  }, [isValid, changeNewPaymentStatus])

  // TODO: 暂时兼容两种逻辑，后续新UI全量后可梳理移除，由后端维护是否显示
  const showSavingBox = useMemo(() => {
    // 仅支付场景展示SaveBox
    if (paymentSubmitMethod !== PaymentSubmitMethodEnum.PAYMENT) return false

    // 若后端返回 以后端为准 老逻辑未返回排除后付费
    const supportFromBE = (channel as IPayinMethodItem).supportPaymentAndBind
    return typeof supportFromBE === 'boolean' ? supportFromBE : type !== PAYMENT.POST_PAY
  }, [paymentSubmitMethod, channel, type])

  useImperativeHandle(ref, () => ({
    processSubmitData: async (submitParams) => {
      if (paycellRef.current?.validateSubmitData) {
        const { paymentParams } = await paycellRef.current?.validateSubmitData()
        submitParams.channelParameter = SafeJSON.stringify(paymentParams)
      }
      return submitParams
    },
    needHandleResult: () => true,
    handleResult: async (res, submitParams) => {
      if ((res as IBindCardRes)?.needOtpCode) {
        const { formData } = await paycellRef.current!.validateSubmitData!()
        openCheckOTPModal &&
          openCheckOTPModal({
            identityNo: utils.replaceStr(formData.mobile_phone, 6, 4, '****'),
            submitType: 'bind',
            submitParams: submitParams as IBindCardSupplementParams,
          })
      } else {
        // 默认处理逻辑
        res?.url ? linkOpen(res.url, 'PAYCELL') : handleBillStatus({ actionType: StatusActionTypeEnum.BIND, status: StatusTypeEnum.SUCCESS })
      }
    },
  }))

  const onValidChange = useCallback((value: boolean) => {
    setIsValid(value)
  }, [])

  return <PaycellForm ref={paycellRef} onValidate={onValidChange} showSavingBox={showSavingBox} />
})

export default Paycell
