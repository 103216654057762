// 别删除 **/index，删除后会报错，待排查
import { BLIK_PAYMENT_METHOD, PROMPT_PAY_PAYMENT_METHOD } from '@upay/pipo-utils'
import { PaymentMethodItem, SupportBindcardPaymentMethodId, SupportPaymentMethodId } from '@upay/pipo-utils/es/types'

import { ALFA_PAYMENT_METHOD } from './alfa'
import { AMBANK_PAYMENT_METHOD } from './ambank'
import { BANKISLAM_PAYMENT_METHOD } from './bank-islam'
import { BANKTRANSFER_2C2P_PAYMENT_METHOD } from './banktransfer-2c2p'
import { BANKTRANSFER_PAYMENT_METHOD } from './banktransfer/index'
import { BOLETO_PAYMENT_METHOD } from './boleto/index'
import { CASH_PLUS_PAYMENT_METHOD } from './cash-plus/index'
import { SUPPORT_CCDC_PAYMENT_METHODS } from './ccdc/index'
import { CIMBBANK_PAYMENT_METHOD } from './cimb-bank'
import { DAILYYAMAZAKI_PAYMENT_METHOD, DAILYYAMAZAKI_PAYMENT_PARAMS_MAP } from './dailyyamazaki'
import { DANA_PAYMENT_METHOD } from './dana/index'
import { FAMILYMART_PAYMENT_METHOD, FAMILYMART_PAYMENT_PARAMS_MAP } from './familymart'
import { FAWRY_PAYMENT_METHOD } from './fawry'
import { GCASH_PAYMENT_METHOD } from './gcash'
import { GOPAY_PAYMENT_METHOD } from './gopay/index'
import { GRABPAY_PAYMENT_METHOD } from './grabpay'
import { HONGLEONGBANK_PAYMENT_METHOD } from './hong-leong-bank'
import { INDOMARET_PAYMENT_METHOD } from './indomaret'
import { KAKAOPAY_PAYMENT_METHOD } from './kakaopay'
import { SUPPORT_KLARNA_PAYMENT_METHODS } from './klarna'
import { LAWSON_PAYMENT_METHOD, LAWSON_PAYMENT_PARAMS_MAP } from './lawson'
import { LINEPAY_PAYMENT_METHOD } from './linepay/index'
import { MAYBANK_PAYMENT_METHOD } from './maybank'
import { MINISTOP_PAYMENT_METHOD, MINISTOP_PAYMENT_PARAMS_MAP } from './ministop'
import { MIR_PAYMENT_METHOD } from './mir-qiwi'
import { MPAY_PAYMENT_METHOD } from './mpay'
import { OVO_DIRECT_PAYMENT_METHOD, OVO_DIRECT_PAYMENT_PARAMS_MAP, OVO_REDIRECT_PAYMENT_METHOD, OVO_REDIRECT_PAYMENT_PARAMS_MAP } from './ovo/index'
import { OXXO_PAYMENT_METHOD, OXXO_PAYMENT_PARAMS_MAP } from './oxxo'
import { PAYEASY_PAYMENT_METHOD, PAYEASY_PAYMENT_PARAMS_MAP } from './payeasy'
import { PAYPAL_PAYMENT_METHOD } from './paypal/index'
import { PAYPAY_PAYMENT_METHOD } from './paypay'
import { PIX_PAYMENT_METHOD } from './pix/index'
import { PUBLICBANK_PAYMENT_METHOD } from './public-bank'
import { QIWI_PAYMENT_METHOD, QIWI_PAYMENT_PARAMS_MAP } from './qiwi'
import { RABBITLINEPAY_PAYMENT_METHOD } from './rabbitlinepay/index'
import { RAZERGOLD_PAYMENT_METHOD } from './razer-gold'
import { RHBBANK_PAYMENT_METHOD } from './rhb-bank'
import { SEICOMART_PAYMENT_METHOD, SEICOMART_PAYMENT_PARAMS_MAP } from './seicomart'
import { SEVENELEVEN_PAYMENT_PARAMS_MAP } from './seveneleven'
import { SHOPEEPAY_PAYMENT_METHOD, SHOPEEPAY_PAYMENT_PARAMS_MAP } from './shopeepay'
import { TOSS_PAYMENT_METHOD } from './toss/index'
import { TOUCHNGO_PAYMENT_METHOD } from './touchngo'
import { TRUEMONEY_PAYMENT_METHOD } from './truemoney/index'
import { YOOMONEY_PAYMENT_METHOD } from './yoomoney/index'
import { ZALOPAY_PAYMENT_METHOD } from './zalopay'

// =================== 支付流程 paymentMethods ================
export const SUPPORT_PAYMENT_METHODS: PaymentMethodItem[] = [
  ...SUPPORT_CCDC_PAYMENT_METHODS,
  BANKTRANSFER_PAYMENT_METHOD,
  DANA_PAYMENT_METHOD,
  GOPAY_PAYMENT_METHOD,
  OVO_DIRECT_PAYMENT_METHOD,
  PAYPAL_PAYMENT_METHOD,
  BOLETO_PAYMENT_METHOD,
  TOSS_PAYMENT_METHOD,
  QIWI_PAYMENT_METHOD,
  RABBITLINEPAY_PAYMENT_METHOD,
  TRUEMONEY_PAYMENT_METHOD,
  LINEPAY_PAYMENT_METHOD,
  RAZERGOLD_PAYMENT_METHOD,
  MPAY_PAYMENT_METHOD,
  ...SUPPORT_KLARNA_PAYMENT_METHODS,
  BANKTRANSFER_2C2P_PAYMENT_METHOD,
  ALFA_PAYMENT_METHOD,
  INDOMARET_PAYMENT_METHOD,
  PIX_PAYMENT_METHOD,
  YOOMONEY_PAYMENT_METHOD,
  OVO_REDIRECT_PAYMENT_METHOD,
  KAKAOPAY_PAYMENT_METHOD,
  FAWRY_PAYMENT_METHOD,
  TOUCHNGO_PAYMENT_METHOD,
  MIR_PAYMENT_METHOD,
  DAILYYAMAZAKI_PAYMENT_METHOD,
  LAWSON_PAYMENT_METHOD,
  MINISTOP_PAYMENT_METHOD,
  SEICOMART_PAYMENT_METHOD,
  FAMILYMART_PAYMENT_METHOD,
  PAYEASY_PAYMENT_METHOD,
  GCASH_PAYMENT_METHOD,
  MAYBANK_PAYMENT_METHOD,
  CIMBBANK_PAYMENT_METHOD,
  AMBANK_PAYMENT_METHOD,
  HONGLEONGBANK_PAYMENT_METHOD,
  PUBLICBANK_PAYMENT_METHOD,
  RHBBANK_PAYMENT_METHOD,
  BANKISLAM_PAYMENT_METHOD,
  SHOPEEPAY_PAYMENT_METHOD,
  GRABPAY_PAYMENT_METHOD,
  ZALOPAY_PAYMENT_METHOD,
  OXXO_PAYMENT_METHOD,
  PAYPAY_PAYMENT_METHOD,
  CASH_PLUS_PAYMENT_METHOD,
  PROMPT_PAY_PAYMENT_METHOD,
  BLIK_PAYMENT_METHOD,
]

// =================== 绑定流程 paymentMethods ================
// 支持单独绑定的 paymentMethods, pi-list 下方的绑定入口
export const SUPPORT_BIND_PAYMENT_METHODS: PaymentMethodItem[] = [
  ...SUPPORT_CCDC_PAYMENT_METHODS,
  DANA_PAYMENT_METHOD,
  BOLETO_PAYMENT_METHOD,
  PIX_PAYMENT_METHOD,
  KAKAOPAY_PAYMENT_METHOD,
  OVO_REDIRECT_PAYMENT_METHOD,
  SHOPEEPAY_PAYMENT_METHOD,
]

type SupportBindPaymentMethodIdMap = Record<SupportBindcardPaymentMethodId, PaymentMethodItem>

// =================== 绑定流程 paymentMethods ID Map ================
export const SUPPORT_BIND_PAYMENT_METHODS_ID_MAP = SUPPORT_BIND_PAYMENT_METHODS.reduce<SupportBindPaymentMethodIdMap>((map, item) => {
  // @ts-ignore
  map[item.paymentMethod.paymentMethodId] = item
  return map
}, {} as SupportBindPaymentMethodIdMap)

type SupportPaymentMethodIdMap = Record<SupportPaymentMethodId, PaymentMethodItem>

// =================== 支付流程 paymentMethods ID Map ================
export const SUPPORT_PAYMENT_METHODS_ID_MAP = SUPPORT_PAYMENT_METHODS.reduce<SupportPaymentMethodIdMap>((map, item) => {
  // @ts-ignore
  map[item.paymentMethod.paymentMethodId] = item
  return map
}, {} as SupportPaymentMethodIdMap)

export { GET_SUPPORT_BANK_MAP } from './banktransfer/index'

export const PAYMENT_PARAMS_MAP: Partial<Record<SupportPaymentMethodId, Record<string, string>>> = {
  pm_pi_ew_ovo_c_d: OVO_DIRECT_PAYMENT_PARAMS_MAP,
  pm_pi_ew_ovo_c_r: OVO_REDIRECT_PAYMENT_PARAMS_MAP,
  pm_pi_ew_qiwi_c_r: QIWI_PAYMENT_PARAMS_MAP,
  pm_pi_cp_dailyyamazaki_c_d: DAILYYAMAZAKI_PAYMENT_PARAMS_MAP,
  pm_pi_cp_seveneleven_c_d: SEVENELEVEN_PAYMENT_PARAMS_MAP,
  pm_pi_cp_lawson_c_d: LAWSON_PAYMENT_PARAMS_MAP,
  pm_pi_cp_ministop_c_d: MINISTOP_PAYMENT_PARAMS_MAP,
  pm_pi_cp_seicomart_c_d: SEICOMART_PAYMENT_PARAMS_MAP,
  pm_pi_cp_familymart_c_d: FAMILYMART_PAYMENT_PARAMS_MAP,
  pm_pi_bt_payeasy_c_r: PAYEASY_PAYMENT_PARAMS_MAP,
  pm_pi_ew_shopeepay_c_r: SHOPEEPAY_PAYMENT_PARAMS_MAP,
  pm_pi_cp_oxxo_c_d: OXXO_PAYMENT_PARAMS_MAP,
}
