export enum ManageCardTypeEnum {
  SetPrimary = 'make_primary',
  RemoveCard = 'remove',
  EditCard = 'edit',
}

export enum PaySwitchEnum {
  Show = '1',
  Hide = '2',
}
