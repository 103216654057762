import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'

import SingleInputForm from '@/components/SingleInputForm'
import { ISingleInputFormValue } from '@/components/SingleInputForm/interface'
import { PAY_WAY_TYPE } from '@/constant'
import utils from '@/utils'
import SafeJSON from '@/utils/SafeJSON'
import starling from '@/utils/starling'

import { IPaymentMethodsProps, IPaymentsMethodRef } from '../../interface'

// 提交时须带上的前缀/参数
const PHONE_PREFIX_REQ = 'ID +62'
const COUNTRY_CODE = '62'
const DEFAULT_FORM_STATUS = {
  id: '',
  save: false,
  valid: false,
}

const Gopay = forwardRef<IPaymentsMethodRef, IPaymentMethodsProps>((props, ref): React.ReactElement => {
  const {
    changeNewPaymentStatus,
    urlQuery: { token },
  } = props
  const [formStatus, setFormStatus] = useState<ISingleInputFormValue>(DEFAULT_FORM_STATUS)
  const GOPAY_CONFIG = {
    payWay: PAY_WAY_TYPE.GOPAY_V2,
    formTitle: starling('funds.refund.comm.system_phonenumber'),
    inputPlaceholder: starling('funds.refund.comm.system_phonenumber_placeholder'),
    inputPrefix: PHONE_PREFIX_REQ,
    showSavingBox: false,
  }

  useImperativeHandle(ref, () => ({
    // 提交处理逻辑
    processSubmitData: async (submitParams) => {
      const encryptPhoneNumber = await utils.fetchKeyAndEncryptString(formStatus.id, token)
      submitParams.channelParameter = SafeJSON.stringify({
        phoneNumber: encryptPhoneNumber,
        encrypted: true,
        countryCode: COUNTRY_CODE,
      })
      return submitParams
    },
  }))

  useEffect(() => {
    changeNewPaymentStatus(PAY_WAY_TYPE.GOPAY_V2, { valid: !!formStatus.valid })
  }, [changeNewPaymentStatus, formStatus.valid])

  const getInvalidMsg = (value: string) => {
    const numRegex = /^[0-9]*$/
    if (!value) return starling('funds.refund.comm.enter_number_required')
    if (!numRegex.test(value)) return starling('funds.refund.comm.enter_number_rule')
    return ''
  }

  return <SingleInputForm value={formStatus} onChange={setFormStatus} getInvalidMsg={getInvalidMsg} config={GOPAY_CONFIG} />
})

export default Gopay
