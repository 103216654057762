import React from 'react'

import { Radio } from '@arco-design/web-react'
import clsx from 'clsx'

import PopoverErrorTip from '@/components/PopoverErrorTip'
import { IPayinMethodItem } from '@/pages/PayNext/interface'

import PaymentItemCard from '../PaymentItemCard'

import './index.less'

interface IProps {
  isSelected: boolean
  paymentItem: IPayinMethodItem
  onSelect: (item: IPayinMethodItem) => void
}
const PaymentItemHeader: React.FC<IProps> = (props) => {
  const { isSelected, paymentItem, onSelect } = props

  return (
    <div
      className={clsx('upay-payout-item-header', {
        'upay-payout-item-header-disable': !!paymentItem.disable,
      })}
      onClick={() => {
        !paymentItem.disable && onSelect?.(paymentItem)
      }}
    >
      <Radio checked={isSelected} disabled={paymentItem.disable} />
      <img className="upay-payout-item-logo" src={paymentItem.imageUrl} />

      {paymentItem?.cardId ? (
        <PaymentItemCard paymentItem={paymentItem} />
      ) : (
        <span className="upay-payout-item-title">{paymentItem.paymentsTitle}</span>
      )}
      {!!paymentItem.disableTips && <PopoverErrorTip message={paymentItem.disableTips} description={paymentItem.disableTips} />}
    </div>
  )
}

export default PaymentItemHeader
