import React, { useEffect, useState } from 'react'

import { OrderStatus } from '@/pipo/utils'
import starling from '@/utils/starling'

import { DefaultVoucherProps } from '../..'
import { AmountCard } from '../../amount-card'
import { ExpiredMask } from '../../expired-mask'
import { PaymentDetailInfo } from '../../payment-detail-info'
import { TradingStatus } from '../../trading-status'

export const BanktransferMXVoucher = (props: DefaultVoucherProps) => {
  const {
    amount,
    expiration_time: expirationTime,
    present_to_shopper_details: presentToShopperDetails,
    upayResult,
    merchant_order_id,
  } = props.paymentDetail

  const { onExpire } = props

  const [orderStatus, setOrderStatus] = useState<OrderStatus>('init')
  useEffect(() => {
    setOrderStatus(props.paymentDetail.orderStatus)
  }, [props.paymentDetail.orderStatus])

  const infoList = [
    {
      title: starling('funds.refund.comm.system_bankaccount_account_number'),
      content: presentToShopperDetails.account_number,
    },
    {
      title: starling('funds.refund.comm.bank_name'),
      content: presentToShopperDetails.bank_name,
    },
    {
      title: starling('funds.check_out.comm.company_name'),
      content: presentToShopperDetails.payee_company_name,
    },
    {
      title: starling('funds.check_out.comm.page_tittle_payment_code'),
      content: presentToShopperDetails.payment_code,
    },
  ]

  return (
    <div className="pipo-pc-voucher">
      <TradingStatus
        orderStatus={orderStatus}
        upayResult={upayResult}
        expirationTime={expirationTime}
        onTimeUp={onExpire}
        orderNo={merchant_order_id}
      />

      <ExpiredMask orderStatus={orderStatus}>
        <AmountCard title={starling('funds.check_out.comm.channel_bank_transfer')} amount={amount} iconUrl={presentToShopperDetails?.icon_url} />
        <PaymentDetailInfo infoList={infoList} />
      </ExpiredMask>
    </div>
  )
}
