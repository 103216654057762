import React from 'react'
import { UseFormReturn } from 'react-hook-form'

import { CCDCUpdate } from '@/pipo/pc'
import { CardListRes } from '@/services/types'

import './index.less'

interface IProps {
  card: Pick<CardListRes, 'paymentMethodId' | 'identityType' | 'identityNo' | 'imageUrl'> | undefined
  form: UseFormReturn
}
const UpdateWidget: React.FC<IProps> = (props) => {
  const { card, form } = props
  const { identityType, identityNo, imageUrl, paymentMethodId } = card || {}

  return (
    <div className="upay-update-widget">
      <div className="upay-update-item-header">
        <img className="upay-update-item-logo" src={imageUrl} />

        <span className="upay-update-item-title">
          {identityType || ''} {identityNo}
        </span>
      </div>

      {/* 目前只有ccdc */}
      <CCDCUpdate form={form} paymentMethodId={paymentMethodId} />
    </div>
  )
}

export default UpdateWidget
