import React, { FC } from 'react'

import { Spin } from '@arco-design/web-react'
import clsx from 'clsx'

import { PAY_WAY_TYPE } from '@/constant'
import { IPayinMethodItem } from '@/pages/PayNext/interface'
import { CardListRes } from '@/services/types'
import starling from '@/utils/starling'

import './style.less'

interface IProps {
  card: CardListRes | IPayinMethodItem
  onUpdate?: (card: CardListRes | IPayinMethodItem) => void
  disabled?: boolean
  loading?: boolean
}
const CardItemTitle: FC<IProps> = (props) => {
  const { card, disabled = false, onUpdate, loading } = props
  const { payWay, primary, imageUrl, identityType, identityNo, expired, allowUpdate, cardDetails } = card || {}

  // TODO: paynext 全量后 可梳理移除此处的兼容逻辑
  const valid = (card as IPayinMethodItem).disable !== true && (card as CardListRes).valid !== false
  const expireTime = (card as IPayinMethodItem).expireTime || (card as CardListRes).expire_time
  const invalidMessage = (card as CardListRes).invalidMessage
  // 新UI才有，放在外层统一展示
  const disableTips = (card as IPayinMethodItem).disableTips

  const cardDisabled = disabled || !valid

  const renderTips = () => {
    if (payWay === PAY_WAY_TYPE.EXISTED_CARD && allowUpdate && expired) {
      return (
        <>
          <span className="upay-color-error">
            {starling('cg.upay.comm.expired_expiry_date', {
              expiry_date: expireTime,
            })}
          </span>{' '}
          <span className="upay-link" onClick={() => onUpdate?.(card)}>
            {starling('cg.upay.comm.expired_update_pi')}
          </span>
        </>
      )
    } else if (payWay === PAY_WAY_TYPE.BOLETO && allowUpdate) {
      return (
        <>
          <span>{starling('cg.upay.comm.update_boleto_prompt')}</span>{' '}
          <span className="upay-link" onClick={() => onUpdate?.(card)}>
            {starling('cg.upay.comm.update_boleto_add_email_link')}
          </span>
        </>
      )
    } else if (cardDetails?.invalid) {
      return <span className="upay-card-item-warning">{starling('funds.refund.comm.insufficientbalance')}</span>
    } else if (cardDetails?.unbound) {
      return <span className="upay-card-item-warning">{starling('funds.refund.comm.unbound')}</span>
    } else if (cardDetails?.default) {
      return <span className="upay-card-item-warning">{starling('funds.checkout.comm.system_api_timeout')}</span>
    } else if (!valid) {
      return invalidMessage || starling('funds.refund.comm.invalid')
    }
  }

  return (
    <div
      className={clsx('upay-card-item-container', `upay-card-payway-${payWay}`, {
        'upay-card-loading': loading,
        'upay-card-item-disabled': cardDisabled,
      })}
      id={`upay-pay-card-${identityNo.slice(-4)}`}
    >
      <img className="upay-card-item-logo" src={imageUrl} />

      <div>
        {/* 第一行展示信息 */}
        <div className="upay-card-item-content">
          <span className="upay-card-item-number">
            {identityType || ''} {identityNo}
          </span>

          <div className="upay-card-item-tip">
            {Boolean(loading) && <Spin size={14} style={{ lineHeight: '14px' }} />}
            {/* 卡余额 */}
            {cardDetails && cardDetails?.amount && (
              <span className="upay-card-item-currency">{`(Balance：${cardDetails?.amount} ${cardDetails?.currency})`}</span>
            )}
            {/* 无效卡 */}
            {!valid && !invalidMessage && !disableTips && <span className="upay-card-item-date">{`(${starling('funds.refund.comm.expired')})`}</span>}
            {/* 过期时间 */}
            {!!valid && expireTime && <span className="upay-card-item-date">{`(${expireTime})`}</span>}
            {/* 主卡 */}
            {primary && <div className="upay-card-item-tag">{starling('funds.refund.comm.primary')}</div>}
          </div>
        </div>

        {/* 第二行展示信息 */}
        <div className="upay-card-item-invalid-message">{renderTips()}</div>
      </div>
    </div>
  )
}

export default CardItemTitle
