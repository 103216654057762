export function chunk<T>(list: string | T[], sizes: number[]) {
  let start = 0
  const chunks: Array<string | T[]> = []
  for (const size of sizes) {
    chunks.push(list.slice(start, start + size))
    start += size
  }
  return chunks
}

// pick numeric characters
export function pickNumeric(value: string) {
  return value.replace(/[^\d]/g, '')
}

// pick numeric and alphabet
export function pickNumericAlphabet(value: string) {
  return value.replace(/[^\da-zA-Z]/g, '')
}

// to replace padStart due to the compatibility on Android 5
function fillZero(str: string) {
  if (str.length === 1) {
    return '0' + str
  } else {
    return str
  }
}

export function formatDate(num: number) {
  const data = new Date(num * 1000)
  const year = data.getFullYear().toString().substring(2)
  const month = fillZero((data.getMonth() + 1).toString())
  const date = fillZero(data.getDate().toString())
  return `${date}/${month}/${year}`
}

export function formatAmount(params: { amount?: string; currency?: string; locale?: string }) {
  const { amount, currency, locale = 'en' } = params
  if (amount && !currency) return amount
  if (!amount && currency) return currency
  let str = ''
  try {
    str = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
    }).format(Number(amount))
  } catch (error) {
    if (amount && currency) str = `${currency} ${amount}`
  }
  return str
}
