import React, { useState } from 'react'

import { Down, Up } from '@icon-park/react'

import { Channel } from '@/components'
import CardItemTitle from '@/components/CardItemTitle'
import { ChannelItem } from '@/components/Channel'
import { CardListRes } from '@/services/types'
import starling from '@/utils/starling'

const MAX_SHOW_NUMBER = 2 // 最多展示2张卡

interface IProps {
  dataSource: CardListRes[]
  activeChannel: string
  onChannelChange: (key: string, data?: any) => void
  errorMsg: string | undefined
}
const CardChannel = (props: IProps) => {
  const { dataSource, activeChannel, onChannelChange, errorMsg } = props
  const [folderExpand, setFolderExpand] = useState(false) // 卡折叠部分展开状态
  const showFolder = dataSource?.length > MAX_SHOW_NUMBER // 卡折叠管理

  const renderCards = () => {
    return dataSource.map((i) => {
      const cardDisable = !!errorMsg || !i.valid
      const c = {
        key: i.id,
        name: i.identityType,
        content: null,
        disable: cardDisable, // 卡为失效态或者当前存在错误，则置灰
        data: i,
        renderTitle: () => <CardItemTitle card={i} disabled={cardDisable} />,
      }
      return c
    }) as ChannelItem[]
  }

  return (
    <React.Fragment>
      <Channel
        channels={renderCards()}
        maxShowCount={folderExpand ? undefined : MAX_SHOW_NUMBER}
        activeChannel={activeChannel}
        onChange={onChannelChange}
      />
      {showFolder && (
        <div
          className="upay-change__card-more"
          onClick={() => {
            setFolderExpand(!folderExpand)
          }}
        >
          {!folderExpand ? (
            <React.Fragment>
              <span>
                {starling('funds.refund.comm.other_cards', {
                  number: dataSource.length - MAX_SHOW_NUMBER,
                })}
              </span>
              <Down theme="outline" size="12" fill="#3F71C3" />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <span>{starling('funds.refund.comm.retract')}</span>
              <Up theme="outline" size="12" fill="#3F71C3" />
            </React.Fragment>
          )}
        </div>
      )}
    </React.Fragment>
  )
}

export default CardChannel
