import React, { FC, useContext, useState } from 'react'

import { Table, Tabs, Tooltip } from '@arco-design/web-react'
import { useRequest } from '@byted/hooks'
import { Help } from '@icon-park/react'
import clsx from 'clsx'

import { FROM_PAGE_ENUM } from '@/constant'
import api from '@/services'
import noDateSvg from '@/static/svg/payout-no-data.svg'
import { ParamsContext } from '@/stores'
import starling from '@/utils/starling'

import PayoutPay from '../Pay'
import PayoutHeader from './InfoHeader'
import {
  IPaymentMethodProps,
  IStatusRenderProps,
  StatusEnum,
  TabKeyEnum,
  formatUtcToZone,
  statusClassNameMap,
  transactionColumns,
  withdrawHistoryColumns,
} from './conf'

import './index.less'

const PayoutInfo: FC = () => {
  const [activeTab, setActiveTab] = useState<string>(TabKeyEnum.TransactionKey)
  const [refreshFlag, setRefreshFlag] = useState({}) // 在完成交易操作后 刷新数据
  const [payoutVisible, setPayoutVisible] = useState(false)

  const {
    urlQuery: { token },
  } = useContext(ParamsContext)

  const { data: transactionData, loading: transactionDataLoading } = useRequest(
    () =>
      api.getPayoutTrans({ token }).then((res) =>
        res?.list?.map((item) => {
          return Object.assign(
            item,
            { displayAmount: `${item.displayAmount} ${item.currency}` },
            { paymentTime: `${formatUtcToZone(item.paymentTime)}` },
          )
        }),
      ),
    {
      auto: true,
      refreshDeps: [refreshFlag],
    },
  )
  const { data: withdrawData, loading: withdrawDataLoading } = useRequest(
    () =>
      api
        .getPayoutHistory({ token })
        .then((res) =>
          res?.list?.map((item) =>
            Object.assign(
              item,
              { displayAmount: `${item.displayAmount} ${item.currency}` },
              { withdrawTime: `${formatUtcToZone(item.withdrawTime)}` },
            ),
          ),
        ),
    {
      auto: true,
      refreshDeps: [refreshFlag],
    },
  )

  const { data: transInfo } = useRequest(() => api.postPayoutInfo({ token }).then((res) => res?.data), {
    auto: true,
    refreshDeps: [refreshFlag],
  })

  const handleClickWithdraw = () => {
    setPayoutVisible(true)
  }

  const handleClosePayout = () => {
    setRefreshFlag({})
    setPayoutVisible(false)
  }

  return (
    <div id="upayPayoutInfo" className={clsx('upay-payout-info', !transInfo?.canWithdraw && 'upay-payout-info-no-header')}>
      {/* payout列表弹窗 */}
      <PayoutPay visible={payoutVisible} from={FROM_PAGE_ENUM.PAYOUT_IN_INFORMATION} onClose={handleClosePayout} />

      {transInfo?.canWithdraw && (
        <PayoutHeader refundAmount={transInfo?.displayAmount} currency={transInfo?.currency} onClickWithdraw={handleClickWithdraw} />
      )}

      <Tabs
        activeTab={activeTab}
        onChange={setActiveTab}
        defaultActiveTab={TabKeyEnum.TransactionKey}
        className={clsx('upay-payout-info-tabs', !transInfo?.canWithdraw && 'upay-payout-info-tab-no-header')}
      >
        <Tabs.TabPane title={starling('funds.refund.comm.transactioninformation')} key={TabKeyEnum.TransactionKey}>
          <Table
            loading={transactionDataLoading}
            border={false}
            columns={transactionColumns().concat(
              {
                title: starling('funds.refund.comm.original_paymentmethod'),
                dataIndex: 'paymentMethod',
                width: 220,
                render: (col, record) => <RenderIcon record={record} />,
              },
              {
                title: starling('funds.refund.comm.status'),
                dataIndex: 'statusName',
                render: (col, record) => (
                  <RenderStatus record={record} onViewDetail={() => setActiveTab(TabKeyEnum.WithdrawHistory)} hasViewWithdrawDetail />
                ),
              },
            )}
            data={transactionData}
            pagination={false}
            className="transaction-table"
            hover={false}
            noDataElement={<TableEmpty />}
          />
        </Tabs.TabPane>
        <Tabs.TabPane title={starling('funds.refund.comm.status_withdraw_history')} key={TabKeyEnum.WithdrawHistory}>
          <Table
            loading={withdrawDataLoading}
            border={false}
            hover={false}
            pagination={false}
            columns={withdrawHistoryColumns().concat(
              {
                title: starling('funds.refund.comm.withdraw_paymentmethod'),
                dataIndex: 'paymentMethod',
                width: 220,
                render: (col, record) => <RenderIcon record={record} />,
              },
              {
                title: starling('funds.refund.comm.status'),
                dataIndex: 'statusName',
                render: (col, record) => <RenderStatus record={record} />,
              },
            )}
            data={withdrawData}
            noDataElement={<TableEmpty />}
            rowKey="withdrawId"
            // expandedRowRender={() => (
            //   <Table
            //     border={false}
            //     columns={transactionColumns().concat({
            //       title: starling('funds.refund.comm.original_paymentmethod'),
            //       dataIndex: 'paymentMethod',
            //       render: (col, record) => <RenderIcon record={record} />,
            //     })}
            //     data={transactionData}
            //     pagination={false}
            //     className="transaction-table withdraw-history-transaction"
            //     hover={false}
            //     noDataElement={<TableEmpty />}
            //   ></Table>
            // )}
            // expandProps={{
            //   icon: ({ expanded }) =>
            //     expanded ? <DownOne theme="filled" size="16" fill="#121212" /> : <RightOne theme="filled" size="16" fill="#121212" />,
            //   width: 48,
            //   expandRowByClick: true,
            //   rowExpandable: () => true,
            // }}
          />
        </Tabs.TabPane>
      </Tabs>
    </div>
  )
}

const TableEmpty: React.FC = () => {
  return <img className="payout-table-empty" src={noDateSvg} alt="no-data" />
}

const RenderStatus: React.FC<IStatusRenderProps> = (props) => {
  const { record, onViewDetail, hasViewWithdrawDetail } = props

  return (
    <>
      {record.status && (
        <span>
          <span className={`${record && record.status && statusClassNameMap[record.status]} information-table-status`} />
          {record.statusName}
          {hasViewWithdrawDetail && record.status === StatusEnum.withdrawProcessing && (
            <a className="view-withdraw-detail" onClick={() => onViewDetail?.()}>
              {starling('funds.refund.comm.view_withdraw_detail')}
            </a>
          )}
          {record.errorMsg && record.status === StatusEnum.withdrawFailed && (
            <Tooltip
              mini
              color="#fff"
              content={<span style={{ color: '#121212' }}>{record.errorMsg}</span>}
              getPopupContainer={() => document.getElementById('upayPayoutInfo') as HTMLElement}
            >
              <Help theme="filled" size="12" fill="#D2D2D2" style={{ marginLeft: '2px' }} />
            </Tooltip>
          )}
        </span>
      )}
    </>
  )
}

const RenderIcon: React.FC<IPaymentMethodProps> = (props) => {
  const { record } = props

  return (
    <div className="payout-info-payment-method">
      <span className="payout-info-payment-method-icon">
        <img src={record?.payWayIconUrl} alt="icon" />
      </span>

      {record?.maskedInstrumentIdentity ? (
        <span>{record?.maskedInstrumentIdentity}</span>
      ) : (
        <span>{record?.paymentMethod || record?.withdrawMethod}</span>
      )}
    </div>
  )
}

export default PayoutInfo
