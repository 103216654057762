import { Logger } from '@upay/utils'

import { logger } from '@/utils/slardar'

export { default as usePostMessage } from './usePostMessage'
export { default as useNewWindow } from './useNewWindow'
export { default as usePostRecord } from './usePostRecord'
export { default as useRetry } from './useRetry'
export { default as usePayoutBindElements } from './usePayoutBindElements'
export * from './useFormModal'

export const hooksLogger = new Logger('SDK_HOOKS', logger)
