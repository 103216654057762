/**
 * TODO: 重新思考并改善视图跳转, action, ref 上实例方法的设计
 * 目前这个 hook 同时也承载了实例方法的功能，叫做 router 依然不太合适
 */
import React, { useState, useImperativeHandle } from 'react'
import { useMultiSelect } from '@byted/hooks'
import { PipoAction, RouterPath, PipoComponentRef, RouterParams, PipoSubmitParams } from '@/pipo/utils'
import { useValidateCallback } from './use-validate-callback'

export interface RefOptions {
  initPath?: RouterPath
  initParams?: RouterParams
  instanceMethods?: {
    submit?: () => void
    validateSubmitData?: () => Promise<PipoSubmitParams>
  }
}

export const usePipoRouter = (ref: React.ForwardedRef<PipoComponentRef>, options?: RefOptions) => {
  const { initPath = 'payment-methods', initParams = {}, instanceMethods = {} } = options || {}
  const [routerParams, setRouterParams] = useState<RouterParams>(initParams)
  const { isValid, onValidate } = initParams

  const {
    selected: [activePath],
    select: routerPush,
  } = useMultiSelect<RouterPath>(['payment-methods', 'pi-list', 'pi-detail', 'ccdc', 'boleto', 'pix', 'oxxo', 'voucher'], {
    isMulti: false,
    defaultSelected: [initPath],
  })

  // trigger onValidate callback
  useValidateCallback({
    ref,
    activePath,
    isValid,
    onValidate,
  })

  useImperativeHandle(ref, () => ({
    initPath,
    handleAction: (action: PipoAction) => {
      if (action.type === 'redirect') {
        const { url } = action.payload
        if (url) window.location.href = url
        return
      }

      const { path } = action.payload

      if (!path) return
      // fix: 通过handleAction第二次进入组件视图时，lodash.defaults会保留老的params，导致视图不会更新
      setRouterParams((oldParams) => ({
        ...oldParams,
        ...(action.payload.params || {}),
      }))

      routerPush(path)
    },
    ...instanceMethods,
  }))

  return { activePath, routerParams }
}
