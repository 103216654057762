import { useContext } from 'react'

import { IBindCardRes, PipoRequestErrorObj, StatusActionTypeEnum, StatusTypeEnum } from '@upay/utils/es/types'

import { IOpenStatusProps } from '@/components/Status/config'
import { FROM_PAGE_ENUM, PIPO_CODE } from '@/constant'
import { PipoSubmitParams } from '@/pipo/utils'
import GlobalInfo from '@/stores/GlobalInfo'

interface IProps {
  fromPage: FROM_PAGE_ENUM
  linkOpen: (url: string, currentPage?: string) => void
  handleBillStatus: (props: IOpenStatusProps) => void
}
export const useSubmitCCDC = (props: IProps) => {
  const { linkOpen, handleBillStatus, fromPage } = props
  const {
    PIPOApi: { bindCCDCAuthorizeAgreement, storePaymentMethod },
  } = useContext(GlobalInfo)

  const submitBind = async (bindCardRes: IBindCardRes, pipoSubmitParams: PipoSubmitParams) => {
    const { agreementId } = bindCardRes
    const actionType = StatusActionTypeEnum.UPDATE

    try {
      let submitResult
      if (agreementId) {
        submitResult = await bindCCDCAuthorizeAgreement({
          pipoSubmitParams,
          fromPage,
          bindCardRes,
        })
      } else {
        submitResult = await storePaymentMethod({ pipoSubmitParams, fromPage, bindCardRes })
      }

      const redirectUrl = submitResult?.redirect_details?.url
      if (submitResult.result_code === PIPO_CODE.REDIRECT && redirectUrl) {
        // 跳转3DS
        linkOpen(redirectUrl as string, 'CCDC')
      } else if ([PIPO_CODE.SUCCESS, PIPO_CODE.SUCCEED].includes(submitResult.result_code)) {
        // 直接展示成果
        handleBillStatus({ actionType, status: submitResult.result_code })
      } else {
        // 其他情况一律按失败处理，根据国际支付的错误info，调用接口得到错误详情
        handleBillStatus({
          actionType,
          status: StatusTypeEnum.FAILED as StatusTypeEnum,
          errMsg: submitResult.error_message,
          pipoErrorCode: submitResult.error_code,
        })
      }
    } catch (error: any) {
      handleBillStatus({
        actionType,
        status: StatusTypeEnum.FAILED as StatusTypeEnum,
        errMsg: (error as PipoRequestErrorObj).errorMessage || error.msg || error.message,
        pipoErrorCode: (error as PipoRequestErrorObj).errorCode,
      })
    }
  }

  return { submitBind }
}
