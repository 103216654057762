import React, { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useState } from 'react'

import { PAY_WAY_TYPE } from '@/constant'
import { BOBanktransferForm } from '@/pipo/pc'
import { INTERNETBANKINGFormData } from '@/pipo/utils'
import { ParamsContext } from '@/stores'
import utils from '@/utils'
import SafeJSON from '@/utils/SafeJSON'

import { IPaymentMethodsProps, IPaymentsMethodRef } from '../../interface'

import './style.less'

const BankTransferBo = forwardRef<IPaymentsMethodRef, IPaymentMethodsProps>((props, ref): React.ReactElement => {
  const { changeNewPaymentStatus } = props
  const {
    urlQuery: { token },
  } = useContext(ParamsContext)
  const [formValid, setFormValid] = useState(false)
  const [formData, setFormData] = useState<INTERNETBANKINGFormData>()

  // 同步验证状态
  useEffect(() => {
    changeNewPaymentStatus(PAY_WAY_TYPE.BANK_TRANSFER_BO, { valid: formValid })
  }, [formValid, changeNewPaymentStatus])

  useImperativeHandle(ref, () => ({
    // 一步支付走3M后端提交
    processSubmitData: async (submitParams) => {
      if (formData) {
        const [fullName, firstName, lastName, iban, nationalId] = await utils.fetchKeyAndEncryptStringArray(
          [
            formData.holder_first_name + ' ' + formData.holder_last_name,
            formData.holder_first_name!,
            formData.holder_last_name!,
            formData.iban!,
            formData.identity!,
          ],
          token,
        )
        const channelParameter = {
          fullName,
          firstName,
          lastName,
          nationalId,
          iban,
          encrypted: true,
        }
        submitParams.channelParameter = SafeJSON.stringify(channelParameter)
      }
      return submitParams
    },
  }))

  const handleValidate = useCallback((isValid: boolean) => {
    setFormValid(isValid)
  }, [])

  const handleChange = useCallback((data: INTERNETBANKINGFormData) => {
    setFormData(data)
  }, [])

  return (
    <div className="upay-banktrans-container">
      <BOBanktransferForm onValidate={handleValidate} onChange={handleChange} />
    </div>
  )
})

export default BankTransferBo
