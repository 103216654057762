import React, { forwardRef, useEffect, useState } from 'react'
import { Controller, ControllerFieldState, useForm } from 'react-hook-form'

import { PAY_WAY_TYPE } from '@/constant'
import { useAddressPC, usePipoRouter, usePostalCode, useSubmittingState, useTouchedErrors } from '@/pipo/hooks'
import {
  PaymentParamsItem,
  PipoComponentRef,
  PipoProvider,
  PipoSubmitParams,
  countryToISOCode,
  generateCommonPaymentParams,
  pickNumeric,
  validator,
} from '@/pipo/utils'
import starling from '@/utils/starling'
import teaClient from '@/utils/tea'

import { AccountType, HolderName, ItemInputNumber, ProtocolDirectDebitUS, SaveBox } from '../../inner-components'
import { BusinessAccount } from './BusinessAccount'
import { PersonalAccount } from './PersonalAccount'
import {
  DIRECT_DEBIT_B_D_PAYMENT_METHOD,
  DIRECT_DEBIT_B_D_PAYMENT_PARAMS_MAP,
  DIRECT_DEBIT_C_D_PAYMENT_METHOD,
  DIRECT_DEBIT_C_D_PAYMENT_PARAMS_MAP,
  DirectDebitFormData,
  DirectDebitProps,
} from './utils'
import { routingCodeRegex } from './validator'

const clsPrefix = 'pipo-bindcard-pc-direct-debit'

// TODO 待确认
export const DirectDebit = forwardRef((props: DirectDebitProps, ref: React.ForwardedRef<PipoComponentRef>) => {
  const {
    showSavingBox = true,
    contractAddressList,
    onFetchDistrict,
    onSubmit,
    onValidate = () => {
      // do nothing.
    },
  } = props
  const {
    control,
    watch,
    handleSubmit: handleFormSubmit,
    getValues,
    setValue,
    trigger,
    setError,
    clearErrors,
    formState: { errors: formErrors, isValid, touchedFields },
  } = useForm<DirectDebitFormData>({
    mode: 'all',
  })

  const errors = useTouchedErrors<DirectDebitFormData>({
    touchedFields,
    errors: formErrors,
  })

  const { state, t, getErrorText, getPublicKeyAsync } = PipoProvider.useContext()
  const { countryOrRegion = '', config = {} } = state
  const billingAddressProps = useAddressPC({
    onFetchDistrict,
    getValues,
    setValue,
    trigger,
    clearErrors,
    setError,
    watch,
    formErrors,
    countryOrRegion,
    contractAddressList: contractAddressList?.filter((address) => address.countryCode === 'US'),
  } as any)
  const { list, selectedAddressArr, selectedContractAddress, getFillAddressState } = billingAddressProps
  const postalCodeProps = usePostalCode({
    selectedAddressArr,
    watch,
    trigger,
  } as any)

  const { selectedSaveBox = false } = config

  const { formSubmitState } = useSubmittingState()

  // TODO: can be modified from outside
  const [storeCard, setStoreCard] = useState<boolean>(selectedSaveBox)

  useEffect(() => {
    isValid && onValidate(true)
  }, [isValid, onValidate])

  // Monitor select box value to render BusinessAccount or PersonalAccount
  const renderBusiness = watch('account_type') === '0'
  const renderPersonal = watch('account_type') === '1'

  /**
   * 处理PIPO提交所需数据
   * @returns PipoSubmitParams
   */
  const processSubmitData = async (formData: DirectDebitFormData): Promise<PipoSubmitParams> => {
    delete formData.account_type // 删除account_type
    delete formData.contract_address // 删除内部使用字段

    if (selectedContractAddress) {
      formData.addressline1 = selectedContractAddress.detailAddress
      formData.billing_country_region = selectedContractAddress.countryCode
      formData.billing_postal_code = selectedContractAddress.postcode
      formData.billing_state = selectedContractAddress.level2Name
      formData.billing_city = selectedContractAddress.level3Name
    } else if (formData.billing_country_region && list[0].list) {
      formData.billing_country_region = countryToISOCode(formData.billing_country_region, list[0].list)
    }

    if (formData.routing_no) formData.routing_no = pickNumeric(formData.routing_no)
    if (formData.account_no) formData.account_no = pickNumeric(formData.account_no)
    if (formData.identity) formData.identity = pickNumeric(formData.identity)
    // PM要求隐藏dd中的可选值addressLine2，此处在接口请求与以前保持一致以防万一。我理解隐藏是为了和ccdc保持一致，降低用户填写门槛。
    if (!renderBusiness) formData.addressline2 = undefined

    const paramsMap = renderBusiness ? DIRECT_DEBIT_B_D_PAYMENT_PARAMS_MAP : DIRECT_DEBIT_C_D_PAYMENT_PARAMS_MAP
    const method = renderBusiness ? DIRECT_DEBIT_B_D_PAYMENT_METHOD : DIRECT_DEBIT_C_D_PAYMENT_METHOD
    const publicKey = await getPublicKeyAsync()
    const paymentParams: PaymentParamsItem[] = generateCommonPaymentParams(formData, paramsMap, publicKey)
    const { paymentMethodType, paymentMethod } = method

    return {
      formData,
      paymentMethod: {
        paymentMethod: paymentMethod.paymentMethod,
        paymentMethodId: paymentMethod.paymentMethodId,
      },
      paymentMethodType: paymentMethodType.paymentMethodType,
      paymentParams,
      storeCard,
      fillAddressState: renderBusiness ? undefined : getFillAddressState(),
    }
  }

  /**
   * 校验后获取PIPO提交所需数据
   * @returns PipoSubmitParams
   */
  const validateSubmitData = () => {
    return new Promise(async (resolve: (value: PipoSubmitParams) => void, reject: (reason: typeof errors) => void) => {
      if (!isValid) {
        reject(errors)
      } else {
        const formData = getValues()
        resolve(await processSubmitData(formData))
      }
    })
  }

  const handleSubmit = async (formData: DirectDebitFormData): Promise<void> => {
    const submitData = await processSubmitData(formData)
    formSubmitState.setTrue()
    await onSubmit?.(submitData)
    formSubmitState.setFalse()
  }

  const { activePath, routerParams } = usePipoRouter(ref, {
    initPath: 'direct-debit',
    initParams: {
      isValid,
      onValidate,
    },
    instanceMethods: {
      submit: handleFormSubmit(handleSubmit),
      validateSubmitData,
    },
  })

  const handleBlur = (scenario: string, fieldState: ControllerFieldState) => {
    const params = {
      pay_way: PAY_WAY_TYPE.DIRECT_DEBIT,
      scenario,
      is_valid: !fieldState.error,
      invalid_reason: fieldState.error?.type,
    }
    teaClient.sendPayPageFillIn(params)
  }

  return (
    <div className="pipo-pc">
      <div className={`${clsPrefix}-flex-container`}>
        <div className={`${clsPrefix}-form-wrapper`}>
          <div className={`${clsPrefix}-subform-wrapper`}>
            <Controller
              rules={{ required: true }}
              name="account_type"
              defaultValue={'0'}
              control={control}
              render={({ field }) => <AccountType {...field} title={starling('funds.refund.comm.system_bankaccounttype')} />}
            />
            {renderBusiness && (
              <Controller
                rules={{ required: true, pattern: validator.nameRegex }}
                name="holder_name"
                control={control}
                render={({ field, fieldState }) => (
                  <HolderName
                    {...field}
                    len="full"
                    error={errors.holder_name}
                    errorMessage={getErrorText(errors.holder_name?.type)}
                    title={starling('funds.refund.comm.system_name')}
                    placeholder={starling('funds.refund.comm.system_name_description')}
                    maxLength={40}
                    onBlur={() => {
                      field.onBlur()
                      handleBlur('holder_name', fieldState)
                    }}
                  />
                )}
              />
            )}
            {renderPersonal && (
              <Controller
                rules={{
                  required: true,
                  pattern: validator.nameRegex,
                }}
                name="holder_first_name"
                control={control}
                render={({ field, fieldState }) => (
                  <HolderName
                    {...field}
                    error={errors.holder_first_name}
                    errorMessage={getErrorText(errors.holder_first_name?.type)}
                    // TODO:i18n
                    title={t('pipo_common_payin_first_name')}
                    placeholder={t('pipo_common_payin_ph_first_name')}
                    onBlur={() => {
                      field.onBlur()
                      handleBlur('holder_first_name', fieldState)
                    }}
                  />
                )}
              />
            )}
            {renderPersonal && (
              <Controller
                rules={{
                  required: true,
                  pattern: validator.nameRegex,
                }}
                name="holder_last_name"
                control={control}
                render={({ field, fieldState }) => (
                  <HolderName
                    {...field}
                    error={errors.holder_last_name}
                    errorMessage={getErrorText(errors.holder_last_name?.type)}
                    // TODO:i18n
                    title={t('pipo_common_payin_last_name')}
                    placeholder={t('pipo_common_payin_ph_last_name')}
                    onBlur={() => {
                      field.onBlur()
                      handleBlur('holder_last_name', fieldState)
                    }}
                  />
                )}
              />
            )}
            <Controller
              rules={{ required: true, pattern: routingCodeRegex }}
              name="routing_no"
              control={control}
              render={({ field, fieldState }) => (
                <ItemInputNumber
                  {...field}
                  error={errors.routing_no}
                  errorMessage={getErrorText(errors.routing_no?.type)}
                  title={starling('funds.refund.comm.system_bankaccount_routingcode_US')}
                  placeholder={starling('funds.refund.comm.system_bankaccount_routingcode_US_placeholder')}
                  maxLength={9}
                  onBlur={() => {
                    field.onBlur()
                    handleBlur('routing_no', fieldState)
                  }}
                />
              )}
            />
            <Controller
              rules={{ required: true }}
              name="account_no"
              control={control}
              render={({ field, fieldState }) => (
                <ItemInputNumber
                  {...field}
                  error={errors.account_no}
                  errorMessage={getErrorText(errors.account_no?.type)}
                  title={starling('funds.refund.comm.system_bankaccount_account_number')}
                  placeholder={starling('funds.refund.comm.system_bankaccount_account_number_placeholder')}
                  maxLength={17}
                  onBlur={() => {
                    field.onBlur()
                    handleBlur('account_no', fieldState)
                  }}
                />
              )}
            />
          </div>
          {renderBusiness && <BusinessAccount clsPrefix={clsPrefix} control={control} errors={errors} handleBlur={handleBlur} />}
          {renderPersonal && (
            <PersonalAccount
              clsPrefix={clsPrefix}
              control={control}
              errors={errors}
              billingAddressProps={billingAddressProps}
              postalCodeProps={postalCodeProps}
              handleBlur={handleBlur}
            />
          )}
          {/* Save Box */}
          {showSavingBox && (
            <SaveBox title={starling('funds.refund.comm.system_save_card_information')} storeCard={storeCard} setStoreCard={setStoreCard} />
          )}

          {renderBusiness && <ProtocolDirectDebitUS />}
        </div>
      </div>
    </div>
  )
})
