import { useContext } from 'react'

import { useRequest } from '@byted/hooks'

import { RESPONSE_CODE } from '@/constant'
import api from '@/services'
import { ParamsContext } from '@/stores'
import GlobalInfo from '@/stores/GlobalInfo'
import SafeJSON from '@/utils/SafeJSON'

interface IProps {
  cardId: string
  payWay: number
}
export const useRiskAmountInfo = (props: IProps) => {
  const { payWay, cardId } = props
  const {
    urlQuery: { token },
  } = useContext(ParamsContext)
  const {
    data: { riskInfoObj },
  } = useContext(GlobalInfo)

  const { data: hitAgreement, loading: getHitAgreementLoading } = useRequest(() => api.hitAgreement({ token }).then((res) => res?.data), {
    auto: true,
  })

  const { data: riskAmountInfo, loading: getRiskInfoLoading } = useRequest(
    (params: any) =>
      api.queryRiskAmount(params).then((res) => {
        if (res?.code !== RESPONSE_CODE.SUCCESS) {
          return Promise.reject(res?.msg || 'error')
        } else {
          return res?.data
        }
      }),
    {
      auto: true,
      ready: hitAgreement === true,
      defaultParams: [
        {
          token,
          payWay,
          cardId,
          riskInfo: SafeJSON.stringify(riskInfoObj),
        },
      ],
    },
  )

  return {
    riskAmountInfo,
    getRiskAmountLoading: getHitAgreementLoading || getRiskInfoLoading,
  }
}
