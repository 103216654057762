import React, { forwardRef, useEffect } from 'react'

interface IProps {
  value?: string
  onChange: (e?: string) => void
}

export const FixedInvisibleValue = forwardRef((props: IProps, ref) => {
  const { value, onChange } = props

  useEffect(() => {
    onChange?.(value)
  }, [value])

  return <div style={{ display: 'none' }}></div>
})
