import React, { useEffect, useState } from 'react'

import { Alert, Button } from '@arco-design/web-react'
import { toArray, uniq } from 'lodash'
import { extend } from 'umi-request'

import { RemindActionType, useJudgeVersion } from '@/hooks/useJudgeVersion'
import { Warning } from '@/static/icon'
import slardar from '@/utils/slardar'
import starling from '@/utils/starling'

import './index.less'

const pureFetch = extend({})
const pureFetchUrl = (url: string) =>
  pureFetch(url, {
    useCache: false,
    headers: {
      'cache-control': 'no-cache',
      pragma: 'no-cache',
    },
  })

type IProps = Parameters<typeof useJudgeVersion>[0]

const VersionReminder: React.FC<IProps> = (props) => {
  const { fromPage, pageVisible } = props
  const [loading, setLoading] = useState(false)

  const { showReminder, handleRead } = useJudgeVersion({ fromPage, pageVisible })
  const needFetch = showReminder
  const showAlert = showReminder || loading

  useEffect(() => {
    try {
      const scriptList = document.getElementsByTagName('script')
      const sdkScriptList = toArray(scriptList)
        .map((item) => item.src)
        .filter((item) => item.includes(`/upay.sdk.${ZONE_ENV}.js`))

      if (needFetch && sdkScriptList.length) {
        uniq(sdkScriptList).forEach((item) => {
          pureFetchUrl(item)
        })
      }
    } catch (e: any) {
      slardar.timer('sdkVersionReminderError', 1, {
        type: 'clearCache',
        msg: e?.msg || e?.message || 'error',
      })
    }
  }, [needFetch])

  const handleIgnore = () => {
    handleRead(RemindActionType.Ignore)
  }

  const handleRefresh = async () => {
    handleRead(RemindActionType.Refresh)

    setLoading(true) // 刷新一般比较慢，加个loading提示
    window.location.reload()
  }

  return (
    <>
      {showAlert && (
        <Alert
          className="version-reminder"
          icon={<Warning />}
          content={starling('funds.check_out.comm.sdk_update')}
          type="warning"
          style={{ marginBottom: '16px' }}
          action={
            <div className="reminder-footer">
              <Button size="mini" onClick={handleIgnore}>
                {starling('funds.check_out.comm.ignore')}
              </Button>
              <Button type="primary" size="mini" onClick={handleRefresh} loading={loading}>
                {starling('funds.check_out.comm.Refresh')}
              </Button>
            </div>
          }
        />
      )}
    </>
  )
}

export default VersionReminder
