import React from 'react'

const Error = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="100" height="98" viewBox="0 0 100 98" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0)">
        <path
          d="M68.209 49.126H19.401c-7.952 0-14.398 6.446-14.398 14.398V75.87c0 .795.645 1.44 1.44 1.44h74.723a1.44 1.44 0 0 0 1.44-1.44V63.524c0-7.952-6.447-14.398-14.398-14.398z"
          fill="#3955F6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M.985 23.056a2.286 2.286 0 0 0-.857 3.115l8.955 15.777a1.074 1.074 0 0 0 1.97-.245l1.47-5.343 11.7-6.65a2.286 2.286 0 0 0 .858-3.116L18.813 15.55a2.282 2.282 0 0 0-3.113-.858L.985 23.056zm10.348.958c-.394.225-.628.675-.588 1.342.014.223-.124.435-.342.478l-1.318.26a.379.379 0 0 1-.457-.32c-.146-1.282.189-2.802 1.644-3.63a3.237 3.237 0 0 1 4.415 1.218c.515.907.387 1.97.085 2.768-.262.692-.726 1.385-1.346 1.853-.178.134-.425.064-.535-.13l-.664-1.168c-.11-.194-.04-.444.109-.608.162-.18.318-.423.426-.708.182-.482.132-.812.057-.945a1.09 1.09 0 0 0-1.486-.41zm4.364 5.094a1.21 1.21 0 1 1-2.105 1.195 1.21 1.21 0 0 1 2.104-1.195z"
          fill="#8EB0EA"
        />
        <path
          d="M52.584 36.825l-2.35 13.309a5.14 5.14 0 0 1-1.46 2.813c-1.198 1.341-3.091 2.006-5.029 1.663-1.9-.335-3.416-1.602-4.154-3.286-.386-.956-.567-2.024-.37-3.136l2.35-13.308 11.013 1.945z"
          fill="#FA7AB1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M70.414 30.53c-3.8-3.884-9.72-5.215-17.318-2.703-2.53.836-5.405-.11-6.57-2.507L39.49 10.857c-1.166-2.396-.175-5.305 2.307-6.273 14.374-5.61 32.988-6.388 47.001 7.934 14.976 15.305 14.713 39.864-.588 54.855-8.916 8.734-21.598 10.507-31.766 9.448-8.805-.916-18.238-4.17-25.537-9.92-2.093-1.647-2.047-4.728-.143-6.593l11.49-11.257c1.904-1.865 5.005-1.831 7.37-.604 2.562 1.33 5.856 2.393 9.491 2.772 6.127.638 9.792-.954 11.1-2.236 5.148-5.043 5.237-13.305.199-18.453z"
          fill="#06D6DB"
        />
        <path
          d="M60.365 35.5c-.146.824-.357 1.6-.67 2.322-.14.383-.312.722-.49 1.098a9 9 0 0 1-.542.977c-.006.036-.042.03-.042.03a16.333 16.333 0 0 1-1.505 2.027c-2.893 3.188-7.294 4.852-11.813 4.054-4.484-.793-8.018-3.821-9.693-7.742-.34-.8-.631-1.665-.814-2.512l.006-.035-.12-.577a13.065 13.065 0 0 1 .04-4.21 9.54 9.54 0 0 1 .214-.998c.093-.316.192-.669.285-.985.068-.173.1-.352.196-.483.007-.036.013-.072.055-.102a.274.274 0 0 1 .061-.137c.074-.209.184-.411.294-.614l.013-.071c.067-.173.128-.31.225-.441l.007-.036c2.592-4.832 8.058-7.714 13.726-6.713l.035.007c.108.019.18.031.281.086A12.966 12.966 0 0 1 60.32 30.941c.01.15.028.264.074.42.023.078.017.114.004.186.03.042.024.078.01.15-.005.036.024.078.018.114-.007.035.03.042.03.042.023.078.01.15.033.228-.006.036.023.078.017.114l-.02.107c.028.264.043.6.028.893.01.15.019.626-.013.805-.015.293-.07.29-.084.584.057.305 0 .628-.051.915z"
          fill="#FA7AB1"
        />
        <path
          d="M39.065 32.107a4.42 4.42 0 0 1-4.234 3.654c-.32-1.537-.328-3.166-.037-4.816.24-1.363.69-2.652 1.3-3.802 2.06.697 3.351 2.811 2.971 4.964z"
          fill="#FA7AB1"
        />
        <path
          d="M34.83 35.76c-.292-.014-.586-.029-.873-.08-.036-.006-.072-.012-.101-.054-.186.004-.359-.064-.532-.131a4.4 4.4 0 0 1-2.898-4.951c.386-2.189 2.317-3.697 4.49-3.646.187-.004.438.04.617.072l.036.006c.18.032.352.1.532.131l.071.013-.006.036a2.676 2.676 0 0 0-.232.477c-.116.238-.268.47-.313.721-.232.477-.405 1.038-.536 1.57a9.545 9.545 0 0 0-.213.998 11.453 11.453 0 0 0-.041 4.839z"
          fill="#FA7AB1"
        />
        <path
          opacity=".36"
          d="M34.831 35.76a7.069 7.069 0 0 1-.873-.08c-.036-.006-.072-.012-.101-.055a12.547 12.547 0 0 1 .129-3.454c.323-1.83.938-3.423 1.74-4.613.103-.166.2-.297.297-.428l.072.013-.006.036a2.683 2.683 0 0 0-.233.477c-.116.238-.268.47-.312.721-.232.477-.405 1.038-.536 1.57a9.54 9.54 0 0 0-.214.998c-.291 1.65-.283 3.28.038 4.816z"
          fill="#000"
        />
        <path
          d="M64.606 36.618a4.432 4.432 0 0 1-4.419 3.659c-.186.004-.371.008-.544-.06-.043.03-.078.024-.114.017a6.516 6.516 0 0 1-.849-.224c.007-.035.042-.029.042-.029.172-.34.378-.673.55-1.013.17-.34.348-.715.49-1.097a13.818 13.818 0 0 0 .835-4.106c-.004-.186.022-.33.011-.48a7.456 7.456 0 0 0-.027-.892.517.517 0 0 0-.034-.228 2.688 2.688 0 0 0-.055-.527c.013-.072-.017-.114-.004-.186l.072.012c.186-.004.365.028.544.06l.036.006c.323.057.604.144.914.272 1.79.76 2.92 2.736 2.552 4.816z"
          fill="#FA7AB1"
        />
        <path
          opacity=".36"
          d="M60.675 36.886a12.542 12.542 0 0 1-1.062 3.29c-.042.029-.078.023-.114.016a6.516 6.516 0 0 1-.849-.224c.007-.035.043-.029.043-.029.17-.34.378-.673.549-1.013.17-.34.348-.715.49-1.097a13.82 13.82 0 0 0 .836-4.106c-.005-.186.02-.33.01-.48a7.455 7.455 0 0 0-.027-.892.517.517 0 0 0-.034-.228 2.688 2.688 0 0 0-.054-.527c.012-.072-.017-.114-.005-.186l.072.012c.076.199.11.427.18.661.267 1.416.275 3.045-.035 4.803z"
          fill="#000"
        />
        <path d="M49.44 22.583a9.398 9.398 0 1 0 3.27-18.51 9.398 9.398 0 0 0-3.27 18.51z" fill="#000340" />
        <path
          d="M60.481 32.116a8.993 8.993 0 0 1-3.18-.007c-4.448-.785-7.65-4.643-7.77-8.993-1.603 4.046-5.897 6.58-10.346 5.793-.322-.057-.639-.15-.962-.207-.842-.26-1.629-.62-2.367-1.047.067-.173.135-.346.232-.477l.006-.036c2.593-4.831 8.059-7.713 13.726-6.712l.036.007c.108.019.18.031.28.086 5.363 1.058 9.333 5.385 10.2 10.532.017.114.027.264.044.378a.328.328 0 0 1 .004.185c.023.078.04.192.028.264.059.084.046.156.07.234z"
          fill="#000340"
        />
        <path
          d="M44.27 38.206a.72.72 0 0 0-1.372.438l1.371-.438zm5.465 2.853a.72.72 0 1 0-.87-1.147l.87 1.147zm-6.151-2.634l-.686.22v.002l.002.003.003.01a1.44 1.44 0 0 0 .041.112 4.504 4.504 0 0 0 .652 1.105 4.257 4.257 0 0 0 2.625 1.544l.25-1.418a2.818 2.818 0 0 1-1.753-1.028 3.064 3.064 0 0 1-.434-.732 1.129 1.129 0 0 1-.015-.037l-.001-.005v.003l.001.001-.685.22zm2.637 2.996c1.158.204 1.993.15 2.566.023.286-.064.504-.145.66-.218a1.98 1.98 0 0 0 .285-.165l.002-.001c0-.001.001-.001-.434-.575a46.087 46.087 0 0 1-.435-.574h.001l.002-.002a.247.247 0 0 1 .017-.012l.002-.001a1.75 1.75 0 0 1-.413.143c-.364.08-1.006.14-2.003-.036l-.25 1.418z"
          fill="#000"
        />
        <path
          d="M83.235 65.524l.092-.071a3.266 3.266 0 0 1 4.589.596l2.139 2.777a3.266 3.266 0 0 1-.596 4.588l-.093.072a3.266 3.266 0 0 1-4.588-.596l-2.14-2.778a3.266 3.266 0 0 1 .597-4.588z"
          fill="#FA7AB1"
        />
        <path
          d="M77.958 69.588l.092-.072a3.266 3.266 0 0 1 4.589.596l2.139 2.778a3.266 3.266 0 0 1-.596 4.588l-.093.071a3.266 3.266 0 0 1-4.588-.596l-2.14-2.777a3.266 3.266 0 0 1 .597-4.588z"
          fill="#FA7AB1"
        />
        <path
          d="M72.68 73.652l.093-.072a3.266 3.266 0 0 1 4.589.596l2.139 2.778a3.266 3.266 0 0 1-.596 4.588l-.093.071a3.266 3.266 0 0 1-4.588-.596l-2.139-2.777a3.266 3.266 0 0 1 .596-4.588z"
          fill="#FA7AB1"
        />
        <path
          d="M82.045 69.39a.586.586 0 0 1 .82.106l2.375 3.037a.586.586 0 0 1-.106.82.586.586 0 0 1-.82-.107l-2.375-3.037a.586.586 0 0 1 .106-.82zM76.768 73.453a.586.586 0 0 1 .82.107l2.375 3.037a.586.586 0 0 1-.106.82.586.586 0 0 1-.82-.107l-2.375-3.037a.586.586 0 0 1 .106-.82z"
          fill="#000"
        />
        <path
          d="M11.107 73.182c5.549-5.429 13.829-4.753 19.022.554 5.309 5.426 5.91 13.838.367 19.262-5.543 5.424-14.06 4.757-19.369-.67-5.193-5.306-5.563-13.722-.02-19.146z"
          fill="#06D6DB"
        />
        <path
          d="M13.93 65.325l.097-.064a3.266 3.266 0 0 1 4.533.925l1.934 2.924a3.266 3.266 0 0 1-.925 4.534l-.098.064a3.266 3.266 0 0 1-4.533-.925l-1.934-2.924a3.266 3.266 0 0 1 .925-4.534z"
          fill="#FA7AB1"
        />
        <path
          d="M8.373 68.998l.098-.065a3.266 3.266 0 0 1 4.533.925l1.933 2.925a3.266 3.266 0 0 1-.925 4.533l-.097.065a3.266 3.266 0 0 1-4.534-.925L7.448 73.53a3.266 3.266 0 0 1 .925-4.533z"
          fill="#FA7AB1"
        />
        <path
          d="M2.817 72.67l.098-.064a3.266 3.266 0 0 1 4.533.925l1.933 2.925a3.266 3.266 0 0 1-.925 4.533l-.097.065a3.266 3.266 0 0 1-4.534-.925l-1.933-2.925a3.266 3.266 0 0 1 .925-4.533z"
          fill="#FA7AB1"
        />
        <path
          d="M12.464 69.095a.586.586 0 0 1 .81.165l2.15 3.2a.586.586 0 0 1-.165.81.586.586 0 0 1-.81-.165l-2.15-3.2a.586.586 0 0 1 .165-.81zM6.909 72.768a.586.586 0 0 1 .81.165l2.15 3.2a.586.586 0 0 1-.165.81.586.586 0 0 1-.81-.165l-2.15-3.2a.586.586 0 0 1 .165-.81z"
          fill="#000"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0h100v97.647H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Error
