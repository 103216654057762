import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

import { Shield, ShieldBg } from '@/static/icon'
import slardar from '@/utils/slardar'
import starling from '@/utils/starling'

import './index.less'

interface ILoadingProps {
  /**
   * 展示loading
   * @default false
   */
  show: boolean
  /**
   * 展示取消按钮
   * @default true
   */
  showBtn?: boolean
  /**
   * 取消按钮disabled
   * @default false
   */
  disabledCancel?: boolean
  /**
   * loading文案
   * @default 'Processing request. Please wait...'
   */
  loadingText?: string
  /**
   * 取消事件
   */
  onCancel?: () => void
  /**
   * 渐入效果
   * @default true
   */
  fadeIn?: boolean
}

const TRANSITION_TIME = 300

const Loading = (props: ILoadingProps): JSX.Element => {
  const {
    show = false,
    disabledCancel = false,
    onCancel,
    loadingText = starling('funds.refund.comm.not_return_request_result'),
    showBtn = true,
    fadeIn = true,
  } = props
  const [mounted, setMounted] = useState(true)
  const [entered, setEntered] = useState(false)

  useEffect(() => {
    // 渐入效果
    if (show) {
      setMounted(true)
      setTimeout(() => {
        setEntered(true)
      }, 0)
    } else {
      if (!fadeIn) {
        setMounted(false)
      } else {
        setTimeout(() => {
          setEntered(false)
        }, 0)
        setTimeout(() => {
          setMounted(false)
        }, TRANSITION_TIME)
      }
    }
  }, [fadeIn, show])

  const handleCancel = () => {
    slardar.timer('loadingCancelBtnClick')
    onCancel?.()
  }

  return mounted ? (
    ReactDOM.createPortal(
      <div className={`upay-loading ${fadeIn && !entered ? '' : 'entered'}`}>
        <ShieldBg className="upay-loading-shield-bg" />
        <div className="upay-loading-loader">
          <Shield className="upay-loading-loader-center upay-loading-loader-shield" />
          <div className="upay-loading-loader-center upay-loading-loader-inner line-spin-fade-loader">
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
          </div>
          <div className="upay-loading-text">{loadingText}</div>
          {showBtn && (
            <button className="upay-loading-btn" type="button" disabled={disabledCancel} onClick={handleCancel}>
              {starling('funds.refund.comm.cancel')}
            </button>
          )}
        </div>
      </div>,
      document.body,
    )
  ) : (
    <></>
  )
}

export default Loading
