import { PaymentMethodItem } from '../../types'

export const PAYPAY_PAYMENT_METHOD: PaymentMethodItem = {
  paymentMethodType: {
    configuration: {},
    icon: {
      iconUrl: '',
    },
    starling_key: 'pipo_common_ewallet',
    paymentMethodType: 'E_WALLET',
    title: {
      payment: 'pipo_common_ewallet',
    },
  },
  paymentMethod: {
    paymentMethod: 'PAYPAY',
    paymentMethodId: 'pm_pi_ew_paypay_c_r',
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/Paypay_icon_24608c.svg',
      grayIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/Paypay_icon_244fe3.svg',
    },
    displayName: 'PayPay',
    starling_key: 'pipo_payin_checkout_cashier_PayPay',
  },
}
