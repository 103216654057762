import React, { FC } from 'react'

import { Delete, Pin, Refresh } from '@icon-park/react'
import clsx from 'clsx'

import { CardListRes } from '@/services/types'
import { oneTimeHoc } from '@/utils/oneTimeHoc'
import starling from '@/utils/starling'

import { ManageCardTypeEnum } from '../../types'

import './index.less'

interface IProps {
  card: CardListRes
  allowUpdate: boolean
  onAction: (type: ManageCardTypeEnum) => void
}

const ActionModal: FC<IProps> = (props) => {
  const { card, allowUpdate = false, onAction } = props

  const { primary, valid } = card

  return (
    <div className="upay-manager-modal">
      <div
        className={clsx('upay-manager-modal-item', { 'upay-manager-modal-item-disable': primary || !valid })}
        onClick={() => {
          if (primary || !valid) return

          onAction(ManageCardTypeEnum.SetPrimary)
        }}
      >
        <Pin theme="outline" size="14" />
        <span>{starling('funds.refund.comm.make_primary')}</span>{' '}
      </div>

      {!!allowUpdate && (
        <div className="upay-manager-modal-item" onClick={() => onAction(ManageCardTypeEnum.EditCard)}>
          <Refresh theme="outline" size="14" />
          <span>{starling('funds.refund.comm.update')}</span>
        </div>
      )}

      <div className="upay-manager-modal-item" onClick={() => onAction(ManageCardTypeEnum.RemoveCard)}>
        <Delete theme="outline" size="14" />
        <span>{starling('funds.refund.comm.remove')}</span>
      </div>
    </div>
  )
}
export default oneTimeHoc(ActionModal)
