import React, { forwardRef, useEffect, useImperativeHandle, useMemo } from 'react'
import { Controller, ControllerFieldState, useForm } from 'react-hook-form'

import { Empty } from '@arco-design/web-react'
import { splitHolderName } from '@upay/utils/es/payout-utils'

import FormBuilder from '@/components/FormBuilder'
import { PAYOUT_WAY_TYPE } from '@/constant/payout'
import { useAddressPC, usePostalCode, useTouchedErrors } from '@/pipo/hooks'
import { AccountType } from '@/pipo/pc/inner-components'
import { PipoProvider, countryToISOCode, pickNumeric } from '@/pipo/utils'
import slardar from '@/utils/slardar'
import starling from '@/utils/starling'
import teaClient from '@/utils/tea'

import { PayoutComponentRef, PayoutSubmitParams } from '../../types'
import { getBankAccountFormConfig } from './config'
import { BankAccountFormData, BankAccountFormProps } from './utils'

const clsPrefix = 'pipo-bindcard-pc'

export const BankAccount = forwardRef((props: BankAccountFormProps, ref: React.ForwardedRef<PayoutComponentRef>) => {
  const { bankList, refreshBankList, onChange, cardHolderInfo, onFetchDistrict, contractAddressList, fetchContractAddress } = props
  const form = useForm<BankAccountFormData>({
    mode: 'all',
  })
  const {
    control,
    watch,
    getValues,
    setValue,
    trigger,
    clearErrors,
    setError,
    formState: { errors: formErrors, isValid, touchedFields },
  } = form
  const errors = useTouchedErrors<BankAccountFormData>({
    touchedFields,
    errors: formErrors,
  })

  const {
    state: { countryOrRegion = '' },
  } = PipoProvider.useContext()

  const billingAddressProps = useAddressPC({
    onFetchDistrict,
    getValues,
    setValue,
    trigger,
    clearErrors,
    setError,
    watch,
    formErrors,
    countryOrRegion,
    contractAddressList,
  } as any)
  const { selectedAddressArr, selectedContractAddress, list } = billingAddressProps
  const postalCodeProps = usePostalCode({
    selectedAddressArr,
    watch,
    trigger,
  } as any)

  // 在表单状态和勾选绑卡时触发onChange
  useEffect(() => {
    onChange?.({
      isValid,
      storeCard: true,
    })
  }, [isValid, onChange])

  const accountType = watch('account_type') === '0' ? 'b' : 'c'
  const formConfig = getBankAccountFormConfig({
    countryCode: countryOrRegion,
    bankList,
    cardHolderInfo,
    postalCodeProps,
    billingAddressProps,
  })

  // 银行列表联动刷新
  useEffect(() => {
    if (formConfig?.needInfo?.includes('bank_list')) {
      refreshBankList?.(accountType)
      setValue('bank_code', undefined)
    }
  }, [accountType])

  // 获取合同地址
  useEffect(() => {
    if (formConfig?.needInfo?.includes('contract_address_list')) {
      fetchContractAddress()
    }
  }, [])

  const getSubmitData = (): PayoutSubmitParams => {
    if (!formConfig) {
      throw new Error('Not Support')
    }
    const formData = getValues()
    const isBusiness = formData['account_type'] === '0'

    // 地址处理
    delete formData.contract_address // 删除内部使用字段
    if (selectedContractAddress) {
      formData.billing_street = selectedContractAddress.detailAddress
      formData.billing_country_region = selectedContractAddress.countryCode
      formData.billing_postal_code = selectedContractAddress.postcode
      formData.billing_state = selectedContractAddress.level2Name
      formData.billing_city = selectedContractAddress.level3Name
    } else if (formData.billing_country_region && list[0].list) {
      formData.billing_country_region = countryToISOCode(formData.billing_country_region, list[0].list)
    }

    // HolderName处理
    const [firstName, lastName] = splitHolderName(formData.holder_name, countryOrRegion)
    if (!isBusiness && !lastName) {
      slardar.timer('personalAccountWithoutLastName', 1, { payWay: PAYOUT_WAY_TYPE.BANK_ACCOUNT })
    }

    const postFormData = {
      ...formData,
      // TODO: 检查是否会有影响
      account_no: formData.account_no ? pickNumeric(formData.account_no) : undefined,
      identity: formData.identity ? pickNumeric(formData.identity) : undefined,
      holder_first_name: isBusiness ? undefined : firstName,
      holder_last_name: isBusiness ? undefined : lastName || firstName, // name不包空格时lastName = firstName，过接口验证
    }

    return {
      formData: postFormData,
      userType: isBusiness ? 'b' : 'c',
      bankCode: formData['bank_code'],
    }
  }

  useImperativeHandle(ref, () => ({
    getSubmitData,
  }))

  const handleBlur = (scenario: string, fieldState: ControllerFieldState) => {
    const params = {
      pay_way: PAYOUT_WAY_TYPE.BANK_ACCOUNT,
      scenario,
      is_valid: !fieldState.error,
      invalid_reason: fieldState.error?.type,
    }
    teaClient.sendPayoutPayPageFillIn(params)
  }

  if (!formConfig) {
    return (
      <div className="pipo-pc">
        <div className={`${clsPrefix}-flex-container`}>
          <Empty description="Sorry, the current scene is not supported for withdrawal" />
        </div>
      </div>
    )
  }

  const acceptAccountType = formConfig.allowAccountType || []
  const formSchema = accountType === 'c' && formConfig.toCFormSchema ? formConfig.toCFormSchema : formConfig.formSchema

  return (
    <div className="pipo-pc">
      <div className={`${clsPrefix}-flex-container`}>
        <div className={`${clsPrefix}-form-wrapper`}>
          <div className={`${clsPrefix}-subform-wrapper`}>
            <Controller
              rules={{ required: true }}
              name="account_type"
              defaultValue={'1'}
              control={control}
              render={({ field }) => (
                <AccountType {...field} title={starling('funds.refund.comm.system_bankaccounttype')} enableOption={acceptAccountType} />
              )}
            />

            <FormBuilder form={form} schema={formSchema} errors={errors} handleBlur={handleBlur} />
          </div>
        </div>
      </div>
    </div>
  )
})
