import React, { useContext, useEffect } from 'react'

import { useTimer } from '@byted/hooks'
import { StatusActionTypeEnum, StatusTypeEnum } from '@upay/utils/es/types'

import { oneTimeHoc } from '@/HOC/oneTimeHoc'
import { Status } from '@/components'
import { PUBSUB_TYPES } from '@/constant'
import { ParamsContext } from '@/stores'
import starling from '@/utils/starling'

interface IProps {
  onTryAgain: () => void
  onOk: () => void
  actionType?: StatusActionTypeEnum
  currentStatus: StatusTypeEnum
  errorMsg?: string
}
const TIMER_COUNT = 3 * 1000
const PayoutBindStatus: React.FC<IProps> = (props) => {
  const { actionType = StatusActionTypeEnum.BIND, currentStatus, errorMsg, onOk, onTryAgain } = props
  const { remainingTime, start: startCount } = useTimer<number>(TIMER_COUNT, {
    auto: false,
    onComplete: () => {
      onOk()
    },
  })
  const { uPubSub } = useContext(ParamsContext)

  const handleFinalResult = async (clickBtn = false) => {
    if (actionType !== StatusActionTypeEnum.BIND) return

    uPubSub.publish(PUBSUB_TYPES.STATUS_RESULT, {
      status: Number(currentStatus === StatusTypeEnum.SUCCESS),
      clickBtn: clickBtn,
      actionType: StatusActionTypeEnum.BIND,
      msg: errorMsg,
    })
  }

  const primaryButtonMap: Record<any, any> = {
    [StatusTypeEnum.SUCCESS]: {
      buttonText: starling('funds.refund.comm.close', {
        number: Math.ceil(remainingTime / 1000),
      }),
      handleClick: () => {
        handleFinalResult(true)
        onOk()
      },
      disabled: false,
    },
    [StatusTypeEnum.FAILED]: {
      buttonText: starling('funds.refund.comm.try_again'),
      handleClick: () => {
        handleFinalResult(true)
        onTryAgain()
      },
      disabled: false,
    },
  }

  useEffect(() => {
    currentStatus == StatusTypeEnum.SUCCESS && startCount()
    handleFinalResult()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Status
      visible
      actionType={actionType}
      status={currentStatus}
      showNeedHelp={false}
      errMsg={currentStatus === StatusTypeEnum.FAILED ? errorMsg : ''}
      primaryButtonInfo={primaryButtonMap[currentStatus]}
    />
  )
}

export default oneTimeHoc(PayoutBindStatus)
