import { FROM_PAGE_ENUM } from '@/constant'
import { IPaymentMethodsPropsBasic } from '@/payments/interface'
import { CardDetail } from '@/services/types'

export interface IPayNextProps {
  /** 是否打开 */
  visible?: boolean
  /**
   * 关闭Pay弹窗回调。回调入参为isSubmit-是否支付成功
   */
  onClose?: (isSubmit: boolean) => void
  from?: FROM_PAGE_ENUM
}

export enum PaymentSubmitMethodEnum {
  PAYMENT = 1,
  BIND = 2,
  DEPENDS = 3,
}

export interface IPayinMethodItem {
  agreementId: string
  allowUpdate: boolean
  payWay: number
  bindPayWay: number
  // 可以通过此值判断是否是已绑卡
  cardId: string
  paymentsTitle: string
  paymentsTips: string
  paymentsLogo: string
  platformLogo: string
  connectTitle: string
  currency: string
  disable: boolean
  needBindElements: boolean
  noPopUp: boolean
  disableTips: string
  upperBoundLimit: string
  identityType: string
  identityNo: string
  primary: boolean
  expireTime: string
  expired: boolean
  imageUrl: string
  needCvv: false
  payToken: string
  paymentMethodId: string
  riskAmount: string
  riskAmountCurrency: string
  displayRiskAmount: string
  supportPaymentAndBind: boolean
  supportBind: boolean
  paymentSubmitMethod: PaymentSubmitMethodEnum
  supportBalanceDisplay?: boolean
  // 异步查询的卡信息，仅在选中卡时展示
  cardDetails?: CardDetail
  supportBindAndPay: boolean
}

export interface IPIListObj {
  unfoldedPIList: IPayinMethodItem[]
  foldedPIList: IPayinMethodItem[]
}

export type Order = {
  amount: string
  charge_id: string
  currency: string
  charge_id_used?: boolean
  oneStep?: boolean
  /**
   * oneStep为true后 payment_reference不在此处返回
   * 一步支付在/init和/submit_order之后返回
   */
  payment_reference?: string
}

export interface IRenderPayinMethodProps {
  piList: IPayinMethodItem[]
  disable: boolean
  paymentsMethodPropsBasic: IPaymentMethodsPropsBasic
  cardDetailLoadingId?: string
}

export const PAYMENT_CODE_PAGE_REG = /wpay\/oversea\/(iframe|web|card)\/paymentcode/
