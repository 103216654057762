import { useContext, useEffect, useMemo, useRef } from 'react'

import { StatusActionTypeEnum, StatusTypeEnum } from '@upay/utils/es/types'

import { FROM_PAGE_ENUM, NEW_SDK_TERMINAL_EQUIP, PUBSUB_TYPES, SUBMIT_RECORD_TYPE } from '@/constant'
import { IPaymentCodeQuery } from '@/pages/PaymentCode/interface'
import api from '@/services'
import { IBindCardParams, IBindCardSupplementParams, ISubmitOrderParams } from '@/services/types'
import { ParamsContext } from '@/stores'
import GlobalInfo from '@/stores/GlobalInfo'
import utils, { CombinationEnum } from '@/utils'
import SafeJSON from '@/utils/SafeJSON'
import slardar from '@/utils/slardar'
import teaClient from '@/utils/tea'

import { payLogger } from '..'
import { PAYMENT_CODE_PAGE_REG } from '../interface'
import { PayNextModel } from '../model/usePayNext'

export const useSubmit = () => {
  const {
    uPubSub,
    urlQuery: { token, bizId },
    unique,
    initStartTime,
  } = useContext(ParamsContext)
  const {
    data: { riskInfoObj, sessionId, channelFingerprint, lastBindCardId },
    utils: { submitRecord },
  } = useContext(GlobalInfo)
  const {
    PID,
    loadStatus: { piListLoading, piListReady, cardDetailLoadingId },
    data: { visible, errorMsg, piPaymentStatus, selectedPayinMethod, isBindSubmit },
    func: { setVisible, setPaying, refreshPreOrder, setOrderChargeIdUsed, getPaymentRefByPIMethod, openPaymentCode, linkOpen, handleBillStatus },
  } = PayNextModel.useContext()

  const initTime = useRef(Date.now()) // 记录init的时间，给tea打点用
  useEffect(() => {
    if (!piListReady) return
    initTime.current = Date.now()
  }, [piListReady])

  // 支付按钮加载状态
  const submitBtnLoading = piListLoading || Boolean(cardDetailLoadingId)

  // 支付禁用态管理
  const disabled = useMemo(() => {
    // 以下情况需要禁用
    // 不显示时提交按钮置灰
    if (!visible) return true
    // 接口异常：禁用并提示toast
    if (errorMsg) return true
    // 缺少选中的支付方式
    if (!selectedPayinMethod) return true
    // 支付按钮处于加载状态
    if (submitBtnLoading) return true

    // 已有卡或新支付方式 未通过校验
    if (
      typeof piPaymentStatus[`${selectedPayinMethod.cardId || selectedPayinMethod.payWay}`]?.valid === 'boolean' &&
      !piPaymentStatus[`${selectedPayinMethod.cardId || selectedPayinMethod.payWay}`].valid
    )
      return true
    // 已有卡支付时，卡余额不足
    if (selectedPayinMethod?.cardDetails?.invalid || selectedPayinMethod?.cardDetails?.unbound) return true

    return false
  }, [visible, errorMsg, selectedPayinMethod, submitBtnLoading, piPaymentStatus])

  const handleSubmit = async () => {
    if (!selectedPayinMethod) return

    payLogger.scenes.paySubmitBtnClick({ selectedPiItem: selectedPayinMethod, isBindSubmit })
    teaClient.sendPayPageClick({
      pay_way: selectedPayinMethod.payWay,
      click_item: isBindSubmit ? 'verify_now' : 'make_payment',
      saved_pi: !!selectedPayinMethod.cardId,
      time_cost_to_bind: isBindSubmit ? Date.now() - initTime.current : undefined,
      time_cost_to_pay: isBindSubmit ? undefined : Date.now() - initTime.current,
    })

    setPaying(true)
    setVisible(false)
    uPubSub.publish(PUBSUB_TYPES.MODAL_SUBMIT)

    isBindSubmit ? bindSubmit() : paySubmit()
  }

  /**
   * 支付提交方法 submit_order
   */
  const paySubmit = async () => {
    if (!selectedPayinMethod) return

    // 渠道结果页面回调地址
    try {
      const newOrder = await refreshPreOrder()
      // 预付费 一个chargeId仅可提交一次，不管成功失败
      setOrderChargeIdUsed()

      // 支付时间统计埋点
      submitRecord({
        actionId: newOrder?.charge_id || '',
        actionType: SUBMIT_RECORD_TYPE.PAYMENT_DECISION_COST,
        payWay: selectedPayinMethod.payWay!,
        decision_cost: new Date().getTime() - initStartTime,
      })

      let submitParams = {
        token,
        payWay: typeof selectedPayinMethod.payWay === 'number' ? selectedPayinMethod.payWay : '',
        recordNo: newOrder?.charge_id,
        returnUrl: utils.getResultPath({ from: FROM_PAGE_ENUM.PAY_NEXT, actionType: StatusActionTypeEnum.PAY, uniqueId: unique }),
        riskInfo: SafeJSON.stringify(riskInfoObj),
        terminalEquip: NEW_SDK_TERMINAL_EQUIP,
        supportAgreementPaymentAndBind: true,
        saveActionByBackend: true,
      } as ISubmitOrderParams

      /**
       * 支付方式所需参数处理
       */

      // 已有卡
      if (selectedPayinMethod.cardId) {
        submitParams.cardId = selectedPayinMethod.cardId
      }

      // 由支付方式表单进行参数处理
      const selectedPaymentRef = getPaymentRefByPIMethod(selectedPayinMethod)
      if (selectedPaymentRef) {
        // 后续提交由此支付方式完全接管
        if (selectedPaymentRef.needHandleSubmit?.() && selectedPaymentRef.handleSubmit) {
          selectedPaymentRef.handleSubmit()
          return
        }

        // 支付方式仅对提交参数进行处理
        if (selectedPaymentRef.processSubmitData) {
          submitParams = await selectedPaymentRef.processSubmitData(submitParams)
        }
      }

      const res = await api.submitOrder(submitParams, {
        extraHandle: {
          uPubSub,
        },
        bizId,
      })
      const { url } = res.data || {}

      payLogger.scenes.payPageSubmitResultFetched({ selectedPiItem: selectedPayinMethod, isBindSubmit, submitRes: res.data!, logid: res.logid! })
      /**
       * 如支付方式需要接管后续流程
       * 需在内部提供needHandleResult&handleResult方法
       */

      if (selectedPaymentRef) {
        // 后续处理流程由此支付方式完全接管
        if (selectedPaymentRef.needHandleResult?.(res.data, submitParams) && selectedPaymentRef.handleResult) {
          selectedPaymentRef.handleResult(res.data, submitParams)
          return
        }
      }

      /**
       * 默认后续处理流程
       */
      if (url && PAYMENT_CODE_PAGE_REG.test(url)) {
        //回传的是paymentcode页面，使用sdk内方法打开
        const newQuery = utils.parseQueryString(url) || {}
        if (!newQuery.payway) {
          newQuery.payway = `${selectedPayinMethod.payWay}`
        }
        openPaymentCode({ query: newQuery as unknown as IPaymentCodeQuery })
      } else {
        // 第三方页面统一用window.open打开
        url ? linkOpen(url, PID) : handleBillStatus({ actionType: StatusActionTypeEnum.PAY, status: StatusTypeEnum.SUCCESS })
      }
    } catch (e: any) {
      payLogger.scenes.payPageSubmitError(e, { selectedPiItem: selectedPayinMethod, isBindSubmit })
      handleBillStatus({ actionType: StatusActionTypeEnum.PAY, status: StatusTypeEnum.FAILED, errMsg: e?.msg || e })
    }
  }

  /**
   * 绑卡提交方法 /card/bind
   */
  const bindSubmit = async () => {
    if (!selectedPayinMethod) return

    uPubSub.publish(PUBSUB_TYPES.MODAL_SUBMIT)
    setPaying(true)
    setVisible(false)
    try {
      let bindCardParams = {
        token,
        returnUrl: utils.getResultPath({
          from: FROM_PAGE_ENUM.PAY_NEXT,
          actionType: StatusActionTypeEnum.BIND,
          uniqueId: unique,
          combination: selectedPayinMethod.supportBindAndPay ? CombinationEnum.SUPPORT : CombinationEnum.NOT_SUPPORT,
        }),
        terminalEquip: NEW_SDK_TERMINAL_EQUIP,
        riskInfo: SafeJSON.stringify(riskInfoObj),
        riskAmount: selectedPayinMethod.riskAmount || undefined,
        payWay: selectedPayinMethod.bindPayWay,
        upaySessionId: sessionId.current,
        refId: channelFingerprint.current,
        supportRiskAmount: true,
      } as IBindCardParams

      // 由支付方式表单进行参数处理
      const selectedPaymentRef = getPaymentRefByPIMethod(selectedPayinMethod)
      if (selectedPaymentRef) {
        // 后续提交由此支付方式完全接管
        if (selectedPaymentRef.needHandleSubmit?.() && selectedPaymentRef.handleSubmit) {
          selectedPaymentRef.handleSubmit()
          return
        }

        // 支付方式仅对提交参数进行处理
        if (selectedPaymentRef.processSubmitData) {
          bindCardParams = await selectedPaymentRef.processSubmitData(bindCardParams)
        }
      }

      const bindCardRes = await api.bindCard(bindCardParams)

      // requestId 将整个绑卡流程串联起来
      const cardId = bindCardRes.data?.cardId
      if (cardId) {
        lastBindCardId.current = cardId
      }

      // 为后续补充绑卡提交准备参数，如paycell
      const bindCardSupplementParams = { ...bindCardParams, cardId } as IBindCardSupplementParams

      // 支付绑卡时间统计埋点
      submitRecord({
        upaySessionId: sessionId.current,
        actionId: cardId || '',
        refId: channelFingerprint.current,
        actionType: SUBMIT_RECORD_TYPE.BIND_DECISION_COST,
        payWay: selectedPayinMethod.bindPayWay,
        decision_cost: new Date().getTime() - initStartTime,
      })

      payLogger.scenes.payPageSubmitResultFetched({
        selectedPiItem: selectedPayinMethod,
        isBindSubmit,
        submitRes: bindCardRes.data!,
        logid: bindCardRes.logid!,
      })

      /**
       * 如支付方式需要接管后续流程
       * 需在内部提供needHandleResult&handleResult方法
       */
      if (selectedPaymentRef) {
        // 后续处理流程由此支付方式完全接管
        if (selectedPaymentRef.needHandleResult?.(bindCardRes.data, bindCardSupplementParams) && selectedPaymentRef.handleResult) {
          selectedPaymentRef.handleResult(bindCardRes.data, bindCardSupplementParams)
          return
        }
      }

      // 标准处理流程
      // url 是要跳转至国际支付提供的页面的链接
      if (bindCardRes.data?.url) {
        linkOpen(bindCardRes.data.url, PID)
      } else {
        handleBillStatus({
          actionType: StatusActionTypeEnum.BIND,
          status: StatusTypeEnum.SUCCESS,
        })
      }
    } catch (e: any) {
      payLogger.scenes.payPageSubmitError(e, { selectedPiItem: selectedPayinMethod, isBindSubmit })
      handleBillStatus({ actionType: StatusActionTypeEnum.BIND, status: StatusTypeEnum.FAILED, errMsg: e?.msg || e?.message || e })
    }
  }

  return { submitBtnLoading, disabled, handleSubmit }
}
