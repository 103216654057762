import React, { useContext } from 'react'

import { Skeleton } from '@arco-design/web-react'

import { ParamsContext } from '@/stores'
import starling from '@/utils/starling'

interface IProps {
  billInfo?: any
}
const TotalAmount: React.FC<IProps> = (props) => {
  const { billInfo } = props
  const { params } = useContext(ParamsContext)

  return (
    <>
      {!params.hideTitle && (
        <div className="upay-modal-header">
          <div className="upay-modal-header-title">{starling('funds.refund.comm.total_withdraw_amount')}</div>
          <div className="upay-modal-header-total">
            <Skeleton loading={!billInfo} animation text={{ width: '140px', rows: 1, className: 'upay-modal-header-amount-skeleton' }}>
              <span>{billInfo?.displayAmount}</span> <span className="upay-modal-header-currency">{billInfo?.currency}</span>
            </Skeleton>
          </div>
        </div>
      )}
    </>
  )
}

export default TotalAmount
