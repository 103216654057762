"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault")["default"];
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.getPaymentCodeScenes = getPaymentCodeScenes;
var _omit2 = _interopRequireDefault(require("lodash/omit"));
function getPaymentCodeScenes() {
  var _this = this;
  return {
    paymentCodePageView: function paymentCodePageView(params) {
      _this.info('paymentCodePageView', params);
    },
    /** 支付码页点击 */
    paymentCodeDataInit: function paymentCodeDataInit(params) {
      _this.info("paymentCodeDataInit", params);
    },
    paymentCodePageClick: function paymentCodePageClick(params) {
      _this.info("paymentCodePageClick_".concat(params.clickItem), (0, _omit2["default"])(params, ['clickItem']));
    }
  };
}