import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'

import { useStateRealtime } from '@byted/hooks'

import { NEW_SDK_TERMINAL_EQUIP } from '@/constant'
import useExcitedPaymentsMethod from '@/payments/ExcitedPayments/useExcitedPaymentsMethod'
import useNewPaymentsMethod from '@/payments/NewPayments/useNewPaymentsMethod'
import { IPaymentMethodsPropsBasic } from '@/payments/interface'
import paymentChannelPreHandler from '@/payments/paymentsPrehandler'
import api from '@/services'
import { ParamsContext } from '@/stores'
import GlobalInfo from '@/stores/GlobalInfo'
import SafeJSON from '@/utils/SafeJSON'
import teaClient from '@/utils/tea'

import { payLogger } from '..'
import { IPIListObj, IPayinMethodItem, PaymentSubmitMethodEnum } from '../interface'
import { useCardDetail } from './useCardDetail'

interface IUsePayinMethodListProps {
  errorMsg: string
  setErrorMsg: React.Dispatch<React.SetStateAction<string>>
  trackSelectChannel: (payWay: number) => void
}

export const usePayinMethodList = ({ errorMsg, setErrorMsg, trackSelectChannel }: IUsePayinMethodListProps) => {
  const {
    urlQuery: { token, bizId },
    uPubSub,
  } = useContext(ParamsContext)
  const {
    data: { riskInfoObj, lastBindCardId },
  } = useContext(GlobalInfo)
  const [selectedPayinMethod, setSelectedPayinMethod, getSelectedPayinMethod] = useStateRealtime<IPayinMethodItem>()
  const [piListLoading, setPIListLoading] = useState(true)
  // 完成首次初始化
  const [piListReady, setPIListReady] = useState(false)
  const [piListObj, setPIListObj] = useState<IPIListObj>({ unfoldedPIList: [], foldedPIList: [] })
  const [currentPIListKey, setCurrentPIListKey] = useState<keyof IPIListObj>('unfoldedPIList')
  // 标记用户是否已选择支付方式
  const userHasSelected = useRef(false)

  // 部分支付方式需要查询卡详情
  const { cardDetailLoadingId, getCardDetail } = useCardDetail()

  // 使用已绑卡支付方式
  const {
    getExcitedPaymentRefByCard,
    renderExcitedPaymentsExtra,
    valid: { excitedPaymentStatus, clearExcitedPaymentStatus },
  } = useExcitedPaymentsMethod()

  // 使用新支付方式
  const {
    renderPaymentsExtra,
    getPaymentRefByPayWay,
    valid: { newPaymentStatus, clearNewPaymentStatus },
  } = useNewPaymentsMethod()

  // 选择支付方式
  const handleSelectPayinMethod = useCallback(
    async (method?: IPayinMethodItem, selectByUser = true) => {
      if (method) {
        payLogger.scenes.payPiSelected({ selectedPiItem: method, autoSelect: !selectByUser })
        teaClient.sendPayPageClick({ pay_way: method.payWay, click_item: method.cardId ? 'pi_choose' : 'sec_pi_choose' })
        // 只有在选择的渠道是绑卡渠道的时候进行打点,Depends 选择默认走绑卡
        if (method.paymentSubmitMethod === PaymentSubmitMethodEnum.BIND || method.paymentSubmitMethod === PaymentSubmitMethodEnum.DEPENDS) {
          trackSelectChannel(method.payWay)
        }
      }

      setSelectedPayinMethod(method)
      selectByUser && (userHasSelected.current = true)
      // 部分卡支付需要先获取卡信息
      if (method?.cardId && method.supportBalanceDisplay) {
        const cardDetails = await getCardDetail(method.cardId)
        setSelectedPayinMethod((preSelectedCard) => {
          if (preSelectedCard?.cardId === method.cardId) {
            return { ...method, cardDetails }
          } else {
            return preSelectedCard
          }
        })
      }
    },
    [getCardDetail, setSelectedPayinMethod, trackSelectChannel],
  )

  /******
   * Effects
   */

  // 指定默认支付方式
  useEffect(() => {
    if (piListReady) {
      // 有卡，则取第一个有效卡选中
      // 其他情况，不默认选
      if (errorMsg) {
        handleSelectPayinMethod(undefined, false)
        return
      }

      // 目前仅在未折叠页加入默认选中逻辑
      if (currentPIListKey === 'unfoldedPIList') {
        const validCards = piListObj.unfoldedPIList.filter((i) => i.cardId && !i.disable)

        if (validCards.length > 0) {
          const firstValidCard = validCards[0]
          //如果存在 Request ID 则代在收银台页面新增了一张卡，需要自动选择到这张卡
          if (lastBindCardId.current) {
            const newCard = validCards.find((card) => card.cardId === lastBindCardId.current)
            if (newCard) {
              handleSelectPayinMethod(newCard, false)
              return
            }
          }
          // 首次进入时默认选择，当用户已选择过支付方式，不再推荐
          if (!userHasSelected.current && firstValidCard) {
            handleSelectPayinMethod(firstValidCard, false)
          }
        }
        return
      }
    }
  }, [currentPIListKey, errorMsg, handleSelectPayinMethod, lastBindCardId, piListObj, piListReady])

  /******
   * 向外提供的工具类方法
   */

  const refreshPIList = async () => {
    setPIListLoading(true)
    api
      .getPIPaymentList(
        {
          token,
          riskInfo: SafeJSON.stringify(riskInfoObj),
          terminalEquip: NEW_SDK_TERMINAL_EQUIP,
        },
        {
          extraHandle: {
            uPubSub,
          },
          bizId,
        },
      ) // 1-支付 2-绑卡
      .then(async (res) => {
        const { unfoldedPIList = [], foldedPIList = [] } = res.data || {}
        //只对渠道做处理 已绑卡忽略
        const availableChannels = await paymentChannelPreHandler([...unfoldedPIList, ...foldedPIList].filter((piItem) => !piItem.cardId))
        const availablePayways = availableChannels.map((item) => item.payWay)
        const newData = {
          unfoldedPIList: unfoldedPIList.filter((item) => item.cardId || availablePayways.includes(item.payWay)),
          foldedPIList: foldedPIList.filter((item) => availablePayways.includes(item.payWay)),
        }
        const savedPINum = newData.unfoldedPIList.filter((item) => !!item.cardId).length
        teaClient.sendPayPageView({ saved_pi_num: savedPINum, other_pi_num: newData.unfoldedPIList.length - savedPINum })

        setPIListObj(newData)
        setPIListLoading(false)
        setPIListReady(true)
        // 列表刷新后，同步刷新选中对象的内容，防止出现与最新内容不同步的情况
        setSelectedPayinMethod((pre) => {
          if (!pre) return undefined

          const newSelectedFromBE = [...unfoldedPIList, ...foldedPIList].filter((method) => {
            return pre.cardId ? method.cardId === pre.cardId : !method.cardId && method.payWay === pre.payWay
          })[0]
          return newSelectedFromBE ? { ...pre, ...newSelectedFromBE } : undefined
        })

        payLogger.scenes.payPMListFetched({
          piPaymentListRes: newData,
          originPiPaymentListRes: res.data,
          logid: res.logid || '',
        })
      })
      .catch((e) => {
        payLogger.fatal('PI payment list fetch error', e, { scene: 'payPMListFetched' })
        setErrorMsg(e?.msg || SafeJSON.stringify(e))
      })
  }

  const getPaymentRefByPIMethod = (method: IPayinMethodItem) => {
    if (method.cardId) {
      return getExcitedPaymentRefByCard(method)
    } else {
      return getPaymentRefByPayWay(method.payWay)
    }
  }

  const handleChangePIList = useCallback(
    (piListKey: keyof IPIListObj) => {
      handleSelectPayinMethod(undefined)
      userHasSelected.current = false
      setCurrentPIListKey(piListKey)
    },
    [handleSelectPayinMethod],
  )

  const clearPayinMethods = useCallback(() => {
    setPIListReady(false)
    setPIListObj({ unfoldedPIList: [], foldedPIList: [] })
    setCurrentPIListKey('unfoldedPIList')
    handleSelectPayinMethod(undefined)
    userHasSelected.current = false

    clearExcitedPaymentStatus()
    clearNewPaymentStatus()
  }, [clearExcitedPaymentStatus, clearNewPaymentStatus, handleSelectPayinMethod])

  const piPaymentStatus = {
    ...excitedPaymentStatus,
    ...newPaymentStatus,
  }

  const renderPayinMethodExtra = useCallback(
    (method: IPayinMethodItem, paymentPropsBasic: IPaymentMethodsPropsBasic) => {
      return method.cardId ? renderExcitedPaymentsExtra(method, paymentPropsBasic) : renderPaymentsExtra(method, paymentPropsBasic)
    },
    [renderExcitedPaymentsExtra, renderPaymentsExtra],
  )

  return {
    piListObj,
    piPaymentStatus,
    selectedPayinMethod,
    piListLoading,
    cardDetailLoadingId,
    piListReady,
    currentPIListKey,
    refreshPIList,
    renderPayinMethodExtra,
    handleSelectPayinMethod,
    getPaymentRefByPIMethod,
    clearPayinMethods,
    handleChangePIList,
    getSelectedPayinMethod,
  }
}
