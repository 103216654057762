import React, { useState } from 'react'

import { Message } from '@arco-design/web-react'
import { useClipboardCopy } from '@byted/hooks'
import { Copy } from '@icon-park/react'

import starling from '@/utils/starling'

import './index.less'

const classPrefix = 'upay-voucher-paymentdetail-card'

export interface IDetailInfoItem {
  title: string
  content?: string
  notice?: string | React.ReactNode
}

interface IProps {
  infoList: IDetailInfoItem[]
}

export const PaymentDetailInfo = ({ infoList }: IProps) => {
  const [showCopyKey, setShowCopyKey] = useState<number | null>(null)
  const { copy } = useClipboardCopy({
    onSuccess() {
      Message.success(starling('funds.check_out.comm.toast_copied'))
    },
  })

  return (
    <div className={`${classPrefix}`}>
      <div className={`${classPrefix}-title`}>{starling('funds.check_out.comm.payment_details')}</div>
      {infoList.map((item, index) => (
        <div className={`${classPrefix}-item-container`} key={index}>
          <div className={`${classPrefix}-item-info`}>
            <div className={`${classPrefix}-item-info-title`}>{item.title}</div>
            <div
              className={`${classPrefix}-item-info-content`}
              onClick={() => copy(item.content)}
              onMouseEnter={() => setShowCopyKey(index)}
              onMouseLeave={() => setShowCopyKey(null)}
            >
              {item.content}
              {showCopyKey === index && <Copy size={14} theme="outline" title="Copy" />}
            </div>
          </div>
          {item.notice && <div className={`${classPrefix}-item-tip`}>{item.notice}</div>}
        </div>
      ))}
    </div>
  )
}
