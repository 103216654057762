import React, { ForwardedRef, forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import { FieldError } from 'react-hook-form'

import { useControlled } from '@byted/hooks'
import { CardLogoPlaceHolder as CardLogoPlaceHolderIcon } from '@pipo-lib/icon'

import { InputHandleRef, formatCursor, formatValue, ibanValidInput } from '@/pipo/utils'

import { CustomInput, ItemBlock } from '../index'

interface IProps {
  error?: FieldError
  value?: string
  title?: string
  maxLength?: number
  placeholder?: string
  errorMessage?: string
  onChange: (val: string) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void
  bankList: string[]
  setDisplayBankIcon: (
    val: string,
    supportBank: {
      code: string
      icon: string
    }[],
  ) => string
  supportBank: {
    code: string
    icon: string
  }[]
}

// eslint-disable-next-line react/display-name
export const Iban = forwardRef((props: IProps, ref: ForwardedRef<HTMLInputElement>) => {
  const { title, placeholder, maxLength = 32, error, errorMessage, onBlur, bankList, setDisplayBankIcon, supportBank } = props
  const [value, onChange] = useControlled<string>(props)
  const [currentCardBrand, setCurrentCardBrand] = useState('')

  const bankIbanHandleRef = useRef<InputHandleRef>()

  useEffect(() => {
    bankIbanHandleRef.current?.setInnerValue(formatValue(value))
    onChange(value)
  }, [value])

  return (
    <ItemBlock style="half" title={title} errorState={Boolean(error)} errorMessage={errorMessage}>
      <CustomInput
        {...props}
        ref={ref}
        handleRef={bankIbanHandleRef}
        allowClear
        size="large"
        error={Boolean(error)}
        placeholder={placeholder}
        maxLength={maxLength}
        prefix={
          currentCardBrand ? (
            <img className={`pipo-pc-card-logo ${currentCardBrand ? '' : 'hidden'}`} src={currentCardBrand} />
          ) : (
            <CardLogoPlaceHolderIcon className="pipo-pc-card-logo" />
          )
        }
        value={value}
        onChange={(val) => {
          onChange(val)
          setCurrentCardBrand(setDisplayBankIcon(val, supportBank))
        }}
        onBlur={onBlur}
        validInput={ibanValidInput}
        formatValue={formatValue}
        formatCursor={formatCursor}
      />
    </ItemBlock>
  )
})
