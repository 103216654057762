import { PaymentMethodItem } from '../../types'

export const AMBANK_PAYMENT_METHOD: PaymentMethodItem = {
  paymentMethodType: {
    configuration: {},
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/InternetBanking-light_a666ca.png',
    },
    starling_key: 'pipo_payin_cashier_dropin_payment_internetbanking',
    paymentMethodType: 'INTERNET_BANKING',
    displayName: 'Internet Banking',
    title: {
      payment: 'pipo_payin_cashier_dropin_payment_internetbanking',
    },
  },
  paymentMethod: {
    configuration: {
      png_icon_url: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/AMBank_c31ebb.png',
      dark_mode_png_icon_url: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/AMBank-dark_54aba1.png',
    },
    paymentMethod: 'AMBANK',
    paymentMethodId: 'pm_pi_ib_ambank_c_r',
    displayName: 'AmBank',
    starling_key: '',
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/AMBank_c31ebb.png',
      pngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/AMBank_c31ebb.png',
      darkModePngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/AMBank-dark_54aba1.png',
    },
  },
}
