import React, { useContext, useEffect, useState } from 'react'

import { Skeleton, Spin } from '@arco-design/web-react'
import { useRequest } from '@byted/hooks'

import { PAY_WAY_TYPE } from '@/constant'
import { VoucherBoardData } from '@/pipo/hooks/use-ui-voucher'
import api from '@/services'
import { ParamsContext } from '@/stores'
import starling from '@/utils/starling'

import './index.less'

const classPrefix = 'pipo-pc-voucher-jp-banktransfer'
interface JPBankTransferCodeProps {
  data: VoucherBoardData
  extraInfoUrl: string
  currency: string
}

const JPBankTransferCode = (props: JPBankTransferCodeProps) => {
  const { data, extraInfoUrl, currency } = props
  const {
    type,
    paymentMethodInfo: { paymentMethodId, bankId },
    shopperDetails,
    simple,
    hint,
  } = data

  return (
    <div className={classPrefix}>
      <div className={`${classPrefix}-dashboard `}>
        <div className={`${classPrefix}-dashboard-left`}>
          <span>{starling('funds.refund.comm.total_amount')}</span>
        </div>
        {type === 'simple' && (
          <div className={`${classPrefix}-dashboard-right`}>
            <div className={`${classPrefix}-dashboard-right-amount`} />
            <div className={`${classPrefix}-dashboard-right-no`}>
              <span> {currency} : </span>
              <span>{simple.amount}</span>
            </div>
          </div>
        )}
      </div>
      {extraInfoUrl && <iframe className={`${classPrefix}-iframe`} src={extraInfoUrl} title="https://komoju.com/" />}
    </div>
  )
}

export { JPBankTransferCode }
