import React from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'

import { PipoProvider, validator } from '@/pipo/utils'
import starling from '@/utils/starling'

import { Email } from '../../inner-components'

export const BoletoUpdate = (props?: { form?: UseFormReturn }) => {
  const { form = {} } = props || {}
  const {
    control,
    formState: { errors },
  } = form as UseFormReturn<{ email: string }>
  const { getErrorText } = PipoProvider.useContext()

  return (
    <>
      <div className="pipo-bindcard-pc-subtitle" style={{ fontWeight: 400 }}>
        {starling('cg.upay.comm.update_boleto_instruction')}
      </div>

      <Controller
        rules={{ required: true, pattern: validator.emailRegex }}
        name="email"
        control={control}
        render={({ field }) => (
          <Email
            {...field}
            blockStyle="full"
            title={starling('funds.refund.comm.system_email')}
            placeholder={starling('funds.refund.comm.system_email_placeholder')}
            error={errors.email}
            errorMessage={getErrorText(errors.email?.type)}
          />
        )}
      />
    </>
  )
}
