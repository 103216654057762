import { useContext, useEffect, useState } from 'react'

import { FROM_PAGE_ENUM, PUBSUB_TYPES } from '@/constant'
import { ParamsContext } from '@/stores'
import { ModeTypeEnum } from '@/typings'

import { IPayNextProps } from '../interface'

/** 同步外层传入的显隐状态 */
export const usePayNextVisible = ({ visible: propsVisible, from }: IPayNextProps) => {
  const { params, uPubSub } = useContext(ParamsContext)
  const defaultVisible = propsVisible ?? params.defaultVisible
  const [visible, setVisible] = useState(defaultVisible)
  const isIndependentPage = from === FROM_PAGE_ENUM.PAY_NEXT && params.mode === ModeTypeEnum.HostedPage

  useEffect(() => {
    setVisible(defaultVisible)
  }, [defaultVisible])

  useEffect(() => {
    /** 告知业务方已经初始化完成 并监听业务方 OPEN_MODAL 事件 */
    const subId = uPubSub.subscribe(PUBSUB_TYPES.OPEN_MODAL, () => setVisible(true))
    uPubSub.publish(PUBSUB_TYPES.DID_MOUNT)
    return () => uPubSub.unsubscribe(subId)
  }, [uPubSub])

  return { visible, isIndependentPage, setVisible }
}
