import React from 'react'

const Info = (): JSX.Element => {
  return (
    <svg width="24" height="24" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
      <g>
        <g>
          <rect fillOpacity="0.01" fill="#FFFFFF" x="0" y="0" width="48" height="48" strokeWidth="4" stroke="none" fillRule="evenodd" />
          <path
            d="M24,44 C29.5228475,44 34.5228475,41.7614237 38.1421356,38.1421356 C41.7614237,34.5228475 44,29.5228475 44,24 C44,18.4771525 41.7614237,13.4771525 38.1421356,9.85786438 C34.5228475,6.23857625 29.5228475,4 24,4 C18.4771525,4 13.4771525,6.23857625 9.85786438,9.85786438 C6.23857625,13.4771525 4,18.4771525 4,24 C4,29.5228475 6.23857625,34.5228475 9.85786438,38.1421356 C13.4771525,41.7614237 18.4771525,44 24,44 Z"
            stroke="#3451f7"
            strokeWidth="4"
            fill="#3451f7"
            fillRule="nonzero"
            strokeLinejoin="round"
          />
          <g transform="translate(24.500000, 23.000000) scale(1, -1) translate(-24.500000, -23.000000) translate(21.000000, 11.000000)">
            <g>
              <circle fill="#FFF" cx="3" cy="21.5" r="2.5" strokeWidth="4" stroke="none" fillRule="evenodd" />
              <g transform="translate(0.000000, 0.500000)">
                <polyline
                  points="3.5 0.5 3.5 14.5 2.5 14.5 1.5 14.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="4"
                  stroke="#FFF"
                  fill="none"
                  fillRule="evenodd"
                />
                <path d="M0,0.5 L7,0.5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" stroke="#FFF" fill="none" fillRule="evenodd" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Info
