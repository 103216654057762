"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault")["default"];
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.getBaseScenes = getBaseScenes;
var _filter = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/filter"));
var _includes = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/includes"));
var _slice = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/slice"));
var _objectSpread2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/objectSpread2"));
var _omit2 = _interopRequireDefault(require("lodash/omit"));
var _apiLevel = require("../config/apiLevel");
function getBaseScenes() {
  var _this = this;
  return {
    fetchApiCount: function fetchApiCount(params) {
      var _context2, _context3;
      var apiLevelItem = (0, _filter["default"])(_apiLevel.API_LEVEL_CONFIG).call(_apiLevel.API_LEVEL_CONFIG, function (i) {
        var _context;
        return (0, _includes["default"])(_context = params.fetchUrl).call(_context, i.url);
      })[0] || {
        url: '',
        level: 'P2'
      };
      _this.info('fetchApiCount', (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, (0, _omit2["default"])(params, ['res'])), {}, {
        level: apiLevelItem.level,
        status: String(params.status),
        code: String(params.code)
      }));
      // 通过log上报请求返回值的详细内容
      var shortUrl = (0, _slice["default"])(_context2 = (0, _filter["default"])(_context3 = (params.fetchUrl || '').replace(/\?.*/, '').split('/')).call(_context3, Boolean)).call(_context2, -2).join('/');
      var isError = params.status !== 200 || typeof params.code === 'number' && params.code !== 0 || !params.res;
      _this.log("fetchApiLog_".concat(shortUrl), (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, params), {}, {
        apiLevel: apiLevelItem.level
      }), isError ? 'error' : 'info');
    }
  };
}