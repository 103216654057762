"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault")["default"];
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.formatUpdateInfoList = void 0;
var _filter = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/filter"));
var _map = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/map"));
var formatUpdateInfoList = function formatUpdateInfoList(paramsList, paramsMap) {
  var _context;
  return (paramsList === null || paramsList === void 0 ? void 0 : (0, _filter["default"])(_context = (0, _map["default"])(paramsList).call(paramsList, function (item) {
    return {
      title: paramsMap[item.paramName],
      value: item.paramValue
    };
  })).call(_context, function (item) {
    return item.title && (item.value || item.value === 0);
  })) || [];
};
exports.formatUpdateInfoList = formatUpdateInfoList;