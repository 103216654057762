import React, { CSSProperties, useContext } from 'react'
import ReactHtmlParser from 'react-html-parser'

import { Collapse, Divider } from '@arco-design/web-react'
import { IconDown } from '@arco-design/web-react/icon'
import { BankCard as AtmSvg, OnlineBanking as OnlineBankingSvg, Phone as PhoneSvg } from '@pipo-lib/icon'

import { PaymentCodeContext } from '@/pages/PaymentCode/interface'
import { PaymentMethodInfo, PcIcon, PipoProvider, boldText } from '@/pipo/utils'
import starling from '@/utils/starling'
import teaClient from '@/utils/tea'

import useGuide from './useGuide'

import './index.less'

const CollapseItem = Collapse.Item
const customStyle: CSSProperties = {
  background: 'white',
}

const classPrefix = 'pipo-pc-voucher-guide'

const formatter = (step: string) => {
  if (!step) return null
  const bold = boldText(step)
  const formatText = bold?.replace(/\/\/\//g, '')
  return <p key={step}>{ReactHtmlParser(formatText)}</p>
}

const generateParagraph = (text: string) => {
  const formatText = text?.replace(/\\n[\r\n]?/g, '///') // 将回车和'\n'替换为'///'
  return formatText?.split('///').map(formatter)
}

const Header = (props: { icon?: PcIcon; title: string }) => {
  const { icon, title } = props
  const IconComponent = {
    bank: <AtmSvg />,
    phone: <PhoneSvg />,
    atm: <OnlineBankingSvg />,
  }
  const { isFlip } = PipoProvider.useContext()
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {icon && IconComponent[icon]}
      <span className={classPrefix + '-collapse-header'} style={{ margin: isFlip ? '0 8px 0 0' : '0 0 0 8px' }}>
        {title}
      </span>
    </div>
  )
}

export interface OperationalGuideProps {
  paymentMethodInfo: PaymentMethodInfo
}

const OperationGuide = (props: OperationalGuideProps) => {
  const { instructionArr, collapsable, defaultActiveKey } = useGuide(props)
  const { isFlip } = PipoProvider.useContext()
  const { payWay, recordNo } = useContext(PaymentCodeContext)
  if (instructionArr.length === 0) {
    return <></>
  }
  return (
    <div className={classPrefix} style={{ width: '100%', direction: isFlip ? 'rtl' : 'ltr' }}>
      <div className={classPrefix + '-infotitle'}>{starling('funds.refund.comm.makingpaymenttips')}</div>
      <div className={classPrefix + '-line'} />
      {instructionArr?.length === 1 && !collapsable ? (
        generateParagraph(instructionArr[0].content)
      ) : (
        <>
          <Collapse
            expandIcon={<IconDown />}
            expandIconPosition={isFlip ? 'left' : 'right'}
            bordered={false}
            defaultActiveKey={defaultActiveKey}
            style={{ maxWidth: '100%', width: '100%' }}
            onChange={(key, keys) => {
              const isExpand = keys.includes(key) // 判断是展开还是关闭
              teaClient.sendPaymentCodePageClick({ order_id: recordNo, pay_way: payWay, click_item: isExpand ? 'expand_guide' : 'hide_guide' })
            }}
          >
            {instructionArr.map((item, index) => {
              return (
                <CollapseItem
                  key={item.subTitle}
                  name={index.toString()}
                  style={customStyle}
                  header={<Header icon={item?.icon} title={item.subTitle} />}
                >
                  {generateParagraph(item.content)}
                </CollapseItem>
              )
            })}
          </Collapse>
          <Divider style={{ margin: 0 }} />
        </>
      )}
    </div>
  )
}
export { OperationGuide }
