import { BankListSelect, CNPJ, CPF, Email, HolderName, ItemInputNumber } from '@/pipo/pc/inner-components'
import { cnpj, cpf, validator } from '@/pipo/utils'
import starling from '@/utils/starling'

import { IBankAccountFormConfigItem, IGetBankAccountFormConfigProps } from '../utils'

export const getBRFormConfig = ({ bankList, cardHolderInfo }: IGetBankAccountFormConfigProps): IBankAccountFormConfigItem => ({
  allowAccountType: ['b', 'c'],
  needInfo: ['bank_list'],
  formSchema: [
    {
      field: 'bank_code',
      widget: BankListSelect,
      widgetProps: {
        title: starling('funds.check_out.comm.channel_bank'),
        bankList,
      },
      rules: { required: true },
    },
    {
      field: 'holder_name',
      widget: HolderName,
      widgetProps: {
        len: 'full',
        title: starling('funds.refund.comm.system_name'),
        placeholder: starling('funds.refund.comm.system_name_description'),
        maxLength: 166,
        selectList: cardHolderInfo?.holderNameList,
      },
      rules: { required: true, pattern: validator.nameRegex },
    },
    {
      field: 'account_no',
      widget: ItemInputNumber,
      widgetProps: {
        title: starling('funds.refund.comm.system_bankaccount_account_number'),
        placeholder: starling('funds.refund.comm.system_bankaccount_account_number_placeholder'),
        maxLength: 14,
      },
      rules: { required: true, minLength: 4 },
    },
    {
      field: 'agency',
      widget: ItemInputNumber,
      widgetProps: {
        title: starling('funds.refund.comm.system_bankaccount_agencycode'),
        placeholder: starling('funds.refund.comm.system_bankaccount_agencycode_placeholder'),
        tips: starling('funds.refund.comm.system_bankaccount_agencycode_tips'),
        maxLength: 5,
      },
      rules: { required: true, pattern: validator.agencyCodeRegex },
    },
    {
      field: 'company_id',
      widget: CNPJ,
      widgetProps: {
        title: starling('funds.refund.comm.system_ic_CNPJ'),
        needPickNumber: true,
      },
      rules: {
        required: true,
        validate: {
          cnpj: (val) => cnpj.isValid(val || ''),
        },
      },
    },
    {
      field: 'email',
      widget: Email,
      widgetProps: {
        title: starling('funds.refund.comm.system_email'),
        placeholder: starling('funds.refund.comm.system_email_placeholder'),
      },
      rules: { required: true, pattern: validator.emailRegex },
    },
  ],
  toCFormSchema: [
    {
      field: 'bank_code',
      widget: BankListSelect,
      widgetProps: {
        title: starling('funds.check_out.comm.channel_bank'),
        bankList,
      },
      rules: { required: true },
    },
    {
      field: 'holder_name',
      widget: HolderName,
      widgetProps: {
        len: 'full',
        title: starling('funds.refund.comm.system_name'),
        placeholder: starling('funds.refund.comm.system_name_description'),
        maxLength: 40,
        selectList: cardHolderInfo?.holderNameList,
      },
      rules: { required: true, pattern: validator.brHolderName },
    },
    {
      field: 'account_no',
      widget: ItemInputNumber,
      widgetProps: {
        title: starling('funds.refund.comm.system_bankaccount_account_number'),
        placeholder: starling('funds.refund.comm.system_bankaccount_account_number_placeholder'),
        maxLength: 30,
      },
      rules: { required: true },
    },
    {
      field: 'agency',
      widget: ItemInputNumber,
      widgetProps: {
        title: starling('funds.refund.comm.system_bankaccount_agencycode'),
        placeholder: starling('funds.refund.comm.system_bankaccount_agencycode_placeholder'),
        tips: starling('funds.refund.comm.system_bankaccount_agencycode_tips'),
        maxLength: 5,
      },
      rules: { required: true, pattern: validator.agencyCodeRegex },
    },
    {
      field: 'identity',
      widget: CPF,
      widgetProps: {
        title: starling('funds.refund.comm.system_ic_CPF'),
      },
      rules: {
        required: true,
        validate: {
          cpf: (val) => cpf.isValid(val || ''),
        },
      },
    },
    {
      field: 'email',
      widget: Email,
      widgetProps: {
        title: starling('funds.refund.comm.system_email'),
        placeholder: starling('funds.refund.comm.system_email_placeholder'),
      },
      rules: { required: true, pattern: validator.emailRegex },
    },
  ],
})
