import React from 'react'

import { CDN_DOMAIN } from '@/constant'
import { VoucherBoardData } from '@/pipo/hooks/use-ui-voucher'
import starling from '@/utils/starling'

import { AmountCard } from '../amount-card'
import { InfoCard } from '../info-card'

import './index.less'

interface IFawryCodeProps {
  data: VoucherBoardData
}

const FawryCode = (props: IFawryCodeProps) => {
  const { data } = props
  const { simple } = data

  const InfoCardNotice = () => {
    return (
      <div className="payment-code-info-card-notice-list">
        {starling('funds.check_out.fawry_paytip_header')}
        <div className="payment-code-info-card-notice-item">{starling('funds.check_out.fawry_paytip_App')}</div>
        <div className="payment-code-info-card-notice-item">
          {starling('funds.check_out.fawry_paytip_offline')}
          <a href="https://fawry.com/storelocator/" target="_blank" rel="noopener noreferrer">
            {starling('funds.check_out.fawry_paytip_learnmore')}
          </a>
        </div>
      </div>
    )
  }

  return (
    <React.Fragment>
      <AmountCard
        iconUrl={`${CDN_DOMAIN}/obj/cg-fe-sg/upay/imgs/Icon_eg_fawry.png`}
        title={starling('funds.check_out.comm.channel_bank_name_Fawry')}
        amount={simple.amount}
      />

      <div className="payment-code-info-card-fawry">
        <InfoCard
          infoList={[
            {
              title: starling('funds.check_out.comm.page_tittle_payment_code'),
              content: simple.formatCode,
              needCopy: true,
              notice: <InfoCardNotice />,
            },
          ]}
        />
      </div>
    </React.Fragment>
  )
}

export { FawryCode }
