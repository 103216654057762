import { IFormBuilderItemProps } from '@/components/FormBuilder'
import { validator } from '@/pipo/utils'
import starling from '@/utils/starling'

import { HolderName } from '../../inner-components'

export const SCHEMA = ({
  t,
}: {
  t: (
    key: string,
    replaceItem?:
      | {
          value: string
        }
      | undefined,
  ) => string
}): IFormBuilderItemProps[] => [
  {
    field: 'holder_name',
    widget: HolderName,
    widgetProps: {
      title: starling('funds.checkout.comm.system_Accountholder_name'),
      placeholder: starling('funds.checkout.comm.system_Accountholder_prompt'),
      len: 'half',
      maxLength: 100,
    },
    rules: { required: true, pattern: validator.czNameRegex },
  } as IFormBuilderItemProps<typeof HolderName>,
]
