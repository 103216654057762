"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.StatusTypeEnum = exports.StatusActionTypeEnum = exports.RESPONSE_CODE = exports.PaymentSubmitMethodEnum = exports.IdcEnum = void 0;
var PaymentSubmitMethodEnum;
exports.PaymentSubmitMethodEnum = PaymentSubmitMethodEnum;
(function (PaymentSubmitMethodEnum) {
  PaymentSubmitMethodEnum[PaymentSubmitMethodEnum["PAYMENT"] = 1] = "PAYMENT";
  PaymentSubmitMethodEnum[PaymentSubmitMethodEnum["BIND"] = 2] = "BIND";
  PaymentSubmitMethodEnum[PaymentSubmitMethodEnum["DEPENDS"] = 3] = "DEPENDS";
})(PaymentSubmitMethodEnum || (exports.PaymentSubmitMethodEnum = PaymentSubmitMethodEnum = {}));
/**
 * 后端返回 code 枚举
 */
var RESPONSE_CODE = {
  /** 成功 */
  SUCCESS: 0,
  /** 网络错误 */
  NETWORK_ERROR: -9999,
  /** 解析错误 */
  PARSE_ERROR: -10000,
  /** 外部接口错误 */
  EXTERNAL_ERROR: -10001
};
exports.RESPONSE_CODE = RESPONSE_CODE;
var StatusActionTypeEnum;
exports.StatusActionTypeEnum = StatusActionTypeEnum;
(function (StatusActionTypeEnum) {
  StatusActionTypeEnum["CHANGE"] = "change";
  StatusActionTypeEnum["BIND"] = "bind";
  StatusActionTypeEnum["PAY"] = "pay";
  StatusActionTypeEnum["PAYOUT"] = "payout";
  StatusActionTypeEnum["TRANSFER"] = "transfer";
  StatusActionTypeEnum["UPDATE"] = "update";
})(StatusActionTypeEnum || (exports.StatusActionTypeEnum = StatusActionTypeEnum = {}));
var StatusTypeEnum;
exports.StatusTypeEnum = StatusTypeEnum;
(function (StatusTypeEnum) {
  StatusTypeEnum["SUCCESS"] = "success";
  StatusTypeEnum["SUCCEED"] = "succeed";
  StatusTypeEnum["FAILED"] = "failed";
  StatusTypeEnum["FAIL"] = "fail";
  StatusTypeEnum["TIMEOUT"] = "wait";
  StatusTypeEnum["PENDING"] = "pending";
  StatusTypeEnum["PROCESSING"] = "processing";
  StatusTypeEnum["INIT"] = "init";
})(StatusTypeEnum || (exports.StatusTypeEnum = StatusTypeEnum = {}));
var IdcEnum;
exports.IdcEnum = IdcEnum;
(function (IdcEnum) {
  IdcEnum["BOE"] = "boei18n";
  IdcEnum["VA"] = "va";
  IdcEnum["SG"] = "sg";
  IdcEnum["OCI"] = "oci";
})(IdcEnum || (exports.IdcEnum = IdcEnum = {}));