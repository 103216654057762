import React, { useCallback, useRef, useState } from 'react'

import { PAY_WAY_TYPE } from '@/constant'
import { IPayinMethodItem, PaymentSubmitMethodEnum } from '@/pages/PayNext/interface'
import { CardListRes } from '@/services/types'

import {
  EXCITED_PAYMENT_EXTRA_TYPE,
  ExcitedPaymentsMethodComponentType,
  IExcitedPaymentMethodsProps,
  IPaymentMethodStatusValue,
  IPaymentMethodsPropsBasic,
  IPaymentsMethodRef,
} from '../interface'
import CVV from './CVV'
import NeedCheckOTP from './NeedCheckOTP'
import Venmo from './Venmo'

/**
 * 使用已绑卡支付方式
 */
const useExcitedPaymentsMethod = () => {
  // 已绑卡支付方式验证情况
  const [excitedPaymentStatus, setExcitedPaymentStatus] = useState<{ [key: string]: IPaymentMethodStatusValue }>({})
  // cardId - ref MAP
  const excitedPaymentsRefMap = useRef(new Map<string, IPaymentsMethodRef | null>([]))
  // componentType - component MAP
  const excitedPaymentsMap = useRef(
    new Map<EXCITED_PAYMENT_EXTRA_TYPE, ExcitedPaymentsMethodComponentType>([
      [EXCITED_PAYMENT_EXTRA_TYPE.CVV_FORM, CVV],
      [EXCITED_PAYMENT_EXTRA_TYPE.OTP_CODE, NeedCheckOTP],
      [EXCITED_PAYMENT_EXTRA_TYPE.VENMO, Venmo],
    ]),
  )

  /**
   * 已绑卡支付方式路由
   * @param card 已绑卡信息
   * @returns 额外显示内容种类
   */
  const getExcitedPaymentType = (card: CardListRes | IPayinMethodItem) => {
    if (card.needCvv) {
      return EXCITED_PAYMENT_EXTRA_TYPE.CVV_FORM
    }
    if (card.payWay === PAY_WAY_TYPE.PAYCELL) {
      return EXCITED_PAYMENT_EXTRA_TYPE.OTP_CODE
    }
    if (card.payWay === PAY_WAY_TYPE.VENMO) {
      return EXCITED_PAYMENT_EXTRA_TYPE.VENMO
    }

    return false
  }

  /** 根据payWay获取支付渠道的ref */
  const getExcitedPaymentRefByCard = useCallback((card?: IPayinMethodItem | null) => {
    if (!card) {
      return undefined
    }

    return excitedPaymentsRefMap.current.get(card.cardId) || undefined
  }, [])

  const changeExcitedPaymentStatus = useCallback(
    (cardId: string, value: IPaymentMethodStatusValue | ((oldValue: IPaymentMethodStatusValue) => IPaymentMethodStatusValue)) => {
      if (typeof value === 'function') {
        setExcitedPaymentStatus((preStatus) => {
          const preValue = preStatus[cardId] || {}
          return { ...preStatus, [cardId]: value(preValue) }
        })
      } else {
        setExcitedPaymentStatus((preStatus) => ({ ...preStatus, [cardId]: value }))
      }
    },
    [],
  )

  const clearExcitedPaymentStatus = useCallback(() => {
    setExcitedPaymentStatus({})
  }, [])

  const renderExcitedPaymentsExtra = useCallback(
    (card: IExcitedPaymentMethodsProps['card'], paymentPropsBasic: IPaymentMethodsPropsBasic): React.ReactNode => {
      const coreProps: IExcitedPaymentMethodsProps = {
        card,
        changeExcitedPaymentStatus,
        paymentSubmitMethod: (card as IPayinMethodItem).paymentSubmitMethod || PaymentSubmitMethodEnum.PAYMENT,
        ...paymentPropsBasic,
      }
      const excitedPaymentType = getExcitedPaymentType(card)
      if (!excitedPaymentType) {
        // 无需显示额外内容
        return null
      }

      const PaymentItem = excitedPaymentsMap.current.get(excitedPaymentType)
      if (PaymentItem) {
        const Component = (
          <PaymentItem
            ref={(ref) => {
              excitedPaymentsRefMap.current.set(card.cardId, ref)
            }}
            {...coreProps}
          />
        )
        return Component
      }

      return null
    },
    [changeExcitedPaymentStatus],
  )

  return {
    getExcitedPaymentRefByCard,
    renderExcitedPaymentsExtra,
    valid: { excitedPaymentStatus, clearExcitedPaymentStatus },
  }
}

export default useExcitedPaymentsMethod
