import React, { forwardRef, useEffect, useState } from 'react'
import { Controller, ControllerFieldState, useForm } from 'react-hook-form'

import { useRequest } from '@byted/hooks'
import { pick } from 'lodash'

import { PAY_WAY_TYPE } from '@/constant'
import { useAddressPC, usePipoRouter, usePostalCode, useSubmittingState, useTouchedErrors } from '@/pipo/hooks'
import { PaymentParamsItem, PipoComponentRef, PipoProvider, PipoSubmitParams, generateCommonPaymentParams } from '@/pipo/utils'
import starling from '@/utils/starling'
import teaClient from '@/utils/tea'

import { AccountType, AuthorizeBox, SaveBox } from '../../inner-components'
import { BankAccountInfo } from './BankAccountInfo'
import { ContractInfo } from './ContractInfo'
import { PaymentAndPayee } from './PaymentAndPayee'
import { ReadInfo } from './ReadInfo'
import { SignatureInfo } from './SignatureInfo'
import {
  DIRECT_DEBIT_B_D_PAYMENT_METHOD,
  DIRECT_DEBIT_B_D_PAYMENT_PARAMS_MAP,
  DIRECT_DEBIT_C_D_PAYMENT_METHOD,
  DIRECT_DEBIT_C_D_PAYMENT_PARAMS_MAP,
  DirectDebitFormData,
  DirectDebitProps,
  generateMandateInfo,
} from './utils'

import './index.less'

const clsPrefix = 'pipo-bindcard-pc-direct-debit'

export const DirectDebitCA = forwardRef((props: DirectDebitProps, ref: React.ForwardedRef<PipoComponentRef>) => {
  const {
    showSavingBox = true,
    contractAddressList,
    onFetchDistrict,
    onFetchMandate,
    onSubmit,
    onValidate = () => {
      // do nothing.
    },
  } = props

  const { state, getPublicKeyAsync } = PipoProvider.useContext()
  const { config = {}, countryOrRegion = '' } = state
  const { selectedSaveBox = false } = config

  const form = useForm<DirectDebitFormData>({
    mode: 'all',
  })

  const {
    control,
    watch,
    handleSubmit: handleFormSubmit,
    setValue,
    getValues,
    trigger,
    clearErrors,
    setError,
    formState: { errors: formErrors, isValid, touchedFields },
  } = form

  const customerAddressProps = useAddressPC({
    onFetchDistrict,
    getValues,
    setValue,
    trigger,
    clearErrors,
    setError,
    watch,
    countryOrRegion,
    formErrors,
    // 仅展示CA的地址
    contractAddressList: contractAddressList?.filter((address) => address.countryCode === 'CA'),
  })
  const { selectedAddressArr, selectedContractAddress, getFillAddressState } = customerAddressProps
  const customerPostalCodeProps = usePostalCode({
    selectedAddressArr,
    watch,
    trigger,
  } as any)

  const errors = useTouchedErrors<DirectDebitFormData>({
    touchedFields,
    errors: formErrors,
  })

  const [mandateChecked, setMandateChecked] = useState(false) // 是否勾选mandate，必须勾选mandate才可以提交
  const { formSubmitState } = useSubmittingState()
  const { data: mandateInfo = [], run: fetchMandate } = useRequest(onFetchMandate, { auto: false })

  // Monitor select box value to render BusinessAccount or PersonalAccount
  const renderBusiness = watch('account_type') === '0'

  // TODO: can be modified from outside
  const [storeCard, setStoreCard] = useState<boolean>(selectedSaveBox)

  useEffect(() => {
    if (isValid) {
      mandateChecked && onValidate(true)
    } else {
      setMandateChecked(false)
    }
  }, [isValid, mandateChecked, onValidate])

  useEffect(() => {
    const method = renderBusiness ? DIRECT_DEBIT_B_D_PAYMENT_METHOD : DIRECT_DEBIT_C_D_PAYMENT_METHOD
    fetchMandate({
      payment_method_id: method.paymentMethod.paymentMethodId,
      payment_method: 'arch_direct_debit',
      payment_type: 'direct_debit',
      integration_type: 'direct',
      user_type: renderBusiness ? 'b' : 'c',
    })
  }, [renderBusiness, fetchMandate])

  const processSubmitData = async (formData: DirectDebitFormData): Promise<PipoSubmitParams> => {
    delete formData.contract_address // 删除内部使用字段
    ;(formData as any).signature_timestamp = Date.now().toString().slice(0, -3)

    if (selectedContractAddress) {
      formData.address_details = selectedContractAddress.detailAddress
      formData.billing_country_region = selectedContractAddress.countryCode
      formData.billing_postal_code = selectedContractAddress.postcode
      formData.billing_state = selectedContractAddress.level2Name
      formData.billing_city = selectedContractAddress.level3Name
    }

    if (!formData.holder_name) {
      formData.holder_name = (formData.holder_first_name || '') + (formData.holder_last_name || '')
    }
    const paymentElementProps = renderBusiness
      ? pick(formData, ['holder_name', 'routing_no', 'account_no'])
      : pick(formData, ['holder_name', 'routing_no', 'account_no'])
    const paramsMap = renderBusiness ? DIRECT_DEBIT_B_D_PAYMENT_PARAMS_MAP : DIRECT_DEBIT_C_D_PAYMENT_PARAMS_MAP
    const publicKey = await getPublicKeyAsync()
    const paymentParams: PaymentParamsItem[] = generateCommonPaymentParams(paymentElementProps, paramsMap, publicKey)
    const mandateInfoData = generateMandateInfo(mandateInfo, formData)
    const method = renderBusiness ? DIRECT_DEBIT_B_D_PAYMENT_METHOD : DIRECT_DEBIT_C_D_PAYMENT_METHOD
    const { paymentMethodType, paymentMethod } = method

    return {
      formData,
      paymentMethod: {
        paymentMethod: paymentMethod.paymentMethod,
        paymentMethodId: paymentMethod.paymentMethodId,
      },
      paymentMethodType: paymentMethodType.paymentMethodType,
      paymentParams,
      extraParams: {
        mandateInfo: mandateInfoData,
      },
      storeCard: storeCard && showSavingBox,
      fillAddressState: getFillAddressState(),
    }
  }

  const validateSubmitData = () => {
    return new Promise(async (resolve: (value: PipoSubmitParams) => void, reject: (reason: typeof errors) => void) => {
      if (!isValid) {
        reject(errors)
      } else {
        const formData = getValues()
        resolve(await processSubmitData(formData))
      }
    })
  }

  const handleSubmit = async (formData: DirectDebitFormData): Promise<void> => {
    const submitData = await processSubmitData(formData)
    formSubmitState.setTrue()
    await onSubmit?.(submitData)
    formSubmitState.setFalse()
  }

  const { activePath, routerParams } = usePipoRouter(ref, {
    initPath: 'direct-debit',
    initParams: {
      isValid: isValid && mandateChecked,
      onValidate,
    },
    instanceMethods: {
      submit: handleFormSubmit(handleSubmit),
      validateSubmitData,
    },
  })

  const handleBlur = (scenario: string, fieldState: ControllerFieldState) => {
    const params = {
      pay_way: PAY_WAY_TYPE.DIRECT_DEBIT_CA,
      scenario,
      is_valid: !fieldState.error,
      invalid_reason: fieldState.error?.type,
    }
    teaClient.sendPayPageFillIn(params)
  }

  return (
    <div className="pipo-pc">
      <div className={`${clsPrefix}-flex-container`}>
        <div className={`${clsPrefix}-form-wrapper`}>
          <div className={`${clsPrefix}-subform-wrapper`}>
            <Controller
              rules={{ required: true }}
              name="account_type"
              defaultValue={'0'}
              control={control}
              render={({ field }) => <AccountType {...field} title={starling('funds.refund.comm.system_bankaccounttype')} />}
            />
          </div>

          <ContractInfo
            form={form}
            errors={errors}
            formErrors={formErrors}
            clsPrefix={clsPrefix}
            onFetchDistrict={onFetchDistrict}
            isBusiness={renderBusiness}
            addressProps={customerAddressProps}
            postalCodeProps={customerPostalCodeProps}
            handleBlur={handleBlur}
          />

          <BankAccountInfo
            form={form}
            errors={errors}
            formErrors={formErrors}
            clsPrefix={clsPrefix}
            onFetchDistrict={onFetchDistrict}
            handleBlur={handleBlur}
          />

          <PaymentAndPayee clsPrefix={clsPrefix} mandateInfo={mandateInfo} />
          {/* Save Box */}
          {showSavingBox && (
            <SaveBox title={starling('funds.refund.comm.system_save_card_information')} storeCard={storeCard} setStoreCard={setStoreCard} />
          )}
          <ReadInfo clsPrefix={clsPrefix} />
          <AuthorizeBox
            title={starling('funds.check_out.comm.ca_dd_mandate')}
            disabled={!isValid}
            authorizeCard={mandateChecked}
            setAuthorizeCard={setMandateChecked}
          />
          <SignatureInfo clsPrefix={clsPrefix} control={control} errors={errors} isBusiness={renderBusiness} />
        </div>
      </div>
    </div>
  )
})
