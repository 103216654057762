import { PaymentMethodItem } from '../../types'

export const TRUEMONEY_PAYMENT_METHOD: PaymentMethodItem = {
  paymentMethodType: {
    configuration: {},
    icon: {
      iconUrl: '',
    },
    starling_key: 'pipo_common_ewallet',
    paymentMethodType: 'E_WALLET',
    title: {
      payment: 'pipo_common_ewallet',
    },
  },
  paymentMethod: {
    configuration: {
      png_icon_url: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/true-money-border_772a17.png',
      dark_mode_png_icon_url: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/true-money-gray_f1ffa7.png',
    },
    paymentMethod: 'TRUEMONEY',
    paymentMethodId: 'pm_pi_ew_truemoney_c_r',
    displayName: 'TrueMoney',
    starling_key: 'pipo_payin_payment_main_truemoney',
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/true-money-border_772a17.png',
      grayIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/true-money-gray_f1ffa7.png',
    },
  },
}
