"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault")["default"];
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.PipoProvider = exports.Context = void 0;
var _includes = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/includes"));
var _objectSpread2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/objectSpread2"));
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/slicedToArray"));
var _react = require("react");
var _utils = require("@upay/utils");
var _createModel = _interopRequireDefault(require("./createModel"));
var _locale = require("./locale");
/* eslint-disable @typescript-eslint/naming-convention */
var Context = function Context() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _useState = (0, _react.useState)(options),
    _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
    state = _useState2[0],
    setState = _useState2[1];
  var isFlip = state === null || state === void 0 ? void 0 : state.dir;
  var mergedLocales = (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _locale.defaultLocale), state === null || state === void 0 ? void 0 : state.localeTranslation);
  (0, _react.useEffect)(function () {
    setState(options);
  }, [options]);
  var getErrorText = function getErrorText(type, message) {
    if (!type) return '';
    switch (type) {
      case 'required':
        return _utils.starlingClient.t('funds.refund.comm.system_mandatory_field');
      default:
        return _utils.starlingClient.t('funds.refund.comm.system_invalidformat');
    }
  };
  var getPMConfig = function getPMConfig(paymentMethodId) {
    var _state$config = state.config,
      config = _state$config === void 0 ? {} : _state$config;
    var shouldDefaultBindOnePmId = ['pm_pi_ew_dana_c_r', 'pm_pi_ew_kakaopay_c_r', 'pm_pi_ew_ovo_c_d', 'pm_pi_ew_qiwi_c_r', 'pm_pi_ew_ovo_c_r', 'pm_pi_cp_oxxo_c_d'];
    return config[paymentMethodId] || ((0, _includes["default"])(shouldDefaultBindOnePmId).call(shouldDefaultBindOnePmId, paymentMethodId) ? {
      bindLimit: 1
    } : {});
  };
  return {
    state: state,
    isFlip: isFlip,
    inApp: options.inApp,
    theme: options.theme,
    t: function t(key, replaceItem) {
      if (replaceItem && replaceItem.value) return mergedLocales[key].replace('{value}', replaceItem.value);
      return mergedLocales[key];
    },
    getErrorText: getErrorText,
    getPMConfig: getPMConfig,
    getPublicKeyAsync: options.getPublicKeyAsync
  };
};
exports.Context = Context;
var PipoProvider = (0, _createModel["default"])(Context);
exports.PipoProvider = PipoProvider;