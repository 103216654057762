import { useCallback, useEffect } from 'react'
import type { FieldError, UseFormGetValues, UseFormTrigger } from 'react-hook-form'

import { PipoProvider } from '@/pipo/utils'
import type { CCDCFormData, PaymentMethodItem } from '@/pipo/utils'
import starling from '@/utils/starling'

type ErrorType = 'required' | 'pattern'

export function useSecurityCode(
  currentCardBrand: PaymentMethodItem | undefined,
  trigger?: UseFormTrigger<CCDCFormData>,
  getValues?: UseFormGetValues<CCDCFormData>,
): {
  cvvTitle: string
  cvvValidator: {
    required: boolean
    pattern: RegExp
  }
  getCVVError: (error: FieldError | undefined) => string
  cvvPlaceholder: string
} {
  const { t, getErrorText } = PipoProvider.useContext()
  const cvvPlaceholder = starling('funds.refund.comm.system_cvv')
  const cvvTitle = starling('funds.refund.comm.system_Security_code')
  const cvvValidator = {
    required: true,
    pattern: new RegExp(`^[0-9]{${currentCardBrand?.paymentMethod?.configuration?.cvv_length || [3, 4]?.join(',')}}$`),
  }
  const getCVVError = useCallback(
    (error: FieldError | undefined): string => {
      const errorType = error?.type as ErrorType
      return getErrorText(errorType)
    },
    [getErrorText, t],
  )

  useEffect(() => {
    currentCardBrand && getValues && getValues('cvv') && trigger && trigger('cvv')
  }, [currentCardBrand])

  return {
    cvvTitle,
    cvvValidator,
    getCVVError,
    cvvPlaceholder,
  }
}
