import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

import { useInterval, useStateRealtime } from '@byted/hooks'
import { StatusActionTypeEnum, StatusTypeEnum } from '@upay/utils/es/types'

import api from '@/services'
import { IBindCardSupplementParams, ISubmitExtraInfoParams } from '@/services/types'
import { ParamsContext } from '@/stores'

import { ICheckOTPModalProps, IOpenCheckOTPModalProps, ISubmitInfoRef, IUseCheckOTPModalProps } from './types'

const OTP_ERROR_CODE = [220051, 220052]
const OTP_RESEND_COUNTDOWN = 60

export const useCheckOTPModal = (props: IUseCheckOTPModalProps) => {
  const { onCancel, handleBillStatus } = props
  const {
    urlQuery: { bizId, accountId },
    uPubSub,
  } = useContext(ParamsContext)

  const [visible, setVisible] = useState(false)
  const [resendTime, setResendTime, getResendTime] = useStateRealtime(OTP_RESEND_COUNTDOWN)
  const [codeValue, setCodeValue] = useState('')
  const [identityNo, setIdentityNo] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const [submitLoading, setSubmitLoading] = useState(false)
  const [resendLoading, setResendLoading] = useState(false)
  const submitInfoRef = useRef<ISubmitInfoRef | null>(null)
  const { run: startCountDown, cancel } = useInterval(() => setResendTime((time) => time - 1), 1000)

  useEffect(() => {
    if (visible) {
      setResendTime(OTP_RESEND_COUNTDOWN)
      startCountDown()
    } else {
      cancel()
    }
  }, [cancel, setResendTime, startCountDown, visible])

  const handleCodeChange = useCallback((value: string) => {
    setCodeValue(value)
    setErrMsg('')
  }, [])

  const openCheckOTPModal = useCallback(({ identityNo, submitParams, submitType }: IOpenCheckOTPModalProps) => {
    setIdentityNo(identityNo)
    submitInfoRef.current = {
      submitParams,
      submitType,
      statusActionType: submitType === 'bind' ? StatusActionTypeEnum.BIND : StatusActionTypeEnum.PAY,
    }
    setVisible(true)
  }, [])

  const clearStates = useCallback(() => {
    submitInfoRef.current = null
    setCodeValue('')
    setIdentityNo('')
    setErrMsg('')
  }, [])

  const handleCancel = useCallback(() => {
    setVisible(false)
    onCancel && onCancel()
    clearStates()
  }, [clearStates, onCancel])

  const handleCloseAfterSubmit = useCallback(() => {
    setVisible(false)
    clearStates()
  }, [clearStates])

  const handleSubmit = useCallback(async () => {
    if (!submitInfoRef.current) return
    setSubmitLoading(true)
    try {
      if (submitInfoRef.current.submitType === 'pay') {
        // 支付提交
        const params: ISubmitExtraInfoParams = {
          ...(submitInfoRef.current.submitParams as ISubmitExtraInfoParams),
          extraInfo: { otpCode: codeValue },
        }
        await api.submitExtraInfo(params, {
          extraHandle: {
            uPubSub,
          },
          bizId,
        })
      } else {
        // 绑卡提交
        const params: IBindCardSupplementParams = { ...(submitInfoRef.current.submitParams as IBindCardSupplementParams), otpCode: codeValue }
        await api.bindCardSupplement(params, {
          headers: { bizAccountId: accountId },
          extraHandle: {
            uPubSub,
          },
          bizId,
        })
      }
      handleBillStatus({ actionType: submitInfoRef.current.statusActionType, status: StatusTypeEnum.SUCCESS })
      handleCloseAfterSubmit()
    } catch (e: any) {
      if (OTP_ERROR_CODE.includes(e.code)) {
        // 部分错误码需留在此页，允许用户继续尝试
        setErrMsg(e?.msg)
      } else {
        handleBillStatus({ actionType: submitInfoRef.current.statusActionType, status: StatusTypeEnum.FAILED, errMsg: e?.msg || e })
        handleCloseAfterSubmit()
      }
    }
    setSubmitLoading(false)
  }, [accountId, bizId, codeValue, handleBillStatus, handleCloseAfterSubmit, uPubSub])

  const handleResend = useCallback(async () => {
    const resendTime = getResendTime()
    if (resendTime > 0) return
    if (!submitInfoRef.current) return
    setResendLoading(true)

    try {
      if (submitInfoRef.current.submitType === 'pay') {
        // 支付重发
        await api.submitExtraInfo(submitInfoRef.current.submitParams as ISubmitExtraInfoParams, {
          extraHandle: {
            uPubSub,
          },
          bizId,
        })
      } else {
        // 绑卡重发
        await api.bindCardSupplement(submitInfoRef.current.submitParams as IBindCardSupplementParams, {
          headers: { bizAccountId: accountId },
          extraHandle: {
            uPubSub,
          },
          bizId,
        })
      }

      setCodeValue('')
      setErrMsg('')
      setResendTime(OTP_RESEND_COUNTDOWN)
      startCountDown()
    } catch (e: any) {
      handleBillStatus({ actionType: submitInfoRef.current.statusActionType, status: StatusTypeEnum.FAILED, errMsg: e?.msg || e })
      handleCloseAfterSubmit()
    }
    setResendLoading(false)
  }, [accountId, bizId, getResendTime, handleBillStatus, handleCloseAfterSubmit, setResendTime, startCountDown, uPubSub])

  const isDisabled = useMemo(() => codeValue.length < 6 || !!errMsg || resendLoading, [codeValue, errMsg, resendLoading])

  const checkOTPModalProps: ICheckOTPModalProps = {
    visible,
    isDisabled,
    submitLoading,
    codeValue,
    errMsg,
    identityNo,
    resendLoading,
    resendTime,
    handleCancel,
    handleSubmit,
    handleCodeChange,
    handleResend,
  }

  return { openCheckOTPModal, checkOTPModalProps }
}
