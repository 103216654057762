"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault")["default"];
_Object$defineProperty(exports, "__esModule", {
  value: true
});
_Object$defineProperty(exports, "SUPPORT_BANK_MAP", {
  enumerable: true,
  get: function get() {
    return _index.SUPPORT_BANK_MAP;
  }
});
exports.SUPPORT_PAYMENT_METHODS_ID_MAP = exports.SUPPORT_PAYMENT_METHODS = exports.SUPPORT_BIND_PAYMENT_METHODS_ID_MAP = exports.SUPPORT_BIND_PAYMENT_METHODS = void 0;
var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));
var _reduce = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/reduce"));
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/toConsumableArray"));
var _banktransferMx = require("./banktransfer-mx");
var _index = require("./banktransfer/index");
var _blik = require("./blik");
var _index2 = require("./boleto/index");
var _cashPlus = require("./cash-plus");
var _index3 = require("./ccdc/index");
var _fawry = require("./fawry");
var _internetbankingBo = require("./internetbanking-bo");
var _oxxo = require("./oxxo");
var _paycell = require("./paycell");
var _index4 = require("./pix/index");
var _promptPay = require("./prompt-pay");
var _toss = require("./toss");
var _context, _context2;
// =================== 支付流程 paymentMethods ================
var SUPPORT_PAYMENT_METHODS = (0, _concat["default"])(_context = []).call(_context, (0, _toConsumableArray2["default"])(_index3.SUPPORT_CCDC_PAYMENT_METHODS), [_index.BANKTRANSFER_PAYMENT_METHOD, _index2.BOLETO_PAYMENT_METHOD, _index4.PIX_PAYMENT_METHOD, _internetbankingBo.INTERNETBANKING_BO_PAYMENT_METHOD, _toss.TOSS_PAYMENT_METHOD, _paycell.PAYCELL_PAYMENT_METHOD, _fawry.FAWRY_PAYMENT_METHOD, _oxxo.OXXO_PAYMENT_METHOD, _cashPlus.CASH_PLUS_PAYMENT_METHOD, _promptPay.PROMPT_PAY_PAYMENT_METHOD, _banktransferMx.BANKTRANSFER_MX_PAYMENT_METHOD, _blik.BLIK_PAYMENT_METHOD]);
// =================== 绑定流程 paymentMethods ================
// 支持单独绑定的 paymentMethods, pi-list 下方的绑定入口
exports.SUPPORT_PAYMENT_METHODS = SUPPORT_PAYMENT_METHODS;
var SUPPORT_BIND_PAYMENT_METHODS = (0, _concat["default"])(_context2 = []).call(_context2, (0, _toConsumableArray2["default"])(_index3.SUPPORT_CCDC_PAYMENT_METHODS), [_index2.BOLETO_PAYMENT_METHOD, _index4.PIX_PAYMENT_METHOD]);
// =================== 绑定流程 paymentMethods ID Map ================
exports.SUPPORT_BIND_PAYMENT_METHODS = SUPPORT_BIND_PAYMENT_METHODS;
var SUPPORT_BIND_PAYMENT_METHODS_ID_MAP = (0, _reduce["default"])(SUPPORT_BIND_PAYMENT_METHODS).call(SUPPORT_BIND_PAYMENT_METHODS, function (map, item) {
  // @ts-ignore
  map[item.paymentMethod.paymentMethodId] = item;
  return map;
}, {});
// =================== 支付流程 paymentMethods ID Map ================
exports.SUPPORT_BIND_PAYMENT_METHODS_ID_MAP = SUPPORT_BIND_PAYMENT_METHODS_ID_MAP;
var SUPPORT_PAYMENT_METHODS_ID_MAP = (0, _reduce["default"])(SUPPORT_PAYMENT_METHODS).call(SUPPORT_PAYMENT_METHODS, function (map, item) {
  // @ts-ignore
  map[item.paymentMethod.paymentMethodId] = item;
  return map;
}, {});
exports.SUPPORT_PAYMENT_METHODS_ID_MAP = SUPPORT_PAYMENT_METHODS_ID_MAP;