import React, { ReactElement, forwardRef, useEffect, useState } from 'react'
import { Controller, ControllerFieldState, useForm } from 'react-hook-form'

import { PAY_WAY_TYPE } from '@/constant'
import { usePipoRouter, useTouchedErrors } from '@/pipo/hooks'
import {
  JPBanktransferFormProps,
  PaymentParamsItem,
  PipoComponentRef,
  PipoProvider,
  PipoSubmitParams,
  generateCommonPaymentParams,
  validator,
} from '@/pipo/utils'
import {
  JPBankTransfer_PAYMENT_METHOD,
  JPBankTransfer_PAYMENT_PARAMS_MAP,
  TransferJPFormDataType,
} from '@/pipo/utils/payment-methods-config/banktransfer-jp'
import starling from '@/utils/starling'
import teaClient from '@/utils/tea'

import { Email, HolderName, PhoneNumber, SaveBox } from '../../inner-components'

enum HolderNameEnum {
  name = 'name',
  katakanaName = 'katakana name',
}
export const JPBanktransferForm = forwardRef((props: JPBanktransferFormProps, ref: React.ForwardedRef<PipoComponentRef>): ReactElement => {
  const { onValidate = () => undefined, showSavingBox = true } = props
  const {
    getPublicKeyAsync,
    getErrorText,
    state: { config = {} },
  } = PipoProvider.useContext()
  const { selectedSaveBox = false } = config
  const [storeCard, setStoreCard] = useState<boolean>(selectedSaveBox)

  const {
    control,
    getValues,
    formState: { errors: formErrors, isValid, touchedFields },
  } = useForm<TransferJPFormDataType>({
    mode: 'all',
  })

  const errors = useTouchedErrors<TransferJPFormDataType>({
    touchedFields,
    errors: formErrors,
  })

  useEffect(() => {
    isValid && onValidate(true)
  }, [isValid, onValidate])

  const getJPNameErrorText = (type: string | undefined, name?: HolderNameEnum) => {
    if (!type) return ''
    if (type === 'required') return getErrorText(type)
    if (type && name === HolderNameEnum.name) {
      return starling('cg.upay.exception.channel_jpname_errormsg')
    }
    if (type && name === HolderNameEnum.katakanaName) {
      return starling('cg.upay.exception.channel_katakana_errormsg')
    }
  }

  /**
   * 处理PIPO提交所需数据
   * @returns PipoSubmitParams
   */
  const processSubmitData = async (formData: TransferJPFormDataType): Promise<PipoSubmitParams> => {
    formData.phone_country_code = '81'
    formData.phone = '' + formData.phone_number

    const publicKey = await getPublicKeyAsync()
    const paymentParams: PaymentParamsItem[] = generateCommonPaymentParams(formData, JPBankTransfer_PAYMENT_PARAMS_MAP, publicKey)

    const { paymentMethodType, paymentMethod } = JPBankTransfer_PAYMENT_METHOD
    return {
      formData,
      paymentMethod: {
        paymentMethod: paymentMethod.paymentMethod,
        paymentMethodId: paymentMethod.paymentMethodId,
      },
      paymentMethodType: paymentMethodType.paymentMethodType,
      paymentParams,
      storeCard: storeCard && showSavingBox,
    }
  }

  /**
   * 校验后获取PIPO提交所需数据
   * @returns PipoSubmitParams
   */
  const validateSubmitData = () => {
    return new Promise(async (resolve: (value: PipoSubmitParams) => void, reject: (reason: typeof errors) => void) => {
      if (!isValid) {
        reject(errors)
      } else {
        const formData = getValues()
        resolve(await processSubmitData(formData))
      }
    })
  }

  usePipoRouter(ref, {
    initParams: {
      onValidate,
      isValid,
    },
    instanceMethods: {
      validateSubmitData,
    },
  })

  const handleBlur = (scenario: string, fieldState: ControllerFieldState) => {
    const params = {
      pay_way: PAY_WAY_TYPE.BANK_TRANSFER_JP,
      scenario,
      is_valid: !fieldState.error,
      invalid_reason: fieldState.error?.type,
    }
    teaClient.sendPayPageFillIn(params)
  }

  return (
    <div id="upay-form-banktransfer-jp">
      <div className="payment-banktransfer-payeasy">
        <Controller
          rules={{ required: true, pattern: validator.JPphoneRegex }}
          name="phone_number"
          control={control}
          render={({ field, fieldState }) => (
            <PhoneNumber
              phoneNumberStyle={'half'}
              {...field}
              error={errors.phone_number}
              addBefore="JP +81"
              title={starling('funds.refund.comm.system_phonenumber')}
              placeholder={starling('funds.refund.comm.system_phonenumber_placeholder')}
              errorMessage={getErrorText(errors.phone_number?.type)}
              onBlur={() => {
                field.onBlur()
                handleBlur('phone_number', fieldState)
              }}
            />
          )}
        />
        <Controller
          rules={{ required: true, pattern: validator.emailRegex }}
          name="email"
          control={control}
          render={({ field, fieldState }) => (
            <Email
              {...field}
              title={starling('funds.refund.comm.system_email')}
              placeholder={starling('funds.refund.comm.system_email_placeholder')}
              error={errors.email}
              errorMessage={getErrorText(errors.email?.type)}
              onBlur={() => {
                field.onBlur()
                handleBlur('email', fieldState)
              }}
            />
          )}
        />
        {/* Frist Name RomajiNameRegex */}
        <Controller
          rules={{ required: true, pattern: validator.RomajiNameRegex }}
          name="holder_first_name"
          control={control}
          render={({ field, fieldState }) => (
            <HolderName
              {...field}
              error={errors.holder_first_name}
              errorMessage={getJPNameErrorText(errors.holder_first_name?.type, HolderNameEnum.name)}
              maxLength={128}
              title={starling('funds.refund.comm.system_firstname')}
              placeholder={starling('cg.upay.exception.channel_jpname_hint')}
              onBlur={() => {
                field.onBlur()
                handleBlur('holder_first_name', fieldState)
              }}
            />
          )}
        />
        {/** Frist Name Katakana */}
        <Controller
          rules={{ required: true, pattern: validator.KatakanaNameRegex }}
          name="holder_first_name_katakana"
          control={control}
          render={({ field, fieldState }) => (
            <HolderName
              title=""
              {...field}
              maxLength={128}
              error={errors.holder_first_name_katakana}
              errorMessage={getJPNameErrorText(errors.holder_first_name_katakana?.type, HolderNameEnum.katakanaName)}
              placeholder={starling('cg.upay.exception.channel_katakana')}
              onBlur={() => {
                field.onBlur()
                handleBlur('holder_first_name_katakana', fieldState)
              }}
            />
          )}
        />
        {/** Last name Romaji */}
        <Controller
          rules={{ required: true, pattern: validator.RomajiNameRegex }}
          name="holder_last_name"
          control={control}
          render={({ field, fieldState }) => (
            <HolderName
              {...field}
              error={errors.holder_last_name}
              errorMessage={getJPNameErrorText(errors.holder_last_name?.type, HolderNameEnum.name)}
              maxLength={128}
              title={starling('funds.refund.comm.system_lastname')}
              placeholder={starling('cg.upay.exception.channel_jpname_hint')}
              onBlur={() => {
                field.onBlur()
                handleBlur('holder_last_name', fieldState)
              }}
            />
          )}
        />
        {/** Last name Katakana */}
        <Controller
          rules={{ required: true, pattern: validator.KatakanaNameRegex }}
          name="holder_last_name_katakana"
          control={control}
          render={({ field, fieldState }) => (
            <HolderName
              title=""
              {...field}
              error={errors.holder_last_name_katakana}
              errorMessage={getJPNameErrorText(errors.holder_last_name_katakana?.type, HolderNameEnum.katakanaName)}
              maxLength={128}
              placeholder={starling('cg.upay.exception.channel_katakana')}
              onBlur={() => {
                field.onBlur()
                handleBlur('holder_last_name_katakana', fieldState)
              }}
            />
          )}
        />
        {showSavingBox && (
          <SaveBox title={starling('funds.refund.comm.system_save_card_information')} storeCard={storeCard} setStoreCard={setStoreCard} />
        )}
      </div>
    </div>
  )
})
