import { IFormBuilderItemProps } from '@/components/FormBuilder'
import starling from '@/utils/starling'

import { ItemInputNumber } from '../../inner-components'

export const SCHEMA = ({
  t,
}: {
  t: (
    key: string,
    replaceItem?:
      | {
          value: string
        }
      | undefined,
  ) => string
}): IFormBuilderItemProps[] => [
  {
    field: 'one_time_code',
    widget: ItemInputNumber,
    widgetProps: {
      title: starling('funds.check_out.comm.blik_code'),
      placeholder: starling('funds.check_out.comm.digits', {
        num: 6,
      }),
      len: 'half',
      maxLength: 6,
      tips: starling('funds.check_out.comm.blik_code_tip'),
    },
    rules: { required: true, pattern: /^\d{6}$/ },
  } as IFormBuilderItemProps<typeof ItemInputNumber>,
]
