import { isNil } from 'lodash'

import utils from '@/utils'

import { BANKTRANSFER_PAYMENT_PARAMS_MAP, CCDC_PAYMENT_PARAMS_MAP, OXXO_PAYMENT_PARAMS_MAP, PAYMENT_PARAMS_MAP, PaymentParamsItem } from '../index'
import { IPaymentParamsMapItem, SupportPaymentMethodId } from '../types'

/**
 * Verify the obj that all attribute values are empty
 *
 * validateEmptyObject({ a: 1, b: null })
 * // => true
 *
 * validateEmptyObject({ a: 0, b: 0 })
 * // => true
 *
 * validateEmptyObject({ a: undefined, b: null })
 * // => false
 *
 * validateEmptyObject({ a: '', b: '' })
 * // => false
 *
 * @param obj
 */
export const noEmptyProperty = (obj: unknown) => {
  if (!obj) return false
  // @ts-ignore
  return Object.values(obj).some((val) => !isNil(val) && val !== '')
}

export const generateCommonPaymentParams = <T extends Record<string, any>>(
  data: T,
  PAYMENT_PARAMS_MAP: Record<string, string | IPaymentParamsMapItem>,
  publicKey?: string,
): PaymentParamsItem[] => {
  const paymentParams: PaymentParamsItem[] = Object.entries(data).reduce<PaymentParamsItem[]>((arr, [key, value]) => {
    if (!PAYMENT_PARAMS_MAP[key]) return arr

    const paymentParam =
      typeof PAYMENT_PARAMS_MAP[key] === 'string'
        ? {
            element: PAYMENT_PARAMS_MAP[key] as string,
          }
        : (PAYMENT_PARAMS_MAP[key] as IPaymentParamsMapItem)

    const obj: PaymentParamsItem = !value
      ? {
          element: paymentParam.element,
          param_name: key,
          param_value: value,
        }
      : {
          element: paymentParam.element,
          param_name: key,
          param_value: !!paymentParam?.is_encrypted && publicKey ? utils.encryptString(value, publicKey) : value,
          is_encrypted: !!paymentParam?.is_encrypted,
        }
    arr.push(obj)
    return arr
  }, [])
  return paymentParams
}

export const generatePaymentParams = (params: {
  paymentMethodType: string
  paymentMethod: string
  paymentMethodId: SupportPaymentMethodId
  data: Record<string, any>
}) => {
  const { paymentMethodType, data = {}, paymentMethod, paymentMethodId } = params
  if (!data || Object.keys(data).length === 0) {
    return []
  }
  const map = PAYMENT_PARAMS_MAP[paymentMethodId]
  if (paymentMethod === 'OVO' && map) {
    const OVOobj = generateCommonPaymentParams(data, map)
    OVOobj.forEach((item) => {
      if (item.param_name === 'phone' || item.param_name === 'wallet_id') item.param_value = '08' + item.param_value
    })
    return OVOobj
  }
  if (map) {
    return generateCommonPaymentParams(data, map)
  }
  if (paymentMethod === 'OXXO') return generateCommonPaymentParams(data, OXXO_PAYMENT_PARAMS_MAP)
  switch (paymentMethodType) {
    case 'CCDC':
      return generateCommonPaymentParams(data, CCDC_PAYMENT_PARAMS_MAP)
    case 'BANK_TRANSFER':
      return generateCommonPaymentParams(data, BANKTRANSFER_PAYMENT_PARAMS_MAP)
    default:
      return []
  }
}

export const validateStatus = (params: {
  items: string[]
  errors: {
    [x: string]: unknown
  }
  getValues: unknown
}) => {
  const { items, errors, getValues } = params
  return items.reduce((status, item) => {
    // @ts-ignore
    const itemStatus = Boolean(getValues(item) && !errors[item])
    return status && itemStatus
  }, true)
}

export const boldText = (str: string) => {
  return str?.replace(/\{.*?\}/g, (match) => {
    return `<b>${match.slice(1, match.length - 1)}</b>`
  })
}
