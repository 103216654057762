import { CSSProperties } from 'react'

import { TableColumnProps } from '@arco-design/web-react'
import { ColumnProps } from '@arco-design/web-react/es/Table/interface'
import dayjs from 'dayjs'

import { IPayoutHistoryListItem, IPayoutTransListItem } from '@/services/payout-types'
import starling from '@/utils/starling'

export interface IStatusRender {
  col: any
  record: any
  viewDetailBtn?: () => void
}

export enum TabKeyEnum {
  TransactionKey = '1',
  WithdrawHistory = '2',
}

export interface IStatusRenderProps {
  record: IRecord
  onViewDetail?: () => void
  hasViewWithdrawDetail?: boolean
  errMsg?: string
}

export interface IPaymentMethodProps {
  record: IRecord
}

type IRecord = IPayoutHistoryListItem & IPayoutTransListItem

export interface ITransactionInfo {
  columns: TableColumnProps[]
  TableData: any[]
  style?: CSSProperties
}

export enum StatusEnum {
  waitingForWithdraw = 1,
  withdrawProcessing = 2,
  withdrawSuccess = 3,
  withdrawFailed = 4,
}

export const statusClassNameMap: Record<string, string> = {
  1: 'waiting-for-withdraw',
  2: 'withdraw-processing',
  3: 'withdraw-success',
  4: 'withdraw-failed',
}

export const transactionColumns = (): ColumnProps[] => [
  {
    title: starling(`funds.refund.comm.transactionid`),
    dataIndex: 'transactionId',
    width: 190,
  },
  {
    title: starling('funds.refund.comm.amount'),
    dataIndex: 'displayAmount',
    width: 130,
  },
  {
    title: starling('funds.refund.comm.time'),
    dataIndex: 'paymentTime',
    width: 180,
  },
]

export const withdrawHistoryColumns = (): ColumnProps[] => [
  {
    title: starling('funds.refund.comm.status_withdraw_request'),
    dataIndex: 'withdrawId',
    width: 190,
  },
  {
    title: starling('funds.refund.comm.amount'),
    dataIndex: 'displayAmount',
    width: 130,
  },
  {
    title: starling('funds.refund.comm.withdraw_time'),
    dataIndex: 'withdrawTime',
    width: 180,
  },
]

export const formatUtcToZone = (time: string) => {
  const timeDate = new Date(time + ' UTC')
  return dayjs(timeDate).format('YYYY-MM-DD HH:mm:ss')
}
