import React from 'react'

import { IPayinMethodItem } from '@/pages/PayNext/interface'

interface IProps {
  paymentItem: Pick<IPayinMethodItem, 'identityNo' | 'identityType'>
}
const PaymentItemCard: React.FC<IProps> = (props) => {
  const { paymentItem } = props
  const { identityType, identityNo } = paymentItem

  return (
    <span className="upay-payout-item-title">
      {identityType || ''} {identityNo}
    </span>
  )
}

export default PaymentItemCard
