import React from 'react'

import { Button, Image } from '@arco-design/web-react'
import { IconLoading } from '@arco-design/web-react/icon'
import { PROMPT_PAY_PAYMENT_METHOD } from '@upay/pipo-utils'

import { PaymentDetail } from '@/pipo/utils'
import { GOPAY_PAYMENT_METHOD } from '@/pipo/utils/payment-methods-config/gopay'
import starling from '@/utils/starling'

import './index.less'

const clsPrefix = 'pipo-pc-voucher'

interface QrCodeProps {
  shopperDetails: NonNullable<PaymentDetail>['present_to_shopper_details']
  paymentMethodId?: string
  copyButtonText?: string
  codeDescription?: string
  backButtonText?: string
  codeElement?: React.ReactNode
  onClickBackButton?: () => void
  size?: 'large' | 'default'
}

export const QrCode = (props: QrCodeProps) => {
  const { size, shopperDetails, codeDescription, backButtonText, codeElement, paymentMethodId, onClickBackButton } = props
  const imgSize = size === 'large' ? 296 : 148

  const codePicture = codeElement || (
    <div className={`${clsPrefix}-code-picture`}>
      <Image
        width={imgSize}
        height={imgSize}
        preview={false}
        loader={
          <div className="arco-image-loader">
            <div className="arco-image-loader-spin">
              <IconLoading />
            </div>
          </div>
        }
        src={shopperDetails?.qr_code || ''}
      />
    </div>
  )

  const renderCodePictureTip = () => {
    if (paymentMethodId === GOPAY_PAYMENT_METHOD.paymentMethod.paymentMethodId) {
      return <div className="upay-voucher-qrcode-scan-tip">{starling('funds.refund.comm.tips_GoPay')}</div>
    } else if (paymentMethodId === PROMPT_PAY_PAYMENT_METHOD.paymentMethod.paymentMethodId) {
      return (
        <div className="upay-voucher-qrcode-scan-tip">
          {starling('funds.check_out.comm.tips_scan_qrcode', {
            app_name: starling('funds.check_out.comm.tips_scan_qrcode_appname'),
          })}
        </div>
      )
    }
    return null
  }

  const codeText = <div className={`${clsPrefix}-code-text`}>{shopperDetails?.payment_code}</div>

  const codeDescriptionNode = <div className={`${clsPrefix}-code-description`}>{codeDescription}</div>

  const renderBackButton = () => {
    if (onClickBackButton && backButtonText) {
      return (
        <div className={`${clsPrefix}-dashboard-back-button`}>
          <Button style={{ minWidth: '167px', height: '36px' }} type="primary" onClick={onClickBackButton}>
            {backButtonText}
          </Button>
        </div>
      )
    }
    return null
  }

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {/* http://tosv.boei18n.byted.org/obj/pipo-sky-admin/sky/barcode-dark_beb445.png */}
      {renderCodePictureTip()}
      {codePicture}
      {codeText}
      {codeDescriptionNode}
      {renderBackButton()}
    </div>
  )
}
