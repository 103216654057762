import React, { forwardRef, useImperativeHandle } from 'react'

import { ISubmitOrderRes, StatusActionTypeEnum, StatusTypeEnum } from '@upay/utils/es/types'

import { IExcitedPaymentMethodsProps, IPaymentsMethodRef } from '../../interface'

/**
 * Paycell 已绑卡
 */
const NeedCheckOTP = forwardRef<IPaymentsMethodRef, IExcitedPaymentMethodsProps>((props, ref): React.ReactElement => {
  const { openCheckOTPModal, linkOpen, handleBillStatus, card } = props

  useImperativeHandle(ref, () => ({
    needHandleResult: () => true,
    handleResult: async (res, submitParams) => {
      const needOtpCode = (res as ISubmitOrderRes)?.needOtpCode
      if (needOtpCode) {
        openCheckOTPModal && openCheckOTPModal({ identityNo: card.identityNo || '', submitType: 'pay', submitParams })
      } else {
        // 默认处理逻辑
        res?.url ? linkOpen(res.url, 'PAYCELL') : handleBillStatus({ actionType: StatusActionTypeEnum.PAY, status: StatusTypeEnum.SUCCESS })
      }
    },
  }))

  return <></>
})

export default NeedCheckOTP
