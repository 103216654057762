import { PaymentMethodItem } from '../../types'

export const RABBITLINEPAY_PAYMENT_METHOD: PaymentMethodItem = {
  paymentMethodType: {
    configuration: {},
    icon: {
      iconUrl: '',
    },
    starling_key: 'pipo_common_ewallet',
    paymentMethodType: 'E_WALLET',
    title: {
      payment: 'pipo_common_ewallet',
    },
  },
  paymentMethod: {
    configuration: {
      png_icon_url: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/rabbit-line-border_824393.png',
      dark_mode_png_icon_url: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/rabbit-line-gray_3f7654.png',
    },
    paymentMethod: 'RABBITLINEPAY',
    paymentMethodId: 'pm_pi_ew_rabbitlinepay_c_r',
    displayName: 'Rabbit LINE Pay',
    starling_key: 'pipo_payin_payment_main_rlp',
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/rabbit-line-border_824393.png',
      grayIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/rabbit-line-gray_3f7654.png',
    },
  },
}
