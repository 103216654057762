import starling from '@/utils/starling'

import { IBankAccountFormConfigItem, IGetBankAccountFormConfigProps } from '../utils'
import { getAUFormConfig } from './au'
import { getBIC_IBANFormConfig } from './bic_iban'
import { getBRFormConfig } from './br'
import { getCAFormConfig } from './ca'
import { getEGFormConfig } from './eg'
import { getGBFormConfig } from './gb'
import { getIDFormConfig } from './id'
import { getKRFormConfig } from './kr'
import { getMXFormConfig } from './mx'
import { getMYFormConfig } from './my'
import { getTHFormConfig } from './th'
import { getTRFormConfig } from './tr'

export const getBankAccountFormConfig = (props: IGetBankAccountFormConfigProps): IBankAccountFormConfigItem | null => {
  if (props.countryCode === 'ID') return getIDFormConfig(props)
  if (props.countryCode === 'TH') return getTHFormConfig(props)
  if (props.countryCode === 'BR') return getBRFormConfig(props)
  if (props.countryCode === 'GB') return getGBFormConfig(props)
  if (props.countryCode === 'EG') return getEGFormConfig(props)
  if (props.countryCode === 'MX') return getMXFormConfig(props)
  if (props.countryCode === 'CA') return getCAFormConfig(props)
  if (props.countryCode === 'MY') return getMYFormConfig(props)
  if (props.countryCode === 'KR') return getKRFormConfig(props)
  if (props.countryCode === 'AU') return getAUFormConfig(props)
  if (props.countryCode === 'TR') return getTRFormConfig(props)
  if (['FR', 'IT', 'DE'].includes(props.countryCode)) return getBIC_IBANFormConfig(props)

  return null
}

export const getUpdateParamsMap = (): Record<string, string> => {
  return {
    holder_name: starling('funds.refund.comm.beneficiary_name'),
    account_no: starling('funds.refund.comm.system_bankaccount_account_number'),
    routing_no: starling('fund.common.BSB_code'),
    // email: starling('funds.refund.comm.system_email'), // 去掉email，免得展示两次
    bic: starling('funds.refund.comm.swift_code'),
    iban: starling('cg.upay.exception.channel_youriban'),
    identity: starling('funds.refund.comm.system_ic_CPF'),
    agency: starling('funds.refund.comm.system_bankaccount_agencycode'),
  }
}
