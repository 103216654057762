"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.SupportedModel = exports.IntegrationMethod = void 0;
// Doc: https://bytedance.feishu.cn/docs/doccnezWowX28BJw5hZyv6mco6f#7acm2P
var SupportedModel;
exports.SupportedModel = SupportedModel;
(function (SupportedModel) {
  SupportedModel["Pay"] = "pay";
  SupportedModel["PayOnToken"] = "pay_on_token";
  SupportedModel["TokenizeAndPay"] = "tokenize_and_pay";
})(SupportedModel || (exports.SupportedModel = SupportedModel = {}));
var IntegrationMethod;
exports.IntegrationMethod = IntegrationMethod;
(function (IntegrationMethod) {
  IntegrationMethod["redirect"] = "redirect";
  IntegrationMethod["direct"] = "direct";
})(IntegrationMethod || (exports.IntegrationMethod = IntegrationMethod = {}));