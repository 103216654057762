import { useCallback, useContext, useRef } from 'react'

import api from '@/services'

import { ParamsContext } from '..'

export const usePublicKey = () => {
  const {
    urlQuery: { token },
  } = useContext(ParamsContext)

  const promiseRef = useRef<Promise<string> | null>()

  const getPublicKeyAsync = useCallback(async () => {
    if (!promiseRef.current) {
      promiseRef.current = api.getPublicKey({ token }).then((res) => res.data?.publicKeyPkcs1 || '')
    }
    return await promiseRef.current
  }, [token])

  return getPublicKeyAsync
}
