import { PipoProvider, CCDCProps } from '@/pipo/utils'

const regionsWithAddress = new Set(['GB', 'US', 'CA'])

// TODO 待确认
export const useCCDCFormConfig = (
  props: CCDCProps,
  watch: (names?: string | string[]) => unknown,
): {
  isBR: boolean
  hasSavingBox: boolean
  hasCNPJ: boolean
  hasPhoneNumber: boolean
  hasEmail: boolean
  hasProxyType: boolean
  hasCPF: boolean
  renderCNPJ: boolean
  showBillingAddress: boolean
} => {
  const { state } = PipoProvider.useContext()
  const { countryOrRegion = '', config = {} } = state
  const { ccdc = {} } = config
  const { mode = 1, showSavingBox = true, hasCNPJ = true, hasBillingAddress = true } = { ...ccdc, ...props }
  const isBR = countryOrRegion === 'BR'
  const hasPhoneNumber = isBR && mode === 2
  const hasEmail = isBR
  const hasProxyType = isBR && mode === 2
  const hasCPF = isBR && (mode === 1 || (mode === 2 && watch('proxy_type') === 'individual'))
  const renderCNPJ = isBR && mode === 2 && watch('proxy_type') === 'company'
  const showBillingAddress = hasBillingAddress && (regionsWithAddress.has(countryOrRegion) || (isBR && mode === 2))

  return {
    isBR,
    hasSavingBox: showSavingBox,
    hasCNPJ,
    hasPhoneNumber,
    hasEmail,
    hasProxyType,
    hasCPF,
    renderCNPJ,
    showBillingAddress,
  }
}
