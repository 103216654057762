import { useCallback, useState } from 'react'

import { StatusActionTypeEnum, StatusTypeEnum } from '@upay/utils/es/types'

import { IOpenStatusProps, IStatusCloseCallbackProps } from '@/components/Status/config'
import useStatus from '@/components/Status/useStatus'
import teaClient from '@/utils/tea'

import { IPayinMethodItem, Order } from '../interface'
import useCombinationStatus from './useCombinationStatus'

interface IUsePayStatusProps {
  paying: boolean
  setPaying: React.Dispatch<React.SetStateAction<boolean>>
  getSelectedPayinMethod: () => IPayinMethodItem | undefined
  setVisible: React.Dispatch<React.SetStateAction<boolean>>
  clearPage: () => void
  /**
   * 关闭Pay弹窗回调。回调入参为isSubmit-是否支付成功
   */
  onPayClose?: (isSubmit: boolean) => void
  handleFBCancelClick: () => Promise<void>
  getOrder: () => Order | undefined
  linkClose: () => void
}

export const useStatusAndLoading = ({
  paying,
  setPaying,
  getSelectedPayinMethod,
  setVisible,
  clearPage,
  onPayClose,
  handleFBCancelClick,
  getOrder,
  linkClose,
}: IUsePayStatusProps) => {
  // 状态窗口钩子
  const { statusProps, openStatus } = useStatus()
  const { combinationStatusProps, openCombinationStatus } = useCombinationStatus()
  const [showLoadingCancelBtn, setShowLoadingCancelBtn] = useState(true)

  const handleStatusClose = useCallback(
    ({ status, retry = true, actionType }: IStatusCloseCallbackProps) => {
      const selectedPayinMethod = getSelectedPayinMethod()
      teaClient.sendResultPageClick({ order_id: getOrder()?.charge_id, pay_way: selectedPayinMethod?.payWay || -1, click_item: 'back' })

      const { SUCCEED, SUCCESS, PROCESSING } = StatusTypeEnum
      const isSuccess = [SUCCEED, SUCCESS, PROCESSING].includes(status as typeof SUCCEED)

      isSuccess && clearPage()
      if (actionType === StatusActionTypeEnum.BIND || (!isSuccess && retry)) {
        // 从绑卡返回时再次打开支付弹窗，引导用户进行支付
        // 预付费失败重试，再次展示支付弹窗
        setVisible(true)
      } else if (!isSuccess && !retry) {
        // 在失败结果页面用户点击cancel走feelgood流程
        handleFBCancelClick()
      } else {
        onPayClose?.(true)
      }
    },
    [clearPage, handleFBCancelClick, onPayClose, setVisible, getSelectedPayinMethod, getOrder],
  )

  const handleBillStatus = useCallback(
    async (props: IOpenStatusProps) => {
      //月账单用户重复支付场景需要隐藏弹窗
      props.hideModal && setVisible(false)
      // 接下来要展示状态，不希望用户中途取消paying页面
      setShowLoadingCancelBtn(false)
      // 如调用时未指定onClose，则使用通用的onClose
      if (!props.onClose) {
        props.onClose = handleStatusClose
      }
      const payWay = getSelectedPayinMethod()?.[props.actionType === StatusActionTypeEnum.PAY ? 'payWay' : 'bindPayWay']
      props.payWay = payWay
      props.recordNo = getOrder()?.charge_id

      teaClient.sendResultPageView({ order_id: getOrder()?.charge_id, pay_way: payWay || -1, result_status: props.status })
      if (props.combination) {
        props.handleBindSuccess = clearPage
        await openCombinationStatus(props)
      } else {
        await openStatus(props)
      }

      setPaying(false)
      linkClose() // 防止新弹窗依然存在
      setShowLoadingCancelBtn(true) // 还原loading cancel
    },
    [getOrder, getSelectedPayinMethod, handleStatusClose, linkClose, openStatus, setPaying, setVisible],
  )

  const handleLoadingCancel = useCallback(() => {
    linkClose()
    setPaying(false)
    setVisible(true)
  }, [linkClose, setPaying, setVisible])

  const loadingProps = { show: paying, showBtn: showLoadingCancelBtn, onCancel: handleLoadingCancel }

  return { statusProps, loadingProps, handleBillStatus, setPaying, combinationStatusProps }
}
