import React, { FC } from 'react'

import { Warning } from '@/static/icon'

import './style.less'

interface IAlertProps {
  message?: string
  description?: string
  style?: React.CSSProperties
}

const Alert: FC<IAlertProps> = ({ message, description, style }) => {
  return message ? (
    <div style={style} className="upay-alert">
      <div className="upay-alert-icon">
        <Warning />
      </div>
      <div className="upay-alert-content">
        <div className="upay-alert-title">{message}</div>
        <div className="upay-alert-desc">{description}</div>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default Alert
