import React, { FC, useEffect, useMemo, useRef, useState } from 'react'

import { Button, Modal } from '@arco-design/web-react'
import { StatusActionTypeEnum, StatusTypeEnum } from '@upay/utils/es/types'

import { CONTENT_MAP, IMAGE_SRC_MAP } from '@/components/Status/config'
import slardar from '@/utils/slardar'
import starling from '@/utils/starling'

type StatusT = {
  /**
   * @description change: 变更支付方式 bind: 绑卡 pay: 支付
   * @type {('change' | 'bind' | 'pay')}
   */
  actionType: StatusActionTypeEnum
  /**
   * @description wait: 超时 failed: 失败 success,succeed: 成功 processing: 处理中
   * @type {('wait' | 'failed' | 'succeed' | 'success'| 'processing')}
   */
  status: StatusTypeEnum
  errMsg?: string
  visible?: boolean
  payway?: number
  button?: JSX.Element
  onClose: () => void
  /**
   * 账单种类，预付/后付等,用于埋点
   */
  billType?: string
}

const { SUCCEED, SUCCESS } = StatusTypeEnum

const Status: FC<StatusT> = (props) => {
  const { status, actionType, errMsg, onClose, visible = true, payway, button, billType } = props
  const [remainTime, setRemainTime] = useState<number>(3)
  const timer = useRef<number | null>(null)
  const isSuccess = useMemo(() => [SUCCESS, SUCCEED].includes(status), [status])

  useEffect(() => {
    // 页面渲染埋点
    visible && slardar.timer(`resultEvent`, 1, { status, actionType, billType, errMsg, payway })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  useEffect(() => {
    if (!isSuccess || !visible || timer.current !== null || button) return

    timer.current = window.setInterval(() => {
      setRemainTime((prevTime) => {
        if (prevTime > 1) return --prevTime
        timer.current && clearInterval(timer.current)
        timer.current = null
        onClose()
        return 3
      })
    }, 1000)
  }, [onClose, isSuccess, visible, button])

  const handelClose = () => {
    slardar.timer('statusCloseBtnClick')
    timer.current && clearInterval(timer.current)
    timer.current = null
    onClose()
  }

  return (
    <Modal className="upay-modal upay-modal-simple upay-status-modal" visible={visible} footer={null} closable={false}>
      <div className="upay-status">
        <img src={IMAGE_SRC_MAP.get(status)} className="upay-status-img" />

        <div className="upay-status-content">{CONTENT_MAP()[`${actionType}-${status}`]?.statusTitle}</div>
        <div className="upay-status-error">{errMsg}</div>

        {button || (
          <Button type="primary" className="upay-status-btn" onClick={handelClose}>
            {isSuccess
              ? starling('funds.refund.comm.close', {
                  number: remainTime,
                })
              : starling('funds.refund.comm.try_again')}
          </Button>
        )}
      </div>
    </Modal>
  )
}
export default Status
