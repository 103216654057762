import { PaymentMethodItem } from '../../types'
/**
 * QIWI 需要填写的表单的位数
 */
export type QIWIFormData = {
  phone?: string
  phone_country_code?: string
}

// TODO: Change
export const QIWI_PAYMENT_PARAMS_MAP: Record<string, string> = {
  phone: 'eg_pi_ew_qiwi_c_r_local_ru_phone',
  phone_country_code: 'eg_pi_ew_qiwi_c_r_local_ru_phone_country_code',
}

export const QIWI_PAYMENT_METHOD: PaymentMethodItem = {
  paymentMethodType: {
    configuration: {},
    paymentMethodType: 'E_WALLET',
    starling_key: 'pipo_common_ewallet',
    icon: {
      iconUrl: '',
    },
    title: {
      payment: 'E-Wallet',
    },
  },
  paymentMethod: {
    configuration: {
      png_icon_url: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/QIWIPhoneWhite4X_4eb385.png',
      dark_mode_png_icon_url: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/QIWIPhoneBlack4X_f46a69.png',
    },
    paymentMethod: 'QIWI',
    paymentMethodId: 'pm_pi_ew_qiwi_c_r',
    displayName: 'QIWI',
    starling_key: 'pipo_payin_payment_main_qiwi',
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/QIWIPhoneWhite4X_4eb385.png',
      pngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/QIWIPhoneWhite4X_4eb385.png',
      darkModePngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/QIWIPhoneBlack4X_f46a69.png',
      grayIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/qiwi-gray_ef6be9.png',
      detailIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/qiwi-detail_78ab51.svg',
    },
  },
}
