import React from 'react'

function SvgShieldBg(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="459" height="422" viewBox="0 0 459 422" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g filter="url(#filter0_f)">
        <ellipse cx="236" cy="204.5" rx="104" ry="83.5" fill="white" />
      </g>
      <g filter="url(#filter1_f)">
        <ellipse cx="243" cy="205.5" rx="111" ry="105.5" fill="#3955F6" />
      </g>
      <g filter="url(#filter2_f)">
        <circle cx="171.5" cy="206.5" r="71.5" fill="#FF004F" fillOpacity="0.5" />
      </g>
      <g filter="url(#filter3_f)">
        <ellipse cx="301" cy="298.5" rx="58" ry="23.5" fill="#06D6DB" />
      </g>
      <g filter="url(#filter4_f)">
        <path
          d="M241.787 277.257C244.163 294.77 214.379 307.874 184.006 311.995C153.632 316.116 119.456 290.496 117.08 272.984C114.705 255.471 144.578 231.116 174.952 226.995C205.325 222.874 239.411 259.745 241.787 277.257Z"
          fill="#FEBA69"
        />
      </g>
      <defs>
        <filter id="filter0_f" x="32" y="21" width="408" height="367" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter1_f" x="32" y="0" width="422" height="411" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter2_f" x="0" y="35" width="343" height="343" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter3_f" x="143" y="175" width="316" height="247" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter4_f" x="16.9475" y="126.676" width="324.973" height="285.76" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur" />
        </filter>
      </defs>
    </svg>
  )
}

export default SvgShieldBg
