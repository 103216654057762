import React from 'react'
import { Controller, UseFormSetValue, UseFormTrigger } from 'react-hook-form'

import clsx from 'clsx'

import { useExpiryDate } from '@/pipo/hooks'
import type { CCDCFormData } from '@/pipo/utils'

import { CustomInput, ItemBlock } from '..'

const prefix = 'pipo-pc-expiry-date'

export const ExpiryDate = (props: {
  errors: any
  control: any
  title?: string
  trigger: UseFormTrigger<CCDCFormData>
  setValue?: UseFormSetValue<CCDCFormData>
}) => {
  const { title, control, errors, trigger } = props
  const {
    monthValidator,
    yearValidator,
    monthHandleRef,
    yearHandleRef,
    monthInputRef,
    yearInputRef,
    focused,
    getExpiryDateError,
    handleMonthChange,
    handleYearChange,
    handleMonthKeyUp,
    handleYearKeyDown,
    handleBlur,
    handleFocus,
    handleClear,
  } = useExpiryDate(props)
  const errorMessage = getExpiryDateError(errors.expiration_month, errors.expiration_year)
  const errorState = Boolean(errorMessage && (errors.expiration_month || errors.expiration_year))
  return (
    <ItemBlock style="half" title={title} errorState={errorState} errorMessage={errorMessage}>
      <div
        className={clsx(`${prefix}-wrapper`, `arco-input`, `arco-input-size-large`, {
          ['expiry-date-focused']: focused,
          ['arco-input-error']: errorState,
        })}
      >
        <Controller
          rules={{
            required: true,
            validate: monthValidator,
          }}
          name="expiration_month"
          control={control}
          render={({ field }) => (
            <div className={`${prefix}-month`}>
              <CustomInput
                {...field}
                size="large"
                className="pipo-pc-expiry-month-input"
                ref={monthInputRef}
                handleRef={monthHandleRef}
                type="number"
                placeholder="MM"
                onChange={(value, e) => handleMonthChange(e, field.onChange)}
                onKeyUp={handleMonthKeyUp}
                onBlur={(e) => {
                  handleBlur(e, 'expiration_month')
                  field.onBlur()
                }}
                onFocus={handleFocus}
              />
            </div>
          )}
        />
        <span className="pipo-pc-expiry-date-splitor">/</span>
        <Controller
          rules={{
            required: true,
            validate: yearValidator,
          }}
          name="expiration_year"
          control={control}
          render={({ field }) => (
            <div className={`${prefix}-year`}>
              <CustomInput
                {...field}
                size="large"
                allowClear
                className="pipo-pc-expiry-year-input"
                ref={yearInputRef}
                handleRef={yearHandleRef}
                type="number"
                max={99}
                maxLength={2}
                placeholder="YY"
                onChange={(value, e) => handleYearChange(e, field.onChange)}
                onKeyDown={handleYearKeyDown}
                onBlur={(e) => {
                  trigger('expiration_month')
                  handleBlur(e, 'expiration_year')
                  field.onBlur()
                }}
                onFocus={handleFocus}
                onClear={handleClear}
              />
            </div>
          )}
        />
      </div>
      {/* <div className="tt-text-field--divider background-color-LineInput" />
      {errorMessage && (errors.expiration_month || errors.expiration_year) && (
        <div className="mt-8 text-color-Negative flex items-center P1-Regular">
          <IconExclamationMarkTriangleFill
            className="self-start"
            style={{
              fontSize: "16px",
            }}
          />
          <span className="mx-8">{errorMessage}</span>
        </div>
      )} */}
    </ItemBlock>
  )
}
