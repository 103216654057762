import React, { forwardRef, useImperativeHandle } from 'react'

import SafeJSON from '@/utils/SafeJSON'

import { IExcitedPaymentMethodsProps, IPaymentsMethodRef } from '../../interface'

/**
 * Venmo 已绑卡
 */
const Venmo = forwardRef<IPaymentsMethodRef, IExcitedPaymentMethodsProps>((props, ref): React.ReactElement => {
  useImperativeHandle(ref, () => ({
    async processSubmitData(submitParams) {
      const riskInfo = SafeJSON.parse(submitParams.riskInfo)
      //@ts-ignore
      const extraRiskInfo = SafeJSON.parse(window.pipoVenmoDataCollectorInstance?.deviceData)
      submitParams.riskInfo = SafeJSON.stringify({
        ...riskInfo,
        ...extraRiskInfo,
      })
      return submitParams
    },
  }))

  return <></>
})

export default Venmo
