import { BankListSelect, CountryCode, Email, HolderName, ItemInputNumber, PhoneNumber } from '@/pipo/pc/inner-components'
import { validator } from '@/pipo/utils'
import starling from '@/utils/starling'

import { IGetLocalBankTransferFormConfigProps, ILocalBankTransferConfigItem } from '../utils'

export const getVNFormConfig = ({ bankList, cardHolderInfo }: IGetLocalBankTransferFormConfigProps): ILocalBankTransferConfigItem => {
  const baseFormSchema = [
    {
      field: 'bank_code',
      widget: BankListSelect,
      widgetProps: {
        title: starling('funds.check_out.comm.channel_bank'),
        placeholder: starling('funds.refund.comm.bank_selection'),
        bankList,
      },
      rules: { required: true },
    },
    {
      field: 'holder_name',
      widget: HolderName,
      widgetProps: {
        len: 'full',
        title: starling('funds.refund.comm.system_name'),
        placeholder: starling('funds.refund.comm.system_name_description'),
        maxLength: 129,
        selectList: cardHolderInfo?.holderNameList,
      },
      rules: { required: true, pattern: validator.vnHolderName },
    },
    {
      field: 'account_no',
      widget: ItemInputNumber,
      widgetProps: {
        title: starling('funds.refund.comm.system_bankaccount_account_number'),
        placeholder: starling('funds.refund.comm.system_bankaccount_account_number_placeholder'),
        maxLength: 30,
      },
      rules: { required: true },
    },
    {
      field: 'email',
      widget: Email,
      widgetProps: {
        title: starling('funds.refund.comm.system_email'),
        placeholder: starling('funds.refund.comm.system_email_placeholder'),
      },
      rules: { required: true, pattern: validator.emailRegex },
    },
  ]
  return {
    allowAccountType: ['c', 'b'],
    needInfo: ['bank_list'],
    formSchema: baseFormSchema,
    toCFormSchema: [
      ...baseFormSchema,
      {
        field: 'phone_country_code',
        widget: CountryCode,
        widgetProps: {
          countryCode: '+84',
        },
      },
      {
        field: 'phone',
        widget: PhoneNumber,
        widgetProps: {
          phoneNumberStyle: 'full',
          addBefore: '+84',
          title: starling('funds.refund.comm.system_phonenumber'),
          placeholder: starling('funds.refund.comm.system_phonenumber_placeholder'),
          maxLength: 10,
        },
        rules: { required: true, pattern: validator.vnPhoneNumberRegex },
      },
    ],
  }
}
