/* eslint-disable @typescript-eslint/no-unsafe-argument */
import starling from '@/utils/starling'

import { chunkString, pickNumericAlphabet } from '.'

const setDisplayBankIcon = (
  val: string,
  supportBank: {
    code: string
    icon: string
  }[],
): string => {
  const bankRegex = /^TR\d{4}(\d{3})\d*/
  const valWithoutSpace = val?.replace(/\s/g, '')
  const bankCodeMatch = valWithoutSpace.match(bankRegex)
  const bankCode = bankCodeMatch ? bankCodeMatch[1] : ''
  const matchIcon = supportBank.find((ele) => ele.code === bankCode)
  return matchIcon ? matchIcon.icon : ''
}

const internetBankIcon = [
  {
    code: '046',
    icon: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/akbankgrey_c1216c.png',
  },
  {
    code: '064',
    icon: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/isbankgrey_fd5301.png',
  },
  {
    code: '203',
    icon: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/albarakagrey_b0c72c.png',
  },
  {
    code: '015',
    icon: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/vakifbankgrey_be5faa.png',
  },
  {
    code: '134',
    icon: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/denizbankgrey_0caae5.png',
  },
  {
    code: '205',
    icon: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/kuveytturkgreynew_7e540a.png',
  },
  {
    code: '103',
    icon: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/fibabankagreynew_335225.png',
  },
]

const banktransferBankIcon = [
  ...internetBankIcon,
  {
    code: '067',
    icon: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/yapikredigrey_a21d9d.png',
  },
  {
    code: '124',
    icon: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/alternatifbankgrey_74ee22.png',
  },
  {
    code: '062',
    icon: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/garantigrey_8c7389.png',
  },
  {
    code: '012',
    icon: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/halkbankgrey_c2146f.png',
  },
  {
    code: '111',
    icon: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/qnbgrey_ac19a8.png',
  },
  {
    code: '206',
    icon: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/turkiyefinansgrey_d6d00a.png',
  },
  {
    code: '010',
    icon: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/ziraatbankgrey_794716.png',
  },
  {
    code: '059',
    icon: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/sekerbankgrey_9cfa80.png',
  },
  {
    code: '032',
    icon: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/tebgrey_cc6cf8.png',
  },
  {
    code: '099',
    icon: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/inggreynew_f3fc8b.png',
  },
]

const formatValue = (value: string) => {
  if (!value) return ''
  if (value.includes(' ')) {
    value = value?.replace(/\s/g, '')
  }
  if (value.length > 4) {
    return chunkString(value, 4).join(' ')
  }
  return value
}

const ibanValidInput = /^[a-zA-Z0-9\s]*$/

const IBANBanktransferValidator = {
  validBank: (value = '') => {
    const IBANNo = value?.replace(/\s/g, '')
    return /^TR\d{4}((046)|(064)|(203)|(015)|(134)|(205)|(103)|(067)|(124)|(062)|(012)|(111)|(206)|(010)|(059)|(032)|(099))\d*$/.test(IBANNo)
  },
  validLength: (value = '') => {
    const IBANNo = value?.replace(/\s/g, '')
    return /^TR\d{24}$/.test(IBANNo)
  },
}

const IBANInternetValidator = {
  validBank: (value = '') => {
    const IBANNo = value?.replace(/\s/g, '')
    return /^TR\d{4}((046)|(064)|(203)|(015)|(134)|(205)|(103))\d*$/.test(IBANNo)
  },
  validLength: (value = '') => {
    const IBANNo = value?.replace(/\s/g, '')
    return /^TR\d{24}$/.test(IBANNo)
  },
}

const errorMessage = (
  errorName: string | undefined,
  message: string,
  getErrorText: (type: string | undefined) => string,
  t: (
    key: string,
    replaceItem?:
      | {
          value: string
        }
      | undefined,
  ) => string,
) => {
  if (errorName === 'required') {
    return getErrorText(errorName)
  } else if (errorName === 'validBank') {
    return starling('cg.upay.exception.channel_IBAN_errormsg')
  } else {
    return getErrorText(errorName)
  }
}

const onBankIbanChange = (val: string, onChange: (val?: string) => void, callback: (...event: unknown[]) => void) => {
  if (!val) {
    // IbanNumberInputRef.current = undefined;
    onChange(val)
    callback(val)
    return
  }
  // e.target.value = formatValue(value);
  if (/^[a-zA-Z0-9\s]*$/.test(val)) {
    // pickNumberic for pure number
    const formatNumber = pickNumericAlphabet(val)
    onChange(formatNumber)
    callback(formatNumber)
  }
}

const formatCursor = (oldVal: string, newVal: string, cursorPosition: number) => {
  const editionType = newVal.length > oldVal.length ? 'insert' : 'delete'
  if (cursorPosition !== 0 && cursorPosition % 5 === 0) {
    return cursorPosition + (editionType === 'insert' ? 1 : -1)
  } else {
    return cursorPosition
  }
}

export {
  setDisplayBankIcon,
  formatValue,
  ibanValidInput,
  IBANBanktransferValidator,
  errorMessage,
  IBANInternetValidator,
  onBankIbanChange,
  banktransferBankIcon,
  internetBankIcon,
  formatCursor,
}
