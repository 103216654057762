/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useContext, useEffect, useMemo, useState } from 'react'

import { Button, Checkbox, Skeleton, Spin } from '@arco-design/web-react'
import { useRequest } from '@byted/hooks'
import clsx from 'clsx'

import { PageModal } from '@/components'
import { PUBSUB_TYPES, RESPONSE_CODE } from '@/constant'
import api from '@/services'
import { CardTransferStatusEnum, ITransferableCardItem } from '@/services/types'
import { ParamsContext } from '@/stores'
import { ModeTypeEnum, ShowTypeEnum } from '@/typings'
import slardar from '@/utils/slardar'
import starling from '@/utils/starling'

import TransferStatus from './components/TransferStatus'
import { useTransferStatus } from './hooks'

import './index.less'

// const PID = 'transfer'

const Transfer: React.FC = () => {
  const { params, urlQuery, uPubSub } = useContext(ParamsContext)
  const { token, showType } = urlQuery || {}
  const isIndependentPage = params.mode === ModeTypeEnum.HostedPage
  const isDropIn = showType === ShowTypeEnum.DropIn

  const [visible, setVisible] = useState(params.defaultVisible)
  const [selectedCards, setSelectedCards] = useState<string[]>([])
  const {
    data: list,
    loading: loadingList,
    run: runList,
  } = useRequest(
    (): Promise<ITransferableCardItem[]> =>
      api.getTransferableCardList({ token }).then((res) => {
        const list = res?.list || []
        setSelectedCards(list.map((item) => item.id))
        return list
      }),
    {
      auto: false,
      onSuccess: (data) => {
        // 如果列表为空，直接走后续流程
        if (!data?.length) {
          setVisible(false)
          openStatus({
            status: CardTransferStatusEnum.Empty,
            errorCode: '',
            errorMsg: '',
            details: [],
          })
        }
      },
    },
  )

  const { statusProps, openStatus } = useTransferStatus({ reopen: () => setVisible(true), cardList: list })

  const { loading: posting, run: runTransfer } = useRequest(api.postCardTransfer, {
    auto: false,
  })

  useEffect(() => {
    if (visible) {
      setSelectedCards([])
      runList()
    }
  }, [visible, runList])

  // 管理提交按钮状态
  const disabled = useMemo(() => {
    return loadingList || !selectedCards?.length
  }, [loadingList, selectedCards])

  useEffect(() => {
    const subId = uPubSub.subscribe(PUBSUB_TYPES.OPEN_MODAL, () => {
      setVisible(true)
    })
    uPubSub.publish(PUBSUB_TYPES.DID_MOUNT)
    return () => uPubSub.unsubscribe(subId)
  }, [uPubSub])

  const handleCancel = () => {
    slardar.timer('transferCancelBtnClick')

    uPubSub.publish(PUBSUB_TYPES.MODAL_CANCEL, false)
    setVisible(false)
  }

  const handleSubmit = async () => {
    try {
      slardar.timer('transferSubmitBtnClick', 1, { selectedCards })

      uPubSub.publish(PUBSUB_TYPES.MODAL_SUBMIT)

      const res = await runTransfer({ token, cardIds: selectedCards })
      const { code, data, msg } = res || {}

      if (code !== RESPONSE_CODE.SUCCESS || !data) {
        throw new Error(msg || '')
      }
      setVisible(false)
      openStatus(data)
    } catch (e: any) {
      console.log(e)
      setVisible(false)
      openStatus({
        status: CardTransferStatusEnum.Error,
        errorMsg: e?.message || e?.msg || e,
        details: [],
      })
    }
  }

  const handleToggleAll = (checked: boolean) => {
    setSelectedCards(checked ? list?.map((item) => item.id) || [] : [])
  }

  const renderContent = () => {
    const isSelectedAll = !!selectedCards?.length && selectedCards.length === (list?.length || 0)
    const isIndeterminate = !!selectedCards?.length && selectedCards.length < (list?.length || 0)

    return (
      <>
        <Spin loading={posting} className="upay-transfer-spin">
          <Skeleton className="upay-transfer-skeleton" loading={loadingList} animation>
            {list && list.length > 1 && (
              <div className="upay-transfer-select-all">
                <Checkbox checked={isSelectedAll} indeterminate={isIndeterminate} onChange={handleToggleAll}>
                  {starling('funds.refund.comm.transfer_selectall_paymentmethod_comm')}
                </Checkbox>
              </div>
            )}

            <Checkbox.Group
              value={selectedCards}
              onChange={(val) => setSelectedCards(val)}
              className={clsx('upay-transfer-list', {
                'upay-transfer-list-scroll': (list?.length || 0) >= 5, // 大于等于5条数据时展示滚动条
              })}
              direction="vertical"
              options={
                list?.map((item) => {
                  return {
                    value: item.id,
                    label: (
                      <div key={item.id} className="upay-transfer-item">
                        <div className="upay-transfer-item-left">
                          <img src={item.imageUrl} className="upay-transfer-item-icon" />

                          <span className="upay-transfer-item-number">
                            {item.identityType || ''} {item.identityNo}
                          </span>

                          {item.expire_time && <span className="upay-transfer-item-date">{`(${item.expire_time})`}</span>}

                          {item.primary && (
                            <div className="upay-transfer-item-tag upay-transfer-item-tag-primary">{starling('funds.refund.comm.primary')}</div>
                          )}
                        </div>

                        {!!item.accountSourceDesc && <div className="upay-transfer-item-tag">{item.accountSourceDesc}</div>}
                      </div>
                    ),
                  }
                }) || []
              }
            />
          </Skeleton>
        </Spin>

        <TransferStatus {...statusProps} />
      </>
    )
  }

  const renderFooter = () => {
    return (
      <div className="upay-modal-footer">
        <div className="upay-modal-footer-left">
          <Button onClick={handleCancel}>{starling('funds.refund.comm.cancel')}</Button>
        </div>
        <div className="upay-modal-footer-right">
          <Button type="primary" disabled={disabled} loading={posting} onClick={handleSubmit}>
            {starling('funds.refund.comm.transfer')}
          </Button>
        </div>
      </div>
    )
  }

  if (isDropIn) {
    return (
      <div className="upay-transfer upay-transfer-modal">
        <div className="upay-transfer-desc">{starling('funds.refund.comm.transfer_paymentmethod_bc')}</div>

        {renderContent()}

        <div className="upay-modal-footer upay-transfer-modal-footer">
          <Button type="primary" disabled={disabled} loading={posting} onClick={handleSubmit}>
            {starling('funds.refund.comm.button_enter_BC')}
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div className="upay-transfer">
      <PageModal
        visible={isIndependentPage || visible}
        contentHeight={params.height}
        mode={isIndependentPage && params.mode}
        className="upay-transfer-modal upay-modal"
        footer={renderFooter()}
        closable={false}
        alignCenter={false}
      >
        <div className="upay-modal-header">
          <div className="upay-modal-header-title">{starling('funds.refund.comm.transfer_paymentmethod_comm')}</div>
        </div>

        <div className="upay-transfer-desc">{starling('funds.refund.comm.transfer_select_paymentmethod_comm')}</div>

        {renderContent()}
      </PageModal>
    </div>
  )
}

export default Transfer
