import { Email, HolderName, ItemInputNumber } from '@/pipo/pc/inner-components'
import { validator } from '@/pipo/utils'
import starling from '@/utils/starling'

import { IBankAccountFormConfigItem, IGetBankAccountFormConfigProps } from '../utils'

export const getGBFormConfig = ({ cardHolderInfo }: IGetBankAccountFormConfigProps): IBankAccountFormConfigItem => {
  const basicSchema = [
    {
      field: 'routing_no',
      widget: ItemInputNumber,
      widgetProps: {
        title: starling('funds.check_out.comm.sort_code'),
        placeholder: '123456',
        maxLength: 6,
      },
      rules: { required: true, pattern: validator.gbSortCode },
      ruleMessages: { pattern: starling('cg.upay.exception.channel_id_num_error_message', { num: 6 }) },
    },
    {
      field: 'account_no',
      widget: ItemInputNumber,
      widgetProps: {
        title: starling('funds.refund.comm.system_bankaccount_account_number'),
        placeholder: starling('funds.refund.comm.system_bankaccount_account_number_placeholder'),
        maxLength: 8,
      },
      rules: { required: true, pattern: validator.gbAccountNo },
      ruleMessages: { pattern: starling('cg.upay.exception.channel_id_num_error_message', { num: 8 }) },
    },
    {
      field: 'email',
      widget: Email,
      widgetProps: {
        title: starling('funds.refund.comm.system_email'),
        placeholder: starling('funds.refund.comm.system_email_placeholder'),
      },
      rules: { required: true, pattern: validator.emailRegex },
    },
  ]
  return {
    allowAccountType: ['b', 'c'],
    formSchema: [
      {
        field: 'holder_name',
        widget: HolderName,
        widgetProps: {
          title: starling('funds.refund.comm.system_name'),
          placeholder: starling('funds.refund.comm.system_name_description'),
          maxLength: 70,
          selectList: cardHolderInfo?.holderNameList,
        },
        rules: { required: true, pattern: validator.gbHolderName },
      },
      ...basicSchema,
    ],
    toCFormSchema: [
      {
        field: 'holder_name',
        widget: HolderName,
        widgetProps: {
          title: starling('funds.refund.comm.system_name'),
          placeholder: starling('funds.refund.comm.system_name_description'),
          maxLength: 35,
          selectList: cardHolderInfo?.holderNameList,
        },
        rules: { required: true, pattern: validator.gbHolderName },
      },
      ...basicSchema,
    ],
  }
}
