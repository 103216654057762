import { useCallback, useContext, useEffect, useState } from 'react'

import api from '@/services'
import { ParamsContext } from '@/stores'

import { payLogger } from '..'

interface IUseFailedChannelTipProps {
  visible: boolean
}

export const useFailedChannelTip = ({ visible }: IUseFailedChannelTipProps) => {
  const {
    urlQuery: { token },
  } = useContext(ParamsContext)
  // 全局错误信息提示
  const [globalNotification, setGlobalNotification] = useState<{ title: string; desc: string } | null>(null)

  useEffect(() => {
    visible && getFailedChannelTip()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  const getFailedChannelTip = useCallback(() => {
    api
      .getFailedChannels({ token })
      .then((res) => {
        if (res.data?.channelErrorTitle && res.data?.channelErrorDesc) {
          setGlobalNotification({ title: res.data.channelErrorTitle, desc: res.data.channelErrorDesc })
        }
      })
      .catch((e) => {
        payLogger.warn('get failed channel tip failed', e)
      })
  }, [token])

  return { globalNotification }
}
