import { PaymentMethodItem } from '../../types'
/**
 * paypal 需要填写的表单的位数
 */
export type PaypalFormData = {
  card_number?: string
  holder_name?: string
  cvv?: string
  expiration_month?: string
  expiration_year?: string
}

// TODO: Change
export const PAYPAL_PAYMENT_PARAMS_MAP: Record<string, string> = {
  card_number: 'eg_ccdc_global_card_number',
  expiration_month: 'eg_ccdc_global_expiration_month',
  expiration_year: 'eg_ccdc_global_expiration_year',
  cvv: 'eg_ccdc_global_cvv',
  holder_name: 'eg_ccdc_global_holder_name',
}

export const PAYPAL_PAYMENT_METHOD: PaymentMethodItem = {
  paymentMethodType: {
    configuration: {},
    icon: {
      iconUrl: '',
    },
    starling_key: 'pipo_common_ewallet',
    paymentMethodType: 'E_WALLET',
    title: {
      payment: 'pipo_common_ewallet',
    },
  },
  paymentMethod: {
    configuration: {},
    paymentMethod: 'PAYPAL',
    paymentMethodId: 'pm_pi_ew_paypal_c_r',
    displayName: 'PAYPAL',
    starling_key: 'payin_pay_with_PayPal',
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/paypal-light-border_4305a4.svg',
      pngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/paypal-light-border_839c52.png',
      darkModePngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/paypal-dark_d99b41.png',
      grayIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/paypal-light-bg_6724b7.svg',
    },
  },
}
