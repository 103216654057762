import React from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'

import { useSecurityCode, useTouchedErrors } from '@/pipo/hooks'
import { CCDCFormData, SUPPORT_PAYMENT_METHODS_ID_MAP, SupportPaymentMethodId } from '@/pipo/utils'
import starling from '@/utils/starling'

import { CVV, ExpiryDate } from '../../inner-components'

const clsPrefix = 'pipo-bindcard-pc'

export const CCDCUpdate = (props?: { form?: UseFormReturn; paymentMethodId: string | undefined }) => {
  const { form = {}, paymentMethodId } = props || {}
  const {
    control,
    trigger,
    getValues,
    setValue,
    formState: { errors: formErrors, touchedFields },
  } = form as UseFormReturn
  const errors = useTouchedErrors<CCDCFormData>({
    touchedFields,
    errors: formErrors,
  })
  const currentCardBrand = SUPPORT_PAYMENT_METHODS_ID_MAP[paymentMethodId as SupportPaymentMethodId]

  const { cvvPlaceholder, cvvValidator, cvvTitle, getCVVError } = useSecurityCode(currentCardBrand, trigger, getValues)

  return (
    <div className={`${clsPrefix}-flex-container`}>
      <div className={`${clsPrefix}-form-wrapper`}>
        <div className={`${clsPrefix}-subform-wrapper`}>
          <ExpiryDate
            title={starling('funds.refund.comm.system_expiry_date')}
            errors={errors}
            control={control}
            trigger={trigger}
            setValue={setValue}
          />
          <Controller
            rules={cvvValidator}
            name="cvv"
            control={control}
            render={({ field }) => (
              <CVV {...field} title={cvvTitle} placeholder={cvvPlaceholder} hasError={Boolean(errors.cvv)} errorMessage={getCVVError(errors.cvv)} />
            )}
          />
        </div>
      </div>
    </div>
  )
}
