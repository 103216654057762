"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.isCurrentMergedItemAlreadySelected = exports.getItemLabel = void 0;
var getItemLabel = function getItemLabel(item, t) {
  var _item$paymentMethod$t;
  if (item.needMerge) {
    var _item$paymentMethodTy;
    return t(((_item$paymentMethodTy = item.paymentMethodType.title) === null || _item$paymentMethodTy === void 0 ? void 0 : _item$paymentMethodTy.payment) || '') || item.paymentMethodType.displayName || 'NEED_I18N';
  }
  return t(((_item$paymentMethod$t = item.paymentMethod.title) === null || _item$paymentMethod$t === void 0 ? void 0 : _item$paymentMethod$t.payment) || '') || t(item.paymentMethod.starling_key) || item.paymentMethod.displayName || 'NEED_I18N';
};
exports.getItemLabel = getItemLabel;
var isCurrentMergedItemAlreadySelected = function isCurrentMergedItemAlreadySelected(item, selected) {
  var _selected$, _selected$2, _selected$3, _selected$3$paymentMe, _item$paymentMethodTy2;
  var isSelectedNeedMerge = (_selected$ = selected[0]) === null || _selected$ === void 0 ? void 0 : _selected$.needMerge;
  // 例如mir这种非直连ccdc，与其它ccdc的type一样，但不要被merge，所以需要判断下needMerge
  if (((_selected$2 = selected[0]) === null || _selected$2 === void 0 ? void 0 : _selected$2.type) !== 'stored' && isSelectedNeedMerge === undefined) {
    // 例如subPaymentMethodList中的支付方式没有needMerge字段，但他们肯定是被merge的
    // 担心选中UIOtherPaymentMethodItem情况下没有type字段，判断type !== "stored"保险一点
    isSelectedNeedMerge = true;
  }
  return item.needMerge === isSelectedNeedMerge && ((_selected$3 = selected[0]) === null || _selected$3 === void 0 ? void 0 : (_selected$3$paymentMe = _selected$3.paymentMethodType) === null || _selected$3$paymentMe === void 0 ? void 0 : _selected$3$paymentMe.paymentMethodType) === (item === null || item === void 0 ? void 0 : (_item$paymentMethodTy2 = item.paymentMethodType) === null || _item$paymentMethodTy2 === void 0 ? void 0 : _item$paymentMethodTy2.paymentMethodType) && selected[0].type === item.type;
};
exports.isCurrentMergedItemAlreadySelected = isCurrentMergedItemAlreadySelected;