import React from 'react'
import { Controller, ControllerFieldState, FieldErrors, FieldValues, UseControllerProps, UseFormReturn } from 'react-hook-form'

import { PipoProvider } from '@/pipo/pc'

export type WidgetType = React.ForwardRefExoticComponent<any>

export interface IFormBuilderItemProps<Widget extends WidgetType = WidgetType> {
  field: string
  noController?: boolean
  widget: Widget
  widgetProps: Partial<Parameters<Widget>[0]> & { fieldState?: ControllerFieldState }
  rules?: UseControllerProps['rules']
  ruleMessages?: Record<string, string>
}

export interface IFormBuilderProps<T extends FieldValues = FieldValues> {
  form: UseFormReturn<T>
  schema: IFormBuilderItemProps[]
  errors: Partial<FieldErrors<T>>
  handleBlur?: (...args: any[]) => void
}

const FormBuilder = <T extends FieldValues = FieldValues>(props: IFormBuilderProps<T>): React.ReactElement => {
  const { schema = [], form, errors, handleBlur } = props
  const { getErrorText } = PipoProvider.useContext()

  const {
    control,
    // formState: { touchedFields },
  } = form

  const getErrorMessage = (item: IFormBuilderItemProps<WidgetType>) => {
    const errorType = errors?.[item.field]?.type as string
    if (!errorType) return ''

    if (item.ruleMessages?.[errorType]) {
      return item.ruleMessages[errorType]
    }
    return getErrorText(errors?.[item.field]?.type as string | undefined)
  }

  return (
    <>
      {schema.map((item, index) => {
        const Comp = item.widget

        if (item.noController) {
          return (
            // @ts-ignore
            // 无需controller包裹的组件，如包含多个字段的<BillingAddress />
            <Comp errors={errors} error={errors?.[item.field]} control={control} {...item.widgetProps} key={index} />
          )
        }

        return (
          <Controller
            key={item.field}
            // @ts-ignore
            name={item.field}
            control={control}
            rules={item.rules}
            render={({ field, fieldState }) => {
              return (
                // @ts-ignore
                <Comp
                  {...field}
                  {...item.widgetProps}
                  fieldState={fieldState}
                  error={errors?.[item.field]}
                  errorMessage={getErrorMessage(item)}
                  onBlur={() => {
                    field.onBlur()
                    handleBlur?.(item.field, fieldState)
                  }}
                />
              )
            }}
          />
        )
      })}
    </>
  )
}

export default FormBuilder
