import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'

import { PAY_WAY_TYPE } from '@/constant'
import { USBanktransferForm } from '@/pipo/pc'
import { PipoComponentRef } from '@/pipo/utils'
import SafeJSON from '@/utils/SafeJSON'

import { IPaymentMethodsProps, IPaymentsMethodRef } from '../../interface'

const BankTransferUS = forwardRef<IPaymentsMethodRef, IPaymentMethodsProps>((props, ref): React.ReactElement => {
  const { changeNewPaymentStatus } = props

  const [isValid, setIsValid] = useState(false)
  const USBankTransferRef = useRef<PipoComponentRef>(null)

  // 同步验证状态
  useEffect(() => {
    changeNewPaymentStatus(PAY_WAY_TYPE.BANK_TRANSFER_US, { valid: isValid })
  }, [isValid, changeNewPaymentStatus])

  useImperativeHandle(ref, () => ({
    processSubmitData: async (submitParams) => {
      if (USBankTransferRef.current?.validateSubmitData) {
        const { paymentParams, storeCard, paymentMethod } = await USBankTransferRef.current?.validateSubmitData()
        const paymentMethodObj = {
          method_id: paymentMethod.paymentMethodId,
          payment_elements: paymentParams,
          method_type: paymentMethod.paymentMethod,
          type: '',
          method_params: null,
        }
        submitParams.channelParameter = SafeJSON.stringify(paymentMethodObj)
        submitParams.bindAndPay = storeCard
      }
      return submitParams
    },
  }))

  const onValidChange = useCallback((value: boolean) => {
    setIsValid(value)
  }, [])

  return (
    <div className="upay-banktrans-container">
      <USBanktransferForm ref={USBankTransferRef} onValidate={onValidChange} />
    </div>
  )
})

export default BankTransferUS
