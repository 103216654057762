import React from 'react'

function TransferError(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M20.7894 24.9243C20.5659 24.0902 21.0608 23.2329 21.8949 23.0094L47.3962 16.1764C48.2303 15.9529 49.0876 16.4478 49.3111 17.2819L56.1441 42.7832C56.3676 43.6173 55.8726 44.4746 55.0386 44.698L29.5373 51.5311C28.7032 51.7546 27.8459 51.2596 27.6224 50.4256L20.7894 24.9243Z"
        fill="#E5E5E5"
      />
      <path
        d="M51.4842 68.3388C51.3019 67.4947 51.8383 66.6627 52.6823 66.4804L70.4739 62.6364C71.3179 62.4541 72.1499 62.9905 72.3322 63.8345L76.1762 81.6261C76.3585 82.4701 75.8222 83.3021 74.9781 83.4844L57.1865 87.3284C56.3425 87.5107 55.5105 86.9744 55.3282 86.1304L51.4842 68.3388Z"
        fill="#E5E5E5"
      />
      <path
        d="M29.9351 32.5264C30.1575 32.4774 30.31 32.364 30.4275 32.2186C30.545 32.0732 30.5544 31.8918 30.4908 31.7066C30.2982 31.1813 28.6733 26.4897 26.528 23.3472C26.7213 22.4479 26.697 21.507 26.4517 20.585C24.9985 16.1145 19.4445 12.2794 19.2331 12.1168C18.8787 11.8862 18.3639 11.9201 18.0923 12.209C17.9764 12.3243 17.932 12.4735 17.9257 12.5944L17.921 12.6851C17.9481 12.8684 18.0499 13.0252 18.2263 13.1556C18.2263 13.1556 23.5307 16.8565 24.8598 20.8962C24.9219 21.1116 24.949 21.2949 24.9745 21.5084C23.4007 20.0626 21.9722 20.0489 21.1399 20.5512C20.3075 21.0535 19.992 22.1889 20.4376 23.4851C20.6589 24.1634 21.0279 24.8191 21.4731 25.4182C22.5672 26.9299 23.6651 26.9567 24.3737 26.7208C25.0822 26.4848 25.5554 25.8426 25.8792 25.2533C27.0833 27.4679 28.1015 29.7335 28.8973 32.0481C28.9609 32.2333 29.1008 32.3618 29.2788 32.462C29.495 32.5338 29.7143 32.5452 29.9351 32.5264ZM22.8662 24.7025C21.6354 23.0018 21.7682 21.8569 22.1463 21.6341C22.3353 21.5227 22.9884 21.6476 23.8628 22.4508C24.211 22.8023 24.5226 23.1519 24.7961 23.5299C24.5615 24.5179 24.1186 25.283 23.7453 25.4151C23.6325 25.4698 23.2781 25.2392 22.8662 24.7025Z"
        fill="#386CF4"
      />
      <path
        d="M11.2871 45.3872C11.0687 44.566 11.5574 43.7234 12.3785 43.505L36.9806 36.9631C37.2637 36.8878 37.5094 37.171 37.395 37.4406L34.9648 43.1672C34.896 43.3294 34.9577 43.5175 35.1093 43.6074L40.3588 46.719L37.5149 50.1423C37.3883 50.2946 37.4123 50.5213 37.568 50.6437L41.8453 54.0083L39.019 59.464C38.9156 59.6636 39.0232 59.9075 39.2402 59.9658L45.2337 61.5753L39.0853 66.6698C38.8755 66.8437 38.9343 67.1798 39.1907 67.272L45.0097 69.3648L42.6048 76.8178C42.5503 76.9868 42.6303 77.17 42.7913 77.2449L48.3537 79.8334C48.652 79.9723 48.6137 80.4081 48.2957 80.4926L23.9364 86.97C23.1152 87.1884 22.2726 86.6997 22.0542 85.8786L11.2871 45.3872Z"
        fill="#386CF4"
      />
      <mask id="mask0_2697_22666" maskUnits="userSpaceOnUse" x="11" y="38" width="33" height="50">
        <path
          d="M11.2861 45.3852C11.0678 44.5641 11.5564 43.7214 12.3775 43.5031L32.1175 38.254C32.3857 38.1827 32.6271 38.4353 32.5436 38.7L30.7734 44.3112C30.7282 44.4546 30.779 44.6109 30.9 44.7002L35.412 48.0322L33.2029 51.301C33.1066 51.4433 33.1273 51.6343 33.2517 51.7528L36.9763 55.3008L34.8709 60.6002C34.798 60.7838 34.8905 60.9915 35.0758 61.0602L40.1277 62.9308L35.2572 67.6958C35.0822 67.867 35.1311 68.1601 35.3523 68.2652L40.299 70.6152L38.6409 77.9058C38.6071 78.0545 38.673 78.2081 38.8041 78.2862L43.5757 81.1268C43.8434 81.2862 43.7872 81.6893 43.4861 81.7693L23.9354 86.9681C23.1142 87.1864 22.2716 86.6978 22.0532 85.8766L11.2861 45.3852Z"
          fill="#386CF4"
        />
      </mask>
      <g mask="url(#mask0_2697_22666)">
        <rect x="16.7649" y="48.5469" width="10.3846" height="3.67633" rx="0.384615" transform="rotate(-15.6935 16.7649 48.5469)" fill="#0E3AC4" />
      </g>
      <path
        d="M78.9236 70.1547C78.7764 70.9915 77.9787 71.5506 77.1419 71.4033L56.897 67.8416C56.7535 67.8163 56.6404 67.7053 56.6125 67.5622L55.417 61.4228C55.3935 61.3026 55.4343 61.1788 55.5247 61.0961L60.1984 56.8148L56.0013 50.058C55.9059 49.9045 55.9423 49.7038 56.0854 49.5935L62.1933 44.8883C62.3111 44.7975 62.3592 44.6424 62.3133 44.501L60.2146 38.0274L64.1919 33.4605C64.3006 33.3356 64.3076 33.1517 64.2086 33.019L60.8518 28.5168L64.594 26.4914C64.8083 26.3754 64.8427 26.0819 64.661 25.9195L58.5484 20.4556L65.5825 16.5171C65.6532 16.4775 65.7355 16.4635 65.8154 16.4776L86.175 20.0595C87.0118 20.2068 87.5708 21.0045 87.4236 21.8413L78.9236 70.1547Z"
        fill="#386CF4"
      />
      <mask id="mask1_2697_22666" maskUnits="userSpaceOnUse" x="55" y="16" width="30" height="55">
        <path
          d="M75.6679 69.4907C75.5207 70.3275 74.7229 70.8865 73.8861 70.7393L56.4875 67.6783C56.3385 67.652 56.2229 67.5334 56.2005 67.3838L55.2885 61.2883C55.2724 61.1806 55.307 61.0715 55.3824 60.9928L59.5767 56.6142L56.0418 49.9551C55.9656 49.8115 55.9977 49.6343 56.1194 49.5265L61.5973 44.6786C61.6969 44.5904 61.7383 44.4536 61.7043 44.325L60.0041 37.8991L63.6006 33.2417C63.6916 33.1238 63.6986 32.9614 63.618 32.8362L60.7708 28.4113L64.1113 26.2977C64.301 26.1777 64.3314 25.9133 64.1739 25.7534L58.9326 20.4319L65.1546 16.3667C65.2294 16.3178 65.3201 16.2992 65.4081 16.3147L82.9193 19.3955C83.7561 19.5427 84.3151 20.3404 84.1679 21.1772L75.6679 69.4907Z"
          fill="#386CF4"
        />
      </mask>
      <g mask="url(#mask1_2697_22666)">
        <rect x="60.8531" y="23.4746" width="18.8978" height="4.19197" rx="0.384615" transform="rotate(12.3341 60.8531 23.4746)" fill="#0E3AC4" />
      </g>
      <path
        d="M75.3273 39.4304C76.8114 39.3393 77.9408 38.0624 77.8497 36.5783C77.7587 35.0942 76.4818 33.9648 74.9976 34.0559C73.5135 34.1469 72.3842 35.4238 72.4752 36.908C72.5662 38.3921 73.8432 39.5214 75.3273 39.4304Z"
        fill="#0E3AC4"
      />
      <path
        d="M49.8463 75.0778C63.7811 75.0778 75.0775 63.7814 75.0775 49.8465C75.0775 35.9117 63.7811 24.6152 49.8463 24.6152C35.9114 24.6152 24.615 35.9117 24.615 49.8465C24.615 63.7814 35.9114 75.0778 49.8463 75.0778Z"
        fill="#FE5274"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.9416 63.3534C57.5946 63.5985 57.1161 63.5141 56.8521 63.1812C52.5409 57.744 44.8055 56.4124 38.9243 60.0951C38.5642 60.3206 38.085 60.2402 37.8399 59.8931L34.2898 54.8666C34.0447 54.5196 34.1277 54.0376 34.4832 53.805C43.8411 47.681 56.3692 49.8376 63.1409 58.7381C63.3981 59.0762 63.3152 59.5582 62.9681 59.8033L57.9416 63.3534Z"
        fill="white"
      />
    </svg>
  )
}

export default TransferError
