import React from 'react'

import { Modal } from '@arco-design/web-react'
import { Logger } from '@upay/utils'
import { IdcEnum, IdcT, StatusActionTypeEnum } from '@upay/utils/es/types'
import { Buffer } from 'buffer'
import { publicEncrypt } from 'public-encrypt'

import { DEV_ORIGIN_KEY, FROM_PAGE_ENUM, IS_DEV, NEW_SDK_TERMINAL_EQUIP, PLATFORM_MAP, SHOPIFY_PRE_ORIGIN_KEY } from '@/constant'
import api from '@/services'
import ErrorSvg from '@/static/svg/error.svg'
import starling from '@/utils/starling'

import { logger } from './slardar'

export type IModalInfo = {
  title: string
  paymentsLogo: string
  platformLogo: string
}
export enum CombinationEnum {
  NOT_SUPPORT,
  SUPPORT,
}
const parseQueryString = (str: string): Record<string, string> => {
  const url = !str ? window.location.href : str

  if (url.indexOf('?') === -1) {
    return {}
  }

  const search = url[0] === '?' ? url.substr(1) : url.substring(url.lastIndexOf('?') + 1)

  if (search === '') {
    return {}
  }

  const pairs = search.split('&')
  const query: Record<string, string> = {}

  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=')
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '')
  }

  return query
}
/**
 * 加载额外的js-sdk
 * 适用 umd 模块
 * 只能浏览器环境下使用
 * @export
 * @param {string} url 额外加载JS 的 CDN 地址
 * @param {funciton} cb 额外加载JS 的回调函数
 * @param {'module'} [type]
 * @returns
 */
export function loadScript(
  url: string,
  cb?: (params?: Record<string, any>) => void,
  type = 'module',
  position?: 'body' | 'head',
  params?: Record<string, any>,
) {
  return new Promise((resolve, reject) => {
    const scriptdom = document.createElement('script')
    scriptdom.src = url
    if (type === 'module') {
      scriptdom.type = 'module'
    }
    document[position || 'body'].appendChild(scriptdom)
    scriptdom.onerror = () => {
      reject('加载异常')
    }

    /**
     * 兼容IE 的情况
     */
    scriptdom.onload = function () {
      cb && cb(params)
      resolve(this)
    }
  })
}
const utils = {
  reportPipoRes: (
    res:
      | {
          error_code: string
          error_message: string
        }
      | Record<string, string | undefined>,
    refId: string | undefined,
    refType: 'upay_card' | 'upay_record',
    token: string,
  ) => {
    const { error_code: errCode, error_message } = res || {}
    api.reportFailReason({
      errorCode: errCode || '', // 为空代表服务器异常
      errorMessage: error_message || 'pipo server error',
      refId: refId || '',
      refType,
      token,
    })
  },
  guid(): string {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1)
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()
  },
  parseQueryString,
  getQueryString(key: string): string {
    const params = parseQueryString(location.href)
    return params[key] || ''
  },
  setUrlParams(url: string, params: Record<string, string>): string {
    const search = Object.keys(params || {}).map((key) => `${key}=${params[key]}`)

    const targetUrl =
      url.split('?').length > 1
        ? `${url}${search.length > 0 ? `&${search.join('&')}` : ''}`
        : `${url}${search.length > 0 ? `?${search.join('&')}` : ''}`

    return targetUrl
  },
  /**
   * @description 字符串加密传输
   * @param {string} plaintext
   * @param {string} publicKey
   * @returns {string}
   */
  encryptString(plaintext: string, publicKey: string): string {
    // publicEncrypt() method with its parameters
    if (!plaintext) return plaintext
    const encrypted = publicEncrypt(publicKey, Buffer.from(plaintext))
    return encrypted.toString('base64')
  },
  /**
   * @description 获取公钥并加密字符串
   * @param plaintext 原文
   * @param token 用户登录态
   * @returns 密文
   */
  fetchKeyAndEncryptString: async (plaintext: string, token: string): Promise<string> => {
    const publicKeyRes = await api.getPublicKey({ token })
    const publicKey = publicKeyRes.data?.publicKeyPkcs1 || ''
    return utils.encryptString(plaintext, publicKey)
  },
  /**
   * @description 获取公钥并加密字符串数组，按顺序返回
   * @param plaintext 原文数组
   * @param token 用户登录态
   * @returns 密文
   */
  fetchKeyAndEncryptStringArray: async (plainArray: string[], token: string): Promise<string[]> => {
    const publicKeyRes = await api.getPublicKey({ token })
    const publicKey = publicKeyRes.data?.publicKeyPkcs1 || ''
    return plainArray.map((plaintext) => utils.encryptString(plaintext, publicKey))
  },
  // 特殊的 cookie 方法，不做通用函数使用
  setCookie(cName: string, cValue: string): void {
    // 顶级域名下有可能有重名的cookie 先行移除
    document.cookie = cName + `=;expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=.${document.domain.split('.').slice(-2).join('.')};path=/`

    document.cookie = cName + '=' + escape(cValue) + ';path=/'
  },
  getCookie(name: string): string | null {
    const arr = document.cookie.match(new RegExp(`(^| )${name}=([^;]*)(;|$)`))
    if (arr != null) return decodeURI(arr[2])
    return null
  },
  getPathByUrl(url: string): string {
    const urlArrs = url.split('//')
    const pathStart = urlArrs[1].indexOf('/')
    let path = urlArrs[1].substring(pathStart) //stop省略，截取从start开始到结尾的所有字符
    if (path.indexOf('?') != -1) {
      path = path.split('?')[0]
    }
    return path
  },
  formatCurrency(money: string | number): string {
    if (money && money !== null) {
      const str = String(money)
      const left = str.split('.')[0]
      let right = str.split('.')[1]
      right = right ? (right?.length >= 2 ? `.${right.substr(0, 2)}` : `.${right}0`) : '.00'
      const temp =
        left
          .split('')
          .reverse()
          .join('')
          .match(/(\d{1,3})/g) || []
      return (Number(str) < 0 ? '-' : '') + temp.join(',').split('').reverse().join('') + right
    } else if (money === 0) {
      return '0.00'
    } else {
      return ''
    }
  },
  openWindow(url = '', width = 1080, height = 680): Window {
    const top = (window.screen.height - height) / 2
    const left = (window.screen.width - width) / 2
    const openLink = window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top},resizable,scrollbars=yes,status=1`)
    return openLink as Window
  },
  openWindowForce(url = '', currentPage = ''): Promise<Window> {
    return new Promise((resolve) => {
      const openLink = this.openWindow(url)
      if (openLink) {
        resolve(openLink)
      } else {
        // 展示挽留信息
        utilsLogger.info('popupBlocker', { url, currentPage })
        Modal.info({
          className: 'upay-open-window-modal upay-modal upay-modal-simple',
          title: null,
          icon: null,
          content: (
            <div className="open-window-confirm-container">
              <img src={ErrorSvg} className="upay-error__img" />
              <div className="open-window-confirm-title">{starling('cg.upay.exception.popup_blocker_tips')}</div>
            </div>
          ),
          okText: starling('funds.refund.comm.confirm_button'),
          alignCenter: true,
          onOk: () => {
            utilsLogger.info('popupBlockConfirmClick')
            resolve(this.openWindow(url))
          },
        })
      }
    })
  },
  postRecord(params: { token: string; actionId: string; actionType: number; payWay: string | number; sourcePlatform: string; advId: string }): void {
    if (!params?.token || !params?.actionId) {
      console.warn('post record param error', params)
      return
    }
    api
      .postRecord({
        ...params,
        terminalEquip: NEW_SDK_TERMINAL_EQUIP,
        actionTime: new Date().getTime(),
      })
      .catch((e) => {
        console.error('post record error', e)
      })
  },
  /**
   * @param from
   * @returns 国际支付回跳地址
   */
  // invokerID 标示调用方，方便pipo中间页面回调判断。
  getResultPath: ({
    from,
    uniqueId,
    actionType,
    combination,
  }: {
    from: FROM_PAGE_ENUM
    uniqueId: string
    actionType?: StatusActionTypeEnum
    combination?: CombinationEnum
  }): string => {
    const actionTypeParam = actionType ? `&actionType=${actionType}` : ''
    const combinationParam = combination ? `&combination=${combination}` : ''
    return `${
      IS_DEV ? 'https://boei18n-ads.byteoversea.net' : window.location.origin
    }/wpay/oversea/result?invokerID=upay_f67efed623c24192ee4791d777fffb045c885e40&from=${from}&uniqueId=${uniqueId}${actionTypeParam}${combinationParam}`
  },
  getOriginKey: (originKey: string, merchantId: string): string => {
    if (window.location.host === 'shopify-pre.bytegration.com') {
      return SHOPIFY_PRE_ORIGIN_KEY[merchantId]
    }
    return IS_DEV ? DEV_ORIGIN_KEY : originKey
  },
  getPIPOErrorDetail: async (errorCode = '', language = 'en') => {
    let detail = ''
    try {
      const res = await api.getErrorMsg({
        errorCode,
        language,
      })
      detail = res.data || ''
    } catch (e) {
      detail = ''
    }
    return detail
  },
  // 登陆鉴权接口
  getPassprotTicket: async ({ bizId, appId }: { bizId: string; appId: string }) => {
    // 目前仅TT及BC需要鉴权,如有变化需要及时修改
    if (
      [
        PLATFORM_MAP.TT_ADS,
        PLATFORM_MAP.BC,
        PLATFORM_MAP.TCM,
        PLATFORM_MAP.TCM_US,
        PLATFORM_MAP.TBP,
        PLATFORM_MAP.PROMOTE_SG,
        PLATFORM_MAP.PROMOTE_TTP,
        PLATFORM_MAP.PROMOTE_VA,
      ].includes(bizId)
    ) {
      // TODO 临时逻辑，只针对TBP切换新接口
      const request = bizId === PLATFORM_MAP.TBP ? api.getWebLoginAuth : api.getLoginAuth
      const loginAuth = await request({
        aid: appId,
      })
      return loginAuth?.data?.token || ''
    } else {
      return ''
    }
  },
  /**
   * 过滤对象中的无效字段
   */
  filterParams: (obj: any) => {
    const _newPar: any = {}
    for (const key in obj) {
      if (typeof obj[key] !== 'undefined' && obj[key] !== '') {
        _newPar[key] = obj[key]
      }
    }
    return _newPar
  },
  getCardIdentityNo: (cardNo: string) => {
    if (cardNo && cardNo.length) {
      const reg = /^(\d{6})(\d*)(\d{4})$/
      const str = cardNo.replace(reg, (a, b, c, d) => {
        return b + c.replace(/\d/g, '*') + d
      })
      return str
    } else {
      return cardNo
    }
  },
  /**
   * 替换字符串特定位置的字符 如手机号掩码
   * @param str 原字符串
   * @param index 开始字符
   * @param len 需要替换的长度
   * @param char 掩码
   * @returns string
   */
  replaceStr: (str: string, index: number, len: number, char: string) => {
    return str.substring(0, index) + char + str.substring(index + len)
  },
  /**
   * 将 boei18n 转换为 sg
   * @param idc 后端链接返回机房名称
   * @param ociTransferToTtp 在某些场景 TTP 机房为 'ttp'
   */
  getIdc: (idc: string = ZONE_ENV, ociTransferToTtp = false): IdcT | 'ttp' => {
    if (idc === IdcEnum.BOE) {
      return IdcEnum.SG
    } else if (idc === IdcEnum.OCI && ociTransferToTtp) {
      return 'ttp'
    } else {
      return idc as IdcT
    }
  },
}

export default utils

export const utilsLogger = new Logger('SDK_UTILS', logger)
