import { IdcEnum } from '@upay/utils/es/types'

/** 实例内发布订阅对象 */
export interface IPubSub {
  /** 订阅 */
  subscribe: PubSubJS.Subscribe<any, any>['subscribe']
  /** 发布 */
  publish: PubSubJS.Publish<any, any>['publish']
  /** 取消订阅 */
  unsubscribe: (token: string) => void
}

export interface IColors {
  /** 主色调 */
  mainColor?: string
  /** 次级主题色 */
  secondaryColor?: string
  /** 按钮悬停 */
  hoverColor?: string
  /** 按钮点击 */
  pressColor?: string
  /** 按钮禁用 */
  disabledColor?: string
}

export interface IParams {
  /**
   * sdk 调用方
   * @default ''
   */
  invokerId?: string
  /**
   * 默认是否打开
   * @default true
   */
  defaultVisible: boolean
  /**
   * 支付/绑卡模块底部的协议跳转地址，没传的时候默认不展示
   */
  policyUrl?: string
  /**
   * 语言缩写
   * @default 'en'
   */
  language: string
  /**
   * 页面主题色相关，默认是蓝色系
   */
  colors?: IColors
  /**
   * 绑卡/支付模块默认高度
   */
  height?: number
  /**
   * 绑卡/支付模块是否隐藏内部的标题
   * @default false
   */
  hideTitle: boolean
  /**
   * 支付模块是否折叠多渠道的支付方式
   * @default false
   */
  hideNewMethod: boolean
  /**
   * 是否允许跳过
   * @default false
   */
  allowSkip: boolean
  /**
   * 是否是boe环境
   * @default false
   */
  isBoe: boolean
  /**
   * 是否是ttp环境
   * @default false
   */
  isTtp?: boolean
  /**
   * 是否在 预付费支付/后付费绑卡 成功的时候展示结果页面（有些业务方想要自己展示结果页面 eg:shopify 创编场景)
   * @default false
   */
  showSuccessStatus: boolean
  /**
   * 允许用户在支付失败后直接关闭收银台弹窗
   * @default false
   */
  allowCancel: boolean
  /**
   * 渲染方式
   * @default modal
   */
  mode?: ModeTypeEnum
}

export enum ModeTypeEnum {
  Modal = 'modal',
  HostedPage = 'hostedPage',
}

export enum ShowTypeEnum {
  Modal = '1',
  DropIn = '2',
}

export interface IUrlQuery {
  token: string
  /**
   * 付费类型 1-预付，2-后付，3-变更支付方式
   */
  type: string
  /**
   * 账单类型 1-预付，2-后付
   */
  billType: string
  accountId: string
  countryCode: string
  appId: string
  bizId: string
  tradeType: string
  upaySessionId: string
  showType: ShowTypeEnum
  // 表示用户目前所处机房
  idc: IdcEnum
  [key: string]: string
}

export interface IOptions {
  /**
   * 挂载SDK的容器，可直接传DOM节点或者ID
   */
  container: Element | string
  /**
   * 获取到的收银台链接
   */
  url: string
  /**
   * 页面相关参数
   */
  params: IParams
}

export type ListenType = 'upay_status_result' | 'upay_request_param'

export type TriggerType = 'upay_open_modal' | 'upay_refresh_manager_list' | 'upay_send_param'
