import React, { useMemo } from 'react'

import { Alert as ArcoAlert } from '@arco-design/web-react'

import { Warning } from '@/static/icon'

import HighlightStarlingKey from '../HighLightStarlingKey'

interface IProps {
  riskInfo:
    | {
        displayRiskAmount: string
        riskAmountCurrency: string
        riskAmount: string
      }
    | undefined
}
const RiskAmountHint: React.FC<IProps> = (props) => {
  const { riskInfo } = props

  const hintTopInfo = useMemo(() => {
    if (!riskInfo?.displayRiskAmount) {
      return null
    }

    return [
      { key: 'amount', value: riskInfo.displayRiskAmount },
      { key: 'currency', value: riskInfo.riskAmountCurrency || 'USD' },
      { key: 'minDay', value: '1' },
      { key: 'maxDay', value: '15' },
    ]
  }, [riskInfo])

  return hintTopInfo ? (
    <ArcoAlert
      icon={<Warning />}
      content={<HighlightStarlingKey placeholders={hintTopInfo} starlingKey="funds.refund.comm.Automatic_deduction_int_top" />}
      type="warning"
      style={{ marginBottom: '16px' }}
    />
  ) : null
}

export default RiskAmountHint
