"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.PAYMENT_CODE_CONFIG = void 0;
var PAYMENT_CODE_CONFIG = {
  // bankTransfer 线下支付方式
  BANK_TRANSFER: {
    // is a collapsible panel
    collapsable: true,
    // specific payment methods
    methods: [{
      starlingKey: 'pipo_payin_paymentcode_title_Via_Mobile_banking',
      mobileIcon: 'right',
      pcIcon: 'bank',
      contentStarlingKey: 'mobile_banking_content'
    }, {
      starlingKey: 'pipo_payin_paymentcode_title_Via_Internet_Banking',
      mobileIcon: 'phone',
      pcIcon: 'phone',
      contentStarlingKey: 'internet_banking_content'
    }, {
      starlingKey: 'pipo_payin_paymentcode_title_Via_ATM',
      mobileIcon: 'bank',
      pcIcon: 'atm',
      contentStarlingKey: 'atm_content'
    }, {
      starlingKey: 'pipo_payin_paymentcode_title_Via_Other_Banks',
      mobileIcon: 'bank',
      pcIcon: 'atm',
      contentStarlingKey: 'otherbank_content'
    }]
  },
  // cash at Indomaret 便利店线下支付方式
  INDOMARET: {
    collapsable: true,
    methods: [{
      starlingKey: 'pipo_payin_paymentcode_indomaret_cvs_cashier_title',
      contentStarlingKey: 'cvs_cashier_content'
    }, {
      starlingKey: 'pipo_payin_paymentcode_indomaret_cvs_title_ikiosk_machine',
      contentStarlingKey: 'cvs_content_ikiosk_machine'
    }, {
      starlingKey: 'pipo_payin_paymentcode_Indomaret_ikios_title',
      contentStarlingKey: 'ikios_content'
    }]
  },
  // cash at Alfa 便利店线下支付方式
  ALFA: {
    collapsable: false,
    methods: [{
      contentStarlingKey: 'cvs_cahsier_content'
    }]
  },
  // Fawry Config
  FAWRY: {
    collapsable: false,
    methods: [{
      contentStarlingKey: 'cashier_content'
    }]
  },
  // OXXO Config
  OXXO: {
    collapsable: false,
    methods: [{
      contentStarlingKey: 'instruction'
    }]
  },
  // BO banktransfer Config
  BOBANKTRANSFER: {
    collapsable: false,
    methods: [{
      contentStarlingKey: 'bo_referencecode'
    }]
  }
};
exports.PAYMENT_CODE_CONFIG = PAYMENT_CODE_CONFIG;