import React, { Fragment, ReactElement, useEffect, useMemo, useRef } from 'react'
import { Controller, ControllerFieldState, FieldError, UseControllerProps } from 'react-hook-form'

import { Radio, Spin } from '@arco-design/web-react'
import TabPane from '@arco-design/web-react/es/Tabs/tab-pane'

import SimpleTabs from '@/components/SimpleTabs'
import { IUsePostalCodeResult } from '@/pipo/hooks/use-postal-code'
import { LocationItem, PipoProvider } from '@/pipo/utils'
import { IGetContractAddressRes } from '@/services/types'
import starling from '@/utils/starling'

import { City } from '../city'
import { Country } from '../country'
import { ItemBlock } from '../item-block'
import { PostalCode } from '../postal-code'
import { Province } from '../province'
import { Street } from '../street'

const RadioGroup = Radio.Group

export interface IBillingAddressCompProps {
  control: any
  errors: { [key: string]: FieldError | undefined }
  title?: string
  onlyCurrentCountry?: boolean
  rules?: {
    street?: UseControllerProps['rules']
    // 邮编规则根据国家联动，请在usePostalCode hook中修改
  }
  streetProps?: any
  // from useAddressPC
  list: {
    label: any
    placeholder: any
    loading: boolean
    list: LocationItem[]
    disabled: boolean
  }[]
  selectedAddressArr: (LocationItem | undefined)[]
  isFillByPostalCode: boolean
  postCodeResArr: string[][]
  contractAddressList: IGetContractAddressRes[] | undefined
  selectedContractAddress: IGetContractAddressRes | undefined
  countryOrRegion: string
  setSelectedContractAddress: React.Dispatch<React.SetStateAction<IGetContractAddressRes | undefined>>
  // from usePostalCode
  postalCodeProps: IUsePostalCodeResult
  loadingAddress: boolean
  handleSelectPostalCodeRes: (postalCodeResItem: string[]) => void
  handlePostalCodeBlur: () => void
  handlePostalCodeChange: () => void
  needRegion?: boolean
  regionDisabled?: boolean
  nameOptions?: { street?: string; region?: string; state?: string; city?: string; postalCode?: string }
  text?: {
    streetTitle?: string
    streetPlaceholder?: string
  }
  handleBlur?: (scenario: string, fieldState: ControllerFieldState) => void
}

export const BillingAddress = React.forwardRef((props: IBillingAddressCompProps, ref: any) => {
  const {
    list: originList,
    control,
    errors,
    selectedAddressArr,
    isFillByPostalCode,
    postalCodeProps,
    loadingAddress,
    postCodeResArr,
    selectedContractAddress,
    contractAddressList: originContractList,
    setSelectedContractAddress,
    handleSelectPostalCodeRes,
    handlePostalCodeBlur,
    handlePostalCodeChange,
    nameOptions = {},
    needRegion = true,
    text = {},
    regionDisabled = false,
    title,
    onlyCurrentCountry,
    countryOrRegion,
    rules,
    streetProps = {},
    handleBlur,
  } = props
  const {
    street = 'billing_street',
    region = 'billing_country_region',
    state = 'billing_state',
    city = 'billing_city',
    postalCode = 'billing_postal_code',
  } = nameOptions

  // 一些支付方式需仅显示当前国家，默认选择已在useAddressPC hook中实现
  const list = useMemo(() => {
    if (onlyCurrentCountry) {
      originList[0].list = originList[0].list.filter((item) => item.code === countryOrRegion)
    }
    return originList
  }, [originList, onlyCurrentCountry, countryOrRegion])

  const contractAddressList = useMemo(() => {
    let addressList = originContractList
    if (onlyCurrentCountry) {
      addressList = originContractList?.filter((address) => address.countryCode === countryOrRegion)
    }
    return addressList
  }, [originContractList, onlyCurrentCountry, countryOrRegion])

  const { postalCodeTitle, postalCodePlaceholder, postalCodeValidator, getPostalCodeError } = postalCodeProps
  const contractAddressSelected = useRef(false)
  const { getErrorText } = PipoProvider.useContext()

  useEffect(() => {
    if (contractAddressList && contractAddressList.length > 0 && !contractAddressSelected.current) {
      // 默认选择仅执行一次
      contractAddressSelected.current = true
      setSelectedContractAddress(contractAddressList[0])
    }
  }, [contractAddressList])

  return (
    <div className="upay-billing-address">
      {title && (
        <div className="upay-billing-address-title">
          {title} {!contractAddressList && <Spin />}
        </div>
      )}
      {contractAddressList && contractAddressList.length > 0 && (
        // 此处使用controller包裹为了出发form表单isValid的更新，否则切换地址时会出现isValid不匹配的情况
        <Controller
          rules={{ required: true }}
          name="contract_address"
          defaultValue="auto"
          control={control}
          render={({ field: { onChange } }) => (
            <RadioGroup
              className="contract-address-radio"
              direction="vertical"
              value={selectedContractAddress}
              onChange={(value) => {
                onChange(value ? 'auto' : 'manually')
                setSelectedContractAddress(value)
              }}
            >
              {contractAddressList?.map((address, index) => (
                <Radio key={index} value={address}>
                  {address.completeBillingAddress.replaceAll(',', ', ')}
                </Radio>
              ))}
              <Radio value={undefined}>{starling('cg.upay.comm.use_another_address')}</Radio>
            </RadioGroup>
          )}
        />
      )}
      {!selectedContractAddress && (
        <Fragment>
          <Controller
            rules={rules?.street || { required: true }}
            name={street}
            control={control}
            render={({ field, fieldState }) => (
              <Street
                {...field}
                len="full"
                title={text.streetTitle || starling('funds.refund.comm.system_street_address')}
                placeholder={text.streetPlaceholder || starling('funds.refund.comm.system_street_address_placeholder')}
                error={errors[street]}
                errorMessage={getErrorText(errors[street]?.type)}
                onBlur={() => {
                  field.onBlur()
                  handleBlur?.(street, fieldState)
                }}
                {...streetProps}
              />
            )}
          />
          {needRegion && (
            <Controller
              rules={{ required: true }}
              name={region}
              control={control}
              render={({ field }) => (
                <Country
                  {...field}
                  title={list[0].label}
                  placeholder={list[0].placeholder}
                  error={errors[region]}
                  errorMessage={getErrorText(errors[region]?.type)}
                  loading={list[0].loading}
                  options={list[0].list}
                  disabled={regionDisabled || list[0].disabled}
                />
              )}
            />
          )}
          <Controller
            rules={postalCodeValidator}
            name={postalCode}
            key={postalCode}
            control={control}
            render={({ field, fieldState }) => (
              <PostalCode
                {...field}
                title={postalCodeTitle}
                placeholder={postalCodePlaceholder}
                error={errors[postalCode]}
                errorMessage={getPostalCodeError(errors[postalCode])}
                countryCode={selectedAddressArr[0]?.code}
                handlePostalCodeBlur={handlePostalCodeBlur}
                handlePostalCodeChange={handlePostalCodeChange}
                onBlur={() => {
                  field.onBlur()
                  handleBlur?.(postalCode, fieldState)
                }}
              />
            )}
          />
          <SimpleTabs
            activeTab={isFillByPostalCode ? 'postalCodeFill' : 'manualFill'}
            animation={{ inkBar: false, tabPane: true }}
            headerPadding={false}
            renderTabHeader={() => null as unknown as ReactElement}
            lazyload={false}
          >
            <TabPane key="postalCodeFill" title="">
              <ItemBlock style="full" title={starling('cg.upay.comm.state_city')} height="fit-content">
                {loadingAddress ? (
                  <div className="upay-address-result-text">
                    <Spin />
                  </div>
                ) : postCodeResArr.length <= 0 ? (
                  <div className="upay-address-result-text">-</div>
                ) : postCodeResArr.length === 1 ? (
                  <div className="upay-address-result-text">{[...postCodeResArr[0]].reverse().join(', ')}</div>
                ) : (
                  <RadioGroup direction="vertical" onChange={handleSelectPostalCodeRes}>
                    {postCodeResArr.map((addressItem, index) => (
                      <Radio key={index} value={addressItem}>
                        {[...addressItem].reverse().join(', ')}
                      </Radio>
                    ))}
                  </RadioGroup>
                )}
              </ItemBlock>
            </TabPane>
            <TabPane key="manualFill" title="">
              <div className="pipo-bindcard-pc-subform-wrapper">
                <Controller
                  rules={{ required: true }}
                  name={state}
                  key={state}
                  control={control}
                  render={({ field }) => (
                    <Province
                      {...field}
                      title={list[1].label}
                      placeholder={list[1].placeholder}
                      error={errors[state]}
                      errorMessage={getErrorText(errors[state]?.type)}
                      loading={list[1].loading}
                      options={list[1].list}
                      disabled={list[1].disabled}
                      isFillByPostalCode={isFillByPostalCode}
                    />
                  )}
                />
                <Controller
                  rules={{ required: true }}
                  name={city}
                  key={city}
                  control={control}
                  render={({ field }) => (
                    <City
                      {...field}
                      title={list[2].label}
                      placeholder={list[2].placeholder}
                      error={errors[city]}
                      errorMessage={getErrorText(errors[city]?.type)}
                      loading={list[2].loading}
                      options={list[2].list}
                      disabled={list[2].disabled}
                      selectedAddressArr={selectedAddressArr}
                      isFillByPostalCode={isFillByPostalCode}
                    />
                  )}
                />
              </div>
            </TabPane>
          </SimpleTabs>
        </Fragment>
      )}
    </div>
  )
})
