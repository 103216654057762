import { BankListSelect, Email, HolderName, ItemInputNumber, PhoneNumber, SimpleSelect } from '@/pipo/pc/inner-components'
import { validator } from '@/pipo/utils'
import starling from '@/utils/starling'

import { IGetLocalBankTransferFormConfigProps, ILocalBankTransferConfigItem } from '../utils'

export const getJPFormConfig = ({ cardHolderInfo, bankList }: IGetLocalBankTransferFormConfigProps): ILocalBankTransferConfigItem => {
  return {
    allowAccountType: ['c', 'b'],
    needInfo: ['bank_list'],
    formSchema: [
      {
        field: 'holder_name',
        widget: HolderName,
        widgetProps: {
          title: starling('funds.refund.comm.beneficiary_name'),
          placeholder: starling('funds.refund.comm.system_name_description'),
          maxLength: 101,
          selectList: cardHolderInfo?.holderNameList,
        },
        rules: { required: true, pattern: validator.jpHolderName },
      },
      {
        field: 'holder_name_katakana',
        widget: HolderName,
        widgetProps: {
          title: starling('funds.refund.comm.katakana_name'),
          placeholder: starling('cg.upay.exception.channel_katakana'),
          maxLength: 100,
        },
        rules: { required: true, pattern: validator.KatakanaNameRegex },
        ruleMessages: { pattern: starling('cg.upay.exception.channel_katakana_errormsg') },
      },
      {
        field: 'bank_code',
        widget: BankListSelect,
        widgetProps: {
          title: starling('funds.check_out.comm.channel_bank'),
          placeholder: starling('funds.refund.comm.bank_selection'),
          bankList,
        },
        rules: { required: true },
      },
      {
        field: 'agency',
        widget: ItemInputNumber,
        widgetProps: {
          title: starling('funds.refund.comm.branch_code'),
          placeholder: starling('funds.refund.comm.branch_code'),
          maxLength: 3,
        },
        rules: { required: true, pattern: validator.jpAgencyCode },
        ruleMessages: { pattern: starling('cg.upay.exception.channel_id_num_error_message', { num: 3 }) },
      },
      {
        field: 'bank_account_type',
        widget: SimpleSelect,
        widgetProps: {
          title: starling('funds.refund.comm.system_bank_account_type'),
          placeholder: starling('funds.refund.comm.system_bank_account_type'),
          options: [
            { label: starling('funds.refund.comm.ordinary_deposit'), value: 'ordinarydeposit' },
            { label: starling('funds.refund.comm.checking_deposit'), value: 'checkingdeposit' },
            { label: starling('funds.refund.comm.saving'), value: 'saving' },
          ],
        },
        rules: { required: true },
      },
      {
        field: 'account_no',
        widget: ItemInputNumber,
        widgetProps: {
          title: starling('funds.refund.comm.system_bankaccount_account_number'),
          placeholder: starling('funds.refund.comm.system_bankaccount_account_number_placeholder'),
          maxLength: 7,
        },
        rules: { required: true, pattern: validator.jpBankAccountNo },
        ruleMessages: { pattern: starling('cg.upay.exception.channel_id_num_error_message', { num: 7 }) },
      },
      {
        field: 'email',
        widget: Email,
        widgetProps: {
          title: starling('funds.refund.comm.system_email'),
          placeholder: starling('funds.refund.comm.system_email_placeholder'),
        },
        rules: { required: true, pattern: validator.emailRegex },
      },
    ],
  }
}
