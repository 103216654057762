import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'

import SingleInputForm from '@/components/SingleInputForm'
import { ISingleInputFormValue } from '@/components/SingleInputForm/interface'
import { PAY_WAY_TYPE } from '@/constant'
import utils from '@/utils'
import SafeJSON from '@/utils/SafeJSON'
import starling from '@/utils/starling'

import { IPaymentMethodsProps, IPaymentsMethodRef } from '../../interface'

// 提交时须带上的前缀/参数
const PHONE_PREFIX_REQ = '+62'
const COUNTRY_CODE = ''
const DEFAULT_FORM_STATUS = {
  id: '',
  save: false,
  valid: false,
}

const ShopeePay = forwardRef<IPaymentsMethodRef, IPaymentMethodsProps>((props, ref): React.ReactElement => {
  const {
    changeNewPaymentStatus,
    urlQuery: { token },
  } = props
  const [formStatus, setFormStatus] = useState<ISingleInputFormValue>(DEFAULT_FORM_STATUS)
  const SHOPEE_PAY_CONFIG = {
    payWay: PAY_WAY_TYPE.SHOPEE_PAY,
    formTitle: starling('cg.upay.channel_title_Shopeepay'),
    inputPlaceholder: starling('cg.upay.channel_id_Shopeepay'),
    checkTip: starling('cg.upay.channel_text_Shopeepay'),
    inputPrefix: PHONE_PREFIX_REQ,
    showSavingBox: false,
  }

  useImperativeHandle(ref, () => ({
    // 提交处理逻辑
    processSubmitData: async (submitParams) => {
      const encryptPhoneNumber = await utils.fetchKeyAndEncryptString(`${PHONE_PREFIX_REQ}${formStatus.id}`, token)
      submitParams.channelParameter = SafeJSON.stringify({
        phoneNumber: encryptPhoneNumber,
        storeToken: formStatus.save,
        encrypted: true,
        countryCode: COUNTRY_CODE,
      })
      submitParams.bindAndPay = formStatus.save
      return submitParams
    },
  }))

  useEffect(() => {
    changeNewPaymentStatus(PAY_WAY_TYPE.SHOPEE_PAY, { valid: !!formStatus.valid })
  }, [changeNewPaymentStatus, formStatus.valid])

  const getInvalidMsg = (value: string) => {
    const numRegex = /^[0-9]*$/
    if (!value) return starling('funds.refund.comm.enter_number_required')
    if (!numRegex.test(value)) return starling('funds.refund.comm.enter_number_rule')
    if (value.length < 8 || value.length > 11)
      return starling('cg.upay.exception.channel_number_length', {
        payment_method_title: 'Shopee Pay',
        numbers1: 8,
        numbers2: 11,
      })
    return ''
  }

  return <SingleInputForm value={formStatus} onChange={setFormStatus} getInvalidMsg={getInvalidMsg} config={SHOPEE_PAY_CONFIG} />
})

export default ShopeePay
