import React, { FC } from 'react'

import { Time as AtmSvg, Failed as FailedSvg, Success as SuccessSvg, Warning as WarningSvg } from '@pipo-lib/icon'
import classnames from 'clsx'

import { IGetResultRes } from '@/services/types'
import starling from '@/utils/starling'

import useTradingStatus from './useTradingStatus'

import './index.less'

const classPrefix = 'pipo-pc-voucher-trading-status'

interface IProps {
  orderStatus: string
  expirationTime?: string
  onTimeUp?: () => void
  orderNo?: string
  upayResult: IGetResultRes
  fundArrivalTips?: string
}

const TradingStatus: FC<IProps> = (props: IProps) => {
  const { orderStatus, orderNo, fundArrivalTips } = props

  const { minutes, hours, seconds, title, subTitle, icon } = useTradingStatus(props)
  const StatusIcon = (props: { type: 'time' | 'warning' | 'success' | 'failed' }) => {
    const { type } = props

    switch (type) {
      case 'time': {
        return <AtmSvg />
      }
      case 'warning': {
        return <WarningSvg />
      }
      case 'success': {
        return <SuccessSvg />
      }
      case 'failed': {
        return <FailedSvg />
      }
      default: {
        return <AtmSvg />
      }
    }
  }
  return (
    <div className={classnames(classPrefix)}>
      <StatusIcon type={icon} />
      <div className={`${classPrefix}-title`}>{title}</div>
      {orderNo && (
        <div className={`${classPrefix}-order-no`}>
          {starling('funds.refund.comm.order_no')}.{orderNo}
        </div>
      )}
      <div className={`${classPrefix}-line`} />
      {/* hide time when order is success */}
      {(orderStatus === 'init' || orderStatus === 'processing') && (
        <div className={`${classPrefix}-time`}>
          {hours}h {minutes}m {seconds}s
        </div>
      )}
      {subTitle && <div className={classPrefix + '-des'}>{subTitle}</div>}
      {fundArrivalTips && <div className={classPrefix + '-tip'}>{fundArrivalTips}</div>}
    </div>
  )
}

export { TradingStatus }
