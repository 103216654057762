import Starling from '@ies/starling_client/lib/base'
import { starlingClient } from '@upay/utils'
import { IdcT } from '@upay/utils/es/types'

import { PIPO_LOCALE_MAP } from '@/constant'
import { getTexts } from '@/pipo/utils'

import { getInitLang, setLang } from '../getLang'
import slardar from '../slardar'

export const DEFAULT_LANG = 'en'

const initStarling = async (language?: string, idc: IdcT = ZONE_ENV): Promise<void> => {
  language = language || getInitLang()

  try {
    const [pipoLocaleTexts] = await Promise.all([
      getTexts(PIPO_LOCALE_MAP[language] || language, idc),
      starlingClient.init(Starling, {
        zone: idc,
        locale: language,
      }),
    ])
    ;(window as any).UPAY_PIPO_I18N_TEXTS = pipoLocaleTexts
  } catch (e) {
    console.log('i18n init error', e)
  }
}

const defaultInitPromise = initStarling()

export const initI18n = async (lang?: string) => {
  const initLang = getInitLang()
  const startTime = Date.now()

  try {
    lang = lang || initLang
    setLang(lang)

    if (lang === initLang) {
      await defaultInitPromise
    } else {
      await initStarling(lang)
    }
    slardar.performance('i18n_init_time', Date.now() - startTime)
  } catch (error: any) {
    slardar.timer('i18nInitError', 1, { error, time: Date.now() - startTime })
  }
}
