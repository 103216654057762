import { useCallback, useContext, useState } from 'react'

import api from '@/services'
import { CardDetail } from '@/services/types'
import { ParamsContext } from '@/stores'

import { payLogger } from '..'

export const useCardDetail = () => {
  const {
    urlQuery: { token },
  } = useContext(ParamsContext)

  // 查询卡信息loading
  const [cardDetailLoadingId, setCardDetailLoadingId] = useState('')

  const getCardDetail = useCallback(
    async (cardId: string) => {
      let detail
      try {
        setCardDetailLoadingId(cardId)
        detail = await api.getCardDetail({ cardId, token })
      } catch (e) {
        payLogger.warn('pay card detail fetch error', e)
        detail = { data: { amount: '', currency: '', default: true } }
      } finally {
        setCardDetailLoadingId('')
      }
      return detail?.data as CardDetail
    },
    [token],
  )

  return { cardDetailLoadingId, getCardDetail }
}
