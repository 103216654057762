"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.BLIK_PAYMENT_PARAMS_MAP = exports.BLIK_PAYMENT_METHOD = void 0;
var BLIK_PAYMENT_PARAMS_MAP = {
  one_time_code: {
    element: 'eg_pi_bt_blik_c_d_local_pl_one_time_code',
    is_encrypted: true
  }
};
exports.BLIK_PAYMENT_PARAMS_MAP = BLIK_PAYMENT_PARAMS_MAP;
var BLIK_PAYMENT_METHOD = {
  paymentMethodType: {
    configuration: {},
    icon: {
      iconUrl: ''
    },
    starling_key: '',
    paymentMethodType: 'BANK_TRANSFER'
  },
  paymentMethod: {
    configuration: {},
    paymentMethod: '',
    paymentMethodId: 'pm_pi_bt_blik_c_d',
    displayName: 'Blik',
    starling_key: '',
    icon: {
      iconUrl: '',
      voucherIconUrl: '',
      pngIconUrl: '',
      darkModePngIconUrl: '',
      detailIconUrl: ''
    },
    title: {
      payment: ''
    }
  }
};
exports.BLIK_PAYMENT_METHOD = BLIK_PAYMENT_METHOD;