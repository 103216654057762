import { useCallback, useContext, useRef } from 'react'

import { ParamsContext } from '@/stores'
import slardar from '@/utils/slardar'

export const useReportLoadTime = (pageName: string) => {
  const { initStartTime } = useContext(ParamsContext)
  // 用于标记关键网络请求已完成 用户已可操作 埋点用
  const basicInfoLoaded = useRef(false)

  const reportLoadtime = useCallback(
    (startTime?: number) => {
      // 基础信息加载完毕 上报自UPAY初始化至页面用户可操作之间的总耗时
      if (!basicInfoLoaded.current) {
        basicInfoLoaded.current = true
        const diff = new Date().getTime() - (startTime || initStartTime)
        console.log(`UPAY_SDK_${pageName.toUpperCase()}_READY_TIME：`, diff)
        slardar.performance(`sdk_${pageName}_ready_time`, diff)
      }
    },
    [initStartTime, pageName],
  )

  return reportLoadtime
}
