import React, { forwardRef, useRef } from 'react'

import { IOnChangeProps, PayoutComponentRef } from '@/pipo/pc-payout/types'

// 封装onChange的HOC，完成逻辑隔离
export const withController = <Props extends { onChange?: (val: any) => void }>(component: React.FC<Props>) => {
  return forwardRef((props: Props, ref: React.ForwardedRef<PayoutComponentRef>) => {
    const { onChange } = props

    const isValidRef = useRef(false)
    const storeCardRef = useRef(false)

    const handleChange = (val: IOnChangeProps) => {
      const { isValid, storeCard } = val
      if (isValidRef.current === isValid && storeCardRef.current === storeCard) return
      isValidRef.current = isValid
      storeCardRef.current = storeCard

      onChange?.({
        isValid,
        storeCard,
      })
    }

    return React.createElement(component, {
      ...props,
      onChange: handleChange,
      ref,
    })
  })
}
