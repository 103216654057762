import React from 'react'

import { IOpenStatusProps } from '@/components/Status/config'
import { FROM_PAGE_ENUM } from '@/constant'

import { Order } from '../PayNext/interface'

export interface IPaymentCodeQuery {
  bizId: string
  token: string
  type: string
  appId: string
  payway: string
  recordNo?: string
  paymentReference?: string
  locale?: string
}
export interface IPaymentCodeProps {
  /** 组件使用来源 */
  from?: FROM_PAGE_ENUM.PAYMENT_CODE
  /** 是否打开 */
  visible?: boolean
  /** 关闭弹窗回调，受控组件 */
  onClose?: (payWay: number, openStatus?: boolean) => void
  /** 支付方式 */
  payWay?: number
  // 接受传入独立的query，如果未传入则默认使用全局参数
  query?: IPaymentCodeQuery
  getOrder?: () => Order | null | undefined
  // 支付成功/失败打开状态页
  handleBillStatus?: (props: IOpenStatusProps) => Promise<void>
}

interface IPaymentCodeContext {
  payWay: number
  recordNo: string
}
export const PaymentCodeContext = React.createContext({} as IPaymentCodeContext)
