import React from 'react'

import { Protect } from '@icon-park/react'

import starling from '@/utils/starling'

import './index.less'

const SecurityTip = () => {
  return (
    <div className="upay-security-tip">
      <Protect theme="filled" size="14" fill="#28a666" />
      <span>{starling('funds.refund.comm.payment_security')}</span>
    </div>
  )
}

export default React.memo(SecurityTip)
