export interface IHintProps {
  content?: string
  preset?: HINT_TYPES
  plain?: boolean
  type?: 'error' | 'info' | 'warning'
  showIcon?: boolean
  show?: boolean
  amount?: string
  currency?: string
}

export enum HINT_TYPES {
  INDIA_DEDUCTION,
  VERIFICATION_REDIRECT,
}

export interface IHintRef {
  getHeight: () => number
}

export interface IHintInfo {
  amount?: string
  displayAmount?: string
  currency?: string
}
