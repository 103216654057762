"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.API_LEVEL_CONFIG = void 0;
var API_LEVEL_CONFIG = [{
  url: '/upay/i18n/pi/payment/list',
  level: 'P0'
}, {
  url: '/upay/i18n/payment/pre_order',
  level: 'P0'
}, {
  url: '/upay/i18n/card/list',
  level: 'P0'
}, {
  url: '/upay/i18n/protocol/bill_info',
  level: 'P0'
}, {
  url: '/upay/i18n/payment/payways',
  level: 'P0'
}, {
  url: '/upay/i18n/payment/merchant_info',
  level: 'P0'
}, {
  url: '/upay/i18n/card/bind',
  level: 'P0'
}, {
  url: '/upay/i18n/payment/get_bind_elements',
  level: 'P0'
}, {
  url: '/upay/i18n/parameter/card_organization',
  level: 'P0'
}, {
  url: '/upay/i18n/payout/payment/list',
  level: 'P0'
}, {
  url: '/upay/i18n/payout/query_in_trans_list',
  level: 'P0'
}, {
  url: '/upay/i18n/payout/get_bind_elements',
  level: 'P0'
}, {
  url: '/upay/i18n/payout/bind_pi',
  level: 'P0'
}, {
  url: '/upay/i18n/payout/submit',
  level: 'P0'
}, {
  url: '/upay/i18n/payout/card/list',
  level: 'P0'
}, {
  url: '/upay/i18n/payout/bind/list',
  level: 'P0'
}, {
  url: '/upay/i18n/payment/submit_extra_info',
  level: 'P1'
}, {
  url: '/passport/auth/login_authorize/',
  level: 'P1'
}, {
  url: '/passport/web/auth/login_authorize/',
  level: 'P1'
}, {
  url: '/protocol/payment_transfer',
  level: 'P1'
}, {
  url: '/upay/i18n/protocol/transfer_submit',
  level: 'P1'
}, {
  url: '/upay/i18n/card/bind_supplement',
  level: 'P1'
}, {
  url: '/upay/i18n/card/get_transferable_card',
  level: 'P1'
}, {
  url: '/upay/i18n/card/transfer',
  level: 'P1'
}, {
  url: '/upay/i18n/transaction/result',
  level: 'P1'
}, {
  url: '/upay/i18n/card/bind/result',
  level: 'P1'
}, {
  url: '/upay/i18n/parameter/mandate',
  level: 'P1'
}, {
  url: '/upay/i18n/payment/detail',
  level: 'P1'
}, {
  url: '/upay/i18n/card/queryRiskAmount',
  level: 'P1'
}, {
  url: '/upay/i18n/card/query',
  level: 'P1'
}, {
  url: '/upay/i18n/payout/query_out_trans_info',
  level: 'P1'
}, {
  url: '/upay/i18n/payout/query_out_trans_status',
  level: 'P1'
}, {
  url: '/upay/i18n/payout/query_card_holder_info',
  level: 'P2'
}, {
  url: '/upay/i18n/card/remove',
  level: 'P2'
}, {
  url: '/upay/i18n/card/primary',
  level: 'P2'
}, {
  url: '/upay/i18n/card/update',
  level: 'P2'
}, {
  url: '/upay/i18n/payment/error_translate',
  level: 'P2'
}, {
  url: '/upay/i18n/payment/order_info',
  level: 'P2'
}, {
  url: '/upay/i18n/card/detail',
  level: 'P2'
}, {
  url: '/upay/i18n/payment/failed_channels',
  level: 'P2'
}, {
  url: '/upay/i18n/action/record/add',
  level: 'P2'
}, {
  url: '/upay/i18n/risk/shark_judge',
  level: 'P2'
}, {
  url: '/upay/i18n/card/hit_agreement',
  level: 'P2'
}, {
  url: '/upay/i18n/parameter/billing',
  level: 'P2'
}, {
  url: '/upay/i18n/record/is_old_version',
  level: 'P2'
}, {
  url: '/upay/i18n/record/pipo/response',
  level: 'P2'
}, {
  url: '/postcode/postcode',
  level: 'P2'
}, {
  url: '/upay/i18n/payout/query_out_trans_list',
  level: 'P2'
}, {
  url: '/upay/i18n/payout/card/primary',
  level: 'P2'
}, {
  url: '/upay/i18n/payout/card/remove',
  level: 'P2'
}];
exports.API_LEVEL_CONFIG = API_LEVEL_CONFIG;