import { useContext, useState } from 'react'

import { useRequest } from '@byted/hooks'
import { StatusTypeEnum } from '@upay/utils/es/types'

import { COMMON_PROMPT, RESPONSE_CODE } from '@/constant'
import api from '@/services'
import { PayoutResultStatusEnum } from '@/services/payout-types'
import { ParamsContext } from '@/stores'

const TIMER_COUNT = 10
type Props = {
  apiName: 'getPayoutStatus'
}
type PayoutStatusT = {
  errorMsg: string
  currentStatus: StatusTypeEnum
  handleClickRefresh: () => void
  remainingTime: number
}
export const usePayoutStatus = ({ apiName }: Props): PayoutStatusT => {
  const [currentStatus, setCurrentStatus] = useState<StatusTypeEnum>(StatusTypeEnum.PROCESSING)
  const [errorMsg, setErrorMsg] = useState('')
  const [remainingTime, setRemainingTime] = useState(TIMER_COUNT)
  const {
    urlQuery: { token },
  } = useContext(ParamsContext)

  const { run: startPolling, cancel: cancelPolling } = useRequest(api[apiName], {
    auto: true,
    pollingInterval: 1000 * 1,
    defaultParams: [{ token }],
    onSuccess: (res) => {
      const { code, data, msg } = res || {}
      const { errorMsg = COMMON_PROMPT().SYS_ERR, status } = data || {}
      if (code !== RESPONSE_CODE.SUCCESS) throw new Error(msg)
      if (remainingTime > 0) {
        if (status === PayoutResultStatusEnum.Success) {
          setCurrentStatus(StatusTypeEnum.SUCCESS)
          handleTaskFinish()
        } else if (status === PayoutResultStatusEnum.Failed) {
          setErrorMsg(errorMsg)
          setCurrentStatus(StatusTypeEnum.FAILED)
          handleTaskFinish()
        } else {
          setRemainingTime((prevCount) => --prevCount)
        }
      } else {
        setCurrentStatus(StatusTypeEnum.TIMEOUT)
        handleTaskFinish()
      }
    },
    onError: (e: any) => {
      setErrorMsg(e?.msg || e?.message || COMMON_PROMPT().SYS_ERR)
      setCurrentStatus(StatusTypeEnum.FAILED)
      handleTaskFinish()
    },
  })

  const handleClickRefresh = () => {
    setCurrentStatus(StatusTypeEnum.PROCESSING)
    setRemainingTime(TIMER_COUNT)
    startPolling({ token })
  }

  const handleTaskFinish = () => {
    cancelPolling()
  }
  return {
    currentStatus,
    handleClickRefresh,
    errorMsg,
    remainingTime,
  }
}
