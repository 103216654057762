"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.list = void 0;
var list = {
  AD: {
    name: 'Andorra',
    currency: 'EUR',
    code: '376'
  },
  AE: {
    name: 'United Arab Emirates',
    currency: 'AED',
    code: '971'
  },
  AF: {
    name: 'Afghanistan',
    currency: 'AFN',
    code: '93'
  },
  AG: {
    name: 'Antigua & Barbuda',
    currency: 'XCD',
    code: '1'
  },
  AI: {
    name: 'Anguilla',
    currency: 'XCD',
    code: '1'
  },
  AL: {
    name: 'Albania',
    currency: 'ALL',
    code: '355'
  },
  AM: {
    name: 'Armenia',
    currency: 'AMD',
    code: '374'
  },
  AO: {
    name: 'Angola',
    currency: 'AOA',
    code: '244'
  },
  AQ: {
    name: 'Antarctica',
    currency: '',
    code: '672'
  },
  AR: {
    name: 'Argentina',
    currency: 'ARS',
    code: '54'
  },
  AS: {
    name: 'American Samoa',
    currency: 'USD',
    code: '1'
  },
  AT: {
    name: 'Austria',
    currency: 'EUR',
    code: '43'
  },
  AU: {
    name: 'Australia',
    currency: 'AUD',
    code: '61'
  },
  AW: {
    name: 'Aruba',
    currency: 'AWG',
    code: '297'
  },
  AX: {
    name: 'Åland Islands',
    currency: 'EUR',
    code: '358'
  },
  AZ: {
    name: 'Azerbaijan',
    currency: 'AZN',
    code: '994'
  },
  BA: {
    name: 'Bosnia & Herzegovina',
    currency: 'BAM',
    code: '387'
  },
  BB: {
    name: 'Barbados',
    currency: 'BBD',
    code: '1'
  },
  BD: {
    name: 'Bangladesh',
    currency: 'BDT',
    code: '880'
  },
  BE: {
    name: 'Belgium',
    currency: 'EUR',
    code: '32'
  },
  BF: {
    name: 'Burkina',
    currency: 'XOF',
    code: '226'
  },
  BG: {
    name: 'Bulgaria',
    currency: 'BGN',
    code: '359'
  },
  BH: {
    name: 'Bahrain',
    currency: 'BHD',
    code: '973'
  },
  BI: {
    name: 'Burundi',
    currency: 'BIF',
    code: '257'
  },
  BJ: {
    name: 'Benin',
    currency: 'XOF',
    code: '229'
  },
  BL: {
    name: 'Saint Barthélemy',
    currency: 'EUR',
    code: '590'
  },
  BM: {
    name: 'Bermuda',
    currency: 'BMD',
    code: '1'
  },
  BN: {
    name: 'Brunei',
    currency: 'BND',
    code: '673'
  },
  BO: {
    name: 'Bolivia',
    currency: 'BOB',
    code: '591'
  },
  BQ: {
    name: 'Caribbean Netherlands',
    currency: 'USD',
    code: '599'
  },
  BR: {
    name: 'Brazil',
    currency: 'BRL',
    code: '55',
    phone: {
      pattern: /^\d{11}$/
    }
  },
  BS: {
    name: 'The Bahamas',
    currency: 'BSD',
    code: '1'
  },
  BT: {
    name: 'Bhutan',
    currency: 'INR,BTN',
    code: '975'
  },
  BV: {
    name: 'Bouvet Island',
    currency: 'NOK',
    code: '47'
  },
  BW: {
    name: 'Botswana',
    currency: 'BWP',
    code: '267'
  },
  BY: {
    name: 'Belarus',
    currency: 'BYN',
    code: '375'
  },
  BZ: {
    name: 'Belize',
    currency: 'BZD',
    code: '501'
  },
  CA: {
    name: 'Canada',
    currency: 'CAD',
    code: '1',
    phone: {
      pattern: /^\d{10}$/,
      // with two dashes
      maxLength: 12
    }
  },
  CC: {
    name: 'Cocos (Keeling) Islands',
    currency: 'AUD',
    code: '61'
  },
  CD: {
    name: 'Democratic Republic of the Congo',
    currency: 'CDF',
    code: '243'
  },
  CF: {
    name: 'Central African Republic',
    currency: 'XAF',
    code: '236'
  },
  CG: {
    name: 'Republic of the Congo',
    currency: 'XAF',
    code: '242'
  },
  CH: {
    name: 'Switzerland',
    currency: 'CHF',
    code: '41'
  },
  CI: {
    name: "Côte d'Ivoire",
    currency: 'XOF',
    code: '225'
  },
  CK: {
    name: 'Cook Islands',
    currency: 'NZD',
    code: '682'
  },
  CL: {
    name: 'Chile',
    currency: 'CLP',
    code: '56'
  },
  CM: {
    name: 'Cameroon',
    currency: 'XAF',
    code: '237'
  },
  CN: {
    name: 'China',
    currency: 'CNY',
    code: '86'
  },
  CO: {
    name: 'Colombia',
    currency: 'COP',
    code: '57'
  },
  CR: {
    name: 'Costa Rica',
    currency: 'CRC',
    code: '506'
  },
  CU: {
    name: 'Cuba',
    currency: 'CUP,CUC',
    code: '53'
  },
  CV: {
    name: 'Cape Verde',
    currency: 'CVE',
    code: '238'
  },
  CX: {
    name: 'Christmas Island',
    currency: 'AUD',
    code: '61'
  },
  CY: {
    name: 'Cyprus',
    currency: 'EUR',
    code: '357'
  },
  CZ: {
    name: 'Czech Republic',
    currency: 'CZK',
    code: '420'
  },
  DE: {
    name: 'Germany',
    currency: 'EUR',
    code: '49'
  },
  DJ: {
    name: 'Djibouti',
    currency: 'DJF',
    code: '253'
  },
  DK: {
    name: 'Denmark',
    currency: 'DKK',
    code: '45'
  },
  DM: {
    name: 'Dominica',
    currency: 'XCD',
    code: '1'
  },
  DO: {
    name: 'Dominican Republic',
    currency: 'DOP',
    code: '809'
  },
  DZ: {
    name: 'Algeria',
    currency: 'DZD',
    code: '213'
  },
  EC: {
    name: 'Ecuador',
    currency: 'USD',
    code: '593'
  },
  EE: {
    name: 'Estonia',
    currency: 'EUR',
    code: '372'
  },
  EG: {
    name: 'Egypt',
    currency: 'EGP',
    code: '20'
  },
  EH: {
    name: 'Western Sahara',
    currency: 'MAD',
    code: '212'
  },
  ER: {
    name: 'Eritrea',
    currency: 'ERN',
    code: '291'
  },
  ES: {
    name: 'Spain',
    currency: 'EUR',
    code: '34'
  },
  ET: {
    name: 'Ethiopia',
    currency: 'ETB',
    code: '251'
  },
  FI: {
    name: 'Finland',
    currency: 'EUR',
    code: '358'
  },
  FJ: {
    name: 'Fiji',
    currency: 'FJD',
    code: '679'
  },
  FK: {
    name: 'Falkland Islands',
    currency: '',
    code: '500'
  },
  FM: {
    name: 'Federated States of Micronesia',
    currency: 'USD',
    code: '691'
  },
  FO: {
    name: 'Faroe Islands',
    currency: 'DKK',
    code: '298'
  },
  FR: {
    name: 'France',
    currency: 'EUR',
    code: '33'
  },
  GA: {
    name: 'Gabon',
    currency: 'XAF',
    code: '241'
  },
  GB: {
    name: 'United Kingdom',
    currency: 'GBP',
    code: '44'
  },
  GD: {
    name: 'Grenada',
    currency: 'XCD',
    code: '1'
  },
  GE: {
    name: 'Georgia',
    currency: 'GEL',
    code: '995'
  },
  GF: {
    name: 'French Guiana',
    currency: 'EUR',
    code: '594'
  },
  GG: {
    name: 'Guernsey',
    currency: 'GBP',
    code: '44'
  },
  GH: {
    name: 'Ghana',
    currency: 'GHS',
    code: '233'
  },
  GI: {
    name: 'Gibraltar',
    currency: 'GIP',
    code: '350'
  },
  GL: {
    name: 'Greenland',
    currency: 'DKK',
    code: '299'
  },
  GM: {
    name: 'Gambia',
    currency: 'GMD',
    code: '220'
  },
  GN: {
    name: 'Guinea',
    currency: 'GNF',
    code: '224'
  },
  GP: {
    name: 'Guadeloupe',
    currency: 'EUR',
    code: '590'
  },
  GQ: {
    name: 'Equatorial Guinea',
    currency: 'XAF',
    code: '240'
  },
  GR: {
    name: 'Greece',
    currency: 'EUR',
    code: '30'
  },
  GS: {
    name: 'South Georgia and the South Sandwich Islands',
    currency: 'GBP',
    code: '500'
  },
  GT: {
    name: 'Guatemala',
    currency: 'GTQ',
    code: '502'
  },
  GU: {
    name: 'Guam',
    currency: 'USD',
    code: '1'
  },
  GW: {
    name: 'Guinea-Bissau',
    currency: 'XOF',
    code: '245'
  },
  GY: {
    name: 'Guyana',
    currency: 'GYD',
    code: '592'
  },
  HK: {
    name: 'Hong Kong',
    currency: 'HKD',
    code: '852'
  },
  HM: {
    name: 'Heard Island and McDonald Islands',
    currency: 'AUD',
    code: '672'
  },
  HN: {
    name: 'Honduras',
    currency: 'HNL',
    code: '504'
  },
  HR: {
    name: 'Croatia',
    currency: 'HRK',
    code: '385'
  },
  HT: {
    name: 'Haiti',
    currency: 'HTG,USD',
    code: '509'
  },
  HU: {
    name: 'Hungary',
    currency: 'HUF',
    code: '36'
  },
  ID: {
    name: 'Indonesia',
    currency: 'IDR',
    code: '62',
    phone: {
      pattern: /^\d{8,11}$/,
      minLength: 8,
      maxLength: 11
    }
  },
  IE: {
    name: 'Ireland',
    currency: 'EUR',
    code: '353'
  },
  IL: {
    name: 'Israel',
    currency: 'ILS',
    code: '972'
  },
  IM: {
    name: 'Isle of Man',
    currency: 'GBP',
    code: '44'
  },
  IN: {
    name: 'India',
    currency: 'INR',
    code: '91'
  },
  IO: {
    name: 'British Indian Ocean Territory',
    currency: 'USD',
    code: '246'
  },
  IQ: {
    name: 'Iraq',
    currency: 'IQD',
    code: '964'
  },
  IR: {
    name: 'Iran',
    currency: 'IRR',
    code: '98'
  },
  IS: {
    name: 'Iceland',
    currency: 'ISK',
    code: '354'
  },
  IT: {
    name: 'Italy',
    currency: 'EUR',
    code: '39'
  },
  JE: {
    name: 'Jersey',
    currency: 'GBP',
    code: '44'
  },
  JM: {
    name: 'Jamaica',
    currency: 'JMD',
    code: '1'
  },
  JO: {
    name: 'Jordan',
    currency: 'JOD',
    code: '962'
  },
  JP: {
    name: 'Japan',
    currency: 'JPY',
    code: '81',
    phone: {
      pattern: /^\d{10,11}$/,
      minLength: 10,
      maxLength: 11
    }
  },
  KE: {
    name: 'Kenya',
    currency: 'KES',
    code: '254'
  },
  KG: {
    name: 'Kyrgyzstan',
    currency: 'KGS',
    code: '996'
  },
  KH: {
    name: 'Cambodia',
    currency: 'KHR',
    code: '855'
  },
  KI: {
    name: 'Kiribati',
    currency: 'AUD',
    code: '686'
  },
  KM: {
    name: 'The Comoros',
    currency: 'KMF',
    code: '269'
  },
  KN: {
    name: 'St. Kitts & Nevis',
    currency: 'XCD',
    code: '1'
  },
  KP: {
    name: 'North Korea',
    currency: 'KPW',
    code: '850'
  },
  KR: {
    name: 'South Korea',
    currency: 'KRW',
    code: '82'
  },
  KW: {
    name: 'Kuwait',
    currency: 'KWD',
    code: '965'
  },
  KY: {
    name: 'Cayman Islands',
    currency: 'KYD',
    code: '1'
  },
  KZ: {
    name: 'Kazakhstan',
    currency: 'KZT',
    code: '7'
  },
  LA: {
    name: 'Laos',
    currency: 'LAK',
    code: '856'
  },
  LB: {
    name: 'Lebanon',
    currency: 'LBP',
    code: '961'
  },
  LC: {
    name: 'St. Lucia',
    currency: 'XCD',
    code: '1'
  },
  LI: {
    name: 'Liechtenstein',
    currency: 'CHF',
    code: '423'
  },
  LK: {
    name: 'Sri Lanka',
    currency: 'LKR',
    code: '94'
  },
  LR: {
    name: 'Liberia',
    currency: 'LRD',
    code: '231'
  },
  LS: {
    name: 'Lesotho',
    currency: 'LSL,ZAR',
    code: '266'
  },
  LT: {
    name: 'Lithuania',
    currency: 'EUR',
    code: '370'
  },
  LU: {
    name: 'Luxembourg',
    currency: 'EUR',
    code: '352'
  },
  LV: {
    name: 'Latvia',
    currency: 'EUR',
    code: '371'
  },
  LY: {
    name: 'Libya',
    currency: 'LYD',
    code: '218'
  },
  MA: {
    name: 'Morocco',
    currency: 'MAD',
    code: '212'
  },
  MC: {
    name: 'Monaco',
    currency: 'EUR',
    code: '377'
  },
  MD: {
    name: 'Moldova',
    currency: 'MDL',
    code: '373'
  },
  ME: {
    name: 'Montenegro',
    currency: 'EUR',
    code: '382'
  },
  MF: {
    name: 'Saint Martin (France)',
    currency: 'EUR',
    code: '590'
  },
  MG: {
    name: 'Madagascar',
    currency: 'MGA',
    code: '261'
  },
  MH: {
    name: 'Marshall islands',
    currency: 'USD',
    code: '692'
  },
  MK: {
    name: 'Republic of Macedonia',
    currency: 'MKD',
    code: '389'
  },
  ML: {
    name: 'Mali',
    currency: 'XOF',
    code: '223'
  },
  MM: {
    name: 'Myanmar',
    currency: 'MMK',
    code: '95'
  },
  MN: {
    name: 'Mongolia',
    currency: 'MNT',
    code: '976'
  },
  MO: {
    name: 'Macao',
    currency: 'MOP',
    code: '853'
  },
  MP: {
    name: 'Northern Mariana Islands',
    currency: 'USD',
    code: '1'
  },
  MQ: {
    name: 'Martinique',
    currency: 'EUR',
    code: '596'
  },
  MR: {
    name: 'Mauritania',
    currency: 'MRO',
    code: '222'
  },
  MS: {
    name: 'Montserrat',
    currency: 'XCD',
    code: '1'
  },
  MT: {
    name: 'Malta',
    currency: 'EUR',
    code: '356'
  },
  MU: {
    name: 'Mauritius',
    currency: 'MUR',
    code: '230'
  },
  MV: {
    name: 'Maldives',
    currency: 'MVR',
    code: '960'
  },
  MW: {
    name: 'Malawi',
    currency: 'MWK',
    code: '265'
  },
  MX: {
    name: 'Mexico',
    currency: 'MXN',
    code: '52',
    phone: {
      pattern: /^\d{10}$/,
      maxLength: 10
    }
  },
  MY: {
    name: 'Malaysia',
    currency: 'MYR',
    code: '60'
  },
  MZ: {
    name: 'Mozambique',
    currency: 'MZN',
    code: '258'
  },
  NA: {
    name: 'Namibia',
    currency: 'NAD,ZAR',
    code: '264'
  },
  NC: {
    name: 'New Caledonia',
    currency: 'XPF',
    code: '687'
  },
  NE: {
    name: 'Niger',
    currency: 'XOF',
    code: '227'
  },
  NF: {
    name: 'Norfolk Island',
    currency: 'AUD',
    code: '672'
  },
  NG: {
    name: 'Nigeria',
    currency: 'NGN',
    code: '234'
  },
  NI: {
    name: 'Nicaragua',
    currency: 'NIO',
    code: '505'
  },
  NL: {
    name: 'Netherlands',
    currency: 'EUR',
    code: '31'
  },
  NO: {
    name: 'Norway',
    currency: 'NOK',
    code: '47'
  },
  NP: {
    name: 'Nepal',
    currency: 'NPR',
    code: '977'
  },
  NR: {
    name: 'Nauru',
    currency: 'AUD',
    code: '674'
  },
  NU: {
    name: 'Niue',
    currency: 'NZD',
    code: '683'
  },
  NZ: {
    name: 'New Zealand',
    currency: 'NZD',
    code: '64'
  },
  OM: {
    name: 'Oman',
    currency: 'OMR',
    code: '968'
  },
  PA: {
    name: 'Panama',
    currency: 'USD',
    code: '507'
  },
  PE: {
    name: 'Peru',
    currency: 'PEN',
    code: '51'
  },
  PF: {
    name: 'French polynesia',
    currency: 'XPF',
    code: '689'
  },
  PG: {
    name: 'Papua New Guinea',
    currency: 'PGK',
    code: '675'
  },
  PH: {
    name: 'The Philippines',
    currency: 'PHP',
    code: '63'
  },
  PK: {
    name: 'Pakistan',
    currency: 'PKR',
    code: '92'
  },
  PL: {
    name: 'Poland',
    currency: 'PLN',
    code: '48'
  },
  PM: {
    name: 'Saint-Pierre and Miquelon',
    currency: 'EUR',
    code: '508'
  },
  PN: {
    name: 'Pitcairn Islands',
    currency: 'NZD',
    code: '64'
  },
  PR: {
    name: 'Puerto Rico',
    currency: 'USD',
    code: '1'
  },
  PS: {
    name: 'Palestinian territories',
    currency: '',
    code: '970'
  },
  PT: {
    name: 'Portugal',
    currency: 'EUR',
    code: '351'
  },
  PW: {
    name: 'Palau',
    currency: 'USD',
    code: '680'
  },
  PY: {
    name: 'Paraguay',
    currency: 'PYG',
    code: '595'
  },
  QA: {
    name: 'Qatar',
    currency: 'QAR',
    code: '974'
  },
  RE: {
    name: 'Réunion',
    currency: 'EUR',
    code: '262'
  },
  RO: {
    name: 'Romania',
    currency: 'RON',
    code: '40'
  },
  RS: {
    name: 'Serbia',
    currency: 'RSD',
    code: '381'
  },
  RU: {
    name: 'Russian Federation',
    currency: 'RUB',
    code: '7',
    phone: {
      pattern: /^\d{10}$/,
      maxLength: 10
    }
  },
  RW: {
    name: 'Rwanda',
    currency: 'RWF',
    code: '250'
  },
  SA: {
    name: 'Saudi Arabia',
    currency: 'SAR',
    code: '966'
  },
  SB: {
    name: 'Solomon Islands',
    currency: 'SBD',
    code: '677'
  },
  SC: {
    name: 'Seychelles',
    currency: 'SCR',
    code: '248'
  },
  SD: {
    name: 'Sudan',
    currency: 'SDG',
    code: '249'
  },
  SE: {
    name: 'Sweden',
    currency: 'SEK',
    code: '46'
  },
  SG: {
    name: 'Singapore',
    currency: 'SGD',
    code: '65'
  },
  SH: {
    name: 'St. Helena & Dependencies',
    currency: 'SHP',
    code: '290'
  },
  SI: {
    name: 'Slovenia',
    currency: 'EUR',
    code: '386'
  },
  SJ: {
    name: 'Template:Country data SJM Svalbard',
    currency: 'NOK',
    code: '47'
  },
  SK: {
    name: 'Slovakia',
    currency: 'EUR',
    code: '421'
  },
  SL: {
    name: 'Sierra Leone',
    currency: 'SLL',
    code: '232'
  },
  SM: {
    name: 'San Marino',
    currency: 'EUR',
    code: '378'
  },
  SN: {
    name: 'Senegal',
    currency: 'XOF',
    code: '221'
  },
  SO: {
    name: 'Somalia',
    currency: 'SOS',
    code: '252'
  },
  SR: {
    name: 'Suriname',
    currency: 'SRD',
    code: '597'
  },
  SS: {
    name: 'South Sudan',
    currency: 'SSP',
    code: '211'
  },
  ST: {
    name: 'Sao Tome & Principe',
    currency: 'STD',
    code: '239'
  },
  SV: {
    name: 'El Salvador',
    currency: 'USD',
    code: '503'
  },
  SY: {
    name: 'Syria',
    currency: 'SYP',
    code: '963'
  },
  SZ: {
    name: 'Swaziland',
    currency: 'SZL',
    code: '268'
  },
  TC: {
    name: 'Turks & Caicos Islands',
    currency: 'USD',
    code: '1'
  },
  TD: {
    name: 'Chad',
    currency: 'XAF',
    code: '235'
  },
  TF: {
    name: 'French Southern Territories',
    currency: 'EUR',
    code: '262'
  },
  TG: {
    name: 'Togo',
    currency: 'XOF',
    code: '228'
  },
  TH: {
    name: 'Thailand',
    currency: 'THB',
    code: '66'
  },
  TJ: {
    name: 'Tajikistan',
    currency: 'TJS',
    code: '992'
  },
  TK: {
    name: 'Tokelau',
    currency: 'NZD',
    code: '690'
  },
  TL: {
    name: 'Timor-Leste',
    currency: 'USD',
    code: '670'
  },
  TM: {
    name: 'Turkmenistan',
    currency: 'TMT',
    code: '993'
  },
  TN: {
    name: 'Tunisia',
    currency: 'TND',
    code: '216'
  },
  TO: {
    name: 'Tonga',
    currency: 'TOP',
    code: '676'
  },
  TR: {
    name: 'Turkey',
    currency: 'TRY',
    code: '90'
  },
  TT: {
    name: 'Trinidad & Tobago',
    currency: 'TTD',
    code: '1'
  },
  TV: {
    name: 'Tuvalu',
    currency: 'AUD',
    code: '688'
  },
  TW: {
    name: 'Taiwan',
    currency: 'NTD',
    code: '886'
  },
  TZ: {
    name: 'Tanzania',
    currency: 'TZS',
    code: '255'
  },
  UA: {
    name: 'Ukraine',
    currency: 'UAH',
    code: '380'
  },
  UG: {
    name: 'Uganda',
    currency: 'UGX',
    code: '256'
  },
  UM: {
    name: 'United States Minor Outlying Islands',
    currency: 'USD',
    code: '246'
  },
  US: {
    name: 'United States of America',
    currency: 'USD',
    code: '1'
  },
  UY: {
    name: 'Uruguay',
    currency: 'UYU',
    code: '598'
  },
  UZ: {
    name: 'Uzbekistan',
    currency: 'UZS',
    code: '998'
  },
  VA: {
    name: 'Vatican City',
    currency: 'EUR',
    code: '379'
  },
  VC: {
    name: 'St. Vincent & the Grenadines',
    currency: 'XCD',
    code: '1'
  },
  VE: {
    name: 'Venezuela',
    currency: 'VEF',
    code: '58'
  },
  VG: {
    name: 'British Virgin Islands',
    currency: 'USD',
    code: '1'
  },
  VI: {
    name: 'United States Virgin Islands',
    currency: 'USD',
    code: '1'
  },
  VN: {
    name: 'Vietnam',
    currency: 'VND',
    code: '84'
  },
  VU: {
    name: 'Vanuatu',
    currency: 'VUV',
    code: '678'
  },
  WF: {
    name: 'Wallis and Futuna',
    currency: 'XPF',
    code: '681'
  },
  WS: {
    name: 'Samoa',
    currency: 'WST',
    code: '685'
  },
  YE: {
    name: 'Yemen',
    currency: 'YER',
    code: '967'
  },
  YT: {
    name: 'Mayotte',
    currency: 'EUR',
    code: '262'
  },
  ZA: {
    name: 'South Africa',
    currency: 'ZAR',
    code: '27'
  },
  ZM: {
    name: 'Zambia',
    currency: 'ZMW',
    code: '260'
  },
  ZW: {
    name: 'Zimbabwe',
    currency: 'ZWL',
    code: '263'
  }
};
exports.list = list;