/**
 * Format address before submit
 */
export function getFormatAddressData(
  value: string,
  countryList: Array<any>,
): {
  countryCode: string
  state: string
  city: string
} {
  const arr = value.split(', ')
  if (arr.length > 2) {
    const [countryName, state, city] = arr
    const countryCode = countryList?.find((item) => item.name === countryName)?.code || countryName
    return {
      countryCode,
      state,
      city,
    }
  }
  return {
    countryCode: '',
    state: '',
    city: '',
  }
}

/**
 * transfer country name to iso code
 */
export function countryToISOCode(countryName: string, countryList: Array<any>): string {
  return countryList?.find((item) => item.name === countryName)?.code || countryName
}
