// https://developer.paypal.com/docs/limited-release/fraudnet/integrate/
import SafeJSON from './SafeJSON'

const fncls = 'fnparams-dede7cc5-15fd-4c75-a9f4-36c430ee3a99'
const fnUrl = 'https://c.paypal.com/da/r/fb.js'
const identifier = 'paypal_reference_01'

// 针对paypal reference支付时的风控能力
const FraudNet = {
  init(): void {
    const configScript = document.createElement('script')
    configScript.setAttribute('id', 'fraudnet-config')
    configScript.setAttribute('type', 'application/json')
    configScript.setAttribute('fncls', fncls)

    const script = document.createElement('script')
    script.setAttribute('id', 'fraudnet-script')

    const noScript = document.createElement('noscript')
    noScript.setAttribute('id', 'fraudnet-noscript')

    document.body.appendChild(configScript)
    document.body.appendChild(script)
    document.body.appendChild(noScript)
  },
  setCMID(cmid: string): void {
    if (!cmid) {
      console.error('no cmid')
      return
    }
    if (!document.querySelector('#fraudnet-script')) {
      this.init()
    }
    // 进行配置内容添加
    const configScript = document.querySelector('#fraudnet-config')
    const config = {
      f: cmid,
      s: identifier,
    }
    if (configScript) {
      configScript.innerHTML = SafeJSON.stringify(config)
    }
    // 加载远程脚本读取配置
    const script = document.querySelector<HTMLScriptElement>('#fraudnet-script')
    if (script) {
      script.src = fnUrl
    }

    const noscript = document.querySelector('#fraudnet-noscript') as HTMLScriptElement
    const imgEle = document.createElement('img')
    if (noscript) {
      imgEle.setAttribute('src', `https://c.paypal.com/v1/r/d/b/ns?f=${cmid}&s=${identifier}&js=0&r=1`)
      noscript.appendChild(imgEle)
    }
  },
  clear(): void {
    document.querySelector('#fraudnet-config')?.remove()
    document.querySelector('#fraudnet-script')?.remove()
    document.querySelector('#fraudnet-noscript')?.remove()
  },
}

export default FraudNet
