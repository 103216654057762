import { DEFAULT_THEME_COLOR } from '@/constant'
import { IColors } from '@/typings'

export const initThemeColor = (colors: IColors = {}) => {
  const colorParams = {
    ...DEFAULT_THEME_COLOR,
    ...colors,
    secondaryColor: colors?.secondaryColor || colors?.mainColor || DEFAULT_THEME_COLOR.mainColor,
  }
  const colorsKey = Object.keys(colorParams) as (keyof IColors)[]
  colorsKey?.forEach((key) => {
    document.body.style.setProperty(`--${key}`, colorParams[key] || null)
  })
}
