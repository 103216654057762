import { useCallback, useRef, useState } from 'react'

import Feelgood from '@ad/feelgood-sdk'
import { Message } from '@arco-design/web-react'

import starling from '@/utils/starling'

import { payLogger } from '..'

interface IUseFeelgoodProps {
  onClose?: () => void
  errorMsg?: string
}

export const useFeelgoodBack = ({ onClose, errorMsg }: IUseFeelgoodProps) => {
  // 是否是首次展示Feelgood
  const isFirstFeelGood = useRef<boolean>(true)
  const [loadingFeelgood, setLoadingFeelgood] = useState(false)

  const handleFeelgoodSubmitComplete = useCallback(() => {
    Message.success({ content: starling('funds.check_out.comm.prompt_thanks_feedback') })
    Feelgood.$off('submit-completed', handleFeelgoodSubmitComplete)
    onClose?.()
    ;(document.getElementsByClassName('athena-widget-mask') as unknown as any[]).forEach((element) => {
      element.classList.remove('upay-feelgood-modal-mask')
    })
    ;(document.getElementsByClassName('athena-survey-widget') as unknown as any[]).forEach((element) => {
      element.classList.remove('upay-feelgood-modal', 'notranslate')
    })
  }, [onClose])

  const handleFBCancelClick = useCallback(async () => {
    if (errorMsg || !isFirstFeelGood.current) {
      // FeelGood仅请求显示一次 报错禁用时不显示
      onClose?.()
      return
    }

    setLoadingFeelgood(true)
    const startTime = new Date().getTime()
    const widget = await Feelgood.triggerEvent('clickBack', { timeoutInMs: 2000 })
    const time = new Date().getTime() - startTime
    setLoadingFeelgood(false)
    if (widget) {
      payLogger.scenes.payBackShowFeelgood({ show: true, time })
      ;(document.getElementsByClassName('athena-widget-mask') as unknown as any[]).forEach((element) => {
        element.classList.add('upay-feelgood-modal-mask')
      })
      ;(document.getElementsByClassName('athena-survey-widget') as unknown as any[]).forEach((element) => {
        element.classList.add('upay-feelgood-modal', 'notranslate')
      })
      isFirstFeelGood.current = false
      Feelgood.$on('submit-completed', handleFeelgoodSubmitComplete)
    } else {
      payLogger.scenes.payBackShowFeelgood({ show: false, time })
      onClose?.()
    }
  }, [errorMsg, handleFeelgoodSubmitComplete, onClose])

  return { handleFBCancelClick, loadingFeelgood }
}
