import { IdcT } from '@upay/utils/es/types'

import { IS_DEV, JS_CDN_DOMAIN_PREFIX } from '@/constant'
import utils, { loadScript } from '@/utils'

const loginMode = 0x201

// 接入风控的path列表，想保护哪些path就填哪些，没填的path默认不会受保护，也不会带上签名
const protectPathList = [
  '/upay/i18n/payment/submit_order',
  '/upay/i18n/payment/submit_extra_info',
  '/upay/i18n/card/bind',
  '/upay/i18n/card/update',
  '/upay/i18n/risk/shark_judge',
  '/upay/i18n/payout/bind_pi',
  '/upay/i18n/payout/update_pi',
  '/upay/i18n/payout/submit',
]

// 该sdk会改写window.fetch，需要在需要保护的请求之前进行加载执行
export async function initMssdk(appId: number, idc: IdcT) {
  try {
    const scriptUrl = `${JS_CDN_DOMAIN_PREFIX}/webmssdk/1.0.0.12/webmssdk.js`
    await loadScript(scriptUrl, execInit, 'module', 'head', { appId, idc })
  } catch (e) {
    console.error('风控脚本加载异常', e)
  }
}

async function execInit(extraParams?: Record<string, any>) {
  const params = {
    aid: extraParams?.appId, // appid，必填,在云平台申请
    isSDK: false, // 默认为true, 如果是中台类sdk接入填写true，业务方填写false
    boe: Boolean(IS_DEV) || location.host.includes('boe') || location.host.includes('boei18n'), // boe模式, 会切换配置到boe环境。生产环境请勿开启。
    enablePathList: protectPathList,
    // 如果接入方式是app，填写以下两个参数。如果是中台sdk，不需要填写。
    region: utils.getIdc(extraParams?.idc, true), // 地区: (数据区域)（中台类sdk的数据上报跟着业务方同机房)
    mode: loginMode, // 初始化时选择对应的用户模式
  }
  console.log('Risk Load Success', window.byted_acrawler, params)
  if (window.byted_acrawler) {
    window.byted_acrawler.init(params)
  }
}
