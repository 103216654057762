import { PaymentMethodItem, IntegrationMethod } from '../../types'

export const MIR_PAYMENT_METHOD: PaymentMethodItem = {
  paymentMethodType: {
    configuration: {},
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/mir_stroke_ff9859.svg',
    },
    starling_key: 'pipo_payin_payment_paywithmir',
    paymentMethodType: 'CCDC',
  },
  paymentMethod: {
    configuration: {},
    paymentMethod: 'CARD_QIWI',
    paymentMethodId: 'pm_pi_ccdc_cardqiwi_c_r',
    integrationMethod: IntegrationMethod.redirect,
    displayName: '',
    starling_key: 'pipo_payin_payment_paywithmir',
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/mir_gray_f778e9.svg',
    },
  },
}
