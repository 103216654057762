import React from 'react'
import ReactDOM from 'react-dom'

import PubSub from 'pubsub-js'

import { IOptions } from '@/typings'
import utils from '@/utils'
import slardar, { logger } from '@/utils/slardar'

import UpaySDK from './UpaySDK'
import { initRtl } from './utils/rtl'

export default class UPAY {
  options: IOptions
  unique: string
  initStartTime: number
  constructor(options: IOptions) {
    // UPAY初始化开始时间
    this.initStartTime = new Date().getTime()
    this.options = options || {}
    this.unique = utils.guid()
    this.init().catch((error: any) => {
      console.error('sdkInitError', error)
      slardar.timer('sdkInitErrorCount', 1, { error })
    })
  }

  async init(): Promise<void> {
    logger.printVersion(BUILD_VERSION)
    logger.printPrettier({ label: 'UPAY_SDK_URL：' }, this.options.url)
    logger.printPrettier({ label: 'UPAY_SDK_ENV：' }, ZONE_ENV, BUILD_ENV)
    // 在Window挂载相关信息，方便查看
    window.__UPAY_BUILD_VERSION__ = BUILD_VERSION
    !window.__UPAY_SDK_URL__ && (window.__UPAY_SDK_URL__ = [])
    window.__UPAY_SDK_URL__.push(this.options.url)

    // 容器准备
    const { container } = this.options
    const MOUNT_NODE = typeof container === 'string' ? document.getElementById(container) : container
    MOUNT_NODE && ReactDOM.unmountComponentAtNode(MOUNT_NODE!)
    // 参数赋默认值
    this.options.params = Object.assign(
      {
        defaultVisible: true,
        isBoe: false,
        language: 'en',
        hideTitle: false,
        hideNewMethod: false,
        allowSkip: false,
        showSuccessStatus: true,
        allowCancel: false,
      },
      this.options.params,
    )
    // 在渲染前完成Rtl能力初始化
    initRtl(this.options.params.language)

    ReactDOM.render(<UpaySDK options={this.options} unique={this.unique} initStartTime={this.initStartTime} />, MOUNT_NODE, () => {
      // 首屏渲染时长
      const diff = Date.now() - this.initStartTime
      console.log('UPAY_SDK_MOUNT_TIME：', diff)
      slardar.performance('sdk_mount', diff)
    })
  }
  listen(key: string, callback: (val?: unknown) => void): void {
    PubSub.subscribe(`${this.unique}.${key}`, (msg: string, values: unknown) => {
      callback(values)
    })
  }
  trigger(key: string, val?: unknown): void {
    PubSub.publish(`${this.unique}.${key}`, val)
  }
}
