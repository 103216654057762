import React, { useCallback, useMemo, useRef, useState } from 'react'
import type { FieldError } from 'react-hook-form'

import { intersectionWith } from 'lodash'

import {
  BANK_CARD_REGEX_LIST,
  PipoProvider,
  SUPPORT_PAYMENT_METHODS_ID_MAP,
  SupportPaymentMethodId,
  chunkString,
  isGeneralCCDC,
  validator,
} from '@/pipo/utils'
import type { PaymentMethodItem } from '@/pipo/utils'
import starling from '@/utils/starling'

type ErrorType = 'required' | 'validCardBIN' | 'validLength' | 'number' | 'luhn'

export function useCardNumber(props: {
  paymentMethodsList: {
    paymentMethodId: SupportPaymentMethodId
  }[]
}): {
  cardNumberInputRef: React.RefObject<HTMLInputElement>
  cardBrands: PaymentMethodItem[]
  cardNoValidator: {
    validCardBIN: (value?: string) => boolean
    validLength: (value?: string) => boolean
    luhn: () => boolean
  }
  currentCardBrand?: PaymentMethodItem
  cardNoPlaceholder: string
  cardNoMaxLength: number
  formatValue: (value: string) => string
  formatCursor: (oldVal: string, newVal: string, cursorPosition: number) => number
  getCardNoError: (error: FieldError | undefined) => string
  onCardNoChange: (e: React.ChangeEvent<HTMLInputElement>, callback: (...event: unknown[]) => void) => void
  binRiskPass: boolean
  setBinRiskPass: React.Dispatch<React.SetStateAction<boolean>>
} {
  const { paymentMethodsList } = props
  const { t, getErrorText } = PipoProvider.useContext()
  const [binRiskPass, setBinRiskPass] = useState(false)
  const cardNoPlaceholder = '1234 3213 2313 3213'
  const cardNoMaxLength = 23
  const currentCardBrandRef = useRef<PaymentMethodItem>()
  const cardNumberInputRef = React.createRef<HTMLInputElement>()
  const cardBrands = useMemo<PaymentMethodItem[]>(() => {
    return paymentMethodsList
      .filter((item) => {
        const pmInfo = SUPPORT_PAYMENT_METHODS_ID_MAP[item.paymentMethodId]
        return pmInfo && isGeneralCCDC(pmInfo)
      })
      .map((item) => {
        return SUPPORT_PAYMENT_METHODS_ID_MAP[item.paymentMethodId]
      })
  }, [paymentMethodsList])

  // 每四个插入空格
  const formatValue = useCallback((value: string) => {
    if (value.includes(' ')) {
      value = value?.replace(/\s/g, '')
    }
    if (value.length > 4) {
      return chunkString(value, 4).join(' ')
    }
    return value
  }, [])

  const formatCursor = useCallback((oldVal: string, newVal: string, cursorPosition: number) => {
    const editionType = newVal.length > oldVal.length ? 'insert' : 'delete'

    if (cursorPosition !== 0 && cursorPosition % 5 === 0) {
      return cursorPosition + (editionType === 'insert' ? 1 : -1)
    } else {
      return cursorPosition
    }
  }, [])

  const cardNoValidator = {
    validCardBIN: (value = '') => {
      const cardNo = value?.replace(/\s/g, '')
      // 1. 根据 cardNumber 筛选出所有满足 BANK_CARD_REGEX_LIST 正则的卡组列表
      const matchedRules = BANK_CARD_REGEX_LIST?.filter((item) => {
        const regex = new RegExp(item.cardBinRegex)
        return regex.test(cardNo)
      })
      // 2. 查找 matchedRules 数组中，PD 支持的支付方式，并返回第一个满足条件的支付方式信息
      // 取 matchedRules、cardBrands 数组的交集，以 matchedRules 的顺序为准
      const intersection = intersectionWith(matchedRules, cardBrands, (a, b) => a?.paymentMethod === b?.paymentMethod?.paymentMethod)
      const pmsPaymentMethodInfo = cardBrands?.find((cb) => cb?.paymentMethod?.paymentMethod === intersection[0]?.paymentMethod)
      // 3. 如果存在满足条件 2 的支付方式，则更新 Card number 输入框中的卡组 logo
      if (pmsPaymentMethodInfo) {
        currentCardBrandRef.current = pmsPaymentMethodInfo
      }
      // 4. 返回校验结果，既满足正则，又满足支付决策 方可校验成功
      return Boolean(matchedRules.length) && Boolean(pmsPaymentMethodInfo)
    },
    validLength: (value = '') => {
      const cardNo = value?.replace(/\s/g, '')
      const currentCardBrand = currentCardBrandRef.current
      const configuration = currentCardBrand?.paymentMethod?.configuration as {
        card_brand_length: number[]
      }
      return configuration?.card_brand_length?.includes(cardNo.length)
    },
    luhn: (value = '') => {
      const cardNo = value?.replace(/\s/g, '')
      return validator.luhn(cardNo)
    },
  }

  const getCardNoError = useCallback(
    (error: FieldError | undefined): string => {
      const errorType = error?.type as ErrorType
      const errorMessage = error?.message
      switch (errorType) {
        case 'required':
          return getErrorText(errorType)
        case 'validCardBIN':
          return starling('funds.refund.comm.system_unsupportedcard')
        case 'validLength':
          return starling('funds.refund.comm.system_invalidlength')
        case 'luhn':
          return getErrorText(errorType)
        default:
          return errorMessage || ''
      }
    },
    [getErrorText, t],
  )

  const onCardNoChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, callback: (...event: unknown[]) => void) => {
      const value = e.target.value || ''
      if (!value) {
        currentCardBrandRef.current = undefined
      }
      e.target.value = formatValue(value)
      if (/^[\d\s]*$/.test(value)) {
        callback(e)
      }
    },
    [formatValue],
  )

  return {
    cardNumberInputRef,
    cardBrands,
    cardNoValidator,
    currentCardBrand: currentCardBrandRef.current,
    cardNoPlaceholder,
    cardNoMaxLength,
    binRiskPass,
    formatValue,
    formatCursor,
    getCardNoError,
    onCardNoChange,
    setBinRiskPass,
  }
}
