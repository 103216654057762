import React, { ReactElement, forwardRef, useEffect } from 'react'
import { Controller, ControllerFieldState, useForm } from 'react-hook-form'

import { useControlled } from '@byted/hooks'

import { PAY_WAY_TYPE } from '@/constant'
import { useTouchedErrors } from '@/pipo/hooks'
import {
  BOBanktransferFormProps,
  IBANBanktransferValidator,
  INTERNETBANKINGFormData,
  PipoProvider,
  bankList,
  banktransferBankIcon,
  errorMessage,
  onBankIbanChange,
  setDisplayBankIcon,
  validator,
} from '@/pipo/utils'
import starling from '@/utils/starling'
import teaClient from '@/utils/tea'

import { HolderName, Iban, NationalID } from '../../inner-components'

export const BOBanktransferForm = forwardRef((props: BOBanktransferFormProps<INTERNETBANKINGFormData>, _ref): ReactElement => {
  const { onValidate } = props

  const [formValue = {}, onChange] = useControlled<INTERNETBANKINGFormData>(props)
  const ibanInputRef = React.createRef<HTMLInputElement>()

  const {
    control,
    getValues,
    formState: { errors: formErrors, isValid, touchedFields },
  } = useForm<INTERNETBANKINGFormData>({
    mode: 'all',
  })

  const errors = useTouchedErrors<INTERNETBANKINGFormData>({
    touchedFields,
    errors: formErrors,
  })

  // const methods = useFormContext<PaymentMethodFormData>();
  const { t, getErrorText } = PipoProvider.useContext()

  // handleChange & onValidatet when form is dirty
  useEffect(() => {
    onChange(formValue)
    onValidate(isValid)
  }, [formValue, isValid])

  const handleBlur = (scenario: string, fieldState: ControllerFieldState) => {
    const params = {
      pay_way: PAY_WAY_TYPE.BANK_TRANSFER_BO,
      scenario,
      is_valid: !fieldState.error,
      invalid_reason: fieldState.error?.type,
    }
    teaClient.sendPayPageFillIn(params)
  }

  return (
    <div className="payment-banktransfer-payeasy" id="upay-form-banktransfer-bo">
      <Controller
        rules={{ required: true, validate: IBANBanktransferValidator }}
        name="iban"
        control={control}
        render={({ field, fieldState }) => (
          <Iban
            {...field}
            ref={ibanInputRef}
            title={starling('cg.upay.exception.channel_youriban')}
            value={formValue.iban}
            onChange={(val) => onBankIbanChange(val, (val) => onChange({ ...formValue, iban: val }), field.onChange)}
            placeholder={starling('cg.upay.exception.channel_youriban')}
            error={errors.iban}
            errorMessage={errorMessage(errors.iban?.type, starling('cg.upay.exception.channel_youriban'), getErrorText, t)}
            bankList={bankList}
            setDisplayBankIcon={setDisplayBankIcon}
            supportBank={banktransferBankIcon}
            onBlur={() => {
              field.onBlur()
              handleBlur('iban', fieldState)
            }}
          />
        )}
      />
      <Controller
        rules={{ required: true, pattern: validator.nationalIDRegex }}
        name="identity"
        control={control}
        render={({ field, fieldState }) => (
          <NationalID
            {...field}
            title={starling('cg.upay.exception.channel_national_id')}
            value={formValue.identity}
            onChange={(val) => {
              field.onChange(val)
              onChange({ ...formValue, identity: val })
            }}
            placeholder={starling('cg.upay.exception.channel_national_id')}
            error={errors.identity}
            errorMessage={getErrorText(errors.identity?.type)}
            onBlur={() => {
              field.onBlur()
              handleBlur('identity', fieldState)
            }}
          />
        )}
      />
      {/* Frist Name Romaji */}
      <Controller
        rules={{ required: true, pattern: validator.LatinNameRegex }}
        name="holder_first_name"
        control={control}
        render={({ field, fieldState }) => (
          <HolderName
            {...field}
            value={formValue.holder_first_name}
            onChange={(val) => {
              field.onChange(val)
              onChange({ ...formValue, holder_first_name: val })
            }}
            error={errors.holder_first_name}
            errorMessage={getErrorText(errors.holder_first_name?.type)}
            maxLength={255}
            title={starling('funds.refund.comm.system_firstname')}
            placeholder={starling('funds.refund.comm.system_firstname_placeholder')}
            onBlur={() => {
              field.onBlur()
              handleBlur('holder_first_name', fieldState)
            }}
          />
        )}
      />
      {/** Last name Romaji */}
      <Controller
        rules={{ required: true, pattern: validator.LatinNameRegex }}
        name="holder_last_name"
        control={control}
        render={({ field, fieldState }) => (
          <HolderName
            {...field}
            value={formValue.holder_last_name}
            onChange={(val) => {
              field.onChange(val)
              onChange({ ...formValue, holder_last_name: val })
            }}
            error={errors.holder_last_name}
            errorMessage={getErrorText(errors.holder_last_name?.type)}
            maxLength={255}
            title={starling('cg.upay.exception.channel_lastname')}
            placeholder={starling('cg.upay.exception.channel_lastname')}
            onBlur={() => {
              field.onBlur()
              handleBlur('holder_last_name', fieldState)
            }}
          />
        )}
      />
      <div className="pc-payment-item-label">
        İşbu hizmet, Türkiye Cumhuriyeti Merkez Bankası ("TCMB") tarafından denetlenen Birleşik Ödeme Hizmetleri ve Elektronik Para A.Ş tarafından
        olarak verilmektedir.
      </div>
      <div style={{ marginTop: 20 }} className="pc-payment-item-label">
        {starling('cg.upay.exception.channel_birlesik_odeme_disclaimer')}
      </div>
    </div>
  )
})
