import { useContext } from 'react'

import { useRequest } from '@byted/hooks'

import api from '@/services'
import { IPayoutGetBindElementsProps } from '@/services/payout-types'
import { ParamsContext } from '@/stores'

const usePayoutBindElements = () => {
  const {
    urlQuery: { token },
  } = useContext(ParamsContext)

  const {
    data: bankListRes,
    run: getBankListAsync,
    loading: getBankListLoading,
  } = useRequest(
    (params: { payWay: number; userType: IPayoutGetBindElementsProps['userType'] }) =>
      api.getPayoutBindElements({
        token,
        ...params,
      }),
    {
      auto: false,
    },
  )

  return {
    bankList: bankListRes?.data?.elements?.find((item) => item?.option_list?.length)?.option_list,
    getBankListAsync: getBankListAsync as unknown as (params: { payWay: number; userType: IPayoutGetBindElementsProps['userType'] }) => void,
    getBankListLoading,
  } as const
}

export default usePayoutBindElements
