import React, { useContext, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Button, Skeleton } from '@arco-design/web-react'
import { useRequest } from '@byted/hooks'
import { StatusTypeEnum } from '@upay/utils/es/types'

import { oneTimeHoc } from '@/HOC/oneTimeHoc'
import { Loading, PageModal, Policy, SecurityTip } from '@/components'
import { PullStatusEnum } from '@/components/Status/config'
import { COMMON_PROMPT, FROM_PAGE_ENUM, NEW_SDK_TERMINAL_EQUIP } from '@/constant'
import { IPayinMethodItem } from '@/pages/PayNext/interface'
import { IOnChangeProps, PayoutComponentRef } from '@/pipo/pc-payout/types'
import api from '@/services'
import { ParamsContext } from '@/stores'
import GlobalInfo from '@/stores/GlobalInfo'
import { ModeTypeEnum } from '@/typings'
import SafeJSON from '@/utils/SafeJSON'
import starling from '@/utils/starling'

import PaymentList from '../components/PaymentList'
import { PayoutContext } from '../context'
import { useCardHolderInfo } from '../hooks'
import { getItemKey } from '../utils'
import PayoutBindStatus from './components/BindStatus'
import BindTitle from './components/BindTitle'
import { useBindList } from './hooks/useBindList'
import { formatSubmitFormDataForBindProcess } from './utils'

import './index.less'

interface IProps {
  onClose?: (needRefreshList?: boolean) => void
  from?: FROM_PAGE_ENUM
}
const PayoutCreate: React.FC<IProps> = (props) => {
  const { from, onClose: onClosePage } = props
  const {
    params,
    urlQuery: { token },
  } = useContext(ParamsContext)
  const {
    data: { riskInfoObj },
    utils: { getPublicKeyAsync },
  } = useContext(GlobalInfo)
  const isIndependentPage = from === FROM_PAGE_ENUM.PAYOUT && params.mode === ModeTypeEnum.HostedPage
  const [innerVisible, setInnerVisible] = useState(true)
  const [posting, setPosting] = useState(false)
  const [statusProps, setStatusProps] = useState({
    errorMsg: '',
    visible: false,
    currentStatus: StatusTypeEnum.SUCCESS,
  })
  const { control, watch } = useForm()
  const [selectedItem, setSelectedItem] = useState<IPayinMethodItem>()
  const activeKey = getItemKey(selectedItem) // 内部使用
  const activeItem = watch(activeKey) as IOnChangeProps

  const outerFormRef = useRef(new Map()) // 缓存所有子项表单

  const { paymentList, getPaymentListLoading } = useBindList({})
  const cardHolderInfo = useCardHolderInfo()
  const disabled = getPaymentListLoading || !selectedItem || activeItem?.isValid === false

  const { run: runBindCard } = useRequest(api.payoutBindCard, {
    auto: false,
  })

  const handleSubmitClick = async () => {
    if (!selectedItem || !activeKey) return

    try {
      setInnerVisible(false)
      setPosting(true)

      const activeForm: PayoutComponentRef = outerFormRef.current.get(activeKey)?.current
      // 新支付渠道参数
      const submitData: any = activeForm?.getSubmitData()
      const publicKey = await getPublicKeyAsync()
      const { data } = await runBindCard({
        payWay: selectedItem.payWay,
        riskInfo: SafeJSON.stringify(riskInfoObj),
        token,
        terminalEquip: NEW_SDK_TERMINAL_EQUIP,
        terminalEquipStr: 'SDK',
        channelParameter: formatSubmitFormDataForBindProcess(submitData, publicKey),
      })
      if (data?.status === PullStatusEnum.SUCCESS) {
        setStatusProps({
          currentStatus: StatusTypeEnum.SUCCESS,
          visible: true,
          errorMsg: '',
        })
      } else {
        throw data?.errorMsg || ''
      }
    } catch (e: any) {
      setStatusProps({
        currentStatus: StatusTypeEnum.FAILED,
        visible: true,
        errorMsg: e?.msg || e?.message || e || COMMON_PROMPT().SYS_ERR,
      })
    } finally {
      setPosting(false)
    }
  }
  const handleModalClose = () => {
    onClosePage?.()
  }

  const handleClickClose = () => {
    setStatusProps((prevStatus) => ({
      ...prevStatus,
      visible: false,
    }))
    onClosePage?.(true)
  }
  const handleTryAgain = () => {
    setStatusProps({
      visible: false,
      errorMsg: '',
      currentStatus: StatusTypeEnum.SUCCESS,
    })
    setInnerVisible(true)
  }

  const renderFooter = () => {
    return (
      <div className="upay-modal-footer">
        <div className="upay-modal-footer-left">
          <Button onClick={handleModalClose}>{starling('funds.refund.comm.back')}</Button>
        </div>
        <div className="upay-modal-footer-right">
          <Button type="primary" disabled={disabled} loading={posting} onClick={handleSubmitClick}>
            {starling('funds.refund.comm.submit')}
          </Button>
        </div>
      </div>
    )
  }
  return (
    <div className="upay-payout">
      <PageModal
        visible={isIndependentPage || innerVisible}
        contentHeight={params.height}
        mode={isIndependentPage && params.mode}
        className="upay-payout-modal upay-modal"
        footer={renderFooter()}
        closable={false}
        alignCenter={false}
      >
        <BindTitle />
        <SecurityTip />
        <Skeleton
          className="upay-payout-skeleton"
          loading={getPaymentListLoading}
          animation
          text={{ width: ['260px', '100%', '100%', '60%'], rows: 4 }}
        >
          <PayoutContext.Provider
            value={{
              control,
              outerFormRef,
              cardHolderInfo,
            }}
          >
            <PaymentList dataSource={paymentList} selectedItem={selectedItem} setSelectedItem={setSelectedItem} />
          </PayoutContext.Provider>
        </Skeleton>
        <Policy policyUrl={params.policyUrl} />
      </PageModal>

      <Loading show={posting} showBtn={false} />
      <PayoutBindStatus {...statusProps} onOk={handleClickClose} onTryAgain={handleTryAgain} />
    </div>
  )
}

export default oneTimeHoc(PayoutCreate)
