import React, { forwardRef, useEffect, useState } from 'react'
import { Controller, ControllerFieldState, useForm } from 'react-hook-form'

import FormBuilder from '@/components/FormBuilder'
import { PAY_WAY_TYPE } from '@/constant'
import { usePipoRouter, useTouchedErrors } from '@/pipo/hooks'
import { PaymentParamsItem, PipoComponentRef, PipoProvider, PipoSubmitParams, callingCode, generateCommonPaymentParams } from '@/pipo/utils'
import { OXXO_PAYMENT_METHOD, OXXO_PAYMENT_PARAMS_MAP, OxxoFormData, OxxoProps } from '@/pipo/utils/payment-methods-config/oxxo'
import starling from '@/utils/starling'
import teaClient from '@/utils/tea'

import { CountryCode, PhoneNumber, SaveBox } from '../../inner-components'
import { SCHEMA_A, SCHEMA_B } from './conf'

const clsPrefix = 'pipo-bindcard-pc'

export const OXXOForm = forwardRef((props: OxxoProps, ref: React.ForwardedRef<PipoComponentRef>) => {
  const {
    showSavingBox = false,
    onValidate = () => {
      // do nothing
    },
  } = props
  const form = useForm<OxxoFormData>({
    mode: 'all',
  })
  const {
    control,
    getValues,
    formState: { errors: formErrors, isValid, touchedFields },
  } = form
  const errors = useTouchedErrors<OxxoFormData>({
    touchedFields,
    errors: formErrors,
  })

  const { getErrorText, t, state } = PipoProvider.useContext()
  const { countryOrRegion = '', config = {} } = state
  const { selectedSaveBox = false } = config
  const phoneCountryCode = callingCode.list[countryOrRegion].code

  const [storeCard, setStoreCard] = useState<boolean>(selectedSaveBox)

  useEffect(() => {
    isValid && onValidate(true)
  }, [isValid, onValidate])

  /**
   * 处理PIPO提交所需数据
   * @returns PipoSubmitParams
   */
  const processSubmitData = async (formData: OxxoFormData): Promise<PipoSubmitParams> => {
    const paymentParams: PaymentParamsItem[] = generateCommonPaymentParams(formData, OXXO_PAYMENT_PARAMS_MAP)

    const { paymentMethodType, paymentMethod } = OXXO_PAYMENT_METHOD
    return {
      formData,
      paymentMethod: {
        paymentMethod: paymentMethod.paymentMethod,
        paymentMethodId: paymentMethod.paymentMethodId,
      },
      paymentMethodType: paymentMethodType.paymentMethodType,
      paymentParams,
      storeCard: storeCard && showSavingBox,
    }
  }

  /**
   * 校验后获取PIPO提交所需数据
   * @returns PipoSubmitParams
   */
  const validateSubmitData = () => {
    return new Promise(async (resolve: (value: PipoSubmitParams) => void, reject: (reason: typeof errors) => void) => {
      if (!isValid) {
        reject(errors)
      } else {
        const formData = getValues()
        resolve(await processSubmitData(formData))
      }
    })
  }

  usePipoRouter(ref, {
    initParams: {
      onValidate,
      isValid,
    },
    instanceMethods: {
      validateSubmitData,
    },
  })

  const handleBlur = (scenario: string, fieldState: ControllerFieldState) => {
    const params = {
      pay_way: PAY_WAY_TYPE.OXXO,
      scenario,
      is_valid: !fieldState.error,
      invalid_reason: fieldState.error?.type,
    }
    teaClient.sendPayPageFillIn(params)
  }

  return (
    <div className="pipo-pc" id="upay-form-oxxo">
      <div className={`${clsPrefix}-flex-container`}>
        <div className={`${clsPrefix}-form-wrapper`}>
          <div className={`${clsPrefix}-subform-wrapper`}>
            <FormBuilder form={form} schema={SCHEMA_A({ t })} errors={errors} handleBlur={handleBlur} />

            {/* Phone Number Block */}
            <Controller
              rules={{ required: true }}
              name="phone_country_code"
              control={control}
              render={({ field }) => <CountryCode countryCode={phoneCountryCode} onChange={field.onChange} />}
            />
            <Controller
              rules={{ required: true, pattern: /^\d{10}$/ }}
              name="phone"
              control={control}
              render={({ field, fieldState }) => (
                <PhoneNumber
                  {...field}
                  title={t('pipo_common_payin_phone_number')}
                  placeholder={t('pipo_common_payin_ph_phone_number')}
                  error={errors.phone}
                  errorMessage={getErrorText(errors.phone?.type)}
                  maxLength={10}
                  countryCode={phoneCountryCode}
                  countryOrRegion={countryOrRegion}
                  phoneNumberStyle="half"
                  onBlur={() => {
                    field.onBlur()
                    handleBlur('phone', fieldState)
                  }}
                />
              )}
            />

            <FormBuilder form={form} schema={SCHEMA_B({ t })} errors={errors} handleBlur={handleBlur} />

            {/* Save Box */}
            {showSavingBox && (
              <SaveBox title={starling('funds.refund.comm.system_save_card_information')} storeCard={storeCard} setStoreCard={setStoreCard} />
            )}
          </div>
        </div>
      </div>
    </div>
  )
})
