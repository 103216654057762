import React, { useCallback, useEffect, useState } from 'react'

import { Skeleton } from '@arco-design/web-react'

import { IBankListItem } from '@/services/types'

import './style.less'

interface IBankListProps {
  selectedKey?: string
  list: IBankListItem[]
  loading: boolean
  onChange: (item: IBankListItem) => void
}

export default function BankList({ selectedKey = '', list, loading, onChange }: IBankListProps): JSX.Element {
  const [select, setSelect] = useState(selectedKey)
  const handleBankItemClick = useCallback(
    (item: IBankListItem) => {
      setSelect(item.key)
      onChange(item)
    },
    [onChange],
  )
  useEffect(() => {
    setSelect(selectedKey)
  }, [selectedKey])
  return loading ? (
    <Skeleton loading={loading} animation image={false} text={{ rows: 3 }} />
  ) : (
    <div className="upay-banklist-container">
      {list.map((item) => (
        <div
          className={`upay-banklist-item ${select === item.key ? 'upay-banklist-selected' : ''}`}
          key={item.key}
          title={item.name}
          onClick={() => handleBankItemClick(item)}
        >
          <img className="upay-banklist-icon" src={item.icon_url} alt={item.name} />
          <div className="upay-banklist-content">{item.name}</div>
        </div>
      ))}
    </div>
  )
}
