import React, { useContext, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Button } from '@arco-design/web-react'
import { useRequest } from '@byted/hooks'
import { StatusActionTypeEnum, StatusTypeEnum } from '@upay/utils/es/types'

import { oneTimeHoc } from '@/HOC/oneTimeHoc'
import { Loading, PageModal, Policy, SecurityTip } from '@/components'
import { PullStatusEnum } from '@/components/Status/config'
import { COMMON_PROMPT, NEW_SDK_TERMINAL_EQUIP } from '@/constant'
import { PayoutComponentRef } from '@/pipo/pc-payout/types'
import api from '@/services'
import { CardListRes } from '@/services/types'
import { ParamsContext } from '@/stores'
import GlobalInfo from '@/stores/GlobalInfo'
import SafeJSON from '@/utils/SafeJSON'
import starling from '@/utils/starling'

import PayoutBindStatus from '../Create/components/BindStatus'
import { formatSubmitFormDataForBindProcess } from '../Create/utils'
import UpdateWidget from './components/UpdateWidget'

import './index.less'

interface IProps {
  card: CardListRes
  onClose?: (needRefresh?: boolean) => void
}
const PayoutUpdate: React.FC<IProps> = (props) => {
  const { card, onClose: onClosePage } = props
  const {
    params,
    urlQuery: { token },
  } = useContext(ParamsContext)
  const {
    data: { riskInfoObj },
    utils: { getPublicKeyAsync },
  } = useContext(GlobalInfo)
  const [innerVisible, setInnerVisible] = useState(true)
  const [posting, setPosting] = useState(false)
  const [statusProps, setStatusProps] = useState({
    errorMsg: '',
    visible: false,
    currentStatus: StatusTypeEnum.SUCCESS,
  })
  const formRef = useRef<PayoutComponentRef | null>(null)
  const { control, watch } = useForm()
  const activeItem = watch('updateItem')

  const disabled = activeItem?.isValid === false

  const { run: runUpdateCard } = useRequest(api.payoutUpdateCard, {
    auto: false,
  })

  const handleSubmitClick = async () => {
    try {
      setInnerVisible(false)
      setPosting(true)

      const submitData = formRef.current!.getSubmitData()
      const publicKey = await getPublicKeyAsync()
      const { data } = await runUpdateCard({
        payWay: card.payWay,
        cardId: card.id || '',
        riskInfo: SafeJSON.stringify(riskInfoObj),
        token,
        channelParameter: formatSubmitFormDataForBindProcess(submitData, publicKey),
        terminalEquipStr: 'SDK',
        terminalEquip: NEW_SDK_TERMINAL_EQUIP,
      })

      if (data?.status === PullStatusEnum.SUCCESS) {
        setStatusProps({
          currentStatus: StatusTypeEnum.SUCCESS,
          visible: true,
          errorMsg: '',
        })
      } else {
        throw data?.errorMsg || ''
      }
    } catch (e: any) {
      setStatusProps({
        currentStatus: StatusTypeEnum.FAILED,
        visible: true,
        errorMsg: e?.msg || e?.message || e || COMMON_PROMPT().SYS_ERR,
      })
    } finally {
      setPosting(false)
    }
  }

  const handleModalClose = () => {
    onClosePage?.()
  }

  const handleClickClose = () => {
    setInnerVisible(false)
    setStatusProps((prevStatus) => ({
      ...prevStatus,
      visible: false,
    }))
    onClosePage?.(true)
  }

  const handleTryAgain = () => {
    setStatusProps({
      visible: false,
      errorMsg: '',
      currentStatus: StatusTypeEnum.SUCCESS,
    })
    setInnerVisible(true)
  }

  const renderFooter = () => {
    return (
      <div className="upay-modal-footer">
        <div className="upay-modal-footer-left">
          <Button onClick={handleModalClose}>{starling('funds.refund.comm.back')}</Button>
        </div>
        <div className="upay-modal-footer-right">
          <Button type="primary" disabled={disabled} loading={posting} onClick={handleSubmitClick}>
            {starling('funds.refund.comm.submit')}
          </Button>
        </div>
      </div>
    )
  }
  return (
    <div className="upay-payout">
      <PageModal
        visible={innerVisible}
        contentHeight={params.height}
        mode={false}
        className="upay-payout-modal upay-modal"
        footer={renderFooter()}
        closable={false}
        alignCenter={false}
      >
        <div className="upay-modal-header">
          <div className="upay-modal-header-title">{starling('funds.refund.comm.edit_paymentmethod')}</div>
        </div>

        <SecurityTip />
        <UpdateWidget card={card} control={control} forwardRef={formRef} />

        <Policy policyUrl={params.policyUrl} />
      </PageModal>

      <Loading show={posting} showBtn={false} />
      <PayoutBindStatus {...statusProps} actionType={StatusActionTypeEnum.UPDATE} onOk={handleClickClose} onTryAgain={handleTryAgain} />
    </div>
  )
}

export default oneTimeHoc(PayoutUpdate)
