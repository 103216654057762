import React, { useContext } from 'react'

import { Button } from '@arco-design/web-react'

import { PUBSUB_TYPES } from '@/constant'
import { ParamsContext } from '@/stores'
import slardar from '@/utils/slardar'
import starling from '@/utils/starling'

interface IProps {
  disabled: boolean
  handleSubmit: () => void
  onClose: () => void
}
const ChangeFooter: React.FC<IProps> = (props) => {
  const { disabled, handleSubmit, onClose } = props
  const { params, uPubSub } = useContext(ParamsContext)

  const handleCancel = () => {
    slardar.timer('changeCancelBtnClick')
    uPubSub.publish(PUBSUB_TYPES.MODAL_CANCEL, false)
    onClose()
  }

  const handleSkip = () => {
    slardar.timer('changeSkipBtnClick')
    uPubSub.publish(PUBSUB_TYPES.MODAL_CANCEL, true)
    onClose()
  }

  return (
    <div className="upay-modal-footer upay-change__footer">
      <div className="upay-modal-footer-left upay-change__footer-left">
        <Button onClick={handleCancel}>{starling('funds.refund.comm.back')}</Button>
      </div>
      <div className="upay-modal-footer-right upay-change__footer-right">
        {params.allowSkip && <Button onClick={handleSkip}>{starling('funds.refund.comm.skip')}</Button>}
        <Button type="primary" disabled={disabled} onClick={handleSubmit}>
          {starling('funds.refund.comm.submit')}
        </Button>
      </div>
    </div>
  )
}

export default ChangeFooter
