import React, { ReactElement, useEffect, useState } from 'react'

import { TabsProps } from '@arco-design/web-react'
import { TabPaneProps } from '@arco-design/web-react/es/Tabs'
import { TabPaneType } from '@arco-design/web-react/es/Tabs/tab-pane'

const getPaneChildren = (props: TabsProps) => {
  const { children } = props
  const paneChildren: any[] = []
  React.Children.forEach(children as any, (child: ReactElement) => {
    if (child && child.type && (child as ReactElement<TabPaneProps, TabPaneType>).type.isTabPane) {
      paneChildren.push(child)
    }
  })
  return paneChildren as ReactElement<TabPaneProps, TabPaneType>[]
}

const SimpleTabs = (props: TabsProps) => {
  const { activeTab } = props
  const paneChildren = getPaneChildren(props)
  const [activePanel, setActivePanel] = useState(activeTab || paneChildren[0]?.key)

  useEffect(() => {
    if (props.activeTab) {
      setActivePanel(props.activeTab)
    }
  }, [activeTab])

  return (
    <div style={{ width: '100%' }}>
      {paneChildren.map((pane) => {
        return (
          <div key={pane.key} style={{ display: pane.key !== activePanel ? 'none' : 'block', width: '100%' }}>
            {pane}
          </div>
        )
      })}
    </div>
  )
}

export default SimpleTabs
