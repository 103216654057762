import React, { FC, useState } from 'react'

import { Input, Popover } from '@arco-design/web-react'
import { Help } from '@icon-park/react'

import CVVSvg from '@/static/svg/cvv.svg'
import starling from '@/utils/starling'

import './index.less'

export interface ICVVProps {
  /** cvv的值 */
  value: string
  /**
   * cvv变化时的回调
   */
  onChange: (val: string) => void
  /**
   * 当前cvv校验
   */
  onValidated: (valid: boolean) => void
}

const CVVForm: FC<ICVVProps> = (props) => {
  const [errMsg, setErrMsg] = useState('')

  const handleChange = (val: string) => {
    let msg = ''
    if (val.length === 0) {
      msg = starling('funds.refund.comm.system_security_code_err_msg')
    } else if (!/^[0-9]{3,4}$/.test(val)) {
      msg = starling('funds.refund.comm.system_security_code_invalid')
    }
    setErrMsg(msg)
    props.onValidated?.(!msg)
    props.onChange?.(val)
  }

  const renderPopoverContent = () => {
    return (
      <div className="upay-cvv__popover-content">
        <img src={CVVSvg} className="upay-cvv__popover-icon" />
        <div className="upay-cvv__popover-title">{starling('funds.refund.comm.how_to_find_cvv')}</div>
        <div className="upay-cvv__popover-text">{starling('funds.refund.comm.cvv_details')}</div>
      </div>
    )
  }

  return (
    <div className="upay-cvv">
      <div className="upay-cvv__input-title">
        <span>{starling('funds.refund.comm.system_security_code')}</span>
        <Popover trigger="click" position="top" content={renderPopoverContent()}>
          <Help theme="outline" size="16" fill="#d8d8d8" strokeWidth={3} style={{ marginLeft: '10px' }} />
        </Popover>
      </div>
      <div className="upay-cvv__input-wrap">
        <Input
          className="upay-cvv__input"
          placeholder="CVV/CVC"
          value={props.value}
          maxLength={4}
          size="large"
          onChange={(value: string) => handleChange(value)}
          error={!!errMsg}
        />
        <span className="upay-cvv__input-tip">{errMsg}</span>
      </div>
    </div>
  )
}

export default CVVForm
