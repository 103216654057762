import { PaymentMethodItem } from '../../types'

export type INTERNETBANKINGFormData = {
  holder_first_name?: string
  holder_last_name?: string
  iban?: string
  identity?: string
}

// TODO: Change
export const INTERNETBANKING_BO_PAYMENT_PARAMS_MAP: Record<string, string> = {
  holder_first_name: 'eg_pi_ib_birlesikodeme_c_r_local_tr_holder_first_name',
  holder_last_name: 'eg_pi_ib_birlesikodeme_c_r_local_tr_holder_last_name',
  iban: 'eg_pi_ib_birlesikodeme_c_r_local_tr_iban',
  identity: 'eg_pi_ib_birlesikodeme_c_r_local_tr_identity',
}

export const INTERNETBANKING_BO_PAYMENT_METHOD: PaymentMethodItem = {
  paymentMethodType: {
    configuration: {},
    icon: {
      iconUrl: '',
    },
    starling_key: 'pipo_payin_cashier_dropin_payment_internetbanking',
    paymentMethodType: 'INTERNET_BANKING',
  },
  paymentMethod: {
    configuration: {
      png_icon_url: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/bointernetbankinggrey_a1ec03.png',
      dark_mode_png_icon_url: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/bointernetbankingdark_a1ec03.png',
    },
    paymentMethod: 'Birlesik Odeme Internet Banking',
    paymentMethodId: 'pm_pi_ib_birlesikodeme_c_r',
    displayName: 'Birlesik Odeme Internet Banking',
    starling_key: 'pipo_payin_logo_Birlesik_Odeme_internet_banking',
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/bointernetbankinggrey_a1ec03.png',
      pngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/bointernetbankinggrey_a1ec03.png',
      darkModePngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/bointernetbankingdark_a1ec03.png',
      grayIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/bointernetbankinggrey_a1ec03.png',
    },
  },
}

export const bankList = [
  'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/akbankgrey_c1216c.png',
  'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/isbankgrey_fd5301.png',
  'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/albarakagrey_b0c72c.png',
  'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/vakifbankgrey_be5faa.png',
  'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/denizbankgrey_0caae5.png',
  'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/kuveytturkgreynew_7e540a.png',
  'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/fibabankagreynew_335225.png',
  'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/yapikredigrey_a21d9d.png',
  'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/alternatifbankgrey_74ee22.png',
  'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/garantigrey_8c7389.png',
  'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/halkbankgrey_c2146f.png',
  'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/qnbgrey_ac19a8.png',
  'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/turkiyefinansgrey_d6d00a.png',
  'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/ziraatbankgrey_794716.png',
  'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/sekerbankgrey_9cfa80.png',
  'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/tebgrey_cc6cf8.png',
  'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/inggreynew_f3fc8b.png',
]

export const internetBankList = [
  'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/akbankgrey_c1216c.png',
  'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/isbankgrey_fd5301.png',
  'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/albarakagrey_b0c72c.png',
  'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/vakifbankgrey_be5faa.png',
  'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/denizbankgrey_0caae5.png',
  'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/kuveytturkgreynew_7e540a.png',
  'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/fibabankagreynew_335225.png',
]
