/* eslint-disable @typescript-eslint/ban-ts-comment */
import { CCDC_PAYMENT_PARAMS_MAP, PaymentParamsItem, SUPPORT_PAYMENT_METHODS_ID_MAP, generateCommonPaymentParams } from '@/pipo/utils'

export const generateCCDCSubmitParams = (data: Record<string, string>, pmId: string | undefined) => {
  const paymentParams: PaymentParamsItem[] = generateCommonPaymentParams(data, CCDC_PAYMENT_PARAMS_MAP)
  const currentPaymentMethodId = pmId || 'pm_pi_ccdc_visa_c_d'
  const { paymentMethod, paymentMethodId } =
    // @ts-ignore
    SUPPORT_PAYMENT_METHODS_ID_MAP[`${currentPaymentMethodId}`]?.paymentMethod

  return {
    formData: data,
    paymentMethod: {
      paymentMethod,
      paymentMethodId,
    },
    paymentMethodType:
      // @ts-ignore
      SUPPORT_PAYMENT_METHODS_ID_MAP[`${currentPaymentMethodId}`]?.paymentMethodType?.paymentMethodType,
    paymentParams,
    storeCard: false,
  }
}
