import React from 'react'

import { Modal } from '@arco-design/web-react'

import starling from '@/utils/starling'

import Captcha from '../Captcha'
import { ICheckOTPModalProps } from './types'

import './style.less'

const clsPrefix = 'upay-otp-modal'

const CheckOTPModal = ({
  visible,
  isDisabled,
  submitLoading,
  codeValue,
  errMsg,
  identityNo,
  resendLoading,
  resendTime,
  handleCancel,
  handleSubmit,
  handleCodeChange,
  handleResend,
}: ICheckOTPModalProps) => {
  return (
    <Modal
      className={clsPrefix}
      title={<div className={`${clsPrefix}-title`}>{starling('cg.upay.exception.channel_otp_subtitle')}</div>}
      visible={visible}
      okButtonProps={{ disabled: isDisabled, loading: submitLoading }}
      okText={starling('funds.refund.comm.confirm_button')}
      cancelText={starling('funds.refund.comm.cancel')}
      wrapStyle={{ zIndex: 10020 }}
      maskStyle={{ zIndex: 10020 }}
      onCancel={handleCancel}
      maskClosable={false}
      onOk={handleSubmit}
    >
      <div className={`${clsPrefix}-tip`}>{starling('cg.upay.exception.channel_otp_instruction', { mobile_No: identityNo })}</div>
      <Captcha value={codeValue} onChange={handleCodeChange} length={6} errorMsg={errMsg} loading={resendLoading} disabled={submitLoading} />
      <div className={`${clsPrefix}-resend`}>
        {starling('cg.upay.exception.channel_otp_didnt_get_code')}
        <span className={`${clsPrefix}-resend-btn ${resendTime > 0 ? `${clsPrefix}-resend-btn-disabled` : ''}`} onClick={handleResend}>
          {resendTime > 0
            ? starling('cg.upay.exception.channel_otp_resend_countdown', { time: resendTime })
            : starling('cg.upay.exception.channel_otp_resend_btn')}
        </span>
      </div>
    </Modal>
  )
}

export default React.memo(CheckOTPModal)
