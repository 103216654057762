import { useCallback, useContext } from 'react'

import { NEW_SDK_TERMINAL_EQUIP } from '@/constant'
import api from '@/services'
import { ParamsContext } from '@/stores'

export interface IPostRecordParams {
  actionId: string
  actionType: number
  payWay?: string | number
  upaySessionId?: string
  refId?: string
  decision_cost?: number
}

export type SubmitRecord = (params: IPostRecordParams) => void

export type UsePostRecord = () => {
  submitRecord: SubmitRecord
}
const usePostRecord: UsePostRecord = () => {
  const { urlQuery } = useContext(ParamsContext)

  const submitRecord = useCallback(
    (extraParams: IPostRecordParams) => {
      const { token, appId, accountId } = urlQuery
      if (!token || !extraParams?.actionId) {
        console.warn('post record param error')
        return
      }
      api
        .postRecord({
          token,
          sourcePlatform: appId,
          advId: accountId,
          terminalEquip: NEW_SDK_TERMINAL_EQUIP,
          actionTime: new Date().getTime(),
          ...extraParams,
        })
        .catch((e) => {
          console.warn('post record error', e)
        })
    },
    [urlQuery],
  )

  return { submitRecord }
}

export default usePostRecord
