import React, { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'

import { useRequest } from '@byted/hooks'

import { PAYMENT, PAY_WAY_TYPE } from '@/constant'
import { IPayinMethodItem, PaymentSubmitMethodEnum } from '@/pages/PayNext/interface'
import { Boleto } from '@/pipo/pc'
import { BoletoProps } from '@/pipo/utils'
import { PipoComponentRef } from '@/pipo/utils/types'
import GlobalInfo from '@/stores/GlobalInfo'
import SafeJSON from '@/utils/SafeJSON'
import slardar from '@/utils/slardar'

import { IPaymentMethodsProps, IPaymentsMethodRef } from '../../interface'

const BoletoForm = forwardRef<IPaymentsMethodRef, IPaymentMethodsProps>((props, ref): React.ReactElement => {
  const { changeNewPaymentStatus, urlQuery, channel, paymentSubmitMethod } = props
  const {
    PIPOApi: { fetchDistrict },
    utils: { getContractAddressAsync },
  } = useContext(GlobalInfo)

  const boletoRef = useRef<PipoComponentRef>(null)
  const [boletoValid, setBoletoValid] = useState(false)

  const { data: contractAddressList } = useRequest(getContractAddressAsync, { auto: true })

  // TODO: 暂时兼容两种逻辑，后续新UI全量后可梳理移除，由后端维护是否显示
  const showSavingBox = useMemo(() => {
    // 仅支付场景展示SaveBox
    if (paymentSubmitMethod !== PaymentSubmitMethodEnum.PAYMENT) return false

    // 若后端返回 以后端为准 老逻辑未返回排除后付费
    const supportFromBE = (channel as IPayinMethodItem).supportPaymentAndBind
    return typeof supportFromBE === 'boolean' ? supportFromBE : urlQuery.type !== PAYMENT.POST_PAY
  }, [paymentSubmitMethod, channel, urlQuery.type])

  // 同步验证状态
  useEffect(() => {
    changeNewPaymentStatus(PAY_WAY_TYPE.BOLETO, { valid: boletoValid })
  }, [boletoValid, changeNewPaymentStatus])

  useImperativeHandle(ref, () => ({
    processSubmitData: async (submitParams) => {
      if (boletoRef.current?.validateSubmitData) {
        const { paymentMethod, paymentParams, storeCard, fillAddressState } = await boletoRef.current?.validateSubmitData()

        fillAddressState &&
          slardar.timer('fillAddressSubmitEvent', fillAddressState.metrics, {
            from: 'BOLETO',
            ...fillAddressState.categories,
          })

        const paymentMethodObj = {
          method_id: paymentMethod.paymentMethodId,
          payment_elements: paymentParams,
        }
        submitParams.channelParameter = SafeJSON.stringify(paymentMethodObj)
        // 后付费不展示绑卡
        submitParams.bindAndPay = storeCard && showSavingBox
      }
      return submitParams
    },
  }))

  const onBoletoValid = useCallback((value: boolean) => {
    setBoletoValid(value)
  }, [])

  const boletoProps: BoletoProps = {
    onFetchDistrict: fetchDistrict,
    onValidate: onBoletoValid,
    showSavingBox,
    contractAddressList,
  }

  return <Boleto ref={boletoRef} {...boletoProps} />
})

export default BoletoForm
