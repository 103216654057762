import starling from '@/utils/starling'

import { ConfigType } from './types'

export const GET_PAYMENT_CODE_CONFIG: () => ConfigType = () => ({
  // bankTransfer 线下支付方式
  BANK_TRANSFER: {
    // is a collapsible panel
    collapsable: true,
    // specific payment methods
    methods: [
      {
        subTitleValue: starling('funds.refund.comm.makingpayment_mobilebanking'),
        mobileIcon: 'right',
        pcIcon: 'bank',
        bankGuideKey: 'mobile',
      },
      {
        subTitleValue: starling('funds.refund.comm.makingpayment_internetbanking'),
        mobileIcon: 'phone',
        pcIcon: 'phone',
        bankGuideKey: 'internet',
      },
      {
        subTitleValue: starling('funds.refund.comm.makingpayment_ATM'),
        mobileIcon: 'bank',
        pcIcon: 'atm',
        bankGuideKey: 'atm',
      },
      {
        subTitleValue: starling('funds.refund.comm.makingpayment_otherbankpayment'),
        mobileIcon: 'bank',
        pcIcon: 'atm',
        bankGuideKey: 'otherbank',
      },
    ],
  },
  // cash at Indomaret 便利店线下支付方式
  INDOMARET: {
    collapsable: true,
    methods: [
      {
        starlingKey: 'pipo_payin_paymentcode_indomaret_cvs_cashier_title',
        contentStarlingKey: 'cvs_cashier_content',
      },
      {
        starlingKey: 'pipo_payin_paymentcode_indomaret_cvs_title_ikiosk_machine',
        contentStarlingKey: 'cvs_content_ikiosk_machine',
      },
      {
        starlingKey: 'pipo_payin_paymentcode_Indomaret_ikios_title',
        contentStarlingKey: 'ikios_content',
      },
    ],
  },
  // cash at Alfa 便利店线下支付方式
  ALFA: {
    collapsable: false,
    methods: [
      {
        contentStarlingKey: 'cvs_cahsier_content',
      },
    ],
  },
  // Fawry Config
  FAWRY: {
    collapsable: false,
    methods: [
      {
        contentStarlingKey: 'cashier_content',
      },
    ],
  },
  // OXXO Config
  OXXO: {
    collapsable: false,
    methods: [
      {
        contentStarlingKey: 'instruction',
      },
    ],
  },
  GO_PAY: {
    collapsable: true,
    methods: [
      {
        subTitleValue: starling('funds.refund.comm.makingpayment_GoPay'),
        contentValue: starling('funds.refund.comm.makingpayment_GoPay_detail'),
        pcIcon: 'bank',
        mobileIcon: 'right',
        defaultActive: true,
      },
    ],
  },
  TOSS: {
    collapsable: false,
    methods: [
      {
        contentValue: starling('cg.upay.exception.channel_va_make_payment_prompt'),
        pcIcon: 'bank',
        mobileIcon: 'right',
        defaultActive: true,
      },
    ],
  },
})
