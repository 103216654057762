import React, { FC } from 'react'

import { Button, Modal, Tooltip } from '@arco-design/web-react'
import { StatusTypeEnum } from '@upay/utils/es/types'

import { IMAGE_SRC_MAP } from '@/components/Status/config'
import { oneTimeHoc } from '@/utils/oneTimeHoc'
import starling from '@/utils/starling'

import { ICombinationStatusProps } from '../../hooks/useCombinationStatus'
import StatusIcon from './StatusIcon'

import './index.less'

const prefix = 'upay-combination-modal'
const CombinationStatus: FC<ICombinationStatusProps> = (props) => {
  const { payStatus, bindStatus, onOk, onCancel, onRetry, timeCount } = props
  let title = starling('funds.check_out.comm.status_payment_processing')
  if (payStatus.status === StatusTypeEnum.SUCCESS) {
    title = starling('funds.refund.comm.payment_successful')
  } else if (payStatus.status === StatusTypeEnum.FAIL || bindStatus.status === StatusTypeEnum.FAIL) {
    title = starling('funds.refund.comm.payment_failed')
  }
  const statusLogoRender = () => {
    let status = payStatus.status
    if (bindStatus.status === StatusTypeEnum.FAIL) status = StatusTypeEnum.FAIL
    return <img src={IMAGE_SRC_MAP.get(status)} id={`upay-status-${[payStatus.status]}`} />
  }

  return (
    <Modal className={`upay-modal upay-modal-simple ${prefix}`} visible={true} footer={null} closable={false}>
      <div className={`${prefix}-container`}>
        {statusLogoRender()}
        <div className={`${prefix}-title`}>{title}</div>
        <div className={`${prefix}-status-box`}>
          <div className={`${prefix}-status ${prefix}-bind-status`}>
            <StatusIcon status={bindStatus.status} />
            <span className={`${prefix}-status-text`}>{starling('funds.refund.comm.system_add_payment_method')}</span>
            <div className={`${prefix}-status-msg`}>
              <Tooltip content={bindStatus.msg}>{bindStatus.msg}</Tooltip>
            </div>
          </div>
          <div className={`${prefix}-line`} />
          <div className={`${prefix}-status`}>
            <StatusIcon status={payStatus.status} />

            <span className={`${prefix}-status-text`}>{starling('funds.refund.comm.make_payment')}</span>
            <div className={`${prefix}-status-msg`}>
              <Tooltip content={payStatus.msg}>{payStatus.msg}</Tooltip>
            </div>
          </div>
        </div>
        {(bindStatus.status === StatusTypeEnum.FAIL || payStatus.status === StatusTypeEnum.FAIL) && (
          <div className={`${prefix}-btn-line`}>
            <Button className={`${prefix}-status-btn`} onClick={onCancel}>
              {starling('funds.refund.comm.skip')}
            </Button>
            <Button type="primary" className={`${prefix}-status-btn`} onClick={onRetry}>
              {starling('funds.refund.comm.try_again')}
            </Button>
          </div>
        )}
        {bindStatus.status === StatusTypeEnum.SUCCESS && payStatus.status === StatusTypeEnum.SUCCESS && (
          <div className={`${prefix}-btn-line`}>
            <Button type="primary" className={`${prefix}-status-btn`} onClick={onOk}>
              {starling('funds.refund.comm.confirm_button')} ({timeCount}s)
            </Button>
          </div>
        )}
      </div>
    </Modal>
  )
}
export default oneTimeHoc(CombinationStatus)
