import React from 'react'

import { Tooltip } from '@arco-design/web-react'
import { IconQuestionCircle } from '@arco-design/web-react/icon'
import classNames from 'clsx'

import { PipoProvider } from '@/pipo/utils'
import starling from '@/utils/starling'

import { CustomInput, ItemBlock } from '../index'

interface IProps {
  title?: string
  placeholder: string
  errorMessage: string
  hasError: boolean
  value?: string
  onChange?: (value: string) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void
}

const clsPrefix = 'pipo-pc-cvv'
const cvvIconSrc = 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/cvv_f906cf.png'

export const CVV = React.forwardRef((props: IProps, ref: any) => {
  const { title, placeholder, errorMessage, hasError, onBlur } = props
  const { t, isFlip } = PipoProvider.useContext()

  return (
    <ItemBlock
      style="half"
      title={
        <Tooltip
          className={`${clsPrefix}-tooltip`}
          color="#fff"
          position="tl"
          trigger="hover"
          content={
            <div className={`${clsPrefix}-tooltip-content`}>
              <img className={`${clsPrefix}-img`} src={cvvIconSrc} />
              <div className={`${clsPrefix}-title`}>{starling('funds.refund.comm.how_to_find_cvv')}</div>
              <div className={`${clsPrefix}-text`}>{starling('funds.refund.comm.system_security_code_tips')}</div>
            </div>
          }
        >
          <span>{title}</span>
          <IconQuestionCircle
            className={classNames(`${clsPrefix}-icon`, {
              [`${clsPrefix}-icon-flip`]: isFlip,
            })}
          />
        </Tooltip>
      }
      errorState={hasError}
      errorMessage={errorMessage}
    >
      <CustomInput
        {...props}
        ref={ref}
        allowClear
        size="large"
        type="number"
        maxLength={4}
        placeholder={placeholder}
        error={hasError}
        onBlur={onBlur}
      />
    </ItemBlock>
  )
})
