import { BankListSelect, Email, HolderName, ItemInputNumber, SelectRegion } from '@/pipo/pc/inner-components'
import { validator } from '@/pipo/utils'
import starling from '@/utils/starling'

import { IBankAccountFormConfigItem, IGetBankAccountFormConfigProps } from '../utils'

export const getMYFormConfig = ({ cardHolderInfo, billingAddressProps, bankList }: IGetBankAccountFormConfigProps): IBankAccountFormConfigItem => {
  return {
    allowAccountType: ['b', 'c'],
    needInfo: ['bank_list'],
    formSchema: [
      {
        field: 'holder_name',
        widget: HolderName,
        widgetProps: {
          len: 'full',
          title: starling('funds.check_out.comm.channel_remittername'),
          placeholder: starling('funds.refund.comm.system_name_description'),
          maxLength: 70,
          selectList: cardHolderInfo?.holderNameList,
        },
        rules: { required: true, pattern: validator.myHolderName },
      },
      {
        field: 'bank_code',
        widget: BankListSelect,
        widgetProps: {
          title: starling('funds.refund.comm.bank_name'),
          placeholder: starling('funds.refund.comm.bank_selection'),
          bankList,
        },
        rules: { required: true },
      },
      {
        field: 'account_no',
        widget: ItemInputNumber,
        widgetProps: {
          title: starling('funds.refund.comm.system_bankaccount_account_number'),
          placeholder: starling('funds.refund.comm.system_bankaccount_account_number_placeholder'),
          maxLength: 34,
        },
        rules: { required: true },
      },
      {
        field: 'email',
        widget: Email,
        widgetProps: {
          title: starling('funds.refund.comm.system_email'),
          placeholder: starling('funds.refund.comm.system_email_placeholder'),
        },
        rules: { required: true, pattern: validator.emailRegex },
      },
      {
        field: 'select_region',
        widget: SelectRegion,
        noController: true,
        widgetProps: {
          ...billingAddressProps,
          onlyCurrentCountry: true,
          level: 2,
        },
      },
    ],
  }
}
