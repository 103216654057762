import React, { forwardRef, useImperativeHandle, useRef } from 'react'

import { Alert } from '@arco-design/web-react'

import { Info, Warning } from '@/static/icon'
import starling from '@/utils/starling'

import { HINT_TYPES, IHintProps, IHintRef } from './interface'

import './index.less'

const Hint = forwardRef<IHintRef, IHintProps>((props, ref): JSX.Element => {
  const { content = '', preset, plain = false, type = '', showIcon = true, show } = props
  const hintDom = useRef<HTMLDivElement>(null)

  useImperativeHandle(ref, () => ({
    getHeight: () => hintDom.current?.getBoundingClientRect()?.height || 0,
  }))

  const infoProps = {
    showIcon,
    show,
  }

  let text = ''
  let iconType = 'info' as 'info' | 'error' | 'warning'

  /**
   * Hint 文案配置部分const
   */

  // const COMMON_LIMIT_INFO = {
  //   amount: props.amount || 3,
  //   currency: props.currency || 'USD',
  //   minDay: 1,
  //   maxDay: 15,
  // }

  const HINT_TYPES_TEXT = {
    [HINT_TYPES.INDIA_DEDUCTION]: {
      type: 'info',
      text: starling('funds.refund.comm.withholding_terms_int'),
    },
    [HINT_TYPES.VERIFICATION_REDIRECT]: {
      type: 'info',
      text: starling('funds.refund.comm.third_party_desc'),
    },
  } as {
    [index: string]: {
      type: 'info' | 'error'
      text: string
    }
  }

  if (content) {
    text = content
    iconType = type ? type : 'info'
  } else if (preset !== undefined && HINT_TYPES_TEXT[preset]) {
    text = HINT_TYPES_TEXT[preset].text
    iconType = type ? type : HINT_TYPES_TEXT[preset].type
  } else {
    return <></>
  }

  return (
    <div ref={hintDom} className="upay-hint">
      {plain ? (
        <div className="upay-hint__plain">
          <span className="upay-hint__plain-icon">{iconType === 'warning' ? <Warning /> : <Info />}</span>
          <span style={{ flex: 1 }}>{text}</span>
        </div>
      ) : (
        <Alert icon={iconType === 'warning' ? <Warning /> : <Info />} content={text} type={iconType} {...infoProps} />
      )}
    </div>
  )
})

export default React.memo(Hint)
