export interface IPayoutSubmitOrderReq {
  cardId: string
  token: string
  payWay: number
  terminalEquipStr: string
  channelParameter: string
  riskInfo: string
}
export interface IPayoutBindCardReq {
  token: string
  payWay: number
  channelParameter?: string
  riskInfo: string
  terminalEquipStr?: string
  /** payout应该用terminalEquipStr */
  terminalEquip?: number
}
export interface IPayoutBindCardRes {
  cardId?: number
  status?: number
  errorMsg?: string
}
export interface IBindListReq {
  token: string
  version?: string
  riskInfo?: string
  terminalEquip?: number
}

export interface IPayoutSubmitOrderRes {
  payoutRecordNo: string
  code: number
}

export interface IPayoutBoundElementsRes {
  elements: {
    paramName: string
    paramValue: string | number
  }[]
}

interface IPayoutPaymentListItemBase {
  amount: string
  currency: string
  displayAmount: string
  maskedInstrumentIdentity: string
  status: number
  statusName: string
}

export interface IPayoutTransListItem extends IPayoutPaymentListItemBase {
  payWayIconUrl: string
  paymentMethod: string
  paymentTime: string
  transactionId: string
}

export interface IPayoutHistoryListItem extends IPayoutPaymentListItemBase {
  errorMsg: string
  withdrawId: string
  withdrawMethod: string
  withdrawTime: string
}

export interface IPayoutInfo {
  amount: string
  canWithdraw: true
  currency: string
  displayAmount: string
  status: number
  statusName: string
}

export interface IPayoutStatusRes {
  status: PayoutResultStatusEnum
  name: string
  errorMsg: string
}

export interface ICardHolderInfoItem {
  email?: string
  firstName?: string
  holderName?: string
  lastName?: string
  mobilePhone?: string
}

export interface IQueryCardHolderInfoRes {
  cardHolderInfoList: ICardHolderInfoItem[]
  toCOrToB?: string
}

export interface IPayoutGetBindElementsProps {
  token: string
  payWay: number
  userType: 'b' | 'c'
}

export enum PayoutResultStatusEnum {
  Waiting = 1,
  Pending = 2,
  Success = 3,
  Failed = 4,
  Timeout = 5,
}
