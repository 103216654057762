import React from 'react'

import { Image } from '@arco-design/web-react'
import { IconLoading } from '@arco-design/web-react/icon'

import { PaymentDetail } from '@/pipo/utils'

import GenerateCommonCode from '../generate-code'

import './index.less'

const clsPrefix = 'pipo-pc-voucher'

interface BarCodeProps {
  shopperDetails: NonNullable<PaymentDetail>['present_to_shopper_details']
}

export const BarCode = (props: BarCodeProps) => {
  const { shopperDetails } = props

  const codePicture = shopperDetails?.payment_code ? (
    <GenerateCommonCode paymentCode={shopperDetails.payment_code} />
  ) : (
    <div className={`${clsPrefix}-code-picture`}>
      <Image
        className={`${clsPrefix}-code-picture-image`}
        preview={false}
        loader={
          <div className="arco-image-loader">
            <div className="arco-image-loader-spin">
              <IconLoading />
            </div>
          </div>
        }
        src={shopperDetails?.bar_code || ''}
      />
    </div>
  )

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {/* http://tosv.boei18n.byted.org/obj/pipo-sky-admin/sky/barcode-dark_beb445.png */}
      {codePicture}
    </div>
  )
}
