import React, { FC } from 'react'

import { Button } from '@arco-design/web-react'

import payoutSvg from '@/static/svg/payout-information.svg'
import starling from '@/utils/starling'

import './index.less'

interface IPayoutHeaderProps {
  refundAmount: string
  currency: string
  onClickWithdraw: () => void
}

const PayoutHeader: FC<IPayoutHeaderProps> = (props) => {
  const { refundAmount, onClickWithdraw, currency } = props
  return (
    <div className="payout-information-header">
      <div className="payout-information-header-left">
        <div className="payout-information-header-img">
          <img src={payoutSvg} alt="payout-information-header-img" />
        </div>

        <div className="payout-information-header-info">
          <div className="payout-information-header-refund-desc">{starling('funds.refund.comm.total_withdraw_amount')}</div>
          <span className="payout-information-header-refund-amount">{refundAmount}</span>
          <span className="payout-information-header-refund-unit">{currency}</span>
        </div>
      </div>
      <div className="payout-information-header-right">
        <Button className="payout-information-header-refund-btn" type="primary" onClick={() => onClickWithdraw()}>
          {starling('funds.refund.comm.withdraw')}
        </Button>
      </div>
    </div>
  )
}

export default PayoutHeader
