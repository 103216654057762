"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.TOSS_PAYMENT_PARAMS_MAP = exports.TOSS_PAYMENT_METHOD = void 0;
var TOSS_PAYMENT_PARAMS_MAP = {
  bank_code: {
    element: 'eg_pi_bt_banktransfertoss_c_d_local_kr_bank_code',
    is_encrypted: true
  },
  holder_name: {
    element: 'eg_pi_bt_banktransfertoss_c_d_local_kr_holder_name',
    is_encrypted: true
  }
};
exports.TOSS_PAYMENT_PARAMS_MAP = TOSS_PAYMENT_PARAMS_MAP;
var TOSS_PAYMENT_METHOD = {
  paymentMethodType: {
    configuration: {},
    icon: {
      iconUrl: ''
    },
    paymentMethodType: 'BANK_TRANSFER',
    starling_key: 'BANK_TRANSFER'
  },
  paymentMethod: {
    configuration: {},
    paymentMethod: 'TOSS',
    paymentMethodId: 'pm_pi_bt_banktransfertoss_c_d',
    starling_key: '',
    icon: {
      iconUrl: '',
      pngIconUrl: '',
      darkModePngIconUrl: '',
      grayIconUrl: '',
      detailIconUrl: ''
    },
    displayName: 'TOSS VA',
    title: {
      payment: '',
      piListShort: '',
      piListLong: ''
    }
  }
};
exports.TOSS_PAYMENT_METHOD = TOSS_PAYMENT_METHOD;