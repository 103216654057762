import React, { useCallback, useRef, useState } from 'react'
import { FieldError } from 'react-hook-form'

import { Empty, Select } from '@arco-design/web-react'
import { SelectHandle } from '@arco-design/web-react/es/Select/interface'
import { IconDown } from '@arco-design/web-react/icon'
import { omit } from 'lodash'

import type { LocationItem } from '@/pipo/utils'
import starling from '@/utils/starling'

import { ItemBlock } from '../index'

const Option = Select.Option

interface IProps {
  title: string
  placeholder?: string
  error?: FieldError
  errorMessage: string
  loading?: boolean
  disabled?: boolean
  options: LocationItem[]
  value?: string
  onChange?: (val: string | undefined) => void
  selectedAddressArr: (LocationItem | undefined)[]
  isFillByPostalCode?: boolean
}

export const City = React.forwardRef((props: IProps, ref: any) => {
  const { title, placeholder, error, errorMessage, loading, disabled, options, selectedAddressArr, isFillByPostalCode = false } = props
  const [searchInput, setSearchInput] = useState('')
  const citySelectRef = useRef<SelectHandle>(null)

  const handleInputValueChange = useCallback((value: string) => {
    setSearchInput(value)
  }, [])

  const handleSelectCountyClick = () => {
    setSearchInput(' County remainder')
    setTimeout(() => {
      const searchInput = citySelectRef.current?.dom.getElementsByTagName('input')[0]
      if (searchInput) {
        searchInput.focus()
        searchInput.setSelectionRange(0, 0)
      }
    }, 0)
  }

  const handleSearchOption = useCallback((inputValue: string, option: React.ReactElement<any, string | React.JSXElementConstructor<any>>) => {
    const usCountyRemainderKey = ' county remainder'
    const optionValue = option.props.value?.toString()?.toLowerCase() || ''
    if (inputValue.toLowerCase().includes(usCountyRemainderKey)) {
      return optionValue.includes(inputValue?.toLowerCase().split(usCountyRemainderKey)[0]) && optionValue.includes(usCountyRemainderKey)
    }
    return optionValue.includes(inputValue?.toLowerCase())
  }, [])

  return (
    <ItemBlock style="half" title={title} errorState={Boolean(error)} errorMessage={errorMessage}>
      {!isFillByPostalCode && (
        <Select
          {...omit(props, ['options'])}
          ref={citySelectRef}
          size="large"
          allowClear
          showSearch
          arrowIcon={<IconDown />}
          error={Boolean(error)}
          placeholder={placeholder}
          notFoundContent={<Empty description={starling('funds.refund.comm.city_no_result')} />}
          loading={loading}
          disabled={disabled}
          // fix: 点击清除时未触发form更新
          onClear={() => props.onChange?.(undefined)}
          inputValue={searchInput}
          onInputValueChange={handleInputValueChange}
          filterOption={handleSearchOption}
          dropdownRender={(menu) => (
            <div>
              {menu}
              {searchInput && !searchInput.toLowerCase().includes('county remainder') && selectedAddressArr[0]?.code === 'US' && (
                <div className="us-city-select-tip">
                  {starling('funds.refund.comm.city_select_tips')}{' '}
                  <span className="us-city-select-tip-trybtn" onClick={handleSelectCountyClick}>
                    {starling('funds.refund.comm.city_remainder_select_link')}
                  </span>
                </div>
              )}
            </div>
          )}
        >
          {options.filter(Boolean).map((item) => {
            return (
              <Option key={item.geoname_id?.toString()} value={item.name} extra={item}>
                <span>{item.name}</span>
              </Option>
            )
          })}
        </Select>
      )}
    </ItemBlock>
  )
})
