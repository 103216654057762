import { BankListSelect, Email, HolderName, ItemInput, ItemInputNumber } from '@/pipo/pc/inner-components'
import { validator } from '@/pipo/utils'
import starling from '@/utils/starling'

import { IBankAccountFormConfigItem, IGetBankAccountFormConfigProps } from '../utils'

export const getAUFormConfig = ({ cardHolderInfo }: IGetBankAccountFormConfigProps): IBankAccountFormConfigItem => {
  return {
    allowAccountType: ['b', 'c'],
    formSchema: [
      {
        field: 'holder_name',
        widget: HolderName,
        widgetProps: {
          title: starling('funds.refund.comm.beneficiary_name'),
          placeholder: starling('funds.refund.comm.system_name_description'),
          maxLength: 35,
          selectList: cardHolderInfo?.holderNameList,
        },
        rules: { required: true, pattern: validator.nameRegex },
      },
      {
        field: 'account_no',
        widget: ItemInputNumber,
        widgetProps: {
          title: starling('funds.refund.comm.system_bankaccount_account_number'),
          placeholder: starling('funds.refund.comm.system_bankaccount_account_number_placeholder'),
          maxLength: 9,
        },
        rules: { required: true, pattern: validator.auBankAccountNo },
        ruleMessages: { pattern: starling('cg.upay.exception.channel_id_num_error_message', { num: 9 }) },
      },
      {
        field: 'routing_no',
        widget: ItemInputNumber,
        widgetProps: {
          title: starling('fund.common.BSB_code'),
          placeholder: starling('fund.common.BSB_code'),
          maxLength: 6,
        },
        rules: { required: true, pattern: validator.auBsbCode },
        ruleMessages: { pattern: starling('cg.upay.exception.channel_id_num_error_message', { num: 6 }) },
      },
      {
        field: 'email',
        widget: Email,
        widgetProps: {
          title: starling('funds.refund.comm.system_email'),
          placeholder: starling('funds.refund.comm.system_email_placeholder'),
        },
        rules: { required: true, pattern: validator.emailRegex },
      },
    ],
  }
}
