import { Message as message } from '@arco-design/web-react'
import { RequestErrorObj } from '@upay/utils/es/types'
import { RequestOptionsInit, RequestResponse, extend } from 'umi-request'

import { PLATFORM_MAP, PREFIX, PUBSUB_TYPES, RESPONSE_CODE } from '@/constant'
import { IPubSub } from '@/typings'
import slardar, { logger } from '@/utils/slardar'

import { getLang } from './getLang'
import starling from './starling'

export interface IRespData<D = unknown> {
  code: number
  msg: string
  message?: string
  data?: D
  list?: D[]
  logid?: string
  [key: string]: unknown
}

interface IApiItem {
  url: string
  method?: 'POST' | 'GET' | 'DELETE' | 'PUT'
  /** 不校验code */
  useRaw?: boolean
  /** 外部接口校验response方法 */
  checkResponse?: (res: any) => { code?: number; msg?: string } | undefined | null
  /** 添加JWT */
  withJWT?: boolean
  /**
   * 添加通用前缀
   * @default true
   */
  withPrefix?: boolean
  /**
   * 展示错误
   * @default true
   */
  withError?: boolean
}

export interface ICustomRequestOptions extends Omit<IApiItem, 'url' | 'method'>, RequestOptionsInit {
  /** path上参数 */
  restParams?: Record<string, unknown>
  /** 额外方法 */
  extraHandle?: {
    uPubSub: IPubSub
  }
}

export const extendRequest = extend({})

// 获取错误信息
const getErrorMsgByStatusCode = (): string => starling('funds.checkout.comm.system_api_timeout')

// 校验数据code
const checkCode =
  (options: ICustomRequestOptions, url: string) =>
  ({ data, response }: RequestResponse<IRespData>): Promise<IRespData> => {
    if (options.checkResponse) {
      const checkRes = options.checkResponse(data)
      if (checkRes) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({
          url,
          msg: checkRes.msg ?? getErrorMsgByStatusCode(),
          code: checkRes.code ?? RESPONSE_CODE.EXTERNAL_ERROR,
          http_status: response.status,
          logid: data.logid,
          ERROR_TYPE: 'REQUEST_ERROR',
        } as RequestErrorObj)
      } else {
        return Promise.resolve(data)
      }
    }
    // 不需要校验错误code
    if (options.useRaw && data.code !== RESPONSE_CODE.NETWORK_ERROR) return Promise.resolve(data)
    if (data.code === RESPONSE_CODE.SUCCESS) {
      return Promise.resolve(data)
    }
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({
      url,
      msg: data.msg,
      code: data.code,
      http_status: response.status,
      logid: data.logid,
      ERROR_TYPE: 'REQUEST_ERROR',
    } as RequestErrorObj)
  }

// 处理错误
const handleError =
  (options: ICustomRequestOptions, url: string) =>
  (error: Error & RequestErrorObj): Promise<IRespData> => {
    const errMsg = error.message || error.msg || ''
    if (options.withError) message.error(errMsg)

    // TODO: 历史埋点，在slardar治理上线且监控配置完成后，可下线此埋点
    slardar.timer('fetchApiCountError', 1, {
      fetchUrl: url?.replace(/\?.*/, ''),
      message: errMsg,
      code: error.code,
    })

    error.msg = errMsg
    logger.printPrettier(
      { label: 'REQUEST_ERROR =>', level: 'error' },
      { url, status: error.http_status, logid: error.logid, errorObjMsg: (error as Error)?.message },
    )
    return Promise.reject(error)
  }

// 获取JWT
const getJWT = (uPubSub: IPubSub): Promise<string> =>
  new Promise((resolve) => {
    const timer = setTimeout(() => {
      resolve('')
    }, 10000)
    uPubSub.subscribe(PUBSUB_TYPES.SEND_PARAM, (msg: string, data: any) => {
      if (data.paramType === 'sessionToken') {
        clearTimeout(timer)
        resolve(data.sessionToken)
      }
    })
    uPubSub.publish(PUBSUB_TYPES.REQUEST_PARAM, {
      paramType: 'sessionToken',
    })
  })

const isPrimitive = (val: unknown): boolean => !['object', 'function'].includes(typeof val) || val === null

// 处理path上参数
extendRequest.interceptors.request.use((url: string, options: ICustomRequestOptions) => {
  const data = options?.restParams || {}
  const finalUrl = url.replace(/:(\w+)/g, (match, p1: string) => {
    const value = data[p1]
    if (!isPrimitive(value)) throw new Error(`${url}: param ${p1} should be a primitive value`)
    if (!value && value !== 0) throw new Error(`${url}: param ${p1} not specified in request param or data`)
    return value as string
  })
  const { headers } = (options || {}) as any
  if (headers?.['Content-Type'] === 'application/x-www-form-urlencoded') {
    options.requestType = 'form'
  }
  return { url: finalUrl, options }
})

// 处理JWT
extendRequest.use(async (ctx, next) => {
  const { req } = ctx
  const { options } = req
  if (options.withJWT) {
    const token = await getJWT(options.extraHandle?.uPubSub || {})
    if (token) {
      ctx.req.options.headers = { ...options.headers, Authorization: token }
    }
  }
  await next()
})

// 发送埋点
extendRequest.use(async (ctx, next) => {
  const prevTime = new Date().getTime()
  const url = (ctx.req?.url || '').replace(/\?.*/, '')

  try {
    await next()
    if (!ctx.res?.data || !ctx.res?.response) throw new Error('No data or no response')
  } catch (error: any) {
    // 请求状态非2XX报错
    const nowTime = new Date().getTime()
    const msg = getErrorMsgByStatusCode()
    let status, logid
    if (error.response) {
      status = ctx.res?.response?.status || error.response.status
      logid = ctx.res?.response?.headers?.get?.('x-tt-logid') || error.response.headers?.get?.('x-tt-logid')
    }
    logger.scenes.fetchApiCount({
      fetchUrl: url,
      time: nowTime - prevTime,
      status,
      msg,
      logid,
      code: RESPONSE_CODE.NETWORK_ERROR,
      errorObjMsg: (error as Error).message,
    })

    throw {
      url,
      msg,
      code: RESPONSE_CODE.NETWORK_ERROR,
      http_status: status,
      logid,
      ERROR_TYPE: 'REQUEST_ERROR',
      errorObjMsg: (error as Error).message,
    }
  }

  const status = ctx.res?.response?.status
  const logid = ctx.res?.response?.headers?.get?.('x-tt-logid')
  const code = ctx.res?.data?.code
  const msg = ctx.res?.data?.msg

  ctx.res.data.logid = logid

  const nowTime = new Date().getTime()
  logger.scenes.fetchApiCount({ fetchUrl: url, time: nowTime - prevTime, status, msg, logid, code, res: ctx.res.data })
})

export const request = (url: string, options: ICustomRequestOptions = {}): Promise<IRespData> =>
  extendRequest(url, {
    ...(options || {}),
    getResponse: true,
  })
    .then(checkCode(options, url))
    .catch(handleError(options, url))

// 处理options
const getRequestOptions = (
  api: IApiItem,
  params?: Record<string, unknown> | URLSearchParams,
  options?: ICustomRequestOptions,
): [string, ICustomRequestOptions] => {
  const { url, method = 'GET', useRaw = false, withJWT = false, withPrefix = true, withError = true, ...others } = api
  const _options = options || {}
  const _params: ICustomRequestOptions = Object.assign(
    {
      method: method.toUpperCase(),
      useRaw,
      withJWT: options?.bizId === PLATFORM_MAP.SHOPIFY ? withJWT : false,
      withPrefix,
      withError,
      credentials: 'same-origin',
      mode: 'cors',
      ...others,
    },
    _options,
  )
  if (_params.method === 'GET') {
    _params.params = params
  } else if (!_params.params) {
    _params.data = params
  }

  if (_params.withPrefix !== false) {
    _params.prefix = _params.prefix || PREFIX
  }

  const headers: Record<string, string> = {
    'Accept-Language': getLang(),
    'X-Requested-With': 'XMLHttpRequest',
  }

  if (_params.method !== 'GET') {
    headers['Content-Type'] = 'application/json'
  }

  _params.headers = Object.assign(headers, _params.headers)

  return [url, _params]
}

export function apiHandlerGenerator<P extends Record<string, any> | undefined, R>(
  api: IApiItem,
): (params?: P, options?: ICustomRequestOptions) => Promise<IRespData<R>> {
  return function (params, options = {}) {
    const [_url, _params] = getRequestOptions(api, params, options)
    return request(_url, _params) as Promise<IRespData<R>>
  }
}

export default apiHandlerGenerator
