import React, { forwardRef, useEffect, useImperativeHandle, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { formatUpdateInfoList } from '@upay/utils/es/payout-utils'

import { useTouchedErrors } from '@/pipo/hooks'
import { Email } from '@/pipo/pc/inner-components'
import { ItemBlockList } from '@/pipo/pc/inner-components/item-block-list'
import { PipoProvider, validator } from '@/pipo/utils'
import starling from '@/utils/starling'

import { PayoutComponentRef, PayoutSubmitParams } from '../../types'
import { getUpdateParamsMap } from './config'
import { LocalBankTransferFormData, LocalBankTransferUpdateFormProps } from './utils'

export const LocalBankTransferUpdate = forwardRef((props: LocalBankTransferUpdateFormProps, ref: React.ForwardedRef<PayoutComponentRef>) => {
  const { onChange, paramsList } = props
  const {
    control,
    getValues,
    formState: { errors: formErrors, isValid, touchedFields },
  } = useForm<LocalBankTransferFormData>({
    mode: 'all',
  })
  const errors = useTouchedErrors<LocalBankTransferFormData>({
    touchedFields,
    errors: formErrors,
  })

  const { getErrorText } = PipoProvider.useContext()
  const infoList = formatUpdateInfoList(paramsList, getUpdateParamsMap())

  // 在表单状态和勾选绑卡时触发onChange
  useEffect(() => {
    onChange?.({
      isValid,
      storeCard: true,
    })
  }, [isValid, onChange])

  const getSubmitData = (): PayoutSubmitParams => {
    return {
      formData: getValues(),
    }
  }

  useImperativeHandle(ref, () => ({
    getSubmitData,
  }))

  return (
    <div className="pipo-pc">
      <div className="pipo-pc-single-widget">
        <Controller
          rules={{ required: true, pattern: validator.emailRegex }}
          name="email"
          control={control}
          render={({ field }) => (
            <Email
              {...field}
              title={starling('funds.refund.comm.system_email')}
              placeholder={starling('funds.refund.comm.system_email_placeholder')}
              error={errors.email}
              errorMessage={getErrorText(errors.email?.type)}
            />
          )}
        />

        <ItemBlockList dataSource={infoList} />
      </div>
    </div>
  )
})
