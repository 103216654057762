import React from 'react'

import { ItemBlock } from '../index'

interface IProps {
  title: string
  value: string
  style: 'half' | 'full'
}

const prefix = 'pipo-mobile-input'

/**
 * 通用的文字录入组件
 */
export const ItemText = (props: IProps) => {
  const { title, value, style } = props

  return (
    <ItemBlock style={style} title={title}>
      <div>{value || ''}</div>
    </ItemBlock>
  )
}
