import { PAY_WAY_TYPE } from '@/constant'
import { CardListRes, ChannelRes } from '@/services/types'

const RiskInfoMap: Record<string, number> = {
  [PAY_WAY_TYPE.EXISTED_CARD]: PAY_WAY_TYPE.CREATE_NEW_CARD,
  [PAY_WAY_TYPE.PAYPAL_REF]: PAY_WAY_TYPE.PAYPAL_REF,
}

/** 预付已有卡转后付的时候，需要展示riskAmount */
export const connectRiskInfo = (card: CardListRes, payWays: ChannelRes[]) => {
  const mapPayWay = RiskInfoMap[String(card?.payWay)]
  const mapPayWayInfo = payWays?.find((item) => item.payWay === mapPayWay) || ({} as ChannelRes)
  const connectData = Object.assign(card, {
    riskAmount: mapPayWayInfo?.riskAmount,
    riskAmountCurrency: mapPayWayInfo?.riskAmountCurrency,
    displayRiskAmount: mapPayWayInfo?.displayRiskAmount,
  })
  return connectData
}
