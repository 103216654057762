import { PaymentMethodItem } from '../../types'

export const LINEPAY_PAYMENT_METHOD: PaymentMethodItem = {
  paymentMethodType: {
    configuration: {},
    icon: {
      iconUrl: '',
    },
    starling_key: 'pipo_common_ewallet',
    paymentMethodType: 'E_WALLET',
    title: {
      payment: 'pipo_common_ewallet',
    },
  },
  paymentMethod: {
    configuration: {
      png_icon_url: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/LINEPay_106568.png',
      dark_mode_png_icon_url: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/linepay-gray_1f9972.png',
    },
    paymentMethod: 'LINEPAY',
    paymentMethodId: 'pm_pi_ew_linepay_c_r',
    displayName: 'LINE Pay',
    starling_key: 'pipo_payin_payment_main_linepay',
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/LINEPay_106568.png',
      grayIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/linepay-gray_1f9972.png',
    },
  },
}
