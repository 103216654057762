import React from 'react'

import starling from '@/utils/starling'

interface IProps {
  clsPrefix: string
}
export const ReadInfo = (props: IProps) => {
  const { clsPrefix } = props

  return (
    <React.Fragment>
      <div className={`${clsPrefix}-signature-line`} />
      <div className={`${clsPrefix}-signature-info`}>
        <span className={`${clsPrefix}-signature-info-title`}>{starling('funds.check_out.comm.type_of_service')}</span>
        {starling('funds.check_out.comm.service_content') || (
          <React.Fragment>
            <p>
              <span>Accuracy and Changes in Account Information:</span>
              <span>
                By signing this authorization, I/We certify that all information is accurate and I/ We certify that all information contained in this
                form is accurate and I/We agree to advise the payee in writing of any change in the information provided 30 days prior to the next due
                date of the PAD.{' '}
              </span>
            </p>
            <p>
              <span>Authority to Debit Account:</span>
              <span>I/We hereby authorize the Payee to debit our account at our Financial Institution as indicated above.</span>
            </p>
            <p>
              <span>Recourse/Reimbursement:</span>
              <span>
                I/We have certain recourse rights if any debit does not comply with this agreement. For example, I/We have the right to receive
                reimbursement for any debit that is not authorized or is not consistent with this PAD agreement. To obtain more information on my/our
                recourse rights, I/We may contact my/our financial institution or visit www.cdnpay.ca.Dispute: I/We may dispute a Pre-authorized Debit
                as per CPA rules under the following conditions:(a) The PAD was not drawn in accordance with the Payor’s PAD agreement.(b) The Payor’s
                PAD agreement was revoked.In order to be reimbursed, I/We will advise in writing to our Financial Institution that either (a) or (b)
                has taken place and the claim is not more than 90 calendar days for a Personal PAD or 10 Business Days for a Business PAD following
                the date on which a PAD was posted to my/our account. We acknowledge that any claim after 90 calendar days for Personal PAD and 10
                business days for a Business PAD or for any reason other than the above will be resolved between the Payee and Payor.
              </span>
            </p>
            <p>
              <span>Cancellation: </span>
              <span>
                This authorization may be cancelled at any time upon notice by us to the payee at least 30 days prior to the next PAD due date. To
                obtain more information on my/our right to cancel a PAD agreement, I/We may contact our Financial Institution or visit www.cdnpay.ca.{' '}
              </span>
            </p>
            <p>
              <span>Pre-Notification Waiver: </span>
              <span>
                We agree with the Payee to waive the pre-notification requirement under CPA Rules to receive written pre-notification before a PAD is
                issued.
              </span>
            </p>
            <p>
              <span>Sporadic PADs: </span>
              <span>
                If this authorization provides for PADs with sporadic frequency, Payor understands that it is required to provide an authorization to
                Payee for each and every PAD prior to the PAD being exchanged and cleared. Payor agrees that a password or security code or other
                signature equivalent will be issued and will constitute valid authorization for the processing member (“Processing Member” as defined
                by the CPA) to debit the Account.
              </span>
            </p>
            <p>
              <span>Validation By Processing Institution: </span>
              <span>
                I/We acknowledge that our Financial Institution is not responsible for validating the terms of this PAD agreement in respect of a PAD
                issued under the agreement.
              </span>
            </p>
            <p>
              <span>Contract for Goods and Services: </span>
              <span>
                Revocation of this authorization does not terminate any contract for goods or services that exists between Payor and Payee. This PAD
                Agreement applies only to the method of payment and does not otherwise have any bearing on the contract for goods or services
                exchanged.
              </span>
            </p>
            <p>
              <span>Disclosure: </span>
              <span>
                Payor consents to the disclosure of any personal information that may be contained in this authorization to [name of Payee’s financial
                institution] to the extent that such disclosure of personal information is directly related to and necessary for the proper
                application of Rule H1 of the Rules of the CPA.
              </span>
            </p>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  )
}
