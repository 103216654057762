import React from 'react'

import { Collapse } from '@arco-design/web-react'

import { IPayinMethodItem } from '@/pages/PayNext/interface'

import { getItemKey } from '../../utils'
import PaymentItemContent from '../PaymentItemContent'
import PaymentItemHeader from '../PaymentItemHeader'

import './index.less'

interface IProps {
  dataSource: IPayinMethodItem[] | undefined
  selectedItem: IPayinMethodItem | undefined
  setSelectedItem: (val: IPayinMethodItem) => void
}
const PaymentList: React.FC<IProps> = (props) => {
  const { dataSource, selectedItem, setSelectedItem } = props
  const activeKey = getItemKey(selectedItem)

  return (
    <div className="upay-payout-channel">
      <Collapse accordion activeKey={activeKey} bordered={false} lazyload={false}>
        {dataSource?.map((item) => {
          const itemKey = getItemKey(item)

          return (
            <Collapse.Item
              showExpandIcon={false}
              key={itemKey}
              name={itemKey}
              disabled={item.disable}
              header={<PaymentItemHeader isSelected={itemKey === activeKey} paymentItem={item} onSelect={setSelectedItem} />}
            >
              <PaymentItemContent paymentItem={item} />
            </Collapse.Item>
          )
        })}
      </Collapse>
    </div>
  )
}

export default PaymentList
