import { PaymentMethodItem } from '../../types'

export const RAZERGOLD_PAYMENT_METHOD: PaymentMethodItem = {
  paymentMethodType: {
    configuration: {},
    icon: {
      iconUrl: '',
    },
    starling_key: 'pipo_common_ewallet',
    paymentMethodType: 'E_WALLET',
    title: {
      payment: 'pipo_common_ewallet',
    },
  },
  paymentMethod: {
    configuration: {
      png_icon_url: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/razerGold_32ceb1.png',
    },
    paymentMethod: 'RAZER_GOLD',
    paymentMethodId: 'pm_pi_ew_razergold_c_r',
    displayName: 'Razer gold',
    starling_key: 'pipo_payin_payment_main_razer_gold',
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/razerGold_32ceb1.png',
      pngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/razerGold_32ceb1.png',
      grayIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/razer-gold-gray_c58b93.png',
    },
  },
}
