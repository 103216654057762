"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.SUPPORT_BANK_MAP = exports.BANKTRANSFER_PAYMENT_METHOD = void 0;
var BANKTRANSFER_PAYMENT_METHOD = {
  paymentMethodType: {
    configuration: {},
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/banktransfer_f0e4e1.png',
      pngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/banktransfer_light_3_6f9671.png',
      darkModePngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/banktransfer_dark_3_6f9671.png'
    },
    starling_key: 'pipo_payin_payment_main_banktransfer',
    paymentMethodType: 'BANK_TRANSFER'
  },
  paymentMethod: {
    configuration: {
      png_icon_url: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/banktransfer_light_3_6f9671.png',
      dark_mode_png_icon_url: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/banktransfer_dark_3_6f9671.png'
    },
    paymentMethod: 'BANK_TRANSFER',
    paymentMethodId: 'pm_pi_bt_banktransfer_c_d',
    displayName: '',
    starling_key: 'pipo_payin_payment_main_banktransfer',
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/banktransfer_f0e4e1.png',
      pngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/banktransfer_light_3_6f9671.png',
      darkModePngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/banktransfer_dark_3_6f9671.png'
    }
  }
};
exports.BANKTRANSFER_PAYMENT_METHOD = BANKTRANSFER_PAYMENT_METHOD;
var SUPPORT_BANK_MAP = {
  ID001: {
    i18nKey: 'pipo_payin_payment_banktransfer_ID001',
    bankCode: 'ID001',
    name: 'Bank Rakyat Indonesia',
    description: 'this is description',
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/bank_bri_v1_d43e72.png',
      pngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/BRI_light_3_f8d97b.png',
      darkModePngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/BRI_dark_3_b75ec7.png',
      voucherIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/bri-code_d5cd29.png',
      grayIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/bank-bri-gray_da1f79.png'
    }
  },
  ID003: {
    i18nKey: 'pipo_payin_payment_banktransfer_ID003',
    bankCode: 'ID003',
    name: 'Bank Mandiri',
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/bank_mandiri_v1_45f845.png',
      pngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/Mandiri_light_3_77d3db.png',
      darkModePngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/Mandiri_dark_3_e991a9.png',
      voucherIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/mandiri-code_2c5f79.png',
      grayIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/bank-maybank-gray_056c14.png'
    }
  },
  ID007: {
    i18nKey: 'pipo_payin_payment_banktransfer_ID007',
    bankCode: 'ID007',
    name: 'Bank Negara Indonesia',
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/bank_bni_v1_e17d6f.png',
      pngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/BNI_light_3_142190.png',
      darkModePngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/BNI_dark_3_351934.png',
      voucherIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/bni-code_654c68.png',
      grayIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/bank-bni-gray_434684.png'
    }
  },
  ID009: {
    i18nKey: 'pipo_payin_payment_banktransfer_ID009',
    bankCode: 'ID009',
    name: 'Bank CIMB Niaga',
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/bank_cimb_v1_ea2e8b.png',
      pngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/bank_cimb_v1_ea2e8b.png',
      voucherIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/cimb-code_fc6b0d.png',
      grayIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/bank-cimb-gray_2c3ee9.png'
    }
  },
  ID011: {
    i18nKey: 'pipo_payin_payment_banktransfer_ID011',
    bankCode: 'ID011',
    name: 'Bank Danamon',
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/bank_danamon_v1_305198.png',
      pngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/Danamon_light_3_6d94e8.png',
      darkModePngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/Danamon_dark_3_5c33d4.png',
      voucherIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/danamon-code_1f7ed9.png',
      grayIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/bank-danamon-gray_b44899.png'
    }
  },
  ID013: {
    i18nKey: 'pipo_payin_payment_banktransfer_ID013',
    bankCode: 'ID013',
    name: 'Bank Maybank (formerly BII)',
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/bank_maybank_d53752.png',
      pngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/maybank_light_3_d9a1fe.png',
      darkModePngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/Maybank_dark_3_8eca01.png',
      voucherIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/maybank-code_459eb2.png',
      grayIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/bank-maybank-gray_056c14.png'
    }
  },
  ID015: {
    i18nKey: 'pipo_payin_payment_banktransfer_ID015',
    bankCode: 'ID015',
    name: 'Bank Permata',
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/bank_permata_v1_05c0c2.png',
      pngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/permata_light_3_38cb42.png',
      darkModePngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/Permata_dark_3_ec0947.png',
      voucherIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/permata-code_a62baf.png',
      grayIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/bank-permata-gray_55eff7.png'
    }
  },
  ID139: {
    i18nKey: 'pipo_payin_payment_banktransfer_ID139',
    bankCode: 'ID139',
    name: 'Artajasa Electronic Payment',
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/bank_artajasa_v1_cd78d4.png',
      pngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/artajasa_dark_3_a66cba.png',
      darkModePngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/artajasa_dark_3_a66cba.png',
      voucherIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/artajasa-code_fe9cca.png',
      grayIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/bank-artajasa-gray_e53ab5.png'
    }
  },
  ID155: {
    i18nKey: 'pipo_payin_payment_banktransfer_ID155',
    bankCode: 'ID155',
    name: 'Bank Central Asia',
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/bank-bca_8d554a.png',
      pngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/bank-bca_8d554a.png',
      voucherIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/bca-code_7ac8f4.png',
      grayIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/bank-bca-gray_9ddd6b.png'
    }
  }
};
exports.SUPPORT_BANK_MAP = SUPPORT_BANK_MAP;