/**
 * 背景：在使用 react-hook-form 开发表单组件时，由于 useForm 的 mode 参数不能满足产品所需的校验策略，所以抽象此 hook 来自定义 errors 的展示
 *
 * 功能描述：根据 touchedFields 筛选出已经 touch 过的字段的 error 信息，实现表单报错提示在失焦后展示，表单提交按钮的高亮状态根据校验状态实时更新的效果
 */
import { FieldErrors, FieldValues, FieldNamesMarkedBoolean } from 'react-hook-form'
import { keys, pick } from 'lodash'

export function useTouchedErrors<TFieldValues extends FieldValues = FieldValues>(props: {
  touchedFields: FieldNamesMarkedBoolean<TFieldValues>
  errors: FieldErrors<TFieldValues>
}): Partial<FieldErrors<TFieldValues>> {
  const { touchedFields, errors } = props
  const touchedFieldKeys: string[] = keys(touchedFields)
  const newErrors = pick(errors, touchedFieldKeys)
  return newErrors as Partial<FieldErrors<TFieldValues>>
}
