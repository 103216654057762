import { useCallback } from 'react'

import { useCountdownTimer } from '@/pipo/hooks'
import { PipoProvider } from '@/pipo/utils'
import { IGetResultRes } from '@/services/types'
import starling from '@/utils/starling'

interface IProps {
  orderStatus: string
  expirationTime?: string
  onTimeUp?: () => void
  upayResult: IGetResultRes
}

const useTradingStatus = (props: IProps) => {
  const { orderStatus, expirationTime = '', onTimeUp, upayResult } = props
  const { t } = PipoProvider.useContext()
  const times = useCountdownTimer({
    expirationTime,
    onTimeUp,
  })
  const { hours = '', minutes = '', seconds = '' } = times
  const getPaymentStatus: () => {
    title: string
    icon: 'time' | 'warning' | 'success' | 'failed'
    subTitle: string
  } = useCallback(() => {
    switch (orderStatus) {
      case 'expired':
        return {
          icon: 'warning',
          title: starling('funds.refund.comm.expired'),
          subTitle: '',
        }
      case 'failed':
      case 'closed':
        return {
          icon: 'failed',
          title: starling('funds.refund.comm.payment_failed'),
          subTitle: upayResult.errorMsg || '',
        }
      case 'success':
        return {
          icon: 'success',
          title: starling('funds.refund.comm.payment_successful'),
          subTitle: '',
        }
      case 'init':
      case 'processing': // 一步支付场景
      default:
        return {
          icon: 'time',
          title: starling('funds.refund.comm.system_waiting'),
          subTitle: starling('funds.refund.comm.remainingtime'),
        }
    }
  }, [orderStatus, t])

  const { title, subTitle, icon } = getPaymentStatus()
  return {
    hours,
    minutes,
    seconds,
    title,
    subTitle,
    icon,
  }
}

export default useTradingStatus
