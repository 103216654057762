import React, { FC, useEffect, useRef, useState } from 'react'

import Layout from '@/layouts'

import { Loading } from './components'
import ErrorBoundary from './components/ErrorBoundary'
import { IInitSDK, initSDK } from './init'
import ManagerSkeleton from './pages/Manager/components/ManagerSkeleton'
import { ParamsContext } from './stores'
import { GlobalInfoProvider } from './stores/GlobalInfo'
import { IOptions, ModeTypeEnum } from './typings'
import { getPid } from './utils/init/initRoute'

interface IUpaySDK {
  options: IOptions
  unique: string
  initStartTime: number
}

const UpaySDK: FC<IUpaySDK> = ({ options, unique, initStartTime }) => {
  const pid = getPid(options.url)
  const [loading, setLoading] = useState(true)
  const sdkData = useRef<IInitSDK>()

  useEffect(() => {
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const init = async () => {
    sdkData.current = await initSDK(options, unique, initStartTime)
    setLoading(false)
  }

  /**
   * SDK初始化时Loading效果
   */
  const getLoadingContent = () => {
    if (pid === 'manager') {
      return <>{loading && <ManagerSkeleton mode={options.params?.mode || ModeTypeEnum.Modal} />}</>
    }
    if (options.params?.defaultVisible) {
      return <Loading show={loading} showBtn={false} loadingText="" fadeIn={true} />
    }
    return null
  }

  return (
    <React.StrictMode>
      <ErrorBoundary>
        {getLoadingContent()}
        {!loading && sdkData.current && (
          <Layout mode={sdkData.current.paramsData?.params?.mode || ModeTypeEnum.Modal}>
            <ParamsContext.Provider value={sdkData.current.paramsData}>
              <GlobalInfoProvider>{sdkData.current.RenderContent}</GlobalInfoProvider>
            </ParamsContext.Provider>
          </Layout>
        )}
      </ErrorBoundary>
    </React.StrictMode>
  )
}

export default UpaySDK
