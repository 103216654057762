import React from 'react'

import { VoucherBoardData } from '@/pipo/hooks/use-ui-voucher'
import starling from '@/utils/starling'

import { AmountCard } from '../amount-card'
import { InfoCard } from '../info-card'

interface IProps {
  data: VoucherBoardData
}

export const CashPlusCode = (props: IProps) => {
  const { data } = props
  const { simple, shopperDetails } = data

  return (
    <React.Fragment>
      <AmountCard iconUrl={shopperDetails.icon_url} title={starling('cg.upay.exception.channel_title_cashplus')} amount={simple.amount} />

      <div className="payment-code-info-card-oxxo">
        <InfoCard
          infoList={[
            {
              title: starling('funds.check_out.comm.page_tittle_payment_code'),
              content: simple.formatCode,
              needCopy: true,
              notice: (
                <div className="payment-code-info-card-notice-list">
                  {starling('funds.check_out.cashplus_paytip_header')}
                  <div className="payment-code-info-card-notice-item">{starling('funds.check_out.cashplus_paytip_store')}</div>
                </div>
              ),
            },
          ]}
        />
      </div>
    </React.Fragment>
  )
}
