/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react'

import { ItemBlockLabel } from '../index'

interface IProps {
  style: 'half' | 'full'
  title: React.ReactNode
  tips?: React.ReactNode
  children: React.ReactNode
  errorState?: boolean
  errorMessage?: string
  footer?: string
  className?: string
  height?: string | number
}

export const ItemBlock = (props: IProps) => {
  const { style, title, tips, children, errorMessage, footer, className = '', height = 36 } = props

  let width: string
  if (style === 'half') width = 'calc((100% - 32px) / 2)'
  else width = '100%'

  return (
    <div className="pipo-form-item-container" style={{ width: `${width}` }}>
      <div className={`pipo-form-item-title ${className}`}>
        <ItemBlockLabel title={title} tips={tips} />
      </div>
      {/* TIP: 有 prefix 的 <Input /> 组件有 `height: 100%;` 的样式, 所以固定容器的高度 */}
      <div style={{ height }}>{children}</div>
      <div className="pipo-form-item-error">{errorMessage}</div>
      {footer && <div className="pipo-form-item-footer">{footer}</div>}
    </div>
  )
}
