import React, { ReactElement, useCallback } from 'react'

import { Skeleton, Spin } from '@arco-design/web-react'
import TabPane from '@arco-design/web-react/es/Tabs/tab-pane'
import { Plus } from '@icon-park/react'

import { Channel } from '@/components'
import CardItemTitle from '@/components/CardItemTitle'
import { ChannelItem } from '@/components/Channel'
import ChannelItemTitle from '@/components/ChannelItemTitle'
import SimpleTabs from '@/components/SimpleTabs'
import starling from '@/utils/starling'
import teaClient from '@/utils/tea'

import { payLogger } from '../..'
import { IRenderPayinMethodProps } from '../../interface'
import { PayNextModel } from '../../model/usePayNext'
import PayinMethodItem from '../PayinMethodItem'

import './style.less'

const PayinMethodList = () => {
  const {
    data: { errorMsg, piListObj, paymentsMethodPropsBasic, selectedPayinMethod, currentPIListKey },
    loadStatus: { piListReady, piListLoading, cardDetailLoadingId },
    func: { handleSelectPayinMethod, handleChangePIList, renderPayinMethodExtra, getSelectedPayinMethod, handleClickUpdate },
  } = PayNextModel.useContext()

  const activeChannelKey = String(selectedPayinMethod?.cardId || selectedPayinMethod?.payWay)

  // 渲染支付方式
  const renderPayinMethod = useCallback(
    ({ piList, disable, paymentsMethodPropsBasic, cardDetailLoadingId }: IRenderPayinMethodProps) => {
      const selectedPayinMethod = getSelectedPayinMethod()
      return piList.map((methodItem) => {
        const payinMethodProps = { methodItem, paymentsMethodPropsBasic, renderPayinMethodExtra }
        if (methodItem.cardId) {
          // 已有卡
          const isChecked = selectedPayinMethod?.cardId === methodItem.cardId
          const cardDisable = disable || methodItem.disable
          const c: ChannelItem = {
            key: methodItem.cardId,
            name: methodItem.identityType || '',
            content: !cardDisable && <PayinMethodItem {...payinMethodProps} />,
            disable: cardDisable, // 卡为失效态或者当前全局存在错误，则置灰
            data: methodItem,
            disableTips: (cardDisable && methodItem.disableTips) || '',
            renderTitle: () => (
              <CardItemTitle
                card={isChecked ? selectedPayinMethod : methodItem}
                disabled={cardDisable}
                loading={Boolean(cardDetailLoadingId) && cardDetailLoadingId === methodItem.cardId}
                onUpdate={handleClickUpdate}
              />
            ),
          }
          return c
        } else {
          const payWayDisable = disable || methodItem.disable
          const isChecked = !!selectedPayinMethod && !selectedPayinMethod.cardId && methodItem.payWay === selectedPayinMethod?.payWay
          const c: ChannelItem = {
            key: methodItem.payWay,
            name: methodItem.paymentsTitle,
            content: !payWayDisable && <PayinMethodItem {...payinMethodProps} />,
            data: methodItem,
            disable: payWayDisable,
            disableTips: (payWayDisable && methodItem.disableTips) || '',
            renderTitle: () => <ChannelItemTitle selected={isChecked} disabled={payWayDisable} channel={methodItem} />,
          }
          return c
        }
      })
    },
    [getSelectedPayinMethod, renderPayinMethodExtra, handleClickUpdate],
  )

  return (
    <div className="upay-payNext-list">
      <Skeleton loading={!piListReady} animation text={{ width: ['260px', '100%', '100%', '60%'], rows: 4 }}>
        <Spin loading={piListLoading} className="upay-payNext-list-loading">
          <SimpleTabs
            activeTab={currentPIListKey}
            animation={{ inkBar: false, tabPane: true }}
            headerPadding={false}
            renderTabHeader={() => null as unknown as ReactElement}
            lazyload={false}
          >
            <TabPane key="unfoldedPIList" title="unfoldedPIList">
              <Channel
                channels={renderPayinMethod({
                  piList: piListObj.unfoldedPIList,
                  disable: !!errorMsg,
                  paymentsMethodPropsBasic,
                  cardDetailLoadingId,
                })}
                activeChannel={activeChannelKey}
                onChange={(k, data) => {
                  handleSelectPayinMethod(data)
                }}
              />
              {piListObj.foldedPIList.length > 0 && (
                <div
                  className="upay-use-new-btn"
                  onClick={() => {
                    teaClient.sendPayPageClick({ click_item: 'pay_new' })
                    payLogger.scenes.payPageClick({ clickItem: 'pay_new' })

                    handleChangePIList('foldedPIList')
                  }}
                >
                  <Plus size={16} />
                  <span>{starling('funds.check_out.comm.pay_with_new_pi')}</span>
                  {piListObj.foldedPIList.map((method) => (
                    <img src={method.imageUrl} key={method.payWay} className="upay-use-new-method-icon" />
                  ))}
                </div>
              )}
            </TabPane>
            <TabPane key="foldedPIList" title="foldedPIList">
              <Channel
                channels={renderPayinMethod({
                  piList: piListObj.foldedPIList,
                  disable: !!errorMsg,
                  paymentsMethodPropsBasic,
                })}
                activeChannel={activeChannelKey}
                onChange={(k, data) => {
                  handleSelectPayinMethod(data)
                }}
              />
            </TabPane>
          </SimpleTabs>
        </Spin>
      </Skeleton>
    </div>
  )
}

export default PayinMethodList
