import { Email, HolderName, ItemInput } from '@/pipo/pc/inner-components'
import { validator } from '@/pipo/utils'
import starling from '@/utils/starling'

import { IBankAccountFormConfigItem, IGetBankAccountFormConfigProps } from '../utils'

export const getTRFormConfig = ({ cardHolderInfo }: IGetBankAccountFormConfigProps): IBankAccountFormConfigItem => {
  return {
    allowAccountType: ['c'],
    formSchema: [
      {
        field: 'holder_name',
        widget: HolderName,
        widgetProps: {
          title: starling('funds.refund.comm.system_name'),
          placeholder: starling('funds.refund.comm.system_name_description'),
          maxLength: 35,
          selectList: cardHolderInfo?.holderNameList,
        },
        rules: { required: true, pattern: validator.nameRegex },
      },
      {
        field: 'iban',
        widget: ItemInput,
        widgetProps: {
          title: starling('cg.upay.exception.channel_youriban'),
          placeholder: 'TR000000000000000000000000',
          maxLength: 26,
        },
        rules: { required: true, pattern: validator.trIban },
        ruleMessages: { pattern: starling('cg.upay.exception.channel_IBAN_errormsg') },
      },
      {
        field: 'email',
        widget: Email,
        widgetProps: {
          title: starling('funds.refund.comm.system_email'),
          placeholder: starling('funds.refund.comm.system_email_placeholder'),
        },
        rules: { required: true, pattern: validator.emailRegex },
      },
    ],
  }
}
