import { ICustomRequestOptions, extendRequest } from './request'

// tcm TTP环境不太规范，需要对request函数做改造
// 初始化 request 函数
export function requestAdaptorTTP(originUrl: string): boolean {
  const adaptorUrls = ['creatormarketplace.us.tiktok.com']
  const domain = /(http.?:\/\/)?(.*)\/wpay\/oversea\/(iframe|web|card)\/([a-zA-Z]+)/.exec(originUrl)?.[2]
  if (!domain || !adaptorUrls.includes(domain)) return false
  // apiHandlerGenerator.requestAdaptorTTP = true
  extendRequest.interceptors.request.use((url: string, options: ICustomRequestOptions) => {
    // 判断当前路径是否携带domain
    const hasDomain = !url.startsWith('/')
    if (hasDomain) {
      return { url, options }
    }

    return { url: `https://${domain}${url}`, options: { ...options, credentials: 'include' } }
  })
  return true
}
