import { IBankListItem } from '@/services/types'
import { IPaymentParamsMapItem, PaymentMethodItem } from '../../types'

export interface TossFormProps {
  bankList?: IBankListItem[]
  onValidate?: (isValid: boolean) => void
}

export type TossFormData = {
  bank_code?: string
  holder_name?: string
}

export const TOSS_PAYMENT_PARAMS_MAP: Record<string, IPaymentParamsMapItem> = {
  bank_code: {
    element: 'eg_pi_bt_banktransfertoss_c_d_local_kr_bank_code',
    is_encrypted: true,
  },
  holder_name: {
    element: 'eg_pi_bt_banktransfertoss_c_d_local_kr_holder_name',
    is_encrypted: true,
  },
}

export const TOSS_PAYMENT_METHOD: PaymentMethodItem = {
  paymentMethodType: {
    configuration: {},
    icon: {
      iconUrl: '',
    },
    paymentMethodType: 'BANK_TRANSFER',
    starling_key: 'BANK_TRANSFER',
  },
  paymentMethod: {
    configuration: {},
    paymentMethod: 'TOSS',
    paymentMethodId: 'pm_pi_bt_banktransfertoss_c_d',
    starling_key: '',
    icon: {
      iconUrl: '',
      pngIconUrl: '',
      darkModePngIconUrl: '',
      grayIconUrl: '',
      detailIconUrl: '',
    },
    displayName: 'TOSS VA',
    title: {
      payment: '',
      piListShort: '',
      piListLong: '',
    },
  },
}
