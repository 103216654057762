import { IPayinMethodItem } from '@/pages/PayNext/interface'
import apiHandlerGenerator from '@/utils/request'

import {
  IBindListReq,
  IPayoutBindCardReq,
  IPayoutBindCardRes,
  IPayoutBoundElementsRes,
  IPayoutGetBindElementsProps,
  IPayoutHistoryListItem,
  IPayoutInfo,
  IPayoutStatusRes,
  IPayoutSubmitOrderReq,
  IPayoutSubmitOrderRes,
  IPayoutTransListItem,
  IQueryCardHolderInfoRes,
} from './payout-types'
import { CardListRes, IBankList, IPIPaymentListRes } from './types'

const apiPayout = {
  /** payout 支付方式列表 */
  getPayoutPaymentList: apiHandlerGenerator<{ token: string; riskInfo: string; terminalEquip: number }, IPIPaymentListRes>({
    url: `/payout/payment/list`,
    method: 'POST',
    withJWT: true,
  }),
  /**  查询退款单详情 */
  postPayoutInfo: apiHandlerGenerator<{ token: string }, IPayoutInfo>({
    url: '/payout/query_out_trans_info',
    method: 'POST',
  }),
  /**  查询payin交易记录 */
  getPayoutTrans: apiHandlerGenerator<{ token: string }, IPayoutTransListItem>({
    url: `/payout/query_in_trans_list`,
    method: 'POST',
  }),
  /**  查询payout交易记录 */
  getPayoutHistory: apiHandlerGenerator<{ token: string }, IPayoutHistoryListItem>({
    url: '/payout/query_out_trans_list',
    method: 'POST',
  }),
  /**  payout 银行列表 */
  getPayoutBindElements: apiHandlerGenerator<IPayoutGetBindElementsProps, IBankList>({
    url: '/payout/get_bind_elements',
    method: 'GET',
  }),
  /** payout 提交绑卡*/
  payoutBindCard: apiHandlerGenerator<IPayoutBindCardReq, IPayoutBindCardRes>({
    url: `/payout/bind_pi`,
    method: 'POST',
  }),
  /** payout 更新绑卡信息*/
  payoutUpdateCard: apiHandlerGenerator<IPayoutBindCardReq & { cardId: string }, IPayoutBindCardRes>({
    url: `/payout/update_pi`,
    method: 'POST',
  }),
  /** payout 查询已绑卡信息*/
  payoutBoundPiElements: apiHandlerGenerator<IPayoutBindCardReq & { cardId: string }, IPayoutBoundElementsRes>({
    url: `/payout/get_bound_pi_elements`,
    method: 'POST',
  }),
  /** payout 提交订单 */
  payoutSubmitOrder: apiHandlerGenerator<IPayoutSubmitOrderReq, IPayoutSubmitOrderRes>({
    url: `/payout/submit`,
    method: 'POST',
    withJWT: true,
  }),
  /**  payout 交易结果 */
  getPayoutStatus: apiHandlerGenerator<{ token: string }, IPayoutStatusRes>({
    url: `/payout/query_out_trans_status`,
    method: 'GET',
  }),
  /**  payout 持卡人信息 */
  getPayoutCardHolderInfo: apiHandlerGenerator<{ token: string }, IQueryCardHolderInfoRes>({
    url: `/payout/query_card_holder_info`,
    method: 'GET',
  }),
  /**  payout 已绑卡列表 */
  getPayoutCardList: apiHandlerGenerator<{ token: string }, CardListRes>({
    url: `/payout/card/list`,
    method: 'GET',
  }),
  /**  payout 绑卡渠道列表 */
  getPayoutBindList: apiHandlerGenerator<IBindListReq, IPayinMethodItem>({
    url: `/payout/bind/list`,
    method: 'POST',
  }),
  /**  payout 设置首选卡 */
  setPayoutPrimaryCard: apiHandlerGenerator<{ token: string; id: string }, CardListRes>({
    url: `/payout/card/primary`,
    method: 'POST',
  }),
  /**  payout 删除卡 */
  removePayoutCard: apiHandlerGenerator<{ token: string; id: string }, CardListRes>({
    url: `/payout/card/remove`,
    method: 'POST',
  }),
}

export default apiPayout
