import React, { useEffect, useMemo, useState } from 'react'

import { VoucherBoardData } from '@/pipo/hooks/use-ui-voucher'
import { GET_SUPPORT_BANK_MAP, PipoProvider, SUPPORT_PAYMENT_METHODS_ID_MAP, banktransferBankIcon, setDisplayBankIcon } from '@/pipo/utils'
import starling from '@/utils/starling'
import { formatter } from '@/utils/starling'

import { IInfoCardItem, InfoCard } from '../info-card'

import './index.less'

const classPrefix = 'upay-voucher-bo-code'

interface BoBankTransferCodeProps {
  data: VoucherBoardData
  userIban: string
}

export const BoBankTransferCode = (props: BoBankTransferCodeProps) => {
  const { data, userIban } = props
  const {
    type,
    paymentMethodInfo: { paymentMethodId, bankId },
    shopperDetails,
    simple,
    hint,
  } = data

  const [bankLogo, setBankLogo] = useState('')

  useEffect(() => {
    // @ts-ignore
    setBankLogo(setDisplayBankIcon(shopperDetails?.iban, banktransferBankIcon))
  }, [shopperDetails])

  const infoList: IInfoCardItem[] = [
    {
      title: starling('cg.upay.exception.channel_bankiban'),
      content: shopperDetails.iban,
      contentLogo: bankLogo,
      needCopy: true,
    },
    {
      title: starling('cg.upay.exception.channel_referencecode'),
      content: shopperDetails.payment_code,
      needCopy: true,
      notice: (
        <div className="code-notice">
          <p className="code-notice-title">{starling('cg.upay.exception.comm.fault_prompt_banner_title')}</p>
          {formatter(starling('cg.upay.exception.channel_tr_banktransfer_notice'))}
        </div>
      ),
    },
  ]

  return (
    <div className={classPrefix}>
      <div className={`${classPrefix}-dashboard`}>
        <div className={`${classPrefix}-dashboard-left`}>
          <div className={`${classPrefix}-dashboard-user-iban-title`}>{starling('cg.upay.exception.channel_youriban')}</div>
          <div className={`${classPrefix}-dashboard-user-iban`}>
            {bankLogo && <img className={`${classPrefix}-dashboard-logo`} src={bankLogo} />}
            <div className={`${classPrefix}-dashboard-user-iban-number`}>{userIban}</div>
          </div>
        </div>
        {type === 'simple' && (
          <div className={`${classPrefix}-dashboard-right`}>
            <div className={`${classPrefix}-dashboard-amount-title`}>{starling('funds.refund.comm.total_amount')}</div>
            <div className={`${classPrefix}-dashboard-amount-number`}>
              {
                // @ts-ignore
                simple?.amount
              }
            </div>
          </div>
        )}
      </div>
      <InfoCard infoList={infoList} />
      {hint && <div className={`${classPrefix}-hint`}>{hint}</div>}
    </div>
  )
}
