import { IS_DEV_OR_BOE } from '@/constant'
import { loadScript } from '@/utils'
import slardar from '@/utils/slardar'

export const VENMO_PROFILEID = IS_DEV_OR_BOE ? '1953896702662410263' : '3591690847614240972'
const VENMO_AUTHORIZATION = IS_DEV_OR_BOE ? 'sandbox_zjw5yw8b_hgkcbv8mtn3b4nkm' : 'production_4xqmwnry_qbs7xcc9zjqb4fbq'
const loadVenmoJsSdk = () => {
  const load = async (url: string) => {
    await loadScript(
      url,
      () => {
        console.log('load venmo script')
      },
      'text/javascript',
      'head',
    )
  }
  return Promise.all([
    load('https://js.braintreegateway.com/web/3.85.3/js/client.min.js'),
    load('https://js.braintreegateway.com/web/3.85.3/js/venmo.min.js'),
    load('https://js.braintreegateway.com/web/3.85.3/js/data-collector.min.js'),
  ])
}
/**
 * @return {*}  {Promise<boolean>}
 * @description 返回值表示 Venmo 是否可以用，如果不可用需要隐藏 venmo
 */
const initVenmo = async (): Promise<boolean> => {
  try {
    // @ts-ignore
    if (!window.braintree) {
      await loadVenmoJsSdk()
    }
    // @ts-ignore
    if (!window.pipoVenmoInstance || !window.pipoVenmoDataCollectorInstance) {
      // @ts-ignore
      const clientInstance = await braintree.client.create({
        authorization: VENMO_AUTHORIZATION,
      })
      const [dataCollectorInstance, venmoInstance] = await Promise.all([
        // @ts-ignore
        window.braintree.dataCollector.create({
          client: clientInstance,
        }),
        // @ts-ignore
        window.braintree.venmo.create({
          client: clientInstance,
          allowDesktop: true,
          allowNewBrowserTab: true,
          profileId: VENMO_PROFILEID,
          paymentMethodUsage: 'multi_use', // available in v3.77.0+
          // Add allowNewBrowserTab: false if your checkout page does not support relaunching in a new tab when returning from the Venmo app. This can be omitted otherwise.
        }),
      ])
      // @ts-ignore
      window.pipoVenmoInstance = venmoInstance
      // @ts-ignore
      window.pipoVenmoDataCollectorInstance = dataCollectorInstance
    }
    // @ts-ignore
    // Verify browser support before proceeding.
    if (!window.pipoVenmoInstance?.isBrowserSupported()) {
      slardar.timer('venmoInitError', 1, { message: 'not support' })
      return false
    }

    return true
  } catch (e: any) {
    slardar.timer('venmoInitError', 1, { message: 'init error' })
    console.error('Venmo init Error', e)
    return false
  }
}

export default initVenmo
