import React, { useContext } from 'react'

import { Message, Modal } from '@arco-design/web-react'

import { FROM_PAGE_ENUM, PUBSUB_TYPES } from '@/constant'
import { useUpdate } from '@/pages/Update/hooks/useUpdate'
import api from '@/services'
import { CardListRes } from '@/services/types'
import { ParamsContext } from '@/stores'
import slardar from '@/utils/slardar'
import starling from '@/utils/starling'
import teaClient from '@/utils/tea'

import { ManageCardTypeEnum } from '../../types'
import CardBase from '../CardBase'

interface IProps {
  card: CardListRes
  refreshList: () => void
  nextPrimaryCardNo: string
}

const PayoutCard: React.FC<IProps> = (props) => {
  const { card, nextPrimaryCardNo, refreshList } = props
  const { urlQuery, uPubSub } = useContext(ParamsContext)
  const { token } = urlQuery

  const { handleOpenUpdate, contentHolder } = useUpdate({
    from: FROM_PAGE_ENUM.UPDATE_IN_MANAGER,
    onClose: (needRefresh) => {
      needRefresh && refreshList()
    },
  })

  const handleAction = (type: ManageCardTypeEnum) => {
    teaClient.sendManagerPageClick({ pay_way: card.payWay, click_item: type })

    const handleMap: Record<ManageCardTypeEnum, () => void> = {
      [ManageCardTypeEnum.SetPrimary]: handleClickSetPrimary,
      [ManageCardTypeEnum.RemoveCard]: handleClickRemove,
      [ManageCardTypeEnum.EditCard]: handleClickEdit,
    }
    handleMap[type]?.()
  }

  const handleClickSetPrimary = () => {
    slardar.timer('managerSetPrimaryCardClick', 1, { currentCard: card })

    Modal.confirm({
      className: 'upay-modal upay-modal-simple upay-modal-primary-tip',
      title: starling('funds.refund.comm.make_primary'),
      content: starling('funds.refund.comm.replace_the_primary_card'),
      okText: starling('funds.refund.comm.confirm_button'),
      cancelText: starling('funds.refund.comm.cancel'),
      autoFocus: false,
      onOk: async () => {
        await api.primaryCard({
          token,
          id: card?.id || '',
        })

        Message.success(starling('funds.refund.comm.replace_the_primary_card_successfully'))
        refreshList()
      },
    })
  }

  const handleClickRemove = () => {
    slardar.timer('managerRemoveCardClick', 1, { currentCard: card })

    Modal.confirm({
      className: 'upay-modal upay-modal-simple upay-delete-card-modal',
      title: starling('funds.refund.comm.delete_card'),
      content:
        card?.primary && nextPrimaryCardNo
          ? starling('funds.refund.comm.replace_the_remove_primary_set', { number: nextPrimaryCardNo })
          : starling('funds.refund.comm.remove_the_card'),
      okText: starling('funds.refund.comm.confirm_button'),
      cancelText: starling('funds.refund.comm.cancel'),
      autoFocus: false,
      onOk: async () => {
        await api.removeCard({
          token,
          id: card?.id || '',
        })

        Message.success(starling('funds.refund.comm.removed_successfully'))
        refreshList()

        uPubSub.publish(PUBSUB_TYPES.REMOVE_CARD_SUCCESS)
      },
    })
  }

  const handleClickEdit = () => {
    handleOpenUpdate(card)
  }

  return (
    <>
      <CardBase card={card} onAction={handleAction} />

      {contentHolder}
    </>
  )
}

export default PayoutCard
