import { PaymentMethodItem } from '../../types'

export const INDOMARET_PAYMENT_METHOD: PaymentMethodItem = {
  paymentMethodType: {
    configuration: {},
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/cvs-common_f99160.svg',
    },
    starling_key: '',
    paymentMethodType: 'CASH_PIN',
    title: {
      payment: 'pipo_common_payin_conveniencestore',
    },
  },
  paymentMethod: {
    configuration: {
      png_icon_url: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/cvs_indomaret_v1_e6cf4b.png',
      dark_mode_png_icon_url: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/indomaret-dark_c9143e.png',
    },
    paymentMethod: 'INDOMARET',
    paymentMethodId: 'pm_pi_cp_indomaret_c_d',
    displayName: 'Cash payment at Indomaret',
    starling_key: 'pipo_payin_payment_main_cashindomaret',
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/cvs_indomaret_v1_e6cf4b.png',
      pngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/cvs_indomaret_v1_e6cf4b.png',
      darkModePngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/indomaret-dark_c9143e.png',
      voucherIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/indomaret-code_b19161.png',
      grayIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/indomaret-gray_d19006.png',
    },
  },
}
