import React, { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'

import { useRequest } from '@byted/hooks'

import { PAYMENT, PAY_WAY_TYPE } from '@/constant'
import { IPayinMethodItem, PaymentSubmitMethodEnum } from '@/pages/PayNext/interface'
import { Pix } from '@/pipo/pc'
import { PixProps } from '@/pipo/utils'
import { PipoComponentRef } from '@/pipo/utils/types'
import GlobalInfo from '@/stores/GlobalInfo'
import SafeJSON from '@/utils/SafeJSON'
import slardar from '@/utils/slardar'

import { IPaymentMethodsProps, IPaymentsMethodRef } from '../../interface'

const PixForm = forwardRef<IPaymentsMethodRef, IPaymentMethodsProps>((props, ref): React.ReactElement => {
  const { urlQuery, paymentSubmitMethod, changeNewPaymentStatus, channel } = props
  const {
    PIPOApi: { fetchDistrict },
    utils: { getContractAddressAsync },
  } = useContext(GlobalInfo)

  const pixRef = useRef<PipoComponentRef>(null)
  const [pixValid, setPixValid] = useState(false)

  // TODO: 暂时兼容两种逻辑，后续新UI全量后可梳理移除，由后端维护是否显示
  const showSavingBox = useMemo(() => {
    // 仅支付场景展示SaveBox
    if (paymentSubmitMethod !== PaymentSubmitMethodEnum.PAYMENT) return false

    // 若后端返回 以后端为准 老逻辑未返回排除后付费
    const supportFromBE = (channel as IPayinMethodItem).supportPaymentAndBind
    return typeof supportFromBE === 'boolean' ? supportFromBE : urlQuery.token !== PAYMENT.POST_PAY
  }, [channel, paymentSubmitMethod, urlQuery.token])

  // 同步验证状态
  useEffect(() => {
    changeNewPaymentStatus(PAY_WAY_TYPE.PIX, { valid: pixValid })
  }, [pixValid, changeNewPaymentStatus])

  const { data: contractAddressList } = useRequest(getContractAddressAsync, { auto: true })

  useImperativeHandle(ref, () => ({
    // 一步支付走3M后端提交
    processSubmitData: async (submitParams) => {
      if (pixRef.current?.validateSubmitData) {
        const { paymentMethod, paymentParams, storeCard, fillAddressState } = await pixRef.current?.validateSubmitData()

        fillAddressState &&
          slardar.timer('fillAddressSubmitEvent', fillAddressState.metrics, {
            from: 'PIX',
            ...fillAddressState.categories,
          })

        const paymentMethodObj = {
          method_id: paymentMethod.paymentMethodId,
          payment_elements: paymentParams,
        }
        submitParams.channelParameter = SafeJSON.stringify(paymentMethodObj)
        submitParams.bindAndPay = storeCard && urlQuery.type !== PAYMENT.POST_PAY
      }
      return submitParams
    },
  }))

  const onPixValid = useCallback((value: boolean) => {
    setPixValid(value)
  }, [])

  const pixProps: PixProps = {
    onFetchDistrict: fetchDistrict,
    onValidate: onPixValid,
    showSavingBox,
    contractAddressList,
  }

  return <Pix ref={pixRef} {...pixProps} />
})

export default PixForm
