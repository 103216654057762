import PubSub from 'pubsub-js'

export const getPubSub = (uniqueId: string) => {
  return {
    subscribe: (key: string, func: (msg: string, values: Record<string, unknown>) => void) => {
      return PubSub.subscribe(`${uniqueId}.${key}`, func)
    },
    publish: (key: string, val: Record<string, unknown>) => {
      return PubSub.publish(`${uniqueId}.${key}`, val)
    },
    // 根据token取消单个
    unsubscribe: (token: string) => {
      return PubSub.unsubscribe(token)
    },
    // 取消所有监听
    unsubscribeKey: (key: string) => {
      return PubSub.unsubscribe(`${uniqueId}.${key}`)
    },
  }
}
