import { PaymentMethodItem, FetchDistrictFunc, PipoSubmitParams } from '@/pipo/utils'
import { IPaymentParamsMapItem } from '@/pipo/utils/types'
import { IGetContractAddressRes } from '@/services/types'
/**
 *  加载 DirectDebit 组件入参
 */
export interface DirectDebitProps {
  showSavingBox?: boolean
  contractAddressList: IGetContractAddressRes[] | undefined
  onFetchDistrict: FetchDistrictFunc
  onValidate?: (isValid: boolean) => void
  onSubmit?: (params: PipoSubmitParams) => Promise<void>
}
/**
 * DirectDebit 需要填写表单的位数
 */
export type DirectDebitFormData = {
  account_type?: string
  holder_name?: string
  routing_no?: string
  account_no?: string
  id_type?: string
  identity?: string
  holder_first_name?: string
  holder_last_name?: string
  billing_country_region?: string
  billing_state?: string
  billing_city?: string
  addressline1?: string
  addressline2?: string
  billing_postal_code?: string
  contract_address?: string // 合同地址选择内部使用
}

export const DIRECT_DEBIT_B_D_PAYMENT_PARAMS_MAP: Record<string, IPaymentParamsMapItem> = {
  holder_name: { element: 'eg_pi_dd_achdirectdebit_b_d_local_us_holder_name', is_encrypted: true },
  routing_no: { element: 'eg_pi_dd_achdirectdebit_b_d_local_us_routing_no', is_encrypted: true },
  account_no: { element: 'eg_pi_dd_achdirectdebit_b_d_local_us_account_no', is_encrypted: true },
  id_type: { element: 'eg_pi_dd_achdirectdebit_b_d_local_us_id_type', is_encrypted: true },
  identity: { element: 'eg_pi_dd_achdirectdebit_b_d_local_us_identity', is_encrypted: true },
}

export const DIRECT_DEBIT_C_D_PAYMENT_PARAMS_MAP: Record<string, IPaymentParamsMapItem> = {
  holder_first_name: { element: 'eg_pi_dd_achdirectdebit_c_d_local_us_holder_last_name', is_encrypted: true },
  holder_last_name: { element: 'eg_pi_dd_achdirectdebit_c_d_local_us_holder_last_name', is_encrypted: true },
  routing_no: { element: 'eg_pi_dd_achdirectdebit_c_d_local_us_routing_no', is_encrypted: true },
  account_no: { element: 'eg_pi_dd_achdirectdebit_c_d_local_us_account_no', is_encrypted: true },
  billing_country_region: { element: 'eg_pi_dd_achdirectdebit_c_d_local_us_billing_country_region', is_encrypted: true },
  billing_state: { element: 'eg_pi_dd_achdirectdebit_c_d_local_us_billing_state', is_encrypted: true },
  billing_city: { element: 'eg_pi_dd_achdirectdebit_c_d_local_us_billing_city', is_encrypted: true },
  addressline1: { element: 'eg_pi_dd_achdirectdebit_c_d_local_us_addressline1', is_encrypted: true },
  addressline2: { element: 'eg_pi_dd_achdirectdebit_c_d_local_us_addressline2', is_encrypted: true },
  billing_postal_code: { element: 'eg_pi_dd_achdirectdebit_c_d_local_us_billing_postal_code', is_encrypted: true },
}

/**
 * DirectDebit PaymentMethodType
 */
export const DIRECT_DEBIT_PAYMENT_METHOD: PaymentMethodItem = {
  paymentMethodType: {
    configuration: {},
    icon: {
      iconUrl: '',
    },
    paymentMethodType: 'ACH_DIRECT_DEBIT' as PaymentMethodItem['paymentMethodType']['paymentMethodType'],
    starling_key: 'pipo_common_payin_direct_debit',
  },
  paymentMethod: {
    configuration: {},
    paymentMethod: 'DIRECT_DEBIT',
    paymentMethodId: '' as PaymentMethodItem['paymentMethod']['paymentMethodId'],
    starling_key: 'pipo_common_payin_direct_debit',
    icon: {
      // TODO 待补充
      iconUrl: '',
      pngIconUrl: '',
      darkModePngIconUrl: '',
      grayIconUrl: '',
      detailIconUrl: '',
    },
    displayName: 'DirectDebit',
    title: {
      // TODO 待补充
      payment: '',
      piListShort: '',
      piListLong: '',
    },
  },
}

export const DIRECT_DEBIT_B_D_PAYMENT_METHOD: PaymentMethodItem = {
  ...DIRECT_DEBIT_PAYMENT_METHOD,
  paymentMethod: {
    ...DIRECT_DEBIT_PAYMENT_METHOD.paymentMethod,
    paymentMethodId: 'pm_pi_dd_achdirectdebit_b_d' as PaymentMethodItem['paymentMethod']['paymentMethodId'],
  },
}

export const DIRECT_DEBIT_C_D_PAYMENT_METHOD: PaymentMethodItem = {
  ...DIRECT_DEBIT_PAYMENT_METHOD,
  paymentMethod: {
    ...DIRECT_DEBIT_PAYMENT_METHOD.paymentMethod,
    paymentMethodId: 'pm_pi_dd_achdirectdebit_c_d' as PaymentMethodItem['paymentMethod']['paymentMethodId'],
  },
}
