import { Email, HolderName, ItemInput } from '@/pipo/pc/inner-components'
import { validator } from '@/pipo/utils'
import starling from '@/utils/starling'

import { IBankAccountFormConfigItem, IGetBankAccountFormConfigProps } from '../utils'

export const getEGFormConfig = ({ cardHolderInfo }: IGetBankAccountFormConfigProps): IBankAccountFormConfigItem => {
  return {
    allowAccountType: ['b', 'c'],
    formSchema: [
      {
        field: 'holder_name',
        widget: HolderName,
        widgetProps: {
          title: starling('funds.refund.comm.beneficiary_name'),
          placeholder: starling('funds.refund.comm.system_name_description'),
          maxLength: 35,
          selectList: cardHolderInfo?.holderNameList,
        },
        rules: { required: true, pattern: validator.AlphabetNumberSpace },
      },
      {
        field: 'bic',
        widget: ItemInput,
        widgetProps: {
          title: starling('funds.refund.comm.swift_code'),
          placeholder: 'Eg: AAAAEG12345',
          maxLength: 11,
        },
        rules: { required: true, pattern: validator.egBic },
      },
      {
        field: 'iban',
        widget: ItemInput,
        widgetProps: {
          title: starling('cg.upay.exception.channel_youriban'),
          placeholder: 'EG000000000000000000000000000',
          maxLength: 29,
        },
        rules: { required: true, pattern: validator.egIban },
      },
      {
        field: 'email',
        widget: Email,
        widgetProps: {
          title: starling('funds.refund.comm.system_email'),
          placeholder: starling('funds.refund.comm.system_email_placeholder'),
        },
        rules: { required: true, pattern: validator.emailRegex },
      },
    ],
  }
}
