import { PaymentMethodItem } from '../../types'

export const CASH_PLUS_PAYMENT_METHOD: PaymentMethodItem = {
  paymentMethodType: {
    configuration: {},
    icon: {
      iconUrl: '',
    },
    starling_key: 'CASH_PIN',
    paymentMethodType: 'CASH_PIN',
  },
  paymentMethod: {
    configuration: {},
    paymentMethod: 'CASH_PLUS',
    paymentMethodId: 'pm_pi_cp_cashpincashplus_c_d',
    displayName: 'Cash Plus',
    starling_key: '',
    icon: {
      iconUrl: '',
      voucherIconUrl: '',
      pngIconUrl: '',
      darkModePngIconUrl: '',
      detailIconUrl: '',
    },
  },
}
