import { BankListSelect, Email, HolderName, ItemInputNumber } from '@/pipo/pc/inner-components'
import { validator } from '@/pipo/utils'
import starling from '@/utils/starling'

import { IBankAccountFormConfigItem, IGetBankAccountFormConfigProps } from '../utils'

export const getTHFormConfig = ({ bankList, cardHolderInfo }: IGetBankAccountFormConfigProps): IBankAccountFormConfigItem => ({
  allowAccountType: ['c', 'b'],
  needInfo: ['bank_list'],
  formSchema: [
    {
      field: 'bank_code',
      widget: BankListSelect,
      widgetProps: {
        title: starling('funds.check_out.comm.channel_bank'),
        placeholder: starling('funds.refund.comm.bank_selection'),
        bankList,
      },
      rules: { required: true },
    },
    {
      field: 'holder_name',
      widget: HolderName,
      widgetProps: {
        len: 'full',
        title: starling('funds.refund.comm.system_name'),
        placeholder: starling('funds.refund.comm.system_name_description'),
        maxLength: 100,
        selectList: cardHolderInfo?.holderNameList,
      },
      rules: { required: true, pattern: validator.thHolderName },
    },
    {
      field: 'account_no',
      widget: ItemInputNumber,
      widgetProps: {
        title: starling('funds.refund.comm.system_bankaccount_account_number'),
        placeholder: starling('funds.refund.comm.system_bankaccount_account_number_placeholder'),
        maxLength: 35,
      },
      rules: { required: true, pattern: validator.thBankAccountNo },
    },
    {
      field: 'email',
      widget: Email,
      widgetProps: {
        title: starling('funds.refund.comm.system_email'),
        placeholder: starling('funds.refund.comm.system_email_placeholder'),
      },
      rules: { required: true, pattern: validator.emailRegex },
    },
  ],
})
