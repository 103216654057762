import React, { useMemo } from 'react'

import { VoucherBoardData } from '@/pipo/hooks/use-ui-voucher'
import {
  GET_SUPPORT_BANK_MAP,
  PipoProvider, // PaymentDetail,
  // SupportPaymentMethodId,
  SUPPORT_PAYMENT_METHODS_ID_MAP,
} from '@/pipo/utils'
import starling from '@/utils/starling'

import './index.less'

const classPrefix = 'pipo-pc-voucher-pureCode'
interface PureCodeProps {
  data: VoucherBoardData
  amount: string
}

const PureCode = (props: PureCodeProps) => {
  const { data } = props
  const {
    type,
    paymentMethodInfo: { paymentMethodId, bankId },
    simple,
    hint,
  } = data
  const { t } = PipoProvider.useContext()
  const SUPPORT_BANK_MAP = useMemo(GET_SUPPORT_BANK_MAP, [])

  const name = bankId ? SUPPORT_BANK_MAP[bankId]?.bankShortName || '' : t(SUPPORT_PAYMENT_METHODS_ID_MAP[paymentMethodId].paymentMethod.starling_key)
  const iconUrl = bankId
    ? SUPPORT_BANK_MAP[bankId || ''].icon.voucherIconUrl
    : SUPPORT_PAYMENT_METHODS_ID_MAP[paymentMethodId].paymentMethod.icon.voucherIconUrl

  return (
    <div className={classPrefix}>
      <div className={`${classPrefix}-dashboard`}>
        <div className={`${classPrefix}-dashboard-left`}>
          <img className={`${classPrefix}-dashboard-logo`} src={iconUrl} />
          <div className={`${classPrefix}-dashboard-name`}>{name}</div>
        </div>
        {type === 'simple' && (
          <div className={`${classPrefix}-dashboard-right`}>
            <div className={`${classPrefix}-dashboard-right-no`}>
              {
                // @ts-ignore
                simple?.formatCode
              }
            </div>
            <div className={`${classPrefix}-dashboard-right-amount`}>
              <span>{starling('funds.refund.comm.total_amount')}: </span>
              <span>
                {
                  // @ts-ignore
                  simple?.amount
                }
              </span>
            </div>
          </div>
        )}
      </div>
      {hint && <div className={`${classPrefix}-hint`}>{hint}</div>}
    </div>
  )
}

export { PureCode }
