import React, { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'

import { PAYMENT, PAY_WAY_TYPE } from '@/constant'
import { IPayinMethodItem, PaymentSubmitMethodEnum } from '@/pages/PayNext/interface'
import { JPBanktransferForm } from '@/pipo/pc'
import { PipoComponentRef } from '@/pipo/utils/types'
import { ParamsContext } from '@/stores'
import SafeJSON from '@/utils/SafeJSON'

import { IPaymentMethodsProps, IPaymentsMethodRef } from '../../interface'

const BankTransferJP = forwardRef<IPaymentsMethodRef, IPaymentMethodsProps>((props, ref): React.ReactElement => {
  const { changeNewPaymentStatus, paymentSubmitMethod, channel } = props

  const {
    urlQuery: { type },
  } = useContext(ParamsContext)

  const [isValid, setIsValid] = useState(false)
  const JPBankTransferRef = useRef<PipoComponentRef>(null)

  // 同步验证状态
  useEffect(() => {
    changeNewPaymentStatus(PAY_WAY_TYPE.BANK_TRANSFER_JP, { valid: isValid })
  }, [isValid, changeNewPaymentStatus])

  // TODO: 暂时兼容两种逻辑，后续新UI全量后可梳理移除，由后端维护是否显示
  const showSavingBox = useMemo(() => {
    // 仅支付场景展示SaveBox
    if (paymentSubmitMethod !== PaymentSubmitMethodEnum.PAYMENT) return false

    // 若后端返回 以后端为准 老逻辑未返回排除后付费
    const supportFromBE = (channel as IPayinMethodItem).supportPaymentAndBind
    return typeof supportFromBE === 'boolean' ? supportFromBE : type !== PAYMENT.POST_PAY
  }, [paymentSubmitMethod, channel, type])

  useImperativeHandle(ref, () => ({
    processSubmitData: async (submitParams) => {
      if (JPBankTransferRef.current?.validateSubmitData) {
        const { paymentParams, storeCard } = await JPBankTransferRef.current?.validateSubmitData()
        const paymentMethodObj = {
          payment_elements: paymentParams,
        }
        submitParams.channelParameter = SafeJSON.stringify(paymentMethodObj)
        submitParams.bindAndPay = storeCard
      }
      return submitParams
    },
  }))

  const onValidChange = useCallback((value: boolean) => {
    setIsValid(value)
  }, [])

  return (
    <div className="upay-banktrans-container">
      <JPBanktransferForm ref={JPBankTransferRef} onValidate={onValidChange} showSavingBox={showSavingBox} />
    </div>
  )
})

export default BankTransferJP
