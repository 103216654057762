import React from 'react'

const Warning = (): JSX.Element => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.5372 11.5373C10.342 12.7325 8.72438 13.4167 6.99992 13.4167C5.27546 13.4167 3.65786 12.7325 2.46265 11.5373C1.26744 10.3421 0.583252 8.7245 0.583252 7.00004C0.583252 5.27558 1.26744 3.65799 2.46265 2.46277C3.65786 1.26756 5.27546 0.583374 6.99992 0.583374C8.72438 0.583374 10.342 1.26756 11.5372 2.46277C12.7324 3.65799 13.4166 5.27558 13.4166 7.00004C13.4166 8.7245 12.7324 10.3421 11.5372 11.5373ZM6.4168 3.50002V8.16669C6.4168 8.48886 6.67797 8.75002 7.00013 8.75002C7.3223 8.75002 7.58347 8.48886 7.58347 8.16669V3.50002C7.58347 3.17786 7.3223 2.91669 7.00013 2.91669C6.67797 2.91669 6.4168 3.17786 6.4168 3.50002ZM7.00006 10.7917C7.40277 10.7917 7.72923 10.4652 7.72923 10.0625C7.72923 9.65982 7.40277 9.33336 7.00006 9.33336C6.59735 9.33336 6.27089 9.65982 6.27089 10.0625C6.27089 10.4652 6.59735 10.7917 7.00006 10.7917Z"
        fill="#e0a111"
      />
    </svg>
  )
}

export default Warning
