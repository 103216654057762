import React from 'react'

import starling from '@/utils/starling'

import './index.less'

const classPrefix = 'pipo-pc-voucher-amount'

interface IProps {
  iconUrl: string | undefined
  title: React.ReactNode
  amount: string
}

export const AmountCard = (props: IProps) => {
  const { iconUrl, title, amount } = props

  return (
    <div className={classPrefix}>
      <div className={`${classPrefix}-dashboard `}>
        <div className={`${classPrefix}-dashboard-left`}>
          {iconUrl && <img className={`${classPrefix}-dashboard-logo`} src={iconUrl} />}
          <div className={`${classPrefix}-dashboard-name`}>{title}</div>
        </div>

        <div className={`${classPrefix}-dashboard-right`}>
          <div className={`${classPrefix}-dashboard-right-amount`}>
            <span>{starling('funds.refund.comm.total_amount')}</span>
          </div>
          <div className={`${classPrefix}-dashboard-right-no`}>
            <span>{amount}</span>
          </div>
        </div>
      </div>
    </div>
  )
}
