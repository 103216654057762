import React from 'react'
import { Controller, FieldErrors, UseFormReturn } from 'react-hook-form'

import { useAddressPC } from '@/pipo/hooks'
import { UseAddressPCProps } from '@/pipo/hooks/use-address-pc'
import { usePostalCode } from '@/pipo/hooks/use-postal-code'
import { PipoProvider, validator } from '@/pipo/utils'
import starling from '@/utils/starling'

import { BillingAddress, HolderName, ItemInputNumber } from '../../inner-components'
import { DirectDebitFormData, DirectDebitProps } from './utils'
import { routingCodeRegex } from './validator'

interface IProps {
  clsPrefix: string
  errors: FieldErrors<DirectDebitFormData>
  form: UseFormReturn<DirectDebitFormData, any>
  onFetchDistrict: DirectDebitProps['onFetchDistrict']
  formErrors: FieldErrors<DirectDebitFormData>
  handleBlur: (...args: any[]) => void
}
export const BankAccountInfo = (props: IProps) => {
  const { errors, clsPrefix, form, onFetchDistrict, formErrors, handleBlur } = props
  const { control, watch, getValues, setValue, trigger, setError, clearErrors } = form

  const { t, getErrorText, state } = PipoProvider.useContext()
  const { countryOrRegion = '', config = {} } = state

  const nameOptions = {
    street: 'bank_address_details',
    region: 'bank_address_region',
    state: 'bank_address_state',
    city: 'bank_address_city',
    postalCode: 'bank_address_postal_code',
  }
  const billingAddressProps = useAddressPC({
    onFetchDistrict,
    getValues,
    setValue,
    trigger,
    clearErrors,
    setError,
    watch,
    countryOrRegion,
    formErrors,
    options: nameOptions as unknown as UseAddressPCProps['options'],
  })
  const { selectedAddressArr } = billingAddressProps
  const postalCodeProps = usePostalCode({
    selectedAddressArr,
    watch,
    trigger,
  } as any)

  return (
    <React.Fragment>
      <div
        className={`${clsPrefix}-subtitle`}
        style={{
          marginTop: '20px',
        }}
      >
        {starling('funds.check_out.comm.financial_institution_info')}
      </div>
      <div className={`${clsPrefix}-subform-wrapper`}>
        <Controller
          rules={{ required: true }}
          name="account_no"
          control={control}
          render={({ field, fieldState }) => (
            <ItemInputNumber
              {...field}
              error={errors.account_no}
              errorMessage={getErrorText(errors.account_no?.type)}
              title={starling('funds.refund.comm.system_bankaccount_account_number')}
              placeholder={starling('funds.refund.comm.system_bankaccount_account_number_placeholder')}
              maxLength={17}
              onBlur={() => {
                field.onBlur()
                handleBlur('account_no', fieldState)
              }}
            />
          )}
        />
        <Controller
          rules={{ required: true, pattern: routingCodeRegex }}
          name="routing_no"
          control={control}
          render={({ field, fieldState }) => (
            <ItemInputNumber
              {...field}
              error={errors.routing_no}
              errorMessage={getErrorText(errors.routing_no?.type)}
              title={starling('funds.refund.comm.system_bankaccount_routingcode_US')}
              placeholder={starling('funds.refund.comm.system_bankaccount_routingcode_US_placeholder')}
              maxLength={9}
              onBlur={() => {
                field.onBlur()
                handleBlur('routing_no', fieldState)
              }}
            />
          )}
        />
        {/** */}
        <Controller
          rules={{ required: true, pattern: validator.nameRegex }}
          name="bank_name"
          control={control}
          render={({ field, fieldState }) => (
            <HolderName
              {...field}
              error={errors.bank_name}
              errorMessage={getErrorText(errors.bank_name?.type)}
              title={starling('funds.check_out.comm.financial_institution_name')}
              placeholder={starling('funds.check_out.comm.financial_institution_name')}
              onBlur={() => {
                field.onBlur()
                handleBlur('bank_name', fieldState)
              }}
            />
          )}
        />
        <BillingAddress
          control={control}
          errors={errors}
          postalCodeProps={postalCodeProps}
          {...billingAddressProps}
          needRegion={false}
          nameOptions={nameOptions}
          text={{
            streetTitle: starling('funds.check_out.comm.financial_institution_address'),
            streetPlaceholder: starling('funds.check_out.comm.financial_institution_address'),
          }}
          handleBlur={handleBlur}
        />
      </div>
    </React.Fragment>
  )
}
