import starling from '@/utils/starling'

import { IGetLocalBankTransferFormConfigProps, ILocalBankTransferConfigItem } from '../utils'
import { getJPFormConfig } from './jp'
import { getUSFormConfig } from './us'
import { getVNFormConfig } from './vn'

export const getLocalBankTransferFormConfig = (props: IGetLocalBankTransferFormConfigProps): ILocalBankTransferConfigItem | null => {
  if (props.countryCode === 'VN') return getVNFormConfig(props)
  if (props.countryCode === 'US') return getUSFormConfig(props)
  if (props.countryCode === 'JP') return getJPFormConfig(props)

  return null
}

export const getUpdateParamsMap = (): Record<string, string> => {
  return {
    holder_name: starling('funds.refund.comm.beneficiary_name'),
    holder_name_katakana: starling('funds.refund.comm.katakana_name'),
    agency: starling('funds.refund.comm.branch_code'),
    phone: starling('funds.refund.comm.system_phonenumber'),
    account_no: starling('funds.refund.comm.system_bankaccount_account_number'),
    routing_no: starling('fund.common.BSB_code'),
    // email: starling('funds.refund.comm.system_email'), // 去掉email，免得展示两次
  }
}
