import React from 'react'
import { FieldError } from 'react-hook-form'

import { DatePicker } from '@arco-design/web-react'
import { omit } from 'lodash'

import { ItemBlock } from '../index'

interface IProps {
  title: string
  placeholder?: string
  len?: 'half' | 'full'
  error?: FieldError
  errorMessage: string
  value?: string
  onChange: (val: string) => void
  onBlur?: () => void
}

export const SignDate = (props: IProps) => {
  const { title, placeholder, len = 'half', error, errorMessage, value, onChange, onBlur } = props

  return (
    <ItemBlock style={len} title={title} errorState={Boolean(error)} errorMessage={errorMessage}>
      <DatePicker
        {...omit(props, 'style')}
        style={{ width: '100%' }}
        size="large"
        allowClear
        value={value}
        error={Boolean(error)}
        placeholder={placeholder}
        onChange={(val) => onChange(val)}
        onVisibleChange={(visible) => {
          !visible && onBlur?.() // 手动触发onBlur给form收集状态
        }}
      />
    </ItemBlock>
  )
}
