import { PaymentMethodItem } from '../../types'

export const ZALOPAY_PAYMENT_METHOD: PaymentMethodItem = {
  paymentMethodType: {
    configuration: {},
    icon: {
      iconUrl: '',
    },
    starling_key: 'pipo_common_ewallet',
    paymentMethodType: 'E_WALLET',
    title: {
      payment: 'pipo_common_ewallet',
    },
  },
  paymentMethod: {
    configuration: {},
    paymentMethod: 'ZALOPAY',
    paymentMethodId: 'pm_pi_ew_zalopay_c_r',
    displayName: 'ZaloPay',
    starling_key: 'pipo_payin_payment_main_ZaloPay',
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/zalopay-icon_e8534b.svg',
      grayIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/zalopay-gray_0818a4.svg',
    },
  },
}
