import React, { FC, useContext } from 'react'

import { Logger } from '@upay/utils'

import { Loading, PageModal, Policy, Status } from '@/components'
import CheckOTPModal from '@/components/CheckOTPModal'
import ErrorBoundary from '@/components/ErrorBoundary'
import { PAYMENT } from '@/constant'
import { ParamsContext } from '@/stores'
import { logger } from '@/utils/slardar'
import teaClient from '@/utils/tea'

import PaymentCodeModal from '../PaymentCode'
import CombinationStatus from './components/CombinationStatus'
import PayNextFooter from './components/PayNextFooter'
import PayNextHeader from './components/PayNextHeader'
import PayinMethodList from './components/PayinMethodList'
import { IPayNextProps } from './interface'
import { PayNextModel } from './model/usePayNext'

const PayNext: FC<IPayNextProps> = () => {
  const {
    data: {
      visible,
      isIndependentPage,
      statusProps,
      loadingProps,
      paymentCodeProps,
      selectedPayinMethod,
      checkOTPModalProps,
      updateContentHolder,
      combinationStatusProps,
    },
  } = PayNextModel.useContext()
  const { params, urlQuery } = useContext(ParamsContext)

  return (
    <div className="upay-payNext">
      <PageModal
        visible={isIndependentPage || visible}
        contentHeight={params.height}
        mode={isIndependentPage && params.mode}
        className="upay-pay-modal upay-modal"
        footer={<PayNextFooter />}
        closable={false}
        alignCenter={false}
      >
        <ErrorBoundary label="PayModal" loggerInstance={payLogger}>
          <PayNextHeader />
          <PayinMethodList />
          {/* 后付费且有风控金额时展示后付费提示 */}
          <Policy
            showAutoPayHint={!!selectedPayinMethod?.riskAmount && urlQuery.type === PAYMENT.POST_PAY}
            policyUrl={params.policyUrl}
            onClickPolicy={() => {
              teaClient.sendPayPageClick({ click_item: 'privacy_policy' })
            }}
          />
        </ErrorBoundary>
      </PageModal>
      <Loading {...loadingProps} />
      <Status {...statusProps} />
      <CombinationStatus {...combinationStatusProps} />
      <PaymentCodeModal {...paymentCodeProps} />
      <CheckOTPModal {...checkOTPModalProps} />
      {updateContentHolder}
    </div>
  )
}

const PayNextProvider: FC<IPayNextProps> = (props) => {
  return (
    <PayNextModel.Provider initialState={props}>
      <PayNext />
    </PayNextModel.Provider>
  )
}

export default PayNextProvider

export const payLogger = new Logger('SDK_PAY', logger)
