import React, { useContext, useMemo } from 'react'

import { Alert as ArcoAlert, Skeleton } from '@arco-design/web-react'

import { SecurityTip } from '@/components'
import Alert from '@/components/Alert'
import HighlightStarlingKey from '@/components/HighLightStarlingKey'
import VersionReminder from '@/components/VersionReminder'
import { FROM_PAGE_ENUM } from '@/constant'
import { Warning } from '@/static/icon'
import { ParamsContext } from '@/stores'
import starling from '@/utils/starling'

import { PayNextModel } from '../../model/usePayNext'

import './index.less'

const PayNextHeader = () => {
  const { params } = useContext(ParamsContext)
  const {
    data: { billInfo, globalNotification, selectedPayinMethod, isBindSubmit, currentPIListKey },
  } = PayNextModel.useContext()

  const hintTopInfo = useMemo(() => {
    if (!selectedPayinMethod?.displayRiskAmount || !isBindSubmit) {
      return null
    }

    return [
      { key: 'amount', value: selectedPayinMethod.displayRiskAmount },
      { key: 'currency', value: selectedPayinMethod.riskAmountCurrency || 'USD' },
      { key: 'minDay', value: '1' },
      { key: 'maxDay', value: '15' },
    ]
  }, [isBindSubmit, selectedPayinMethod])

  return (
    <div>
      <VersionReminder fromPage={FROM_PAGE_ENUM.PAY_NEXT} pageVisible />

      {!params.hideTitle && (
        <div className="upay-modal-header upay-payNext-header">
          <div className="upay-modal-header-title">
            {currentPIListKey === 'foldedPIList' ? starling('funds.check_out.comm.pay_with_new_pi') : starling('funds.refund.comm.total_amount')}
          </div>
          <div className="upay-modal-header-total">
            <Skeleton loading={!billInfo} animation text={{ width: '140px', rows: 1, className: 'upay-modal-header-amount-skeleton' }}>
              <span>{billInfo?.displayAmount}</span> <span className="upay-modal-header-currency">{billInfo?.currency}</span>
            </Skeleton>
          </div>
        </div>
      )}
      <SecurityTip />
      {globalNotification && <Alert style={{ margin: '0 0 8px' }} message={globalNotification?.title} description={globalNotification?.desc} />}
      {hintTopInfo && (
        <ArcoAlert
          icon={<Warning />}
          content={<HighlightStarlingKey placeholders={hintTopInfo} starlingKey="funds.refund.comm.Automatic_deduction_int_top" />}
          type="warning"
          style={{ marginBottom: 16 }}
        />
      )}
    </div>
  )
}

export default PayNextHeader
