import React from 'react'

import { IPayinMethodItem } from '@/pages/PayNext/interface'

import { getItemKey } from '../../utils'
import ControllerItem from '../ControllerItem'
import { getExtraComponent } from './utils'

interface IProps {
  paymentItem: IPayinMethodItem
}
const PaymentItemContent: React.FC<IProps> = (props) => {
  const { paymentItem } = props
  const { cardId, payWay } = paymentItem
  const extraComponent = getExtraComponent(payWay, !!cardId)

  if (!extraComponent) return null

  return <ControllerItem name={getItemKey(paymentItem)}>{React.createElement(extraComponent)}</ControllerItem>
}

export default PaymentItemContent
