import React, { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Button, Skeleton } from '@arco-design/web-react'
import { StatusActionTypeEnum, StatusTypeEnum } from '@upay/utils/es/types'

import { Loading, PageModal, Policy, SecurityTip, Status } from '@/components'
import RiskAmountHint from '@/components/RiskAmountHint'
import { IOpenStatusProps, IStatusCloseCallbackProps } from '@/components/Status/config'
import useStatus from '@/components/Status/useStatus'
import { FROM_PAGE_ENUM, NEW_SDK_TERMINAL_EQUIP, PUBSUB_TYPES, RESPONSE_CODE } from '@/constant'
import { useNewWindow, usePostMessage } from '@/hooks'
import { useSubmitCCDC } from '@/hooks/useSubmitCCDC'
import api from '@/services'
import { ParamsContext } from '@/stores'
import GlobalInfo from '@/stores/GlobalInfo'
import SafeJSON from '@/utils/SafeJSON'
import { oneTimeHoc } from '@/utils/oneTimeHoc'
import slardar from '@/utils/slardar'
import starling from '@/utils/starling'

import UpdateWidget from './components/UpdateWidget'
import { useCardInfo } from './hooks/useCardInfo'
import { useRiskAmountInfo } from './hooks/useRiskAmountInfo'
import { generateCCDCSubmitParams } from './utils'

import './index.less'

interface IProps {
  cardId: string
  payWay: number
  from: FROM_PAGE_ENUM
  onClose?: (needRefresh?: boolean) => void
}
// 目前只支持CCDC
const Update: React.FC<IProps> = (props) => {
  const {
    data: { sessionId, channelFingerprint },
  } = useContext(GlobalInfo)
  const { cardId, payWay, onClose: onClosePage } = props
  const {
    params,
    urlQuery: { token },
  } = useContext(ParamsContext)
  const {
    data: { riskInfoObj, lastBindCardId },
  } = useContext(GlobalInfo)
  const [innerVisible, setInnerVisible] = useState(true)
  const [posting, setPosting] = useState(false)
  const [linkOpen, linkClose] = useNewWindow(() => {
    setPosting(false)
    setInnerVisible(true)
  })
  const { statusProps, openStatus } = useStatus()
  const { submitBind } = useSubmitCCDC({
    handleBillStatus,
    fromPage: FROM_PAGE_ENUM.UPDATE,
    linkOpen,
  })

  const form = useForm({ mode: 'all' })
  const {
    formState: { isValid },
    getValues,
  } = form

  const { card, getCardInfoLoading } = useCardInfo({ cardId })
  const { riskAmountInfo, getRiskAmountLoading } = useRiskAmountInfo({ cardId, payWay })

  const submitDisabled = !isValid || getRiskAmountLoading || getCardInfoLoading
  const submitLoading = posting || getRiskAmountLoading || getCardInfoLoading

  usePostMessage(async (postData) => {
    const { upayType, query } = postData
    const { error_msg, bind_card_status, payment_status, from, error_code } = query
    // 来自result结果页的通知
    if (upayType === PUBSUB_TYPES['3DS_RESULT'] && from === FROM_PAGE_ENUM.UPDATE) {
      const isSuccess = [StatusTypeEnum.SUCCEED, StatusTypeEnum.SUCCESS].includes(bind_card_status || payment_status)
      slardar.timer('updateOPENstatus', 1, {
        from: props.from,
        to: 'status',
        type: 'status_modal',
        action: StatusActionTypeEnum.UPDATE,
        desc: bind_card_status || payment_status,
        error: error_msg || '',
      })
      handleBillStatus({
        actionType: StatusActionTypeEnum.UPDATE,
        status: isSuccess ? StatusTypeEnum.SUCCESS : StatusTypeEnum.FAIL,
        errMsg: error_msg,
        pipoErrorCode: error_code,
      })
    }
  })

  async function handleBillStatus(props: IOpenStatusProps) {
    linkClose()
    props.onClose = handleResultClose
    props.payWay = card?.payWay
    await openStatus(props)
    setPosting(false)
  }

  function handleResultClose({ status }: IStatusCloseCallbackProps) {
    const isSuccess = [StatusTypeEnum.SUCCEED, StatusTypeEnum.SUCCESS].includes(status)
    if (isSuccess) {
      onClosePage?.(true)
    } else {
      setInnerVisible(true)
    }
  }

  const handleSubmitClick = async () => {
    try {
      if (!card) throw 'error'

      setInnerVisible(false)
      setPosting(true)

      const formData = getValues()
      const submitParams = generateCCDCSubmitParams(formData, card.paymentMethodId)
      const bindRes = await api.updateCard({
        payWay: card.payWay,
        cardId: cardId || '',
        riskInfo: SafeJSON.stringify(riskInfoObj),
        token,
        upaySessionId: sessionId.current,
        refId: channelFingerprint.current,
        terminalEquip: NEW_SDK_TERMINAL_EQUIP,
        riskAmount: riskAmountInfo?.riskAmount || '',
      })
      if (bindRes?.code !== RESPONSE_CODE.SUCCESS || !bindRes?.data) throw bindRes?.msg || 'error'
      lastBindCardId.current = bindRes?.data?.cardId
      await submitBind({ ...bindRes?.data, payToken: card.payToken }, submitParams)
    } catch (e: any) {
      handleBillStatus({
        actionType: StatusActionTypeEnum.UPDATE,
        status: StatusTypeEnum.FAIL,
        errMsg: e?.msg || e?.message || e,
      })
      slardar.timer('updateSubmitError', 1, { error: SafeJSON.stringify(e) })
    }
  }

  const handleClickClose = () => {
    setInnerVisible(false)

    onClosePage?.(false)
  }

  const renderFooter = () => {
    return (
      <div className="upay-modal-footer">
        <div className="upay-modal-footer-left">
          <Button onClick={handleClickClose}>{starling('funds.refund.comm.back')}</Button>
        </div>
        <div className="upay-modal-footer-right">
          <Button type="primary" disabled={submitDisabled} loading={submitLoading} onClick={handleSubmitClick}>
            {starling('funds.refund.comm.submit')}
          </Button>
        </div>
      </div>
    )
  }
  return (
    <div className="upay-update">
      <PageModal
        visible={innerVisible}
        contentHeight={params.height}
        mode={false}
        className="upay-update-modal upay-modal"
        footer={renderFooter()}
        closable={false}
        alignCenter={false}
      >
        <div className="upay-modal-header">
          <div className="upay-modal-header-title">{starling('cg.upay.comm.update_pi_title')}</div>
        </div>

        <SecurityTip />
        <RiskAmountHint riskInfo={riskAmountInfo} />

        <Skeleton loading={getCardInfoLoading} animation image={false} text={{ rows: 4 }}>
          <UpdateWidget card={card} form={form} />
        </Skeleton>

        <Policy policyUrl={params.policyUrl} />
      </PageModal>

      <Loading show={posting} showBtn={false} />
      <Status {...statusProps} />
    </div>
  )
}

export default oneTimeHoc(Update)
