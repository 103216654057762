import { useEffect, useState, useMemo } from 'react'
import { PaymentDetail, OrderStatus, chunkString, PipoProvider, formatAmount, BOBanktransferPaymentDetail } from '@/pipo/utils'
import { PullStatusEnum } from '@/components/Status/config'
import { IGetResultRes } from '@/services/types'
import { useStateRealtime } from '@byted/hooks'

export interface ReturnValue {
  paymentDetail: PaymentDetail & {
    amount: string
    expired: boolean
    orderStatus: OrderStatus
    formatCode: string
    upayResult: IGetResultRes
    userIban: string
  }
  onExpire: () => void
}

const useVoucher = (paymentDetail: PaymentDetail, upayResult: IGetResultRes) => {
  const [expired, setExpired] = useState(false)
  const [orderStatus, setOrderStatus, getOrderStatus] = useStateRealtime<OrderStatus>('init')
  const { state } = PipoProvider.useContext()

  const { payment_code: paymentCode } = paymentDetail.present_to_shopper_details || {}

  useEffect(() => {
    // 非终态依赖upayStatus更新状态
    if (!['expired', 'failed', 'success'].includes(orderStatus)) {
      let newOrderStatus: OrderStatus
      switch (upayResult.status) {
        case PullStatusEnum.SUCCESS:
          newOrderStatus = 'success'
          break
        case PullStatusEnum.FAIL:
          newOrderStatus = 'failed'
          break
        case PullStatusEnum.PENDING:
        default:
          newOrderStatus = 'init'
          break
      }
      setOrderStatus(newOrderStatus)
    }
  }, [upayResult.status])

  // 一些数据格式转换
  // 金额
  const amount = useMemo(() => {
    return formatAmount({
      amount: paymentDetail?.amount,
      currency: paymentDetail?.currency,
      locale: state.locale,
    })
  }, [paymentDetail?.currency, paymentDetail?.amount])

  const formatCode = useMemo(() => {
    if (paymentCode && paymentCode.length > 4) {
      return chunkString(paymentCode, 4).join(' ')
    }
    return ''
  }, [paymentCode])

  const switchToExpiredState = () => {
    // 优先显示成功/失败
    if (!['failed', 'success'].includes(getOrderStatus())) {
      setOrderStatus('expired')
      setExpired(true)
    }
  }

  const userIban = useMemo((): string => {
    const chargeDetails = paymentDetail.charge_details
    if (chargeDetails && chargeDetails.payment_method_details) {
      const paymentMethodDetail: BOBanktransferPaymentDetail = JSON.parse(chargeDetails.payment_method_details) as BOBanktransferPaymentDetail
      return paymentMethodDetail.iban
    }
    return ''
  }, [paymentDetail?.charge_details?.payment_method_details])

  const defaultVoucherProps: ReturnValue = {
    // 数据
    paymentDetail: {
      ...paymentDetail,
      amount,
      expired,
      orderStatus,
      formatCode,
      upayResult,
      userIban,
    },
    onExpire: switchToExpiredState,
  }
  return { defaultVoucherProps }
}

export { useVoucher }
