import { PaymentMethodItem } from '../../types'
/**
 * gopay 需要填写的表单的位数
 */
export type GOPAYFormData = {
  card_number?: string
  holder_name?: string
  cvv?: string
  expiration_month?: string
  expiration_year?: string
}

// TODO: Change
export const GOPAY_PAYMENT_PARAMS_MAP: Record<string, string> = {
  card_number: 'eg_ccdc_global_card_number',
  expiration_month: 'eg_ccdc_global_expiration_month',
  expiration_year: 'eg_ccdc_global_expiration_year',
  cvv: 'eg_ccdc_global_cvv',
  holder_name: 'eg_ccdc_global_holder_name',
}

export const GOPAY_PAYMENT_METHOD: PaymentMethodItem = {
  paymentMethodType: {
    configuration: {},
    icon: {
      iconUrl: '',
    },
    starling_key: 'pipo_common_ewallet',
    paymentMethodType: 'E_WALLET',
    title: {
      payment: 'pipo_common_ewallet',
    },
  },
  paymentMethod: {
    configuration: {
      package_name: 'com.gojek.app',
      schema: 'gojek://',
      dark_mode_png_icon_url: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/gopay_e74ce4.png',
      png_icon_url: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/gopay_e74ce4.png',
    },
    paymentMethod: 'GO_PAY',
    paymentMethodId: 'pm_pi_ew_gopay_c_r',
    displayName: '',
    starling_key: 'pipo_payin_payment_main_gopay',
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/gopay_e74ce4.png',
      pngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/gopay_e74ce4.png',
      darkModePngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/gopay_e74ce4.png',
    },
  },
}
