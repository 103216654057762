"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.PIX_PAYMENT_PARAMS_MAP = exports.PIX_PAYMENT_METHOD = void 0;
var PIX_PAYMENT_PARAMS_MAP = {
  holder_name: {
    element: 'eg_pi_bap_pix_c_d_local_br_holder_name',
    is_encrypted: true
  },
  email: {
    element: 'eg_pi_bap_pix_c_d_local_br_email',
    is_encrypted: true
  },
  phone: {
    element: 'eg_pi_bap_pix_c_d_local_br_phone',
    is_encrypted: true
  },
  phone_country_code: {
    element: 'eg_pi_bap_pix_c_d_local_br_phone_country_code',
    is_encrypted: true
  },
  proxy_type: {
    element: 'eg_pi_bap_pix_c_d_local_br_proxy_type',
    is_encrypted: true
  },
  identity: {
    element: 'eg_pi_bap_pix_c_d_local_br_identity',
    is_encrypted: true
  },
  company_id: {
    element: 'eg_pi_bap_pix_c_d_local_br_company_id',
    is_encrypted: true
  },
  billing_country_region: {
    element: 'eg_pi_bap_pix_c_d_local_br_billing_country_region',
    is_encrypted: true
  },
  billing_state: {
    element: 'eg_pi_bap_pix_c_d_local_br_billing_state',
    is_encrypted: true
  },
  billing_city: {
    element: 'eg_pi_bap_pix_c_d_local_br_billing_city',
    is_encrypted: true
  },
  billing_postal_code: {
    element: 'eg_pi_bap_pix_c_d_local_br_billing_postal_code',
    is_encrypted: true
  },
  billing_street: {
    element: 'eg_pi_bap_pix_c_d_local_br_billing_street',
    is_encrypted: true
  }
};
/**
 * Pix PaymentMethodType
 */
exports.PIX_PAYMENT_PARAMS_MAP = PIX_PAYMENT_PARAMS_MAP;
var PIX_PAYMENT_METHOD = {
  paymentMethodType: {
    configuration: {},
    icon: {
      iconUrl: ''
    },
    paymentMethodType: 'BANK_ACCOUNT_PROXY',
    starling_key: 'BANK_ACCOUNT_PROXY'
  },
  paymentMethod: {
    configuration: {},
    paymentMethod: 'PIX',
    paymentMethodId: 'pm_pi_bap_pix_c_d',
    starling_key: 'pipo_payin_payment_main_Pix',
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/pix-light-border_171cd8.svg',
      pngIconUrl: '',
      darkModePngIconUrl: '',
      grayIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/pix-gray_edcae2.svg',
      detailIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/pix-detail_97807d.svg'
    },
    displayName: 'Pix',
    title: {
      payment: 'pipo_payin_new_Pix_payment',
      piListShort: 'pipo_common_payin_pix_add',
      piListLong: 'pipo_common_payin_pix_add'
    }
  }
};
exports.PIX_PAYMENT_METHOD = PIX_PAYMENT_METHOD;