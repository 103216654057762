/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect } from 'react'
import { FieldError } from 'react-hook-form'

import { Empty, Select } from '@arco-design/web-react'
import { IconDown } from '@arco-design/web-react/icon'

import { IBankListItem } from '@/services/types'
import starling from '@/utils/starling'

import { ItemBlock } from '../index'

interface IProps {
  title?: string
  value?: string
  len?: 'half' | 'full'
  onChange: (e: string) => void
  error?: FieldError
  errorMessage?: string
  placeholder?: string
  bankList?: IBankListItem[]
}

export const BankListSelect = React.forwardRef((props: IProps, ref: any) => {
  const { title, value, len = 'full', onChange, error, errorMessage, placeholder, bankList } = props

  useEffect(() => {
    // 默认选中第一个银行吧
    if (!value && bankList?.[0]?.value) {
      setTimeout(() => onChange(bankList?.[0].value), 0)
    }
  }, [bankList])

  return (
    <ItemBlock style={len} title={title} errorState={Boolean(error)} errorMessage={errorMessage}>
      <Select
        size="large"
        ref={ref}
        showSearch
        value={value}
        placeholder={placeholder}
        loading={!bankList}
        onChange={onChange}
        arrowIcon={<IconDown />}
        notFoundContent={<Empty description="N/A" />}
        filterOption={(inputValue, option) => {
          return (option.key as string).toLowerCase().includes(inputValue.toLowerCase())
        }}
      >
        {(bankList || []).map((bank) => (
          <Select.Option key={bank.name} value={bank.value}>
            <div className="upay-bank-list-select-item">
              {bank.icon_url && <img className="upay-bank-list-select-icon" src={bank.icon_url} alt={bank.name} />}
              <span className="upay-bank-list-select-name">
                {starling(`funds.check_out.comm.channel_bank_name_${bank.value}` as any, {}, bank.name)}
              </span>
            </div>
          </Select.Option>
        ))}
      </Select>
    </ItemBlock>
  )
})
