import React, { useMemo } from 'react'

import { Radio } from '@arco-design/web-react'

import starling from '@/utils/starling'

import { ItemBlock } from '../item-block'

interface IProps {
  title?: string
  value?: string
  onChange: (e: string) => void
  enableOption?: string[]
}

export const AccountType = React.forwardRef((props: IProps, ref: any) => {
  const { value, onChange, title, enableOption = ['b', 'c'] } = props

  const optionList = useMemo(() => {
    const optionList = []
    enableOption.includes('c') && optionList.push({ value: '1', label: starling('funds.refund.comm.system_bankaccount_personal') })
    enableOption.includes('b') && optionList.push({ value: '0', label: starling('funds.refund.comm.system_bankaccount_business') })

    return optionList
  }, [enableOption])

  const handleChange = (e: string) => {
    onChange(e)
  }

  return (
    <ItemBlock style="full" title={title}>
      <Radio.Group value={value} onChange={handleChange}>
        {optionList.map((option) => {
          return (
            <Radio key={option.value} value={option.value}>
              {option.label}
            </Radio>
          )
        })}
      </Radio.Group>
    </ItemBlock>
  )
})
