import React from 'react'
import { FieldError, Noop } from 'react-hook-form'

import { Input, Tooltip } from '@arco-design/web-react'
import { IconQuestionCircle } from '@arco-design/web-react/icon'
import { omit } from 'lodash'

import { FILL_BY_POSTAL_CODE_REGION, POSTAL_CODE_HELP_URL } from '@/pipo/hooks/use-fill-by-postal-code'
import { validator } from '@/pipo/utils'
import utils from '@/utils'
import slardar from '@/utils/slardar'
import starling from '@/utils/starling'

import { ItemBlock } from '../index'

interface IProps {
  len?: 'half' | 'full'
  title: string
  maxLength?: number
  placeholder?: string
  error?: FieldError
  errorMessage: string
  value?: string
  countryCode?: string
  onChange: (val: string) => void
  onBlur: Noop
  handlePostalCodeBlur: () => void
  handlePostalCodeChange: () => void
}

const prefix = 'pipo-mobile-postal-code'

export const PostalCode = React.forwardRef((props: IProps, ref: any) => {
  const {
    len = 'half',
    title,
    maxLength,
    placeholder,
    error,
    errorMessage,
    value,
    countryCode = '',
    onChange,
    onBlur,
    handlePostalCodeBlur,
    handlePostalCodeChange,
  } = props
  const handleChange = (val: string, callback: (val: string) => void) => {
    if (validator.postalcodeRegex.test(val)) {
      callback(val.toUpperCase().replaceAll(/\s|-/g, ''))
    }
    handlePostalCodeChange()
  }
  const showAutoFillTip = !!FILL_BY_POSTAL_CODE_REGION[countryCode]
  const showHelpUrl = !!POSTAL_CODE_HELP_URL[countryCode]

  const handleClick = () => {
    utils.openWindow(POSTAL_CODE_HELP_URL[countryCode])
    slardar.timer('postalCodeHelpClick', 1, { countryCode })
  }

  return (
    <ItemBlock
      style={len}
      title={
        showAutoFillTip || showHelpUrl ? (
          <Tooltip
            className={`${prefix}-tooltip`}
            color="#fff"
            position="tl"
            trigger="hover"
            content={
              <>
                {showAutoFillTip && <span>{starling('cg.upay.comm.autofilled_by_zipcode_prompt')}</span>}
                {showHelpUrl && <a onClick={handleClick}>{starling('cg.upay.comm.find_zipcode')}</a>}
              </>
            }
          >
            <span>{title}</span>
            <IconQuestionCircle className={`${prefix}-tooltip-icon`} />
          </Tooltip>
        ) : (
          title
        )
      }
      errorState={Boolean(error)}
      errorMessage={errorMessage}
    >
      <Input
        {...omit(props, ['len', 'errorMessage'])}
        ref={ref}
        className={`${prefix} oo-block`}
        size="large"
        allowClear
        value={value}
        error={Boolean(error)}
        placeholder={placeholder}
        maxLength={maxLength}
        onChange={(val) => {
          handleChange(val, onChange)
        }}
        onBlur={() => {
          onBlur()
          handlePostalCodeBlur()
        }}
      />
    </ItemBlock>
  )
})
