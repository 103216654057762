"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault")["default"];
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.formatter = formatter;
exports.placeholder = exports.isValid = void 0;
var _map = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/map"));
var _filter = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/filter"));
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/slicedToArray"));
var _zip2 = _interopRequireDefault(require("lodash/zip"));
var _formatter = require("./formatter");
var _cpfCnpjValidator = require("cpf-cnpj-validator");
// 11438374798000 => 114.383.747-98
// 11438374798    => 114.383.747-98
// 114383747      => 114.383.747
// 11438374       => 114.383.74
// 114383         => 114.383
function formatter() {
  var _context;
  var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var separators = ['', '.', '.', '-'];
  var chunkSizes = [3, 3, 3, 2];
  var numeric = (0, _formatter.pickNumeric)(value);
  var pairs = (0, _zip2["default"])(separators, (0, _formatter.chunk)(numeric, chunkSizes));
  var result = (0, _map["default"])(_context = (0, _filter["default"])(pairs).call(pairs, function (_ref) {
    var _ref2 = (0, _slicedToArray2["default"])(_ref, 2),
      _ = _ref2[0],
      split = _ref2[1];
    return Boolean(split);
  })).call(_context, function (_ref3) {
    var _ref4 = (0, _slicedToArray2["default"])(_ref3, 2),
      separator = _ref4[0],
      split = _ref4[1];
    return separator + split;
  }).join('');
  return result;
}
var isValid = _cpfCnpjValidator.cpf.isValid;
exports.isValid = isValid;
var placeholder = '000.000.000-00';
exports.placeholder = placeholder;