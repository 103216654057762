"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.PAYCELL_PAYMENT_PARAMS_MAP = exports.PAYCELL_PAYMENT_METHOD = void 0;
var PAYCELL_PAYMENT_PARAMS_MAP = {
  identity: {
    element: 'eg_pi_ew_paycell_c_d_local_tr_identity',
    is_encrypted: true
  },
  mobile_phone: {
    element: 'eg_pi_ew_paycell_c_d_local_tr_mobile_phone',
    is_encrypted: true
  },
  phone: {
    element: 'eg_pi_ew_paycell_c_d_local_tr_phone',
    is_encrypted: true
  },
  phone_country_code: {
    element: 'eg_pi_ew_paycell_c_d_local_tr_phone_country_code',
    is_encrypted: true
  }
};
exports.PAYCELL_PAYMENT_PARAMS_MAP = PAYCELL_PAYMENT_PARAMS_MAP;
var PAYCELL_PAYMENT_METHOD = {
  paymentMethodType: {
    configuration: {},
    icon: {
      iconUrl: ''
    },
    paymentMethodType: 'E_WALLET',
    starling_key: 'E_WALLET'
  },
  paymentMethod: {
    configuration: {},
    paymentMethod: 'Paycell',
    paymentMethodId: 'pm_pi_ew_paycell_c_d',
    starling_key: '',
    icon: {
      iconUrl: '',
      pngIconUrl: '',
      darkModePngIconUrl: '',
      grayIconUrl: '',
      detailIconUrl: ''
    },
    displayName: 'Paycell',
    title: {
      payment: '',
      piListShort: '',
      piListLong: ''
    }
  }
};
exports.PAYCELL_PAYMENT_METHOD = PAYCELL_PAYMENT_METHOD;