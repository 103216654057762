import React, { useContext } from 'react'

import { ParamsContext } from '@/stores'
import starling from '@/utils/starling'

const BindTitle: React.FC = () => {
  const { params } = useContext(ParamsContext)

  return (
    <>
      {!params.hideTitle && (
        <div className="upay-modal-header">
          <div className="upay-modal-header-title">{starling('funds.refund.comm.add_withdrawal_method')}</div>
        </div>
      )}
    </>
  )
}

export default BindTitle
