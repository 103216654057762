import { useContext, useEffect, useState } from 'react'

import { Message } from '@arco-design/web-react'

import { PAYMENT } from '@/constant'
import api from '@/services'
import { CardListRes } from '@/services/types'
import { ParamsContext } from '@/stores'
import SafeJSON from '@/utils/SafeJSON'

interface IProps {
  visible: boolean
  setErrorMsg: (msg: string) => void
}
export const useChangeCards = (props: IProps) => {
  const { visible, setErrorMsg } = props
  const { urlQuery } = useContext(ParamsContext)
  const { token } = urlQuery
  const [cards, setCards] = useState<CardListRes[]>([])
  const [cardsReady, setCardsReady] = useState(false)

  useEffect(() => {
    visible && getCardList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  const getCardList = () => {
    api
      .getCardList({ token, type: PAYMENT.PRE_PAY })
      .then((res) => {
        setCardsReady(true)
        setCards(res.list || [])
      })
      .catch((e) => {
        setErrorMsg(e?.msg || SafeJSON.stringify(e))
        Message.error(e?.msg || SafeJSON.stringify(e))
      })
  }

  return { getCardList, cards, cardsReady }
}
