import React from 'react'

import ErrorSvg from '@/static/svg/error.svg'

import './index.less'

const Error = (props: {
  handleClick: () => void
  content: {
    errorContent: string
    btnContent: string
  }
}): JSX.Element => {
  const {
    handleClick,
    content: { errorContent, btnContent },
  } = props

  return (
    <div className="upay-error">
      <img src={ErrorSvg} className="upay-error__img" />
      <div>
        {errorContent && <span className="upay-error__info">{errorContent}</span>}
        <span className="upay-error__btn" onClick={handleClick}>
          {btnContent}
        </span>
      </div>
    </div>
  )
}

export default Error
