import { useContext } from 'react'

import { useRequest } from '@byted/hooks'

import { RESPONSE_CODE } from '@/constant'
import api from '@/services'
import { ParamsContext } from '@/stores'

interface IProps {
  cardId: string
}
export const useCardInfo = (props: IProps) => {
  const { cardId } = props
  const {
    urlQuery: { token },
  } = useContext(ParamsContext)

  const { data, loading } = useRequest(
    () => {
      return api
        .getCardInfo({
          id: cardId,
          token,
        })
        .then((res) => {
          if (res?.code !== RESPONSE_CODE.SUCCESS) throw res?.msg || 'error'
          return res?.data
        })
    },
    { auto: true, ready: !!cardId, refreshDeps: [cardId] },
  )

  return { card: data, getCardInfoLoading: loading }
}
