import React from 'react'

/** 捕获组件错误后reset */
class ErrorReset extends React.Component<any, { hasError: boolean; resetKey: number }> {
  constructor(props: { children: React.ReactNode }) {
    super(props)
    this.state = { hasError: false, resetKey: Date.now() }
  }

  static getDerivedStateFromError() {
    // 更新 state 使下一次渲染能够显示降级后的 UI
    return { hasError: true, resetKey: Date.now() }
  }

  componentDidCatch(error: Error) {
    console.log(error?.name, error?.message)
  }

  render() {
    if (this.state.hasError) {
      // 你可以自定义降级后的 UI 并渲染
      return React.cloneElement(this.props.children, { key: this.state.resetKey })
    }

    return this.props.children
  }
}

export default ErrorReset
