import React from 'react'

import { Checkbox } from '@arco-design/web-react'

interface IProps {
  title: string
  storeCard: boolean
  setStoreCard: React.Dispatch<React.SetStateAction<boolean>>
  marginTopStyle?: string
}

export const SaveBox = (props: IProps) => {
  const { title, storeCard, setStoreCard, marginTopStyle } = props
  return (
    <div
      style={{
        display: 'flex',
        marginRight: 24,
        marginTop: marginTopStyle || 0,
      }}
    >
      {/* TODO: padding? */}
      <Checkbox
        style={{ paddingLeft: 0 }}
        onChange={(value) => {
          setStoreCard(value)
        }}
        checked={storeCard}
      >
        {title}
      </Checkbox>
    </div>
  )
}
