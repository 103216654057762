"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.isGeneralCCDC = isGeneralCCDC;
var _types = require("./types");
/**
 * 该函数用于判断一个CCDC支付方式，是否属于通用的CCDC处理逻辑，如在收银台合并为一个支付选项、使用标准的卡信息填写表单。
 * 对于paymentMethodType为CCDC，且IntegrationMethod为redirect的支付方式，在收银台上需要单独展示支付选项。
 * 在其被选中并点击支付后，跳至第三方页面（例如填写卡信息）
 * @param paymentMethodType
 * @returns {boolean}
 */
function isGeneralCCDC(paymentMethod) {
  return paymentMethod.paymentMethodType.paymentMethodType === 'CCDC' && paymentMethod.paymentMethod.integrationMethod !== _types.IntegrationMethod.redirect;
}