import { OrderStatus, PaymentDetail, PaymentMethodInfo, PaymentMethodItem, SupportPaymentMethodId, SupportPaymentMethodType } from '@upay/pipo-utils'

import { IGetResultRes } from '@/services/types'

import { BoletoProps, CCDCProps, OxxoFormData, PixProps } from '.'
import { BanktransferFormData } from './payment-methods-config/banktransfer'
import { CCDCFormData } from './payment-methods-config/ccdc'
import { OVODirectFormData, OVORedirectFormData } from './payment-methods-config/ovo'
import { PAYEASYFormData } from './payment-methods-config/payeasy'
import { QIWIFormData } from './payment-methods-config/qiwi'
import { ShopeePayFormData } from './payment-methods-config/shopeepay'

export { PaymentMethodItem, SupportPaymentMethodId, OrderStatus, PaymentDetail, PaymentMethodInfo }

// Doc: https://bytedance.feishu.cn/docs/doccnezWowX28BJw5hZyv6mco6f#7acm2P
export enum SupportedModel {
  Pay = 'pay', // 直接支付--渠道侧针对该支付方式并不存在token绑定
  PayOnToken = 'pay_on_token', // 先绑后付--使用该支付方式时，必须携带已绑定的token
  TokenizeAndPay = 'tokenize_and_pay', // 支付并绑定，支付和绑定可以在一个流程中完成，没有依赖关系（即使用该支付方式完成支付后，能够获得一个token）
}

type Icon = {
  iconUrl: string
  pngIconUrl?: string
  darkModePngIconUrl?: string
  voucherIconUrl?: string
  detailIconUrl?: string
  grayIconUrl?: string
}

type Config = {
  icon: Icon
  starling_key: string
  displayName?: string
  title?: {
    payment?: string
    piListShort?: string
    piListLong?: string
  }
  piListForm?: boolean // 标记在 pi 页面是否需要展开表单，适用于有表单的先绑后付支付方式，如 ovo_c_r
  availableExpired?: boolean // 标记支付时，过期的支付方式是否可用，即不作置灰处理，如 ovo_c_r
}
/**
 * PaymentMethods.Item (Meta Data)
 */

type StringUnknownConfig = Record<string, unknown>

export enum IntegrationMethod {
  redirect = 'redirect',
  direct = 'direct',
}

/**
 * All onSubmit callbacks share this as type of their parameters
 */

export interface PipoSubmitParams {
  formData: Record<string, string>
  paymentMethodType: SupportPaymentMethodType
  paymentMethod: {
    paymentMethod: string
    paymentMethodId: SupportPaymentMethodId
  }
  paymentParams: PaymentParamsItem[]
  extraParams?: {
    /** DirectDebit EU 专属参数 */
    mandateInfo?: any[]
  }
  storeCard?: boolean
  token?: string
  // 当前表单需要收集地址时有此项，可供外部埋点使用
  fillAddressState?: {
    metrics: {
      [key: string]: any
    }
    categories: {
      [key: string]: any
    }
  }
}

export type PaymentParamsItem = {
  element: string
  param_name: string
  param_value: string
  is_encrypted?: boolean
}

/**
 * Basic types of PaymentMethods
 */
export type BasePaymentMethodsProps = {
  paymentMethodsList: {
    paymentMethodId: SupportPaymentMethodId
    elements?: string[]
    model?: SupportedModel
  }[]
  storedPaymentMethodsList: {
    paymentMethodId: SupportPaymentMethodId
    title: string
    token: string
    isPrimary?: boolean
    expiryDate?: string
    isExpired?: boolean
    balance?: string
  }[]
  mergedPaymentMethodsList?: SupportPaymentMethodType[]
}

/**
 * PaymentMethodsConfiguration
 * Configure each payment method displayed on the Drop-in
 */
export type PaymentMethodsConfiguration = {
  [key in SupportPaymentMethodId]?: {
    bindLimit?: number // -1 means no limit
  }
}

/**
 * Types of PaymentMethod.Item UI
 */
export type UIOtherPaymentMethodPayItem = {
  token?: string
} & OtherPaymentMethodItem

export type UIOtherPaymentMethodItem = {
  subPaymentMethodList: UIOtherPaymentMethodPayItem[]
  needMerge: boolean
  needExpand: boolean
  elements?: string[]
  model?: SupportedModel
  iconList?: string[]
  token?: string
  key?: number
  btnText?: string
} & UIOtherPaymentMethodPayItem

export type UIStoredPaymentMethodItem = {
  needExpand?: boolean
} & StoredPaymentMethodItem

export type UIPaymentMethodItem = UIStoredPaymentMethodItem | UIOtherPaymentMethodItem

/**
 * OtherPaymentList.Item
 */

export interface OtherPaymentMethodItem<
  PaymentMethodConfig extends StringUnknownConfig = StringUnknownConfig,
  PaymentMethodTypeConfig extends StringUnknownConfig = StringUnknownConfig,
> extends PaymentMethodItem<PaymentMethodConfig, PaymentMethodTypeConfig> {
  type: 'others' | 'stored'
}

/**
 * StoredPaymentMethod.Item
 */

export interface StoredPaymentMethodItem<
  PaymentMethodConfig extends StringUnknownConfig = StringUnknownConfig,
  PaymentMethodTypeConfig extends StringUnknownConfig = StringUnknownConfig,
> extends OtherPaymentMethodItem<PaymentMethodConfig, PaymentMethodTypeConfig> {
  token?: string
  title?: string
  isPrimary?: boolean
  expiryDate?: string
  isExpired?: boolean
  balance?: string
}

export type RouterPath = 'ccdc' | 'boleto' | 'pix' | 'oxxo' | 'voucher' | 'payment-methods' | 'pi-list' | 'pi-detail' | 'direct-debit'

export type ActionType = 'route' | 'redirect'

export type RouterParams = Partial<
  CCDCProps &
    BoletoProps &
    PixProps &
    PIDetailProps &
    VoucherProps &
    BasePaymentMethodsProps & {
      isValid?: boolean
      // TODO: 统一命名
      paymentMethodList: {
        paymentMethodId: SupportPaymentMethodId
        elements?: string[]
      }[]
    }
>

export interface PipoAction {
  type: ActionType
  payload: {
    url?: string
    path?: RouterPath
    params?: RouterParams
  }
  paymentMethodItem?: PaymentMethodItem
}

export interface PipoComponentRef {
  handleAction: (action: PipoAction) => void
  submit?: () => void
  validateSubmitData?: (() => Promise<PipoSubmitParams>) | undefined
}

/**
 * Address types
 */
export interface LocationItem {
  asci_name: string
  code: string
  geoname_id: number
  level: string
  name: string
  sub_region: LocationItem[]
}

export interface IRenderDistrictData {
  isLabel?: boolean
  label?: string
  level?: number
  data?: IDistrictData
  displayFields?: string
  key?: string
}

export interface IDistrictData {
  code: string
  name: string
  geoname_id: number
  asci_name: string
  level: string
  center?: Array<number>
}

export type FetchDistrictFunc = (geonameid: any, selectedArr?: any) => Promise<LocationItem[]>

export type IBillingAddress = {
  billing_country_region: string
  billing_state: string
  billing_city: string
  billing_street: string
  billing_postal_code: string
}

export type InputHandleRef = {
  clear: () => void
  setInnerValue: (value: string) => void
}

export type PaymentMethodFormData = CCDCFormData &
  OVODirectFormData &
  OVORedirectFormData &
  QIWIFormData &
  BanktransferFormData &
  PAYEASYFormData &
  ShopeePayFormData &
  OxxoFormData

export interface PIDetailProps {
  showSetPrimaryButton?: boolean
  detail: StoredPaymentMethodItem
  onSetPrimary: (token: string) => Promise<boolean>
  onRemove: (token: string) => Promise<boolean>
}

export interface VoucherProps {
  paymentDetail: PaymentDetail
  upayResult: IGetResultRes
  onClickBackButton?: () => void
}

export type MobileIcon = 'bank' | 'phone' | 'right'

export type PcIcon = 'bank' | 'atm' | 'phone'

export type BankGuideKeyType = 'mobile' | 'internet' | 'atm' | 'otherbank'
export interface VoucherGuide {
  subTitleValue?: string
  starlingKey?: string
  mobileIcon?: MobileIcon
  pcIcon?: PcIcon
  // TODO: fix type
  contentStarlingKey?: string
  contentValue?: string
  defaultActive?: boolean
  bankGuideKey?: BankGuideKeyType
}

export interface ConfigType {
  [key: string]: {
    collapsable: boolean
    methods: VoucherGuide[]
  }
}

export interface BOBanktransferFormProps<T, U = T> {
  value?: T & U
  onValidate: (isValid: boolean) => void
  onChange: (formData: T & U, params?: PipoSubmitParams) => void
}
export interface USBanktransferFormProps {
  onValidate: (isValid: boolean) => void
}

export interface JPBanktransferFormProps {
  onValidate?: (isValid: boolean) => void
  showSavingBox?: boolean
}

export interface BOBanktransferPaymentDetail {
  holder_first_name?: string
  holder_last_name?: string
  iban: string
  identity?: string
  method_type?: string
  payment_method?: string
  payment_method_id?: string
  type?: string
}

export interface StoreCard {
  storeCard?: boolean
}

export interface BaseFormProps<T, U = T> {
  value?: T & U
  onValidate: (isValid: boolean) => void
  onChange: (formData: T & U, params?: PipoSubmitParams) => void
}

export interface StoreFormProps<T> extends BaseFormProps<T, StoreCard> {
  showSavingBox?: boolean
}

export interface IPaymentParamsMapItem {
  element: string
  is_encrypted?: boolean
}
