import React from 'react'

import { IFormBuilderItemProps } from '@/components/FormBuilder'
import { validator } from '@/pipo/utils'
import starling from '@/utils/starling'

import { Email, HolderName, ItemInput } from '../../inner-components'

export const SCHEMA_A = ({
  t,
}: {
  t: (
    key: string,
    replaceItem?:
      | {
          value: string
        }
      | undefined,
  ) => string
}): IFormBuilderItemProps[] => [
  {
    field: 'holder_name',
    widget: HolderName,
    widgetProps: {
      title: t('pipo_common_payin_boleto_name'),
      placeholder: t('pipo_common_payin_boleto_name'),
      len: 'half',
    },
    rules: { required: true, pattern: validator.nameRegex },
  } as IFormBuilderItemProps<typeof HolderName>,
  {
    field: 'identity',
    widget: ItemInput,
    widgetProps: {
      title: starling('funds.check_out.oxxo_curprfc'),
      placeholder: starling('funds.check_out.oxxo_curprfc'),
      tips: starling('funds.check_out.oxxo_ID_tips')
        .split('\n')
        .map((item: string) => React.createElement('div', { style: { textAlign: 'left' } }, item)),
      len: 'half',
      maxLength: 18,
    },
    rules: { required: true, pattern: validator.OXXOidRegex },
  } as IFormBuilderItemProps<typeof ItemInput>,
]

export const SCHEMA_B = ({
  t,
}: {
  t: (
    key: string,
    replaceItem?:
      | {
          value: string
        }
      | undefined,
  ) => string
}): IFormBuilderItemProps[] => [
  {
    field: 'email',
    widget: Email,
    widgetProps: {
      title: t('pipo_common_payin_Email'),
      placeholder: t('pipo_common_payin_ph_email'),
      blockStyle: 'half',
    },
    rules: { required: true, pattern: validator.emailRegex },
  } as IFormBuilderItemProps<typeof Email>,
]
