import { IdcT } from '@upay/utils/es/types'

import { LOCATION_SG_DOMAIN, LOCATION_VA_DOMAIN } from '@/constant/row.config'
import { getPostalCodeRules } from '@/pipo/hooks/use-postal-code'
import { getLang } from '@/utils/getLang'
import slardar from '@/utils/slardar'

import api from '.'
import { IPostalCodeRes } from './types'

const LocationURLBase = {
  sg: LOCATION_SG_DOMAIN,
  va: LOCATION_VA_DOMAIN,
  oci: LOCATION_VA_DOMAIN,
}
const LocationAPIKey = 'ceb6c970658f31504a901b89dcd3e461'

export const servicesUtils = {
  /**
   * 根据邮编反查地址
   * @param postcode 邮编
   * @param country_code 国家code
   * @returns 从大到小的地址数组
   */
  getAddressByPostalCode: async (postcode: string, country_code: string, idc: IdcT) => {
    try {
      // 邮编反查接口文档：https://cloud.bytedance.net/bam/rd/toutiao.location.postcode/api_doc/show_doc
      const getAddress = api.customAPI<IPostalCodeRes>({ url: `${LocationURLBase[idc]}/postcode/postcode`, method: 'GET' })
      const res = (await getAddress(
        {
          key: LocationAPIKey,
          language: getLang(),
          address_search_level: 0,
          postcode_match_type: 2,
          country_code,
          postcode,
          page: 0,
          page_size: 30,
        },
        {
          checkResponse: (res: any) => {
            console.log('checkResponse', res, !res || res.status !== '1' || !res.post_infos || res.post_infos.length <= 0)
            if (!res || res.status !== '1' || !res.post_infos || res.post_infos.length <= 0) {
              return { msg: res?.info, code: res?.status }
            }
          },
          withError: false,
          headers: { accept: 'application/json' },
        },
      )) as IPostalCodeRes
      const addressResArr: string[] = []
      // 粒度聚合到2极地址
      res.post_infos.forEach((info: any) => {
        const address = info.adm1.name + ',' + info.adm2.name + ',' + info.adm3.name
        if (!addressResArr.includes(address)) addressResArr.push(address)
      })
      return addressResArr.map((item) => item.split(','))
    } catch (error) {
      return []
    }
  },
  getContractAddress: async (token: string) => {
    try {
      const res = await api.getContractAddress({ token })

      const addressList = res.list || []
      // 对地址结果进行简单过滤
      const availableAddressList = addressList.filter(
        (v) =>
          v.completeBillingAddress &&
          v.countryCode &&
          v.detailAddress &&
          v.level2Name &&
          v.level3Name &&
          v.postcode &&
          new RegExp(getPostalCodeRules(v.countryCode)).test(v.postcode),
      )
      // 打点上报
      slardar.timer('contractAddressResultEvent', { length: addressList.length, availableLength: availableAddressList.length })
      return availableAddressList
    } catch (error: any) {
      console.error('fetchContractAddress Error', error)
      return []
    }
  },
}
