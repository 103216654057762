import React, { forwardRef } from 'react'

import { PAYOUT_WAY_TYPE } from '@/constant/payout'
import { usePayoutBindElements } from '@/hooks'
import { LocalBankTransfer, LocalBankTransferFormProps } from '@/pipo/pc-payout'
import { PayoutComponentRef } from '@/pipo/pc-payout/types'
import { IPayoutGetBindElementsProps } from '@/services/payout-types'

// 对表单组件的二次封装 补充额外属性
const LocalBankTransferWidget = forwardRef((props: Omit<LocalBankTransferFormProps, 'bankList'>, ref: React.ForwardedRef<PayoutComponentRef>) => {
  const { getBankListAsync, bankList, getBankListLoading } = usePayoutBindElements()

  const refreshBankList = (userType: IPayoutGetBindElementsProps['userType']) => {
    getBankListAsync({ payWay: PAYOUT_WAY_TYPE.LOCAL_BANK_TRANSFER, userType })
  }

  return <LocalBankTransfer bankList={getBankListLoading ? undefined : bankList} {...props} refreshBankList={refreshBankList} ref={ref} />
})

export default LocalBankTransferWidget
