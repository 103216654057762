import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { Alert, Button, Skeleton } from '@arco-design/web-react'
import { StatusActionTypeEnum, StatusTypeEnum } from '@upay/utils/es/types'

import { Channel, Loading, PageModal, Policy, SecurityTip, Status } from '@/components'
import ChannelItemTitle from '@/components/ChannelItemTitle'
import CheckOTPModal from '@/components/CheckOTPModal'
import { useCheckOTPModal } from '@/components/CheckOTPModal/useCheckOTPModal'
import HighlightStarlingKey from '@/components/HighLightStarlingKey'
import { IHintInfo } from '@/components/Hint/interface'
import { IOpenStatusProps, IStatusCloseCallbackProps } from '@/components/Status/config'
import useStatus from '@/components/Status/useStatus'
import VersionReminder from '@/components/VersionReminder'
import { EVENT_TYPE, FROM_PAGE_ENUM, NEW_SDK_TERMINAL_EQUIP, PAYMENT, PUBSUB_TYPES, SUBMIT_RECORD_TYPE } from '@/constant'
import { useNewWindow, usePostMessage } from '@/hooks'
import { useReportLoadTime } from '@/hooks/useReportLoadTime'
import useNewPaymentsMethod from '@/payments/NewPayments/useNewPaymentsMethod'
import { IPaymentMethodsPropsBasic } from '@/payments/interface'
import paymentChannelPreHandler from '@/payments/paymentsPrehandler'
import api from '@/services'
import { ChannelRes, IBindCardParams, IBindCardSupplementParams } from '@/services/types'
import { Warning } from '@/static/icon'
import { ParamsContext } from '@/stores'
import GlobalInfo from '@/stores/GlobalInfo'
import { ModeTypeEnum } from '@/typings'
import utils from '@/utils'
import SafeJSON from '@/utils/SafeJSON'
import slardar from '@/utils/slardar'
import starling from '@/utils/starling'
import teaClient from '@/utils/tea'

import { PaymentSubmitMethodEnum } from '../PayNext/interface'
import useBindRecorder from './hooks/useBindRecorder'

import './index.less'

interface IProps {
  /** 是否打开 */
  visible?: boolean
  /**
   * 关闭Create弹窗回调。回调入参为isSubmit-是否提交成功
   */
  onClose?: (isSubmit: boolean) => void
  /** 针对新 SDK 交互多实例需要做一个区分 */
  from: FROM_PAGE_ENUM
}

const Create: FC<IProps> = (props) => {
  const PID = props.from || 'create'
  const { params, urlQuery, uPubSub, unique, initStartTime } = useContext(ParamsContext)
  const isIndependentPage = props.from === FROM_PAGE_ENUM.CREATE && params.mode === ModeTypeEnum.HostedPage
  const {
    data: { sessionId, channelFingerprint, lastBindCardId, riskInfoObj },
    utils: { trackSelectChannel, trackViewChannel, submitRecord },
  } = useContext(GlobalInfo)
  const { token, bizId, type, accountId } = urlQuery
  const defaultVisible = useMemo(() => props.visible ?? params.defaultVisible, [props.visible, params.defaultVisible])
  const [visible, setVisible] = useState(defaultVisible)
  // 支付方式列表
  const [list, setList] = useState<ChannelRes[]>([])
  // 绑卡时扣款校验信息
  const [hintInfo, setHintInfo] = useState<IHintInfo>({})
  // 已选支付方式
  const [radioValue, setRadioValue] = useState<number>()
  // 是否展示loading
  const [loading, setLoading] = useState<boolean>(false)
  // Loading是否展示cancel按钮
  const [showCancelLoadingBtn, setShowCancelLoadingBtn] = useState<boolean>(true)
  // 绑卡渠道基础能力
  const {
    renderPaymentsExtra,
    getPaymentRefByPayWay,
    valid: { newPaymentStatus, clearNewPaymentStatus },
  } = useNewPaymentsMethod()
  //选中的渠道的Ref
  const getSelectedBindChannelRef = useCallback(() => getPaymentRefByPayWay(radioValue), [radioValue, getPaymentRefByPayWay])

  const [linkOpen, linkClose] = useNewWindow(() => {
    setLoading(false)
    setVisible(true)
  })
  const { statusProps, openStatus } = useStatus()

  // OTP验证Modal
  const { openCheckOTPModal, checkOTPModalProps } = useCheckOTPModal({
    onCancel: () => {
      handleLoadingCancel()
    },
    handleBillStatus: (props) => handleBillStatus(props),
  })

  // 加载耗时上报
  const reportLoadtime = useReportLoadTime('create')
  // 录制用户操作
  useBindRecorder({
    from: props.from,
    visible,
  })
  usePostMessage(async (postData) => {
    const { upayType, query } = postData
    const { error_msg, bind_card_status, payment_status, from, error_code } = query
    // 来自result结果页的通知
    if (upayType === PUBSUB_TYPES['3DS_RESULT'] && from === props.from) {
      const isSuccess = [StatusTypeEnum.SUCCEED, StatusTypeEnum.SUCCESS].includes(bind_card_status || payment_status)
      slardar.timer('createOPENstatus', 1, {
        from: props.from,
        to: 'status',
        type: 'status_modal',
        action: StatusActionTypeEnum.BIND,
        desc: bind_card_status || payment_status,
        error: error_msg || '',
      })
      handleBillStatus({
        actionType: StatusActionTypeEnum.BIND,
        status: isSuccess ? StatusTypeEnum.SUCCESS : StatusTypeEnum.FAIL,
        errMsg: error_msg,
        pipoErrorCode: error_code,
      })
    }
  })

  const initData = useCallback(async () => {
    const initStartTime = Date.now()

    const res = await api.getPayWays(
      {
        token: token,
        eventType: EVENT_TYPE.BIND,
        riskInfo: SafeJSON.stringify(riskInfoObj),
        terminalEquip: NEW_SDK_TERMINAL_EQUIP,
      },
      {
        extraHandle: {
          uPubSub,
        },
        bizId: bizId,
      },
    )
    const list = await paymentChannelPreHandler(res.list || [])
    setList(list)
    if (list.length === 1 && !list[0].disable) {
      handleChangeRadio(list[0])
    }
    reportLoadtime(initStartTime)
  }, [token, riskInfoObj, uPubSub, bizId, reportLoadtime])

  const clearPage = () => {
    setList([])
    setHintInfo({})
    setRadioValue(undefined)
    clearNewPaymentStatus()
  }

  const handleChangeRadio = (radioItem?: ChannelRes) => {
    if (!radioItem || Number(radioItem.payWay) === radioValue) return

    setRadioValue(Number(radioItem.payWay))
    trackSelectChannel(Number(radioItem.payWay))
    setHintInfo({
      amount: radioItem.riskAmount,
      currency: radioItem.riskAmountCurrency,
      displayAmount: radioItem.displayRiskAmount,
    })

    teaClient.sendCreatePageClick({ click_item: 'sec_pi_choose', pay_way: Number(radioItem.payWay) })
  }

  const handleBillStatus = async (props: IOpenStatusProps) => {
    linkClose()
    // 即将展示状态，不希望用户中途取消
    setShowCancelLoadingBtn(false)
    props.onClose = handleResultClose
    props.payWay = radioValue

    teaClient.sendResultPageView({ pay_way: radioValue || -1, result_status: props.status })
    await openStatus(props)
    setLoading(false)
    setShowCancelLoadingBtn(true)
  }

  const handleResultClose = ({ status }: IStatusCloseCallbackProps) => {
    teaClient.sendResultPageClick({ pay_way: radioValue || -1, click_item: 'back' })

    const isSuccess = [StatusTypeEnum.SUCCEED, StatusTypeEnum.SUCCESS].includes(status)
    if (isSuccess) {
      clearPage()
      props.onClose?.(true)
    } else {
      setVisible(true)
    }
  }

  // 支付禁用态管理
  const disabled = useMemo(() => {
    // 以下情况需要禁用
    // 不显示时提交按钮置灰
    if (!visible) return true
    // 缺少选中的支付方式
    if (typeof radioValue === 'undefined') return true
    // 使用PIPO表单提交，但验证填写无效
    if (typeof newPaymentStatus[`${radioValue}`]?.valid === 'boolean' && !newPaymentStatus[`${radioValue}`].valid) return true
    return false
  }, [visible, radioValue, newPaymentStatus])

  const handleCancel = () => {
    slardar.timer('createCancelBtnClick')
    teaClient.sendCreatePageClick({ click_item: 'back' })

    setVisible(false)
    props.onClose?.(false)
    //在 Pay 页面打开的 create 弹窗不发送该事件
    props.from !== FROM_PAGE_ENUM.CREATE_IN_PAY && uPubSub.publish(PUBSUB_TYPES.MODAL_CANCEL, false)
  }

  const handleSkip = () => {
    slardar.timer('createSkipBtnClick')
    teaClient.sendCreatePageClick({ click_item: 'skip' })

    setVisible(false)
    props.onClose?.(false)
    uPubSub.publish(PUBSUB_TYPES.MODAL_CANCEL, true)
  }

  const handleSubmit = async () => {
    slardar.timer('createSubmitBtnClick', 1, { radioValue })
    teaClient.sendCreatePageClick({ click_item: 'confirm', pay_way: radioValue })

    uPubSub.publish(PUBSUB_TYPES.MODAL_SUBMIT)
    setLoading(true)
    setVisible(false)
    try {
      const resultPagePath = utils.getResultPath({ from: props.from, uniqueId: unique })

      let bindCardParams = {
        token,
        returnUrl: resultPagePath,
        terminalEquip: NEW_SDK_TERMINAL_EQUIP,
        riskInfo: SafeJSON.stringify(riskInfoObj),
        riskAmount: hintInfo?.amount || undefined,
        payWay: radioValue!,
        upaySessionId: sessionId.current,
        refId: channelFingerprint.current,
        supportRiskAmount: true,
      } as IBindCardParams

      const selectedPayWayRef = getSelectedBindChannelRef()
      if (selectedPayWayRef) {
        // 后续提交由此支付方式完全接管
        if (selectedPayWayRef.needHandleSubmit?.() && selectedPayWayRef.handleSubmit) {
          selectedPayWayRef.handleSubmit()
          return
        }

        // 支付方式仅对提交参数进行处理
        if (selectedPayWayRef.processSubmitData) {
          bindCardParams = await selectedPayWayRef.processSubmitData(bindCardParams)
        }
      }
      const bindCardRes = await api.bindCard(bindCardParams, {
        headers: { bizAccountId: accountId },
        extraHandle: {
          uPubSub,
        },
        bizId,
      })

      // requestId 将整个绑卡流程串联起来
      const cardId = bindCardRes.data?.cardId
      if (cardId) {
        lastBindCardId.current = cardId
      }

      // 为后续补充绑卡提交准备参数，如paycell
      const bindCardSupplementParams = { ...bindCardParams, cardId } as IBindCardSupplementParams

      /**
       * 如支付方式需要接管后续流程
       * 需在内部提供needHandleResult&handleResult方法
       */
      if (selectedPayWayRef) {
        // 后续处理流程由此支付方式完全接管
        if (selectedPayWayRef.needHandleResult?.(bindCardRes.data, bindCardSupplementParams) && selectedPayWayRef.handleResult) {
          selectedPayWayRef.handleResult(bindCardRes.data, bindCardSupplementParams)
          return
        }
      }

      // 标准处理流程
      // url 是要跳转至国际支付提供的页面的链接
      if (bindCardRes.data?.url) {
        linkOpen(bindCardRes.data.url, PID)
      } else {
        handleBillStatus({
          actionType: StatusActionTypeEnum.BIND,
          status: StatusTypeEnum.SUCCESS,
        })
      }
    } catch (e: any) {
      handleBillStatus({
        actionType: StatusActionTypeEnum.BIND,
        status: StatusTypeEnum.FAIL,
        errMsg: e?.msg || e?.message || e,
      })
      slardar.timer('createSubmitError', 1, { error: SafeJSON.stringify(e) })
    }
  }

  const handleLoadingCancel = () => {
    linkClose()
    setLoading(false)
    setVisible(true)
  }

  const renderFooter = () => {
    return (
      <div className="upay-modal-footer upay-create__footer">
        <div className="upay-modal-footer-left upay-create__footer-left">
          <Button onClick={handleCancel}>{starling('funds.refund.comm.back')}</Button>
        </div>
        <div className="upay-modal-footer-right upay-create__footer-right">
          {params.allowSkip && <Button onClick={handleSkip}>Skip</Button>}

          <Button type="primary" disabled={disabled} onClick={handleSubmit}>
            {starling('funds.refund.comm.submit')}
          </Button>
        </div>
      </div>
    )
  }

  useEffect(() => {
    setVisible(defaultVisible)
  }, [defaultVisible])

  useEffect(() => {
    if (visible) {
      teaClient.sendCreatePageView()
      trackViewChannel(SUBMIT_RECORD_TYPE.BIND_CHANNEL_VIEW)
      initData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, initData])

  useEffect(() => {
    /** 告知业务方已经初始化完成 并监听业务方 OPEN_MODAL 事件 */
    const subId = uPubSub.subscribe(PUBSUB_TYPES.OPEN_MODAL, () => {
      if (props.from !== FROM_PAGE_ENUM.CREATE_IN_PAY) {
        setVisible(true)
      }
    })
    uPubSub.publish(PUBSUB_TYPES.DID_MOUNT)
    return () => uPubSub.unsubscribe(subId)
  }, [uPubSub, props.from])

  const paymentsMethodPropsBasic: IPaymentMethodsPropsBasic = {
    fromPage: props.from,
    paymentSubmitMethod: PaymentSubmitMethodEnum.BIND,
    urlQuery,
    linkOpen,
    handleBillStatus,
    openCheckOTPModal,
  }

  useEffect(() => {
    // 页面渲染埋点
    visible && slardar.timer('createPageView', 1, { props: { params, urlQuery } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  const hintTopInfo = useMemo(() => {
    if (!hintInfo?.displayAmount) {
      return null
    }

    return [
      { key: 'amount', value: hintInfo.displayAmount },
      { key: 'currency', value: hintInfo.currency || 'USD' },
      { key: 'minDay', value: '1' },
      { key: 'maxDay', value: '15' },
    ]
  }, [hintInfo])

  return (
    <div className="upay-create">
      <PageModal
        visible={isIndependentPage || visible}
        contentHeight={params.height}
        mode={isIndependentPage && params.mode}
        className="upay-create-modal upay-modal"
        footer={renderFooter()}
        closable={false}
        alignCenter={false}
      >
        <VersionReminder fromPage={FROM_PAGE_ENUM.CREATE} pageVisible={visible} />

        {!params.hideTitle && (
          <div className="upay-modal-header">
            <div className="upay-modal-header-title">{starling('funds.refund.comm.add_payment_mothod')}</div>
          </div>
        )}
        <SecurityTip />

        {hintTopInfo && (
          <Alert
            icon={<Warning />}
            content={<HighlightStarlingKey placeholders={hintTopInfo} starlingKey="funds.refund.comm.Automatic_deduction_int_top" />}
            type="warning"
            style={{ marginBottom: 16 }}
          />
        )}

        <Skeleton loading={!list.length} animation>
          <Channel
            channels={list?.map((item) => {
              return {
                key: item.payWay,
                name: item.paymentsTitle,
                content: renderPaymentsExtra(item, paymentsMethodPropsBasic),
                data: item,
                disable: item.disable,
                disableTips: (item.disable && item.exceedAmountLimitTips) || '',
                renderTitle: () => <ChannelItemTitle selected={item.payWay === Number(radioValue)} channel={item} />,
              }
            })}
            activeChannel={String(radioValue)}
            onChange={(value) => handleChangeRadio(list.find((item) => Number(item.payWay) === Number(value)))}
          />
        </Skeleton>

        <Policy
          showAutoPayHint={!!hintInfo?.amount && type === PAYMENT.POST_PAY}
          policyUrl={params.policyUrl}
          onClickPolicy={() => {
            teaClient.sendCreatePageClick({ click_item: 'privacy_policy' })
          }}
        />
      </PageModal>
      <Status {...statusProps} />
      <Loading show={loading} onCancel={handleLoadingCancel} showBtn={showCancelLoadingBtn} />
      <CheckOTPModal {...checkOTPModalProps} />
    </div>
  )
}

export default Create
