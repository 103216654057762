import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'

import { PAY_WAY_TYPE } from '@/constant'
import { IPayinMethodItem, PaymentSubmitMethodEnum } from '@/pages/PayNext/interface'
import { MXBanktransferForm } from '@/pipo/pc'
import { PipoComponentRef } from '@/pipo/utils/types'
import SafeJSON from '@/utils/SafeJSON'

import { IPaymentMethodsProps, IPaymentsMethodRef } from '../../interface'

const BankTransferMX = forwardRef<IPaymentsMethodRef, IPaymentMethodsProps>((props, ref): React.ReactElement => {
  const { changeNewPaymentStatus, paymentSubmitMethod, channel } = props

  const [isValid, setIsValid] = useState(false)
  const formRef = useRef<PipoComponentRef>(null)
  const showSavingBox = paymentSubmitMethod === PaymentSubmitMethodEnum.PAYMENT && (channel as IPayinMethodItem).supportPaymentAndBind

  // 同步验证状态
  useEffect(() => {
    changeNewPaymentStatus(PAY_WAY_TYPE.BANK_TRANSFER_MX, { valid: isValid })
  }, [isValid, changeNewPaymentStatus])

  useImperativeHandle(ref, () => ({
    processSubmitData: async (submitParams) => {
      if (formRef.current?.validateSubmitData) {
        const { paymentParams, storeCard, paymentMethod } = await formRef.current?.validateSubmitData()
        const paymentMethodObj = {
          payment_method_id: paymentMethod.paymentMethodId,
          payment_elements: paymentParams,
        }
        submitParams.channelParameter = SafeJSON.stringify(paymentMethodObj)
        submitParams.bindAndPay = storeCard
      }
      return submitParams
    },
  }))

  const onValidChange = useCallback((value: boolean) => {
    setIsValid(value)
  }, [])

  return (
    <div className="upay-banktrans-container">
      <MXBanktransferForm ref={formRef} onValidate={onValidChange} showSavingBox={showSavingBox} />
    </div>
  )
})

export default BankTransferMX
