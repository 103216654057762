import React, { useMemo } from 'react'

import { Button, Message } from '@arco-design/web-react'
import { useClipboardCopy } from '@byted/hooks'

import { PAY_WAY_TYPE } from '@/constant'
import { PaymentDetail } from '@/pipo/utils'
import starling from '@/utils/starling'
import teaClient from '@/utils/tea'

import { paymentCodeLogger } from '.'

const ActionBtns = (props: { paymentDetail?: PaymentDetail; payWay: number; recordNo: string | undefined }) => {
  const { paymentDetail, payWay, recordNo = '' } = props
  const { payment_code: paymentCode, channel_url: channelUrl, pdf } = paymentDetail?.present_to_shopper_details || {}
  const extraInfoUrl = paymentDetail?.extraInfoUrl

  const paymentDetailUrl = extraInfoUrl || pdf

  const { copy } = useClipboardCopy({
    onSuccess() {
      Message.success(starling('funds.check_out.comm.toast_copied'))
    },
  })

  const showCopyBtn = (() => {
    if ([PAY_WAY_TYPE.BANK_TRANSFER_US, PAY_WAY_TYPE.BANK_TRANSFER_MX].includes(payWay)) return false
    return !!paymentCode
  })()

  const copyCodeBtnName = useMemo(() => {
    if (payWay === PAY_WAY_TYPE.TOSS) return starling('cg.upay.exception.channel_copy_va')
    return starling("funds.refund.comm.makingpayment_paymentcode_copy'")
  }, [payWay])

  const handleCopyCode = () => {
    copy(paymentCode)

    paymentCodeLogger.scenes.paymentCodePageClick({ clickItem: 'copy_code', order_id: recordNo, payWay })
    teaClient.sendPaymentCodePageClick({ order_id: recordNo, pay_way: payWay, click_item: 'copy_code' })
  }

  // 打开支付渠道链接进行支付 (pdf 等)
  const openLink = (url: string) => {
    paymentCodeLogger.scenes.paymentCodePageClick({ clickItem: 'open_link', order_id: recordNo, payWay })
    setTimeout(() => teaClient.sendPaymentCodePageClick({ order_id: recordNo, pay_way: payWay, click_item: 'open_link' }))

    window.open(url, '_blank')
  }

  return (
    <div>
      {showCopyBtn && (
        <Button onClick={handleCopyCode} type="primary">
          {copyCodeBtnName}
        </Button>
      )}
      {channelUrl && (
        <Button onClick={() => openLink(channelUrl)} style={{ marginLeft: '8px' }} type="primary">
          {starling('funds.refund.comm.makingpayment_paymentcode_openlink')}
        </Button>
      )}
      {paymentDetailUrl && (
        <Button onClick={() => openLink(paymentDetailUrl)} style={{ marginLeft: '8px' }} type="primary">
          {starling('cg.upay.exception.channel_view_link')}
        </Button>
      )}
    </div>
  )
}
export default ActionBtns
