import { PaymentMethodItem } from '../../types'

export const GRABPAY_PAYMENT_METHOD: PaymentMethodItem = {
  paymentMethodType: {
    configuration: {},
    icon: {
      iconUrl: '',
    },
    starling_key: 'pipo_common_ewallet',
    paymentMethodType: 'E_WALLET',
    title: {
      payment: 'pipo_common_ewallet',
    },
  },
  paymentMethod: {
    configuration: {},
    paymentMethod: 'GRABPAY',
    paymentMethodId: 'pm_pi_ew_grabpay_c_r',
    displayName: 'GrabPay',
    starling_key: 'pipo_payin_payment_main_GrabPay',
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/grabpay-icon_c3f6c9.svg',
      grayIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/grabpay-gray_0ac84c.svg',
    },
  },
}
