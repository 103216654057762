import { PaymentMethodItem } from '../../types'

export const LAWSON_PAYMENT_PARAMS_MAP: Record<string, string> = {
  email: 'eg_cvs_jp_email',
}

export const LAWSON_PAYMENT_METHOD: PaymentMethodItem = {
  paymentMethodType: {
    configuration: {},
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/cvs-common_f99160.svg',
    },
    starling_key: '',
    paymentMethodType: 'CASH_PIN',
    title: {
      payment: 'pipo_common_payin_conveniencestore',
    },
  },
  paymentMethod: {
    configuration: {
      png_icon_url: '',
    },
    paymentMethod: 'LAWSON',
    paymentMethodId: 'pm_pi_cp_lawson_c_d',
    displayName: 'Lawson',
    starling_key: 'pipo_payin_payment_main_lawson',
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/lawson-common_06f101.svg',
      grayIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/lawson-common_06f101.svg',
      voucherIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/lawson-voucher_79f2d4.svg',
    },
  },
}
