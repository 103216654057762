import React from 'react'

import { Modal, ModalProps } from '@arco-design/web-react'
import classNames from 'classnames'

import { PipoProvider } from '@/pipo/pc'
import { ModeTypeEnum } from '@/typings'

import './index.less'

interface IProps extends ModalProps {
  mode: ModeTypeEnum | false | undefined
  contentHeight?: number
  footer?: React.ReactNode
  children?: React.ReactNode
}

const PageModal: React.FC<IProps> = (props) => {
  const { mode, contentHeight, children, ...modalProps } = props
  const { isFlip } = PipoProvider.useContext()

  if (mode === ModeTypeEnum.HostedPage) {
    return (
      <div className={classNames('upay-page upay-page-hosted', modalProps.className)} style={{ display: modalProps.visible ? 'block' : 'none' }}>
        <div className="upay-page-hosted-body">{children}</div>
        {modalProps?.footer && <div className="upay-page-hosted-footer">{modalProps.footer}</div>}
      </div>
    )
  }

  return (
    <Modal
      {...modalProps}
      autoFocus={false}
      focusLock={false}
      className={classNames('upay-page upay-page-modal notranslate', modalProps.className, {
        'upay-page-modal-rtl': isFlip,
      })}
      style={
        {
          ...modalProps.style,
          '--upay-modal-max-height': contentHeight ? contentHeight + 'px' : 'unset',
          '--upay-modal-overflow-y': contentHeight ? 'auto' : 'unset',
        } as any
      }
    >
      {children}
    </Modal>
  )
}

export default PageModal
