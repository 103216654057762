import React, { useContext } from 'react'

import { Button, Popover } from '@arco-design/web-react'

import { ParamsContext } from '@/stores'
import starling from '@/utils/starling'
import teaClient from '@/utils/tea'

import { payLogger } from '../..'
import { useSubmit } from '../../hooks/useSubmit'
import { PayNextModel } from '../../model/usePayNext'

const PayNextFooter = () => {
  const { params } = useContext(ParamsContext)
  const {
    data: { currentPIListKey, isBindSubmit },
    loadStatus: { loadingFeelgood },
    func: { handleFBCancelClick, handleSkip, handleChangePIList },
  } = PayNextModel.useContext()
  const { submitBtnLoading, disabled, handleSubmit } = useSubmit()

  const handleBackClick = () => {
    if (currentPIListKey === 'foldedPIList') {
      payLogger.scenes.payPageClick({ clickItem: 'back_to_unfold' })

      // 回退到上一级
      handleChangePIList('unfoldedPIList')
    } else {
      teaClient.sendPayPageClick({ click_item: 'back' })
      payLogger.scenes.payPageClick({ clickItem: 'back' })

      handleFBCancelClick()
    }
  }

  const SubmitBtn = (
    <Button type="primary" disabled={disabled} onClick={handleSubmit} loading={submitBtnLoading} id="upay-pay-btn">
      {isBindSubmit ? starling('funds.check_out.comm.verify_card') : starling('funds.refund.comm.make_payment')}
    </Button>
  )

  return (
    <div className="upay-modal-footer">
      <div className="upay-modal-footer-left">
        <Button onClick={handleBackClick} loading={loadingFeelgood}>
          {starling('funds.refund.comm.back')}
        </Button>
      </div>
      <div className="upay-modal-footer-right">
        {params.allowSkip && <Button onClick={handleSkip}>Skip</Button>}

        {isBindSubmit ? <Popover content={starling('funds.check_out.comm.save_prompt')}>{SubmitBtn}</Popover> : SubmitBtn}
      </div>
    </div>
  )
}

export default PayNextFooter
