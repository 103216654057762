import React, { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'

import { useRequest } from '@byted/hooks'

import { PAYMENT, PAY_WAY_TYPE } from '@/constant'
import { IPayinMethodItem, PaymentSubmitMethodEnum } from '@/pages/PayNext/interface'
import { DirectDebit } from '@/pipo/pc'
import { PipoComponentRef } from '@/pipo/utils'
import GlobalInfo from '@/stores/GlobalInfo'
import SafeJSON from '@/utils/SafeJSON'
import slardar from '@/utils/slardar'

import { IPaymentMethodsProps, IPaymentsMethodRef } from '../../interface'

const DirectDebitForm = forwardRef<IPaymentsMethodRef, IPaymentMethodsProps>((props, ref): React.ReactElement => {
  const { urlQuery, paymentSubmitMethod, changeNewPaymentStatus, channel } = props
  const {
    PIPOApi: { fetchDistrict },
    utils: { getContractAddressAsync },
  } = useContext(GlobalInfo)
  const [directDebitValid, setDirectDebitValid] = useState(false)
  const ddRef = useRef<PipoComponentRef>(null)

  // TODO: 暂时兼容两种逻辑，后续新UI全量后可梳理移除，由后端维护是否显示
  const showSavingBox = useMemo(() => {
    // 仅支付场景展示SaveBox
    if (paymentSubmitMethod !== PaymentSubmitMethodEnum.PAYMENT) return false

    // 若后端返回 以后端为准 老逻辑未返回排除后付费
    const supportFromBE = (channel as IPayinMethodItem).supportPaymentAndBind
    return typeof supportFromBE === 'boolean' ? supportFromBE : urlQuery.type !== PAYMENT.POST_PAY
  }, [channel, paymentSubmitMethod, urlQuery.type])

  const { data: contractAddressList } = useRequest(getContractAddressAsync, { auto: true })

  // 同步验证状态
  useEffect(() => {
    changeNewPaymentStatus(PAY_WAY_TYPE.DIRECT_DEBIT, { valid: directDebitValid })
  }, [changeNewPaymentStatus, directDebitValid])

  useImperativeHandle(ref, () => ({
    // 一步支付走3M后端提交
    processSubmitData: async (submitParams) => {
      if (ddRef.current?.validateSubmitData) {
        const { paymentMethod, paymentParams, storeCard, fillAddressState } = await ddRef.current?.validateSubmitData()

        fillAddressState &&
          slardar.timer('fillAddressSubmitEvent', fillAddressState.metrics, {
            from: 'US DD',
            ...fillAddressState.categories,
          })

        const paymentMethodObj = {
          method_id: paymentMethod.paymentMethodId,
          payment_elements: paymentParams,
          method_type: paymentMethod.paymentMethod,
          type: '',
          method_params: null,
        }
        submitParams.channelParameter = SafeJSON.stringify(paymentMethodObj)
        // 后付费不展示绑卡
        submitParams.bindAndPay = storeCard && showSavingBox
      }
      return submitParams
    },
  }))

  const onDirectDebitValid = useCallback((value: boolean) => {
    setDirectDebitValid(value)
  }, [])

  return (
    <DirectDebit
      ref={ddRef}
      onFetchDistrict={fetchDistrict}
      onValidate={onDirectDebitValid}
      showSavingBox={showSavingBox}
      contractAddressList={contractAddressList}
    />
  )
})

export default DirectDebitForm
