import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

import { Alert, Skeleton } from '@arco-design/web-react'
import { StatusActionTypeEnum, StatusTypeEnum } from '@upay/utils/es/types'

import { Loading, PageModal, Policy, SecurityTip } from '@/components'
import HighlightStarlingKey from '@/components/HighLightStarlingKey'
import { IHintInfo } from '@/components/Hint/interface'
import { IOpenStatusProps } from '@/components/Status/config'
import { FROM_PAGE_ENUM, NEW_SDK_TERMINAL_EQUIP, PAY_WAY_TYPE, PUBSUB_TYPES, SUBMIT_RECORD_TYPE } from '@/constant'
import { useNewWindow, usePostMessage } from '@/hooks'
import { useReportLoadTime } from '@/hooks/useReportLoadTime'
import useNewPaymentsMethod from '@/payments/NewPayments/useNewPaymentsMethod'
import { IPaymentMethodsPropsBasic } from '@/payments/interface'
import api from '@/services'
import { CardListRes, ChannelRes, IBindCardParams, IBindCardSupplementParams } from '@/services/types'
import { Warning } from '@/static/icon'
import { ChangeStatusEnum, ParamsContext } from '@/stores'
import GlobalInfo from '@/stores/GlobalInfo'
import { ModeTypeEnum } from '@/typings'
import utils from '@/utils'
import SafeJSON from '@/utils/SafeJSON'
import slardar from '@/utils/slardar'
import starling from '@/utils/starling'

import { PaymentSubmitMethodEnum } from '../PayNext/interface'
import ChangeChannels, { PanelKey } from './components/ChangeChannels'
import ChangeFooter from './components/ChangeFooter'
import ChangeStatus, { IStatusItem } from './components/ChangeStatus'
import { useChangeCards, useChangePayWays, useChangeVisible, useQueryAccountResult, useQueryCardResult } from './hooks'
import { connectRiskInfo } from './utils'

import './index.less'

const PID = 'change'

/** 当前变更流程：0：初始阶段，1：正在进行后付费绑卡，2：变更账户阶段 */
enum CHANGE_STEP {
  INIT = '0',
  CHANGE_CARD = '1',
  CHANGE_ACCOUNT = '2',
}
interface IProps {
  /** 是否打开 */
  visible?: boolean
  /** 关闭Pay弹窗回调。回调入参为isSubmit-是否支付成功 */
  onClose?: (isSubmit: boolean) => void
  from?: FROM_PAGE_ENUM
}

const Change: FC<IProps> = (props) => {
  const { visible: propsVisible, onClose } = props
  const { params, urlQuery, uPubSub, unique } = useContext(ParamsContext)
  const isIndependentPage = props.from === FROM_PAGE_ENUM.CHANGE && params.mode === ModeTypeEnum.HostedPage
  const { token, changeStatus, accountId, bizId } = urlQuery
  const {
    data: { sessionId, channelFingerprint, lastBindCardId, riskInfoObj },
    utils: { trackSelectChannel, trackViewChannel },
  } = useContext(GlobalInfo)

  // 选中支付方式管理
  const [selectedMethod, setSelectedMethod] = useState<string>('-1')
  const [selectedPayWay, setSelectedPayWay] = useState<ChannelRes | null>()
  const [selectedCard, setSelectedCard] = useState<CardListRes | null>()

  const [errorMsg, setErrorMsg] = useState('')
  const [paying, setPaying] = useState(false) // 支付处理loading相关变量
  const [showCancelLoading, setShowCancelLoading] = useState(true)
  const [showCountDown, setShowCountDown] = useState(false) // 是否让paying进入倒计时
  const [retry, setRetry] = useState(true)
  const [statusItem, setStatusItem] = useState<IStatusItem>({ show: false })
  const [hintInfo, setHintInfo] = useState<IHintInfo>({})

  const changeStep = useRef<CHANGE_STEP>(CHANGE_STEP.INIT)

  const [visible, setVisible] = useChangeVisible(propsVisible)
  const { getPayWays, payWays, payWaysReady } = useChangePayWays({ visible, setErrorMsg })
  const { getCardList, cards, cardsReady } = useChangeCards({ visible, setErrorMsg })

  // 支付方式接入
  const selectedPayWayNumber = selectedCard?.payWay || selectedPayWay?.payWay
  const {
    renderPaymentsExtra,
    getPaymentRefByPayWay,
    valid: { newPaymentStatus, clearNewPaymentStatus },
  } = useNewPaymentsMethod()
  const getSelectedPayWayRef = useCallback(() => getPaymentRefByPayWay(selectedPayWayNumber), [getPaymentRefByPayWay, selectedPayWayNumber])
  // 渠道结果页面回调地址
  const resultPagePath = utils.getResultPath({ from: FROM_PAGE_ENUM.CHANGE, uniqueId: unique })

  // 加载耗时上报
  const reportLoadtime = useReportLoadTime('change')

  const [linkOpen, linkClose] = useNewWindow(() => {
    if (changeStep.current === CHANGE_STEP.INIT) {
      setPaying(false)
      setVisible(true)
    } else if (changeStep.current === CHANGE_STEP.CHANGE_CARD) {
      setPaying(true)
    } else {
      setPaying(true)
    }
  })

  const { run: startQueryCardResult, refresh: refreshQueryCardResult } = useQueryCardResult({
    onPollingSuccess: () => handleQueryCardSuccess(),
    onPollingEnd: () => handleQueryCardEnd(),
  })

  const {
    run: startQueryAccountResult,
    stop: stopQueryAccountResult,
    loadingText,
  } = useQueryAccountResult({
    showCountDown: showCountDown,
    onPollingEnd: () => handleQueryAccountEnd(),
  })

  usePostMessage(async (postData) => {
    const { upayType, query } = postData
    const { error_msg, bind_card_status, payment_status, from, error_code } = query
    // 来自result结果页的通知
    if (upayType === PUBSUB_TYPES['3DS_RESULT'] && from === FROM_PAGE_ENUM.CHANGE) {
      setShowCancelLoading(false)
      const status = bind_card_status || payment_status
      if ([StatusTypeEnum.SUCCESS, StatusTypeEnum.SUCCEED].includes(status)) {
        changeStep.current = CHANGE_STEP.CHANGE_CARD
        setPaying(true)
        startQueryCardResult()
      } else {
        linkClose()
        let errorMsg = error_msg
        if (error_code) {
          errorMsg = await utils.getPIPOErrorDetail(error_code, params.language)
        }
        handleBillStatus({ actionType: StatusActionTypeEnum.BIND, status, errMsg: errorMsg })
      }
    }
  })

  useEffect(() => {
    if (visible) {
      initData()
      trackViewChannel(SUBMIT_RECORD_TYPE.BIND_CHANNEL_VIEW)
    } else {
      clearNewPaymentStatus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  /**
   * 基础信息是否准备就绪
   * 卡列表/支付方式列表是否拿到
   */
  const basicInfoLoading = useMemo(() => {
    if (!cardsReady || !payWaysReady) return true

    reportLoadtime()
    return false
  }, [cardsReady, payWaysReady, reportLoadtime])

  // 支付禁用态管理
  const disabled = useMemo(() => {
    // 以下情况需要禁用
    // 不显示时提交按钮置灰
    if (!visible) return true
    // 基础信息尚未准备就绪
    if (basicInfoLoading) return true
    // 接口异常：禁用并提示toast
    if (errorMsg) return true
    // 缺少选中的支付方式
    if (selectedMethod === '-1') return true
    // 各支付方式后续逐渐收拢到此处的payWayValid，统一管理
    if (typeof newPaymentStatus[`${selectedMethod}`]?.valid === 'boolean' && !newPaymentStatus[`${selectedMethod}`].valid) return true

    return false
  }, [visible, basicInfoLoading, errorMsg, selectedMethod, newPaymentStatus])

  // 用于确定默认选中的支付方式
  useEffect(() => {
    if (!basicInfoLoading) {
      if (errorMsg) {
        setSelectedMethod('-1')
        setHintInfo({})
        return
      }
      // 有卡，则取第一个有效卡选中，无有效卡，则选ccdc
      // 无卡，直接选ccdc
      // 其他情况，不默认选
      if (cards.length) {
        const firstValidCard = cards.filter((i) => i.valid === true)?.[0]
        if (firstValidCard) {
          const connectData = connectRiskInfo(firstValidCard, payWays)
          handleChannelChange(PanelKey.Card, firstValidCard.id, connectData)
        } else {
          if (payWays.length === 1 && payWays[0].payWay === PAY_WAY_TYPE.CREATE_NEW_CARD) {
            handleChannelChange(PanelKey.PayWay, payWays[0].payWay, payWays[0])
          }
        }
      } else {
        if (payWays.length === 1 && payWays[0].payWay === PAY_WAY_TYPE.CREATE_NEW_CARD) {
          handleChannelChange(PanelKey.PayWay, payWays[0].payWay, payWays[0])
        }
      }
    }
  }, [cards, payWays, errorMsg, basicInfoLoading])

  useEffect(() => {
    // 页面渲染埋点
    visible && slardar.timer('changePageView', 1, { props: { params, urlQuery } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  // 初始化数据部分
  const initData = async () => {
    if (changeStatus === ChangeStatusEnum.Init || !changeStatus) {
      getPayWays()
      getCardList()
    } else if (changeStatus === ChangeStatusEnum.Pause) {
      transferSubmit()
    }
  }

  const handleChannelChange = (panelKey: PanelKey, channelKey: string | number, data?: any) => {
    setSelectedMethod(String(channelKey))
    setSelectedCard(panelKey === PanelKey.Card ? data : null)
    setSelectedPayWay(panelKey === PanelKey.PayWay ? data : null)
    panelKey === PanelKey.PayWay && trackSelectChannel(channelKey as number)

    setHintInfo({
      amount: data?.riskAmount,
      currency: data?.riskAmountCurrency,
      displayAmount: data?.displayRiskAmount,
    })
  }

  /**
   * 以下为支付提交方法
   */
  const handleSubmit = async () => {
    slardar.timer('changeSubmitBtnClick')

    setPaying(true)
    setVisible(false)
    uPubSub.publish(PUBSUB_TYPES.MODAL_SUBMIT)

    try {
      if (selectedPayWay) {
        // 选中新支付方式
        handleBindNewChannel()
      } else {
        // 已有卡支付
        paymentTransfer({
          token,
          cardId: selectedCard?.id,
          riskInfo: SafeJSON.stringify(riskInfoObj),
        })
      }
    } catch (e) {
      console.error(e, 'submit fail')
    }
  }

  const handleBindNewChannel = async () => {
    try {
      let bindCardParams = {
        token,
        returnUrl: resultPagePath,
        terminalEquip: NEW_SDK_TERMINAL_EQUIP,
        riskInfo: SafeJSON.stringify(riskInfoObj),
        riskAmount: hintInfo?.amount || undefined,
        payWay: selectedPayWay!.payWay,
        upaySessionId: sessionId.current,
        refId: channelFingerprint.current,
      } as IBindCardParams

      const selectedPayWayRef = getSelectedPayWayRef()
      if (selectedPayWayRef) {
        // 后续提交由此支付方式完全接管
        if (selectedPayWayRef.needHandleSubmit?.() && selectedPayWayRef.handleSubmit) {
          selectedPayWayRef.handleSubmit()
          return
        }

        // 支付方式仅对提交参数进行处理
        if (selectedPayWayRef.processSubmitData) {
          bindCardParams = await selectedPayWayRef.processSubmitData(bindCardParams)
        }
      }
      const bindCardRes = await api.bindCard(bindCardParams, {
        headers: { bizAccountId: accountId },
        extraHandle: {
          uPubSub,
        },
        bizId,
      })

      // requestId 将整个绑卡流程串联起来
      const cardId = bindCardRes.data?.cardId
      if (cardId) {
        lastBindCardId.current = cardId
      }
      // 为后续补充绑卡提交准备参数，如paycell
      const bindCardSupplementParams = { ...bindCardParams, cardId } as IBindCardSupplementParams

      /**
       * 如支付方式需要接管后续流程
       * 需在内部提供needHandleResult&handleResult方法
       */
      if (selectedPayWayRef) {
        // 后续处理流程由此支付方式完全接管
        if (selectedPayWayRef.needHandleResult?.(bindCardRes.data, bindCardSupplementParams) && selectedPayWayRef.handleResult) {
          selectedPayWayRef.handleResult(bindCardRes.data, bindCardSupplementParams)
          return
        }
      }

      // 标准处理流程
      // url 是要跳转至国际支付提供的页面的链接
      if (bindCardRes.data?.url) {
        linkOpen(bindCardRes.data.url, PID)
      } else {
        Promise.reject()
      }
    } catch (e: any) {
      setPaying(false)
      setStatusItem({
        payWay: selectedPayWayNumber!,
        action: StatusActionTypeEnum.BIND,
        desc: StatusTypeEnum.FAILED,
        error: e.msg || e,
        show: true,
      })
    }
  }

  /** 卡变更 */
  const paymentTransfer = (extraParams: any) => {
    const params = {
      ...extraParams,
      riskAmount: hintInfo.amount,
      returnUrl: resultPagePath,
      token,
    }
    api
      .paymentTransfer(params)
      .then((res) => {
        const { url } = res.data || {}
        if (url) {
          linkOpen(url, PID) // 预付费已有卡转后付费的绑卡页面
          setShowCancelLoading(false)
        } else {
          startQueryCardResult()
        }
      })
      .catch((e) => {
        handleBillStatus({ actionType: StatusActionTypeEnum.PAY, status: StatusTypeEnum.FAILED as any, errMsg: e?.msg || e?.toString() })
      })
  }

  // 账户变更
  const transferSubmit = () => {
    setPaying(true)
    setShowCountDown(true) // 展示计数10次
    startQueryAccountResult() // 开始倒计时查询支付方式变更结果
    api
      .transferSubmit({ token })
      .then(() => {
        stopQueryAccountResult()
        setShowCancelLoading(false)
        handleBillStatus({ actionType: StatusActionTypeEnum.CHANGE, status: StatusTypeEnum.SUCCEED as any })
        setRetry(false) // 已经完成全部流程，不需要再次展示内容提供重试
      })
      .catch(() => {
        stopQueryAccountResult()
        handleBillStatus({
          actionType: StatusActionTypeEnum.BIND,
          status: StatusTypeEnum.TIMEOUT,
          errMsg: starling('funds.refund.comm.change_results_unknown_tips'),
        })
      })
  }

  const handleQueryCardSuccess = () => {
    changeStep.current = CHANGE_STEP.CHANGE_ACCOUNT
    setRetry(false) // 第一阶段结束，不用再把界面打开
    transferSubmit()
  }

  const handleQueryCardEnd = () => {
    changeStep.current = CHANGE_STEP.INIT
    setPaying(false)
    setStatusItem({
      payWay: selectedPayWayNumber!,
      action: StatusActionTypeEnum.CHANGE,
      desc: StatusTypeEnum.TIMEOUT,
      error: ' ',
      show: true,
    })
  }

  const handleQueryAccountEnd = () => {
    handleBillStatus({
      actionType: StatusActionTypeEnum.BIND,
      status: StatusTypeEnum.TIMEOUT,
      errMsg: starling('funds.refund.comm.change_results_unknown_tips'),
    })
  }

  const handleBillStatus = async (params: IOpenStatusProps) => {
    const { actionType, status, errMsg } = params
    const isSuccess = [StatusTypeEnum.SUCCEED, StatusTypeEnum.SUCCESS].includes(status as StatusTypeEnum)
    // 针对后付费绑卡pipo直接返回success
    if (actionType === StatusActionTypeEnum.BIND && isSuccess) {
      setShowCancelLoading(false)
      changeStep.current = CHANGE_STEP.CHANGE_CARD
      setPaying(true)
      startQueryCardResult()
      return
    }
    slardar.timer('changeOPENstatus', 1, {
      from: 'change',
      to: 'status',
      type: 'status_modal',
      action: actionType,
      desc: status,
      error: errMsg || '',
    })
    // 展示当前状态，清除状态轮询，清除三方页面，清除loading遮罩
    linkClose()
    setShowCountDown(false)
    setPaying(false)
    setStatusItem({
      payWay: selectedPayWayNumber!,
      action: actionType,
      desc: status,
      error: errMsg || '',
      show: true,
    })
    uPubSub.publish(PUBSUB_TYPES.STATUS_RESULT, {
      status: isSuccess,
      clickBtn: false,
      actionType,
      msg: errMsg || '',
    })
    if (!errMsg) {
      setVisible(false)
    }
  }

  const handleStatusRefresh = () => {
    setPaying(true)
    refreshQueryCardResult()
  }

  const handleStatusClose = () => {
    // 没有完成第一阶段，关闭状态页继续展示内容
    retry && setVisible(true)
  }

  const handleFooterClose = () => {
    setVisible(false)
    onClose?.(false)
  }

  const handleLoadingCancel = () => {
    linkClose()
    setPaying(false)
    setVisible(true)
  }

  const hintTopInfo = useMemo(() => {
    if (!hintInfo?.displayAmount) {
      return null
    }

    return [
      { key: 'amount', value: hintInfo.displayAmount },
      { key: 'currency', value: hintInfo.currency || 'USD' },
      { key: 'minDay', value: '1' },
      { key: 'maxDay', value: '15' },
    ]
  }, [hintInfo])

  return (
    <div className="upay-change">
      {changeStatus === ChangeStatusEnum.Init && (
        <PageModal
          visible={isIndependentPage || visible}
          contentHeight={params.height}
          mode={isIndependentPage && params.mode}
          className="upay-change-modal upay-modal"
          footer={<ChangeFooter disabled={disabled} onClose={handleFooterClose} handleSubmit={handleSubmit} />}
          closable={false}
          alignCenter={false}
        >
          {!params.hideTitle && (
            <div className="upay-modal-header">
              <div className="upay-modal-header-title">{starling('funds.refund.comm.change_payment_mothod')}</div>
            </div>
          )}
          <SecurityTip />

          {hintTopInfo && (
            <Alert
              icon={<Warning />}
              content={<HighlightStarlingKey placeholders={hintTopInfo} starlingKey="funds.refund.comm.Automatic_deduction_int_top" />}
              type="warning"
              style={{ marginBottom: 8 }}
            />
          )}

          <Skeleton
            className="upay-change__skeleton"
            loading={!cardsReady || !payWaysReady}
            animation
            text={{ width: ['275px', '100%', '100%', '60%'], rows: 4 }}
          >
            <ChangeChannels
              cards={cards}
              payWays={payWays}
              activeChannel={selectedMethod}
              onChannelChange={handleChannelChange}
              errorMsg={errorMsg}
              renderPayWayExtra={(item: ChannelRes) => {
                return renderPaymentsExtra(item, {
                  fromPage: FROM_PAGE_ENUM.CHANGE,
                  paymentSubmitMethod: PaymentSubmitMethodEnum.BIND,
                  handleBillStatus,
                  linkOpen,
                  urlQuery,
                  riskAmount: hintInfo.amount,
                  currency: hintInfo.currency,
                } as IPaymentMethodsPropsBasic)
              }}
            />
          </Skeleton>

          <Policy showAutoPayHint={!!hintInfo?.amount} policyUrl={params.policyUrl} />
        </PageModal>
      )}
      <Loading showBtn={showCancelLoading} show={paying} loadingText={loadingText} onCancel={handleLoadingCancel} />
      <ChangeStatus statusItem={statusItem} setStatusItem={setStatusItem} onRefresh={handleStatusRefresh} onClose={handleStatusClose} />
    </div>
  )
}

export default Change
