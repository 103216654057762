import starling from '@/utils/starling'

import { PaymentMethodItem } from '../../types'

// 这个文件里的国际化都没整，兼容一下
const __ = (text: string, key: string) => {
  return starling(key as any, {}, text)
}
/**
 * banktransfer 需要填写的表单的位数
 */
export type BanktransferFormData = {
  bank_code?: string
}

export const BANKTRANSFER_PAYMENT_PARAMS_MAP: Record<string, string> = {
  bank_code: 'eg_banktransfer_id_bank_code',
}

export const BANKTRANSFER_PAYMENT_METHOD: PaymentMethodItem = {
  paymentMethodType: {
    configuration: {},
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/banktransfer_f0e4e1.png',
      pngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/banktransfer_light_3_6f9671.png',
      darkModePngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/banktransfer_dark_3_6f9671.png',
    },
    starling_key: 'pipo_payin_payment_main_banktransfer',
    paymentMethodType: 'BANK_TRANSFER',
  },
  paymentMethod: {
    configuration: {
      png_icon_url: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/banktransfer_light_3_6f9671.png',
      dark_mode_png_icon_url: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/banktransfer_dark_3_6f9671.png',
    },
    paymentMethod: 'BANK_TRANSFER',
    paymentMethodId: 'pm_pi_bt_banktransfer_c_d',
    displayName: '',
    starling_key: 'pipo_payin_payment_main_banktransfer',
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/banktransfer_f0e4e1.png',
      pngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/banktransfer_light_3_6f9671.png',
      darkModePngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/banktransfer_dark_3_6f9671.png',
    },
  },
}
export type SupportBankCode = 'ID003' | 'ID007' | 'ID009' | 'ID001' | 'ID011' | 'ID013' | 'ID015' | 'ID139' | 'ID155'
export interface BankItem {
  bankShortName: string
  bankCode: string
  name: string // 如果对应的 i18n 取不到值就用 name
  icon: {
    iconUrl: string
    pngIconUrl?: string
    darkModePngIconUrl?: string
    voucherIconUrl?: string
    grayIconUrl?: string
  }
  description?: string
  operationGuide: {
    mobile?: string
    internet?: string
    atm?: string
    otherbank?: string
  }
}
export const GET_SUPPORT_BANK_MAP: () => Record<SupportBankCode | string, BankItem> = () => ({
  ID001: {
    bankShortName: 'BRI',
    bankCode: 'ID001',
    name: 'Bank Rakyat Indonesia',
    description: 'this is description',
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/bank_bri_v1_d43e72.png',
      pngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/BRI_light_3_f8d97b.png',
      darkModePngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/BRI_dark_3_b75ec7.png',
      voucherIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/bri-code_d5cd29.png',
      grayIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/bank-bri-gray_da1f79.png',
    },
    operationGuide: {
      mobile: __(
        '1. Log in to your BRI mobile account \\n2. Select "Mobile Banking BRI" then tap, "PEMBAYARAN" > "BRIVA" \\n3. Enter your 16-digit payment code \\n4. Enter the transaction amount exactly as it appears on your order \\n5. Enter your PIN \\n6. When the transaction is complete, the payment receipt will be sent to you by SMS ',
        'funds.refund.comm.makingpayment_mobilebanking_detail_BRI',
      ),
      internet: __(
        '1. Log in to your BNI Internet Banking account at https://ib.bri.co.id/ib-bri/Login.html \\n2. Select "Billing Payment", then "Payment" > "BRIVA" \\n3. Enter your 16-digit payment code \\n4. Confirm that the BRIVA number, customer name, and payment amount are correct \\n5. Enter your password and mToken \\n6. When the transaction is complete, take a screenshot or print out the receipt page for your records',
        'funds.refund.comm.makingpayment_internetbanking_detail_BRI',
      ),
      atm: __(
        '1. Insert your BRI debit card and enter your PIN \\n2. Select "Other Transaction", then "Payment" > "Others" > "BRIVA" \\n3. Enter your 16-digit payment code \\n4. Confirm that the BRIVA number, customer name, and payment amount are correct \\n5. Follow the instructions to finish the payment \\n6. When the transaction is complete, make sure to keep the receipt for your records',
        'funds.refund.comm.makingpayment_ATM_detail_BRI',
      ),
      otherbank: __(
        '1. Choose "TRANSFER", then "Transfer to other bank" \\n2. Enter BRI bank code (002) (If you use BCA mobile, make sure to be registered first) \\n3. Enter the transaction amount exactly as it appears on your order \\n4. Enter your Virtual Account Number \\n5. Follow the instructions to finish the payment \\n6. Once complete, make sure the payment confirmation screen is displayed ',
        'funds.refund.comm.makingpayment_otherbankpayment_detail_BRI',
      ),
    },
  },
  ID003: {
    bankShortName: 'Mandiri',
    bankCode: 'ID003',
    name: 'Bank Mandiri',
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/bank_mandiri_v1_45f845.png',
      pngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/Mandiri_light_3_77d3db.png',
      darkModePngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/Mandiri_dark_3_e991a9.png',
      voucherIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/mandiri-code_2c5f79.png',
      grayIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/bank-maybank-gray_056c14.png',
    },
    operationGuide: {
      mobile: __(
        '1. Log in to your Mandiri mobile banking account  \\n2. Select "TRANSFER" > "Multipayment", and then tap "LANJUT" \\n3. Search "Doku" and select "DOKU Merchant (89022)" from the "Penyedia Jasa" list \\n4. Under "No Virtual", enter your 16-digit payment code, and tap "TAMBAH SEBAGAI NOMOR BARY" \\n5. Under "Nama Pembayaran", enter "Shop", and tap "KONFIRMASI" \\n6. Under "Nominal", enter the transaction amount exactly as it appears on your order and tap "LANJUT" \\n7. Tap "KONFIRMASI" on the Konfirmasi Pembayaran page \\n8. Enter your PIN and save your order ',
        'funds.refund.comm.makingpayment_mobilebanking_detail_Mandiri',
      ),
      internet: __(
        '1. Log into your Mandiri Online account \\n2. Select "Payment" \\n3. Select "Multipayment" \\n4. Select "DOKU" (Biller Code: 89022) for the service provider \\n5. Enter your Virtual Account Number \\n6. Enter the transaction amount exactly as it appears on your order then select "Continue" \\n7. After the payment amount is displayed, select "Confirm" \\n8. Enter your PIN and Challenge Code Token \\n9. When the transaction is complete, take a screenshot or print out a receipt page and keep it for your records ',
        'funds.refund.comm.makingpayment_internetbanking_detail_Mandiri',
      ),
      atm: __(
        '1. Insert your ATM card and enter your PIN \\n2. Select "Pay/Buy" \\n3. Select "Others", then select "Multipayment" \\n4. Enter the DOKU Biller Code (89022), then select "Correct" \\n5. Enter DOKU "Virtual Account Number", then select "Correct" \\n6. Enter "1" for billing, then select "Yes" \\n7. When the payment confirmation screen is displayed, select "Yes" ',
        'funds.refund.comm.makingpayment_ATM_detail_Mandiri',
      ),
    },
  },
  ID007: {
    bankShortName: 'BNI',
    bankCode: 'ID007',
    name: 'Bank Negara Indonesia',
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/bank_bni_v1_e17d6f.png',
      pngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/BNI_light_3_142190.png',
      darkModePngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/BNI_dark_3_351934.png',
      voucherIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/bni-code_654c68.png',
      grayIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/bank-bni-gray_434684.png',
    },
    operationGuide: {
      mobile: __(
        '1. Log into your BNI Mobile Banking account \\n2. Select "Transfer" \\n3. Select "Virtual Account Billing", then choose your debit account \\n4. Enter your Virtual Account Number in "New Input" \\n5. Confirm that the payment amount is displayed on the screen \\n6. Confirm the transaction and enter your transaction password ',
        'funds.refund.comm.makingpayment_mobilebanking_detail_BNI',
      ),
      internet: __(
        '1. Log in to your BNI Internet Banking account at https://ibank.bni.co.id \\n2. Select "Transfer" \\n3. Select "Virtual Account Billing" \\n4. Enter your Virtual Account Number \\n5. Select your debit account you wish to use, then select "Continue" \\n6. Confirm that the payment amount is displayed on the screen \\n7. Enter your TAC to initiate the transaction ',
        'funds.refund.comm.makingpayment_internetbanking_detail_BNI',
      ),
      atm: __(
        '1. Insert your ATM card \\n2. Select language \\n3. Enter your ATM PIN \\n4. Select "Other Menu" \\n5. Select "Transfer" \\n6. Select account type (Example: "From Source Account") \\n7. Select "Virtual Account Billing" \\n8. Enter your Virtual Account Number',
        'funds.refund.comm.makingpayment_ATM_detail_BNI',
      ),
      otherbank: __(
        '1. Select "TRANSFER", then "Transfer to other bank"\\n2. Enter BNI bank code (009) (If you use BCA mobile, you need to have a BNI account)\\n3. Enter the transaction amount exactly as it appears on your order\\n4. Enter your Virtual Account Number\\n5. Follow the instructions to complete the transfer\\n6. Once complete, make sure the payment confirmation screen is displayed',
        'funds.refund.comm.makingpayment_otherbankpayment_detail_BNI',
      ),
    },
  },
  ID009: {
    bankCode: 'ID009',
    bankShortName: 'CIMB',
    name: 'Bank CIMB Niaga',
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/bank_cimb_v1_ea2e8b.png',
      pngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/bank_cimb_v1_ea2e8b.png',
      voucherIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/cimb-code_fc6b0d.png',
      grayIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/bank-cimb-gray_2c3ee9.png',
    },
    operationGuide: {
      mobile: __(
        '1. Log in to your Go Mobile account \\n2. Select "Transfer to other CIMB Niaga account" \\n3. Select your source account: CASA or Phone account. \\n4. Enter your Virtual Account Number \\n5. Enter your Mobile Banking PIN \\n6. When the transaction is completed, the payment receipt will be sent to you by SMS ',
        'funds.refund.comm.makingpayment_mobilebanking_detail_CIMB',
      ),
      internet: __(
        '1. Log in to your online banking account \\n2. Select the option to transfer to other online bank \\n3. Select CIMB Niaga Bank (Bank code: 022) \\n4. Enter the transaction amount exactly as it appears on your order \\n5. Verify your virtual account holder name, Virtual Account Number, and the payment amount \\n6. Follow the instructions to complete the payment \\n7. Once complete, make sure the payment confirmation screen is displayed',
        'funds.refund.comm.makingpayment_internetbanking_detail_CIMB',
      ),
      atm: __(
        '1. Insert your ATM card and enter your CIMB PIN \\n2. Select "Payment", then "Continue" then "Virtual Account" \\n3. Enter your Virtual Account Number \\n4. Verify your virtual account holder name and Virtual Account Number \\n5. Select "OK" to initiate the payment \\n6. When the transaction is complete, make sure to keep the receipt for your records ',
        'funds.refund.comm.makingpayment_ATM_detail_CIMB',
      ),
      otherbank: __(
        '1. Select "TRANSFER", then "Transfer to other bank"\\n2. Enter CIMB bank code (022) (If you use BCA mobile, you need to have a CIMB account)\\n3. Enter the transaction amount exactly as it appears on your order\\n4. Enter your Virtual Account Number\\n5. Follow the instructions to finish the payment\\n6. Once complete, make sure the payment confirmation screen is displayed',
        'funds.refund.comm.makingpayment_otherbankpayment_detail_CIMB',
      ),
    },
  },
  ID011: {
    bankCode: 'ID011',
    bankShortName: 'Danamon',
    name: 'Bank Danamon',
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/bank_danamon_v1_305198.png',
      pngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/Danamon_light_3_6d94e8.png',
      darkModePngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/Danamon_dark_3_5c33d4.png',
      voucherIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/danamon-code_1f7ed9.png',
      grayIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/bank-danamon-gray_b44899.png',
    },
    operationGuide: {
      mobile: __(
        '1. Log in to your D-Mobile account \\n2. Select "Payment" \\n3. Select your virtual account, add the biller, then select "Payment" \\n4. Enter your 16-digit payment code \\n5. Verify the payment details and enter your transaction PIN. ',
        'funds.refund.comm.makingpayment_mobilebanking_detail_Danamon',
      ),
      atm: __(
        `1. Insert your ATM card and enter your PIN \\n2. Select "Payment" > "Other" > "Virtual Account" \\n3. Enter your 16-digit payment code \\n4. Verify your virtual account holder name, Virtual Account Number, institution, and the payment amount \\n5. Select "Yes" to initiate the payment \\n6. When the transaction is complete, don't forget to keep the receipt for your records `,
        'funds.refund.comm.makingpayment_ATM_detail_Danamon',
      ),
      otherbank: __(
        `1. Select "Transfer" \\n2. Select "Transfer to other bank" and select "Danamon Bank" as the destination \\n3. Enter Danamon bank code (011) and your 16-digit payment code \\n4. Enter the transaction amount exactly as it appears on your order \\n5. When the payment confirmation screen is displayed, select "Yes" `,
        'funds.refund.comm.makingpayment_otherbankpayment_detail_Danamon',
      ),
    },
  },
  ID013: {
    bankCode: 'ID013',
    bankShortName: 'Maybank',
    name: 'Bank Maybank (formerly BII)',
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/bank_maybank_d53752.png',
      pngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/maybank_light_3_d9a1fe.png',
      darkModePngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/Maybank_dark_3_8eca01.png',
      voucherIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/maybank-code_459eb2.png',
      grayIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/bank-maybank-gray_056c14.png',
    },
    operationGuide: {
      mobile: __(
        '1. Select "Fund Transfer" \\n2. Select "Virtual Account" \\n3. Select your source account \\n4. Enter your Virtual Account Number \\n5. Enter the transaction amount exactly as it appears on your order \\n6. Enter your TAC to initiate the transaction ',
        'funds.refund.comm.makingpayment_mobilebanking_detail_maybank',
      ),
      internet: __(
        '1. Select "Fund Transfer" \\n2. Select "Virtual Account" \\n3. Select your source account \\n4. Enter your Virtual Account Number \\n5. Enter the transaction amount exactly as it appears on your order \\n6. Enter your TAC to initiate the transaction ',
        'funds.refund.comm.makingpayment_internetbanking_detail_maybank',
      ),
      atm: __(
        '1. Select "Payment" \\n2. Select "Virtual Account" \\n3. Enter your Virtual Account Number and select "Correct" ',
        'funds.refund.comm.makingpayment_ATM_detail_maybank',
      ),
      otherbank: __(
        '1. Select "Transfer" under the bank menu \\n2. Enter Maybank bank code (016) and enter your Virtual Account Number \\n3. Enter the transaction amount exactly as it appears on your order \\n4. When the payment confirmation screen is displayed, select "Yes" ',
        'funds.refund.comm.makingpayment_otherbankpayment_detail_maybank',
      ),
    },
  },
  ID015: {
    bankCode: 'ID015',
    name: 'Bank Permata',
    bankShortName: 'Permata',
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/bank_permata_v1_05c0c2.png',
      pngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/permata_light_3_38cb42.png',
      darkModePngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/Permata_dark_3_ec0947.png',
      voucherIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/permata-code_a62baf.png',
      grayIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/bank-permata-gray_55eff7.png',
    },
    operationGuide: {
      mobile: __(
        '1. Log in to your PermataMobile X account \\n2. Select "Billing Payment" \\n3. Select "Virtual Account" and enter your Virtual Account Number \\n4. Enter the transaction amount exactly as it appears on your order \\n5. Enter your TAC to initiate the transaction',
        'funds.refund.comm.makingpayment_mobilebanking_detail_permata',
      ),
      internet: __(
        '1. Log in to your PermataNet account \\n2. Select "Billing Payment" \\n3. Select "Virtual Account" and enter your Virtual Account Number \\n4. Enter the transaction amount exactly as it appears on your order \\n5. Enter your TAC to initiate the transaction',
        'funds.refund.comm.makingpayment_internetbanking_detail_permata',
      ),
      atm: __(
        '1. Select "Other Transaction" \\n2. Select "Payment" then "Other Payment" \\n3. Select "Virtual Account" \\n4. Enter your Virtual Account Number \\n5. Confirm that the correct payment amount is displayed on the screen ',
        'funds.refund.comm.makingpayment_ATM_detail_permata',
      ),
      otherbank: __(
        '1. Select "Transfer" \\n2. Enter Permata bank code (013) and select "CORRECT", then add the prefix (8641) followed by the 12-digit payment code \\n3. Enter the transaction amount exactly as it appears on your order \\n4. Once complete, make sure the payment confirmation screen is displayed ',
        'funds.refund.comm.makingpayment_otherbankpayment_detail_permata',
      ),
    },
  },
  ID139: {
    bankCode: 'ID139',
    name: 'Artajasa Electronic Payment',
    bankShortName: 'Artajasa',
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/bank_artajasa_v1_cd78d4.png',
      pngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/artajasa_dark_3_a66cba.png',
      darkModePngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/artajasa_dark_3_a66cba.png',
      voucherIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/artajasa-code_fe9cca.png',
      grayIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/bank-artajasa-gray_e53ab5.png',
    },
    operationGuide: {
      mobile: __(
        '1. Open the app affiliated with ATM Bersama \\n2. Select "Transfer to other bank" \\n3. Enter the Artajasa bank code (987) and your Virtual Account Number \\n4. Enter the transaction amount exactly as it appears on your order \\n5. Confirm the information to initiate payment ',
        'funds.refund.comm.makingpayment_mobilebanking_detail_artajasa',
      ),
      atm: __(
        '1. Insert your ATM card with an ATM Bersama logo \\n2. Select "Transfer to other bank" \\n3. Enter the Artajasa bank code (987) and your Virtual Account Number \\n4. Enter the transaction amount exactly as it appears on your order \\n5. Confirm the information to initiate payment ',
        'funds.refund.comm.makingpayment_ATM_detail_artajasa',
      ),
    },
  },
  ID155: {
    bankCode: 'ID155',
    name: 'Bank Central Asia',
    bankShortName: 'BCA',
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/bank-bca_8d554a.png',
      pngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/bank-bca_8d554a.png',
      voucherIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/bca-code_7ac8f4.png',
      grayIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/bank-bca-gray_9ddd6b.png',
    },
    operationGuide: {
      mobile: __(
        '1. Log in to your BCA mobile banking account and enter your m-BCA access code \\n2. Select "m-Transfer" then "BCA Virtual Account" \\n3. Select "Daftar Transfer" or enter your 16-digit payment code \\n4. Enter your m-BCA PIN to initiate the payment \\n5. Take a screenshot of the confirmation screen or save the payment notification for your records. ',
        'funds.refund.comm.makingpayment_mobilebanking_detail_BCA',
      ),
      internet: __(
        `1. Log in to your BCA Internet Banking account at https://klikbca.com \\n2. Select "Transfer Dana" then "Transfer ke BCA Virtual Account" \\n3. On the payment column, enter your 16-digit payment code \\n4. Confirm that the Virtual Account code, account holder name, products, and payment amount are correct \\n5. Enter your password and mToken \\n6. When the transaction is complete, don't forget to keep the transfer receipt for your records`,
        'funds.refund.comm.makingpayment_internetbanking_detail_BCA',
      ),
      atm: __(
        `1. Insert your ATM card and enter your PIN \\n2. Select "Transaksi Lainnya" > "Transfer" > "ke Rekening BCA Virtual Account" \\n3. Enter your 16-digit payment code \\n4. Confirm that the Virtual Account code, account holder name, products, and payment amount are correct \\n5. Enter the transaction amount exactly as it appears on your order \\n6. Follow the on-screen instructions to initiate the payment \\n7. When the transaction is complete, don't forget to keep the transfer receipt for your records `,
        'funds.refund.comm.makingpayment_ATM_detail_BCA',
      ),
    },
  },
})
