import React from 'react'

import { Channel } from '@/components'
import { ChannelItem } from '@/components/Channel'
import ChannelItemTitle from '@/components/ChannelItemTitle'
import { ChannelRes } from '@/services/types'

interface IProps {
  dataSource: ChannelRes[]
  activeChannel: string
  onChannelChange: (key: string, data?: any) => void
  errorMsg: string | undefined
  renderPayWayExtra: (item: ChannelRes) => React.ReactNode
}
const PayWayChannel = (props: IProps) => {
  const { dataSource, activeChannel, onChannelChange, errorMsg, renderPayWayExtra } = props

  const renderPayWays = () => {
    return dataSource.map((i) => {
      const disable = !!errorMsg || i.disable
      const c = {
        key: i.payWay,
        name: i.paymentsTitle,
        content: !disable && renderPayWayExtra(i),
        data: i,
        disable,
        disableTips: disable && i.exceedAmountLimitTips,
        renderTitle: () => <ChannelItemTitle selected={String(i.payWay) === activeChannel} channel={i} />,
      }
      return c
    }) as ChannelItem[]
  }

  return <Channel channels={renderPayWays()} activeChannel={activeChannel} onChange={onChannelChange} />
}

export default PayWayChannel
