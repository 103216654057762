import React, { ChangeEventHandler, FC, useState } from 'react'

import { Checkbox, Input } from '@arco-design/web-react'

import teaClient from '@/utils/tea'

import { ISingleInputFormProps } from './interface'

import './index.less'

const SingleInputForm: FC<ISingleInputFormProps> = ({
  onChange,
  value,
  getInvalidMsg = () => '',
  config: { formTitle, inputPrefix, inputPlaceholder, checkTip, showSavingBox, payWay },
}) => {
  const [invalidMsg, setInvalidMsg] = useState<string>('')

  const inputHandler = (inputVal: string) => {
    const invalidMsg = getInvalidMsg(inputVal)
    setInvalidMsg(invalidMsg)
    onChange({
      id: inputVal,
      save: value.save,
      valid: !invalidMsg,
    })
  }

  const clickHandler = (checked: boolean) => {
    onChange({
      ...value,
      save: checked,
    })

    teaClient.sendPayPageClick({
      pay_way: payWay,
      click_item: checked ? 'save_for_next_time' : 'unsave_for_next_time',
    })
  }

  const blurHandler: ChangeEventHandler<{
    value: string
  }> = ({ target: { value } }) => {
    setInvalidMsg(getInvalidMsg(value))

    teaClient.sendPayPageFillIn({
      pay_way: payWay,
      is_valid: !getInvalidMsg(value),
      invalid_reason: 'pattern',
      scenario: 'single_input',
    })
  }

  return (
    <div className="upay-single-form">
      {formTitle && <div className="upay-single-form__title">{formTitle}</div>}
      <Input
        id="upay-phonenumber-sigle-input"
        value={value.id}
        onChange={inputHandler}
        onBlur={blurHandler}
        error={!!invalidMsg}
        size="large"
        type="text"
        addBefore={inputPrefix}
        placeholder={inputPlaceholder}
      />
      <div className="upay-single-form__msg">{invalidMsg}</div>
      {showSavingBox && (
        <div className="upay-single-form__save">
          <Checkbox style={{ paddingLeft: 0 }} checked={!!value.save} onChange={clickHandler}>
            {checkTip}
          </Checkbox>
        </div>
      )}
    </div>
  )
}
export default SingleInputForm
