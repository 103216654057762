import React, { forwardRef, useEffect, useState } from 'react'
import { ControllerFieldState, useForm } from 'react-hook-form'

import FormBuilder from '@/components/FormBuilder'
import { PAY_WAY_TYPE } from '@/constant'
import { usePipoRouter, useTouchedErrors } from '@/pipo/hooks'
import { PaymentParamsItem, PipoComponentRef, PipoProvider, PipoSubmitParams, generateCommonPaymentParams } from '@/pipo/utils'
import { BANKTRANSFER_MX_PAYMENT_METHOD, BANKTRANSFER_MX_PAYMENT_PARAMS_MAP, BanktransferMXFormData, BanktransferMXProps } from '@/pipo/utils'
import starling from '@/utils/starling'
import teaClient from '@/utils/tea'

import { SaveBox } from '../../inner-components'
import { SCHEMA } from './conf'

export const MXBanktransferForm = forwardRef((props: BanktransferMXProps, ref: React.ForwardedRef<PipoComponentRef>) => {
  const {
    showSavingBox = false,
    onValidate = () => {
      // do nothing
    },
  } = props
  const form = useForm<BanktransferMXFormData>({
    mode: 'all',
  })
  const {
    control,
    getValues,
    formState: { errors: formErrors, isValid, touchedFields },
  } = form
  const errors = useTouchedErrors<BanktransferMXFormData>({
    touchedFields,
    errors: formErrors,
  })

  const { t, state, getPublicKeyAsync } = PipoProvider.useContext()
  const { config = {} } = state
  const { selectedSaveBox = false } = config

  const [storeCard, setStoreCard] = useState<boolean>(selectedSaveBox)

  useEffect(() => {
    isValid && onValidate(true)
  }, [isValid, onValidate])

  /**
   * 处理PIPO提交所需数据
   * @returns PipoSubmitParams
   */
  const processSubmitData = async (formData: BanktransferMXFormData): Promise<PipoSubmitParams> => {
    const publicKey = await getPublicKeyAsync()
    const paymentParams: PaymentParamsItem[] = generateCommonPaymentParams(formData, BANKTRANSFER_MX_PAYMENT_PARAMS_MAP, publicKey)

    const { paymentMethodType, paymentMethod } = BANKTRANSFER_MX_PAYMENT_METHOD
    return {
      formData,
      paymentMethod: {
        paymentMethod: paymentMethod.paymentMethod,
        paymentMethodId: paymentMethod.paymentMethodId,
      },
      paymentMethodType: paymentMethodType.paymentMethodType,
      paymentParams,
      storeCard: storeCard && showSavingBox,
    }
  }

  /**
   * 校验后获取PIPO提交所需数据
   * @returns PipoSubmitParams
   */
  const validateSubmitData = () => {
    return new Promise(async (resolve: (value: PipoSubmitParams) => void, reject: (reason: typeof errors) => void) => {
      if (!isValid) {
        reject(errors)
      } else {
        const formData = getValues()
        resolve(await processSubmitData(formData))
      }
    })
  }

  usePipoRouter(ref, {
    initParams: {
      onValidate,
      isValid,
    },
    instanceMethods: {
      validateSubmitData,
    },
  })

  const handleBlur = (scenario: string, fieldState: ControllerFieldState) => {
    const params = {
      pay_way: PAY_WAY_TYPE.BANK_TRANSFER_MX,
      scenario,
      is_valid: !fieldState.error,
      invalid_reason: fieldState.error?.type,
    }
    teaClient.sendPayPageFillIn(params)
  }

  return (
    <div className="payment-banktransfer-payeasy" id="upay-form-banktransfer-mx">
      <FormBuilder form={form} schema={SCHEMA({ t })} errors={errors} handleBlur={handleBlur} />

      {/* Save Box */}
      {showSavingBox && (
        <SaveBox title={starling('funds.refund.comm.system_save_card_information')} storeCard={storeCard} setStoreCard={setStoreCard} />
      )}
    </div>
  )
})
