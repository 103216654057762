import React from 'react'
import { Controller } from 'react-hook-form'

import PaymentItemCard from '@/pages/Payout/components/PaymentItemCard'
import { CardListRes } from '@/services/types'

import { useBoundElements } from '../../hooks'
import { getExtraComponent } from './utils'

import './index.less'

interface IProps {
  card: CardListRes
  control: any
  forwardRef: any
}
const UpdateWidget: React.FC<IProps> = (props) => {
  const { card, control, forwardRef } = props
  const Comp = getExtraComponent(card.payWay)

  const { boundElements } = useBoundElements({ cardId: card.id })

  return (
    <div className="upay-payout-update-widget">
      <div className="upay-payout-item-header">
        <img className="upay-payout-item-logo" src={card.imageUrl} />

        <PaymentItemCard paymentItem={card} />
      </div>

      {Comp ? (
        <Controller name="updateItem" control={control} render={({ field }) => <Comp {...field} ref={forwardRef} paramsList={boundElements} />} />
      ) : null}
    </div>
  )
}

export default UpdateWidget
