import { useContext } from 'react'

import { JS_CDN_DOMAIN_PREFIX } from '@/constant'
import { ParamsContext } from '@/stores'

import { loadScript } from '../utils'

export const RECORDER_EVENT_HUB = {
  USER_LEAVE: 'user leave',
  PAY_SUCCESS: 'pay success',
  BIND_SUCCESS: 'bind success',
  PAY_FAIL: 'pay fail',
  BIND_FAILL: 'bind fail',
  PAY_PENDING: 'pay pending',
}
const useRecorder = () => {
  const {
    urlQuery: { idc },
  } = useContext(ParamsContext)
  const bid = 'upay_sdk_web'
  const recorderStart = async (userId: string) => {
    // ttp 或者其他模块已经开启过录制 不做任何处理
    if (idc === 'oci' || window.upay_recorder?.isStarted) return
    try {
      if (!window.upay_recorder) {
        const loadCb = () => {
          window.upay_recorder = new window.OolongTeaRecorder(bid, {
            mode: 'control-mode',
          })
        }
        await loadScript(`${JS_CDN_DOMAIN_PREFIX}/cg/fe/upay_sdk_web/oolong-tea@0.0.1.js`, loadCb)
      }
      console.log(
        `%c oolongTea %c start %c`,
        'background:#35495e ; padding: 2px; border-radius: 3px 0 0 3px;  color: #fff',
        'background:#41b883 ; padding: 2px; border-radius: 0 3px 3px 0;  color: #fff',
        'background:transparent',
      )
      window.upay_recorder?.config({
        uid: userId,
      })
      window.upay_recorder?.startRecorder()
      window.upay_recorder.isStarted = true
    } catch {
      console.log('Recorder error!')
    }
  }
  const recorderStopAndSend = (message: string) => {
    // ttp 环境不做任何处理
    if (idc === 'oci' || !window.upay_recorder?.isStarted) return
    try {
      console.log(
        `%c oolongTea %c end ${message} %c`,
        'background:#35495e ; padding: 2px; border-radius: 3px 0 0 3px;  color: #fff',
        'background:#41b883 ; padding: 2px; border-radius: 0 3px 3px 0;  color: #fff',
        'background:transparent',
      )
      window.upay_recorder?.stopRecorder()
      window.upay_recorder?.send(message)
      window.upay_recorder.isStarted = false
    } catch {
      console.log('Recorder error!')
    }
  }

  return {
    recorderStart,
    recorderStopAndSend,
  }
}
export default useRecorder
