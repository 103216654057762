import { IdcT } from '@upay/utils/es/types'

import { IParamsContext } from './stores/interface'
import { IOptions, IUrlQuery } from './typings'
import utils from './utils'
import { initFeelGood } from './utils/init/initFeelgood'
import { initI18n } from './utils/init/initI18n'
import { getPubSub } from './utils/init/initPubsub'
import { getRenderPage } from './utils/init/initRoute'
import { initThemeColor } from './utils/init/initTheme'
import { initMssdk } from './utils/mssdk'
import { requestAdaptorTTP } from './utils/requestExtensions'
import slardar, { logger } from './utils/slardar'
import { initTea } from './utils/tea'

export interface IInitSDK {
  RenderContent: JSX.Element
  paramsData: IParamsContext
}

/**
 * SDK初始化方法
 */
export const initSDK = async (options: IOptions, uniqueId: string, initStartTime: number): Promise<IInitSDK> => {
  const { container, url, params } = options
  const urlQuery = (utils.parseQueryString(url) || {}) as IUrlQuery
  const { accountId, type, countryCode, bizId, appId, lang, idc } = urlQuery
  // 针对 TCM US 特殊场景进行请求 URL 替换 （业务本身不是 TTP 机房，支付需要走 TTP）
  params.isTtp = requestAdaptorTTP(url) || params.isTtp
  // 如果后端链接没有返回idc，上报 slardar
  if (!idc) {
    slardar.timer('illegalIdc', 1, {
      url,
      params,
      urlQuery,
    })
  }
  console.log('UPAY USER CURRENT IDC:', idc)

  // 路由渲染
  const { RenderContent, pid } = await getRenderPage(url, urlQuery)

  // 第三方依赖初始化
  slardar.init({ userId: accountId, pid, url, bizId, appId, countryCode, invokerId: options.params?.invokerId || '' })
  logger.info('upaySdkInit', { container, params })

  initThemeColor(params.colors)
  initMssdk(+appId, utils.getIdc(idc) as IdcT)
  initTea({
    user_unique_id: accountId,
    countryCode,
    type,
    channel: utils.getIdc(idc),
    bizId,
  })
  // 优先级：params.language > urlQuery.lang > cookie.lang_type > cookie.lang >> default = 'en'
  await initI18n(params.language || lang)
  initFeelGood(accountId, bizId, countryCode, params.isBoe) // 需要先确定语言

  // 收银台初始化总时长
  const diff = new Date().getTime() - initStartTime
  logger.print('UPAY_SDK_INIT_TIME：', diff)
  slardar.performance('sdk_init_time', diff)

  return {
    RenderContent,
    paramsData: {
      pid,
      params,
      urlQuery,
      unique: uniqueId,
      uPubSub: getPubSub(uniqueId),
      initStartTime,
      initPageId: pid,
    },
  }
}
