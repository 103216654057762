import React, { forwardRef, useEffect, useState } from 'react'
import { Controller, ControllerFieldState, useForm } from 'react-hook-form'

import { PAY_WAY_TYPE } from '@/constant'
import { usePipoRouter, useSubmittingState, useTouchedErrors } from '@/pipo/hooks'
import { PaymentParamsItem, PipoComponentRef, PipoProvider, PipoSubmitParams, generateCommonPaymentParams, validator } from '@/pipo/utils'
import starling from '@/utils/starling'
import teaClient from '@/utils/tea'

import { AuthorizeBox, Email, HolderName, ItemInputNumber, SaveBox } from '../../inner-components'
import { DIRECT_DEBIT_PAYMENT_METHOD, DIRECT_DEBIT_PAYMENT_PARAMS_MAP, DirectDebitFormData, DirectDebitProps } from './utils'

import './index.less'

const clsPrefix = 'pipo-bindcard-pc-direct-debit'

export const DirectDebitUK = forwardRef((props: DirectDebitProps, ref: React.ForwardedRef<PipoComponentRef>) => {
  const {
    showSavingBox = true,
    onSubmit,
    onValidate = () => {
      // do nothing.
    },
  } = props

  const form = useForm<DirectDebitFormData>({
    mode: 'all',
  })

  const {
    control,
    handleSubmit: handleFormSubmit,
    getValues,
    formState: { errors: formErrors, isValid, touchedFields },
  } = form

  const errors = useTouchedErrors<DirectDebitFormData>({
    touchedFields,
    errors: formErrors,
  })
  const { state, getErrorText, getPublicKeyAsync } = PipoProvider.useContext()

  const { config = {} } = state

  const { selectedSaveBox = false } = config
  const [mandateChecked, setMandateChecked] = useState(false) // 是否勾选mandate，必须勾选mandate才可以提交
  const { formSubmitState } = useSubmittingState()

  // TODO: can be modified from outside
  const [storeCard, setStoreCard] = useState<boolean>(selectedSaveBox)

  useEffect(() => {
    if (isValid) {
      mandateChecked && onValidate(true)
    } else {
      setMandateChecked(false)
    }
  }, [isValid, mandateChecked, onValidate])

  const processSubmitData = async (formData: DirectDebitFormData): Promise<PipoSubmitParams> => {
    ;(formData as any).signature_timestamp = Date.now().toString().slice(0, -3)
    const paramsMap = DIRECT_DEBIT_PAYMENT_PARAMS_MAP

    const publicKey = await getPublicKeyAsync()
    const paymentParams: PaymentParamsItem[] = generateCommonPaymentParams(formData, paramsMap, publicKey)
    const { paymentMethodType, paymentMethod } = DIRECT_DEBIT_PAYMENT_METHOD

    return {
      formData,
      paymentMethod: {
        paymentMethod: paymentMethod.paymentMethod,
        paymentMethodId: paymentMethod.paymentMethodId,
      },
      paymentMethodType: paymentMethodType.paymentMethodType,
      paymentParams,
      storeCard: storeCard && showSavingBox,
    }
  }

  const validateSubmitData = () => {
    return new Promise(async (resolve: (value: PipoSubmitParams) => void, reject: (reason: typeof errors) => void) => {
      if (!isValid) {
        reject(errors)
      } else {
        const formData = getValues()
        resolve(await processSubmitData(formData))
      }
    })
  }

  const handleSubmit = async (formData: DirectDebitFormData): Promise<void> => {
    const submitData = await processSubmitData(formData)
    formSubmitState.setTrue()
    await onSubmit?.(submitData)
    formSubmitState.setFalse()
  }

  usePipoRouter(ref, {
    initPath: 'direct-debit',
    initParams: {
      isValid: isValid && mandateChecked,
      onValidate,
    },
    instanceMethods: {
      submit: handleFormSubmit(handleSubmit),
      validateSubmitData,
    },
  })

  const handleBlur = (scenario: string, fieldState: ControllerFieldState) => {
    const params = {
      pay_way: PAY_WAY_TYPE.DIRECT_DEBIT_UK,
      scenario,
      is_valid: !fieldState.error,
      invalid_reason: fieldState.error?.type,
    }
    teaClient.sendPayPageFillIn(params)
  }

  return (
    <div className="pipo-pc">
      <div className={`${clsPrefix}-flex-container`}>
        <div className={`${clsPrefix}-form-wrapper`}>
          <div className={`${clsPrefix}-subform-wrapper`}>
            <Controller
              rules={{ required: true, pattern: validator.nameRegex }}
              name="holder_name"
              control={control}
              render={({ field, fieldState }) => (
                <HolderName
                  {...field}
                  len="half"
                  error={errors.holder_name}
                  errorMessage={getErrorText(errors.holder_name?.type)}
                  title={starling('funds.refund.comm.system_name')}
                  placeholder={starling('funds.refund.comm.system_name_description')}
                  maxLength={40}
                  onBlur={() => {
                    field.onBlur()
                    handleBlur('holder_name', fieldState)
                  }}
                />
              )}
            />
            <Controller
              rules={{ required: true }}
              name="account_no"
              control={control}
              render={({ field, fieldState }) => (
                <ItemInputNumber
                  {...field}
                  len="half"
                  error={errors.account_no}
                  errorMessage={getErrorText(errors.account_no?.type)}
                  title={starling('funds.refund.comm.system_bankaccount_account_number')}
                  placeholder={starling('funds.refund.comm.system_bankaccount_account_number_placeholder')}
                  maxLength={40}
                  onBlur={() => {
                    field.onBlur()
                    handleBlur('account_no', fieldState)
                  }}
                />
              )}
            />
            <Controller
              rules={{ required: true }}
              name="routing_no"
              control={control}
              render={({ field, fieldState }) => (
                <ItemInputNumber
                  {...field}
                  len="half"
                  error={errors.account_no}
                  errorMessage={getErrorText(errors.account_no?.type)}
                  title={starling('funds.check_out.comm.sort_code')}
                  placeholder={starling('funds.check_out.comm.sort_code')}
                  maxLength={40}
                  onBlur={() => {
                    field.onBlur()
                    handleBlur('routing_no', fieldState)
                  }}
                />
              )}
            />
            <Controller
              rules={{ required: true, pattern: validator.emailRegex }}
              name="email"
              control={control}
              render={({ field, fieldState }) => (
                <Email
                  {...field}
                  title={starling('funds.refund.comm.system_email')}
                  placeholder={starling('funds.refund.comm.system_email_placeholder')}
                  error={errors.email}
                  errorMessage={getErrorText(errors.email?.type)}
                  onBlur={() => {
                    field.onBlur()
                    handleBlur('email', fieldState)
                  }}
                />
              )}
            />
            {/* Save Box */}
            {showSavingBox && (
              <SaveBox title={starling('funds.refund.comm.system_save_card_information')} storeCard={storeCard} setStoreCard={setStoreCard} />
            )}
            <AuthorizeBox
              title={starling('funds.check_out.comm.uk_terms')}
              disabled={!isValid}
              authorizeCard={mandateChecked}
              setAuthorizeCard={setMandateChecked}
            />
          </div>
        </div>
      </div>
    </div>
  )
})
