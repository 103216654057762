import React from 'react'

import { OrderStatus } from '@/pipo/utils'

import './index.less'

interface IExpiredMaskProps {
  children: React.ReactNode
  orderStatus: OrderStatus
}

export const ExpiredMask = ({ children, orderStatus }: IExpiredMaskProps) => {
  return (
    <div className="upay-voucher-expired-mask-container">
      {children}
      <div className={`upay-voucher-expired-mask ${['success', 'failed', 'expired', 'closed'].includes(orderStatus) ? 'show-mask' : ''}`}></div>
    </div>
  )
}
