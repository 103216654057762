import { PaymentMethodItem } from '../../types'

export type TransferJPFormDataType = {
  phone_country_code: string
  phone: string
  phone_number?: string
  email?: string
  holder_first_name?: string
  holder_last_name?: string
  holder_first_name_katakana?: string
  holder_last_name_katakana?: string
  identity?: string
}

export const JPBankTransfer_PAYMENT_PARAMS_MAP: Record<string, { element: string; is_encrypted?: boolean }> = {
  identity: {
    element: 'eg_pi_ew_paycell_c_d_local_tr_identity',
    is_encrypted: true,
  },
  email: {
    element: 'eg_pi_bt_banktransfer_c_d_local_jp_email',
    is_encrypted: true,
  },
  phone: {
    element: 'eg_pi_bt_banktransfer_c_d_local_jp_phone',
    is_encrypted: true,
  },
  phone_country_code: {
    element: 'eg_pi_bt_banktransfer_c_d_local_jp_phone_country_code',
    is_encrypted: true,
  },
  holder_first_name: {
    element: 'eg_pi_bt_banktransfer_c_d_local_jp_holder_first_name',
    is_encrypted: true,
  },
  holder_first_name_katakana: {
    element: 'eg_pi_bt_banktransfer_c_d_local_jp_holder_first_name_katakana',
    is_encrypted: true,
  },
  holder_last_name: {
    element: 'eg_pi_bt_banktransfer_c_d_local_jp_holder_last_name',
    is_encrypted: true,
  },
  holder_last_name_katakana: {
    element: 'eg_pi_bt_banktransfer_c_d_local_jp_holder_last_name_katakana',
    is_encrypted: true,
  },
}

export const JPBankTransfer_PAYMENT_METHOD: PaymentMethodItem = {
  paymentMethodType: {
    configuration: {},
    icon: {
      iconUrl: '',
    },
    paymentMethodType: 'BANK_TRANSFER',
    starling_key: '',
  },
  paymentMethod: {
    configuration: {},
    paymentMethod: 'JPBankTransfer',
    paymentMethodId: 'pm_pi_bt_banktransfer_c_d',
    starling_key: '',
    icon: {
      iconUrl: '',
      pngIconUrl: '',
      darkModePngIconUrl: '',
      grayIconUrl: '',
      detailIconUrl: '',
    },
    displayName: 'JPBankTransfer',
    title: {
      payment: '',
      piListShort: '',
      piListLong: '',
    },
  },
}
