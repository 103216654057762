import React, { forwardRef, useEffect, useState } from 'react'
import { Controller, ControllerFieldState, useForm } from 'react-hook-form'

import { useRequest } from '@byted/hooks'
import { pick } from 'lodash'

import { PAY_WAY_TYPE } from '@/constant'
import { useAddressPC, usePipoRouter, usePostalCode, useSubmittingState, useTouchedErrors } from '@/pipo/hooks'
import {
  PaymentParamsItem,
  PipoComponentRef,
  PipoProvider,
  PipoSubmitParams,
  countryToISOCode,
  generateCommonPaymentParams,
  pickNumeric,
  validator,
} from '@/pipo/utils'
import starling from '@/utils/starling'
import teaClient from '@/utils/tea'

import {
  AccountType,
  BillingAddress,
  HolderName,
  ItemInput,
  ItemInputNumber,
  MandateBox,
  ProtocolDirectDebitEU,
  SaveBox,
} from '../../inner-components'
import { bicRegex, getLocalBankRules } from './getLocalBankRules'
import {
  DIRECT_DEBIT_B_D_PAYMENT_METHOD,
  DIRECT_DEBIT_B_D_PAYMENT_PARAMS_MAP,
  DIRECT_DEBIT_C_D_PAYMENT_METHOD,
  DIRECT_DEBIT_C_D_PAYMENT_PARAMS_MAP,
  DirectDebitFormData,
  DirectDebitProps,
  generateMandateInfo,
} from './utils'

const clsPrefix = 'pipo-bindcard-pc-direct-debit'

// TODO 待确认
export const DirectDebitEU = forwardRef((props: DirectDebitProps, ref: React.ForwardedRef<PipoComponentRef>) => {
  const {
    showSavingBox = true,
    contractAddressList,
    onFetchDistrict,
    onFetchMandate,
    onSubmit,
    onValidate = () => {
      // do nothing.
    },
  } = props
  const {
    control,
    watch,
    handleSubmit: handleFormSubmit,
    getValues,
    setValue,
    trigger,
    setError,
    clearErrors,
    formState: { errors: formErrors, isValid, touchedFields },
  } = useForm<DirectDebitFormData>({
    mode: 'all',
  })
  const errors = useTouchedErrors<DirectDebitFormData>({
    touchedFields,
    errors: formErrors,
  })

  const { state, t, getErrorText, getPublicKeyAsync } = PipoProvider.useContext()
  const { countryOrRegion = '', config = {} } = state

  const billingAddressProps = useAddressPC({
    onFetchDistrict,
    getValues,
    setValue,
    trigger,
    clearErrors,
    setError,
    watch,
    formErrors,
    countryOrRegion,
    contractAddressList,
  } as any)
  const { list, selectedAddressArr, selectedContractAddress, getFillAddressState } = billingAddressProps
  const postalCodeProps = usePostalCode({
    selectedAddressArr,
    watch,
    trigger,
  } as any)

  const { ibanRules } = getLocalBankRules(countryOrRegion || '')
  const { selectedSaveBox = false } = config
  const [mandateChecked, setMandateChecked] = useState(false) // 是否勾选mandate，必须勾选mandate才可以提交
  const { formSubmitState } = useSubmittingState()
  const { data: mandateInfo = [], run: fetchMandate } = useRequest(onFetchMandate, { auto: false })

  // Monitor select box value to render BusinessAccount or PersonalAccount
  const renderBusiness = watch('account_type') === '0'

  // TODO: can be modified from outside
  const [storeCard, setStoreCard] = useState<boolean>(selectedSaveBox)

  useEffect(() => {
    if (isValid) {
      mandateChecked && onValidate(true)
    } else {
      setMandateChecked(false)
    }
  }, [isValid, mandateChecked, onValidate])

  useEffect(() => {
    const method = renderBusiness ? DIRECT_DEBIT_B_D_PAYMENT_METHOD : DIRECT_DEBIT_C_D_PAYMENT_METHOD
    fetchMandate({
      payment_method_id: method.paymentMethod.paymentMethodId,
      payment_method: 'sepa_direct_debit',
      payment_type: 'direct_debit',
      integration_type: 'direct',
      user_type: renderBusiness ? 'b' : 'c',
    })
  }, [renderBusiness, fetchMandate])

  const processSubmitData = async (formData: DirectDebitFormData): Promise<PipoSubmitParams> => {
    delete formData.contract_address // 删除内部使用字段
    if (selectedContractAddress) {
      formData.billing_street = selectedContractAddress.detailAddress
      formData.billing_country_region = selectedContractAddress.countryCode
      formData.billing_postal_code = selectedContractAddress.postcode
      formData.billing_state = selectedContractAddress.level2Name
      formData.billing_city = selectedContractAddress.level3Name
    } else if (formData.billing_country_region && list[0].list) {
      formData.billing_country_region = countryToISOCode(formData.billing_country_region, list[0].list)
    }
    if (formData.bic)
      formData.bic = pickNumeric(formData.bic)
      // 添加提交时间的时间戳作为参数
    ;(formData as any).signature_timestamp = Date.now().toString().slice(0, -3)

    const paymentElementProps = pick(formData, ['iban', 'holder_name'])
    const paramsMap = renderBusiness ? DIRECT_DEBIT_B_D_PAYMENT_PARAMS_MAP : DIRECT_DEBIT_C_D_PAYMENT_PARAMS_MAP

    const publicKey = await getPublicKeyAsync()
    const paymentParams: PaymentParamsItem[] = generateCommonPaymentParams(paymentElementProps, paramsMap, publicKey)
    const mandateInfoData = generateMandateInfo(mandateInfo, formData)
    const method = renderBusiness ? DIRECT_DEBIT_B_D_PAYMENT_METHOD : DIRECT_DEBIT_C_D_PAYMENT_METHOD
    const { paymentMethodType, paymentMethod } = method

    return {
      formData,
      paymentMethod: {
        paymentMethod: paymentMethod.paymentMethod,
        paymentMethodId: paymentMethod.paymentMethodId,
      },
      paymentMethodType: paymentMethodType.paymentMethodType,
      paymentParams,
      extraParams: {
        mandateInfo: mandateInfoData,
      },
      storeCard: storeCard && showSavingBox,
      fillAddressState: getFillAddressState(),
    }
  }

  const validateSubmitData = () => {
    return new Promise(async (resolve: (value: PipoSubmitParams) => void, reject: (reason: typeof errors) => void) => {
      if (!isValid) {
        reject(errors)
      } else {
        const formData = getValues()
        resolve(await processSubmitData(formData))
      }
    })
  }

  const handleSubmit = async (formData: DirectDebitFormData): Promise<void> => {
    const submitData = await processSubmitData(formData)
    formSubmitState.setTrue()
    await onSubmit?.(submitData)
    formSubmitState.setFalse()
  }

  const { activePath, routerParams } = usePipoRouter(ref, {
    initPath: 'direct-debit',
    initParams: {
      isValid: isValid && mandateChecked,
      onValidate,
    },
    instanceMethods: {
      submit: handleFormSubmit(handleSubmit),
      validateSubmitData,
    },
  })

  const handleBlur = (scenario: string, fieldState: ControllerFieldState) => {
    const params = {
      pay_way: PAY_WAY_TYPE.DIRECT_DEBIT_EU,
      scenario,
      is_valid: !fieldState.error,
      invalid_reason: fieldState.error?.type,
    }
    teaClient.sendPayPageFillIn(params)
  }

  return (
    <div className="pipo-pc">
      <div className={`${clsPrefix}-flex-container`}>
        <div className={`${clsPrefix}-form-wrapper`}>
          <div className={`${clsPrefix}-subform-wrapper`}>
            <Controller
              rules={{ required: true }}
              name="account_type"
              defaultValue={'0'}
              control={control}
              render={({ field }) => <AccountType {...field} title={t('pipo_common_payin_account_type')} />}
            />
            <Controller
              rules={{ required: true, pattern: validator.nameRegex }}
              name="holder_name"
              control={control}
              render={({ field, fieldState }) => (
                <HolderName
                  {...field}
                  len="full"
                  error={errors.holder_name}
                  errorMessage={getErrorText(errors.holder_name?.type)}
                  title={starling('funds.checkout.comm.system_Accountholder_name')}
                  placeholder={starling('funds.checkout.comm.system_Accountholder_prompt')}
                  maxLength={50}
                  onBlur={() => {
                    field.onBlur()
                    handleBlur('holder_name', fieldState)
                  }}
                />
              )}
            />
            <Controller
              rules={{ required: true, pattern: ibanRules.value }}
              name="iban"
              control={control}
              render={({ field, fieldState }) => (
                <ItemInput
                  {...field}
                  error={errors.iban}
                  errorMessage={getErrorText(errors.iban?.type)}
                  title={starling('funds.checkout.comm.system_IBAN')}
                  placeholder={starling('funds.checkout.comm.system_IBAN_prompt')}
                  maxLength={34}
                  onBlur={() => {
                    field.onBlur()
                    handleBlur('iban', fieldState)
                  }}
                />
              )}
            />
            <Controller
              rules={{ required: true, pattern: bicRegex }}
              name="bic"
              control={control}
              render={({ field, fieldState }) => (
                <ItemInputNumber
                  {...field}
                  error={errors.bic}
                  errorMessage={getErrorText(errors.bic?.type)}
                  title={starling('funds.checkout.comm.system_bank_code')}
                  placeholder={starling('funds.checkout.comm.system_bank_code_prompt')}
                  maxLength={11}
                  onBlur={() => {
                    field.onBlur()
                    handleBlur('bic', fieldState)
                  }}
                />
              )}
            />

            {/* billing address selects */}
            <BillingAddress
              title={starling('funds.refund.comm.system_Billing_address')}
              control={control}
              errors={errors}
              postalCodeProps={postalCodeProps}
              {...billingAddressProps}
            />
          </div>

          {/* Save Box */}
          {showSavingBox && <SaveBox title={starling('funds.checkout.comm.system_save_account')} storeCard={storeCard} setStoreCard={setStoreCard} />}

          <ProtocolDirectDebitEU mandateInfo={mandateInfo} />

          <MandateBox disabled={!isValid} mandate={mandateChecked} setMandate={setMandateChecked} />
        </div>
      </div>
    </div>
  )
})
