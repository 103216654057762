const rtlLangs: string[] = ['ar', 'ur', 'he']

export const RTL_CODES = ['ar', 'he-IL', 'ur'] // AR阿拉伯语 HE希伯来语 UR乌尔都语

/**
 * @description rtl能力初始化
 * @param {string} lang
 */
export const initRtl = (lang: string): void => {
  const _isRtl: boolean = isRtl(lang)
  const dir = _isRtl ? 'rtl' : 'ltr'
  const containerDom = document.body
  containerDom?.setAttribute('dir', dir)
}

export const isRtl = (lang: string): boolean => {
  const l = lang.split('-')[0]
  return rtlLangs.includes(l)
}
