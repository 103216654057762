import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react'

import SingleInputForm from '@/components/SingleInputForm'
import { ISingleInputFormValue } from '@/components/SingleInputForm/interface'
import { PAYMENT, PAY_WAY_TYPE } from '@/constant'
import { IPayinMethodItem, PaymentSubmitMethodEnum } from '@/pages/PayNext/interface'
import utils from '@/utils'
import SafeJSON from '@/utils/SafeJSON'
import starling from '@/utils/starling'

import { IPaymentMethodsProps, IPaymentsMethodRef } from '../../interface'

// 提交时须带上的前缀/参数
const PHONE_PREFIX_REQ = '08'
const COUNTRY_CODE = ''

const OVO = forwardRef<IPaymentsMethodRef, IPaymentMethodsProps>((props, ref): React.ReactElement => {
  const {
    changeNewPaymentStatus,
    urlQuery: { token, type },
    paymentSubmitMethod,
    channel,
  } = props
  const [formStatus, setFormStatus] = useState<ISingleInputFormValue>({
    id: '',
    save: true,
    valid: false,
  })

  // TODO: 暂时兼容两种逻辑，后续新UI全量后可梳理移除，由后端维护是否显示
  const showSavingBox = useMemo(() => {
    // 仅支付场景展示SaveBox
    if (paymentSubmitMethod !== PaymentSubmitMethodEnum.PAYMENT) return false

    // 若后端返回 以后端为准 老逻辑未返回排除后付费
    const supportFromBE = (channel as IPayinMethodItem).supportPaymentAndBind
    return typeof supportFromBE === 'boolean' ? supportFromBE : type !== PAYMENT.POST_PAY
  }, [channel, paymentSubmitMethod, type])

  const OVO_CONFIG = useMemo(
    () => ({
      payWay: PAY_WAY_TYPE.OVO,
      formTitle: starling('funds.refund.comm.ovoidtitle'),
      inputPlaceholder: starling('funds.refund.comm.ovoid'),
      checkTip: starling('funds.refund.comm.saveovoid'),
      inputPrefix: '08',
      showSavingBox,
    }),
    [showSavingBox],
  )

  useImperativeHandle(ref, () => ({
    // 提交处理逻辑
    processSubmitData: async (submitParams) => {
      const encryptPhoneNumber = await utils.fetchKeyAndEncryptString(`${PHONE_PREFIX_REQ}${formStatus.id}`, token)
      submitParams.channelParameter = SafeJSON.stringify({
        phoneNumber: encryptPhoneNumber,
        storeToken: formStatus.save,
        encrypted: true,
        countryCode: COUNTRY_CODE,
      })
      submitParams.bindAndPay = formStatus.save && OVO_CONFIG.showSavingBox
      return submitParams
    },
  }))

  useEffect(() => {
    if (formStatus.valid) {
      changeNewPaymentStatus(PAY_WAY_TYPE.OVO, { valid: true })
    } else {
      changeNewPaymentStatus(PAY_WAY_TYPE.OVO, { valid: false })
    }
  }, [changeNewPaymentStatus, formStatus.valid])

  const getInvalidMsg = useCallback((value: string) => {
    const numRegex = /^[0-9]*$/
    if (!value) return starling('funds.refund.comm.enter_number_required')
    if (!numRegex.test(value)) return starling('funds.refund.comm.enter_number_rule')
    if (value.length < 8 || value.length > 11)
      return starling('funds.refund.comm.enter_number_length', {
        numbers1: 8,
        numbers2: 11,
      })
    return ''
  }, [])

  return <SingleInputForm value={formStatus} onChange={setFormStatus} getInvalidMsg={getInvalidMsg} config={OVO_CONFIG} />
})

export default OVO
