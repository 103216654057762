"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault")["default"];
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.getUtils = getUtils;
var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));
function getUtils() {
  return {
    /** 在PIPO API 请求成功，但result_code为error的情况下，生成标准的PipoRequestErrorObj，用于后续抛出 */
    getPipoRequestErrorObjByRes: function getPipoRequestErrorObjByRes(res) {
      var _res$__request, _res$__request2;
      return {
        ERROR_TYPE: 'PIPO_REQUEST_ERROR',
        resultCode: res.result_code,
        errorCode: res.error_code,
        errorMessage: res.error_message,
        logid: res === null || res === void 0 ? void 0 : res['x-tt-logid'],
        http_status: res === null || res === void 0 ? void 0 : (_res$__request = res.__request) === null || _res$__request === void 0 ? void 0 : _res$__request.status,
        url: res === null || res === void 0 ? void 0 : (_res$__request2 = res.__request) === null || _res$__request2 === void 0 ? void 0 : _res$__request2.responseURL
      };
    },
    /** 在PIPO API 请求直接失败（非2xx），传入pipo api方法抛出的错误，生成标准的PipoRequestErrorObj，用于后续抛出 */
    getPipoRequestErrorObjByError: function getPipoRequestErrorObjByError(error) {
      var _error$response, _error$response2, _error$response2$conf, _error$response3, _error$response3$conf, _error$response4, _error$response4$head, _context;
      error.ERROR_TYPE = 'PIPO_REQUEST_ERROR';
      error.http_status = error === null || error === void 0 ? void 0 : (_error$response = error.response) === null || _error$response === void 0 ? void 0 : _error$response.status;
      error.url = ((error === null || error === void 0 ? void 0 : (_error$response2 = error.response) === null || _error$response2 === void 0 ? void 0 : (_error$response2$conf = _error$response2.config) === null || _error$response2$conf === void 0 ? void 0 : _error$response2$conf.baseUrl) || '') + ((error === null || error === void 0 ? void 0 : (_error$response3 = error.response) === null || _error$response3 === void 0 ? void 0 : (_error$response3$conf = _error$response3.config) === null || _error$response3$conf === void 0 ? void 0 : _error$response3$conf.url) || '');
      error.logid = error === null || error === void 0 ? void 0 : (_error$response4 = error.response) === null || _error$response4 === void 0 ? void 0 : (_error$response4$head = _error$response4.headers) === null || _error$response4$head === void 0 ? void 0 : _error$response4$head['x-tt-logid'];
      error.errorMessage = "Network error. Try again later. ".concat(error.http_status || error.logid ? (0, _concat["default"])(_context = "(".concat(error.http_status + '_')).call(_context, error.logid, ")") : '');
      error.errorObjMsg = error.message;
      return error;
    }
  };
}