import React from 'react'

import starling from '@/utils/starling'

import './index.less'

export interface IPolicyProps {
  policyUrl?: string
  showAutoPayHint?: boolean
  onClickPolicy?: () => void
}

const Policy = (props: IPolicyProps): JSX.Element => {
  const { policyUrl, showAutoPayHint, onClickPolicy } = props

  const handleClickPrivacy = () => {
    policyUrl && window.open(policyUrl)

    onClickPolicy?.()
  }

  const showPolicy = policyUrl || showAutoPayHint

  return showPolicy ? (
    <div className="upay-policy">
      {showAutoPayHint && <span>{starling('funds.refund.comm.Automatic_deduction_bottom')} </span>}
      {policyUrl && (
        <>
          <span>{starling('funds.refund.comm.sign_up_int')}</span>
          <span className="upay-policy__btn" onClick={handleClickPrivacy}>
            {starling('funds.refund.comm.sign_up_here')}
          </span>
        </>
      )}
    </div>
  ) : (
    <></>
  )
}

export default Policy
