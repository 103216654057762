import React, { useEffect, useMemo, useRef, useState } from 'react'

import { Spin } from '@arco-design/web-react'
import clsx from 'clsx'

import './index.less'

const DEFAULT_LENGTH = 4

enum CaptchaThemeEnum {
  Line = 'line',
  Box = 'box',
}

interface IProps {
  value?: string
  onChange?: (value: string) => void
  length?: number
  theme?: CaptchaThemeEnum
  autoFocus?: boolean
  errorMsg?: string
  loading?: boolean
  disabled?: boolean
}

/** 验证码输入框 */
const Captcha: React.FC<IProps> = (props) => {
  const { value = '', onChange, length = DEFAULT_LENGTH, autoFocus = true, theme = CaptchaThemeEnum.Box, errorMsg, loading, disabled } = props
  // 组件内部维护的输入框输入值
  const [inputValue, setInputValue] = useState('')
  // 验证码数组
  const codeArray = useMemo(() => {
    return new Array(length).fill('').map((item, index) => inputValue[index] || '')
  }, [inputValue, length])
  // 是否获取焦点，仅在 focus 时展示 Input 闪烁条
  const [isFocused, setFocus] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    // 仅支持数字，后续可拓展
    const tempValue = value.replace(/[^0-9]/g, '').slice(0, length)
    setInputValue(tempValue)
  }, [value, length])

  useEffect(() => {
    if (autoFocus) {
      inputRef.current?.focus()
      setFocus(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 输入框change
  const handleInputCodeChange = (e: any) => {
    if (loading || disabled) return
    const eValue = e.target.value
    const tempValue = eValue.replace(/[^0-9]/g, '').slice(0, length)

    setInputValue(tempValue)
    onChange?.(tempValue)
  }

  const handleCodeBoxClick = () => {
    inputRef.current?.focus()
    setFocus(true)
  }

  return (
    <div className={clsx('upay-captcha', `captcha-theme-${theme}`)}>
      {/* 展示部分 */}
      <Spin loading={loading}>
        <div className={clsx('code-box', { 'code-box-error': errorMsg, 'code-box-disabled': disabled })} onClick={handleCodeBoxClick}>
          {codeArray.map((item, index, array) => {
            const prevItemValue = index === 0 ? '-1' : array[index - 1] // 第一项视为前一项有值
            const isItemActive = isFocused && !!prevItemValue && (!item || index === length - 1) // Input 闪烁条展示在前一项有值切当前项为空的位置
            return (
              <div key={index} className={clsx('item-content', { 'item-content-active': isItemActive })}>
                {item}
                <div className={`item-content-input-cursor ${item ? 'cursor-hidden' : ''}`} />
              </div>
            )
          })}
        </div>
      </Spin>
      {errorMsg && <div className="captcha-error-tip">{errorMsg}</div>}

      {/* 输入部分 */}
      <div className="input-box-wrap">
        <input
          type="text"
          value={inputValue}
          onChange={handleInputCodeChange}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          pattern="[0-9]*"
          autoComplete="one-time-code"
          inputMode="numeric"
          maxLength={length}
          ref={inputRef}
        />
      </div>
    </div>
  )
}

export default Captcha
