import React, { CSSProperties, ForwardedRef, useEffect, useImperativeHandle, useState } from 'react'

import { Input, InputProps } from '@arco-design/web-react'
import { omit } from 'lodash'

import { PipoProvider } from '@/pipo/utils'

interface IProps {
  style?: CSSProperties
  value?: string
  defaultValue?: string
  handleRef?: any
  type?: 'number' | 'text'
  onChange?: (value: string, e: React.ChangeEvent | null) => void
  onClear?: () => void
  formatValue?: (value: string) => string
  formatCursor?: (oldVal: string, newVal: string, cursorPosition: number) => number
  validInput?: RegExp
  maxLength?: number
}

const CustomInput = (props: IProps & InputProps, ref: ForwardedRef<any>) => {
  const { style, value, defaultValue, handleRef, type, onChange, onClear, formatValue, formatCursor, validInput } = props
  const { isFlip } = PipoProvider.useContext()
  const [innerValue, setInnerValue] = useState(value || defaultValue || '')
  const [cursorPosition, setCursorPosition] = useState<number>(0)

  useEffect(() => {
    if (innerValue && ref && 'current' in ref && ref.current) {
      // The ref of arcodesign's input component is special, we should use "ref.current.dom" to access input dom
      ref.current.dom?.setSelectionRange?.(cursorPosition, cursorPosition)
    }
  }, [innerValue])

  useImperativeHandle(handleRef, () => ({
    clear: () => {
      handleClear()
    },
    setInnerValue: (v: string) => {
      setInnerValue(v)
    },
  }))

  const handleChange = (value: string, e: React.ChangeEvent<HTMLInputElement>) => {
    if (ref && 'current' in ref && ref.current) {
      setCursorPosition(ref.current.dom?.selectionStart)
    }
    if (value) {
      // 纯数字
      if (props.type === 'number') {
        if (!/^[0-9\s]+$/.test(value)) {
          return
        }
        // 空格处理，卡号输入框每四位会插入空格
        if (value.includes(' ')) {
          value = value?.replace(/\s/g, '')
        }
        if (props.max && Number(value) > props.max) {
          return
        }
      }
      if (validInput) {
        if (!validInput.test(value)) {
          return
        }
      }
      if (props.maxLength && value.length > props.maxLength) {
        value = value.slice(0, props.maxLength)
      }
      if (formatValue) {
        if (formatCursor) {
          setCursorPosition((prev) => formatCursor(innerValue.toString(), value, prev))
        }
        value = formatValue(value)
      }
    }
    setInnerValue(value)
    onChange && onChange(value, e)
  }

  const handleClear = () => {
    setInnerValue('')
    onChange && onChange('', null)
    onClear && onClear()
  }

  return (
    <Input
      ref={ref}
      spellCheck="false"
      className="upay-form-custom-input oo-block"
      style={{
        direction: isFlip ? 'rtl' : 'ltr',
        ...style,
      }}
      {...omit(props, [
        'type',
        'hasError',
        'errorMessage',
        'countryCode',
        'countryOrRegion',
        'phoneNumberStyle',
        'cardBrands',
        'currentCardBrand',
        'formatValue',
        'formatCursor',
        'handleRef',
      ])}
      value={innerValue}
      onChange={handleChange}
    />
  )
}

export default React.forwardRef(CustomInput)
