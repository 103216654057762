import React, { useEffect, useMemo } from 'react'

import { ConfigProvider, Message } from '@arco-design/web-react'

import { ModeTypeEnum } from '@/typings'
import { getLang } from '@/utils/getLang'
import { initRtl, isRtl } from '@/utils/rtl'

import './style/index.less'

interface IProps {
  mode: ModeTypeEnum
  children: React.ReactNode
}
const CoreLayout: React.FC<IProps> = (props) => {
  const { mode, children } = props
  const lang = useMemo(() => getLang(), [])

  useEffect(() => {
    initRtl(lang)

    Message.config({ duration: 5000 }) // 默认错误提示展示5秒钟
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ConfigProvider rtl={isRtl(lang)}>
      <div className="upay-root notranslate">
        <div className={`upay-layout ${mode === ModeTypeEnum.HostedPage ? 'upay-mode-hosted' : ''}`}>{children}</div>
      </div>
    </ConfigProvider>
  )
}

export default CoreLayout
