import { useRef, useState } from 'react'

import { useRetry } from '@/hooks'
import starling from '@/utils/starling'

/** 支付方式变更时【第二步，账户变更】，后付费绑卡成功后提交账户变更，查询变更结果10次，无明确结果则提示用户等待 */

const QUERY_ACCOUNT_RESULT_COUNT = 10

interface IProps {
  onPollingEnd: () => void
  showCountDown: boolean
}
export const useQueryAccountResult = (props: IProps) => {
  const { onPollingEnd, showCountDown } = props
  const [loadingRemainTime, setLoadingRemainTime] = useState<number>(QUERY_ACCOUNT_RESULT_COUNT)
  const loadingRemainTimeRef = useRef<number>(QUERY_ACCOUNT_RESULT_COUNT)
  const [startQueryAccountResult, stopQueryAccountResult] = useRetry(QUERY_ACCOUNT_RESULT_COUNT, 1000, false)

  // 倒计时10s，如果期间结果返回了，会清除掉该倒计时，否则可能是失败了，会提示用户稍后再次查看
  const queryAccountResult = () => {
    return new Promise((resolve) => {
      loadingRemainTimeRef.current -= 1
      setLoadingRemainTime((pre) => pre - 1)
      resolve(loadingRemainTimeRef.current)
    })
  }

  // 倒计时结束，还未返回响应时执行回调
  const handleQueryAccountResult = () => {
    if (loadingRemainTimeRef.current < 1) {
      stopQueryAccountResult()

      onPollingEnd()
    }
  }

  const renderLoadingText = () => {
    if (showCountDown) {
      return starling('funds.refund.comm.change_returning_results', {
        number: loadingRemainTime,
      })
    } else {
      return starling('funds.refund.comm.not_return_request_result')
    }
  }

  const startQuery = () => {
    startQueryAccountResult(queryAccountResult, handleQueryAccountResult)
  }

  return { run: startQuery, stop: stopQueryAccountResult, loadingText: renderLoadingText() }
}
