/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState } from 'react'

import { Select } from '@arco-design/web-react'
import { IconDown } from '@arco-design/web-react/icon'

import starling from '@/utils/starling'

import { ItemBlock } from '../index'

interface IProps {
  show?: boolean
  title?: string
  value?: string
  onChange: (e: string) => void
}

const ProxySelectChildren = (props: IProps) => {
  const { value, onChange } = props
  const [innerValue, setInnerValue] = useState(value)

  // hard-coded option list
  const optionList = [
    {
      title: starling('funds.refund.comm.system_ic_personal'),
      value: 'individual',
    },
    {
      title: starling('funds.refund.comm.system_ic_company'),
      value: 'company',
    },
  ]

  const handleChange = (e: string) => {
    setInnerValue(e)
    onChange(e)
  }

  return (
    <Select size="large" showSearch value={innerValue} onChange={(e: string) => handleChange(e)} arrowIcon={<IconDown />}>
      {optionList.map((option) => (
        <Select.Option key={option.value} value={option.value}>
          <span>{option.title}</span>
        </Select.Option>
      ))}
    </Select>
  )
}

export const ProxySelect = React.forwardRef((props: IProps, ref: any) => {
  const { show, title = '' } = props
  return show ? (
    <ItemBlock style="half" title={title}>
      {ProxySelectChildren(props)}
    </ItemBlock>
  ) : (
    <></>
  )
})
