import { useContext, useState } from 'react'

import { StatusActionTypeEnum } from '@upay/utils/es/types'

import { PUBSUB_TYPES } from '@/constant'
import { CardTransferStatusEnum, ICardTransferRes, ITransferableCardItem } from '@/services/types'
import { ParamsContext } from '@/stores'
import { ShowTypeEnum } from '@/typings'

import { ITransferStatusProps } from './components/TransferStatus'

interface IProps {
  reopen: () => void
  cardList?: ITransferableCardItem[]
}
export const useTransferStatus = (props: IProps) => {
  const { reopen, cardList = [] } = props
  const { urlQuery, uPubSub } = useContext(ParamsContext)
  const [statusProps, setStatusProps] = useState<Omit<ITransferStatusProps, 'onOk' | 'onCancel'>>({ visible: false })

  const isDropIn = urlQuery?.showType === ShowTypeEnum.DropIn

  const openStatus = (data: ICardTransferRes) => {
    const errorMsgCount: { [key: string]: { msg: string; count: number } } = {}
    const newDetails = data.details.map((item) => {
      const cardDetail = cardList.filter((card) => card.id === item.cardId)[0]
      if (cardDetail) {
        item = { ...item, identityType: cardDetail.identityType, identityNo: cardDetail.identityNo, expire_time: cardDetail.expire_time }
      }
      if (item.status === CardTransferStatusEnum.Error) {
        errorMsgCount[item.errorCode] ? errorMsgCount[item.errorCode].count++ : (errorMsgCount[item.errorCode] = { msg: item.errorMsg, count: 1 })
      }
      return item
    })

    // 全部失败：查找出现次数最多的errorMsg，在结果页展示
    let maxCountMsgObj = { count: 0, errorMsg: '', errorCode: '' }
    for (const key in errorMsgCount) {
      if (Object.prototype.hasOwnProperty.call(errorMsgCount, key)) {
        if (errorMsgCount[key].count > maxCountMsgObj.count) {
          maxCountMsgObj = { count: errorMsgCount[key].count, errorCode: key, errorMsg: errorMsgCount[key].msg }
        }
      }
    }

    data = { ...data, ...(maxCountMsgObj.count > 0 ? maxCountMsgObj : {}), details: newDetails }

    // dropIn 模式不展示状态
    if (!isDropIn) {
      setStatusProps({
        data,
        visible: true,
      })
    }

    // dropIn 模式不展示状态直接通知结果
    sendEvent(data, {
      clickBtn: false,
    })
  }

  const handleOk = (data: ICardTransferRes | undefined) => {
    setStatusProps({
      visible: false,
    })

    // 报错场景重新打开
    if (data?.status === CardTransferStatusEnum.Error) {
      reopen()
    } else {
      sendEvent(data, {
        clickBtn: true,
      })
    }
  }

  const handleCancel = (data: ICardTransferRes | undefined) => {
    setStatusProps({
      visible: false,
    })

    sendEvent(data, {
      clickBtn: true,
    })
  }

  /** 统一发送事件告知业务方 */
  function sendEvent(data: ICardTransferRes | undefined, params: any) {
    uPubSub.publish(PUBSUB_TYPES.STATUS_RESULT, {
      status: data?.status === CardTransferStatusEnum.Success ? 1 : 0, // 只需要告知成功或者失败，部分成功算成功
      clickBtn: true,
      actionType: StatusActionTypeEnum.TRANSFER,
      msg: data?.errorMsg,
      ...params,
    })
  }

  return {
    statusProps: {
      ...statusProps,
      onOk: handleOk,
      onCancel: handleCancel,
    },
    openStatus,
  }
}
