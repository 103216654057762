import utils from '@/utils'

export const getItemKey = (item: any) => {
  if (!item) return ''

  return item?.cardId || `paw_way_${item.payWay}`
}

export const encryptFormData = (formData: Record<string, any>, publicKey: string): Record<string, string> => {
  if (typeof formData !== 'object') return {}

  const out: Record<string, any> = {}
  for (const field in formData) {
    out[field] = utils.encryptString(formData[field], publicKey)
  }
  return out
}
