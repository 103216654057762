"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
_Object$defineProperty(exports, "formatSubmitFormDataForBindProcess", {
  enumerable: true,
  get: function get() {
    return _formatSubmitData.formatSubmitFormDataForBindProcess;
  }
});
_Object$defineProperty(exports, "formatUpdateInfoList", {
  enumerable: true,
  get: function get() {
    return _formatUpdateInfoList.formatUpdateInfoList;
  }
});
_Object$defineProperty(exports, "splitHolderName", {
  enumerable: true,
  get: function get() {
    return _splitHolderName.splitHolderName;
  }
});
var _formatSubmitData = require("./format-submit-data");
var _formatUpdateInfoList = require("./format-update-info-list");
var _splitHolderName = require("./split-holder-name");