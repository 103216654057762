import React, { ReactElement, forwardRef, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { usePipoRouter, useTouchedErrors } from '@/pipo/hooks'
import {
  PaymentParamsItem,
  PipoComponentRef,
  PipoProvider,
  PipoSubmitParams,
  USBankTransfer_PAYMENT_METHOD,
  USBankTransfer_PAYMENT_PARAMS_MAP,
  USBanktransTransferFormData,
  USBanktransferFormProps,
  generateCommonPaymentParams,
  validator,
} from '@/pipo/utils'
import starling from '@/utils/starling'

import { Email, HolderName, SaveBox } from '../../inner-components'

export const USBanktransferForm = forwardRef((props: USBanktransferFormProps, ref: React.ForwardedRef<PipoComponentRef>): ReactElement => {
  const { onValidate = () => undefined } = props
  const {
    getPublicKeyAsync,
    getErrorText,
    state: { config = {} },
  } = PipoProvider.useContext()
  const { selectedSaveBox = false } = config
  const [storeCard, setStoreCard] = useState<boolean>(selectedSaveBox)
  const {
    control,
    getValues,
    formState: { errors: formErrors, isValid, touchedFields },
  } = useForm<USBanktransTransferFormData>({
    mode: 'all',
  })

  const errors = useTouchedErrors<USBanktransTransferFormData>({
    touchedFields,
    errors: formErrors,
  })

  useEffect(() => {
    isValid && onValidate(true)
  }, [isValid, onValidate])

  /**
   * 处理PIPO提交所需数据
   * @returns PipoSubmitParams
   */
  const processSubmitData = async (formData: USBanktransTransferFormData): Promise<PipoSubmitParams> => {
    const publicKey = await getPublicKeyAsync()
    const paymentParams: PaymentParamsItem[] = generateCommonPaymentParams(formData, USBankTransfer_PAYMENT_PARAMS_MAP, publicKey)

    const { paymentMethodType, paymentMethod } = USBankTransfer_PAYMENT_METHOD
    return {
      formData,
      paymentMethod: {
        paymentMethod: paymentMethod.paymentMethod,
        paymentMethodId: paymentMethod.paymentMethodId,
      },
      paymentMethodType: paymentMethodType.paymentMethodType,
      paymentParams,
      storeCard: storeCard,
    }
  }

  /**
   * 校验后获取PIPO提交所需数据
   * @returns PipoSubmitParams
   */
  const validateSubmitData = () => {
    return new Promise(async (resolve: (value: PipoSubmitParams) => void, reject: (reason: typeof errors) => void) => {
      if (!isValid) {
        reject(errors)
      } else {
        const formData = getValues()
        resolve(await processSubmitData(formData))
      }
    })
  }

  usePipoRouter(ref, {
    initParams: {
      onValidate,
      isValid,
    },
    instanceMethods: {
      validateSubmitData,
    },
  })

  return (
    <div id="upay-form-banktransfer-us">
      <div className="payment-banktransfer-payeasy">
        <Controller
          rules={{ required: true, pattern: validator.nameRegex }}
          name="holder_name"
          control={control}
          render={({ field }) => (
            <HolderName
              {...field}
              error={errors.holder_name}
              errorMessage={getErrorText(errors.holder_name?.type)}
              title={starling('funcg.upay.exception.channel_BankTransfer_US_instruction_remitter_name')}
              placeholder={starling('funds.refund.comm.system_name_description')}
            />
          )}
        />
        <Controller
          rules={{ required: true, pattern: validator.emailRegex }}
          name="email"
          control={control}
          render={({ field }) => (
            <Email
              {...field}
              title={starling('funds.refund.comm.system_email')}
              placeholder={starling('funds.refund.comm.system_email_placeholder')}
              error={errors.email}
              errorMessage={getErrorText(errors.email?.type)}
            />
          )}
        />
        <SaveBox title={starling('funds.refund.comm.system_save_card_information')} storeCard={storeCard} setStoreCard={setStoreCard} />
      </div>
    </div>
  )
})
