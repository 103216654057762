import { PAYOUT_WAY_TYPE } from '@/constant/payout'
import BankAccount from '@/pages/Payout/payout-widgets/BankAccount'
import LocalBankTransfer from '@/pages/Payout/payout-widgets/LocalBankTransfer'
import { withController } from '@/pages/Payout/payout-widgets/withController'
import { OVODirectForm, QIWIForm, TrueMoneyForm } from '@/pipo/pc-payout'

// 已绑卡暂无
const PAY_WAY_EXTRA_CARD_MAP: Record<number, React.FC<any>> = {}

const PAY_WAY_EXTRA_CHANNEL_MAP: Record<number, React.FC<any>> = {
  [PAYOUT_WAY_TYPE.LOCAL_BANK_TRANSFER]: withController(LocalBankTransfer),
  [PAYOUT_WAY_TYPE.BANK_ACCOUNT]: withController(BankAccount),
  [PAYOUT_WAY_TYPE.TRUE_MONEY]: withController(TrueMoneyForm),
  [PAYOUT_WAY_TYPE.OVO]: withController(OVODirectForm),
  [PAYOUT_WAY_TYPE.QIWI]: withController(QIWIForm),
}

export const hasExtraContent = (payWay: number, isCard = false): boolean => {
  return isCard ? payWay in PAY_WAY_EXTRA_CARD_MAP : payWay in PAY_WAY_EXTRA_CHANNEL_MAP
}

export const getExtraComponent = (payWay: number, isCard = false) => {
  return isCard ? PAY_WAY_EXTRA_CARD_MAP[payWay] : PAY_WAY_EXTRA_CHANNEL_MAP[payWay]
}
