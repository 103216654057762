import { useEffect } from 'react'
import { RouterPath } from '@/pipo/utils'

export function useValidateCallback(props: { ref?: any; activePath?: RouterPath; isValid?: boolean; onValidate?: (isValid: boolean) => void }) {
  const { ref, activePath, isValid = false, onValidate } = props

  useEffect(() => {
    const triggerComponent = ref?.current?.initPath
    triggerComponent === activePath && onValidate?.(isValid)
  }, [ref, activePath, isValid, onValidate])
}
