import { BillingAddress, CountryCode, Email, ItemInputNumber, PhoneNumber } from '@/pipo/pc/inner-components'
import { validator } from '@/pipo/utils'
import starling from '@/utils/starling'

import { ICCDCConfigItem, IGetCCDCFormConfigProps } from '.'

export const getPEFormConfig = ({ postalCodeProps, billingAddressProps }: IGetCCDCFormConfigProps): ICCDCConfigItem => {
  return {
    needInfo: ['contract_address_list'],
    formSchema: [
      {
        field: 'email',
        widget: Email,
        widgetProps: {
          title: starling('funds.refund.comm.system_email'),
          placeholder: starling('funds.refund.comm.system_email_placeholder'),
        },
        rules: { required: true, pattern: validator.emailRegex },
      },
      {
        field: 'identity',
        widget: ItemInputNumber,
        widgetProps: {
          title: 'DNI',
          placeholder: starling('cg.upay.exception.channel_id_num_hint'),
          maxLength: 9,
        },
        rules: { required: true, pattern: /^\d{8,9}$/ },
      },
      {
        field: 'phone_country_code',
        widget: CountryCode,
        widgetProps: {
          countryCode: '51',
        },
      },
      {
        field: 'phone',
        widget: PhoneNumber,
        widgetProps: {
          phoneNumberStyle: 'half',
          addBefore: 'PE +51',
          title: starling('funds.refund.comm.system_phonenumber'),
          placeholder: starling('funds.refund.comm.system_phonenumber_placeholder'),
        },
        rules: { required: true, pattern: /^\d+$/ },
      },
      {
        field: 'billing_address',
        widget: BillingAddress,
        noController: true,
        widgetProps: {
          title: starling('funds.refund.comm.system_Billing_address'),
          postalCodeProps,
          ...billingAddressProps,
          onlyCurrentCountry: true,
          streetProps: { maxLength: 100 },
        },
      },
    ],
  }
}
