import React, { useState } from 'react'
import { FieldError } from 'react-hook-form'

import { Input } from '@arco-design/web-react'

import { cnpj, pickNumeric } from '@/pipo/utils'

import { ItemBlock } from '../index'

interface IProps {
  error?: FieldError
  errorMessage: string
  value?: string
  title: string
  needPickNumber?: boolean
  onChange: (val: string) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void
}

export const CNPJ = React.forwardRef((props: IProps, ref: any) => {
  const { error, errorMessage, value, title, needPickNumber = false, onChange, onBlur } = props
  const [innerValue, setInnerValue] = useState(value)

  const handleChange = (val: string) => {
    const formatValue = cnpj.formatter(val)
    setInnerValue(formatValue)
    onChange(needPickNumber ? pickNumeric(formatValue) : formatValue)
  }

  return (
    <ItemBlock style="half" title={title} errorState={Boolean(error)} errorMessage={errorMessage}>
      <Input
        {...props}
        ref={ref}
        size="large"
        allowClear
        value={innerValue}
        error={Boolean(error)}
        placeholder={cnpj.placeholder}
        onChange={(e) => handleChange(e)}
        onBlur={onBlur}
      />
    </ItemBlock>
  )
})
