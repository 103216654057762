"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault")["default"];
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.splitHolderName = void 0;
var _includes = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/includes"));
var _replaceAll = _interopRequireDefault(require("@babel/runtime-corejs3/core-js/instance/replace-all"));
var _dropRight2 = _interopRequireDefault(require("lodash/dropRight"));
var NO_SPACE_IN_NAME_COUNTRY = ['KR'];
/** 拆分name为first/last */
var splitHolderName = function splitHolderName(holderName, countryCode) {
  var _holderName;
  if (!holderName) holderName = '';
  var holderNameArr = ((_holderName = holderName) === null || _holderName === void 0 ? void 0 : _holderName.split(' ')) || [];
  if (holderNameArr.length <= 1) {
    return [holderNameArr[0] || '', ''];
  }
  var firstName = (0, _dropRight2["default"])(holderNameArr, 1).join(' ') || ''; // 取最后一个空格前的字符
  if (countryCode && (0, _includes["default"])(NO_SPACE_IN_NAME_COUNTRY).call(NO_SPACE_IN_NAME_COUNTRY, countryCode)) {
    firstName = (0, _replaceAll["default"])(firstName).call(firstName, ' ', '');
  }
  var lastName = holderNameArr[holderNameArr.length - 1] || ''; // 取最后一个空格后的字符
  return [firstName, lastName];
};
exports.splitHolderName = splitHolderName;