import React from 'react'

import { Collapse as ArcoCollapse } from '@arco-design/web-react'

import { getLang } from '@/utils/getLang'
import { isRtl } from '@/utils/rtl'

import './index.less'

export type CollapseItem = {
  title: string
  key?: number | string
  content?: JSX.Element
}

export interface ICollapseProps {
  config?: CollapseItem[]
  active?: string[] | string
  onChange?: (k: string[] | string) => void
  className?: string
}

const Collapse = (props: ICollapseProps): JSX.Element => {
  const { config = [], active = [], onChange, className } = props

  return (
    <div className="upay-collapse">
      <ArcoCollapse
        className={className}
        activeKey={Array.isArray(active) ? active.map(String) : String(active)}
        expandIconPosition={isRtl(getLang()) ? 'right' : 'left'}
        onChange={(key, keys) => onChange?.(keys)}
        bordered={false}
        lazyload={false}
      >
        {config.map((i: CollapseItem) => (
          <ArcoCollapse.Item key={i.key || i.title} name={String(i.key || i.title)} header={i.title}>
            {i.content}
          </ArcoCollapse.Item>
        ))}
      </ArcoCollapse>
    </div>
  )
}

export default Collapse
