import React from 'react'

import { IPaymentMethodsPropsBasic } from '@/payments/interface'

import { IPayinMethodItem } from '../../interface'

interface IPayinMethodItemProps {
  methodItem: IPayinMethodItem
  paymentsMethodPropsBasic: IPaymentMethodsPropsBasic
  renderPayinMethodExtra: (method: IPayinMethodItem, paymentPropsBasic: IPaymentMethodsPropsBasic) => React.ReactNode
}

// 性能优化，减少支付方式不必要的重复渲染
const PayinMethodItem = ({ methodItem, paymentsMethodPropsBasic, renderPayinMethodExtra }: IPayinMethodItemProps) => {
  return <>{renderPayinMethodExtra(methodItem, paymentsMethodPropsBasic)}</>
}

export default React.memo(PayinMethodItem)
