import React, { useContext, useState } from 'react'

import { useRequest, useTimer } from '@byted/hooks'
import { StatusActionTypeEnum, StatusTypeEnum } from '@upay/utils/es/types'

import { Status } from '@/components'
import { COMMON_PROMPT, PUBSUB_TYPES, RESPONSE_CODE } from '@/constant'
import api from '@/services'
import { PayoutResultStatusEnum } from '@/services/payout-types'
import { ParamsContext } from '@/stores'
import { oneTimeHoc } from '@/utils/oneTimeHoc'
import starling from '@/utils/starling'

const TIMER_COUNT = 10 * 1000

interface IProps {
  initStatus?: StatusTypeEnum
  initErrorMsg?: string
  onOk?: () => void
}
const PayoutStatus: React.FC<IProps> = (props) => {
  const { initStatus = StatusTypeEnum.PROCESSING, initErrorMsg = '', onOk } = props
  const [currentStatus, setCurrentStatus] = useState<StatusTypeEnum>(initStatus)
  const [errorMsg, setErrorMsg] = useState(initErrorMsg)

  const {
    urlQuery: { token },
    uPubSub,
  } = useContext(ParamsContext)
  const {
    reset: resetTimer,
    pause: pauseTimer,
    remainingTime,
    isCounting,
  } = useTimer<number>(TIMER_COUNT, {
    auto: true,
    onComplete: () => {
      setCurrentStatus(StatusTypeEnum.TIMEOUT)
      handleTaskFinish()
    },
  })

  const { run: startPolling, cancel: cancelPolling } = useRequest(api.getPayoutStatus, {
    auto: true,
    pollingInterval: 1000 * 1,
    defaultParams: [{ token }],
    onSuccess: (res) => {
      const { code, data, msg } = res || {}
      const { errorMsg = COMMON_PROMPT().SYS_ERR, status } = data || {}

      if (code !== RESPONSE_CODE.SUCCESS) throw new Error(msg)

      if (status === PayoutResultStatusEnum.Success) {
        setCurrentStatus(StatusTypeEnum.SUCCESS)
        handleTaskFinish()
      } else if (status === PayoutResultStatusEnum.Failed) {
        setErrorMsg(errorMsg)
        setCurrentStatus(StatusTypeEnum.FAILED)
        handleTaskFinish()
      } else if (!isCounting) {
        setCurrentStatus(StatusTypeEnum.TIMEOUT)
        handleTaskFinish()
      }
    },
    onError: (e: any) => {
      setErrorMsg(e?.msg || e?.message || COMMON_PROMPT().SYS_ERR)
      setCurrentStatus(StatusTypeEnum.FAILED)
      handleTaskFinish()
    },
  })

  const handleClickRefresh = () => {
    setCurrentStatus(StatusTypeEnum.PROCESSING)
    resetTimer(true, TIMER_COUNT)
    startPolling({ token })
  }

  const handleTaskFinish = () => {
    pauseTimer()
    cancelPolling()
  }

  const handleClickOk = () => {
    uPubSub.publish(PUBSUB_TYPES.STATUS_RESULT, {
      status: currentStatus === StatusTypeEnum.SUCCESS ? 1 : 0,
      clickBtn: true,
      actionType: StatusActionTypeEnum.PAYOUT,
      msg: currentStatus === StatusTypeEnum.FAILED ? errorMsg : '',
    })
    onOk?.()
  }

  const primaryButtonMap: Record<any, any> = {
    [StatusTypeEnum.PROCESSING]: {
      buttonText: `${starling('funds.check_out.comm.Refresh')} ${Math.ceil(remainingTime / 1000)} s`,
      handleClick: () => undefined,
      disabled: true,
    },
    [StatusTypeEnum.TIMEOUT]: {
      buttonText: starling('funds.check_out.comm.Refresh'),
      handleClick: handleClickRefresh,
      disabled: false,
    },
    [StatusTypeEnum.SUCCESS]: {
      buttonText: starling('funds.refund.comm.back'),
      handleClick: handleClickOk,
      disabled: false,
    },
    [StatusTypeEnum.FAILED]: {
      buttonText: starling('funds.refund.comm.back'),
      handleClick: handleClickOk,
      disabled: false,
    },
  }

  return (
    <Status
      visible
      actionType={StatusActionTypeEnum.PAYOUT}
      status={currentStatus}
      showNeedHelp={false}
      errMsg={currentStatus === StatusTypeEnum.FAILED ? errorMsg : ''}
      primaryButtonInfo={primaryButtonMap[currentStatus]}
      cancelButtonInfo={currentStatus === StatusTypeEnum.TIMEOUT ? primaryButtonMap[StatusTypeEnum.SUCCESS] : undefined}
    />
  )
}

export default oneTimeHoc(PayoutStatus)
