import React, { forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'

import { PAYMENT, PAY_WAY_TYPE } from '@/constant'
import { IPayinMethodItem, PaymentSubmitMethodEnum } from '@/pages/PayNext/interface'
import { OXXOForm } from '@/pipo/pc'
import { PipoComponentRef } from '@/pipo/utils/types'
import { ParamsContext } from '@/stores'
import SafeJSON from '@/utils/SafeJSON'

import { IPaymentMethodsProps, IPaymentsMethodRef } from '../../interface'

const OXXO = forwardRef<IPaymentsMethodRef, IPaymentMethodsProps>((props, ref): React.ReactElement => {
  const { changeNewPaymentStatus, paymentSubmitMethod, channel } = props

  const {
    urlQuery: { type },
  } = useContext(ParamsContext)

  const formRef = useRef<PipoComponentRef>(null)
  const [isValid, setIsValid] = useState(false)

  // TODO: 暂时兼容两种逻辑，后续新UI全量后可梳理移除，由后端维护是否显示
  const showSavingBox = useMemo(() => {
    // 仅支付场景展示SaveBox
    if (paymentSubmitMethod !== PaymentSubmitMethodEnum.PAYMENT) return false

    // 若后端返回 以后端为准 老逻辑未返回排除后付费
    const supportFromBE = (channel as IPayinMethodItem).supportPaymentAndBind
    return typeof supportFromBE === 'boolean' ? supportFromBE : type !== PAYMENT.POST_PAY
  }, [paymentSubmitMethod, channel, type])

  // 同步验证状态
  useEffect(() => {
    changeNewPaymentStatus(PAY_WAY_TYPE.OXXO, { valid: isValid })
  }, [isValid, changeNewPaymentStatus])

  useImperativeHandle(ref, () => ({
    processSubmitData: async (submitParams) => {
      if (formRef.current?.validateSubmitData) {
        const { paymentMethod, paymentParams, storeCard } = await formRef.current?.validateSubmitData()
        const paymentMethodObj = {
          method_id: paymentMethod.paymentMethodId,
          payment_elements: paymentParams,
        }
        submitParams.channelParameter = SafeJSON.stringify(paymentMethodObj)
        submitParams.bindAndPay = storeCard
      }
      return submitParams
    },
  }))

  return <OXXOForm ref={formRef} onValidate={setIsValid} showSavingBox={showSavingBox} />
})

export default OXXO
