import React from 'react'

// copy for https://github.com/jamiebuilds/unstated-next/blob/master/src/unstated-next.tsx

// eslint-disable-next-line symbol-description
const EMPTY: unique symbol = Symbol()

export interface ModelProviderProps<State = void> {
  options?: State
  children: React.ReactNode
}

export interface Model<Value, State = void> {
  Provider: React.ComponentType<ModelProviderProps<State>>
  useContext: () => Value
}

export default function createModel<Value, State = void>(useHook: (options?: State) => Value): Model<Value, State> {
  const HooksContext = React.createContext<Value | typeof EMPTY>(EMPTY)

  function Provider(props: ModelProviderProps<State>) {
    const value = useHook(props.options)
    return (
      // @ts-ignore
      <HooksContext.Provider value={value}>{props.children}</HooksContext.Provider>
    )
  }

  function useContext(): Value {
    const value = React.useContext(HooksContext)
    if (value === EMPTY) {
      throw new Error('Component must be wrapped with <Model.Provider>')
    }
    return value
  }

  return { Provider, useContext }
}
