import { PaymentMethodItem } from '../../types'
/**
 * OVO 需要填写的表单的位数
 */
export type OVODirectFormData = {
  wallet_id?: string
}
export type OVORedirectFormData = {
  phone?: string
  bind_required?: string
  phone_country_code?: string
}

// TODO: Change
export const OVO_DIRECT_PAYMENT_PARAMS_MAP: Record<string, string> = {
  wallet_id: 'eg_pi_ew_ovo_c_d_local_id_wallet_id',
}

export const OVO_DIRECT_PAYMENT_METHOD: PaymentMethodItem = {
  paymentMethodType: {
    configuration: {},
    paymentMethodType: 'E_WALLET',
    starling_key: 'pipo_common_ewallet',
    icon: {
      iconUrl: '',
    },
    title: {
      payment: 'pipo_common_ewallet',
    },
  },
  paymentMethod: {
    configuration: {
      package_name: 'ovo.id',
      schema: 'ovo://',
      png_icon_url: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/ovo_light_3_98f223.png',
      dark_mode_png_icon_url: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/ovo_dark_3_1b2f8c.png',
    },
    paymentMethod: 'OVO',
    paymentMethodId: 'pm_pi_ew_ovo_c_d',
    displayName: 'OVO',
    starling_key: 'pipo_payin_payment_main_ovo',
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/ewallet_ovo_0467f8.png',
      pngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/ovo_light_3_98f223.png',
      darkModePngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/ovo_dark_3_1b2f8c.png',
      detailIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/ovo-detail_492a1b.svg',
    },
  },
}

export const OVO_REDIRECT_PAYMENT_PARAMS_MAP: Record<string, string> = {
  phone: 'eg_pi_ew_ovo_c_r_local_id_phone',
  phone_country_code: 'eg_pi_ew_ovo_c_r_local_id_phone_country_code',
}

export const OVO_REDIRECT_PAYMENT_METHOD: PaymentMethodItem = {
  ...OVO_DIRECT_PAYMENT_METHOD,
  paymentMethod: {
    ...OVO_DIRECT_PAYMENT_METHOD.paymentMethod,
    paymentMethodId: 'pm_pi_ew_ovo_c_r',
    title: {
      piListShort: 'pipo_payin_payment_main_ovo',
      piListLong: 'pipo_payin_payment_main_ovo',
    },
    piListForm: true,
    availableExpired: true,
  },
}
