"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault")["default"];
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.krBankAccountNo = exports.jpHolderName = exports.jpBankAccountNo = exports.jpAgencyCode = exports.itIban = exports.gbSortCode = exports.gbHolderName = exports.gbAccountNo = exports.frIban = exports.frBic = exports.emailRegex = exports.egIban = exports.egBic = exports.deIban = exports.czNameRegex = exports.caHolderName = exports.caBankAccountNo = exports.caAddress = exports.brHolderName = exports.auBsbCode = exports.auBankAccountNo = exports.agencyCodeRegex = exports.RomajiNameRegex = exports.QIWIphoneRegex = exports.OXXOphoneRegex = exports.OXXOidRegex = exports.OVOIdRegex = exports.LatinNameRegex = exports.KatakanaNameRegex = exports.JPphoneRegex = exports.IDphoneRegex = exports.IDNumberRegex = exports.AlphabetNumberSpace = void 0;
exports.luhn = luhn;
exports.vnPhoneNumberRegex = exports.vnHolderName = exports.usHolderName = exports.usBankAccountNo = exports.trIban = exports.thHolderName = exports.thBankAccountNo = exports.routingNo = exports.postalcodeRegex = exports.phoneRegex = exports.paycellPhoneRegex = exports.noWhitespaceRegex = exports.nationalIDRegex = exports.nameRegex = exports.myHolderName = exports.mxHolderName = exports.mxBankAccountNo = void 0;
var _reverse = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/reverse"));
var _parseInt2 = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/parse-int"));
/* eslint-disable no-useless-escape */
function luhn(num) {
  var _context;
  num = (0, _reverse["default"])(_context = String(num).replace(/\D+/g, '').split('')).call(_context);
  if (!num.length) {
    return false;
  }
  var total = 0;
  for (var i = 0; i < num.length; i++) {
    var temp = (0, _parseInt2["default"])(num[i], 10);
    total += i % 2 ? 2 * temp - (temp > 4 ? 9 : 0) : temp;
  }
  if (total === 0) {
    return false;
  }
  return total % 10 === 0;
}
var noWhitespaceRegex = /\s{1,}/;
exports.noWhitespaceRegex = noWhitespaceRegex;
var nameRegex = /^[^`~!@#$%^&*()_+=<>?:"{}|,/;[\]·~！@#¥%……&*（）——+={}|《》？：“”【】、；‘，。、]*$/;
exports.nameRegex = nameRegex;
var phoneRegex = /^\d{10,11}$/;
exports.phoneRegex = phoneRegex;
var emailRegex = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
exports.emailRegex = emailRegex;
var OVOIdRegex = /^\d{8,11}$/; // not ID phone
exports.OVOIdRegex = OVOIdRegex;
var IDphoneRegex = /^\d{8,11}$/;
exports.IDphoneRegex = IDphoneRegex;
var QIWIphoneRegex = /^\d{10}$/;
exports.QIWIphoneRegex = QIWIphoneRegex;
var OXXOphoneRegex = /^\d{10}$/;
exports.OXXOphoneRegex = OXXOphoneRegex;
var OXXOidRegex = /^([a-zA-Z0-9]{18}|[a-zA-Z0-9]{12,13})$/;
exports.OXXOidRegex = OXXOidRegex;
var JPphoneRegex = /^\d{10,11}$/;
exports.JPphoneRegex = JPphoneRegex;
var RomajiNameRegex = /^[\u3040-\u309F\u30A0-\u30FFA-Za-z\u4E00-\u9FFF\u3400-\u4DBF\uF900-\uFAFF]{0,255}$/;
exports.RomajiNameRegex = RomajiNameRegex;
var KatakanaNameRegex = /^[\u30A0-\u30FF]{0,255}$/;
exports.KatakanaNameRegex = KatakanaNameRegex;
var postalcodeRegex = /^[^\u4E00-\u9FFF\u3400-\u4DBF\uF900-\uFAFF]*$/;
exports.postalcodeRegex = postalcodeRegex;
var nationalIDRegex = /^\d{11}$/;
exports.nationalIDRegex = nationalIDRegex;
var IDNumberRegex = /^\d{11}$/;
exports.IDNumberRegex = IDNumberRegex;
var LatinNameRegex = /^[\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00FFA-Za-z\u0100-\u024F\u2C60-\u2C77\uA720-\uA721]{0,255}$/;
exports.LatinNameRegex = LatinNameRegex;
var paycellPhoneRegex = /^5\d{9}$/;
exports.paycellPhoneRegex = paycellPhoneRegex;
var vnPhoneNumberRegex = /^\d{10}$/;
exports.vnPhoneNumberRegex = vnPhoneNumberRegex;
var agencyCodeRegex = /^\d{3,5}$/;
exports.agencyCodeRegex = agencyCodeRegex;
var thHolderName = /^[a-zA-Z\u0E00-\u0E7F-.()-/*@#^\u0026|\u003c\u003e\\\\~ ]+$/;
exports.thHolderName = thHolderName;
var thBankAccountNo = /^[0-9]{4,35}$/;
exports.thBankAccountNo = thBankAccountNo;
var vnHolderName = /^[A-Za-z àáãạảăắằẳẵặâấầẩẫậèéẹẻẽêềếểễệđìíĩỉịòóõọỏôốồổỗộơớờởỡợùúũụủưứừửữựỳỵỷỹýÀÁÃẠẢĂẮẰẲẴẶÂẤẦẨẪẬÈÉẸẺẼÊỀẾỂỄỆĐÌÍĨỈỊÒÓÕỌỎÔỐỒỔỖỘƠỚỜỞỠỢÙÚŨỤỦƯỨỪỬỮỰỲỴỶỸÝ\.\(\)\-\/\*\@\#\&\\\|\<\>\~]+$/;
exports.vnHolderName = vnHolderName;
var czNameRegex = /^[^`~!@#$%^&*()_+=<>?:"{}|,/;[\]·~！@#¥%……&*（）——+={}|《》？：“”【】、；‘，。、]{3,100}$/;
exports.czNameRegex = czNameRegex;
var egBic = /^([A-Za-z0-9]{8}|[A-Za-z0-9]{11})$/;
exports.egBic = egBic;
var egIban = /^EG[a-zA-Z\d]{27}$/;
exports.egIban = egIban;
var mxBankAccountNo = /^[0-9]{18}$/;
exports.mxBankAccountNo = mxBankAccountNo;
var routingNo = /^[0-9]{9}$/;
exports.routingNo = routingNo;
var caHolderName = /^[A-Za-z \/\-\:\(\)\.\,\'\&]+$/;
exports.caHolderName = caHolderName;
var caBankAccountNo = /^[0-9]{5,12}$/;
exports.caBankAccountNo = caBankAccountNo;
var caAddress = /^[A-Za-z0-9 \/\-\:\(\)\.\,\'\&]+$/;
exports.caAddress = caAddress;
var usBankAccountNo = /^[0-9]{5,17}$/;
exports.usBankAccountNo = usBankAccountNo;
var jpAgencyCode = /^[0-9]{3}$/;
exports.jpAgencyCode = jpAgencyCode;
var jpBankAccountNo = /^[0-9]{7}$/;
exports.jpBankAccountNo = jpBankAccountNo;
var jpHolderName = /^[A-Za-z\u0800-\u4e00 ]+$/;
exports.jpHolderName = jpHolderName;
var brHolderName = /^[A-Za-zàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð\,\.\&\/\'\- ]+$/;
exports.brHolderName = brHolderName;
var gbHolderName = /^[A-Za-z\.\- ]+$/;
exports.gbHolderName = gbHolderName;
var gbSortCode = /^[0-9]{6}$/;
exports.gbSortCode = gbSortCode;
var gbAccountNo = /^[0-9]{8}$/;
exports.gbAccountNo = gbAccountNo;
var AlphabetNumberSpace = /^[A-Za-z0-9 ]+$/;
exports.AlphabetNumberSpace = AlphabetNumberSpace;
var mxHolderName = /^[A-Za-z0-9 \/\-\?\:\(\)\.\,\']+$/;
exports.mxHolderName = mxHolderName;
var myHolderName = /^[A-Za-z0-9 \.\(\)\-\/\*\@\#\&\\\|\<\>\~\?\:\,\'\'\+]+$/;
exports.myHolderName = myHolderName;
var usHolderName = /^[A-Za-z0-9 \/\-\?\:\(\)\.\,\'\+\"]+$/;
exports.usHolderName = usHolderName;
var frBic = /^[a-zA-Z]{6}[a-zA-Z\d]{0,5}$/;
exports.frBic = frBic;
var frIban = /^FR[a-zA-Z\d]{25}$/;
exports.frIban = frIban;
var trIban = /^TR[a-zA-Z\d]{24}$/;
exports.trIban = trIban;
var krBankAccountNo = /^[0-9]{1,15}$/;
exports.krBankAccountNo = krBankAccountNo;
var auBankAccountNo = /^[0-9]{9}$/;
exports.auBankAccountNo = auBankAccountNo;
var auBsbCode = /^[0-9]{6}$/;
exports.auBsbCode = auBsbCode;
var itIban = /^IT[a-zA-Z\d]{25}$/;
exports.itIban = itIban;
var deIban = /^DE[a-zA-Z\d]{20}$/;
exports.deIban = deIban;