import React from 'react'

import { Checkbox, Tooltip } from '@arco-design/web-react'

import starling from '@/utils/starling'

interface IProps {
  mandate: boolean
  setMandate: React.Dispatch<React.SetStateAction<boolean>>
  disabled?: boolean
  marginTopStyle?: string
}

export const MandateBox = (props: IProps) => {
  const { mandate, setMandate, disabled, marginTopStyle } = props

  return (
    <div
      style={{
        display: 'flex',
        marginRight: 24,
        marginTop: marginTopStyle || 24,
      }}
    >
      <Tooltip disabled={!disabled} content={starling('funds.checkout.comm.system_mandate_fillin')}>
        <Checkbox
          style={{ paddingLeft: 0 }}
          onChange={(value) => {
            setMandate(value)
          }}
          checked={mandate}
          disabled={disabled}
        >
          {starling('funds.checkout.comm.system_mandate_sign')}
        </Checkbox>
      </Tooltip>
    </div>
  )
}
