import { PaymentMethodItem } from '../../types'

export const FAWRY_PAYMENT_METHOD: PaymentMethodItem = {
  paymentMethodType: {
    configuration: {},
    icon: {
      iconUrl: '',
    },
    starling_key: 'CASH_PIN',
    paymentMethodType: 'CASH_PIN',
  },
  paymentMethod: {
    configuration: {},
    paymentMethod: 'FAWRY',
    paymentMethodId: 'pm_pi_cp_fawry_c_d',
    displayName: 'FAWRY',
    starling_key: 'pipo_payin_cashier_dropin_payment_Farwyname',
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/FAWRY_icon_7a7231.svg',
      voucherIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/Fawry_Voucher_b54f7e.png',
      pngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/FAWRY_a8bcf9.png',
      darkModePngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/FAWRY_a8bcf9.png',
    },
    title: {
      payment: 'pipo_payin_cashier_dropin_payment_Farwyname',
    },
  },
}
