import { PaymentMethodItem } from '../../types'

export const SEICOMART_PAYMENT_PARAMS_MAP: Record<string, string> = {
  email: 'eg_cvs_jp_email',
}

export const SEICOMART_PAYMENT_METHOD: PaymentMethodItem = {
  paymentMethodType: {
    configuration: {},
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/cvs-common_f99160.svg',
    },
    starling_key: '',
    paymentMethodType: 'CASH_PIN',
    title: {
      payment: 'pipo_common_payin_conveniencestore',
    },
  },
  paymentMethod: {
    configuration: {
      png_icon_url: '',
    },
    paymentMethod: 'SEICOMART',
    paymentMethodId: 'pm_pi_cp_seicomart_c_d',
    displayName: 'Seicomart',
    starling_key: 'pipo_payin_payment_main_seicomart',
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/seicomart-white_8de74b.svg',
      grayIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/seicomart-gray_0f7365.svg',
      voucherIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/seicomart-voucher_f7276d.svg',
    },
  },
}
