export const defaultLocale = {
  Pipo_payin_doku_message_paymentcode:
    'Your TikTok Shop No. Virtual Account is: {payment_code}. Please make your payment within {countdown_hhmm} before the code expires. To see detailed instructions, go to "Settings and privacy" > "Orders" in the TikTok app.',
  instrument_error_ui00032: 'Invalid card. Try another card or contact your card issuer for more information.',
  instrument_error_ui00033: 'Invalid card information. Make sure information is entered correctly and try again.',
  instrument_error_ui00034: 'Card expired. Try another card or contact your card issuer for more information.',
  instrument_error_ui00035: 'Invalid card. Try another card or contact your card issuer for more information.',
  instrument_error_ui00036: 'Invalid card number.  Make sure card number is entered correctly and try again.',
  instrument_error_ui00037: 'Invalid CVV/CVC. Make sure information is entered correctly and try again.',
  instrument_error_ui00038: 'Invalid ZIP/postal code. Make sure information is entered correctly and try again.',
  instrument_error_ui00039: 'Your card is restricted. Contact your card issuer for more information.',
  instrument_error_ui00040: 'Invalid PIN. Make sure PIN is entered correctly and try again.',
  instrument_error_ui0035: "The phone number doesn't match any account. Please check the number and try again.",
  payin_pay_with_PayPal: 'PayPal',
  payin_pay_with_PayPal_redirect: 'You will be redirected to PayPal to complete your payment securely',
  payin_pay_with_new_card: 'Pay with a new card',
  payin_paypal_description: '-',
  paypal_not_verify_popup_btn1: 'OK',
  pipo_EG_Fakka_policy: 'Please fully consume your existing Fakka balance before topping up with Vodafone’s 5 EGP product.',
  pipo_EG_fakka_title: 'Notice for Vodafone',
  pipo_aml_header_notice:
    'We collect information of the account holder for security reasons. The information will be kept confidential and not be shared for any other purposes.',
  pipo_common_404_error: 'Page Not Found',
  pipo_common_address: 'Address',
  pipo_common_billing_address: 'Billing address',
  pipo_common_billing_address_same_as: 'Billing address is same as {value}',
  pipo_common_bind_limit_exceed: 'Maximum binding times exceeded',
  pipo_common_btn_back_to: 'Back to {value}',
  pipo_common_cancel: 'Cancel',
  pipo_common_cards: 'Cards',
  pipo_common_citizenship_intro: 'Citizenship is the legal status of being a citizen of a particular country and region.',
  pipo_common_country: 'Country and Region',
  pipo_common_country_of_residence: 'Country and Region of Residence',
  pipo_common_date_of_birth: 'Date of Birth',
  pipo_common_default_select_country: 'Select country or region',
  pipo_common_delete: 'Delete',
  pipo_common_delete_card_confirmation: 'Are you sure you want to delete this card？',
  pipo_common_error_card_check: 'Card number must only contain numbers',
  pipo_common_error_card_default: 'Something went wrong with your  payment. Please try again later. {error_code}',
  pipo_common_error_card_expired: 'Card expired',
  pipo_common_error_card_not_supported: 'Invalid or unsupported form of card',
  pipo_common_error_card_number: 'Card number is invalid',
  pipo_common_error_card_number_length: 'Card number has invalid length',
  pipo_common_error_code: 'error code',
  pipo_common_error_contain_number: '{the_field} must contain numbers',
  pipo_common_error_is_invalid: '{the_field} is invalid',
  pipo_common_error_month_check: 'Month must be a number between 01 and 12',
  pipo_common_error_must_numbe: '{the_field} must be numbers',
  pipo_common_error_paste_beyond_length: 'Pasted data beyonds character limit. / Pasting beyonds character limit.',
  pipo_common_error_paste_data_invalid: 'Please enter a valid {value}',
  pipo_common_error_required_field: '{the_field} is required',
  pipo_common_error_year_too_far: 'Year is too far into the future',
  pipo_common_ewallet: 'E-Wallet',
  pipo_common_ewallet_enter_account: 'Enter account number',
  pipo_common_ewallet_id: 'E-Wallet ID',
  pipo_common_ewallet_id_number: 'E-Wallet ID number',
  pipo_common_got_it: 'Got it',
  pipo_common_id_card: 'ID card',
  pipo_common_id_card_number: 'ID card number',
  pipo_common_last_step: 'Last step',
  pipo_common_link_ewallet_confirm: 'Link now',
  pipo_common_link_ewallet_need_info: 'Enter your account number',
  pipo_common_link_ewallet_with_no_info: 'Link your account first',
  pipo_common_loading: 'Processing...',
  pipo_common_month: 'Month',
  pipo_common_nationality: 'Nationality',
  pipo_common_nationality_intro:
    'Nationality is the status of belonging to a particular nation/country and region, whether by birth or naturalization.',
  pipo_common_next: 'Next',
  pipo_common_optional: '(Optional)',
  pipo_common_order_id: 'Order ID',
  pipo_common_order_promotion: 'Discount',
  pipo_common_order_subtotal: 'Subtotal',
  pipo_common_order_tax: 'Tax',
  pipo_common_order_time: 'Order time',
  pipo_common_ovo_id_hint: 'OVO ID is the phone number linked to your OVO account',
  pipo_common_pagbank: 'Pagbank',
  pipo_common_payin_CNPJ: 'CNPJ',
  pipo_common_payin_CPF: 'CPF',
  pipo_common_payin_Email: 'Email',
  pipo_common_payin_PC_bind_loading: 'Processing...',
  pipo_common_payin_PC_bind_loading_update: 'Processing...',
  pipo_common_payin_PC_cell_nothingtoshow: 'N/A',
  pipo_common_payin_PC_cell_nothingtoshow_update: 'N/A',
  pipo_common_payin_PC_middlepage_ovo_token: 'Your token has been expired',
  pipo_common_payin_PC_middlepage_ovo_token_porcessing: "We are leading you to OVO's page \\nPlease wait...",
  pipo_common_payin_PC_middlepage_ovo_token_subtitle: 'You need to input your OVO security PIN twice to refresh it.',
  pipo_common_payin_account_name: 'Account Name',
  pipo_common_payin_account_number: 'Account Number',
  pipo_common_payin_account_type: 'Account Type',
  pipo_common_payin_add_cc_dc: 'Add debit or credit card',
  pipo_common_payin_add_payment_instrument: 'Add {payment_instrument}',
  pipo_common_payin_address: 'Street address',
  pipo_common_payin_address_line_1: 'Address Line 1',
  pipo_common_payin_address_line_2: 'Address Line 2',
  pipo_common_payin_address_update: 'Address',
  pipo_common_payin_authorization_content: 'Bank Routing Number: 021000021\\nAccount Number: 723872120\\nAccount Name: Pipo US Inc.',
  pipo_common_payin_authorization_title: 'Please whitelist PIPO to direct debit your bank account',
  pipo_common_payin_boleto_add: 'Add Boleto',
  pipo_common_payin_boleto_code: 'Payment code',
  pipo_common_payin_boleto_copy: 'Copy Boleto barcode',
  pipo_common_payin_boleto_link: 'Boleto link',
  pipo_common_payin_boleto_name: 'Name',
  pipo_common_payin_boleto_pay: 'Pay with a new Boleto',
  pipo_common_payin_boleto_payment_city: 'City',
  pipo_common_payin_boleto_payment_country: 'Country',
  pipo_common_payin_boleto_payment_method: 'Boleto',
  pipo_common_payin_boleto_print: 'Print Boleto',
  pipo_common_payin_boleto_save: 'Save this Boleto information for next time',
  pipo_common_payin_boleto_street: 'Street',
  pipo_common_payin_btn_add: 'Add',
  pipo_common_payin_btn_add_card: 'Add card',
  pipo_common_payin_btn_continue: 'Continue',
  pipo_common_payin_btn_save: 'Save',
  pipo_common_payin_btn_save_account: 'Save Account',
  pipo_common_payin_btn_save_card: 'Save Card',
  pipo_common_payin_business_account: 'Business Account',
  pipo_common_payin_card_number: 'Card number',
  pipo_common_payin_card_number_1: 'Card number',
  pipo_common_payin_cardholder_email: 'Cardholder email',
  pipo_common_payin_cardholder_name: 'Cardholder name',
  pipo_common_payin_cc_dc: 'Debit/Credit card',
  pipo_common_payin_citytown: 'City/town',
  pipo_common_payin_citytown_US: 'City',
  pipo_common_payin_citytown_US_update: 'City',
  pipo_common_payin_citytown_ca: 'City',
  pipo_common_payin_citytown_de: 'municipality',
  pipo_common_payin_citytown_gb: 'County',
  pipo_common_payin_citytown_update: 'County',
  pipo_common_payin_citytown_us: 'County',
  pipo_common_payin_company: 'Company',
  pipo_common_payin_country: 'Country and Region',
  pipo_common_payin_country_ca: 'Country and Region',
  pipo_common_payin_country_gb: 'Country and Region',
  pipo_common_payin_country_update: 'Country and Region',
  pipo_common_payin_country_us: 'Country and Region',
  pipo_common_payin_cvv_popup_title: 'How to find security code?',
  pipo_common_payin_dana_badge: 'Limited offers. 50% off up to Rp 10,000',
  pipo_common_payin_direct_debit: 'Direct Debit',
  pipo_common_payin_expiry_date: 'Expiry date',
  pipo_common_payin_first_name: 'First Name',
  pipo_common_payin_id_number: 'ID Number',
  pipo_common_payin_id_type: 'ID Type',
  pipo_common_payin_individual: 'Personal',
  pipo_common_payin_last_name: 'Last Name',
  pipo_common_payin_mobile_address1: 'Region',
  pipo_common_payin_mobile_address2: 'Street address',
  pipo_common_payin_mobile_address3: 'Optional',
  pipo_common_payin_mobile_address_button1: 'Billing address',
  pipo_common_payin_mobile_address_button2: 'Same as shipping address',
  pipo_common_payin_mobile_address_inside1: 'Select Country/Region',
  pipo_common_payin_mobile_address_inside2: 'Search',
  pipo_common_payin_mobile_address_inside3_ca: 'Select Province',
  pipo_common_payin_mobile_address_inside3_gb: 'Select State',
  pipo_common_payin_mobile_address_inside3_us: 'Select State',
  pipo_common_payin_mobile_address_inside4_ca: 'Select City',
  pipo_common_payin_mobile_address_inside4_gb: 'Select County',
  pipo_common_payin_mobile_address_inside4_us: 'Select City',
  pipo_common_payin_mobile_address_inside5: 'Done',
  pipo_common_payin_mobile_address_inside6: 'Cancel',
  pipo_common_payin_mobile_address_inside7: 'No results found',
  pipo_common_payin_mobile_address_inside8: 'Try another search',
  pipo_common_payin_mobile_address_popup1_confirm: 'OK',
  pipo_common_payin_mobile_address_popup1_main: 'The address to which you would like your orders shipped.',
  pipo_common_payin_mobile_address_popup1_title: 'Billing address',
  pipo_common_payin_mobile_address_popup2_confirm: 'OK',
  pipo_common_payin_mobile_address_popup2_main: 'The address associated with your credit or debit card account.',
  pipo_common_payin_mobile_address_popup2_title: 'Shipping address',
  pipo_common_payin_mobile_address_popup3_cancel: 'Cancel',
  pipo_common_payin_mobile_address_popup3_confirm: 'Use shipping address',
  pipo_common_payin_mobile_address_popup3_main:
    'Selecting this option will remove the address you entered on this screen. If you wish to keep the address you entered, tap "Cancel".',
  pipo_common_payin_mobile_address_popup3_title: 'Warning',
  pipo_common_payin_mobile_address_title: 'Address',
  pipo_common_payin_mobile_basicinfo_title: 'Payment information',
  pipo_common_payin_ovo_bind_success: 'Bind your OVO account successfully',
  pipo_common_payin_ovo_bind_success_subtitle: 'We are processing back to cashier in 5s. \\nPlease wait...',
  pipo_common_payin_ovo_id: 'OVO ID',
  pipo_common_payin_ovo_id_append: 'Your phone number will be saved for the next OVO payment',
  pipo_common_payin_payment_method: 'Payment method',
  pipo_common_payin_pci:
    'We comply with the Payment Card Industry Data Security Standard (PCI DSS) to secure transmission and protect your personal data.',
  pipo_common_payin_pci_compliance:
    'We follow the Payment Card Industry Data Security Standard (PCI DSS) and use industry-standard encryption to protect the confidentiality of your personal information.',
  pipo_common_payin_personal_account: 'Personal Account',
  pipo_common_payin_ph_account_name: 'Account Name',
  pipo_common_payin_ph_account_number: 'Account Number',
  pipo_common_payin_ph_address_address: 'Address',
  pipo_common_payin_ph_address_address_update: 'Address',
  pipo_common_payin_ph_address_citytown: 'City/town',
  pipo_common_payin_ph_address_citytown_USv: 'City',
  pipo_common_payin_ph_address_citytown_USv_update: 'City',
  pipo_common_payin_ph_address_citytown_update: 'County',
  pipo_common_payin_ph_address_country: 'Country and Region',
  pipo_common_payin_ph_address_country_update: 'Country and Region',
  pipo_common_payin_ph_address_postcode: 'Postal code',
  pipo_common_payin_ph_address_postcode_update: 'Postal code',
  pipo_common_payin_ph_address_statecity: 'County',
  pipo_common_payin_ph_address_statecity_US: 'State/province',
  pipo_common_payin_ph_address_statecity_US_update: 'State/province',
  pipo_common_payin_ph_address_statecity_update: 'State',
  pipo_common_payin_ph_card_number: '1234 3213 2313 3213',
  pipo_common_payin_ph_cardholder_email: 'name@email.com',
  pipo_common_payin_ph_cardholder_name: 'Full name',
  pipo_common_payin_ph_cpf: '000.000.000-00',
  pipo_common_payin_ph_cvv_cvc: 'CVV/CVC',
  pipo_common_payin_ph_email: 'example@email.com',
  pipo_common_payin_ph_expiry_mm: 'MM',
  pipo_common_payin_ph_expiry_yy: 'YY',
  pipo_common_payin_ph_first_name: 'First Name',
  pipo_common_payin_ph_id_number: 'ID Number',
  pipo_common_payin_ph_id_type: 'ID Type',
  pipo_common_payin_ph_last_name: 'Last Name',
  pipo_common_payin_ph_mobile_address1_ca: 'City, province, country and region',
  pipo_common_payin_ph_mobile_address1_gb: 'County and Region, State, Country',
  pipo_common_payin_ph_mobile_address1_us: 'City, State, Country/Region',
  pipo_common_payin_ph_mobile_address2: '123 Main Street',
  pipo_common_payin_ph_ovo_id: 'Enter OVO account phone number',
  pipo_common_payin_ph_ovo_id_updated: 'Enter the phone number linked to your OVO account',
  pipo_common_payin_ph_phone_number: 'Phone number',
  pipo_common_payin_ph_routing_code: 'Routing Code',
  pipo_common_payin_phone_number: 'Phone number',
  pipo_common_payin_phone_number_append: 'A No. Virtual Account and other payment information will be sent to your phone',
  pipo_common_payin_pix_add: 'Add Pix',
  pipo_common_payin_postalcode: 'Postal code',
  pipo_common_payin_postalcode_update: 'Postal code',
  pipo_common_payin_proxy_type: 'Document type',
  pipo_common_payin_routing_code: 'Routing Code',
  pipo_common_payin_save_account: 'Save this account for next time',
  pipo_common_payin_save_bank: 'Save this bank account for next time',
  pipo_common_payin_save_card: 'Save this card for next time',
  pipo_common_payin_security_code: 'Security code',
  pipo_common_payin_state_city: 'State',
  pipo_common_payin_statecity: 'County',
  pipo_common_payin_statecity_US: 'State/province',
  pipo_common_payin_statecity_US_update: 'State/province',
  pipo_common_payin_statecity_ca: 'Province',
  pipo_common_payin_statecity_de: 'rural district',
  pipo_common_payin_statecity_gb: 'Country',
  pipo_common_payin_statecity_update: 'State',
  pipo_common_payin_statecity_us: 'State',
  pipo_common_payin_tin: 'Taxpayer Identification Number',
  pipo_common_payin_tips_security_code:
    'Security code is on your credit card or debit card is a 3 digit number on VISA and MasterCard branded credit and  debit cards. On your American Express branded credit or debit card it is a 4  digit numeric code.',
  pipo_common_payin_tips_security_code_1: 'The CVV is a number with 3 or 4 digits printed on the back or front of your card',
  pipo_common_payin_token_expire: "The payment token has expired. Extend the token's expiry date to continue with this payment method.",
  pipo_common_payin_token_expire_extendandconfirm: 'Extend and Confirm',
  pipo_common_payin_where_is_cvv: 'Where is the CVV ?',
  pipo_common_payout_card_number: 'Card number',
  pipo_common_payout_card_number_ph: 'Enter Card number',
  pipo_common_payout_expire_date: 'Expiration date',
  pipo_common_postal_code: 'Postal code',
  pipo_common_promptpay: 'PromptPay',
  pipo_common_province: 'Province',
  pipo_common_search_bankname: 'Search by bank name',
  pipo_common_search_branchname: 'Search by branch name',
  pipo_common_state: 'State',
  pipo_common_title_error: 'Error',
  pipo_common_title_secure_checkout: 'Secure checkout',
  pipo_common_town_city_suburb: 'Town/City/Suburb',
  pipo_common_try_again: 'Try again',
  pipo_common_unable_method_reason_insufficient_balance: 'Insufficient balance',
  pipo_common_unable_method_reason_merchant_support: 'Merchant does not support this payment method',
  pipo_common_unable_method_reason_risk_control: "Can't use this method",
  pipo_common_unable_method_reason_transaction_limits: 'Transaction limits',
  pipo_common_waiting_while_loading: 'Please wait while loading',
  pipo_common_waiting_while_loading_short: 'Loading',
  pipo_common_withdrawal_gotit: 'Got it',
  pipo_common_year: 'Year',
  pipo_dropinsdk_pc_paymentcode_exipiredhint: 'Complete the payment before the countdown ends to prevent expiration',
  pipo_inquiry_header_notice:
    'To process your withdrawal request, the bank requires the following information to verify your identity in compliance with its regulatory obligations.',
  pipo_merchantwithdraw_bindcard_indentifynumber_placeholder: '12345678AB1AB12A12',
  pipo_merchantwithdraw_bindcard_indentifynumber_title: 'Unique Identification Number',
  pipo_merchantwithdraw_bindcard_regisEmail_placeholder: 'example@email.com',
  pipo_merchantwithdraw_bindcard_regisEmail_title: 'Payoneer Registration',
  pipo_merchantwithdraw_fail_back: 'Back to home page',
  pipo_merchantwithdraw_fail_retry: 'Retry',
  pipo_merchantwithdraw_fail_subtitle: 'Please try again or go back to homepage',
  pipo_merchantwithdraw_fail_title: 'Binding failed',
  pipo_merchantwithdraw_list_top_up_hint:
    'Please choose a Payoneer account that will receive the payments for your shop {Merchant name} with the shop ID of {Merchant ID}',
  pipo_merchantwithdraw_pending_after_48hrs: 'The account is in the process of registration, please try again after 48 hours',
  pipo_merchantwithdraw_pending_subtitle: 'The account bind is processing',
  pipo_merchantwithdraw_pending_title: 'Pending',
  pipo_notice_valid_card_number: 'Please enter a valid card number',
  pipo_payin_PC_cashier_payment_directdebti_PI_title: 'Set up a Direct Debit Account with PIPO',
  pipo_payin_PC_cashier_payment_gocardless_BACSDirectDebitmandate: 'The Direct Debit Guarantee',
  pipo_payin_PC_cashier_payment_gocardless_BACSPolicy:
    '1. This Guarantee is offered by all banks and building societies that accept instructions to pay Direct Debits.\n2. If there are any changes to the amount, date or interval of your Direct Debit {merchant} will notify you 3 working days in advance of your account being debited or as otherwise agreed. If you request {merchant} to collect a payment, confirmation of the amount and date will be given to you at the time of the request\n3. If an error is made in the payment of your Direct Debit, by {merchant} or your bank or building society, you are entitled to a full and immediate refund of amount paid from your bank or building society-If you receive a refund you are not entitled to, you must pay it back when {merchant} asks you to. \nYou can cancel a Direct Debit at any time by simply contacting your bank or building society, Written confirmation may be required. Please also notify us.',
  pipo_payin_PC_cashier_payment_gocardless_BACS_Readme: 'Payments by GoCardless. Read the ',
  pipo_payin_PC_cashier_payment_gocardless_BACS_SortCode: 'Sort Code',
  pipo_payin_PC_cashier_payment_gocardless_BACS_privacynotice: 'GoCardless privacy notice',
  pipo_payin_PC_cashier_payment_gocardless_Confirmyourinformation: 'Confirm your information',
  pipo_payin_PC_cashier_payment_gocardless_PI_NewDirectDebitAccount: 'New Direct Debit Account',
  pipo_payin_PC_cashier_payment_gocardless_PI_card: 'Card',
  pipo_payin_PC_cashier_payment_gocardless_PI_newaccount: 'New account',
  pipo_payin_PC_cashier_payment_gocardless_SEPAConfirmtitile: 'You may cancel this Direct Debit at any time by contacting TikTok or your bank.',
  pipo_payin_PC_cashier_payment_gocardless_SEPADirectDebitmandate: 'SEPA Direct Debit mandate',
  pipo_payin_PC_cashier_payment_gocardless_SEPAPolicy:
    'By signing this mandate form, you authorise TikTok to send instructions to your bank to debit your account and your bank to debit your account in accordance with the instructions from TikTok. As part of your rights, you are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within 8 weeks starting from the date on which your account was debited. Please complete all the fields marked.\nYour rights are explained in a statement that you can obtain from your bank.',
  pipo_payin_PC_cashier_payment_gocardless_SEPA_BankCode: 'Bank Code',
  pipo_payin_PC_cashier_payment_gocardless_SEPA_Dateofmandatesetup: 'Date of mandate set up',
  pipo_payin_PC_cashier_payment_gocardless_SEPA_Transactiontype: 'Transaction type',
  pipo_payin_PC_cashier_payment_gocardless_SEPAconfirmpage_Creditoraddress: 'Creditor address',
  pipo_payin_PC_cashier_payment_gocardless_SEPAconfirmpage_Creditorinformation: 'Creditor information',
  pipo_payin_PC_cashier_payment_gocardless_SEPAconfirmpage_Creditorname: 'Creditor name',
  pipo_payin_PC_cashier_payment_gocardless_SEPAconfirmpage_SEPACreditorID: 'SEPA Creditor ID',
  pipo_payin_PC_cashier_payment_gocardless_SEPAconfirmpage_Transactiontype: 'Transaction type',
  pipo_payin_PC_cashier_payment_gocardless_button_Continue: 'Continue',
  pipo_payin_PC_cashier_payment_gocardless_button_Laststep: 'Last step',
  pipo_payin_PC_cashier_payment_gocardless_button_Setupaccountandpay: 'Set up account and pay',
  pipo_payin_PC_cashier_payment_gocardless_cell_BankInformation: 'Bank Information',
  pipo_payin_PC_cashier_payment_gocardless_cell_Bankaccountnumber: 'Bank account number',
  pipo_payin_PC_cashier_payment_gocardless_cell_BranchCode: 'Branch Code',
  pipo_payin_PC_cashier_payment_gocardless_cell_CardholdersEmail: 'Cardholder’s Email',
  pipo_payin_PC_cashier_payment_gocardless_cell_CardholdersName: 'Cardholder’s Name',
  pipo_payin_PC_cashier_payment_gocardless_cell_Country: 'Country',
  pipo_payin_PC_cashier_payment_gocardless_cell_IBAN: 'IBAN (Internatiaol Bank Account Number)',
  pipo_payin_PC_cashier_payment_gocardless_cell_IBAN_placeholder: 'IBAN',
  pipo_payin_PC_cashier_payment_gocardless_cell_State: 'State/City',
  pipo_payin_PC_cashier_payment_gocardless_cell_Town: 'City/Town',
  pipo_payin_PC_cashier_payment_gocardless_confirmDebitAccount: 'I confirm only one person is required to authorise the debits from this account',
  pipo_payin_PC_cashier_payment_gocardless_confirmpage_MerchantEmail: 'Merchant Email',
  pipo_payin_PC_cashier_payment_gocardless_confirmpage_MerchantInformation: 'Merchant Information',
  pipo_payin_PC_cashier_payment_gocardless_confirmpage_Merchantname: 'Merchant name',
  pipo_payin_add_payment_method: 'Add a new payment method',
  pipo_payin_agree: 'By continuing, you agree to our {Terms_of_Service} and {Privacy_Policy}.',
  pipo_payin_and: 'and',
  pipo_payin_bindPI_Pix: 'Save this Pix information for next time',
  pipo_payin_btn_buy_now: 'Buy now',
  pipo_payin_btn_manage: 'Manage',
  pipo_payin_btn_pay: 'Pay',
  pipo_payin_btn_pay_amount: 'Pay {currency symbol + amount}',
  pipo_payin_btn_pay_now: 'PAY NOW',
  pipo_payin_btn_retry: 'Retry',
  pipo_payin_btn_submit_payment: 'Submit payment',
  pipo_payin_card: 'Accepted payment methods',
  pipo_payin_card_bind_fail: 'Card binding failed.',
  pipo_payin_card_bind_fail_notice: 'Please try again or try a different card.',
  pipo_payin_card_bind_success: 'Card binding successfully.',
  pipo_payin_card_bind_success_notice: 'This card has been added in your payment method.',
  pipo_payin_card_manage_title: 'Payment method',
  pipo_payin_card_number_short_bank: '{bank name} **** {card no}',
  pipo_payin_card_number_short_card_org: '{card organization} **** {card no}',
  pipo_payin_card_operation: 'Operation',
  pipo_payin_card_status_expired: 'Expired',
  pipo_payin_card_status_primary: 'Primary',
  pipo_payin_card_sub_decline_01: 'The transaction amount exceeds the daily limit.',
  pipo_payin_card_sub_decline_03: 'The amount exceeds the limits established by the issuer for this  transaction.',
  pipo_payin_card_sub_decline_04: 'Insufficient funds.',
  pipo_payin_card_sub_decline_05: 'PIN code you entered is not correct',
  pipo_payin_card_sub_decline_06: 'PIN code tries exceeded. Please call the your issuing bank for more  information',
  pipo_payin_card_sub_decline_07: 'This card is expired. Please change another card to try again',
  pipo_payin_card_sub_decline_08: 'This card is not valid. Please call the your issuing bank for more  information',
  pipo_payin_card_sub_decline_09: 'The digital signature is invalid. Please try again',
  pipo_payin_card_sub_decline_10: 'Your bank account is not valid. Please call the your issuing bank for  more information',
  pipo_payin_card_sub_decline_11: 'Your merchant is not valid.  Please call customer service.',
  pipo_payin_card_sub_decline_12: 'Please call the customer service',
  pipo_payin_card_sub_decline_13: 'Please call your issuing bank for more information',
  pipo_payin_card_sub_decline_14: 'Please change another card to try again',
  pipo_payin_card_sub_error_01: 'Please try again',
  pipo_payin_card_sub_error_02: 'Your network is unavailable. Please try again later.',
  pipo_payin_card_sub_pending_01: 'Please wait for {day1}-{day2} business days to confirm',
  pipo_payin_cashier_PC_resultpage_fail_button_back: 'merchant page',
  pipo_payin_cashier_PC_resultpage_success_button_back: 'merchant page',
  pipo_payin_cashier_bindcard_mobile_PrivacypolicyforEURP: 'Payment will be processed separately by PIPO according to the {PIPO Privacy Policy}',
  pipo_payin_cashier_paymentmethod_alipay: 'Alipay',
  pipo_payin_cashier_paymentmethod_alipayhk: 'AlipayHK',
  pipo_payin_cashier_paymentmethod_wechat: 'Wechat',
  pipo_payin_cashier_paymentmethod_wechathk: 'WechatHK',
  pipo_payin_cc: 'Credit card',
  pipo_payin_cc_not_supported: 'Sorry, only debit card is accepted currently.',
  pipo_payin_checkout_CPF: 'CPF',
  pipo_payin_checkout_Email: 'Email',
  pipo_payin_checkout_PIPO_Privacy_Policy:
    "Payment transactions processed by PIPO. By continuing, you confirm that you agree to {PIPO's Privacy Policy}.",
  pipo_payin_checkout_PI_failmain: 'No saved cards',
  pipo_payin_checkout_cashier_paymentmethodname_2C2PBanktransfer: 'Bank transfer',
  pipo_payin_checkout_cashier_paymentmethodname_Klarna: 'Klarna Paynow',
  pipo_payin_checkout_disabled: 'Disabled',
  pipo_payin_checkout_header: 'Checkout',
  pipo_payin_checkout_midpage2_retry_butt: 'Retry',
  pipo_payin_checkout_midpage2_retry_main: 'Confirm payment',
  pipo_payin_checkout_midpage2_retry_main_1: 'Retry',
  pipo_payin_checkout_midpage2_retry_sub: 'Please try again',
  pipo_payin_checkout_midpage2_retry_title: 'Something went wrong',
  pipo_payin_checkout_midpage2_wait_main: 'Confirm payment',
  pipo_payin_checkout_midpage2_wait_sub: 'Payment could take a few minutes to complete',
  pipo_payin_checkout_midpage2_wait_title: 'Payment is processing',
  pipo_payin_checkout_midpage3_retry_navigationbar: 'Retry',
  pipo_payin_checkout_midpage_gopay_download: 'Download Gojek',
  pipo_payin_checkout_midpage_gopay_header: "Don't close this page until you complete your payment.",
  pipo_payin_checkout_midpage_gopay_header_or: 'OR',
  pipo_payin_checkout_midpage_gopay_main: '{If you have the Gojek app}, select "Already have Gojek, open it" to complete your payment.',
  pipo_payin_checkout_midpage_gopay_main1:
    'If you need to {download GoJek app first}, please click "I need to download GoJek app". Do remember {come back and click "Already have GoJek app, open it" after installation}.',
  pipo_payin_checkout_midpage_gopay_main2: 'After you complete your payment, select "Payment completed".',
  pipo_payin_checkout_midpage_gopay_openapp: 'Already have Gojek, open it',
  pipo_payin_checkout_midpage_gopay_paymentfinished: 'Payment completed',
  pipo_payin_checkout_order_details: 'Order details',
  pipo_payin_checkout_other_payment_methods: 'Other payment methods',
  pipo_payin_checkout_ovo_main:
    '1. Make sure to keep the current page open while you are on OVO. For TikTok user, you can check your order via "Me" tab and open the options menu, then select "Account" > "Orders".\\n2. You need to have an OVO Premium account for purchases over Rp 2 million IDR. \\n3. If you don\'t have the OVO app installed on your device, please download it and turn on push notifications.',
  pipo_payin_checkout_ovo_main_new:
    "1. Make sure to keep the current page open while you are on OVO. \\n2. You need to have an OVO Premium account for purchases over IDR 2 million.\\n3. If you don't have the OVO app installed on your device, please download it and turn on push notifications.",
  pipo_payin_checkout_ovo_title: 'Open the OVO app to approve the payment within 55 seconds',
  pipo_payin_checkout_pay_with_a_new_card: 'Pay with a new card',
  pipo_payin_checkout_saved_cards: 'Saved cards',
  pipo_payin_checkout_saved_pm: 'Saved payment methods',
  pipo_payin_checkout_see_more: 'See more',
  pipo_payin_confirm: 'Confirm',
  pipo_payin_confirm_3ds_main: 'The payment will be processed in 15 seconds',
  pipo_payin_confirm_3ds_title: 'Transaction processing',
  pipo_payin_confirm_main: 'The payment should be completed in 15 seconds',
  pipo_payin_confirm_title: 'Payment is processing',
  pipo_payin_copyPixcode: 'Copy Pix payment code',
  pipo_payin_dc: 'Debit card',
  pipo_payin_delete_card_fail: 'Delete failed',
  pipo_payin_delete_card_success: 'Delete successfully',
  pipo_payin_dropin_bindcard_addressinfo: 'Address Information',
  pipo_payin_dropin_bindcard_basicinfo: 'Basic Information',
  pipo_payin_dropin_cashier_bindcard_cardnumber_ocrcheck: 'Verify your card information',
  pipo_payin_dropin_cashier_bindcard_cardnumber_ocrpopup_titile: '弃用',
  pipo_payin_dropin_cashier_bindcard_cardnumber_ocrpopup_usercancel: 'Not now',
  pipo_payin_dropin_cashier_bindcard_cardnumber_ocrpopup_usernotice: 'Allow camera access to start taking pictures.',
  pipo_payin_dropin_cashier_bindcard_cardnumber_ocrpopup_userok: 'Allow access',
  pipo_payin_dropin_cashier_bindcard_cardnumber_ocrremind: 'Align the edges of your bank card to this frame',
  pipo_payin_dropin_cashier_qiwi_errow: 'QIWI account',
  pipo_payin_estimate_time: 'Funds should arrive in {day1} - {day2} business days',
  pipo_payin_everypage_abnormal_button: 'Retry',
  pipo_payin_everypage_abnormal_main: 'Something went wrong',
  pipo_payin_expiration_date: 'Expiration date',
  pipo_payin_in_payment: 'Payment processing',
  pipo_payin_last_pay_fail_notice: 'Something went wrong with your last payment.',
  pipo_payin_midpage_mobile_bindPI_CCDC_PC_pagecontent: 'Please keep the page running',
  pipo_payin_midpage_mobile_bindPI_CCDC_pagecontent: 'Please keep the app running',
  pipo_payin_midpage_mobile_bindPI_CCDC_pagetitle: 'We are requesting the response...',
  pipo_payin_midpage_mobile_bindPI_pagechecking_header: 'Processing',
  pipo_payin_midpage_mobile_bindPI_pagechecking_major: 'Processing timeout',
  pipo_payin_midpage_mobile_bindPI_pagechecking_minor: 'Please try again',
  pipo_payin_midpage_mobile_bindPI_pagetitle: 'Link accounts',
  pipo_payin_midpage_mobile_bindPI_retrytitle: 'Link accounts',
  pipo_payin_midpage_mobile_bindPI_subTitle: 'It may take a few moments before your accounts are linked.',
  pipo_payin_midpage_mobile_bindPI_title: 'Linking your accounts...',
  pipo_payin_midpage_pc_bindPI_pagechecking_major: 'Processing timeout',
  pipo_payin_midpage_pc_bindPI_pagechecking_minor: 'Please try again',
  pipo_payin_netbanking: 'Netbanking',
  pipo_payin_new_Pix_payment: 'Pay with new Pix account',
  pipo_payin_new_card: 'New card',
  pipo_payin_new_cc: 'New credit card',
  pipo_payin_new_cc_dc: 'New Credit or Debit Card',
  pipo_payin_new_dc: 'New debit card',
  pipo_payin_no_cards_added: 'No credit/debit cards have been added',
  pipo_payin_order_detail: 'Order details',
  pipo_payin_order_item: 'Order items',
  pipo_payin_order_summary: 'Order Summary',
  pipo_payin_pay_fail: "Couldn't process payment",
  pipo_payin_pay_sussess: 'Payment completed successfully',
  pipo_payin_payment_PC_banktransfer_promptmessage:
    'Enter the bank code (Permata is 013) followed by the 16 digit payment code as the destination account, then choose "Correct"',
  pipo_payin_payment_PC_ovoinput: 'Enter your OVO ID',
  pipo_payin_payment_PC_ovoinput_invalid: 'Invalid number',
  pipo_payin_payment_PC_ovoinput_loading_maintext: 'Please open your OVO app to approve the payment',
  pipo_payin_payment_PC_ovoinput_loading_subtext1:
    'Please make sure that you have an OVO premium account if the amount of order exceeds IDR 2 million.',
  pipo_payin_payment_PC_ovoinput_loading_subtext2: 'Please do not close TikTok when you open OVO app',
  pipo_payin_payment_PC_ovoinput_rememberid: 'Remember my account',
  pipo_payin_payment_PC_paypal_loading_maintext: 'Processing',
  pipo_payin_payment_PC_paypal_loading_subtext: 'Please wait while loading',
  pipo_payin_payment_PC_paypal_loading_subtext1: 'You will be redirected to Paypal to complete verification',
  pipo_payin_payment_PC_paypal_tryagain: 'Please try again',
  pipo_payin_payment_PCcashier_phonenumber_error: 'Your phone number is invalid',
  pipo_payin_payment_banktransfer_ID001: 'BRI',
  pipo_payin_payment_banktransfer_ID003: 'Mandiri',
  pipo_payin_payment_banktransfer_ID006: 'BCA',
  pipo_payin_payment_banktransfer_ID007: 'BNI',
  pipo_payin_payment_banktransfer_ID009: 'CIMB',
  pipo_payin_payment_banktransfer_ID011: 'Danamon',
  pipo_payin_payment_banktransfer_ID013: 'Maybank',
  pipo_payin_payment_banktransfer_ID015: 'Permata',
  pipo_payin_payment_banktransfer_ID128: 'Artajasa',
  pipo_payin_payment_banktransfer_ID139: 'Artajasa',
  pipo_payin_payment_banktransfer_ID139_update: 'Artajasa',
  pipo_payin_payment_banktransfer_ID155: 'BCA',
  pipo_payin_payment_banktransfer_ID888: 'Other bank',
  pipo_payin_payment_banktransfer_otherbank: 'Other bank',
  pipo_payin_payment_bca_info:
    'If you use BCA mobile banking to make a payment, select "m-Transfer" > "Antar Bank" under "Daftar Transfer", then select "Bank Mandiri".',
  pipo_payin_payment_bcapopup_button: 'OK',
  pipo_payin_payment_bcapopup_main: 'This will be an inter-bank transfer via Mandiri and additional fees can be charged',
  pipo_payin_payment_bcapopup_title: 'Making payment with BCA',
  pipo_payin_payment_cashier_phonenumber_error: 'Your phone number is invalid',
  pipo_payin_payment_cashphone: 'Phone number',
  pipo_payin_payment_cashphoneremind: 'A No. Virtual Account and other payment information will be sent to your phone',
  pipo_payin_payment_cashphoneremindlabal: 'Phone number',
  pipo_payin_payment_dont_know: "Don't know how to make payment",
  pipo_payin_payment_dont_prefer: "Don't see preferred payment method",
  pipo_payin_payment_main_ApplePay: 'Apple Pay',
  pipo_payin_payment_main_KakaoPay: 'Kakaopay',
  pipo_payin_payment_main_Korea: 'Korea Debit Card',
  pipo_payin_payment_main_Pix: 'Pix',
  pipo_payin_payment_main_banktransfer: 'Bank transfer',
  pipo_payin_payment_main_cashinalfa: 'Cash payment at Alfa',
  pipo_payin_payment_main_cashindomaret: 'Cash payment at Indomaret',
  pipo_payin_payment_main_dana: 'DANA',
  pipo_payin_payment_main_ewallet: 'E-wallet',
  pipo_payin_payment_main_gopay: 'GoPay',
  pipo_payin_payment_main_linepay: 'LINE Pay',
  pipo_payin_payment_main_mPay: 'mPAY',
  pipo_payin_payment_main_ovo: 'OVO',
  pipo_payin_payment_main_pppp: "Payment transactions processed by PIPO. By continuing, you confirm that you agree to {PIPO's Privacy Policy}.",
  pipo_payin_payment_main_qiwi: 'QIWI',
  pipo_payin_payment_main_razer_gold: 'Razer Gold',
  pipo_payin_payment_main_rlp: 'Rabbit LINE Pay',
  pipo_payin_payment_main_truemoney: 'True Money',
  pipo_payin_payment_otherbankpopup_button: 'OK',
  pipo_payin_payment_otherbankpopup_main: 'Select this option If your bank is not listed here',
  pipo_payin_payment_otherbankpopup_title: "Don't see your bank?",
  pipo_payin_paymentcode_ALFA_CVS_content:
    "1. Go to an Alfamart, Alfamidi, or Lawson store, inform the cashier that you wish to make a payment to {TikTok via Doku}, and provide your {16-digit payment code}\\n2. Wait for the cashier to confirm seller name, customer name, and payment amount\\n3. Wait for the transaction to be completed\\n4. After the transaction is completed, make sure to keep a receipt with stamp and cashier's signature\\n{Notice}: Keep the receipt as a proof of payment in case any further verification is needed.",
  pipo_payin_paymentcode_ALFA_CVS_title: 'Cash payment at Alfa',
  pipo_payin_paymentcode_ID001_atm_content:
    '1. Insert your BRI debit card and enter your PIN \\n2. Select "Other Transaction", then "Payment" > "Others" > "BRIVA"\\n3. Enter your 16-digit No. Virtual Account\\n4. Confirm that the BRIVA number,  customer name, and payment amount are correct\\n5. Follow the instructions to finish the payment\\n6. When the transaction is completed, print out a receipt and keep it for your records',
  pipo_payin_paymentcode_ID001_internet_banking_content:
    '1. Log in to your BNI Internet Banking account at https://ib.bri.co.id/ib-bri/Login.html\\n2. Select "Billing Payment", then "Payment" > "BRIVA"\\n3. Enter your 16-digit No. Virtual Account\\n4. Confirm that the BRIVA number,  customer name, and payment amount are correct\\n5. Enter your password and mToken\\n6. When the transaction is completed, take a screenshot or print out a receipt page and keep it for your records',
  pipo_payin_paymentcode_ID001_mobile_banking_content:
    '1. Log in to your BRI mobile account\\n2. Select "Mobile Banking BRI", and then tap "PEMBAYARAN" > "BRIVA"\\n3. Enter your 16-digit No. Virtual Account\\n4. Enter the transaction amount exactly as it appears on your order\\n5. Enter your PIN\\n6. When the transaction is completed, the payment receipt will be sent to you by SMS',
  pipo_payin_paymentcode_ID001_mobile_banking_content_update:
    '1. Log in to your BRI mobile account\\n2. Select "Mobile Banking BRI", and then tap "PEMBAYARAN" > "BRIVA"\\n3. Enter your 16-digit No. Virtual Account\\n4. Enter the transaction amount exactly as it appears on your order\\n5. Enter your PIN\\n6. When the transaction is completed, the payment receipt will be sent to you by SMS',
  pipo_payin_paymentcode_ID001_otherbank_content:
    '1. Choose "TRANSFER", then "Transfer to other bank"\\n2. Enter BRI bank code (002) (If you use BCA mobile, make sure to be registered first)\\n3. Enter the transaction amount exactly as it appears on your order\\n4. Enter your Virtual Account Number\\n5. Follow the instructions to finish the payment\\n6. Confirm that the payment confirmation screen is displayed',
  pipo_payin_paymentcode_ID003_atm_content:
    '1. Insert your ATM card and enter your PIN\\n2. Select "Pay/Buy"\\n3. Select "Others", then select "Multipayment"\\n4. Enter the DOKU Biller Code (89022), then select "Correct"\\n5. Enter DOKU "Virtual Account Number", then select "Correct" and enter the amount\\n6. Enter "1" for billing, then select "Yes"\\n7. When the payment confirmation screen is displayed, select "Yes"',
  pipo_payin_paymentcode_ID003_internet_banking_content:
    '1. Log in to your Mandiri Online account\\n2. Select "Payment"\\n3. Select "Multipayment"\\n4. Select "DOKU" (Biller Code: 89022) for the service provider\\n5. Enter your Virtual Account Number\\n6. Enter the transaction amount exactly as it appears on your order, then select "Continue"\\n7. After the payment amount is displayed, select "Confirm"\\n8. Enter your PIN and Challenge Code Token\\n9. When the transaction is completed, take a screenshot or print out a receipt page and keep it for your records',
  pipo_payin_paymentcode_ID003_mobile_banking_content:
    '1. Log in to your Mandiri mobile banking account\\n2. Select "Bayar" > "Multipayment", and then tap "LANJUT"\\n3. Search and select "DOKU Merchant (89022)" from the "Penyedia Jasa" list\\n4. Under "No Virtual", enter your 16-digit No. Virtual Account, and tap "TAMBAH SEBAGAI NOMOR BARU"\\n5. Under "Nominal", enter the transaction amount exactly as it appears on your order, and tap "LANJUT"\\n6. Tap "KONFIRMASI" on the Konfirmasi Pembayaran page\\n7. Enter your PIN and save your order',
  pipo_payin_paymentcode_ID003_mobile_banking_content_update:
    '1. Log in to your Mandiri mobile banking account\\n2. Select "Bayar" > "Multipayment", and then tap "LANJUT"\\n3. Search and select "DOKU Merchant (89022)" from the "Penyedia Jasa" list\\n4. Under "No Virtual", enter your 16-digit No. Virtual Account, and tap "TAMBAH SEBAGAI NOMOR BARU"\\n5. Under "Nominal", enter the transaction amount exactly as it appears on your order, and tap "LANJUT"\\n6. Tap "KONFIRMASI" on the Konfirmasi Pembayaran page\\n7. Enter your PIN and save your order',
  pipo_payin_paymentcode_ID007_atm_content:
    '1. Insert your ATM card\\n2. Select language\\n3. Enter your ATM PIN\\n4. Select "Other Menu"\\n5. Select "Transfer"\\n6. Select the account type  (Example: "From Source Account")\\n7. Select "Virtual Account Billing"\\n8. Enter your Virtual Account Number',
  pipo_payin_paymentcode_ID007_internet_banking_content:
    '1. Log in to your BNI Internet Banking account at https://ibank.bni.co.id\\n2. Select "Transfer"\\n3. Select "Virtual Account Billing"\\n4. Enter your Virtual Account Number \\n5. Select your debit account you wish to use, then select "Continue"\\n6. Confirm that the payment amount is displayed on the screen\\n7. Enter your TAC to initiate the transaction',
  pipo_payin_paymentcode_ID007_mobile_banking_content:
    '1. Log in to your BNI Mobile Banking account\\n2. Select "Transfer"\\n3. Select "Virtual Account Billing",  then select your debit account\\n4. Enter your Virtual Account Number in "New Input"\\n5. Confirm that the payment amount is displayed on the screen\\n6. Confirm the transaction and enter your transaction password',
  pipo_payin_paymentcode_ID007_otherbank_content:
    '1. Select "TRANSFER", then "Transfer to other bank"\\n2. Enter BNI bank code (009) (If you use BCA mobile, you need to have a BNI account)\\n3. Enter the transaction amount exactly as it appears on your order \\n4. Enter your Virtual Account Number\\n5. Follow the instructions to finish the payment\\n6. Confirm that the payment confirmation screen is displayed',
  pipo_payin_paymentcode_ID009_atm_content:
    '1. Insert your ATM card and enter your CIMB PIN \\n2. Select "Payment", then "Continue" > "Virtual Account"\\n3. Enter your Virtual Account Number\\n4. Verify your virtual account holder name and Virtual Account Number\\n5. Select "OK" to initiate the payment\\n6. When the transaction is completed, print out a receipt and keep it for your records',
  pipo_payin_paymentcode_ID009_internet_banking_content:
    '1. Log in to your online banking account\\n2. Select the option to transfer to other online bank\\n3. Select CIMB Niaga Bank (Bank code: 022)\\n4. Enter the transaction amount exactly as it appears on your order\\n5. Verify your virtual account holder name, Virtual Account Number, and the payment amount\\n6. Follow the instructions to finish the payment\\n7. Confirm that the payment confirmation screen is displayed',
  pipo_payin_paymentcode_ID009_mobile_banking_content:
    '1. Log in to your Go Mobile account\\n2. Select "Transfer to other CIMB Niaga account"\\n3. Select your source account: CASA or Phone account.\\n4. Enter your Virtual Account Number\\n5. Enter your Mobile Banking PIN\\n6. When the transaction is completed, the payment receipt will be sent to you by SMS',
  pipo_payin_paymentcode_ID009_otherbank_content:
    '1. Select "TRANSFER", then "Transfer to other bank"\\n2. Enter CIMB bank code (022) (If you use BCA mobile, you need to have a CIMB account)\\n3. Enter the transaction amount exactly as it appears on your order\\n4. Enter your Virtual Account Number\\n5. Follow the instructions to finish the payment\\n6. Confirm that the payment confirmation screen is displayed',
  pipo_payin_paymentcode_ID011_atm_content:
    '1. Insert your ATM card and enter your PIN\\n2. Select "Payment" > "Other" "Virtual Account"\\n3. Enter your 16-digit No. Virtual Account\\n4. Verify your virtual account holder name, Virtual Account Number, institution, and the payment amount\\n5. Select "Yes" to initiate the payment\\n6. When the transaction is completed, print out a receipt and keep it for your records',
  pipo_payin_paymentcode_ID011_mobile_banking_content:
    '1. Log in to your D-Mobile account\\n2. Select "Payment"\\n3. Select your virtual account, add the biller, and select "Payment"\\n4. Enter your 16-digit No. Virtual Account\\n5. Verify the payment details and enter your transaction PIN.',
  pipo_payin_paymentcode_ID011_otherbank_content:
    '1. Select "Transfer"\\n2. Select "Transfer to other bank" and select "Danamon Bank" as the destination\\n3. Enter Danamon bank code (011) and your 16-digit No. Virtual Account\\n4. Enter the transaction amount exactly as it appears on your order\\n5. When the payment confirmation screen is displayed, select "Yes"',
  pipo_payin_paymentcode_ID013_atm_content:
    '1. Select "Payment" \\n2. Select "Virtual Account"\\n3. Enter your Virtual Account Number and select "Correct"',
  pipo_payin_paymentcode_ID013_internet_banking_content:
    '1. Select "Fund Transfer"\\n2. Select "Virtual Account"\\n3. Select your source account \\n4. Enter your Virtual Account Number\\n5. Enter the transaction amount exactly as it appears on your order\\n6. Enter your TAC to initiate the transaction',
  pipo_payin_paymentcode_ID013_mobile_banking_content:
    'Send a SMS message to "69811" with the information in the following format: TRANSFER <source account number> <Virtual Account Number> <payment amount>',
  pipo_payin_paymentcode_ID013_otherbank_content:
    '1. Select "Transfer" under the bank menu\\n2. Enter Maybank bank code (016) and enter your Virtual Account Number\\n3. Enter the transaction amount exactly as it appears on your order\\n4. When the payment confirmation screen is displayed, select "Yes"',
  pipo_payin_paymentcode_ID015_atm_content:
    '1. Select "Other Transaction" \\n2. Select "Payment" > "Other Payment"\\n3. Select "Virtual Account"\\n4. Enter your Virtual Account Number\\n5. Confirm that the payment confirmation screen is displayed',
  pipo_payin_paymentcode_ID015_internet_banking_content:
    '1. Log in to your PermataNet account\\n2. Select "Billing Payment"\\n3. Select "Virtual Account" and enter your Virtual Account Number\\n4. Enter the transaction amount exactly as it appears on your order\\n5. Enter your TAC to initiate the transaction',
  pipo_payin_paymentcode_ID015_mobile_banking_content:
    '1. Log in to your PermataMobile X account\\n2. Select "Billing Payment"\\n3. Select "Virtual Account" and enter your Virtual Account Number\\n4. Enter the transaction amount exactly as it appears on your order\\n5. Enter your TAC to initiate the transaction',
  pipo_payin_paymentcode_ID015_otherbank_content:
    '1. Select "Transfer"\\n2. Enter Permata bank code (013) and select "CORRECT", then add the prefix (8641) followed by the 12-digit No. Virtual Account\\n3. Enter the transaction amount exactly as it appears on your order\\n4. Confirm that the payment confirmation screen is displayed',
  pipo_payin_paymentcode_ID128_atm_content:
    '1. Insert your ATM card that has an ATM Bersama logo on it\\n2. Select "Transfer to other bank"\\n3. Enter the Artajasa bank code (987) and your Virtual Account Number\\n4. Enter the transaction amount exactly as it appears on your order\\n5. Confirm the information to initiate the payment',
  pipo_payin_paymentcode_ID128_mobile_banking_content:
    '1. Open the app affiliated with ATM Bersama\\n2. Select "Transfer to other bank"\\n3. Enter the Artajasa bank code (987) and your Virtual Account Number\\n4. Enter the transaction amount exactly as it appears on your order\\n5. Confirm the information to initiate the payment',
  pipo_payin_paymentcode_ID139_atm_content:
    '1. Insert your ATM card that has an ATM Bersama logo on it\\n2. Select "Transfer to other bank"\\n3. Enter the Artajasa bank code (987) and your Virtual Account Number\\n4. Enter the transaction amount exactly as it appears on your order\\n5. Confirm the information to initiate the payment',
  pipo_payin_paymentcode_ID139_mobile_banking_content:
    '1. Open the app affiliated with ATM Bersama\\n2. Select "Transfer to other bank"\\n3. Enter the Artajasa bank code (987) and your Virtual Account Number\\n4. Enter the transaction amount exactly as it appears on your order\\n5. Confirm the information to initiate the payment',
  pipo_payin_paymentcode_ID155_atm_content:
    '1. Insert your ATM card and enter your PIN\\n2. Select "Transaksi Lainnya" > "Transfer" > "ke Rekening BCA Virtual Account"\\n3. Enter your 16-digit No. Virtual Account or "Daftar Transfer" if you have saved the your 16-digit No.Virtual Account before\\n4. Confirm that the Virtual Account code, account holder name, products, and payment amount are correct\\n5. Enter the transaction amount exactly as it appears on your order\\n6. Follow the on-screen instructions to initiate the payment\\n7. When the transaction is completed, print out a receipt and keep it for your records',
  pipo_payin_paymentcode_ID155_atm_content_extra: 'Need help?',
  pipo_payin_paymentcode_ID155_internet_banking_content:
    '1. Log in to your BCA Internet Banking account at https://klikbca.com\\n2. Select "Transfer Dana" > "Transfer ke BCA Virtual Account"\\n3. On the payment column, enter your 16-digit No. Virtual Account\\n4. Confirm that the Virtual Account code, account holder name, products, and payment amount are correct\\n5. Enter your password and mToken\\n6. Save the transfer receipt for your records',
  pipo_payin_paymentcode_ID155_mobile_banking_content:
    '1. Log in to your BCA mobile banking account and enter your m-BCA access code\\n2. Select "m-Transfer" > "BCA Virtual Account"\\n3. Select "Daftar Transfer" or enter your 16-digit No. Virtual Account\\n4. Enter your m-BCA PIN to initiate the payment\\n5. Take a screenshot of the confirmation screen or save the payment notification for your records.',
  pipo_payin_paymentcode_ID888_atm_content:
    'This payment method applies when you use a Mandiri virtual account to make a payment through another bank\'s ATM.\\nNote: A standard transaction fee may apply on ATM transfers.\\n1. Select "Transfer Online" or "Transfer to other bank"\\n2. Enter Mandiri bank code (008)\\n3. Enter your Virtual Account Number in the destination number section\\n4. Enter the transaction amount exactly as it appears on your order\\n5. Confirm that all the information is correct on the next screen and continue to initiate the payment\\n6. Confirm that the payment confirmation screen is displayed\\n7. When the transaction is completed, print out a receipt and keep it for your records',
  pipo_payin_paymentcode_Indomaret_CVS_content:
    "1. Go to an Indomaret or Ceriamart store, inform the cashier that you wish to make a payment to {TikTok through Doku}, and provide your {16-digit payment code}\\n2. Wait for the cashier to confirm seller name, customer name, and payment amount\\n3. Wait for the transaction to be completed\\n4. After the transaction is completed, make sure to keep a receipt with stamp and cashier's signature\\n{Notice}: Keep the receipt as a proof of payment in case any further verification is needed.",
  pipo_payin_paymentcode_Indomaret_CVS_content_ikioskmachine:
    "1. Go to an Indomaret or Ceriamart store and find an i-kios machine. Go to I-Payment / Payment Point > E-Commerce > TikTok\\n2. Enter your phone number and the 16-digit payment code, and tap Next\\n3. Confirm your order ID and invoice amount, tap Next, and tap Payment with cash\\n4. Collect the ticket from the machine, hand it to the cashier, and make the payment\\n5. After the transaction is completed, make sure to keep the receipt with stamp and cashier's signature\\nNotice: Keep the receipt as a proof of payment in case any further verification is needed.",
  pipo_payin_paymentcode_Indomaret_CVS_title: 'Cash payment at Indomaret with cashier',
  pipo_payin_paymentcode_Indomaret_CVS_title_ikioskmachine: 'Cash payment at Indomaret through i-kios machine',
  pipo_payin_paymentcode_Indomaret_ikios_content:
    '1. Open i.saku app\\n2. Select "Look All " and "Online Shopping" \\n3. Select "TikTok"\\n4. Enter your 16-digit No. Virtual Account and the transaction amount exactly as it appears on your order\\n5. Select "Pay" to initiate the payment',
  pipo_payin_paymentcode_Indomaret_ikios_title: 'i.saku mobile payment',
  pipo_payin_paymentcode_alfa_cvs_cahsier_content:
    "1. Go to an Alfamart, Alfamidi, or Lawson store, inform the cashier that you wish to make a payment to {TikTok via Doku}, and provide your {16-digit payment code}\\n2. Wait for the cashier to confirm seller name, customer name, and payment amount\\n3. Wait for the transaction to be completed\\n4. After the transaction is completed, make sure to keep a receipt with stamp and cashier's signature\\n{Notice}: Keep the receipt as a proof of payment in case any further verification is needed.",
  pipo_payin_paymentcode_alfa_cvs_cashier_content:
    "1. Go to an Alfamart, Alfamidi, or Lawson store, inform the cashier that you wish to make a payment to {TikTok via Doku}, and provide your {16-digit payment code}\\n2. Wait for the cashier to confirm seller name, customer name, and payment amount\\n3. Wait for the transaction to be completed\\n4. After the transaction is completed, make sure to keep a receipt with stamp and cashier's signature\\n{Notice}: Keep the receipt as a proof of payment in case any further verification is needed.",
  pipo_payin_paymentcode_alfa_cvs_content:
    "1. Go to an Alfamart, Alfamidi, or Lawson store, inform the cashier that you wish to make a payment to TikTok via Doku, and provide your 16-digit payment code\\n2. Wait for the cashier to confirm seller name, customer name, and payment amount\\n3. Wait for the transaction to be completed\\n4. After the transaction is completed, make sure to keep a receipt with stamp and cashier's signature\\nNotice: Keep the receipt as a proof of payment in case any further verification is needed.",
  pipo_payin_paymentcode_alfa_cvs_title: 'Cash payment at Alfa',
  pipo_payin_paymentcode_guide_link_text: 'Need help?',
  pipo_payin_paymentcode_howtomakepayment: 'How to make payments',
  pipo_payin_paymentcode_indomaret_cvs_cashier_content:
    "1. Go to an Indomaret or Ceriamart store, inform the cashier that you wish to make a payment to {TikTok}, and provide your {16-digit payment code}\\n2. Wait for the cashier to confirm seller name, customer name, and payment amount\\n3. Wait for the transaction to be completed\\n4. After the transaction is completed, make sure to keep a receipt with stamp and cashier's signature\\n{Notice}: Keep the receipt as a proof of payment in case any further verification is needed.",
  pipo_payin_paymentcode_indomaret_cvs_cashier_title: 'Cash payment at Indomaret with cashier',
  pipo_payin_paymentcode_indomaret_cvs_content:
    "1. Go to an Indomaret or Ceriamart store, inform the cashier that you wish to make a payment to TikTok through Doku, and provide your 16-digit payment code\\n2. Wait for the cashier to confirm seller name, customer name, and payment amount\\n3. Wait for the transaction to be completed\\n4. After the transaction is completed, make sure to keep a receipt with stamp and cashier's signature\\nNotice: Keep the receipt as a proof of payment in case any further verification is needed.",
  pipo_payin_paymentcode_indomaret_cvs_content_ikiosk_machine:
    "1. Go to an Indomaret or Ceriamart store and find an i-kios machine. Go to {I-Payment / Payment Point > E-Commerce > TikTok}\\n2. Enter your phone number and the {16-digit payment code}, and tap {Next}\\n3. Confirm your order ID and invoice amount, tap {Next}, and tap {Payment with cash}\\n4. Collect the ticket from the machine, hand it to the cashier, and make the payment\\n5. After the transaction is completed, make sure to keep the receipt with stamp and cashier's signature\\n{Notice}: Keep the receipt as a proof of payment in case any further verification is needed.",
  pipo_payin_paymentcode_indomaret_cvs_content_ikioskmachine:
    "1. Go to an Indomaret or Ceriamart store and find an i-kios machine. Go to I-Payment / Payment Point > E-Commerce > TikTok\\n2. Enter your phone number and the 16-digit payment code, and tap Next\\n3. Confirm your order ID and invoice amount, tap Next, and tap Payment with cash\\n4. Collect the ticket from the machine, hand it to the cashier, and make the payment\\n5. After the transaction is completed, make sure to keep the receipt with stamp and cashier's signature\\nNotice: Keep the receipt as a proof of payment in case any further verification is needed.",
  pipo_payin_paymentcode_indomaret_cvs_title: 'Cash payment at Indomaret',
  pipo_payin_paymentcode_indomaret_cvs_title_ikiosk_machine: 'Cash payment at Indomaret through i-kios machine',
  pipo_payin_paymentcode_indomaret_ikios_content:
    '1. Open i.saku app\\n2. Select "Look All " and "Online Shopping" \\n3. Select "TikTok"\\n4. Enter your 16-digit No. Virtual Account and the transaction amount exactly as it appears on your order\\n5. Select "Pay" to initiate the payment',
  pipo_payin_paymentcode_indomaret_ikios_title: 'i.saku mobile payment',
  pipo_payin_paymentcode_instrcution_hint: 'Please follow the instruction strictly, otherwise the transaction may fail.',
  pipo_payin_paymentcode_nameofbank: 'Bank',
  pipo_payin_paymentcode_ordernumber: 'Order number',
  pipo_payin_paymentcode_paymentcode: 'No. Virtual Account',
  pipo_payin_paymentcode_paymentstatu_expired: 'Expired',
  pipo_payin_paymentcode_paymentstatu_failed: 'Failed',
  pipo_payin_paymentcode_paymentstatu_success: 'Success',
  pipo_payin_paymentcode_paymentstatu_waitingforpayment: 'Waiting for payment',
  pipo_payin_paymentcode_remind:
    'Take a screenshot of this page or take a note of your No. Virtual Account for your records. You can find this page any time on the TikTok app by going to <b>Me</b> options menu, then selecting <b>Settings and privacy</b> > <b>Orders</b> > <b>Unpaid</b> > <b>View code</b>.',
  pipo_payin_paymentcode_subtitle_expired: 'If you still wish to purchase the items, you will need to repeat the order process',
  pipo_payin_paymentcode_subtitle_failed: 'Payment has been failed, please contact the customer service',
  pipo_payin_paymentcode_subtitle_success: 'The payment has been processed. Please check your order',
  pipo_payin_paymentcode_subtitle_waitingforpayment: 'Pay within {countdown} before the No. Virtual Account expires',
  pipo_payin_paymentcode_title_Via_ATM: 'ATM payment',
  pipo_payin_paymentcode_title_Via_Internet_Banking: 'Online banking payment',
  pipo_payin_paymentcode_title_Via_Mobile_banking: 'Mobile banking payment',
  pipo_payin_paymentcode_title_Via_Other_Banks: 'Other Bank payment',
  pipo_payin_paymentcode_totalamount: 'Total',
  pipo_payin_paymentcode_waitingforpayment_copynumber_fail: 'The No. Virtual Account is no longer valid',
  pipo_payin_paymentcode_waitingforpayment_copynumber_success: 'Thank you for your payment',
  pipo_payin_paymentcode_waitingforpayment_copynumber_wait: 'Copy code',
  pipo_payin_pending_notice: 'The payment is awaiting processing.',
  pipo_payin_pi_balance: 'Balance',
  pipo_payin_pimanage_addpm1: 'Add',
  pipo_payin_pimanage_head: 'Payment method',
  pipo_payin_pimanage_pc_unbindpopup_title: 'Confirm deletion of {method_name}?',
  pipo_payin_pimanage_savedpm1: 'Saved cards',
  pipo_payin_pimanage_savepm2: 'Other payment methods',
  pipo_payin_pp: 'Privacy Policy',
  pipo_payin_primary_title: 'Primary',
  pipo_payin_promotion: 'Discount',
  pipo_payin_put_cards: 'See more',
  pipo_payin_put_cards_hide: 'Hide',
  pipo_payin_quit_reason_option_do_not_want_to_buy: "Don't want to buy the product",
  pipo_payin_quit_reason_option_option_other: 'Other reasons',
  pipo_payin_quit_reason_option_other: 'Other reasons',
  pipo_payin_quit_reason_option_payment_problem: 'Have problems with payment',
  pipo_payin_quit_reason_other_label: 'Leave us a note (optional)',
  pipo_payin_quit_reason_other_ph: 'Include a description',
  pipo_payin_quit_reason_question: 'Why do you want to quit?',
  pipo_payin_security_note: 'Your details are secured by PCI DSS. This complies with the strongest identity authentication standard available today.',
  pipo_payin_select_payment_method: 'Select a payment method',
  pipo_payin_sgpimanage_head: 'Payment method',
  pipo_payin_sgpimanage_primary: 'Primary',
  pipo_payin_sgpimanage_primarypopup_body: '1. You can only set one card as the primary payment method.',
  pipo_payin_sgpimanage_primarypopup_body1: '2. The current primary payment method will no longer be set as primary.',
  pipo_payin_sgpimanage_primarypopup_body_update: '1. You can only set one card as the primary payment method.',
  pipo_payin_sgpimanage_primarypopup_buttonm: 'Set as primary',
  pipo_payin_sgpimanage_primarypopup_buttons: 'Cancel',
  pipo_payin_sgpimanage_primarypopup_title: 'Set this method as the primary payment method?',
  pipo_payin_sgpimanage_set_as_primary: 'Set primary card',
  pipo_payin_sgpimanage_unbinding: 'Remove Card',
  pipo_payin_sgpimanage_unbindpopup_body: 'If you want to remove this payment method from the list of payment options, tap "Remove".',
  pipo_payin_sgpimanage_unbindpopup_buttonm: 'Cancel',
  pipo_payin_sgpimanage_unbindpopup_buttons: 'Remove',
  pipo_payin_sgpimanage_unbindpopup_title: 'Remove this payment method?',
  pipo_payin_show_bank: 'Show all banks',
  pipo_payin_show_card: 'Show all cards',
  pipo_payin_status_title_declined: 'Transaction was declined',
  pipo_payin_status_title_error: 'Oops. Something went wrong',
  pipo_payin_status_title_payment_pending: 'Payment pending',
  pipo_payin_status_title_pending: 'Pending',
  pipo_payin_status_title_successful: 'Payment Successful',
  pipo_payin_subtotal: 'Subtotal',
  pipo_payin_tax: 'Tax',
  pipo_payin_tos: 'Terms of Service',
  pipo_payin_total_amount: 'Total',
  pipo_payin_username: '{user_name}',
  pipo_payment_error_expire_date_early: 'Expiration date must not be earlier than current month.',
  pipo_payment_error_incorrect_digits: 'Card number digits is not correct.',
  pipo_payment_error_incorrect_expire: 'Expiration date is not correct.',
  pipo_payment_error_pay001: 'Generic payment error. Please contact customer service.',
  pipo_payment_error_pay002: 'Insufficient fund or credit',
  pipo_payment_error_pay003: 'Gateway timeout',
  pipo_payment_error_pay004: 'Amount exceeds limit.',
  pipo_payment_error_pay005: 'Refund amount too large',
  pipo_payment_error_pay006: 'Cannot find payment record ID. ',
  pipo_payment_error_pay007: 'cannot find charge record',
  pipo_payment_error_pay008: 'cannot find refund record',
  pipo_payment_error_pay009: 'Payment already closed',
  pipo_payment_error_pay010: 'Payment already expired',
  pipo_payment_error_pay011: 'Exceeds max refund time',
  pipo_payment_error_pay012_1: 'Incorrect payment method data',
  pipo_payment_error_pay012_2: 'Invalid card number',
  pipo_payment_error_pay012_3: 'Invalid card cvv',
  pipo_payment_error_pay012_4: 'Invalid card expiration date',
  pipo_payment_error_pay013: '3ds failed',
  pipo_payment_error_pay014: 'cannot find payment method record',
  pipo_payment_error_pay015: 'Unavailable payment method',
  pipo_payment_error_unaccept_card_org: 'Card organization is not accepted currently.',
  pipo_payment_unknown_error: 'Unknown error. Please contact customer service.',
  pipo_payout_cc_header: 'Withdraw',
  pipo_paypal_hyperwallet_notice:
    'The payment may be processed by Hyperwallet, a PayPal service, or by PayPal directly. Hyperwallet {Privacy_Policy} and {Terms_of_Service}',
  pipo_qiwi_phone_number_rule: 'Your QIWI ID shall be start with 0 and the length should be 12 digits',
  pipo_request_error_invalid_currency: 'Invalid currency',
  pipo_request_error_merchant_id_long: 'Merchant user id too long',
  pipo_request_error_merchant_id_missing: 'Merchant order id missing',
  pipo_security_error_sec001: 'Cannot authenticate merchant',
  pipo_security_error_sec002: 'Unauthorized operation',
  pipo_security_error_sec003_1: 'Detected possible fraud. Please contact customer service.',
  pipo_security_error_sec003_2: 'Detected possible chargeback. Please contact customer service.',
  pipo_txn_detail_generalError: 'Sorry, there is something wrong with the system, try again later',
  pipo_txn_detail_link_inquiry: 'Fill in Inquiry',
  pipo_txn_detail_requestTime: 'Request time',
  pipo_txn_detail_status_failed: 'Transaction is failed',
  pipo_txn_detail_status_inquiryInProcess: 'Processing (Inquiry Required)',
  pipo_txn_detail_status_inquirySubmitted: 'Processing (Inquiry Submitted)',
  pipo_txn_detail_status_processing: 'Transaction is processing',
  pipo_txn_detail_status_success: 'Transaction is completed',
  pipo_txn_detail_tips_default: 'The update is coming, please come back later.',
  pipo_txn_detail_tips_inquiryRequired: 'Please fill in compliance inquiry to further process transaction',
  pipo_txn_detail_title: 'Transaction details',
  pipo_txn_detail_updateTime: 'Update time',
  pipo_withdraw_beneficiary_info: 'Beneficiary Information',
  pix_inquiry_email_content:
    'Dear user, we would like to inform you that your withdrawal request to your PIX in TikTok is pending. Further action is required to enable the request to be processed.',
  pix_inquiry_email_head: '[TikTok cash withdrawal] Further Action Required',
  tp_error_tp0007: 'Insufficient funds in your bank account or your payment service account.',
  tp_error_tp0008: "Transaction couldn't be processed. Please try again later or contact customer support for details.",
  tp_error_tp0009: 'Too many PIN entry attempts. Try again in 24 hours.',
}
