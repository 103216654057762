"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault")["default"];
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.formatSubmitFormDataForBindProcess = void 0;
var _filter = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/filter"));
var _map = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/map"));
var _entries = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/entries"));
var _stringify = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/json/stringify"));
var _objectSpread2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/objectSpread2"));
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/slicedToArray"));
var _omit2 = _interopRequireDefault(require("lodash/omit"));
var _encryptString = require("../encrypt-string");
var formatSubmitFormDataForBindProcess = function formatSubmitFormDataForBindProcess(submitData, publicKey) {
  var _context, _context2;
  if (!submitData) return '';
  var elements = (0, _filter["default"])(_context = (0, _map["default"])(_context2 = (0, _entries["default"])(submitData.formData)).call(_context2, function (_ref) {
    var _ref2 = (0, _slicedToArray2["default"])(_ref, 2),
      key = _ref2[0],
      value = _ref2[1];
    return {
      param_name: key,
      param_value: value && (0, _encryptString.encryptString)(value, publicKey) || ''
    };
  })).call(_context, function (element) {
    return !!element.param_value;
  });
  var extraParams = (0, _omit2["default"])(submitData, ['formData']);
  return (0, _stringify["default"])((0, _objectSpread2["default"])({
    elements: elements
  }, extraParams));
};
exports.formatSubmitFormDataForBindProcess = formatSubmitFormDataForBindProcess;