import { useContext, useEffect, useState } from 'react'

import { useRequest } from '@byted/hooks'

import { FROM_PAGE_ENUM, NEW_SDK_TERMINAL_EQUIP } from '@/constant'
import api from '@/services'
import { ParamsContext } from '@/stores'
import slardar from '@/utils/slardar'

export enum RemindActionType {
  Show = 'show',
  Refresh = 'refresh',
  Ignore = 'ignore',
}

interface IProps {
  fromPage: FROM_PAGE_ENUM
  pageVisible: boolean
}
export const useJudgeVersion = (props: IProps) => {
  const { fromPage, pageVisible } = props
  const { urlQuery } = useContext(ParamsContext)
  const { token, accountId } = urlQuery

  const [hasReminded, setHasReminded] = useState(() => {
    let bool = false
    try {
      bool = localStorage.getItem(`upay_has_reminder_${accountId}`) === BUILD_VERSION
    } catch (e: any) {
      console.log(e)

      slardar.timer('sdkVersionReminderError', 1, {
        type: 'getItem',
        msg: e?.msg || e?.message || 'error',
      })
    }
    return bool
  })

  const { data: res } = useRequest(api.judgeSdkVersion, {
    auto: true,
    refreshDeps: [pageVisible],
    ready: !!pageVisible,
    debounceInterval: 1000, // 不加的话在第二次打开页面的时候会连发两次
    defaultParams: [
      {
        token,
        curVersion: BUILD_VERSION,
        terminalEquip: NEW_SDK_TERMINAL_EQUIP,
      },
      { withError: false },
    ],
  })

  const showReminder = res?.data?.showRefreshMsg && !hasReminded

  useEffect(() => {
    if (showReminder) {
      slardar.timer('sdkVersionReminder', 1, {
        action: RemindActionType.Show,
        fromPage,
      })
    }
  }, [showReminder, fromPage])

  const handleRead = (type: RemindActionType) => {
    try {
      setHasReminded(true)
      localStorage.setItem(`upay_has_reminder_${accountId}`, BUILD_VERSION)

      slardar.timer('sdkVersionReminder', 1, {
        action: type,
      })
    } catch (e: any) {
      console.log(e)

      slardar.timer('sdkVersionReminderError', 1, {
        type: 'setItem',
        action: type,
        msg: e?.msg || e?.message || 'error',
      })
    }
  }

  return {
    // 是否展示刷新提示
    showReminder,
    // 是否强制刷新，暂时用不上
    forceReload: res?.data?.forceUpdateVersion,
    // 设置已读
    handleRead,
  }
}
