import starling from '@/utils/starling'

import { PipoProvider } from '../utils'

interface IProps {
  phoneRegex: RegExp
  options: {
    type: string
    min?: number
    max?: number
    errorMessage?: string
  }
}
export const usePhoneValidator = ({ phoneRegex, options }: IProps) => {
  const { getErrorText } = PipoProvider.useContext()
  const phoneValidate = (value?: string) => phoneRegex.test(value || '')
  const getPhoneNumErrorText = () => {
    const { type, min, max, errorMessage } = options
    if (type === 'phoneValidate') {
      if (errorMessage) return errorMessage
      if (min && max)
        return starling('funds.refund.comm.enter_number_length', {
          numbers1: min,
          numbers2: max,
        })
      return getErrorText(type)
    } else {
      return getErrorText(type)
    }
  }
  return {
    phoneValidate,
    errorMessage: getPhoneNumErrorText(),
  }
}
