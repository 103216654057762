import React from 'react'
import { Control, Controller, FieldErrors } from 'react-hook-form'

import { PipoProvider } from '@/pipo/utils'
import starling from '@/utils/starling'

import { IdTypeSelect, ItemInputNumber } from '../../inner-components'
import { DirectDebitFormData } from './utils'
import { idNumberRegex } from './validator'

interface IProps {
  clsPrefix: string
  control: Control<DirectDebitFormData, any>
  errors: FieldErrors<DirectDebitFormData>
  handleBlur: (...args: any[]) => void
}
export const BusinessAccount = (props: IProps) => {
  const { control, errors, clsPrefix, handleBlur } = props
  const { t, getErrorText } = PipoProvider.useContext()

  return (
    <React.Fragment>
      <div className={`${clsPrefix}-subform-wrapper`}>
        <Controller
          rules={{ required: true }}
          name="id_type"
          control={control}
          defaultValue={'tin'}
          render={({ field }) => <IdTypeSelect {...field} title={starling('funds.refund.comm.system_bankaccount_idtype')} />}
        />
        <Controller
          rules={{ required: true, pattern: idNumberRegex }}
          name="identity"
          control={control}
          render={({ field, fieldState }) => (
            <ItemInputNumber
              {...field}
              error={errors.identity}
              errorMessage={getErrorText(errors.identity?.type)}
              title={starling('funds.refund.comm.system_bankaccount_idnumber')}
              placeholder={starling('funds.refund.comm.system_bankaccount_idnumber_placeholder')}
              maxLength={9}
              onBlur={() => {
                field.onBlur()
                handleBlur('identity', fieldState)
              }}
            />
          )}
        />
      </div>
    </React.Fragment>
  )
}
