import React, { forwardRef, useContext } from 'react'

import { useRequest } from '@byted/hooks'

import { PAYOUT_WAY_TYPE } from '@/constant/payout'
import { usePayoutBindElements } from '@/hooks'
import { BankAccount, BankAccountFormProps } from '@/pipo/pc-payout'
import { PayoutComponentRef } from '@/pipo/pc-payout/types'
import { IPayoutGetBindElementsProps } from '@/services/payout-types'
import GlobalInfo from '@/stores/GlobalInfo'

// 对表单组件的二次封装 补充额外属性
const BankAccountWidget = forwardRef((props: Omit<BankAccountFormProps, 'bankList'>, ref: React.ForwardedRef<PayoutComponentRef>) => {
  const {
    PIPOApi: { fetchDistrict },
    utils: { getContractAddressAsync },
  } = useContext(GlobalInfo)
  const { getBankListAsync, bankList, getBankListLoading } = usePayoutBindElements()
  const { data: contractAddressList, run: fetchContractAddress } = useRequest(getContractAddressAsync, { auto: false })

  const refreshBankList = (userType: IPayoutGetBindElementsProps['userType']) => {
    getBankListAsync({ payWay: PAYOUT_WAY_TYPE.BANK_ACCOUNT, userType })
  }

  return (
    <BankAccount
      bankList={getBankListLoading ? undefined : bankList}
      {...props}
      onFetchDistrict={fetchDistrict}
      contractAddressList={contractAddressList}
      refreshBankList={refreshBankList}
      ref={ref}
      fetchContractAddress={fetchContractAddress}
    />
  )
})

export default BankAccountWidget
