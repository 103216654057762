"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.PAY_WAY_TYPE = exports.PAYMENT_TYPE_MAP = exports.BIZ_PLATFORM_MAP = void 0;
var PAY_WAY_TYPE = {
  /** bank transfer 支付方式 */
  BANK_TRANSFER: 30,
  /** 新卡支付 */ //
  CCDC: 0,
  CREATE_NEW_CARD: 0,
  /** 已有卡支付 */
  EXISTED_CARD: 99,
  /** OVO */
  OVO: 34,
  /** DANA */
  DANA: 35,
  /** DIRECT_DEBIT_US */
  DIRECT_DEBIT: 36,
  /** DIRECT_DEBIT_EU */
  DIRECT_DEBIT_EU: 47,
  /** DIRECT_DEBIT_CA */
  DIRECT_DEBIT_CA: 50,
  /** DIRECT_DEBIT_CA */
  DIRECT_DEBIT_UK: 57,
  /** Boleto */ // CASH_PIN
  BOLETO: 39,
  /** QIWI */
  QIWI: 40,
  /** paypal */
  PAYPAL: 5,
  /** paypal refrence */
  PAYPAL_REF: 31,
  /** FPX */
  FPX: 42,
  /** PIX */
  PIX: 44,
  /** SHOPEE_PAY */
  SHOPEE_PAY: 45,
  /** GoPay */
  GOPAY: 49,
  /** BANK_TRANSFER_BO */
  BANK_TRANSFER_BO: 56,
  /** MoMo */
  MOMO: 52,
  /** Zalo */
  ZALO: 54,
  /** Paycell */
  PAYCELL: 58,
  /** Toss */
  TOSS: 61,
  AT_EPS: 64,
  /** BANK_TRANSFER_JP */
  BANK_TRANSFER_JP: 68,
  VENMO: 65,
  /** Fawry */
  FAWRY: 66,
  OXXO: 70,
  PAY_U: 71
};
exports.PAY_WAY_TYPE = PAY_WAY_TYPE;
var BIZ_PLATFORM_MAP = {
  TT_ADS: '87316307520',
  BC: '87316307533',
  SHOPIFY: '87216307551',
  TCM: '87216307523',
  TCM_US: '100002',
  TBP: '100003',
  PROMOTE_SG: '87216307519',
  PROMOTE_TTP: '87216307553',
  PROMOTE_VA: '87216307550'
};
exports.BIZ_PLATFORM_MAP = BIZ_PLATFORM_MAP;
var PAYMENT_TYPE_MAP = {
  PRE_PAY: '1',
  POST_PAY: '2',
  CHANGE_TYPE: '3',
  MONTHLY_PAY: '4'
};
exports.PAYMENT_TYPE_MAP = PAYMENT_TYPE_MAP;