"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault")["default"];
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.setDisplayBankIcon = exports.onBankIbanChange = exports.internetBankIcon = exports.ibanValidInput = exports.formatValue = exports.formatCursor = exports.errorMessage = exports.banktransferBankIcon = exports.IBANInternetValidator = exports.IBANBanktransferValidator = void 0;
var _find = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/find"));
var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));
var _includes = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/includes"));
var _ = require(".");
var _context;
var setDisplayBankIcon = function setDisplayBankIcon(val, supportBank) {
  var bankRegex = /^TR\d{4}(\d{3})\d*/;
  var valWithoutSpace = val === null || val === void 0 ? void 0 : val.replace(/\s/g, '');
  var bankCodeMatch = valWithoutSpace.match(bankRegex);
  var bankCode = bankCodeMatch ? bankCodeMatch[1] : '';
  var matchIcon = (0, _find["default"])(supportBank).call(supportBank, function (ele) {
    return ele.code === bankCode;
  });
  return {
    icon: (matchIcon === null || matchIcon === void 0 ? void 0 : matchIcon.icon) || '',
    starlingKey: (matchIcon === null || matchIcon === void 0 ? void 0 : matchIcon.starlingKey) || ''
  };
};
exports.setDisplayBankIcon = setDisplayBankIcon;
var internetBankIcon = [{
  code: '046',
  starlingKey: 'pipo_payin_logo_Akbank',
  icon: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/akbankgrey_c1216c.png'
}, {
  code: '064',
  starlingKey: 'pipo_payin_logo_Isbank',
  icon: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/isbankgrey_fd5301.png'
}, {
  code: '203',
  starlingKey: 'pipo_payin_logo_Albaraka',
  icon: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/albarakagrey_b0c72c.png'
}, {
  code: '015',
  starlingKey: 'pipo_payin_logo_Vakifbank',
  icon: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/vakifbankgrey_be5faa.png'
}, {
  code: '134',
  starlingKey: 'pipo_payin_logo_Denizbank',
  icon: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/denizbankgrey_0caae5.png'
}, {
  code: '205',
  starlingKey: 'pipo_payin_logo_Kuveytturk',
  icon: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/kuveytturkgreynew_7e540a.png'
}, {
  code: '103',
  starlingKey: 'pipo_payin_logo_Fibabanka',
  icon: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/fibabankagreynew_335225.png'
}];
exports.internetBankIcon = internetBankIcon;
var banktransferBankIcon = (0, _concat["default"])(_context = []).call(_context, internetBankIcon, [{
  code: '067',
  starlingKey: 'pipo_payin_logo_Yapikredi',
  icon: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/yapikredigrey_a21d9d.png'
}, {
  code: '124',
  starlingKey: 'pipo_payin_logo_Alternatifbank',
  icon: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/alternatifbankgrey_74ee22.png'
}, {
  code: '062',
  starlingKey: 'pipo_payin_logo_Garanti',
  icon: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/garantigrey_8c7389.png'
}, {
  code: '012',
  starlingKey: 'pipo_payin_logo_Halkbank',
  icon: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/halkbankgrey_c2146f.png'
}, {
  code: '111',
  starlingKey: 'pipo_payin_logo_Qnb',
  icon: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/qnbgrey_ac19a8.png'
}, {
  code: '206',
  starlingKey: 'pipo_payin_logo_Trkiyefinans',
  icon: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/turkiyefinansgrey_d6d00a.png'
}, {
  code: '010',
  starlingKey: 'pipo_payin_logo_Ziraatbankasi',
  icon: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/ziraatbankgrey_794716.png'
}, {
  code: '059',
  starlingKey: 'pipo_payin_logo_Sekerbank',
  icon: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/sekerbankgrey_9cfa80.png'
}, {
  code: '032',
  starlingKey: 'pipo_payin_logo_Teb',
  icon: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/tebgrey_cc6cf8.png'
}, {
  code: '099',
  starlingKey: 'pipo_payin_logo_Ing',
  icon: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/inggreynew_f3fc8b.png'
}]);
exports.banktransferBankIcon = banktransferBankIcon;
var formatValue = function formatValue(value) {
  if (!value) return '';
  if ((0, _includes["default"])(value).call(value, ' ')) {
    var _value;
    value = (_value = value) === null || _value === void 0 ? void 0 : _value.replace(/\s/g, '');
  }
  if (value.length > 4) {
    return (0, _.chunkString)(value, 4).join(' ');
  }
  return value;
};
exports.formatValue = formatValue;
var ibanValidInput = /^[a-zA-Z0-9\s]*$/;
exports.ibanValidInput = ibanValidInput;
var IBANBanktransferValidator = {
  validBank: function validBank() {
    var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    var IBANNo = value === null || value === void 0 ? void 0 : value.replace(/\s/g, '');
    return /^TR\d{4}((046)|(064)|(203)|(015)|(134)|(205)|(103)|(067)|(124)|(062)|(012)|(111)|(206)|(010)|(059)|(032)|(099))\d*$/.test(IBANNo);
  },
  validLength: function validLength() {
    var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    var IBANNo = value === null || value === void 0 ? void 0 : value.replace(/\s/g, '');
    return /^TR\d{24}$/.test(IBANNo);
  }
};
exports.IBANBanktransferValidator = IBANBanktransferValidator;
var IBANInternetValidator = {
  validBank: function validBank() {
    var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    var IBANNo = value === null || value === void 0 ? void 0 : value.replace(/\s/g, '');
    return /^TR\d{4}((046)|(064)|(203)|(015)|(134)|(205)|(103))\d*$/.test(IBANNo);
  },
  validLength: function validLength() {
    var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    var IBANNo = value === null || value === void 0 ? void 0 : value.replace(/\s/g, '');
    return /^TR\d{24}$/.test(IBANNo);
  }
};
exports.IBANInternetValidator = IBANInternetValidator;
var errorMessage = function errorMessage(errorName, message, getErrorText, t) {
  if (errorName === 'required') {
    return getErrorText(errorName, message);
  } else if (errorName === 'validBank') {
    return t('pipo_payin_state_cashier_error_bo_banktransfer_wrongiban');
  } else {
    return getErrorText(errorName, message);
  }
};
exports.errorMessage = errorMessage;
var onBankIbanChange = function onBankIbanChange(val, callback) {
  if (!val) {
    // IbanNumberInputRef.current = undefined;
    callback(val);
    return;
  }
  // e.target.value = formatValue(value);
  if (/^[a-zA-Z0-9\s]*$/.test(val)) {
    // pickNumberic for pure number
    var formatNumber = (0, _.pickNumericAlphabet)(val);
    callback(formatNumber);
  }
};
exports.onBankIbanChange = onBankIbanChange;
var formatCursor = function formatCursor(oldVal, newVal, cursorPosition) {
  var editionType = newVal.length > oldVal.length ? 'insert' : 'delete';
  console.log(editionType, 'editionType');
  console.log(cursorPosition, 'cursorPosition');
  if (cursorPosition !== 0 && cursorPosition % 5 === 0) {
    return cursorPosition + (editionType === 'insert' ? 1 : -1);
  } else {
    return cursorPosition;
  }
};
exports.formatCursor = formatCursor;