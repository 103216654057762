import React, { useState } from 'react'
import { FieldError } from 'react-hook-form'

import { Input } from '@arco-design/web-react'
import { omit } from 'lodash'

import { cpf } from '@/pipo/utils'

import { ItemBlock } from '../index'

interface IProps {
  error?: FieldError
  errorMessage: string
  value?: string
  title: string
  onChange?: (val: string) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void
}

export const CPF = React.forwardRef((props: IProps, ref: any) => {
  const { error, errorMessage, title, value, onChange, onBlur } = props
  const [innerValue, setInnerValue] = useState(value)

  const handleChange = (val: string) => {
    const formatValue = cpf.formatter(val)
    setInnerValue(formatValue)
    onChange && onChange(val)
  }

  return (
    <ItemBlock style="half" title={title} errorState={Boolean(error)} errorMessage={errorMessage}>
      <Input
        {...omit(props, 'errorMessage')}
        className="oo-block"
        ref={ref}
        size="large"
        allowClear
        value={innerValue}
        error={Boolean(error)}
        placeholder={cpf.placeholder}
        onChange={(val) => handleChange(val)}
        onBlur={onBlur}
        maxLength={14}
      />
    </ItemBlock>
  )
})
