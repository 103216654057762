import { omit } from 'lodash'

import { PayoutSubmitParams } from '@/pipo/pc-payout/types'
import utils from '@/utils'
import SafeJSON from '@/utils/SafeJSON'

export const formatSubmitFormDataForBindProcess = (submitData: PayoutSubmitParams, publicKey: string): string => {
  if (!submitData) return ''
  const elements: {
    param_name: string
    param_value: string
  }[] = Object.entries(submitData.formData)
    .map(([key, value]) => ({
      param_name: key,
      param_value: (value && utils.encryptString(value, publicKey)) || '',
    }))
    .filter((element) => !!element.param_value)
  const extraParams = omit(submitData, ['formData'])
  return SafeJSON.stringify({
    elements,
    ...extraParams,
  })
}
