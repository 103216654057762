"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault")["default"];
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.SUPPORT_CCDC_PAYMENT_METHOD_MAP = exports.SUPPORT_CCDC_PAYMENT_METHODS = exports.CCDC_PAYMENT_PARAMS_MAP = exports.BANK_CARD_REGEX_LIST = void 0;
var _reduce = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/reduce"));
var CCDC_PAYMENT_PARAMS_MAP = {
  card_number: 'eg_ccdc_global_card_number',
  expiration_month: 'eg_ccdc_global_expiration_month',
  expiration_year: 'eg_ccdc_global_expiration_year',
  cvv: 'eg_ccdc_global_cvv',
  holder_name: 'eg_ccdc_global_holder_name',
  phone: 'eg_ccdc_global_phone',
  phone_country_code: 'eg_ccdc_global_phone_country_code',
  email: 'eg_ccdc_global_email',
  proxy_type: 'eg_ccdc_global_proxy_type',
  identity: 'eg_ccdc_global_identity',
  company_id: 'eg_ccdc_global_company_id',
  billing_country_region: 'eg_ccdc_global_billing_address_country_regin',
  billing_state: 'eg_ccdc_global_billing_address_state',
  billing_city: 'eg_ccdc_global_billing_address_city',
  billing_street: 'eg_ccdc_global_billing_address_street',
  billing_postal_code: 'eg_ccdc_global_billing_address_postal_code'
};
/**
 * CCDC PaymentMethodType
 */
exports.CCDC_PAYMENT_PARAMS_MAP = CCDC_PAYMENT_PARAMS_MAP;
var CCDC_PAYMENT_METHOD_TYPE = {
  configuration: {},
  icon: {
    iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/checkout_card/svg/cardLogoPlaceHolderLine.svg'
  },
  paymentMethodType: 'CCDC',
  starling_key: 'CCDC',
  title: {
    payment: 'pipo_payin_checkout_pay_with_a_new_card',
    piListShort: 'pipo_common_payin_cc_dc',
    piListLong: 'pipo_common_payin_add_cc_dc'
  }
};
var generateCCDCPaymentMethod = function generateCCDCPaymentMethod(paymentMethod) {
  return {
    paymentMethodType: CCDC_PAYMENT_METHOD_TYPE,
    paymentMethod: paymentMethod
  };
};
/**
 * VISA PaymentMethod
 */
var VISA_PAYMENT_METHOD = generateCCDCPaymentMethod({
  configuration: {
    card_brand_length: [13, 16, 19],
    cvv_length: [3]
  },
  icon: {
    iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/card_visa_v1_0fffde.svg',
    pngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/visa_light_3_3f17dc.png',
    darkModePngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/visa_dark_3_3f17dc.png',
    detailIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/ccdc-visa-detail_7a4c93.svg'
  },
  displayName: 'Visa',
  paymentMethod: 'VISA',
  paymentMethodId: 'pm_pi_ccdc_visa_c_d',
  starling_key: ''
});
/**
 * MASTER PaymentMethod
 */
var MASTER_PAYMENT_METHOD = generateCCDCPaymentMethod({
  configuration: {
    card_brand_length: [16],
    cvv_length: [3]
  },
  icon: {
    iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/card_master_card_v1_6992ea.svg',
    pngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/mastercard_light_3_a935dd.png',
    darkModePngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/mastercard_dark_3_2889e1.png',
    detailIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/ccdc-mastercard-detail_d23550.svg'
  },
  displayName: 'Mastercard',
  paymentMethod: 'MASTER',
  paymentMethodId: 'pm_pi_ccdc_mastercard_c_d',
  starling_key: ''
});
/**
 * AMEX PaymentMethod
 */
var AMEX_PAYMENT_METHOD = generateCCDCPaymentMethod({
  configuration: {
    card_brand_length: [15],
    cvv_length: [4]
  },
  icon: {
    iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/card_american_express_v1_429e0f.svg',
    detailIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/ccdc-amex-detail_500aa3.svg'
  },
  displayName: 'American Express',
  paymentMethod: 'AMEX',
  paymentMethodId: 'pm_pi_ccdc_amex_c_d',
  starling_key: ''
});
/**
 * DISCOVER PaymentMethod
 */
var DISCOVER_PAYMENT_METHOD = generateCCDCPaymentMethod({
  configuration: {
    card_brand_length: [16, 17, 18, 19],
    cvv_length: [3]
  },
  icon: {
    iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/discover_5ec158.svg',
    detailIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/ccdc-discover-detail_43c7ea.svg'
  },
  displayName: 'Discover',
  paymentMethod: 'DISCOVER',
  paymentMethodId: 'pm_pi_ccdc_discover_c_d',
  starling_key: ''
});
/**
 * HIPER PaymentMethod
 */
var HIPER_PAYMENT_METHOD = generateCCDCPaymentMethod({
  configuration: {
    card_brand_length: [16],
    cvv_length: [3]
  },
  icon: {
    iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/Icon_Cards_Hiper_a3f1f9.svg',
    detailIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/ccdc-hiper-detail-update_38fb42.svg'
  },
  displayName: 'HIPER',
  paymentMethod: 'HIPER',
  paymentMethodId: 'pm_pi_ccdc_hipercard_c_d',
  starling_key: ''
});
/**
 * AURA PaymentMethod
 */
var AURA_PAYMENT_METHOD = generateCCDCPaymentMethod({
  icon: {
    iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/Group%20123282_046242.png',
    detailIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/ccdc-aura-detail_6f69a9.svg'
  },
  displayName: 'AURA',
  paymentMethod: 'AURA',
  paymentMethodId: 'pm_pi_ccdc_aura_c_d',
  starling_key: ''
});
/**
 * ELO PaymentMethod
 */
var ELO_PAYMENT_METHOD = generateCCDCPaymentMethod({
  configuration: {
    card_brand_length: [16],
    cvv_length: [3]
  },
  icon: {
    iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/Icon_Cards_ELO_97c69d.png',
    detailIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/ccdc-elo-detail_b89097.svg'
  },
  displayName: 'ELO',
  paymentMethod: 'ELO',
  paymentMethodId: 'pm_pi_ccdc_elo_c_d',
  starling_key: ''
});
/**
 * DINERS PaymentMethod
 */
var DINERS_PAYMENT_METHOD = generateCCDCPaymentMethod({
  configuration: {
    card_brand_length: [13, 14, 15, 16, 17, 18, 19],
    cvv_length: [3, 4]
  },
  icon: {
    iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/diners_a3de24.svg',
    pngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/Diners_club_light_cf04f7.png',
    darkModePngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/Diner_club_dark_a263a0.png',
    detailIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/ccdc-diners-detail_004277.svg'
  },
  displayName: 'DINERS',
  paymentMethod: 'DINERS',
  paymentMethodId: 'pm_pi_ccdc_diners_c_d',
  starling_key: ''
});
/**
 * JCB PaymentMethod
 */
var JCB_PAYMENT_METHOD = generateCCDCPaymentMethod({
  configuration: {
    card_brand_length: [16, 17, 18, 19],
    cvv_length: [3]
  },
  icon: {
    iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/card_jcb_v1_be2294.svg',
    pngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/jcb_light_3_067369.png',
    darkModePngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/jcb_dark_3_067369.png',
    detailIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/ccdc-jcb-detail_19ef43.svg'
  },
  displayName: 'JCB',
  paymentMethod: 'JCB',
  paymentMethodId: 'pm_pi_ccdc_jcb_c_d',
  starling_key: ''
});
/**
 * MAESTRO PaymentMethod
 */
var MAESTRO_PAYMENT_METHOD = generateCCDCPaymentMethod({
  configuration: {
    card_brand_length: [13, 16, 17, 18, 19],
    cvv_length: [3, 4]
  },
  icon: {
    iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/maestro_188b29.svg',
    detailIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/ccdc-maestro-detail_09ea72.svg'
  },
  displayName: 'Maestro',
  paymentMethod: 'MAESTRO',
  paymentMethodId: 'pm_pi_ccdc_maestro_c_d',
  starling_key: ''
});
/**
 * RUPAY PaymentMethod
 */
var RUPAY_PAYMENT_METHOD = generateCCDCPaymentMethod({
  configuration: {
    card_brand_length: [16],
    cvv_length: [3]
  },
  icon: {
    iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/Icon_Cards_Rupay_3fedf4.svg',
    detailIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/ccdc-rupay-detail_92932b.svg'
  },
  displayName: 'RuPay',
  paymentMethod: 'RUPAY',
  paymentMethodId: 'pm_pi_ccdc_rupay_c_d',
  starling_key: ''
});
/**
 * 支持的卡组
 */
var SUPPORT_CCDC_PAYMENT_METHODS = [VISA_PAYMENT_METHOD, MASTER_PAYMENT_METHOD, AMEX_PAYMENT_METHOD, DISCOVER_PAYMENT_METHOD, HIPER_PAYMENT_METHOD, AURA_PAYMENT_METHOD, ELO_PAYMENT_METHOD, DINERS_PAYMENT_METHOD, JCB_PAYMENT_METHOD, MAESTRO_PAYMENT_METHOD, RUPAY_PAYMENT_METHOD];
exports.SUPPORT_CCDC_PAYMENT_METHODS = SUPPORT_CCDC_PAYMENT_METHODS;
var SUPPORT_CCDC_PAYMENT_METHOD_MAP = (0, _reduce["default"])(SUPPORT_CCDC_PAYMENT_METHODS).call(SUPPORT_CCDC_PAYMENT_METHODS, function (map, item) {
  map[item.paymentMethod.paymentMethod] = item;
  return map;
}, {});
exports.SUPPORT_CCDC_PAYMENT_METHOD_MAP = SUPPORT_CCDC_PAYMENT_METHOD_MAP;
var BANK_CARD_REGEX_LIST = [{
  paymentMethod: 'HIPER',
  cardBinRegex: '^(637095|637568|637599|637609|637612|606282)[0-9]{0,10}|38[0-9]{14}$'
}, {
  paymentMethod: 'ELO',
  cardBinRegex: '^((((50900[0-9])|(50901[3-9])|(50902[0-9])|(50903[1-9])|(50904[0-9])|(50905[0-9])|(50906[0-4])|(50906[6-9])|(50907[0-2])|(50907[4-5])|(50908[0-9])|(506699)|(506770)|(506771)|(506772)|(506773)|(506774)|(506775)|(506776)|(506777)|(506778)|(401178)|(438935)|(451416)|(457631)|(457632)|(504175)|(627780)|(636368)|(636297))[0-9]{0,10})|((50676)|(50675)|(50674)|(50673)|(50672)|(50671)|(50670))[0-9]{0,11})$'
}, {
  paymentMethod: 'DINERS',
  cardBinRegex: '^(36|68)[0-9]{0,12}$'
}, {
  paymentMethod: 'AMEX',
  cardBinRegex: '^3[47][0-9]{0,13}$'
}, {
  paymentMethod: 'DISCOVER',
  cardBinRegex: '^(6011[0-9]{0,12}|(644|645|646|647|648|649)[0-9]{0,13}|65[0-9]{0,14})$'
}, {
  paymentMethod: 'JCB',
  cardBinRegex: '^(352[8,9]{1}[0-9]{0,15}|35[4-8]{1}[0-9]{0,16})$'
}, {
  paymentMethod: 'MASTER',
  cardBinRegex: '^(5[1-5][0-9]{0,14}|2[2-7][0-9]{0,14})$'
}, {
  paymentMethod: 'VISA',
  cardBinRegex: '^4[0-9]{12,15}$'
}, {
  paymentMethod: 'MAESTRO',
  cardBinRegex: '^(5[0|6-8][0-9]{0,17}|6[0-9]{0,18})$'
}, {
  paymentMethod: 'MIR',
  cardBinRegex: '^(220)[0-4][0-9]{9,12}$'
}, {
  paymentMethod: 'MASTERCARD',
  cardBinRegex: '^(5[1-5][0-9]{11,14}|222[1-9]{10,13}|22[3-6][0-9]{10,13}|27[0-1][0-9]{10,13}|2720[0-9]{9,12})$'
}, {
  paymentMethod: 'CARTESBANCAIRES',
  cardBinRegex: '^[4-6][0-9]{0,15}$'
}, {
  paymentMethod: 'MADA',
  cardBinRegex: '^(4(0(0861|1757|7(197|395)|9201)|1(0685|7633|9593)|2(281(7|8|9)|8(331|67(1|2|3)))|3(1361|2328|4107|9954)|4(0(533|647|795)|5564|6(393|404|672))|5(5(036|708)|7865|8456)|6(2220|854(0|1|2|3))|8(301(0|1|2)|4783|609(4|5|6)|931(7|8|9))|93428)|5(0(4300|8160)|13213|2(1076|4(130|514)|9(415|741))|3(0906|1095|2013|5(825|989)|6023|7767|9931)|4(3(085|357)|9760)|5(4180|7606|8848)|8(5265|8(8(4(5|6|7|8|9)|5(0|1))|98(2|3))|9(005|206)))|6(0(4906|5141)|36120)|9682(0(1|2|3|4|5|6|7|8|9)|1(0|1)))\\d{10}$'
}, {
  paymentMethod: 'CARNET',
  cardBinRegex: '^(286900|502275|506(199|2(0[1-6]|1[2-578]|2[289]|3[67]|4[579]|5[01345789]|6[1-79]|7[02-9]|8[0-7]|9[234679])|3(0[0-9]|1[1-479]|2[0239]|3[02-79]|4[0-49]|5[0-79]|6[014-79]|7[0-4679]|8[023467]|9[1234689])|4(0[0-8]|1[0-7]|2[0-46789]|3[0-9]|4[0-69]|5[0-79]|6[0-38]))|588772|604622|606333|627535|636(318|379)|639(388|484|559))$'
}, {
  paymentMethod: 'UNIONPAY',
  cardBinRegex: '^(62[0-9]{14,17})$'
}, {
  paymentMethod: 'AURA',
  cardBinRegex: '^(5078d{2})(d{2})(d{11})$'
}, {
  paymentMethod: 'RUPAY',
  cardBinRegex: '^(100003|508(2|[5-9])|60(69|[7-8])|652(1[5-9]|[2-5][0-9]|8[5-9])|65300[3-4]|8172([0-1]|[3-5]|7|9)|817(3[3-8]|40[6-9]|410)|35380([0-2]|[5-6]|9))[0-9]{0,12}$'
}];
exports.BANK_CARD_REGEX_LIST = BANK_CARD_REGEX_LIST;