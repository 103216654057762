import React, { useCallback, useRef, useState } from 'react'

import { PAY_WAY_TYPE } from '@/constant'
import { IPayinMethodItem, PaymentSubmitMethodEnum } from '@/pages/PayNext/interface'

import {
  IPaymentMethodStatus,
  IPaymentMethodStatusValue,
  IPaymentMethodsProps,
  IPaymentMethodsPropsBasic,
  IPaymentsMethodRef,
  PaymentsMethodComponentType,
} from '../interface'
import BankTransferBo from './BankTransferBo'
import BankTransferJP from './BankTransferJP'
import BankTransferMX from './BankTransferMX'
import BankTransferUS from './BankTransferUS'
import Blik from './Blik'
import BoletoForm from './Boleto'
import CCDCForm from './CCDC'
import DecisionSubmitMethod from './DecisionSubmitMethod'
import DirectDebit from './DirectDebit'
import DirectDebitCA from './DirectDebitCA'
import DirectDebitEU from './DirectDebitEU'
import DirectDebitUK from './DirectDebitUK'
import Gopay from './Gopay'
import NeedBindElements from './NeedBindElements'
import OVO from './OVO'
import OXXO from './OXXO'
import PayAndSaveCard from './PayAndSaveCard'
import PayU from './PayU'
import Paycell from './Paycell'
import PixForm from './Pix'
import QIWI from './QIWI'
import ShopeePay from './ShopeePay'
import Toss from './Toss'
import Venmo from './Venmo'

const useNewPaymentsMethod = () => {
  // 支付方式验证情况
  const [newPaymentStatus, setNewPaymentStatus] = useState<IPaymentMethodStatus>({})

  // payWay - ref MAP
  const paymentsRefMap = useRef(new Map<number, IPaymentsMethodRef | null>([]))

  // 根据payWay映射表单信息
  const paymentsPayWay2ComponentMap = useRef(
    new Map<number, PaymentsMethodComponentType>([
      [PAY_WAY_TYPE.CREATE_NEW_CARD, CCDCForm],
      [PAY_WAY_TYPE.BOLETO, BoletoForm],
      [PAY_WAY_TYPE.PIX, PixForm],
      [PAY_WAY_TYPE.DIRECT_DEBIT, DirectDebit],
      [PAY_WAY_TYPE.DIRECT_DEBIT_UK, DirectDebitUK],
      [PAY_WAY_TYPE.DIRECT_DEBIT_EU, DirectDebitEU],
      [PAY_WAY_TYPE.DIRECT_DEBIT_CA, DirectDebitCA],
      [PAY_WAY_TYPE.OVO, OVO],
      [PAY_WAY_TYPE.QIWI, QIWI],
      [PAY_WAY_TYPE.SHOPEE_PAY, ShopeePay],
      [PAY_WAY_TYPE.BANK_TRANSFER_BO, BankTransferBo],
      [PAY_WAY_TYPE.PAYCELL, Paycell],
      [PAY_WAY_TYPE.TOSS, Toss],
      [PAY_WAY_TYPE.BANK_TRANSFER_JP, BankTransferJP],
      [PAY_WAY_TYPE.VENMO, Venmo],
      [PAY_WAY_TYPE.BANK_TRANSFER_US, BankTransferUS],
      [PAY_WAY_TYPE.OXXO, OXXO],
      [PAY_WAY_TYPE.PAY_U, PayU],
      [PAY_WAY_TYPE.BANK_TRANSFER_MX, BankTransferMX],
      [PAY_WAY_TYPE.BLIK, Blik],
      [PAY_WAY_TYPE.GOPAY_V2, Gopay],
    ]),
  )

  const getPaymentExtraComponent = (channel: IPaymentMethodsProps['channel']) => {
    // 优先根据PayWay进行映射
    const ComponentFromPayWay = paymentsPayWay2ComponentMap.current.get(channel.payWay)
    if (ComponentFromPayWay) return ComponentFromPayWay

    // 根据具体规则路由
    if ((channel as IPayinMethodItem).paymentSubmitMethod === PaymentSubmitMethodEnum.DEPENDS) {
      return DecisionSubmitMethod
    }
    //需要根据 checkbox 判断是走支付还是支付并绑卡的支付方式
    if ([PAY_WAY_TYPE.MOMO].includes(channel.payWay) && (channel as IPayinMethodItem).supportPaymentAndBind) {
      return PayAndSaveCard
    }
    if (channel.needBindElements) {
      return NeedBindElements
    }

    return null
  }

  /** 根据payWay获取支付渠道的ref */
  const getPaymentRefByPayWay = useCallback((payWay?: number) => {
    return typeof payWay === 'number' ? paymentsRefMap.current.get(payWay) || undefined : undefined
  }, [])

  const changeNewPaymentStatus = useCallback(
    (payWay: number, value: IPaymentMethodStatusValue | ((oldValue: IPaymentMethodStatusValue) => IPaymentMethodStatusValue)) => {
      if (typeof value === 'function') {
        setNewPaymentStatus((preStatus) => {
          const preValue = preStatus[`${payWay}`] || {}
          return { ...preStatus, [`${payWay}`]: value(preValue) }
        })
      } else {
        setNewPaymentStatus((preStatus) => ({ ...preStatus, [`${payWay}`]: value }))
      }
    },
    [],
  )

  const clearNewPaymentStatus = useCallback(() => {
    setNewPaymentStatus({})
  }, [])

  const renderPaymentsExtra = useCallback(
    (channel: IPaymentMethodsProps['channel'], paymentPropsBasic: IPaymentMethodsPropsBasic): React.ReactNode => {
      const coreProps: IPaymentMethodsProps = {
        channel,
        paymentSubmitMethod: (channel as IPayinMethodItem).paymentSubmitMethod,
        changeNewPaymentStatus,
        ...paymentPropsBasic,
      }
      const PaymentComponent = getPaymentExtraComponent(channel)

      if (PaymentComponent) {
        return (
          <PaymentComponent
            ref={(ref) => {
              paymentsRefMap.current.set(channel.payWay, ref)
            }}
            {...coreProps}
          />
        )
      }

      return null
    },
    [changeNewPaymentStatus],
  )

  return { renderPaymentsExtra, getPaymentRefByPayWay, valid: { newPaymentStatus, clearNewPaymentStatus } }
}

export default useNewPaymentsMethod
