export function getLocalBankRules(code: string) {
  let accountNoRules, bankCodeRules, agencyRules, ibanRules

  switch (code) {
    // UK
    case 'GB': {
      accountNoRules = '^[0-9]{6,8}$'
      agencyRules = '^[0-9]{6}$'
      ibanRules = '^GB[0-9]{2}[0-9A-Z]{18}$'
      break
    }
    // Andorra
    case 'AD': {
      ibanRules = '^AD[0-9]{2}[0-9A-Z]{20}$'
      break
    }
    // Austria
    case 'AT': {
      accountNoRules = '^[0-9]{4,11}$'
      bankCodeRules = '^[0-9]{5}$/'
      ibanRules = '^AT[0-9]{2}[0-9A-Z]{16}$'
      break
    }
    // Belgium
    case 'BE': {
      accountNoRules = '^[0-9]{12}$'
      ibanRules = '^BE[0-9]{2}[0-9A-Z]{12}$'
      break
    }
    // Bulgaria
    case 'BG': {
      ibanRules = '^BG[0-9]{2}[0-9A-Z]{18}$'
      break
    }
    // Croatia
    case 'HR': {
      ibanRules = '^HR[0-9]{2}[0-9A-Z]{17}$'
      break
    }
    // Cyprus
    case 'CY': {
      accountNoRules = '^[0-9]{7,16}$'
      bankCodeRules = '^[0-9]{3}$'
      agencyRules = '^[0-9]{5}$'
      ibanRules = '^CY[0-9]{2}[0-9A-Z]{24}$'
      break
    }
    // Czech Republic
    case 'CZ': {
      ibanRules = '^CZ[0-9]{2}[0-9A-Z]{20}$'
      break
    }
    // Denmark
    case 'DK': {
      ibanRules = '^DK[0-9]{2}[0-9A-Z]{14}$'
      break
    }
    // Estonia
    case 'EE': {
      accountNoRules = '^[0-9]{1,14}$'
      ibanRules = '^EE[0-9]{2}[0-9A-Z]{16}$'
      break
    }
    // Finland
    case 'FI': {
      ibanRules = '^FI[0-9]{2}[0-9A-Z]{14}$'
      break
    }
    // France
    case 'FR': {
      accountNoRules = '^[0-9a-zA-Z]{1,11}[0-9]{2}$'
      bankCodeRules = '^[0-9]{5}$'
      agencyRules = '^[0-9]{5}$'
      ibanRules = '^FR[0-9]{2}[0-9A-Z]{23}$'
      break
    }
    // Germany
    case 'DE': {
      accountNoRules = '^[0-9]{1,10}$'
      bankCodeRules = '^[0-9]{8}$'
      ibanRules = '^DE[0-9]{2}[0-9A-Z]{18}$'
      break
    }
    // Greece
    case 'GR': {
      ibanRules = '^GR[0-9]{2}[0-9A-Z]{23}$'
      break
    }
    // Hungary
    case 'HU': {
      ibanRules = '^HU[0-9]{2}[0-9A-Z]{24}$'
      break
    }
    // Ireland
    case 'IE': {
      accountNoRules = '^[0-9]{6,8}$'
      agencyRules = '^[0-9]{6}$'
      ibanRules = '^IE[0-9]{2}[0-9A-Z]{18}$'
      break
    }
    // Italy
    case 'IT': {
      accountNoRules = '^[0-9]{1,12}$'
      bankCodeRules = '^[0-9]{5}$'
      agencyRules = '^[0-9]{5}$'
      ibanRules = '^IT[0-9]{2}[0-9A-Z]{23}$'
      break
    }
    // Latvia
    case 'LV': {
      accountNoRules = '^[0-9]{13}$'
      bankCodeRules = '^[a-zA-Z]{4}$'
      ibanRules = '^LV[0-9]{2}[0-9A-Z]{17}$'
      break
    }
    // Lithuania
    case 'LT': {
      ibanRules = '^LT[0-9]{2}[0-9A-Z]{16}$'
      break
    }
    // Luxembourg
    case 'LU': {
      accountNoRules = '^[0-9]{13}$'
      bankCodeRules = '^[0-9]{3}$'
      ibanRules = '^LU[0-9]{2}[0-9A-Z]{16}$'
      break
    }
    // Malta
    case 'MT': {
      accountNoRules = '^[0-9]{1,18}$'
      agencyRules = '^[0-9]{5}$'
      ibanRules = '^MT[0-9]{2}[0-9A-Z]{27}$'
      break
    }
    // Monaco
    case 'MC': {
      accountNoRules = '^[0-9a-zA-Z]{1,11}[0-9]{2}$'
      bankCodeRules = '^[0-9]{5}$'
      agencyRules = '^[0-9]{5}$'
      break
    }
    // Netherlands
    case 'NL': {
      accountNoRules = '^[0-9]{1,10}$'
      bankCodeRules = '^[a-zA-Z]{5}$'
      ibanRules = '^NL[0-9]{2}[0-9A-Z]{14}$'
      break
    }
    // Norway
    case 'NO': {
      ibanRules = '^NO[0-9]{2}[0-9A-Z]{11}$'
      break
    }
    // Poland
    case 'PL': {
      ibanRules = '^PL[0-9]{2}[0-9A-Z]{24}$'
      break
    }
    // Portugal
    case 'PT': {
      accountNoRules = '^[0-9]{1,11}[0-9]{2}$'
      bankCodeRules = '^[0-9]{4}$'
      agencyRules = '^[0-9]{4}$'
      ibanRules = '^PT[0-9]{2}[0-9A-Z]{21}$'
      break
    }
    // Romania
    case 'RO': {
      ibanRules = '^RO[0-9]{2}[0-9A-Z]{20}$'
      break
    }
    // Slovakia
    case 'SK': {
      ibanRules = '^SK[0-9]{2}[0-9A-Z]{20}$'
      break
    }
    // San Marino
    case 'SM': {
      accountNoRules = '^[0-9]{1,12}$'
      bankCodeRules = '^[0-9]{5}$'
      agencyRules = '^[0-9]{5}$'
      break
    }
    // Slovenia
    case 'SI': {
      accountNoRules = '^[0-9]{1,10}$'
      bankCodeRules = '^[0-9]{5}$'
      ibanRules = '^SI[0-9]{2}[0-9A-Z]{15}$'
      break
    }
    // Spain
    case 'ES': {
      accountNoRules = '^[0-9]{12}$'
      bankCodeRules = '^[0-9]{4}$'
      agencyRules = '^[0-9]{4}$'
      ibanRules = '^ES[0-9]{2}[0-9A-Z]{20}$'
      break
    }
    // Sweden
    case 'SE': {
      ibanRules = '^SE[0-9]{2}[0-9A-Z]{20}$'
      break
    }
    // Switzerland
    case 'CH': {
      ibanRules = '^CH[0-9]{2}[0-9A-Z]{17}$'
      break
    }
    default: {
      break
    }
  }

  return {
    accountNoRules: { value: accountNoRules ? new RegExp(accountNoRules) : undefined },
    bankCodeRules: { value: bankCodeRules ? new RegExp(bankCodeRules) : undefined },
    agencyRules: { value: agencyRules ? new RegExp(agencyRules) : undefined },
    ibanRules: { value: ibanRules ? new RegExp(ibanRules) : undefined },
  }
}

export const postalCodeRegex = /^.{5,10}$/

export const bicRegex = /^.{8,11}$/
