import { PaymentMethodItem, PipoSubmitParams } from '../../types'

/**
 *  加载 OXXO 组件入参
 */
export interface OxxoProps {
  showSavingBox?: boolean
  onValidate?: (isValid: boolean) => void
  onSubmit?: (params: PipoSubmitParams) => Promise<void>
}

/**
 * OXXO 需要填写表单的位数
 */

export type OxxoFormData = {
  holder_name?: string
  email?: string
  phone?: string
  phone_country_code?: string
}

export const OXXO_PAYMENT_PARAMS_MAP: Record<string, string> = {
  holder_name: 'eg_pi_cp_oxxo_c_d_local_mx_full_name',
  email: 'eg_pi_cp_oxxo_c_d_local_mx_email',
  phone: 'eg_pi_cp_oxxo_c_d_local_mx_mobile_phone',
  phone_country_code: 'eg_pi_cp_oxxo_c_d_local_mx_phone_country_code',
  identity: 'eg_pi_cp_oxxo_c_d_local_mx_identity',
}

export const OXXO_PAYMENT_METHOD: PaymentMethodItem = {
  paymentMethodType: {
    configuration: {},
    icon: {
      iconUrl: '',
    },
    starling_key: 'CASH_PIN',
    paymentMethodType: 'CASH_PIN',
  },
  paymentMethod: {
    configuration: {},
    paymentMethod: 'OXXO',
    paymentMethodId: 'pm_pi_cp_oxxo_c_d',
    displayName: 'OXXO',
    starling_key: 'pipo_payin_cashier_dropin_payment_OXXO',
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/OXXO_icon_b6bb6f.svg',
      voucherIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/OXXO_voucher_189a61.png',
      pngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/OXXO_icon_b6bb6f.svg',
      darkModePngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/OXXO_icon_b6bb6f.svg',
      detailIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/OXXO_detail_38a01b.svg',
    },
    title: {
      payment: 'pipo_payin_cashier_dropin_payment_OXXO',
    },
  },
}
