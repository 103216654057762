import { IBindCardRes, ISubmitOrderRes } from '@upay/utils/es/types'

import { IOpenCheckOTPModalProps } from '@/components/CheckOTPModal/types'
import { IOpenStatusProps } from '@/components/Status/config'
import { FROM_PAGE_ENUM } from '@/constant'
import { IPayinMethodItem, Order, PaymentSubmitMethodEnum } from '@/pages/PayNext/interface'
import { IPaymentCodeQuery } from '@/pages/PaymentCode/interface'
import { ChannelRes, IBindCardSupplementParams, ISubmitOrderParams } from '@/services/types'
import { IUrlQuery } from '@/typings'

export interface IPaymentMethodsPropsBasic {
  /**
   * 当前支付方式提交类型，在这一层抹平传参
   * pay / create / change 页面由外部指定
   * payNext 由支付方式本身决定 无需传入
   */
  paymentSubmitMethod?: PaymentSubmitMethodEnum
  urlQuery: IUrlQuery
  fromPage: FROM_PAGE_ENUM
  /**
   * 打开状态页
   */
  handleBillStatus: (props: IOpenStatusProps) => Promise<void> | void
  /**
   * 打开新窗口
   */
  linkOpen: (url: string, currentPage?: string | undefined) => Promise<Window>
  /**
   * 获取订单信息
   */
  getOrder?: () => Order | null | undefined

  /**
   * 打开PaymentCode
   */
  openPaymentCode?: ({ payWay, query }: { payWay?: number | undefined; query?: IPaymentCodeQuery | undefined }) => void
  /**
   * 打开验证码弹窗
   */
  openCheckOTPModal?: (props: IOpenCheckOTPModalProps) => void
  /** 主动关闭支付结果的全屏loading，回到列表页 */
  closeLoading?: () => void
}

export type IPaymentMethodsProps = IPaymentMethodsPropsBasic & {
  channel: ChannelRes | IPayinMethodItem
  /**
   * 改变父页面中此支付方式的验证状态
   */
  changeNewPaymentStatus: (
    payWay: number,
    value: IPaymentMethodStatusValue | ((oldValue: IPaymentMethodStatusValue) => IPaymentMethodStatusValue),
  ) => void
}

export type IExcitedPaymentMethodsProps = IPaymentMethodsPropsBasic & {
  card: IPayinMethodItem
  /**
   * 改变父页面中此已绑卡支付方式的验证状态
   */
  changeExcitedPaymentStatus: (
    cardId: string,
    value: IPaymentMethodStatusValue | ((oldValue: IPaymentMethodStatusValue) => IPaymentMethodStatusValue),
  ) => void
}

/**
 * 支付方式子组件需要向父组件暴露的ref
 */
export interface IPaymentsMethodRef {
  /**
   * 支付方式是否需要处理提交
   * 可在此方法内根据 预付/后付/绑卡方式等 进行判断
   * 若方法存在且返回true 则，后续提交操作由支付方式接管 如 CCDC
   */
  needHandleSubmit?: () => boolean
  /**
   * 支付方式的后续提交操作
   * 如 needHandleSubmit 有可能返回true，则此方法应始终存在
   */
  handleSubmit?: () => void
  /**
   * 支付方式可提供此方法对提交的参数进行处理
   * 如 needProcessSubmitData 有可能返回true，则此方法应始终存在
   */
  processSubmitData?: (submitParams: any) => Promise<any>
  /**
   * 支付方式是否需要处理submitOrder后续操作
   * 可在此方法内根据 预付/后付/绑卡方式等 进行判断
   * 若方法存在且返回true 则，后续处理操作由支付方式接管 如 一步支付CCDC二期
   */
  needHandleResult?: (res: ISubmitOrderRes | IBindCardRes | undefined, submitParams: ISubmitOrderParams | IBindCardSupplementParams) => boolean
  /**
   * 支付方式的后续提交操作
   * 如 needHandleResult 有可能返回true，则此方法应始终存在
   */
  handleResult?: (res: ISubmitOrderRes | IBindCardRes | undefined, submitParams: ISubmitOrderParams | IBindCardSupplementParams) => void
}

export type PaymentsMethodComponentType = React.ForwardRefExoticComponent<
  IPaymentMethodsPropsBasic & IPaymentMethodsProps & React.RefAttributes<IPaymentsMethodRef>
>

export type ExcitedPaymentsMethodComponentType = React.ForwardRefExoticComponent<
  IPaymentMethodsPropsBasic & IExcitedPaymentMethodsProps & React.RefAttributes<IPaymentsMethodRef>
>

/**
 * 此标志符仅前端使用
 * 标记已绑卡支付方式需额外显示的组件内容 方便路由
 */
export enum EXCITED_PAYMENT_EXTRA_TYPE {
  CVV_FORM = 1,
  OTP_CODE,
  VENMO,
}

export interface IPaymentMethodStatusValue {
  valid: boolean
  paymentSubmitMethod?: PaymentSubmitMethodEnum.PAYMENT | PaymentSubmitMethodEnum.BIND
}

export interface IPaymentMethodStatus {
  // 新支付方式 String(payWay) 已有卡 cardId
  [key: string]: IPaymentMethodStatusValue
}
