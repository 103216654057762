import { useCallback, useRef } from 'react'

import { v4 as uuidv4 } from 'uuid'

import { SUBMIT_RECORD_TYPE } from '@/constant'
import slardar from '@/utils/slardar'

import { SubmitRecord } from './usePostRecord'

interface IProps {
  submitRecord: SubmitRecord
  upaySessionId: string
}
// 用户绑卡行为跟踪
const useBindTrack = ({ submitRecord, upaySessionId }: IProps) => {
  // 如果后端没返回打一个点 帮助排错 没告警后删除
  if (!upaySessionId) {
    slardar.timer('emptyUpaySessionId')
  }
  const sessionId = useRef(upaySessionId || uuidv4())
  const channelFingerprint = useRef(uuidv4())
  // 在一些场景，如卡管理里面的绑卡模块，主动刷新 UpaySessionId
  const refreshSession = () => {
    sessionId.current = uuidv4()
  }
  // 每一次选择一次支付渠道都是一次唯一的 ID,提交绑卡的时候使用选择对应渠道的 FP
  const generateChannelId = useCallback(() => {
    const uid = uuidv4()
    channelFingerprint.current = uid
    return uid
  }, [])
  // 绑卡选择追踪
  const trackSelectChannel = useCallback(
    (payWay: number) => {
      try {
        submitRecord?.({
          upaySessionId: sessionId.current,
          actionId: generateChannelId(),
          actionType: SUBMIT_RECORD_TYPE.BIND_CHANNEL_SELECT,
          payWay,
        })
      } catch (e) {
        console.error('绑卡打点失败', e)
      }
    },
    [generateChannelId, submitRecord],
  )
  // 绑卡渠道浏览追踪
  const trackViewChannel = useCallback(
    (actionType: number) => {
      try {
        submitRecord?.({
          upaySessionId: sessionId.current,
          // 这里的 Action ID 可以随便用
          actionId: uuidv4(),
          actionType,
        })
      } catch (e) {
        console.error('绑卡打点失败', e)
      }
    },
    [submitRecord],
  )

  return { trackSelectChannel, trackViewChannel, sessionId, channelFingerprint, refreshSession }
}

export default useBindTrack
