import React from 'react'

import { Divider } from '@arco-design/web-react'

import { PipoProvider } from '@/pipo/utils'
import starling from '@/utils/starling'

interface IProps {
  mandateInfo: any[]
}

export const ProtocolDirectDebitEU: React.FC<IProps> = (props) => {
  const { mandateInfo } = props
  const { t } = PipoProvider.useContext()

  return (
    <React.Fragment>
      <Divider style={{ margin: '24px 0' }} />
      <div className="payment-pc-protocol">
        <div className="payment-pc-protocol-title">{starling('funds.checkout.comm.system_whitelisting')}</div>
        {mandateInfo
          ?.filter((item) => ['creditor_id', 'creditor_name'].includes(item.mandate_element_param_name))
          .map((item) => {
            return (
              <p key={item.mandate_element_param_name} className="payment-pc-protocol-content">
                {`${item.attribute_object?.display_name || item.mandate_element_param_name}: ${item.attribute_object?.valid_value || ''}`}
              </p>
            )
          })}
      </div>
    </React.Fragment>
  )
}
