import React from 'react'
import { Control } from 'react-hook-form'

import { PayoutComponentRef } from '@/pipo/pc-payout/types'

import { useCardHolderInfo } from '../hooks/index'

export const PayoutContext = React.createContext(
  {} as {
    control: Control
    outerFormRef: React.MutableRefObject<Map<string, React.MutableRefObject<PayoutComponentRef | null>>>
    cardHolderInfo: ReturnType<typeof useCardHolderInfo>
  },
)
