import React from 'react'
import { FieldError } from 'react-hook-form'

import { Input } from '@arco-design/web-react'
import { omit } from 'lodash'

import { ItemBlock } from '../index'

interface IProps {
  title: string
  maxLength?: number
  placeholder?: string
  len?: 'half' | 'full'
  error?: FieldError
  errorMessage: string
  value?: string
  onChange: (val: string) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void
}

export const Street = React.forwardRef((props: IProps, ref: any) => {
  const { title, maxLength, placeholder, len = 'half', error, errorMessage, value, onChange, onBlur } = props

  return (
    <ItemBlock style={len} title={title} errorState={Boolean(error)} errorMessage={errorMessage}>
      <Input
        {...omit(props, ['style', 'errorMessage'])}
        ref={ref}
        size="large"
        allowClear
        value={value}
        error={Boolean(error)}
        placeholder={placeholder}
        maxLength={maxLength}
        onChange={(val) => onChange(val)}
        onBlur={onBlur}
      />
    </ItemBlock>
  )
})
