import React, { useContext } from 'react'

import { Button } from '@arco-design/web-react'
import { StatusActionTypeEnum, StatusTypeEnum } from '@upay/utils/es/types'

import { PUBSUB_TYPES } from '@/constant'
import { ParamsContext } from '@/stores'
import starling from '@/utils/starling'

import Status from './Status'

export interface IStatusItem {
  show: boolean
  payWay?: number
  error?: string
  action?: StatusActionTypeEnum | string
  desc?: StatusTypeEnum | string
}

interface IProps {
  statusItem: IStatusItem
  setStatusItem: (statusItem: IStatusItem) => void
  onRefresh: () => void
  onClose: () => void
}
const ChangeStatus = (props: IProps) => {
  const { statusItem, setStatusItem, onRefresh, onClose } = props
  const { uPubSub } = useContext(ParamsContext)

  const handleRefresh = () => {
    setStatusItem({
      ...statusItem,
      show: false,
    })

    onRefresh()
  }

  const handleClose = () => {
    setStatusItem({
      ...statusItem,
      show: false,
    })
    uPubSub.publish(PUBSUB_TYPES.STATUS_RESULT, {
      status: [StatusTypeEnum.SUCCEED, StatusTypeEnum.SUCCESS].includes(statusItem.desc as StatusTypeEnum),
      clickBtn: true,
      actionType: statusItem.action,
      msg: statusItem.error || '',
    })

    onClose()
  }

  const renderStatusButton = () => {
    if (statusItem.desc === StatusTypeEnum.PROCESSING) {
      return (
        <Button type="primary" className="upay-status-btn" onClick={handleRefresh}>
          {starling('funds.check_out.comm.Refresh')}
        </Button>
      )
    } else {
      return undefined
    }
  }

  return (
    <Status
      visible={statusItem.show}
      actionType={statusItem.action as StatusActionTypeEnum}
      errMsg={statusItem.error}
      status={statusItem.desc as StatusTypeEnum}
      button={renderStatusButton()}
      onClose={handleClose}
    />
  )
}

export default ChangeStatus
