import React, { FC } from 'react'

import './index.less'

const TTLoading: FC<{ hide?: boolean; height?: number }> = (props) => {
  const { hide = false, height = 250 } = props
  return (
    <div className="upay-tt-loading" style={{ height: `${height}px`, display: hide ? 'none' : 'flex' }}>
      <div className="upay-tt-spinner">
        <div className="upay-tt-cube1" />
        <div className="upay-tt-cube2" />
      </div>
    </div>
  )
}

export default TTLoading
