import React from 'react'
import { FieldError } from 'react-hook-form'

import { Select } from '@arco-design/web-react'
import { omit } from 'lodash'

import { CustomInput, ItemBlock } from '../index'

const Option = Select.Option

interface IProps {
  title: string
  maxLength?: number
  len?: 'half' | 'full'
  placeholder?: string
  error?: FieldError
  errorMessage: string
  value?: string
  options: { value: string; label: string }[]
  onChange: (val: string) => void
}

const prefix = 'pipo-pc-select'

/**
 * 通用的选择组件
 */
export const SimpleSelect = React.forwardRef((props: IProps, ref: any) => {
  const { title, len = 'half', error, errorMessage, placeholder, value, options, onChange } = props
  const itemBlockProps = { title, error, errorMessage, style: len }
  const inputProps = omit(props, Object.keys(itemBlockProps))

  const handleChange = (e: string) => {
    onChange(e)
  }

  return (
    <ItemBlock {...itemBlockProps} errorState={Boolean(error)}>
      <Select
        {...inputProps}
        ref={ref}
        className={prefix}
        size="large"
        allowClear
        value={value}
        error={Boolean(error)}
        placeholder={placeholder}
        onChange={handleChange}
      >
        {options.map(({ value, label }) => (
          <Option key={value} value={value}>
            {label}
          </Option>
        ))}
      </Select>
    </ItemBlock>
  )
})
