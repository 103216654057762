import React, { forwardRef, useEffect } from 'react'
import { Controller, ControllerFieldState, useForm } from 'react-hook-form'

import { PAY_WAY_TYPE } from '@/constant'
import { usePipoRouter, useSubmittingState, useTouchedErrors } from '@/pipo/hooks'
import { PaymentParamsItem, PipoComponentRef, PipoProvider, PipoSubmitParams, generateCommonPaymentParams, validator } from '@/pipo/utils'
import { TOSS_PAYMENT_METHOD, TOSS_PAYMENT_PARAMS_MAP, TossFormData, TossFormProps } from '@/pipo/utils/payment-methods-config/toss'
import starling from '@/utils/starling'
import teaClient from '@/utils/tea'

import { BankListSelect, HolderName } from '../../inner-components'

const clsPrefix = 'pipo-bindcard-pc'

// TODO 待确认
export const TossForm = forwardRef((props: TossFormProps, ref: React.ForwardedRef<PipoComponentRef>) => {
  const {
    onValidate = () => {
      // do nothing
    },
    bankList,
  } = props
  const {
    control,
    handleSubmit: handleFormSubmit,
    getValues,
    formState: { errors: formErrors, isValid, touchedFields },
  } = useForm<TossFormData>({
    mode: 'all',
  })
  const errors = useTouchedErrors<TossFormData>({
    touchedFields,
    errors: formErrors,
  })

  const { getErrorText, getPublicKeyAsync } = PipoProvider.useContext()
  const { formSubmitState } = useSubmittingState()

  useEffect(() => {
    isValid && onValidate(true)
  }, [isValid, onValidate])

  /**
   * 处理PIPO提交所需数据
   * @returns PipoSubmitParams
   */
  const processSubmitData = async (formData: TossFormData): Promise<PipoSubmitParams> => {
    const publicKey = await getPublicKeyAsync()
    const paymentParams: PaymentParamsItem[] = generateCommonPaymentParams(formData, TOSS_PAYMENT_PARAMS_MAP, publicKey)

    const { paymentMethodType, paymentMethod } = TOSS_PAYMENT_METHOD
    return {
      formData,
      paymentMethod: {
        paymentMethod: paymentMethod.paymentMethod,
        paymentMethodId: paymentMethod.paymentMethodId,
      },
      paymentMethodType: paymentMethodType.paymentMethodType,
      paymentParams,
    }
  }

  /**
   * 校验后获取PIPO提交所需数据
   * @returns PipoSubmitParams
   */
  const validateSubmitData = () => {
    return new Promise(async (resolve: (value: PipoSubmitParams) => void, reject: (reason: typeof errors) => void) => {
      if (!isValid) {
        reject(errors)
      } else {
        const formData = getValues()
        resolve(await processSubmitData(formData))
      }
    })
  }

  usePipoRouter(ref, {
    initParams: {
      onValidate,
      isValid,
    },
    instanceMethods: {
      validateSubmitData,
    },
  })

  const handleBlur = (scenario: string, fieldState: ControllerFieldState) => {
    const params = {
      pay_way: PAY_WAY_TYPE.TOSS,
      scenario,
      is_valid: !fieldState.error,
      invalid_reason: fieldState.error?.type,
    }
    teaClient.sendPayPageFillIn(params)
  }

  return (
    <div className="pipo-pc" id="upay-form-toss">
      <div className={`${clsPrefix}-flex-container`}>
        <div className={`${clsPrefix}-form-wrapper`}>
          <Controller
            rules={{ required: true }}
            name="bank_code"
            control={control}
            render={({ field }) => (
              <BankListSelect
                {...field}
                bankList={bankList}
                error={errors.bank_code}
                errorMessage={getErrorText(errors.bank_code?.type)}
                title={starling('funds.check_out.comm.channel_bank')}
              />
            )}
          />
          <Controller
            rules={{ required: true, pattern: validator.nameRegex }}
            name="holder_name"
            control={control}
            render={({ field, fieldState }) => (
              <HolderName
                {...field}
                len="full"
                error={errors.holder_name}
                errorMessage={getErrorText(errors.holder_name?.type)}
                title={starling('funds.check_out.comm.channel_remittername')}
                placeholder={starling('cg.upay.exception.channel_remittername')}
                onBlur={() => {
                  field.onBlur()
                  handleBlur('holder_name', fieldState)
                }}
              />
            )}
          />
        </div>
      </div>
    </div>
  )
})
