import React, { useContext, useEffect, useRef } from 'react'
import { Controller } from 'react-hook-form'

import { PayoutComponentRef } from '@/pipo/pc-payout/types'

import { PayoutContext } from '../../context'

interface IProps {
  name: string
  children: React.ReactElement
}
const ControllerItem: React.FC<IProps> = (props) => {
  const { name, children } = props
  const { control, outerFormRef, cardHolderInfo } = useContext(PayoutContext)

  const formRef = useRef<PayoutComponentRef | null>(null)

  useEffect(() => {
    outerFormRef.current.set(name, formRef)
  }, [name, outerFormRef])

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const Component = React.cloneElement(children, { ...field, cardHolderInfo, ref: formRef })
        return Component
      }}
    />
  )
}

export default ControllerItem
