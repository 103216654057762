import { PaymentMethodItem } from '../../types'

export const ALFA_PAYMENT_METHOD: PaymentMethodItem = {
  paymentMethodType: {
    configuration: {},
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/cvs-common_f99160.svg',
    },
    starling_key: '',
    paymentMethodType: 'CASH_PIN',
    title: {
      payment: 'pipo_common_payin_conveniencestore',
    },
  },
  paymentMethod: {
    configuration: {
      png_icon_url: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/cvs_alfa_v1_fd4698.png',
      dark_mode_png_icon_url: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/alfa-dark_88f86a.png',
    },
    paymentMethod: 'ALFA',
    paymentMethodId: 'pm_pi_cp_alfa_c_d',
    displayName: 'Cash payment at Alfa',
    starling_key: 'pipo_payin_payment_main_cashinalfa',
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/cvs_alfa_v1_fd4698.png',
      pngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sg/sky/cvs_alfa_v1_fd4698.png',
      darkModePngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/alfa-dark_88f86a.png',
      voucherIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/alfa-code_606f05.png',
      grayIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/alfa-gray_b7a5c3.png',
    },
  },
}
