import { useContext, useEffect, useRef, useState } from 'react'
import { FieldErrors, UseFormGetValues, UseFormSetValue, UseFormTrigger, UseFormWatch } from 'react-hook-form'

import { useStateRealtime } from '@byted/hooks'
import { IdcEnum, IdcT } from '@upay/utils/es/types'

import { servicesUtils } from '@/services/utils'
import { ParamsContext } from '@/stores'
import utils from '@/utils'

import { BoletoFormData, CCDCFormData } from '../utils'
import { getPostalCodeRules } from './use-postal-code'

export const FILL_BY_POSTAL_CODE_REGION: { [key: string]: { fetchAfterBlur: boolean } } = {
  US: { fetchAfterBlur: false },
  GB: { fetchAfterBlur: true },
  FR: { fetchAfterBlur: false },
  PL: { fetchAfterBlur: true },
}
export const POSTAL_CODE_HELP_URL: { [key: string]: string } = {
  US: 'https://tools.usps.com/go/ZipLookupAction!input.action',
  GB: 'http://www.royalmail.com/postcode-finder',
  FR: 'http://www.laposte.fr/Particulier/Utiliser-nos-outils-pratiques/Outils-et-documents/Trouvez-un-code-postal',
  PL: 'http://kody.poczta-polska.pl/',
  BR: 'https://buscacepinter.correios.com.br/app/endereco/index.php',
}

interface IUseFillByPostalCode {
  formErrors: FieldErrors<CCDCFormData | BoletoFormData>
  nameOptions: {
    region: 'billing_country_region'
    state: 'billing_state'
    city: 'billing_city'
    postalCode: 'billing_postal_code'
  }
  trigger: UseFormTrigger<CCDCFormData | BoletoFormData>
  getValues: UseFormGetValues<CCDCFormData | BoletoFormData>
  setValue: UseFormSetValue<CCDCFormData | BoletoFormData>
  watch: UseFormWatch<Omit<CCDCFormData, 'proxy_type'> | Omit<BoletoFormData, 'proxy_type'>>
}

export const useFillByPostalCode = (props: IUseFillByPostalCode) => {
  const { urlQuery } = useContext(ParamsContext)
  const { formErrors, nameOptions, getValues, setValue, trigger, watch } = props
  const { region, city, state, postalCode } = nameOptions
  const currentCountryCode = useRef('')
  const [isFillByPostalCode, setIsFillByPostalCode, getIsFillByPostalCode] = useStateRealtime(false)
  const [loadingAddress, setLoadingAddress] = useState(false)
  const [postCodeResArr, setPostCodeResArr] = useState<string[][]>([])

  const handleCountryChange = (countryCode: string) => {
    setIsFillByPostalCode(!!FILL_BY_POSTAL_CODE_REGION[countryCode])
    currentCountryCode.current = countryCode
    setPostCodeResArr([])
    setValue(state, undefined)
    setValue(city, undefined)
    setValue(postalCode, undefined)
  }

  const resetStates = () => {
    setPostCodeResArr([])
    setValue(state, undefined)
    setValue(city, undefined)
    setIsFillByPostalCode(true)
  }

  const fetchAddress = async (code: string) => {
    setLoadingAddress(true)
    const addressArr = await servicesUtils.getAddressByPostalCode(code, currentCountryCode.current, utils.getIdc(urlQuery.idc) as IdcT)
    setLoadingAddress(false)
    if (code === getValues()?.[postalCode]) {
      setPostCodeResArr(addressArr)
      if (addressArr.length <= 0) {
        setIsFillByPostalCode(false)
        return
      }
      setIsFillByPostalCode(true)
      if (addressArr.length === 1) handleSelectPostalCodeRes(addressArr[0])
    }
  }

  const handleSelectPostalCodeRes = (postalCodeResItem: string[]) => {
    setValue(state, postalCodeResItem[0])
    setValue(city, postalCodeResItem[2])
    trigger(state)
    trigger(city)
  }

  const postalCodeValue = watch(postalCode)

  // 根据验证规则自动触发,适合强校验的邮编
  useEffect(() => {
    if (FILL_BY_POSTAL_CODE_REGION[currentCountryCode.current] && !FILL_BY_POSTAL_CODE_REGION[currentCountryCode.current].fetchAfterBlur) {
      if (postalCodeValue && new RegExp(getPostalCodeRules(currentCountryCode.current)).test(postalCodeValue)) {
        fetchAddress(postalCodeValue)
      } else {
        resetStates()
      }
    }
  }, [postalCodeValue])

  // Blur后触发,适合弱校验的邮编 如GB
  const handlePostalCodeBlur = () => {
    if (FILL_BY_POSTAL_CODE_REGION[currentCountryCode.current]?.fetchAfterBlur) {
      setTimeout(() => {
        const code = getValues()?.[postalCode]
        !formErrors?.[postalCode] && code && fetchAddress(code)
      }, 0)
    }
  }
  // Blur触发类型 onchange后重置
  const handlePostalCodeChange = () => {
    if (FILL_BY_POSTAL_CODE_REGION[currentCountryCode.current]?.fetchAfterBlur) {
      resetStates()
    }
  }

  return {
    isFillByPostalCode,
    loadingAddress,
    postCodeResArr,
    handleCountryChange,
    getIsFillByPostalCode,
    handleSelectPostalCodeRes,
    handlePostalCodeBlur,
    handlePostalCodeChange,
  }
}
