import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

import { Alert, Button, Message, Modal, Result } from '@arco-design/web-react'
import { useRequest } from '@byted/hooks'
import { Logger } from '@upay/utils'
import { StatusActionTypeEnum, StatusTypeEnum } from '@upay/utils/es/types'

import { PageModal } from '@/components'
import ErrorBoundary from '@/components/ErrorBoundary'
import { PullStatusEnum } from '@/components/Status/config'
import TTLoading from '@/components/TTLoading'
import { COMMON_PROMPT, FROM_PAGE_ENUM, PAY_WAY_TYPE, PUBSUB_TYPES } from '@/constant'
import { PipoProvider, Voucher } from '@/pipo/pc'
import { PaymentDetail } from '@/pipo/utils'
import api from '@/services'
import { IGetResultRes, PreOrderRes } from '@/services/types'
import { Error as ErrorIcon } from '@/static/icon'
import { ParamsContext } from '@/stores'
import GlobalInfo from '@/stores/GlobalInfo'
import { ModeTypeEnum } from '@/typings'
import SafeJSON from '@/utils/SafeJSON'
import { getLang } from '@/utils/getLang'
import { oneTimeHoc } from '@/utils/oneTimeHoc'
import { logger } from '@/utils/slardar'
import starling from '@/utils/starling'
import teaClient from '@/utils/tea'

import ActionBtns from './ActionBtns'
import { IPaymentCodeProps, PaymentCodeContext } from './interface'

import './index.less'

const PaymentCode: FC<IPaymentCodeProps> = (props) => {
  const { query, onClose, handleBillStatus } = props
  const { params, urlQuery, uPubSub } = useContext(ParamsContext)
  const isIndependentPage = props.from === FROM_PAGE_ENUM.PAYMENT_CODE && params.mode === ModeTypeEnum.HostedPage
  const {
    data: { pipoProviderOptions },
  } = useContext(GlobalInfo)
  const { token, payway, recordNo: chargeId } = query || urlQuery
  const selectedPayWayNumber = Number(payway || `${PAY_WAY_TYPE.BOLETO}`)

  const defaultVisible = useMemo(() => props.visible ?? params.defaultVisible, [props.visible, params.defaultVisible])
  const [visible, setVisible] = useState(defaultVisible)
  const [paymentDetails, setPaymentDetails] = useState<PaymentDetail>()
  const [upayResult, setUpayResult] = useState<IGetResultRes>({ status: PullStatusEnum.PENDING, errorMsg: '' })
  const fetchOrderInfoPromise = useRef<Promise<PreOrderRes | undefined>>()

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const tipRef = useRef<any>(null)
  const [tipHeight, setTipHeight] = useState(40)

  const [backLoading, setBackLoading] = useState(false)
  const [contextValue, setContextValue] = useState({
    payWay: selectedPayWayNumber,
    recordNo: chargeId || '',
  })

  useEffect(() => {
    setVisible(defaultVisible)
  }, [defaultVisible])

  const queryOrderInfo = useCallback(async () => {
    if (!chargeId) {
      !fetchOrderInfoPromise.current && (fetchOrderInfoPromise.current = api.getOrderInfo({ token }).then((res) => res.data))
      const orderRes = await fetchOrderInfoPromise.current
      return {
        chargeId: orderRes?.charge_id,
      }
    }
    return { chargeId }
  }, [chargeId, token])

  const fetchPaymentResult = useCallback(async () => {
    try {
      const { chargeId } = await queryOrderInfo()
      const { data } = await api.getPayResult({ recordNo: chargeId || '', token, language: getLang() })
      data && setUpayResult(data)

      return data
    } catch (error) {
      console.error('fetchPaymentResultError', error)
      return undefined
    }
  }, [queryOrderInfo, token])

  // 轮训支付结果
  const { cancel, run } = useRequest(fetchPaymentResult, {
    pollingInterval: 60 * 1000,
  })
  // 开始轮询
  useEffect(() => {
    // 展示Paymentcode后才开始轮训结果
    if (isIndependentPage || visible) {
      run()
    } else {
      cancel()
    }
  }, [cancel, run, isIndependentPage, visible])
  // 结束轮训
  useEffect(() => {
    if (upayResult.status !== PullStatusEnum.PENDING) {
      cancel()
    }
  }, [cancel, upayResult])

  const init = useCallback(async () => {
    try {
      const { chargeId = ' ' } = await queryOrderInfo()
      setContextValue((prev) => ({ ...prev, recordNo: chargeId }))
      teaClient.sendPaymentCodePageView({ order_id: chargeId, pay_way: selectedPayWayNumber })

      const res = await api.getPaymentDetail({ token, recordNo: chargeId })
      if (!res?.data) {
        throw new Error(COMMON_PROMPT().SYS_ERR)
      }
      const paymentDetail: PaymentDetail = {
        ...res.data,
        // 此处present_to_shopper_details 以及 payment_method_info 取到的是string，需预先处理
        payment_method_info: (res.data?.payment_method_info
          ? SafeJSON.parse(res.data?.payment_method_info as unknown as string)
          : {}) as PaymentDetail['payment_method_info'],
        present_to_shopper_details: (res.data?.present_to_shopper_details
          ? SafeJSON.parse(res.data?.present_to_shopper_details as unknown as string)
          : {}) as PaymentDetail['present_to_shopper_details'],
      }
      setPaymentDetails(paymentDetail)
      setLoading(false)
      paymentCodeLogger.scenes.paymentCodeDataInit({ payWay: selectedPayWayNumber, chargeId })
    } catch (error: any) {
      paymentCodeLogger.fatal('paymentcode page init fail', error, { scene: 'paymentCodeDataInit' })
      const errMsg = error.msg || error.errorMessage || error.message
      errMsg && Message.error(errMsg)
      setError(true)
    }
  }, [queryOrderInfo, selectedPayWayNumber, token])

  useEffect(() => {
    if (visible) {
      paymentCodeLogger.scenes.paymentCodePageView({ payWay: selectedPayWayNumber, chargeId })
      init()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [init, visible])

  useEffect(() => {
    if (loading || upayResult.status !== PullStatusEnum.PENDING) {
      setTipHeight(0)
    } else {
      setTipHeight(tipRef.current?.getBoundingClientRect()?.height || 40)
    }
  }, [loading, upayResult.status])

  const handleClosePaymentCode = (openStatus = false) => {
    if (onClose) {
      onClose(selectedPayWayNumber, openStatus)
    } else {
      setVisible(false)
      uPubSub.publish(PUBSUB_TYPES.MODAL_CANCEL, false)
    }
  }

  const onCancelBtnClick = async () => {
    setBackLoading(true)
    const payResult = await fetchPaymentResult()
    setBackLoading(false)
    paymentCodeLogger.scenes.paymentCodePageClick({ clickItem: 'back', order_id: contextValue.recordNo, payWay: selectedPayWayNumber, payResult })
    teaClient.sendPaymentCodePageClick({
      order_id: contextValue.recordNo,
      pay_way: selectedPayWayNumber,
      click_item: 'back',
    })

    if (payResult?.status === PullStatusEnum.SUCCESS) {
      handleBillStatus?.({
        actionType: StatusActionTypeEnum.PAY,
        status: StatusTypeEnum.SUCCESS,
      })
      handleClosePaymentCode(!!handleBillStatus)
    } else if (payResult?.status === PullStatusEnum.FAIL) {
      handleBillStatus?.({
        actionType: StatusActionTypeEnum.PAY,
        status: StatusTypeEnum.FAILED,
        errMsg: payResult.errorMsg,
      })
      handleClosePaymentCode(!!handleBillStatus)
    } else {
      // 展示挽留信息
      Modal.confirm({
        title: starling('funds.check_out.comm.sure_to_leave'),
        content: starling('funds.check_out.comm.status_payment_code_processing_tip3'),
        okText: starling('funds.check_out.comm.button_stay'),
        cancelText: starling('funds.check_out.comm.button_leave'),
        className: 'upay-modal upay-modal-simple',
        onCancel: () => handleClosePaymentCode(false),
        maskClosable: false,
      })
    }
  }

  const renderFooter = () => {
    return (
      <div className="upay-modal-footer upay-payment-code__footer">
        <div className="upay-modal-footer-left upay-payment-code__footer-left">
          <Button loading={backLoading} onClick={onCancelBtnClick}>
            {starling('funds.refund.comm.back')}
          </Button>
        </div>
        <ActionBtns recordNo={contextValue.recordNo} paymentDetail={paymentDetails} payWay={selectedPayWayNumber} />
      </div>
    )
  }

  return (
    <PageModal
      className="upay-payment-code upay-modal"
      visible={isIndependentPage || visible}
      contentHeight={params.height}
      mode={isIndependentPage && params.mode}
      closable={false}
      alignCenter={false}
      footer={renderFooter()}
    >
      <ErrorBoundary loggerInstance={paymentCodeLogger} label="PaymentCodeModal">
        <div className="payment-container">
          <div className={`payment-tip ${loading || upayResult.status !== PullStatusEnum.PENDING ? 'hide' : ''}`} style={{ height: tipHeight }}>
            <Alert
              ref={tipRef}
              type="info"
              showIcon
              style={{ marginBottom: '10px' }}
              content={starling('funds.check_out.comm.status_payment_code_processing_tip3')}
            />
          </div>
          <div className="payment-code">
            {error ? (
              <Result style={{ padding: '80px 0' }} status={null} icon={<ErrorIcon />} subTitle={starling('funds.refund.comm.system_system_error')} />
            ) : loading ? (
              <TTLoading hide={!loading} height={400} />
            ) : (
              <PipoProvider.Provider options={pipoProviderOptions}>
                <PaymentCodeContext.Provider value={contextValue}>
                  <Voucher paymentDetail={paymentDetails!} upayResult={upayResult} />
                </PaymentCodeContext.Provider>
              </PipoProvider.Provider>
            )}
          </div>
        </div>
      </ErrorBoundary>
    </PageModal>
  )
}

export default oneTimeHoc(React.memo(PaymentCode))

export const paymentCodeLogger = new Logger('SDK_PAYMENT_CODE', logger)
