import { useContext } from 'react'

import { useRequest } from '@byted/hooks'

import api from '@/services'
import { ParamsContext } from '@/stores'

export const useCardHolderInfo = () => {
  const {
    urlQuery: { token },
  } = useContext(ParamsContext)

  const handleFetchCardHolderInfo = async () => {
    const res = await api.getPayoutCardHolderInfo({ token })

    const cardHolderInfoList = res.data?.cardHolderInfoList || []
    const holderNameList = cardHolderInfoList.map((item) => item.holderName || '').filter(Boolean) || []

    const firstNameList = cardHolderInfoList.map((item) => item.firstName || '').filter(Boolean) || []
    const lastNameList = cardHolderInfoList.map((item) => item.lastName || '').filter(Boolean) || []
    const fullNameValid = firstNameList.length > 0 && firstNameList.length === lastNameList.length

    return {
      holderNameList,
      firstNameList: fullNameValid ? firstNameList : [],
      lastNameList: fullNameValid ? lastNameList : [],
      toCOrToB: res.data?.toCOrToB || 'b_c',
    }
  }

  const { data: cardHolderInfo } = useRequest(handleFetchCardHolderInfo, {
    auto: true,
  })

  return cardHolderInfo
}
