import { PaymentMethodItem } from '../../types'

export const FAMILYMART_PAYMENT_PARAMS_MAP: Record<string, string> = {
  email: 'eg_cvs_jp_email',
}

export const FAMILYMART_PAYMENT_METHOD: PaymentMethodItem = {
  paymentMethodType: {
    configuration: {},
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/cvs-common_f99160.svg',
    },
    starling_key: '',
    paymentMethodType: 'CASH_PIN',
    title: {
      payment: 'pipo_common_payin_conveniencestore',
    },
  },
  paymentMethod: {
    configuration: {
      png_icon_url: '',
    },
    paymentMethod: 'FAMILY_MART',
    paymentMethodId: 'pm_pi_cp_familymart_c_d',
    displayName: 'FamilyMart',
    starling_key: 'pipo_payin_payment_main_familymart',
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/familymart-white_a1cea0.svg',
      grayIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/familymart-gray_9f5012.svg',
      voucherIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/familymart-voucher_12b5a9.svg',
    },
  },
}
