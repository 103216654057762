import { useBoolean } from '@byted/hooks'
import type { ReturnValue } from '@byted/hooks/es/useBoolean'

export const useSubmittingState = (): {
  isSubmitting: boolean
  formSubmitState: ReturnValue
} => {
  const formSubmitState = useBoolean(false)
  return {
    isSubmitting: formSubmitState.state,
    formSubmitState,
  }
}
