import { useMemo } from 'react'
import { OperationalGuideProps } from './index'
import { PipoProvider, SUPPORT_PAYMENT_METHODS_ID_MAP, GET_PAYMENT_CODE_CONFIG, PcIcon, GET_SUPPORT_BANK_MAP } from '@/pipo/utils'

const textPrefix = 'pipo_payin_paymentcode'
interface GuideItem {
  content: string
  icon?: PcIcon
  subTitle: string
}

const useGuide = (props: OperationalGuideProps) => {
  const { paymentMethodType, paymentMethodId, bankId = '' } = props.paymentMethodInfo
  const { t } = PipoProvider.useContext()

  const paymentMethod = SUPPORT_PAYMENT_METHODS_ID_MAP[paymentMethodId].paymentMethod.paymentMethod
  const methodKey = useMemo(() => {
    if (paymentMethodId === 'pm_pi_bt_banktransfertoss_c_d') return 'TOSS'
    return paymentMethodType === 'BANK_TRANSFER' ? paymentMethodType : paymentMethod
  }, [paymentMethodType, paymentMethod])

  const extraText = paymentMethodType === 'BANK_TRANSFER' ? `${bankId}_` : `${paymentMethod?.toLowerCase()}_`
  const PAYMENT_CODE_CONFIG = useMemo(GET_PAYMENT_CODE_CONFIG, [])
  const SUPPORT_BANK_MAP = useMemo(GET_SUPPORT_BANK_MAP, [])

  const [instructionArr, defaultActiveKey] = useMemo<[GuideItem[], string[]]>(() => {
    const resArr: GuideItem[] = []
    const resActiveKeys: string[] = []
    methodKey &&
      PAYMENT_CODE_CONFIG[methodKey] &&
      PAYMENT_CODE_CONFIG[methodKey].methods.forEach((item, index) => {
        let content = item?.contentValue || ''

        // 此处暂时保留使用PIPO提供文案的方法 以防万一
        if (item.contentStarlingKey) {
          const contentStarling = `${textPrefix}_${extraText}${item.contentStarlingKey}`
          content = t(contentStarling)
        }

        // BankTransfer帮助文案
        if (bankId && item.bankGuideKey) {
          content = SUPPORT_BANK_MAP[bankId]?.operationGuide[item.bankGuideKey] || ''
        }

        if (content) {
          const subTitle = item.subTitleValue || (item.starlingKey ? t(item.starlingKey) : '')
          resArr.push({
            subTitle: subTitle,
            icon: item.pcIcon,
            content: content,
          })
          item.defaultActive && resActiveKeys.push(index.toString())
        }
      })

    return [resArr, resActiveKeys]
  }, [paymentMethodId, bankId, paymentMethodType, t])

  return {
    instructionArr,
    collapsable: PAYMENT_CODE_CONFIG[methodKey || '']?.collapsable ? true : false,
    defaultActiveKey,
  }
}

export default useGuide
