import { StatusTypeEnum } from '@upay/utils/es/types'

import starling from '@/utils/starling'

export const PREFIX = `/upay/i18n`

/**
 * 后端返回 code 枚举
 */
export const RESPONSE_CODE = {
  /** 成功 */
  SUCCESS: 0,
  /** 网络错误 */
  NETWORK_ERROR: -9999,
  /** 解析错误 */
  PARSE_ERROR: -10000,
  /** 外部接口错误 */
  EXTERNAL_ERROR: -10001,
}

/**
 * 外部接口 code 枚举
 */
export const EXTERNAL_RESPONSE_CODE = {
  /** 登录接口成功code */
  LOGIN_SUCCESS: 'success',
}

/**
 * 订阅、发布事件类型
 */
export const PUBSUB_TYPES = {
  // 业务方listen
  /** 通知结果到业务方 */
  STATUS_RESULT: `upay_status_result`,
  /** 从业务方获取特定参数 */
  REQUEST_PARAM: `upay_request_param`,
  /** 告知业务方 SDK 已经初始化成功 */
  DID_MOUNT: `upay_did_mount`,
  /** 通知业务方用户点击弹窗关闭 */
  MODAL_CANCEL: `upay_modal_cancel`,
  /** 通知业务方用户点击弹窗提交 */
  MODAL_SUBMIT: `upay_modal_submit`,
  /** 通知业务方用户移除卡成功 */
  REMOVE_CARD_SUCCESS: `remove_card_success`,

  // 业务方trigger
  /** 打开绑卡/支付弹窗 */
  OPEN_MODAL: `upay_open_modal`,
  /** 刷新卡管理列表 */
  REFRESH_MANAGER_LIST: `upay_refresh_manager_list`,
  /** 业务方传递特定参数 */
  SEND_PARAM: `upay_send_param`,

  // 结果页message type
  /** 3ds结果 */
  '3DS_RESULT': `upay_3ds_result`,
  UPDATE_REQUEST_ID: 'update_request_id',

  // 内部调用
  /**在后付费用户切换使用预付支付方式时，重新拉取 nonce */
  POST_PAY_CHECKOUT_ROUTE: 'post_pay_checkout_route',
}

export const IS_PROD = BUILD_TYPE === 'online'
export const IS_BOE = BUILD_TYPE === 'offline'
export const IS_DEV = BUILD_ENV === 'local' || BUILD_ENV === 'docs'
export const IS_DEV_OR_BOE = IS_DEV || IS_BOE || location.host.includes('boe') || location.host.includes('boei18n')

/**
 * 不同平台的bizId参数
 */
export const PLATFORM_MAP = {
  TT_ADS: '87316307520',
  BC: '87316307533',
  SHOPIFY: '87216307551',
  TCM: '87216307523',
  TCM_US: '100002',
  TBP: '100003',
  PROMOTE_SG: '87216307519',
  PROMOTE_TTP: '87216307553',
  PROMOTE_VA: '87216307550',
}

// 通用提示
export const COMMON_PROMPT = (): {
  [index: string]: string
} => {
  return {
    SYS_ERR: starling('funds.refund.comm.system_system_error'),
  }
}

// 类型：1-预付，2-后付，3-变更支付方式 4月账单
export const PAYMENT = {
  PRE_PAY: '1',
  POST_PAY: '2',
  CHANGE_PAY: '3',
  MONTHLY_PAY: '4',
}

// 1、自助 2、非自助
export const ADS_TYPE = {
  SELF_HELP_CUSTOMER: 1,
  NONE_SELF_HELP_CUSTOMER: 2,
}

/**
 * 绑卡类型
 * agreement: 协议绑卡
 * auth: 独立绑卡
 * payment: 支付绑卡
 */
export const PIPO_TYPE = {
  /** 协议绑卡 */
  AGREEMENT: 'agreement',
  /** 独立绑卡 */
  AUTH: 'auth',
  /** 支付绑卡 */
  PAYMENT: 'payment',
}

// 订单状态，1-待支付 2-支付中 3-支付成功 4-支付失败 5-支付超时
export const ORDER_STATUS = {
  NOTPAY: 1,
  PAYING: 2,
  PAY_SUCCESS: 3,
  PAY_FAILED: 4,
  PAY_TIMEOUT: 5,
}
// 订单状态，1-待支付 2-支付中 3-支付成功 4-支付失败 5-支付超时
export const ORDER_STATUS_MAP: Record<number, StatusTypeEnum> = {
  2: StatusTypeEnum.PROCESSING,
  3: StatusTypeEnum.SUCCESS,
  4: StatusTypeEnum.FAILED,
}

export const TRADE_TYPE = {
  MONTHLY_PAY: 'MONTHLY_PAY',
}

export const PAY_WAY_TYPE = {
  /** bank transfer 支付方式 */
  BANK_TRANSFER: 30,
  /** 新卡支付 */ //
  CCDC: 0,
  CREATE_NEW_CARD: 0,
  /** 已有卡支付 */
  EXISTED_CARD: 99,
  /** OVO */
  OVO: 34,
  /** DANA */
  DANA: 35,
  /** DIRECT_DEBIT_US */
  DIRECT_DEBIT: 36,
  /** DIRECT_DEBIT_EU */
  DIRECT_DEBIT_EU: 47,
  /** DIRECT_DEBIT_CA */
  DIRECT_DEBIT_CA: 50,
  /** DIRECT_DEBIT_CA */
  DIRECT_DEBIT_UK: 57,
  /** Boleto */ // CASH_PIN
  BOLETO: 39,
  /** QIWI */
  QIWI: 40,
  /** paypal */
  PAYPAL: 5,
  /** paypal refrence */
  PAYPAL_REF: 31,
  /** FPX */
  FPX: 42,
  /** PIX */
  PIX: 44,
  /** SHOPEE_PAY */
  SHOPEE_PAY: 45,
  /** GoPay */
  GOPAY: 49,
  /** BANK_TRANSFER_BO */
  BANK_TRANSFER_BO: 56,
  /** BANK_TRANSFER_US */
  BANK_TRANSFER_US: 69,
  /** MoMo */
  MOMO: 52,
  /** Zalo */
  ZALO: 54,
  /** Paycell */
  PAYCELL: 58,
  /** Toss */
  TOSS: 61,
  AT_EPS: 64,
  /** BANK_TRANSFER_JP */
  BANK_TRANSFER_JP: 68,
  VENMO: 65,
  /** Fawry */
  FAWRY: 66,
  OXXO: 70,
  PAY_U: 71,
  ATM_CARD: 72,
  CASH_PLUS: 73,
  BANK_TRANSFER_MX: 79,
  BLIK: 80,
  GCASH: 51,
  GOPAY_V2: 78,
}
export const PAYWAY_HAS_BALANCE = [PAY_WAY_TYPE.DANA, PAY_WAY_TYPE.SHOPEE_PAY, PAY_WAY_TYPE.PAYCELL]

export const PIPO_CODE = {
  REDIRECT: 'redirect',
  SUCCESS: 'success',
  ERROR: 'error',
  SUCCEED: 'succeed',
}

// risk insert时的action type
export const SUBMIT_TYPE = {
  BIND: 2,
  PAY: 3,
}

export const PIPO_REFRESH_TIME = 1000 * 60 * 4
export const PASSPORT_TICKET_REFRESH_TIME = 1000 * 60 * 4

export const ORIGIN_KEY = {
  LOCAL: 'a0d46aff760a035f6dcea5ce3d6d87b324280dd6',
  DOCS: 'a1aada5216fa37982a2f29a9f69b9a45a2694c26',
}

export const SHOPIFY_PRE_ORIGIN_KEY: Record<string, string> = {
  '07311SGintjTYBbusi': '709468ad9ca138da2936bfb7516c0b887c0f7dc4',
  '10202007S2Pa69': 'e4fd20d0359c0f5f4da651c73e7e6ff26c672609',
  '10202007Bym6R6': '07f3ce3ab5686d7fee89b60fd6358f26fa76603d',
  '11202011XzYsmB': '986cc171256fed072cbc450fef66c6dda47cb0f5',
  '11202011MEvcHv': '10f5a796b1deb973561f252b59575be8e0f400f2',
  '11202011QAUcdu': 'bd56b187b4e3f06ac52785d48fa39fd9626f2ea2',
  '11202011SC3WV3': '8bc2312fc2e09a89a40cce6af499a1cc08ec060e',
  '11202105SJR5dB': '59725653c74bd10d88c3aefc4e1e7d3f08e61f4e',
  '10202007S5JB8z': '25fea7d8a553088066d6cd91c3a03cf45b5e758e',
  '10202106RyJ7S1': 'f46256dba537f7e76c8568fef8023fdf4120957d',
}

export const DEV_ORIGIN_KEY = BUILD_ENV === 'local' ? ORIGIN_KEY.LOCAL : ORIGIN_KEY.DOCS

export const DEFAULT_THEME_COLOR = {
  mainColor: '#2a4d8f',
  // secondaryColor: '#2a4d8f', // 默认不指定，init时取mainColor的值
  hoverColor: '#3f71c3',
  pressColor: '#25457f',
  disabledColor: '#91acdf',
}

/**
 * PIPO语言标识符转换 https://bytedance.feishu.cn/docs/doccnl4B44YrHbzQ2bmCvHEitUd
 */
export const PIPO_LOCALE_MAP: Record<string, string> = {
  pt: 'pt-BR',
  'vi-VN': 'vi',
}

export enum FROM_PAGE_ENUM {
  PAY = 'pay',
  CREATE_IN_MANAGER = 'createInManager',
  CREATE_IN_PAY = 'createInPay',
  UPDATE = 'update',
  UPDATE_IN_MANAGER = 'updateInManager',
  UPDATE_IN_PAY = 'updateInPay',
  CHANGE = 'change',
  CREATE = 'create',
  PAYMENT_CODE = 'paymentCode',
  MANAGER = 'manager',
  NEW_PAY = 'newPay',
  PAY_NEXT = 'payNext',
  PAYOUT = 'payout',
  PAYOUT_IN_INFORMATION = 'payoutInInformation',
}
/**
 * 新SDK 终端埋点标记
 */
export const NEW_SDK_TERMINAL_EQUIP = 4
// 发送recordADD埋点时的actionType
export const SUBMIT_RECORD_TYPE = {
  /**
   * 支付提交
   */
  PAY_SUBMIT: 1,
  /**
   * 绑卡选择
   */
  BIND_CHANNEL_SELECT: 2,
  /**
   * 绑卡提交
   */
  BIND_SUBMIT: 3,
  /**
   * 月账单预览
   */
  MONTHLY_VIEW: 4,
  /**
   * 月账单登陆按钮点击
   */
  MONTHLY_LOGIN: 5,
  /**
   * 点击新绑卡并支付
   */
  CLICK_NEW_BIND_AND_PAY: 6,
  /**
   * 是否确定离开收银台
   */
  LEAVE_UPAY: 7,
  /**
   * 进入payment code页面
   */
  PAYMENT_CODE_VIEW: 8,
  /**
   * 预下单提交
   */
  PRE_ORDER_SUBMIT: 9,
  /**
   * 支付提交用户决策所花时间
   */
  PAYMENT_DECISION_COST: 14,
  /**
   * 绑卡提交用户决策所花时间
   */
  BIND_DECISION_COST: 15,
  /**
   * 绑卡路由浏览埋点
   */
  BIND_CHANNEL_VIEW: 16,
  /**
   * 支付路由浏览埋点
   */
  PAY_CHANNEL_VIEW: 17,
}
// 1-支付 2-绑卡
export enum EVENT_TYPE {
  BIND = 2,
  PAY = 1,
}

// 需要针对4级地址进行优化的国家
export const REQUIRE_L4_ADDRESS_COUNTRY_CODE = ['US', 'CA']

// 根据用户选中的地理位置获取邮编提示
export const POSTALCODE_TIP = (): { [key: string]: string } => ({
  US: starling('funds.refund.comm.system_postalcode_tips_US'),
  CA: starling('funds.refund.comm.system_postalcode_tips_CA'),
  // 英国在地理位置接口中的简称为GB
  GB: starling('funds.refund.comm.system_postalcode_tips_UK'),
  BR: starling('funds.refund.comm.system_postalcode_tips_BR'),
})

export const HELP_CENTER_LINK = 'https://ads.tiktok.com/help/article?aid=10006002'
export const SHOW_HELP_CENTER_PLATFORM_MAP = ['87316307520', '87316307533']

export const CDN_DOMAIN = '//lf16-upay.g-t-static.com'
export const JS_CDN_DOMAIN_PREFIX = '//sf16-website-login.neutral.ttwstatic.com/obj/tiktok_web_login_static'
