import { PAY_WAY_TYPE } from '@/constant'
import initVenmo from '@/utils/venmo'

export const PAYMENTS_2_HANDLER_MAP = {
  [PAY_WAY_TYPE.VENMO]: initVenmo,
}
const paymentChannelPreHandler = async (paymentList: any[]) => {
  const finalList = []
  for (const paymentItem of paymentList) {
    const handler = PAYMENTS_2_HANDLER_MAP[paymentItem.payWay]
    let isPaymentItemAvaliable = true
    if (handler) {
      isPaymentItemAvaliable = await handler()
    }
    isPaymentItemAvaliable && finalList.push(paymentItem)
  }
  return finalList
}
export default paymentChannelPreHandler
