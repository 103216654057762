import React, { useContext, useState } from 'react'

import { ParamsContext } from '@/stores'

import PayinManager from './PayinManager'
import PayoutManager from './PayoutManager'

import './index.less'

enum ManagerTypeEnum {
  Payin = 'payin',
  Payout = 'payout',
}
const Manager: React.FC = () => {
  const [managerType, setManagerType] = useState(ManagerTypeEnum.Payin)
  const isPayin = managerType === ManagerTypeEnum.Payin

  const { params } = useContext(ParamsContext)
  const { height } = params

  const handleChangeType = (type: ManagerTypeEnum) => {
    setManagerType(type)
  }

  return (
    <div className="upay-manager" style={{ height: height }}>
      <PayinManager visible={isPayin} onChangeType={() => handleChangeType(ManagerTypeEnum.Payout)} />
      <PayoutManager visible={!isPayin} onChangeType={() => handleChangeType(ManagerTypeEnum.Payin)} />
    </div>
  )
}

export default Manager
