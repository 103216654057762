import { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { Message as message } from '@arco-design/web-react'
import { createModel } from '@byted/hooks'
import { StatusActionTypeEnum, StatusTypeEnum } from '@upay/utils/es/types'

import { useCheckOTPModal } from '@/components/CheckOTPModal/useCheckOTPModal'
import { FROM_PAGE_ENUM, PUBSUB_TYPES, SUBMIT_RECORD_TYPE } from '@/constant'
import { useNewWindow, usePostMessage } from '@/hooks'
import { useUpdate } from '@/pages/Update/hooks/useUpdate'
import { IPaymentMethodsPropsBasic } from '@/payments/interface'
import { CardListRes } from '@/services/types'
import { ParamsContext } from '@/stores'
import GlobalInfo from '@/stores/GlobalInfo'
import teaClient from '@/utils/tea'

import { payLogger } from '..'
import { useBillInfo } from '../hooks/useBillInfo'
import { useFailedChannelTip } from '../hooks/useFailedChannelTip'
import { useFeelgoodBack } from '../hooks/useFeelgoodBack'
import { usePayNextVisible } from '../hooks/usePayNextVisible'
import usePayRecorder from '../hooks/usePayRecorder'
import { usePayinMethodList } from '../hooks/usePayinMethodList'
import { usePaymentCode } from '../hooks/usePaymentCode'
import { usePreOrder } from '../hooks/usePreOrder'
import { useStatusAndLoading } from '../hooks/useStatusAndLoading'
import { IPayNextProps, IPayinMethodItem, PaymentSubmitMethodEnum } from '../interface'

const usePayNext = (props: IPayNextProps = {}) => {
  const PID = 'payNext'
  const { urlQuery, uPubSub } = useContext(ParamsContext)
  const {
    utils: { trackViewChannel, trackSelectChannel },
  } = useContext(GlobalInfo)

  /******
   * 页面基础状态
   */

  const [errorMsg, setErrorMsg] = useState('')
  const { visible, isIndependentPage, setVisible } = usePayNextVisible(props)
  // 支付处理loading相关变量
  const [paying, setPaying] = useState(false)

  /******
   * 基础信息获取
   */
  const { billInfo, refreshBillInfo } = useBillInfo({ setErrorMsg, handleBillStatus: (props) => handleBillStatus(props) })
  const { getOrder, refreshPreOrder, setOrderChargeIdUsed } = usePreOrder({
    setErrorMsg,
  })
  const { globalNotification } = useFailedChannelTip({ visible })
  const {
    piListObj,
    piPaymentStatus,
    selectedPayinMethod,
    piListLoading,
    cardDetailLoadingId,
    piListReady,
    currentPIListKey,
    refreshPIList,
    renderPayinMethodExtra,
    handleSelectPayinMethod,
    getPaymentRefByPIMethod,
    clearPayinMethods,
    handleChangePIList,
    getSelectedPayinMethod,
  } = usePayinMethodList({
    trackSelectChannel,
    setErrorMsg,
    errorMsg,
  })

  /******
   * 能力及行为
   */

  // 使用弹窗
  const handleWindowClose = useCallback(() => {
    setPaying(false)
    setVisible(true)
  }, [setPaying, setVisible])
  const [linkOpen, linkClose] = useNewWindow(handleWindowClose)

  // 使用feelgood反馈返回
  const handleClose = useCallback(() => {
    setVisible(false)
    props.onClose?.(false)
    uPubSub.publish(PUBSUB_TYPES.MODAL_CANCEL, false)
  }, [uPubSub, props, setVisible])
  const { handleFBCancelClick, loadingFeelgood } = useFeelgoodBack({ errorMsg, onClose: handleClose })

  // 页面重置
  const clearPage = useCallback(() => {
    clearPayinMethods()
  }, [clearPayinMethods])

  // 支付加载及状态处理钩子
  const { statusProps, loadingProps, handleBillStatus, combinationStatusProps } = useStatusAndLoading({
    paying,
    setPaying,
    getSelectedPayinMethod,
    getOrder,
    setVisible,
    clearPage,
    onPayClose: props.onClose,
    handleFBCancelClick,
    linkClose,
  })
  // 使用支付码
  const { paymentCodeProps, openPaymentCode } = usePaymentCode({ getOrder, setPaying, handleBillStatus })
  // OTP验证Modal
  const { openCheckOTPModal, checkOTPModalProps } = useCheckOTPModal({
    onCancel: loadingProps.onCancel,
    handleBillStatus,
  })

  /******
   * Effects
   */

  // 录制用户操作
  usePayRecorder()

  // 页面初始化数据加载
  useEffect(() => {
    if (visible) {
      payLogger.scenes.payPageView()
      refreshBillInfo()
      refreshPIList()
      refreshPreOrder()
      trackViewChannel(SUBMIT_RECORD_TYPE.PAY_CHANNEL_VIEW)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  // 错误信息提示
  useEffect(() => {
    errorMsg && message.error(errorMsg)
  }, [errorMsg])

  /******
   * 页面生命周期相关方法
   */

  const handleSkip = useCallback(() => {
    payLogger.scenes.payPageClick({ clickItem: 'skip' })
    teaClient.sendCreatePageClick({ click_item: 'skip' })

    setVisible(false)
    props.onClose?.(false)
    uPubSub.publish(PUBSUB_TYPES.MODAL_CANCEL, true)
  }, [uPubSub, props, setVisible])

  // 监听postMessage
  usePostMessage((postData) => {
    const { upayType, query } = postData

    const { error_msg, bind_card_status, payment_status, from, error_code, actionType, combination } = query
    // 来自result结果页的通知
    if (upayType === PUBSUB_TYPES['3DS_RESULT']) {
      if (from === FROM_PAGE_ENUM.PAY_NEXT) {
        const isSuccess = [StatusTypeEnum.SUCCEED, StatusTypeEnum.SUCCESS].includes(bind_card_status || payment_status)
        linkClose()
        handleBillStatus({
          actionType: actionType || StatusActionTypeEnum.PAY,
          status: bind_card_status || payment_status,
          errMsg: error_msg,
          pipoErrorCode: error_code,
          combination: combination && isSuccess, //只有在绑卡成功的场景在会走到合并绑卡支付流程
        })
      }
    }
  })

  /******
   * 参数准备
   */

  //决策提交路径 支付/绑卡
  const isBindSubmit = useMemo(() => {
    // 未选卡 显示submit
    if (!selectedPayinMethod) return false
    // 后端指定绑卡
    if (selectedPayinMethod.paymentSubmitMethod === PaymentSubmitMethodEnum.BIND) return true
    // 新支付渠道、后端指定待定、支付方式决策需要绑卡
    if (
      !selectedPayinMethod.cardId &&
      selectedPayinMethod.paymentSubmitMethod === PaymentSubmitMethodEnum.DEPENDS &&
      piPaymentStatus[`${selectedPayinMethod.payWay}`]?.paymentSubmitMethod === PaymentSubmitMethodEnum.BIND
    )
      return true

    return false
  }, [piPaymentStatus, selectedPayinMethod])

  // 支付方式所需基础参数
  const paymentsMethodPropsBasic: IPaymentMethodsPropsBasic = useMemo(
    () => ({
      fromPage: FROM_PAGE_ENUM.PAY_NEXT,
      urlQuery,
      getOrder,
      handleBillStatus,
      linkOpen,
      openPaymentCode,
      openCheckOTPModal,
      closeLoading: loadingProps.onCancel,
    }),
    [getOrder, handleBillStatus, linkOpen, openCheckOTPModal, openPaymentCode, loadingProps.onCancel, urlQuery],
  )

  const { handleOpenUpdate, contentHolder } = useUpdate({
    from: FROM_PAGE_ENUM.PAY_NEXT,
    onClose: () => {
      setVisible(true)
    },
  })

  const handleClickUpdate = (card: CardListRes | IPayinMethodItem) => {
    setVisible(false)
    handleOpenUpdate(card)
  }

  return {
    PID,
    data: {
      visible,
      errorMsg,
      piListObj,
      piPaymentStatus,
      selectedPayinMethod,
      isIndependentPage,
      statusProps,
      combinationStatusProps,
      billInfo,
      globalNotification,
      loadingProps,
      paymentCodeProps,
      paymentsMethodPropsBasic,
      currentPIListKey,
      checkOTPModalProps,
      isBindSubmit,
      updateContentHolder: contentHolder,
    },
    loadStatus: { loadingFeelgood, piListLoading, cardDetailLoadingId, piListReady },
    func: {
      setVisible,
      handleBillStatus,
      renderPayinMethodExtra,
      handleSelectPayinMethod,
      handleFBCancelClick,
      setPaying,
      getOrder,
      refreshPreOrder,
      setOrderChargeIdUsed,
      getPaymentRefByPIMethod,
      openPaymentCode,
      linkOpen,
      handleSkip,
      handleChangePIList,
      getSelectedPayinMethod,
      handleClickUpdate,
    },
  }
}

export const PayNextModel = createModel(usePayNext)
