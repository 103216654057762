import { useContext, useEffect, useRef } from 'react'

import { ParamsContext } from '@/stores'

/** 数据处理方法 */
type Handler<T> = (data: any) => T

interface IUsePostMessage {
  <T = any>(handler?: Handler<T>): void
}

const usePostMessage: IUsePostMessage = (handler) => {
  const { unique } = useContext(ParamsContext)
  const handlerRef = useRef<any>(handler)
  useEffect(() => {
    handlerRef.current = handler
  }, [handler])
  useEffect(() => {
    const eventHandler = (e: MessageEvent) => {
      // 无有效数据
      if (!e?.data) return
      const { upayType, query } = e.data
      // 非upay消息
      if (!upayType) return
      // 非当前实例消息
      if (query.uniqueId && query.uniqueId !== unique) return

      handlerRef.current?.(e.data)
    }
    window.addEventListener('message', eventHandler)

    return () => window.removeEventListener('message', eventHandler)
  }, [unique])
}

export default usePostMessage
