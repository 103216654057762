import React, { useEffect, useState } from 'react'

import { useUIVoucher, useVoucher } from '@/pipo/hooks'
import { OrderStatus, PipoProvider, VoucherProps } from '@/pipo/utils'
import starling from '@/utils/starling'

import { AmountCard } from './amount-card'
import { BarCode } from './bar-code'
import { BoBankTransferCode } from './bo-banktransfer-code'
import { CashPlusCode } from './cash-plus-code'
import { ExpiredMask } from './expired-mask'
import { FawryCode } from './fawry-code'
import GenerateCommonCode from './generate-code'
import { JPBankTransferCode } from './jp-banktransfer-code'
import { OperationGuide } from './operation-guide'
import { OxxoCode } from './oxxo-code'
import { BanktransferMXVoucher, PromptPayVoucher } from './payment-methods-config'
import { PureCode } from './pure-code'
import { QrCode } from './qr-code'
import { TossBankTransferCode } from './toss-banktransfer-code'
import { TradingStatus } from './trading-status'
import { USBankTransferCode } from './us-banktransfer-code'

export type DefaultVoucherProps = ReturnType<typeof useVoucher>['defaultVoucherProps']

const Voucher = ({ paymentDetail, upayResult }: VoucherProps) => {
  const paymentMethodId = paymentDetail.payment_method_info?.payment_method_id

  const { state } = PipoProvider.useContext()
  const { countryOrRegion } = state

  const { defaultVoucherProps } = useVoucher(paymentDetail, upayResult)

  switch (paymentMethodId) {
    case 'pm_pi_cp_boleto_c_d':
      return <BoletoVoucher {...defaultVoucherProps} />
    case 'pm_pi_bap_pix_c_d':
      return <PixVoucher {...defaultVoucherProps} />
    case 'pm_pi_ew_gopay_c_r':
      return <GopayVoucher {...defaultVoucherProps} />
    case 'pm_pi_bt_banktransfertoss_c_d':
      return <TossBankTransferVoucher {...defaultVoucherProps} />
    case 'pm_pi_cp_fawry_c_d':
      return <FawryVoucher {...defaultVoucherProps} />
    case 'pm_pi_cp_oxxo_c_d':
      return <OxxoVoucher {...defaultVoucherProps} />
    case 'pm_pi_cp_cashpincashplus_c_d':
      return <CashPlusVoucher {...defaultVoucherProps} />
    case 'pm_pi_bap_promptpay_c_r':
      return <PromptPayVoucher {...defaultVoucherProps} />
    case 'pm_pi_bt_spei_c_d':
      return <BanktransferMXVoucher {...defaultVoucherProps} />

    case 'pm_pi_bt_banktransfer_c_d':
      if (countryOrRegion === 'TR') {
        return <BOBanktransferVoucher {...defaultVoucherProps} />
      } else if (countryOrRegion === 'JP') {
        return <JPBankTransferVoucher {...defaultVoucherProps} />
      } else if (countryOrRegion === 'US') {
        return <USBankTransferVoucher {...defaultVoucherProps} />
      } else {
        return <DefaultVoucher {...defaultVoucherProps} />
      }
    default:
      return <DefaultVoucher {...defaultVoucherProps} />
  }
}

function DefaultVoucher(props: DefaultVoucherProps) {
  const {
    amount,
    expiration_time: expirationTime,
    formatCode,
    payment_method_info: paymentMethodInfo,
    present_to_shopper_details: presentToShopperDetails,
    currency,
    merchant_order_id,
    upayResult,
  } = props.paymentDetail
  const { payment_method_id: paymentMethodId } = paymentMethodInfo!

  const voucherData = useUIVoucher({
    amount,
    currency,
    formatCode,
    paymentMethodId,
    shopperDetails: presentToShopperDetails,
  })

  const { onExpire } = props

  const [orderStatus, setOrderStatus] = useState<OrderStatus>('init')

  useEffect(() => {
    setOrderStatus(props.paymentDetail.orderStatus)
  }, [props.paymentDetail.orderStatus])

  return (
    <div className="pipo-pc-voucher">
      <React.Fragment>
        <TradingStatus
          orderStatus={orderStatus}
          upayResult={upayResult}
          expirationTime={expirationTime}
          onTimeUp={onExpire}
          orderNo={merchant_order_id}
        />
        <ExpiredMask orderStatus={orderStatus}>
          <PureCode data={voucherData} amount={amount} />
          {presentToShopperDetails.payment_code && formatCode && (
            <GenerateCommonCode paymentCode={presentToShopperDetails.payment_code} formatCode={formatCode} />
          )}
        </ExpiredMask>
        <OperationGuide paymentMethodInfo={voucherData.paymentMethodInfo} />
      </React.Fragment>
    </div>
  )
}

const BoletoVoucher = (props: DefaultVoucherProps) => {
  const {
    amount,
    expiration_time: expirationTime,
    present_to_shopper_details: presentToShopperDetails,
    merchant_order_id,
    upayResult,
    fundArrivalTips,
  } = props.paymentDetail

  const { onExpire } = props

  const [orderStatus, setOrderStatus] = useState<OrderStatus>('init')

  useEffect(() => {
    setOrderStatus(props.paymentDetail.orderStatus)
  }, [props.paymentDetail.orderStatus])

  return (
    <div className="pipo-pc-voucher">
      <React.Fragment>
        <TradingStatus
          orderStatus={orderStatus}
          upayResult={upayResult}
          expirationTime={expirationTime}
          onTimeUp={onExpire}
          orderNo={merchant_order_id}
          fundArrivalTips={fundArrivalTips}
        />
        <ExpiredMask orderStatus={orderStatus}>
          <AmountCard title={starling('cg.upay.exception.channel_title_Boleto')} amount={amount} iconUrl={presentToShopperDetails?.icon_url} />
          <BarCode shopperDetails={presentToShopperDetails} />
        </ExpiredMask>
      </React.Fragment>
    </div>
  )
}

const PixVoucher = (props: DefaultVoucherProps) => {
  const {
    amount,
    expiration_time: expirationTime,
    present_to_shopper_details: presentToShopperDetails,
    merchant_order_id,
    upayResult,
  } = props.paymentDetail

  const { onExpire } = props

  const [orderStatus, setOrderStatus] = useState<OrderStatus>('init')

  useEffect(() => {
    setOrderStatus(props.paymentDetail.orderStatus)
  }, [props.paymentDetail.orderStatus])

  return (
    <div className="pipo-pc-voucher">
      <React.Fragment>
        <TradingStatus
          orderStatus={orderStatus}
          upayResult={upayResult}
          expirationTime={expirationTime}
          onTimeUp={onExpire}
          orderNo={merchant_order_id}
        />
        <ExpiredMask orderStatus={orderStatus}>
          <AmountCard title={starling('cg.upay.channel_title_PIX')} amount={amount} iconUrl={presentToShopperDetails?.icon_url} />
          <QrCode shopperDetails={presentToShopperDetails} />
        </ExpiredMask>
      </React.Fragment>
    </div>
  )
}

const GopayVoucher = (props: DefaultVoucherProps) => {
  const {
    amount,
    orderStatus,
    expiration_time: expirationTime,
    present_to_shopper_details: presentToShopperDetails,
    payment_method_info: paymentMethodInfo,
    merchant_order_id,
    upayResult,
    currency,
    formatCode,
  } = props.paymentDetail

  const { payment_method_id: paymentMethodId, payment_method_type: paymentMethodType } = paymentMethodInfo!
  const { onExpire } = props

  const voucherData = useUIVoucher({
    amount,
    currency,
    formatCode,
    paymentMethodId,
    shopperDetails: presentToShopperDetails,
  })

  return (
    <div className="pipo-pc-voucher">
      <React.Fragment>
        <TradingStatus
          orderStatus={orderStatus}
          upayResult={upayResult}
          expirationTime={expirationTime}
          onTimeUp={onExpire}
          orderNo={merchant_order_id}
        />
        <ExpiredMask orderStatus={orderStatus}>
          <AmountCard title={starling('cg.upay.exception.channel_title_GoPay')} amount={amount} iconUrl={presentToShopperDetails?.icon_url} />
          <QrCode paymentMethodId={paymentMethodId} shopperDetails={presentToShopperDetails} />
        </ExpiredMask>
        <OperationGuide paymentMethodInfo={voucherData.paymentMethodInfo} />
      </React.Fragment>
    </div>
  )
}

function BOBanktransferVoucher(props: DefaultVoucherProps) {
  const {
    amount,
    expiration_time: expirationTime,
    formatCode,
    payment_method_info: paymentMethodInfo,
    present_to_shopper_details: presentToShopperDetails,
    currency,
    upayResult,
    userIban,
    merchant_order_id,
  } = props.paymentDetail
  // @ts-ignore
  const { payment_method_id: paymentMethodId } = paymentMethodInfo!
  const voucherData = useUIVoucher({
    amount,
    currency,
    formatCode,
    paymentMethodId,
    shopperDetails: presentToShopperDetails,
  })

  const { onExpire } = props

  const [orderStatus, setOrderStatus] = useState<OrderStatus>('init')

  useEffect(() => {
    setOrderStatus(props.paymentDetail.orderStatus)
  }, [props.paymentDetail.orderStatus])

  return (
    <div className="pipo-pc-voucher">
      <React.Fragment>
        <TradingStatus
          orderStatus={orderStatus}
          upayResult={upayResult}
          expirationTime={expirationTime}
          onTimeUp={onExpire}
          orderNo={merchant_order_id}
        />
        <ExpiredMask orderStatus={orderStatus}>
          <BoBankTransferCode data={voucherData} userIban={userIban} />
          {presentToShopperDetails.payment_code && formatCode && (
            <GenerateCommonCode paymentCode={presentToShopperDetails.payment_code} formatCode={formatCode} />
          )}
        </ExpiredMask>
      </React.Fragment>
    </div>
  )
}

function TossBankTransferVoucher(props: DefaultVoucherProps) {
  const {
    amount,
    expiration_time: expirationTime,
    formatCode,
    payment_method_info: paymentMethodInfo,
    present_to_shopper_details: presentToShopperDetails,
    currency,
    upayResult,
    merchant_order_id,
  } = props.paymentDetail
  // @ts-ignore
  const { payment_method_id: paymentMethodId } = paymentMethodInfo!
  const voucherData = useUIVoucher({
    amount,
    currency,
    formatCode,
    paymentMethodId,
    shopperDetails: presentToShopperDetails,
  })

  const { onExpire } = props

  const [orderStatus, setOrderStatus] = useState<OrderStatus>('init')

  useEffect(() => {
    setOrderStatus(props.paymentDetail.orderStatus)
  }, [props.paymentDetail.orderStatus])

  return (
    <div className="pipo-pc-voucher">
      <React.Fragment>
        <TradingStatus
          orderStatus={orderStatus}
          upayResult={upayResult}
          expirationTime={expirationTime}
          onTimeUp={onExpire}
          orderNo={merchant_order_id}
        />
        <ExpiredMask orderStatus={orderStatus}>
          <TossBankTransferCode data={voucherData} />
          {presentToShopperDetails.payment_code && formatCode && (
            <GenerateCommonCode paymentCode={presentToShopperDetails.payment_code} formatCode={formatCode} />
          )}
          <OperationGuide paymentMethodInfo={voucherData.paymentMethodInfo} />
        </ExpiredMask>
      </React.Fragment>
    </div>
  )
}

const FawryVoucher = (props: DefaultVoucherProps) => {
  const {
    amount,
    expiration_time: expirationTime,
    formatCode,
    payment_method_info: paymentMethodInfo,
    present_to_shopper_details: presentToShopperDetails,
    currency,
    upayResult,
    merchant_order_id,
  } = props.paymentDetail
  const { payment_method_id: paymentMethodId } = paymentMethodInfo!
  const voucherData = useUIVoucher({
    amount,
    currency,
    formatCode,
    paymentMethodId,
    shopperDetails: presentToShopperDetails,
  })

  const { onExpire } = props

  const [orderStatus, setOrderStatus] = useState<OrderStatus>('init')
  useEffect(() => {
    setOrderStatus(props.paymentDetail.orderStatus)
  }, [props.paymentDetail.orderStatus])

  return (
    <div className="pipo-pc-voucher">
      <React.Fragment>
        <TradingStatus
          orderStatus={orderStatus}
          upayResult={upayResult}
          expirationTime={expirationTime}
          onTimeUp={onExpire}
          orderNo={merchant_order_id}
        />
        <ExpiredMask orderStatus={orderStatus}>
          <FawryCode data={voucherData} />
          {presentToShopperDetails.payment_code && formatCode && (
            <GenerateCommonCode paymentCode={presentToShopperDetails.payment_code} formatCode={formatCode} />
          )}
        </ExpiredMask>
      </React.Fragment>
    </div>
  )
}

const JPBankTransferVoucher = (props: DefaultVoucherProps) => {
  const {
    amount,
    expiration_time: expirationTime,
    formatCode,
    payment_method_info: paymentMethodInfo,
    present_to_shopper_details: presentToShopperDetails,
    currency,
    upayResult,
    extraInfoUrl,
    merchant_order_id,
  } = props.paymentDetail
  const { payment_method_id: paymentMethodId } = paymentMethodInfo!

  const voucherData = useUIVoucher({
    amount,
    currency,
    formatCode,
    paymentMethodId,
    shopperDetails: presentToShopperDetails,
  })

  const { onExpire } = props

  const [orderStatus, setOrderStatus] = useState<OrderStatus>('init')
  useEffect(() => {
    setOrderStatus(props.paymentDetail.orderStatus)
  }, [props.paymentDetail.orderStatus])

  return (
    <div className="pipo-pc-voucher">
      <React.Fragment>
        <TradingStatus
          orderStatus={orderStatus}
          upayResult={upayResult}
          expirationTime={expirationTime}
          onTimeUp={onExpire}
          orderNo={merchant_order_id}
        />
        <ExpiredMask orderStatus={orderStatus}>
          <JPBankTransferCode data={voucherData} currency={currency} extraInfoUrl={extraInfoUrl || ''} />
        </ExpiredMask>
      </React.Fragment>
    </div>
  )
}

const OxxoVoucher = (props: DefaultVoucherProps) => {
  const {
    amount,
    expiration_time: expirationTime,
    formatCode,
    payment_method_info: paymentMethodInfo,
    present_to_shopper_details: presentToShopperDetails,
    currency,
    upayResult,
    merchant_order_id,
  } = props.paymentDetail
  const { payment_method_id: paymentMethodId } = paymentMethodInfo!
  const voucherData = useUIVoucher({
    amount,
    currency,
    formatCode,
    paymentMethodId,
    shopperDetails: presentToShopperDetails,
  })

  const { onExpire } = props

  const [orderStatus, setOrderStatus] = useState<OrderStatus>('init')
  useEffect(() => {
    setOrderStatus(props.paymentDetail.orderStatus)
  }, [props.paymentDetail.orderStatus])

  return (
    <div className="pipo-pc-voucher">
      <React.Fragment>
        <TradingStatus
          orderStatus={orderStatus}
          upayResult={upayResult}
          expirationTime={expirationTime}
          onTimeUp={onExpire}
          orderNo={merchant_order_id}
        />
        <ExpiredMask orderStatus={orderStatus}>
          <OxxoCode data={voucherData} />
        </ExpiredMask>
      </React.Fragment>
    </div>
  )
}
const USBankTransferVoucher = (props: DefaultVoucherProps) => {
  const {
    amount,
    expiration_time: expirationTime,
    formatCode,
    payment_method_info: paymentMethodInfo,
    present_to_shopper_details: presentToShopperDetails,
    currency,
    upayResult,
    extraInfoUrl,
    merchant_order_id,
  } = props.paymentDetail
  const { payment_method_id: paymentMethodId } = paymentMethodInfo!

  const voucherData = useUIVoucher({
    amount,
    currency,
    formatCode,
    paymentMethodId,
    shopperDetails: presentToShopperDetails,
  })

  const { onExpire } = props

  const [orderStatus, setOrderStatus] = useState<OrderStatus>('init')
  useEffect(() => {
    setOrderStatus(props.paymentDetail.orderStatus)
  }, [props.paymentDetail.orderStatus])

  return (
    <div className="pipo-pc-voucher">
      <React.Fragment>
        <TradingStatus
          orderStatus={orderStatus}
          upayResult={upayResult}
          expirationTime={expirationTime}
          onTimeUp={onExpire}
          orderNo={merchant_order_id}
        />
        <ExpiredMask orderStatus={orderStatus}>
          <USBankTransferCode data={voucherData} currency={currency} />
        </ExpiredMask>
      </React.Fragment>
    </div>
  )
}

const CashPlusVoucher = (props: DefaultVoucherProps) => {
  const {
    amount,
    expiration_time: expirationTime,
    formatCode,
    payment_method_info: paymentMethodInfo,
    present_to_shopper_details: presentToShopperDetails,
    currency,
    upayResult,
    merchant_order_id,
  } = props.paymentDetail
  const { payment_method_id: paymentMethodId } = paymentMethodInfo!
  const voucherData = useUIVoucher({
    amount,
    currency,
    formatCode,
    paymentMethodId,
    shopperDetails: presentToShopperDetails,
  })

  const { onExpire } = props

  const [orderStatus, setOrderStatus] = useState<OrderStatus>('init')
  useEffect(() => {
    setOrderStatus(props.paymentDetail.orderStatus)
  }, [props.paymentDetail.orderStatus])

  return (
    <div className="pipo-pc-voucher">
      <React.Fragment>
        <TradingStatus
          orderStatus={orderStatus}
          upayResult={upayResult}
          expirationTime={expirationTime}
          onTimeUp={onExpire}
          orderNo={merchant_order_id}
        />
        <ExpiredMask orderStatus={orderStatus}>
          <CashPlusCode data={voucherData} />
        </ExpiredMask>
      </React.Fragment>
    </div>
  )
}

export { Voucher }
