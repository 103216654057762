import React, { useState } from 'react'

import { useClickOutside } from '@byted/hooks'
import { More, Pin } from '@icon-park/react'
import clsx from 'clsx'

import { PAY_WAY_TYPE } from '@/constant'
import { CardListRes } from '@/services/types'
import starling from '@/utils/starling'
import teaClient from '@/utils/tea'

import { ManageCardTypeEnum } from '../../types'
import ActionModal from '../ActionModal'

import './index.less'

interface IProps {
  card: CardListRes
  onAction: (type: ManageCardTypeEnum) => void
}

const CardBase: React.FC<IProps> = (props) => {
  const { card, onAction } = props
  const { valid, imageUrl, identityType, identityNo, expireTime, primary, expired, payWay, allowUpdate } = card

  const [actionModalVisible, setActionModalVisible] = useState(false)
  const cardRef = useClickOutside(undefined, () => {
    setActionModalVisible(false)
  })

  const handleClickMore = () => {
    teaClient.sendManagerPageClick({ pay_way: payWay, click_item: 'see_more' })
    setActionModalVisible(true)
  }

  const renderTips = () => {
    if (payWay === PAY_WAY_TYPE.EXISTED_CARD && allowUpdate && expired) {
      return (
        <>
          <span className="upay-color-error">
            {starling('cg.upay.comm.expired_expiry_date', {
              expiry_date: expireTime,
            })}
          </span>{' '}
          <span className="upay-link" onClick={() => onAction(ManageCardTypeEnum.EditCard)}>
            {starling('cg.upay.comm.expired_update_pi')}
          </span>
        </>
      )
    } else if (payWay === PAY_WAY_TYPE.BOLETO && allowUpdate) {
      return (
        <>
          <span>{starling('cg.upay.comm.update_boleto_prompt')}</span>{' '}
          <span className="upay-link" onClick={() => onAction(ManageCardTypeEnum.EditCard)}>
            {starling('cg.upay.comm.update_boleto_add_email_link')}
          </span>
        </>
      )
    } else if (valid && expireTime) {
      return `${starling('funds.refund.comm.manage_expriy_date')} ${expireTime}`
    } else if (!valid) {
      return starling('funds.refund.comm.invalid')
    }
  }

  return (
    <div
      ref={cardRef}
      className={clsx('upay-manager-card', { 'upay-manager-card-disable': !valid, 'upay-manager-card-primary': primary })}
      id={`upay-card-${identityNo?.slice(-4)}`}
    >
      <div className="upay-manager-card-image">
        <img src={imageUrl} alt="icon" />
      </div>

      {primary && (
        <div className="upay-manager-card-primary-icon">
          <Pin fill="#fff" />
        </div>
      )}
      <More fill="#333333" className="upay-manager-card-manage-btn" onClick={handleClickMore} />

      <div className="upay-manager-card-no">
        {identityType || ''} {identityNo}
      </div>

      <div className="upay-manager-card-tips">{renderTips()}</div>

      <ActionModal visible={actionModalVisible} card={card} allowUpdate={allowUpdate} onAction={onAction} />
    </div>
  )
}

export default CardBase
