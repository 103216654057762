"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault")["default"];
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.countryToISOCode = countryToISOCode;
exports.getFormatAddressData = getFormatAddressData;
var _find = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/find"));
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/slicedToArray"));
/**
 * Format address before submit
 */
function getFormatAddressData(value, countryList) {
  var arr = value.split(', ');
  if (arr.length > 1) {
    var _countryList$find;
    var _arr = (0, _slicedToArray2["default"])(arr, 3),
      countryName = _arr[0],
      state = _arr[1],
      city = _arr[2];
    var countryCode = (countryList === null || countryList === void 0 ? void 0 : (_countryList$find = (0, _find["default"])(countryList).call(countryList, function (item) {
      return item.name === countryName;
    })) === null || _countryList$find === void 0 ? void 0 : _countryList$find.code) || countryName;
    return {
      countryCode: countryCode,
      state: state,
      city: city
    };
  }
  return {
    countryCode: '',
    state: '',
    city: ''
  };
}
/**
 * transfer country name to iso code
 */
function countryToISOCode(countryName, countryList) {
  var _countryList$find2;
  return (countryList === null || countryList === void 0 ? void 0 : (_countryList$find2 = (0, _find["default"])(countryList).call(countryList, function (item) {
    return item.name === countryName;
  })) === null || _countryList$find2 === void 0 ? void 0 : _countryList$find2.code) || countryName;
}