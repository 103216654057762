import { PAYOUT_WAY_TYPE } from '@/constant/payout'
import { withController } from '@/pages/Payout/payout-widgets/withController'
import { BankAccountUpdate, LocalBankTransferUpdate } from '@/pipo/pc-payout'

const PAY_WAY_EXTRA_CHANNEL_MAP: Record<number, React.FC<any>> = {
  [PAYOUT_WAY_TYPE.LOCAL_BANK_TRANSFER]: withController(LocalBankTransferUpdate),
  [PAYOUT_WAY_TYPE.BANK_ACCOUNT]: withController(BankAccountUpdate),
}

export const hasExtraContent = (payWay: number): boolean => {
  return payWay in PAY_WAY_EXTRA_CHANNEL_MAP
}

export const getExtraComponent = (payWay: number) => {
  return PAY_WAY_EXTRA_CHANNEL_MAP[payWay] || null
}
