import { useRef, useEffect } from 'react'
import { useTimer } from '@byted/hooks'

interface IProps {
  expirationTime?: string
  onTimeUp?: () => void
}

const useCountdownTimer = (props: IProps) => {
  const { expirationTime = '', onTimeUp } = props
  const time = useRef(new Date(expirationTime))

  const { days, hours, minutes, seconds } = useTimer<Date>(time.current, {
    onComplete: onTimeUp,
    isCountDown: true,
  })

  useEffect(() => {
    time.current = new Date(expirationTime)
  }, [expirationTime])

  if (!expirationTime) return {}

  return {
    hours: String(parseInt(days) * 24 + parseInt(hours)),
    minutes,
    seconds,
  }
}

export { useCountdownTimer }
