import React, { useEffect } from 'react'
import { FieldError } from 'react-hook-form'

import { omit } from 'lodash'

import { CustomInput, ItemBlock } from '../index'

interface IProps {
  title: string
  tips?: React.ReactNode
  maxLength?: number
  len?: 'half' | 'full'
  placeholder?: string
  error?: FieldError
  errorMessage: string
  value?: string
  onChange: (val: string) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void
  formatter?: (preValue: string) => string
}

const prefix = 'pipo-pc-input'

/**
 * 通用的文字录入组件
 */
export const ItemInput = React.forwardRef((props: IProps, ref: any) => {
  const { title, tips, len = 'half', error, errorMessage, placeholder, value, onChange, onBlur, formatter } = props
  const itemBlockProps = { title, tips, error, errorMessage, style: len }

  const inputProps = omit(props, Object.keys(itemBlockProps))

  const handleChange = (e: string) => {
    onChange(e)
  }

  return (
    <ItemBlock {...itemBlockProps} errorState={Boolean(error)}>
      <CustomInput
        {...inputProps}
        ref={ref}
        className={prefix}
        size="large"
        allowClear
        value={value}
        error={Boolean(error)}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={onBlur}
        formatValue={formatter}
      />
    </ItemBlock>
  )
})
