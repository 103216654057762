import React, { useContext, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Button, Skeleton } from '@arco-design/web-react'
import { Plus } from '@icon-park/react'
import { StatusTypeEnum } from '@upay/utils/es/types'

import { Loading, PageModal, Policy, SecurityTip } from '@/components'
import { FROM_PAGE_ENUM, PUBSUB_TYPES } from '@/constant'
import { IPayinMethodItem } from '@/pages/PayNext/interface'
import { IOnChangeProps, PayoutComponentRef } from '@/pipo/pc-payout/types'
import api from '@/services'
import { ParamsContext } from '@/stores'
import GlobalInfo from '@/stores/GlobalInfo'
import { ModeTypeEnum } from '@/typings'
import SafeJSON from '@/utils/SafeJSON'
import { oneTimeHoc } from '@/utils/oneTimeHoc'
import starling from '@/utils/starling'

import PayoutStatus from '../Status'
import PaymentList from '../components/PaymentList'
import TotalAmount from '../components/TotalAmount'
import { PayoutContext } from '../context'
import { useCardHolderInfo } from '../hooks'
import { encryptFormData, getItemKey } from '../utils'
import { useBillInfo, usePaymentList } from './hooks'

import './index.less'

enum PaymentListEnum {
  FoldedList = 'foldedPIList',
  UnfoldedList = 'unfoldedPIList',
}
interface IProps {
  onClose?: () => void
  from?: FROM_PAGE_ENUM
}
const PayoutPay: React.FC<IProps> = (props) => {
  const { from, onClose: onClosePage } = props
  const {
    params,
    urlQuery: { token },
    uPubSub,
  } = useContext(ParamsContext)
  const {
    data: { riskInfoObj },
    utils: { getPublicKeyAsync },
  } = useContext(GlobalInfo)
  const isIndependentPage = from === FROM_PAGE_ENUM.PAYOUT && params.mode === ModeTypeEnum.HostedPage
  const [innerVisible, setInnerVisible] = useState(true)
  const [posting, setPosting] = useState(false)
  const [statusVisible, setStatusVisible] = useState(false)
  const [statusProps, setStatusProps] = useState<{ initStatus?: StatusTypeEnum; initErrorMsg?: string }>({})
  const [activeList, setActiveList] = useState(PaymentListEnum.UnfoldedList)

  const { control, watch } = useForm()
  const [selectedItem, setSelectedItem] = useState<IPayinMethodItem>()
  const activeKey = getItemKey(selectedItem) // 内部使用
  const activeItem = watch(activeKey) as IOnChangeProps

  const outerFormRef = useRef(new Map()) // 缓存所有子项表单

  const { paymentList, getPaymentListLoading } = usePaymentList({
    onSuccess: (data) => {
      invokeSelectDefault(data?.unfoldedPIList)
    },
  })
  const { billInfo, billInfoLoading } = useBillInfo()
  const cardHolderInfo = useCardHolderInfo()
  const disabled = getPaymentListLoading || billInfoLoading || !selectedItem || activeItem?.isValid === false

  const handleSwitchList = (type: PaymentListEnum) => {
    setActiveList(type)
    setSelectedItem(undefined)

    invokeSelectDefault(type === PaymentListEnum.FoldedList ? paymentList?.foldedPIList : paymentList?.unfoldedPIList)
  }

  const invokeSelectDefault = (list: IPayinMethodItem[] | undefined) => {
    if (!list?.length) return

    const autoSelectedCard = list.filter((item) => item.cardId && !item.disable)[0] || null
    autoSelectedCard && setSelectedItem(autoSelectedCard)
  }

  const handleSubmitClick = async () => {
    if (!selectedItem || !activeKey) return

    try {
      setInnerVisible(false)
      setPosting(true)

      // 新支付渠道参数
      let submitData: any = {}
      if (!selectedItem?.cardId) {
        const activeForm: PayoutComponentRef = outerFormRef.current.get(activeKey)?.current
        submitData = activeForm?.getSubmitData()
      }

      const publicKey = await getPublicKeyAsync()
      await api.payoutSubmitOrder({
        payWay: selectedItem.payWay,
        cardId: selectedItem?.cardId || '',
        riskInfo: SafeJSON.stringify(riskInfoObj),
        token,
        channelParameter: !selectedItem?.cardId
          ? JSON.stringify({
              detailContentEncrypted: SafeJSON.stringify(encryptFormData(submitData.formData, publicKey)),
              bankCode: submitData.bankCode,
              userType: submitData.userType,
            })
          : '',
        terminalEquipStr: 'SDK',
      })
    } catch (e: any) {
      setStatusProps({ initStatus: StatusTypeEnum.FAILED, initErrorMsg: e?.msg || '' })
    } finally {
      setPosting(false)
      setStatusVisible(true)
    }
  }

  const handleClickClose = () => {
    // 如果当前展示的是折叠列表，则退回展示未折叠列表
    if (activeList === PaymentListEnum.FoldedList) {
      handleSwitchList(PaymentListEnum.UnfoldedList)
      return
    }

    uPubSub.publish(PUBSUB_TYPES.MODAL_CANCEL, false)
    handleFinish()
  }

  const handleFinish = () => {
    setInnerVisible(false)
    setStatusProps({})
    setStatusVisible(false)
    onClosePage?.()
  }

  const renderFooter = () => {
    return (
      <div className="upay-modal-footer">
        <div className="upay-modal-footer-left">
          <Button onClick={handleClickClose}>{starling('funds.refund.comm.back')}</Button>
        </div>
        <div className="upay-modal-footer-right">
          <Button type="primary" disabled={disabled} loading={posting} onClick={handleSubmitClick}>
            {starling('funds.refund.comm.submit')}
          </Button>
        </div>
      </div>
    )
  }
  return (
    <div className="upay-payout">
      <PageModal
        visible={isIndependentPage || innerVisible}
        contentHeight={params.height}
        mode={isIndependentPage && params.mode}
        className="upay-payout-modal upay-modal"
        footer={renderFooter()}
        closable={false}
        alignCenter={false}
      >
        <TotalAmount billInfo={billInfo} />
        <SecurityTip />

        <Skeleton
          className="upay-payout-skeleton"
          loading={getPaymentListLoading}
          animation
          text={{ width: ['260px', '100%', '100%', '60%'], rows: 4 }}
        >
          <PayoutContext.Provider
            value={{
              control,
              outerFormRef,
              cardHolderInfo,
            }}
          >
            <PaymentList
              dataSource={activeList === PaymentListEnum.UnfoldedList ? paymentList?.unfoldedPIList : paymentList?.foldedPIList}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
            />
          </PayoutContext.Provider>

          {paymentList?.foldedPIList?.length > 0 && activeList === PaymentListEnum.UnfoldedList && (
            <div className="upay-use-new-btn" onClick={() => handleSwitchList(PaymentListEnum.FoldedList)}>
              <Plus size={16} />
              <span>{starling('funds.check_out.comm.withdraw_with_new_pi')}</span>
              {paymentList?.foldedPIList.map((method) => (
                <img src={method.imageUrl} key={method.payWay} className="upay-use-new-method-icon" />
              ))}
            </div>
          )}
        </Skeleton>

        <Policy policyUrl={params.policyUrl} />
      </PageModal>

      <Loading show={posting} showBtn={false} />
      <PayoutStatus visible={statusVisible} onOk={handleFinish} {...statusProps} />
    </div>
  )
}

export default oneTimeHoc(PayoutPay)
