import React, { FC, useContext, useEffect, useState } from 'react'

import { Skeleton } from '@arco-design/web-react'
import { IconPlus, IconSwap } from '@arco-design/web-react/icon'
import { useRequest } from '@byted/hooks'

import { FROM_PAGE_ENUM, PUBSUB_TYPES } from '@/constant'
import { useReportLoadTime } from '@/hooks/useReportLoadTime'
import api from '@/services'
import { ParamsContext } from '@/stores'
import GlobalInfo from '@/stores/GlobalInfo'
import { oneTimeHoc } from '@/utils/oneTimeHoc'
import slardar from '@/utils/slardar'
import starling from '@/utils/starling'
import teaClient from '@/utils/tea'

import Create from '../Create'
import ErrorReset from './components/ErrorReset'
import PayinCard from './components/PayinCard'
import { useTeaEvent } from './hooks/useTeaEvent'
import { PaySwitchEnum } from './types'

import './index.less'

interface IProps {
  onChangeType: () => void
}
const PayinManager: FC<IProps> = (props) => {
  const { onChangeType } = props
  const { params, urlQuery, uPubSub } = useContext(ParamsContext)
  const {
    utils: { refreshSession },
  } = useContext(GlobalInfo)
  const { hideTitle } = params
  const { token, paySwitch } = urlQuery

  const [nextPrimaryCardNo, setNextPrimaryCardNo] = useState<string>('') // 设置首选卡时提示给用户
  const [createModalVisible, setCreateModalVisible] = useState(false)

  // 加载耗时上报
  const reportLoadTime = useReportLoadTime('manager')
  const { sendManagerPageView } = useTeaEvent()

  const {
    data: cardList,
    loading,
    refresh: refreshList,
  } = useRequest(() => api.getCardList({ token }).then((res) => res?.list), {
    auto: true,
    onSuccess: (list) => {
      reportLoadTime()
      sendManagerPageView({ saved_pi_num: list?.length || 0 })

      const validList = list?.filter((card) => !card.primary && card.valid) || []
      const nextCardNum = (validList.length && validList[0].identityNo) || ''
      setNextPrimaryCardNo(nextCardNum.slice(-4)) // 取后四位卡号
    },
  })

  useEffect(() => {
    const subId = uPubSub.subscribe(PUBSUB_TYPES.REFRESH_MANAGER_LIST, refreshList)

    return () => uPubSub.unsubscribe(subId)
  }, [refreshList, uPubSub])

  useEffect(() => {
    // 页面渲染埋点
    slardar.timer('managerPageView', 1, { props: { params, urlQuery } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const openCreateModal = () => {
    slardar.timer('managerCreateMethodClick')
    teaClient.sendManagerPageClick({ click_item: 'add_new' })

    setCreateModalVisible(true)
  }

  const closeCreateModal = (needRefreshList = false) => {
    needRefreshList && refreshList()
    refreshSession()
    setCreateModalVisible(false)
  }

  return (
    <div className="upay-payin-manager">
      <div className="upay-manager-header">
        {/* Title */}
        <div className="upay-manager-title">{hideTitle ? '' : starling('funds.refund.comm.manage_payment_mothod')}</div>

        {/* 切换按钮 */}
        {paySwitch === PaySwitchEnum.Show && (
          <div className="upay-manager-switch" onClick={() => onChangeType()}>
            <IconSwap />
            {starling('funds.refund.comm.managewithdraw_paymentmethods')}
          </div>
        )}
      </div>

      {/* 卡列表 */}
      <ErrorReset>
        <Skeleton loading={loading} animation text={{ rows: 5 }} style={{ height: 143, overflow: 'hidden' }}>
          <div className="upay-manager-card-list">
            {cardList?.map((card) => {
              return <PayinCard key={card.id} card={card} refreshList={refreshList} nextPrimaryCardNo={nextPrimaryCardNo} />
            })}

            <div className="upay-manager-card-add" onClick={openCreateModal}>
              <IconPlus fontSize={18} />
              {starling('funds.refund.comm.add_a_card')}
            </div>
          </div>
        </Skeleton>
      </ErrorReset>

      <Create visible={createModalVisible} onClose={closeCreateModal} from={FROM_PAGE_ENUM.CREATE_IN_MANAGER} />
    </div>
  )
}

export default oneTimeHoc(PayinManager)
