"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault")["default"];
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.setCookie = exports.getCookie = void 0;
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var getCookie = function getCookie(key) {
  return _jsCookie["default"].get(key);
};
exports.getCookie = getCookie;
var setCookie = function setCookie(key, value) {
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  return _jsCookie["default"].set(key, value, options);
};
exports.setCookie = setCookie;