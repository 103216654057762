import React, { forwardRef, useEffect, useState } from 'react'

import { Checkbox } from '@arco-design/web-react'

import { PaymentSubmitMethodEnum } from '@/pages/PayNext/interface'
import { IPaymentMethodsProps, IPaymentsMethodRef } from '@/payments/interface'
import starling from '@/utils/starling'

import './style.less'

const DecisionSubmitMethod = forwardRef<IPaymentsMethodRef, IPaymentMethodsProps>((props): React.ReactElement => {
  const { changeNewPaymentStatus, channel } = props
  const [saveAccount, setSaveAccount] = useState(true)

  useEffect(() => {
    changeNewPaymentStatus(channel.payWay, {
      valid: true,
      paymentSubmitMethod: saveAccount ? PaymentSubmitMethodEnum.BIND : PaymentSubmitMethodEnum.PAYMENT,
    })
  }, [changeNewPaymentStatus, channel.payWay, saveAccount])

  return (
    <div className="upay-decision-submit-container">
      <Checkbox style={{ paddingLeft: 0 }} checked={saveAccount} onChange={setSaveAccount}>
        {starling('funds.refund.comm.system_save_card_information')}
      </Checkbox>
    </div>
  )
})

export default DecisionSubmitMethod
