import React, { useCallback, useContext, useEffect, useRef } from 'react'

import { usePostRecord } from '@/hooks'
import useBindTrack from '@/hooks/useBindTrack'
import { PipoProvider } from '@/pipo/pc'

import { ParamsContext } from '.'
import { useContractAddress } from './hooks/useContractAddress'
import { usePIPOApi } from './hooks/usePIPOApi'
import { usePassportTicket } from './hooks/usePassportTicket'
import { usePublicKey } from './hooks/usePublicKey'
import { useRiskInfo } from './hooks/useRiskInfo'
import { IGlobalInfo } from './interface'

const useGlobalInfo = (): IGlobalInfo => {
  const {
    urlQuery: { upaySessionId },
  } = useContext(ParamsContext)
  const lastBindCardId = useRef('')
  //发起绑卡提交后所对应的卡ID
  const { submitRecord } = usePostRecord()
  // 跟踪用户绑卡操作
  const { trackSelectChannel, trackViewChannel, channelFingerprint, sessionId, refreshSession } = useBindTrack({ submitRecord, upaySessionId })

  const getPublicKeyAsync = usePublicKey()

  const getPassportTicketAsync = usePassportTicket()
  const { PIPOApi, pipoProviderOptions } = usePIPOApi({
    getPassportTicketAsync,
  })
  const getContractAddressAsync = useContractAddress()
  const riskInfoObj = useRiskInfo()

  return {
    data: {
      pipoProviderOptions,
      lastBindCardId,
      channelFingerprint,
      sessionId,
      riskInfoObj,
    },
    utils: {
      refreshSession,
      submitRecord,
      trackSelectChannel,
      trackViewChannel,
      getPublicKeyAsync,
      getContractAddressAsync,
    },
    PIPOApi,
  }
}

const GlobalInfo = React.createContext<IGlobalInfo>({} as IGlobalInfo)

export const GlobalInfoProvider: React.FC<{ children: React.ReactNode }> = (props) => {
  const { children } = props
  const globalInfoValue = useGlobalInfo()
  return (
    <GlobalInfo.Provider value={globalInfoValue}>
      <PipoProvider.Provider options={globalInfoValue.data.pipoProviderOptions}>{children}</PipoProvider.Provider>
    </GlobalInfo.Provider>
  )
}

export default GlobalInfo
