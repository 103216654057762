/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react'

import { ISubmitOrderRes, PipoRequestErrorObj, StatusActionTypeEnum, StatusTypeEnum } from '@upay/utils/es/types'

import { CVVForm } from '@/components'
import { paymentsLogger } from '@/payments'
import GlobalInfo from '@/stores/GlobalInfo'

import { IExcitedPaymentMethodsProps, IPaymentsMethodRef } from '../../interface'

/**
 * 已绑定部分卡片需要输入CVV
 */
const CVV = forwardRef<IPaymentsMethodRef, IExcitedPaymentMethodsProps>((props, ref): React.ReactElement => {
  const { fromPage, card, getOrder, changeExcitedPaymentStatus, handleBillStatus, linkOpen } = props
  const {
    PIPOApi: { paySubmitByCvvOneStep },
  } = useContext(GlobalInfo)
  const [cvvValid, setCvvValid] = useState(false)
  const [cvvCode, setCvvCode] = useState('')

  // 同步验证状态
  useEffect(() => {
    changeExcitedPaymentStatus(card.cardId, { valid: cvvValid })
  }, [card, changeExcitedPaymentStatus, cvvValid])

  // 一步支付CVV补充提交
  const handleCVVOneStepSupplySubmit = async ({ submitOrderRes }: { submitOrderRes: ISubmitOrderRes }) => {
    try {
      const result = await paySubmitByCvvOneStep({
        order: getOrder!()!,
        card,
        cvvCode,
        fromPage,
        submitOrderRes,
      })
      const url = result?.redirect_details?.url
      if (url) {
        linkOpen(url, 'CVVForm')
      } else {
        handleBillStatus({ actionType: StatusActionTypeEnum.PAY, status: StatusTypeEnum.SUCCESS })
      }
    } catch (error: any) {
      paymentsLogger.fatal('pay excited ccdc supplement cvv submit failed', error)
      handleBillStatus({
        actionType: StatusActionTypeEnum.PAY,
        status: StatusTypeEnum.FAILED as StatusTypeEnum,
        errMsg: (error as PipoRequestErrorObj).errorMessage || error.msg || error.message,
        pipoErrorCode: (error as PipoRequestErrorObj).errorCode,
      })
    }
  }

  useImperativeHandle(ref, () => ({
    needHandleResult: () => true,
    handleResult: async (res) => {
      handleCVVOneStepSupplySubmit({ submitOrderRes: res! as ISubmitOrderRes })
    },
  }))

  return (
    <>
      <CVVForm value={cvvCode} onChange={setCvvCode} onValidated={setCvvValid} />
    </>
  )
})

export default CVV
