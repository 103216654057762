import React from 'react'
import { Controller, FieldErrors, UseFormReturn } from 'react-hook-form'

import { useAddressPC } from '@/pipo/hooks'
import { IUsePostalCodeResult } from '@/pipo/hooks/use-postal-code'
import { PipoProvider, validator } from '@/pipo/utils'
import starling from '@/utils/starling'

import { BillingAddress, CallingCodeSelect, HolderName, PhoneNumber } from '../../inner-components'
import { DirectDebitFormData, DirectDebitProps } from './utils'

interface IProps {
  clsPrefix: string
  errors: FieldErrors<DirectDebitFormData>
  isBusiness: boolean
  form: UseFormReturn<DirectDebitFormData, any>
  onFetchDistrict: DirectDebitProps['onFetchDistrict']
  formErrors: FieldErrors<DirectDebitFormData>
  postalCodeProps: IUsePostalCodeResult
  addressProps: ReturnType<typeof useAddressPC>
  handleBlur: (...args: any[]) => void
}
export const ContractInfo = (props: IProps) => {
  const { errors, clsPrefix, isBusiness, form, addressProps, postalCodeProps, handleBlur } = props
  const { control } = form

  const { t, getErrorText } = PipoProvider.useContext()

  return (
    <React.Fragment>
      <div
        className={`${clsPrefix}-subtitle`}
        style={{
          marginTop: '20px',
        }}
      >
        {starling('funds.check_out.comm.customer_info')}
      </div>
      <div className={`${clsPrefix}-subform-wrapper`}>
        {isBusiness && (
          <Controller
            rules={{ required: isBusiness, pattern: validator.nameRegex }}
            name="holder_name"
            control={control}
            render={({ field, fieldState }) => (
              <HolderName
                {...field}
                len="full"
                error={errors.holder_name}
                errorMessage={getErrorText(errors.holder_name?.type)}
                title={starling('funds.refund.comm.system_name')}
                placeholder={starling('funds.refund.comm.system_name_description')}
                maxLength={40}
                onBlur={() => {
                  field.onBlur()
                  handleBlur('holder_name', fieldState)
                }}
              />
            )}
          />
        )}
        {!isBusiness && (
          <Controller
            rules={{ required: !isBusiness, pattern: validator.nameRegex }}
            name="holder_first_name"
            control={control}
            render={({ field, fieldState }) => (
              <HolderName
                {...field}
                error={errors.holder_first_name}
                errorMessage={getErrorText(errors.holder_first_name?.type)}
                title={t('pipo_common_payin_first_name')}
                placeholder={t('pipo_common_payin_ph_first_name')}
                onBlur={() => {
                  field.onBlur()
                  handleBlur('holder_first_name', fieldState)
                }}
              />
            )}
          />
        )}
        {!isBusiness && (
          <Controller
            rules={{ required: !isBusiness, pattern: validator.nameRegex }}
            name="holder_last_name"
            control={control}
            render={({ field, fieldState }) => (
              <HolderName
                {...field}
                error={errors.holder_last_name}
                errorMessage={getErrorText(errors.holder_last_name?.type)}
                title={t('pipo_common_payin_last_name')}
                placeholder={t('pipo_common_payin_ph_last_name')}
                onBlur={() => {
                  field.onBlur()
                  handleBlur('holder_last_name', fieldState)
                }}
              />
            )}
          />
        )}
        <BillingAddress
          control={control}
          errors={errors}
          postalCodeProps={postalCodeProps}
          {...addressProps}
          regionDisabled={true}
          nameOptions={{ street: 'address_details' }}
          handleBlur={handleBlur}
        />
        <Controller
          rules={{ required: true, pattern: validator.phoneRegex }}
          name="mobile_phone"
          control={control}
          render={({ field, fieldState }) => (
            <PhoneNumber
              {...field}
              title={starling('funds.refund.comm.system_phonenumber')}
              placeholder={starling('funds.refund.comm.system_phonenumber_placeholder')}
              error={errors.mobile_phone}
              errorMessage={getErrorText(errors.mobile_phone?.type)}
              addBefore={
                <Controller
                  name="phone_country_code"
                  control={control}
                  render={({ field }) => <CallingCodeSelect {...field} disabled supportedCountryCodeList={['CA']} />}
                />
              }
              phoneNumberStyle="half"
              onBlur={() => {
                field.onBlur()
                handleBlur('mobile_phone', fieldState)
              }}
            />
          )}
        />
      </div>
    </React.Fragment>
  )
}
