import React from 'react'
import Barcode from 'react-barcode'

import QRCodeReact from 'qrcode.react'

import './index.less'

const clsPrefix = 'upay-pc-generate-code'

interface IGenerateCodeProps {
  paymentCode: string
  formatCode?: string
}

export default function GenerateCommonCode({ paymentCode, formatCode }: IGenerateCodeProps) {
  return (
    <div className={`${clsPrefix}-container`}>
      <div className={`${clsPrefix}-qrcode`}>
        <QRCodeReact size={148} value={paymentCode} />
      </div>
      <div className={`${clsPrefix}-barcode`}>
        <Barcode value={paymentCode} height={68} margin={0} displayValue={false} />
      </div>
      <div className={`${clsPrefix}-paymentcode-value`}>{formatCode || paymentCode}</div>
    </div>
  )
}
