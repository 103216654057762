"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault")["default"];
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.createTeaClient = void 0;
var _objectSpread2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/objectSpread2"));
var _constant = require("./constant");
var createTeaClient = function createTeaClient() {
  var instance = {
    event: function event() {
      return undefined;
    }
  }; // tea实例
  // let _defaultParams = {};
  return {
    init: function init(_instance, _config) {
      if (!_instance) return;
      instance = _instance;
      // _defaultParams = config?.commonParams;
    },

    sendPayPageView: function sendPayPageView(params) {
      instance.event(_constant.EVENT_NAME_MAP.PAY_PAGE_VIEW, params);
    },
    sendPayPageClick: function sendPayPageClick(params) {
      instance.event(_constant.EVENT_NAME_MAP.PAY_PAGE_CLICK, (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, params), {}, {
        saved_pi: params.saved_pi ? 'saved' : 'other'
      }));
    },
    sendPayPageFillIn: function sendPayPageFillIn(params) {
      instance.event(_constant.EVENT_NAME_MAP.PAY_PAGE_FILL_IN, (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, params), {}, {
        is_valid: params.is_valid ? '1' : '0'
      }));
    },
    sendPayoutPayPageFillIn: function sendPayoutPayPageFillIn(params) {
      instance.event(_constant.EVENT_NAME_MAP.PAYOUT_PAY_PAGE_FILL_IN, (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, params), {}, {
        is_valid: params.is_valid ? '1' : '0'
      }));
    },
    sendResultPageView: function sendResultPageView(params) {
      instance.event(_constant.EVENT_NAME_MAP.RESULT_PAGE_VIEW, params);
    },
    sendResultPageClick: function sendResultPageClick(params) {
      instance.event(_constant.EVENT_NAME_MAP.RESULT_PAGE_CLICK, params);
    },
    sendPaymentCodePageView: function sendPaymentCodePageView(params) {
      instance.event(_constant.EVENT_NAME_MAP.PAYMENT_CODE_PAGE_VIEW, params);
    },
    sendPaymentCodePageClick: function sendPaymentCodePageClick(params) {
      instance.event(_constant.EVENT_NAME_MAP.PAYMENT_CODE_PAGE_CLICK, params);
    },
    sendCreatePageView: function sendCreatePageView(params) {
      instance.event(_constant.EVENT_NAME_MAP.CREATE_PAGE_VIEW, params);
    },
    sendCreatePageClick: function sendCreatePageClick(params) {
      instance.event(_constant.EVENT_NAME_MAP.CREATE_PAGE_CLICK, params);
    },
    sendBindPageFillIn: function sendBindPageFillIn(params) {
      instance.event(_constant.EVENT_NAME_MAP.BIND_PAGE_FILL_IN, (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, params), {}, {
        is_valid: params.is_valid ? '1' : '0'
      }));
    },
    sendManagerPageView: function sendManagerPageView(params) {
      instance.event(_constant.EVENT_NAME_MAP.MANAGER_PAGE_VIEW, params);
    },
    sendManagerPageClick: function sendManagerPageClick(params) {
      instance.event(_constant.EVENT_NAME_MAP.MANAGER_PAGE_CLICK, params);
    },
    sendUpdatePageView: function sendUpdatePageView(params) {
      instance.event(_constant.EVENT_NAME_MAP.UPDATE_PAGE_VIEW, params);
    },
    sendCheckoutError: function sendCheckoutError(params) {
      instance.event(_constant.EVENT_NAME_MAP.CHECKOUT_ERROR, params);
    }
  };
};
exports.createTeaClient = createTeaClient;