import safeStringify from 'fast-safe-stringify'

import slardar from './slardar'

const jsonStringifyReplacer = (key: string, data: any) => {
  // Remove the circular structure
  if (data === '[Circular]') {
    return
  }
  // JsonStringify Error
  if (data instanceof Error) {
    return { errorName: data.name, errorMessage: data.message, errorStack: data.stack }
  }
  return data
}

const SafeJSON = {
  stringify: (data: any): string => {
    try {
      return safeStringify(data, jsonStringifyReplacer)
    } catch (error) {
      return ''
    }
  },
  /**
   * 解析JSON
   * @param from 可选参数 用于解析失败后上报 帮助快速定位问题
   */
  parse: <T = Record<string, any>>(json: string, from = ''): T | undefined => {
    try {
      return JSON.parse(json)
    } catch (e) {
      slardar.timer('jsonParseErrorCount', 1, { json, from })
      return undefined
    }
  },
}

export default SafeJSON
