import { useCallback, useContext } from 'react'

import { useStateRealtime } from '@byted/hooks'

import { SUBMIT_RECORD_TYPE } from '@/constant'
import api from '@/services'
import { ParamsContext } from '@/stores'
import GlobalInfo from '@/stores/GlobalInfo'
import SafeJSON from '@/utils/SafeJSON'
import FraudNet from '@/utils/fraudnet'

import { payLogger } from '..'
import { Order } from '../interface'

interface IUsePreOrderProps {
  setErrorMsg: (msg: string) => void
}

export const usePreOrder = ({ setErrorMsg }: IUsePreOrderProps) => {
  const {
    utils: { submitRecord },
  } = useContext(GlobalInfo)
  const {
    urlQuery: { token, bizId },
    uPubSub,
  } = useContext(ParamsContext)
  // 订单信息
  const [order, setOrder, getOrder] = useStateRealtime<Order>()

  const refreshPreOrder = useCallback(async (): Promise<Order | undefined> => {
    // 预付费时需进行预下单操作
    try {
      if (!order || order.charge_id_used) {
        // 若charge_id_used为true，说明此chargeId是已经提交过，需要再次重新预下单
        const orderRes = await api.getPreOrder(
          { token },
          {
            extraHandle: {
              uPubSub,
            },
            bizId,
          },
        )
        setOrder(orderRes.data)
        submitRecord({
          actionId: orderRes.data?.charge_id || '',
          actionType: SUBMIT_RECORD_TYPE.PRE_ORDER_SUBMIT,
          payWay: -1, // 此处传-1为默认值无意义
        })
        FraudNet.clear()
        FraudNet.setCMID(orderRes.data!.charge_id)
        payLogger.scenes.payPreOrderFetched({ preOrderRes: orderRes.data!, logid: orderRes.logid! })

        return orderRes.data
      } else {
        return order
      }
    } catch (e: any) {
      payLogger.fatal('pay pre order fetch error', e, { scene: 'payPreOrderFetched' })
      setErrorMsg(e?.msg || SafeJSON.stringify(e))
    }
  }, [bizId, order, setErrorMsg, setOrder, submitRecord, token, uPubSub])

  const setOrderChargeIdUsed = useCallback(() => {
    const order = getOrder()
    order && setOrder({ ...order, charge_id_used: true })
  }, [getOrder, setOrder])

  return { order, getOrder, refreshPreOrder, setOrderChargeIdUsed }
}
