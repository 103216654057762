import { PaymentMethodItem } from '../../types'

export const BANKTRANSFER_2C2P_PAYMENT_METHOD: PaymentMethodItem = {
  paymentMethodType: {
    configuration: {},
    icon: {
      iconUrl: '',
    },
    starling_key: 'pipo_payin_payment_main_banktransfer',
    paymentMethodType: 'BANK_TRANSFER',
  },
  paymentMethod: {
    configuration: {
      png_icon_url: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/2c2p_629df5.png',
      dark_mode_png_icon_url: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/2c2p-dark_5b5858.png',
    },
    paymentMethod: 'BANK_TRANSFER',
    paymentMethodId: 'pm_pi_bt_banktransfer_c_r',
    displayName: '2c2p banktransfer',
    starling_key: 'pipo_payin_checkout_cashier_paymentmethodname_2C2PBanktransfer',
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/2c2p-border_2ec2d6.png',
      pngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/2c2p-border_2ec2d6.png',
      darkModePngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/2c2p-dark_5b5858.png',
      grayIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/2c2p_629df5.png',
    },
  },
}
