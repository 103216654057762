import React, { FC, useRef } from 'react'

import { IPayinMethodItem } from '@/pages/PayNext/interface'
import { ChannelRes } from '@/services/types'

import { Hint } from '..'
import { IHintRef } from '../Hint/interface'

import './style.less'

const ChannelItemTitle: FC<{ selected: boolean; channel: ChannelRes | IPayinMethodItem; disabled?: boolean }> = ({ selected, channel, disabled }) => {
  let tipEle
  const channelDisabled = disabled || channel.disable
  const hintRef = useRef<IHintRef>(null)

  // TODO: paynext 全量后 可梳理移除此处的兼容逻辑
  const imageUrl = (channel as IPayinMethodItem).imageUrl || (channel as ChannelRes).paymentsIcon

  if (channel?.paymentsTips) {
    tipEle = <Hint ref={hintRef} plain content={channel.paymentsTips} showIcon />
  }

  return (
    <div className={`upay-channel-container ${channelDisabled ? 'upay-channel-disabled' : ''}`}>
      <div className="upay-channel__title">
        <div
          className="upay-channel__title-logo"
          style={{
            background: `url(${imageUrl}) center center no-repeat rgba(239, 236, 232, 1)`,
          }}
        />
        <span className="upay-channel__title-text">{channel.paymentsTitle}</span>
      </div>
      {tipEle && (
        <div
          className={`upay-channel__tip ${selected ? 'upay-channel__tip-show' : ''}`}
          style={{ height: selected ? hintRef.current?.getHeight() : 0 }}
        >
          {tipEle}
        </div>
      )}
    </div>
  )
}

export default ChannelItemTitle
