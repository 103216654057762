"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault")["default"];
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.getPipoScenes = getPipoScenes;
var _objectSpread2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/objectSpread2"));
function getPipoScenes() {
  var _this = this;
  return {
    fetchPipoApiCount: function fetchPipoApiCount(_ref) {
      var _pipoApiRes$redirect_;
      var apiName = _ref.apiName,
        pipoApiRes = _ref.pipoApiRes,
        pipoApiError = _ref.pipoApiError,
        from = _ref.from,
        time = _ref.time;
      var infoContent = {
        errorCode: (pipoApiRes === null || pipoApiRes === void 0 ? void 0 : pipoApiRes.error_code) || '',
        errorMessage: (pipoApiRes === null || pipoApiRes === void 0 ? void 0 : pipoApiRes.error_message) || '',
        resultCode: (pipoApiRes === null || pipoApiRes === void 0 ? void 0 : pipoApiRes.result_code) || '',
        redirectUrl: (pipoApiRes === null || pipoApiRes === void 0 ? void 0 : (_pipoApiRes$redirect_ = pipoApiRes.redirect_details) === null || _pipoApiRes$redirect_ === void 0 ? void 0 : _pipoApiRes$redirect_.url) || '',
        logid: (pipoApiRes === null || pipoApiRes === void 0 ? void 0 : pipoApiRes['x-tt-logid']) || '',
        from: from,
        time: time
      };
      if (pipoApiError) {
        var _pipoApiError$headers;
        (pipoApiError === null || pipoApiError === void 0 ? void 0 : (_pipoApiError$headers = pipoApiError.headers) === null || _pipoApiError$headers === void 0 ? void 0 : _pipoApiError$headers['x-tt-logid']) && (infoContent.logid = pipoApiError.headers['x-tt-logid']);
        typeof (pipoApiError === null || pipoApiError === void 0 ? void 0 : pipoApiError.status) === 'number' && (infoContent.http_status = pipoApiError.status);
        (pipoApiError === null || pipoApiError === void 0 ? void 0 : pipoApiError.message) && (infoContent.errorMessage = pipoApiError.message);
      }
      _this.info("fetchPIPOApi_".concat(apiName), infoContent);
      // 详细返回内容会在log中上报
      var isError = !pipoApiRes || infoContent.resultCode === 'error' || pipoApiError || infoContent.errorCode;
      _this.log("fetchPIPOApiLog_".concat(apiName), (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, infoContent), {}, {
        pipoApiRes: pipoApiRes
      }), isError ? 'error' : 'info');
    },
    fetchDistrictApiCount: function fetchDistrictApiCount(_ref2) {
      var time = _ref2.time,
        pipoApiError = _ref2.pipoApiError;
      var infoContent = {
        time: time,
        resultCode: 'success'
      };
      if (pipoApiError) {
        var _pipoApiError$headers2;
        infoContent.resultCode = 'error';
        (pipoApiError === null || pipoApiError === void 0 ? void 0 : (_pipoApiError$headers2 = pipoApiError.headers) === null || _pipoApiError$headers2 === void 0 ? void 0 : _pipoApiError$headers2['x-tt-logid']) && (infoContent.logid = pipoApiError.headers['x-tt-logid']);
        typeof (pipoApiError === null || pipoApiError === void 0 ? void 0 : pipoApiError.status) === 'number' && (infoContent.http_status = pipoApiError.status);
        (pipoApiError === null || pipoApiError === void 0 ? void 0 : pipoApiError.message) && (infoContent.errorMessage = pipoApiError.message);
      }
      _this.info("fetchDistrictApiCount", infoContent);
    }
  };
}