import { useRef } from 'react'

import teaClient from '@/utils/tea'

export const useTeaEvent = () => {
  const countRef = useRef(0)

  const sendManagerPageView = (params: Parameters<typeof teaClient.sendManagerPageView>[0]) => {
    if (countRef.current) return
    countRef.current++

    teaClient.sendManagerPageView(params)
  }

  return { sendManagerPageView }
}
