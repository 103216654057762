import { useCallback, useMemo } from 'react'
import type { FieldError } from 'react-hook-form'

import { POSTALCODE_TIP } from '@/constant'
import { LocationItem, PipoProvider } from '@/pipo/utils'
import starling from '@/utils/starling'

type ErrorType = 'required' | 'pattern'

interface IUsePostalCode {
  selectedAddressArr: (LocationItem | undefined)[]
}

export interface IUsePostalCodeResult {
  postalCodeTitle: string
  postalCodeValidator: {
    required: boolean
    pattern: RegExp
  }
  getPostalCodeError: (error: FieldError | undefined) => string
  postalCodePlaceholder: string
}

export function getPostalCodeRules(code: string) {
  let postalCodeRules: string | RegExp

  switch (code) {
    // Brasil
    case 'BR': {
      postalCodeRules = '^[0-9]{8}$'
      break
    }
    // France
    case 'FR':
      postalCodeRules = /(^[0-8]\d\d{3}$)|(^9[0-5]\d{3}$)|(^97[1-6]\d{2}$)|(^98[46-8]\d{2}$)/
      break
    case 'US':
      postalCodeRules = /^[0-9]{5}$/
      break
    // GB
    case 'GB':
      // from https://stackoverflow.com/a/164994
      postalCodeRules =
        /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/
      break
    default: {
      postalCodeRules = '^[0-9a-zA-Z]*$'
      break
    }
  }

  return postalCodeRules
}

export function usePostalCode({ selectedAddressArr }: IUsePostalCode): IUsePostalCodeResult {
  const { getErrorText } = PipoProvider.useContext()

  // 此处的countryCode为地理位置接口中的code 可能会有细微区别 如 UK对应GB
  const countryCode = selectedAddressArr?.length > 0 ? selectedAddressArr[0]?.code || '' : ''
  const title = starling('funds.refund.comm.system_postalcode')
  const POSTALCODE_TIP_OBJECT = useMemo(() => POSTALCODE_TIP(), [])
  const placeholder = POSTALCODE_TIP_OBJECT[countryCode] || title

  const pattern = getPostalCodeRules(countryCode)
  const postalCodeValidator = {
    required: true,
    pattern: new RegExp(pattern),
  }
  const getPostalCodeError = useCallback(
    (error: FieldError | undefined): string => {
      const errorType = error?.type as ErrorType
      return getErrorText(errorType)
    },
    [getErrorText],
  )

  return {
    postalCodeTitle: title,
    postalCodeValidator,
    getPostalCodeError,
    postalCodePlaceholder: placeholder,
  }
}
