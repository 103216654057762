/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useCallback, useContext, useMemo } from 'react'

import Service, { LocationItem, PayRequest, StorePaymentMethodRequest } from '@pipo-lib/api'

import { COMMON_PROMPT, IS_DEV, PIPO_CODE, PIPO_LOCALE_MAP, REQUIRE_L4_ADDRESS_COUNTRY_CODE } from '@/constant'
import utils from '@/utils'
import { getLang } from '@/utils/getLang'

import { ParamsContext, globalInfoLogger } from '..'
import {
  IBindAuthorizeAgreementProps,
  IPaySubmitByCVVOneStepProps,
  IPaySubmitByPIPOFormProps,
  IPaySubmitProps,
  IPipoContextOptions,
  IStorePaymentMethodProps,
} from '../interface'

interface IUsePIPOApi {
  getPassportTicketAsync: () => Promise<string | null>
}

export const usePIPOApi = ({ getPassportTicketAsync }: IUsePIPOApi) => {
  const {
    params: { isBoe },
    urlQuery,
    unique,
  } = useContext(ParamsContext)
  // PIPO 语言包
  const pipoLocale = useMemo(() => PIPO_LOCALE_MAP[getLang()] || getLang(), [])
  // PIPO组件 上下文配置
  const pipoProviderOptions: IPipoContextOptions = useMemo(
    () => ({
      countryOrRegion: urlQuery.countryCode,
      localeTranslation: (window as any).UPAY_PIPO_I18N_TEXTS,
      locale: pipoLocale,
      config: {
        showTitle: false,
        showPayBtn: false,
        selectedSaveBox: true, //后付费使用预付支付不能绑卡
        ccdc: { mode: 2, hasCNPJ: true },
        boleto: {
          hasCNPJ: true,
          hasEmail: true,
        },
        pix: {
          hasCNPJ: true,
          hasBillingAddress: true,
        },
      },
    }),
    [pipoLocale, urlQuery],
  )

  const initPIPOApiService = useCallback(
    (merchantId = '', pipoDomain?: string) => {
      const idc = (IS_DEV || isBoe ? 'boe' : utils.getIdc(urlQuery.idc, true)) as IDC
      const ser = new Service({ merchantId, idc, domainName: pipoDomain })
      return ser
    },
    [isBoe, urlQuery.idc],
  )

  /**
   * ============== 以下为PIPO API请求封装 =============
   */
  const getPipoApiConfiguration = useCallback(() => {
    return {
      environment: (IS_DEV || isBoe ? 'test' : 'live') as 'test' | 'live',
      locale: pipoLocale,
      flow: 'web' as 'web' | 'wap',
    }
  }, [isBoe, pipoLocale])

  const fetchDistrict = useCallback(
    async (geonameid: any, selectedArr: string | any[]) => {
      try {
        const startTime = new Date().getTime()
        const pipoService = initPIPOApiService()
        let res
        try {
          res = (await pipoService.api.location.get_district({
            language: pipoLocale,
            key: '83639969f5b36a5e31a83487aedcbc9b',
            geonameid: String(geonameid),
            // @ts-ignore 支持部分国家4级地址选择 查2级地址后平铺显示
            subdistrict: selectedArr && REQUIRE_L4_ADDRESS_COUNTRY_CODE.includes(selectedArr[0]?.code) && selectedArr?.length === 2 ? 2 : 1,
          })) as any
        } catch (error) {
          globalInfoLogger.scenes.fetchDistrictApiCount({ pipoApiError: error, time: new Date().getTime() - startTime })

          throw globalInfoLogger.utils.getPipoRequestErrorObjByError(error)
        }

        globalInfoLogger.scenes.fetchDistrictApiCount({ time: new Date().getTime() - startTime })

        if (selectedArr && REQUIRE_L4_ADDRESS_COUNTRY_CODE.includes(selectedArr[0]?.code) && selectedArr?.length === 2) {
          const L4LocationList = res?.map((item: any) => item.sub_region)
          const L4LocationListFlat = L4LocationList?.flat(Infinity)
          return L4LocationListFlat as LocationItem[]
        } else {
          return res
        }
      } catch (e) {
        globalInfoLogger.fatal('fetchDistrictError', e, { scene: 'fetchDistrictApiCount' })
        throw e
      }
    },
    [initPIPOApiService, pipoLocale],
  )

  const paySubmit = useCallback(
    async (props: IPaySubmitProps, from?: string) => {
      const { paymentMethodObj, extraSubmitParams, order, fromPage, submitOrderRes } = props
      // 两步支付与一步支付获取paymentReference位置不同
      const { paymentReference = order.payment_reference, riskInfo = '', apiBaseUrl, nonce, merchantId } = submitOrderRes
      const startTime = new Date().getTime()
      try {
        if (!paymentReference) {
          // 无paymentReference上报
          globalInfoLogger.info('payNoPaymentReference', { order, props, from: 'paySubmit' })

          throw new Error(COMMON_PROMPT().SYS_ERR + '(PaySubmit No PaymentReference Error)')
        }

        const configuration = getPipoApiConfiguration()
        const passportTicket = await getPassportTicketAsync()

        // 兼容PIPO后端 为所有的支付方式加上element_params参数
        const pms_params = {
          country_code: urlQuery.countryCode,
          currency: order.currency,
          amount_value: order.amount,
          sdk_type: 'go',
          sdk_version: '10.0.0',
        }
        // @ts-ignore
        paymentMethodObj.element_params = pms_params

        const submitParams: PayRequest = {
          payment_method: paymentMethodObj,
          amount: order.amount,
          charge_id: order.charge_id,
          currency: order.currency,
          payment_reference: paymentReference,
          configuration,
          idempotency_key: String(Date.now()),
          merchant_user_id: urlQuery.accountId,
          return_url: utils.getResultPath({ from: fromPage, uniqueId: unique }),
          risk_info: riskInfo, // pay使用补充风控参数
          nonce: nonce!,
          ...extraSubmitParams,
        }
        const headers = {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-FP-PASSPORT-TICKET': passportTicket || '',
        }
        if (!apiBaseUrl) {
          globalInfoLogger.warn('paySubmit emptyPipoDomain')
        }
        const pipoService = initPIPOApiService(merchantId, apiBaseUrl)

        let res
        try {
          res = await pipoService.api.pipo.payment.pay(submitParams, undefined, { headers })
        } catch (error: any) {
          // PIPO 接口非2xx错误
          globalInfoLogger.scenes.fetchPipoApiCount({ apiName: 'pay', pipoApiError: error, from, time: new Date().getTime() - startTime })

          throw globalInfoLogger.utils.getPipoRequestErrorObjByError(error)
        }

        globalInfoLogger.scenes.fetchPipoApiCount({ apiName: 'pay', pipoApiRes: res, from, time: new Date().getTime() - startTime })

        if (res.result_code === PIPO_CODE.ERROR) {
          // PIPO 接口错误码错误
          utils.reportPipoRes(res, order.charge_id, 'upay_record', urlQuery.token)

          throw globalInfoLogger.utils.getPipoRequestErrorObjByRes(res)
        }
        return res
      } catch (e) {
        globalInfoLogger.fatal('PIPO API pay submit error', e, { from })
        throw e
      }
    },
    [getPassportTicketAsync, urlQuery, getPipoApiConfiguration, unique, initPIPOApiService],
  )

  const paySubmitByPIPOForm = useCallback(
    async (props: IPaySubmitByPIPOFormProps) => {
      const { pipoSubmitParams, order, fromPage, submitOrderRes } = props
      const { agreementId } = submitOrderRes
      const { paymentMethod, paymentParams, token: paymentMethodToken, storeCard } = pipoSubmitParams

      const paymentMethodObj = {
        method_id: paymentMethod.paymentMethodId,
        payment_elements: paymentParams,
        payment_method_token: paymentMethodToken,
      }

      const isAgreementBindAndPay = agreementId && storeCard
      const agreementParams = isAgreementBindAndPay
        ? {
            is_agreement: true,
            agreement_id: agreementId,
          }
        : {}

      // Direct Debit兼容
      if (paymentMethod?.paymentMethod === 'DIRECT_DEBIT') {
        // @ts-ignore
        paymentMethodObj.method_type = paymentMethod?.paymentMethod
        // @ts-ignore
        paymentMethodObj.type = ''
        // @ts-ignore
        paymentMethodObj.method_params = null
      }

      const extraSubmitParams = {
        store_payment_method: storeCard,
        ...agreementParams,
      }
      const result = await paySubmit({ paymentMethodObj, order, extraSubmitParams, fromPage, submitOrderRes }, 'paySubmitByPIPOForm')

      return result
    },
    [paySubmit],
  )

  const paySubmitByCvvOneStep = useCallback(
    async ({ order, card, cvvCode, returnUrl, fromPage, submitOrderRes }: IPaySubmitByCVVOneStepProps) => {
      const { paymentMethodId } = submitOrderRes
      const paymentMethodObj: PayRequest['payment_method'] = {
        method_id: paymentMethodId,
        payment_method_token: card.payToken,
        payment_elements: [
          {
            element: 'eg_ccdc_global_cvv',
            param_name: 'cvv',
            param_value: cvvCode,
          },
        ],
      }
      const extraSubmitParams: any = {
        store_payment_method: false,
      }
      if (returnUrl) {
        extraSubmitParams.return_url = returnUrl
      }

      const result = await paySubmit({ paymentMethodObj, extraSubmitParams, order, fromPage, submitOrderRes }, 'paySubmitByCvv')

      return result
    },
    [paySubmit],
  )

  /**
   * PIPO 独立绑卡接口
   */
  const storePaymentMethod = useCallback(
    async ({ pipoSubmitParams, fromPage, bindCardRes }: IStorePaymentMethodProps) => {
      const { cardId, payToken = '', riskInfo, apiBaseUrl, notificationUrl, nonce, merchantId } = bindCardRes
      try {
        const { paymentMethod, paymentParams, paymentMethodType, formData } = pipoSubmitParams
        const startTime = new Date().getTime()

        const configuration = getPipoApiConfiguration()
        const passportTicket = await getPassportTicketAsync()

        const paymentMethodObj = {
          method_id: paymentMethod.paymentMethodId,
          payment_elements: paymentParams,
          payment_method_token: payToken,
          element_params: {
            country_code: urlQuery.countryCode,
            sdk_type: 'go',
            sdk_version: '10.0.0',
          },
        }
        // 为了兼容服务端 CCDC 支付时需要传 method_type，wenbo 2021.10.1 后第一个火车上线。
        if (paymentMethodType === 'CCDC') {
          // @ts-ignore
          paymentMethodObj.method_type = paymentMethodType
          // @ts-ignore
          paymentMethodObj.method_params = formData
        }

        const submitParams: StorePaymentMethodRequest = {
          payment_method: paymentMethodObj,
          country_or_region: urlQuery.countryCode,
          configuration,
          idempotency_key: cardId,
          merchant_user_id: urlQuery.accountId,
          notification_url: notificationUrl || '',
          return_url: utils.getResultPath({ from: fromPage, uniqueId: unique }),
          risk_info: riskInfo, // pay使用补充风控参数
          nonce,
          // @ts-ignore 需要传must_do_3ds
          must_do_3ds: true,
        }
        const headers = {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-FP-PASSPORT-TICKET': passportTicket || '',
        }
        if (!apiBaseUrl) {
          globalInfoLogger.warn('storePaymentMethod emptyPipoDomain')
        }

        const pipoService = initPIPOApiService(merchantId, apiBaseUrl)
        let res
        try {
          res = await pipoService.api.pipo.payment.storePaymentMethod(submitParams, undefined, { headers })
        } catch (error: any) {
          // PIPO 接口请求非2xx错误
          globalInfoLogger.scenes.fetchPipoApiCount({ apiName: 'storePaymentMethod', pipoApiError: error, time: new Date().getTime() - startTime })

          throw globalInfoLogger.utils.getPipoRequestErrorObjByError(error)
        }

        globalInfoLogger.scenes.fetchPipoApiCount({ apiName: 'storePaymentMethod', pipoApiRes: res, time: new Date().getTime() - startTime })

        if (res.result_code === PIPO_CODE.ERROR) {
          // PIPO 接口错误码错误
          utils.reportPipoRes(res, cardId, 'upay_card', urlQuery.token)

          throw globalInfoLogger.utils.getPipoRequestErrorObjByRes(res)
        }
        return res
      } catch (e) {
        globalInfoLogger.fatal('PIPO api storePaymentMethod submit error', e)
        throw e
      }
    },
    [getPassportTicketAsync, urlQuery, getPipoApiConfiguration, unique, initPIPOApiService],
  )

  /**
   * PIPO CCDC协议绑卡接口
   */
  const bindCCDCAuthorizeAgreement = useCallback(
    async ({ pipoSubmitParams, fromPage, bindCardRes }: IBindAuthorizeAgreementProps) => {
      try {
        const { agreementId, riskAmount = '', currency = '', cardId, riskInfo, apiBaseUrl, payToken, nonce, merchantId } = bindCardRes
        const { paymentMethod, paymentParams, paymentMethodType, formData } = pipoSubmitParams
        const startTime = new Date().getTime()

        const configuration = getPipoApiConfiguration()
        const passportTicket = await getPassportTicketAsync()

        const paymentMethodObj = {
          method_id: paymentMethod.paymentMethodId,
          method_type: paymentMethodType,
          method_params: formData,
          payment_elements: paymentParams,
          payment_method_token: payToken,
          element_params: {
            country_code: urlQuery.countryCode,
            sdk_type: 'go',
            sdk_version: '10.0.0',
          },
        }

        const submitParams = {
          agreement_id: agreementId,
          configuration,
          country_or_region: urlQuery.countryCode,
          merchant_user_id: urlQuery.accountId,
          payment_method: paymentMethodObj,
          request_id: cardId,
          return_url: utils.getResultPath({ from: fromPage, uniqueId: unique }),
          nonce,
          risk_info: riskInfo,
          request_amount: String(riskAmount),
          currency,
        }
        const headers = {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-FP-PASSPORT-TICKET': passportTicket || '',
        }
        if (!apiBaseUrl) {
          globalInfoLogger.warn('emptyPipoDomain')
        }

        const pipoService = initPIPOApiService(merchantId, apiBaseUrl)
        let res
        try {
          res = await pipoService.api.pipo.agreement.bindCCDCAuthorizeAgreement(submitParams, undefined, { headers })
        } catch (error: any) {
          globalInfoLogger.scenes.fetchPipoApiCount({
            apiName: 'bindAuthorizeAgreement',
            pipoApiError: error,
            time: startTime - new Date().getTime(),
          })

          throw globalInfoLogger.utils.getPipoRequestErrorObjByError(error)
        }

        globalInfoLogger.scenes.fetchPipoApiCount({ apiName: 'bindAuthorizeAgreement', pipoApiRes: res, time: startTime - new Date().getTime() })

        if (res.result_code === PIPO_CODE.ERROR) {
          utils.reportPipoRes(res, cardId, 'upay_card', urlQuery.token)

          throw globalInfoLogger.utils.getPipoRequestErrorObjByRes(res)
        }
        return res
      } catch (e) {
        globalInfoLogger.fatal('PIPO api bindCCDCAuthorizeAgreement submit error', e)
        throw e
      }
    },
    [getPassportTicketAsync, getPipoApiConfiguration, initPIPOApiService, unique, urlQuery],
  )

  const PIPOApi = useMemo(
    () => ({
      fetchDistrict,
      paySubmitByPIPOForm,
      paySubmitByCvvOneStep,
      bindCCDCAuthorizeAgreement,
      storePaymentMethod,
    }),
    [bindCCDCAuthorizeAgreement, fetchDistrict, paySubmitByCvvOneStep, paySubmitByPIPOForm, storePaymentMethod],
  )
  return { PIPOApi, pipoProviderOptions, initPIPOApiService }
}
