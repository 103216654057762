import { useContext } from 'react'

import { useRequest } from '@byted/hooks'

import api from '@/services'
import { ParamsContext } from '@/stores'

interface IProps {
  cardId: string
}
export const useBoundElements = (props: IProps) => {
  const { cardId } = props
  const {
    urlQuery: { token },
  } = useContext(ParamsContext)

  const { data } = useRequest(api.payoutBoundPiElements, {
    auto: true,
    defaultParams: [
      {
        token,
        cardId,
      },
    ],
  })

  return { boundElements: data?.data?.elements || [] }
}
