"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.PROMPT_PAY_PAYMENT_METHOD = void 0;
var PROMPT_PAY_PAYMENT_METHOD = {
  paymentMethodType: {
    configuration: {},
    icon: {
      iconUrl: ''
    },
    starling_key: '',
    paymentMethodType: 'INTERNET_BANKING'
  },
  paymentMethod: {
    configuration: {},
    paymentMethod: '',
    paymentMethodId: 'pm_pi_bap_promptpay_c_r',
    displayName: 'PromptPay',
    starling_key: '',
    icon: {
      iconUrl: '',
      voucherIconUrl: '',
      pngIconUrl: '',
      darkModePngIconUrl: '',
      detailIconUrl: ''
    }
  }
};
exports.PROMPT_PAY_PAYMENT_METHOD = PROMPT_PAY_PAYMENT_METHOD;