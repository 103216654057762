import React from 'react'

import { Tooltip } from '@arco-design/web-react'
import { IconQuestionCircle } from '@arco-design/web-react/icon'
import classnames from 'classnames'

import { PipoProvider } from '../..'

const prefix = 'pipo-pc-input'

interface IProps {
  title: React.ReactNode
  tips?: React.ReactNode
}
export const ItemBlockLabel: React.FC<IProps> = (props) => {
  const { title, tips } = props
  const { isFlip } = PipoProvider.useContext()

  return (
    <>
      {tips ? (
        <Tooltip
          className={`${prefix}-tooltip`}
          color="#fff"
          position="tl"
          trigger="hover"
          content={
            <div className={`${prefix}-tooltip-content`}>
              <div className={`${prefix}-text`}>{tips}</div>
            </div>
          }
        >
          <span>{title}</span>
          <IconQuestionCircle
            className={classnames(`${prefix}-icon`, {
              [`${prefix}-icon-flip`]: isFlip,
            })}
          />
        </Tooltip>
      ) : (
        title
      )}
    </>
  )
}
