import React, { ReactElement, forwardRef, useEffect, useImperativeHandle } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { usePhoneValidator, useTouchedErrors } from '@/pipo/hooks'
import { PhoneNumber } from '@/pipo/pc/inner-components'
import { PaymentMethodFormData, PipoProvider, callingCode } from '@/pipo/utils'
import starling from '@/utils/starling'

import { PayoutComponentRef, PayoutSubmitParams } from '../../types'
import { QIWIFormData, QIWIFormProps } from './utils'

const phoneRegex = /^\d{10}$/

export const QIWIForm = forwardRef((props: QIWIFormProps, ref: React.ForwardedRef<PayoutComponentRef>): ReactElement => {
  const { onChange } = props
  const { state } = PipoProvider.useContext()
  const { countryOrRegion = '' } = state || {}
  const phoneCountryCode = callingCode.list[countryOrRegion].code
  const {
    control,
    getValues,
    formState: { errors: formErrors, isValid, touchedFields },
  } = useForm<PaymentMethodFormData>({
    mode: 'all',
  })
  const errors = useTouchedErrors<QIWIFormData>({
    touchedFields,
    errors: formErrors,
  })
  const { phoneValidate, errorMessage } = usePhoneValidator({
    phoneRegex: phoneRegex,
    options: {
      type: errors?.phone?.type || '',
      errorMessage: starling('cg.upay.exception.channel_QIWI_number_length', {
        numbers1: 10,
      }),
    },
  })

  // 在表单状态和勾选绑卡时触发onChange
  useEffect(() => {
    onChange?.({
      isValid,
      storeCard: true,
    })
  }, [isValid, onChange])

  useImperativeHandle(ref, () => ({
    getSubmitData,
  }))

  const getSubmitData = (): PayoutSubmitParams => {
    const formData = getValues()

    const postFormData = {
      ...formData,
      phone_country_code: phoneCountryCode,
      mobile_phone: `${phoneCountryCode}${formData.phone}`,
    }

    return {
      formData: postFormData,
    }
  }

  return (
    <div className="pipo-pc">
      <div className="pipo-pc-single-widget">
        <Controller
          rules={{
            required: true,
            validate: {
              phoneValidate,
            },
          }}
          name="phone"
          control={control}
          render={({ field }) => (
            <PhoneNumber
              {...field}
              maxLength={10}
              error={errors.phone}
              phoneNumberStyle="full"
              addBefore={`+${phoneCountryCode}`}
              title={starling('funds.refund.comm.qiwiidtitle')}
              placeholder={starling('funds.refund.comm.qiwiid')}
              errorMessage={errorMessage}
            />
          )}
        />
      </div>
    </div>
  )
})
