import React from 'react'

import { Logger } from '@upay/utils'

import { logger } from '@/utils/slardar'

import Error from '../Error'

interface IErrorBoundaryProps {
  children: React.ReactNode
  loggerInstance?: Logger
  // 使用对个ErrorBoundary时的上报标识
  label?: string
}

/** 捕获错误边界 */
class ErrorBoundary extends React.Component<IErrorBoundaryProps, { hasError: boolean }> {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    // 更新 state 使下一次渲染能够显示降级后的 UI
    return { hasError: true }
  }

  componentDidCatch(error: Error) {
    const _logger = this.props.loggerInstance || logger
    const label = this.props.label || 'BASE'
    _logger.fatal(`Page Crash, catch by ${label}ErrorBoundary`, error, { label })
  }

  render() {
    if (this.state.hasError) {
      // 你可以自定义降级后的 UI 并渲染
      return (
        <Error
          content={{ errorContent: 'Oops, Something Went Wrong. Please Try Again Later.', btnContent: 'Retry' }}
          handleClick={() => {
            window.location.reload()
          }}
        />
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
