import React from 'react'
import { FieldError } from 'react-hook-form'

import { Empty, Select } from '@arco-design/web-react'
import { IconDown } from '@arco-design/web-react/icon'
import { omit } from 'lodash'

import type { LocationItem } from '@/pipo/utils'

import { ItemBlock } from '../index'

const Option = Select.Option

interface IProps {
  title: string
  placeholder?: string
  error?: FieldError
  errorMessage: string
  loading?: boolean
  options: LocationItem[]
  disabled?: boolean
  value?: string
  onChange?: (value: string | undefined) => void
}

export const Country = React.forwardRef((props: IProps, ref: any) => {
  const { title, placeholder, error, errorMessage, loading, disabled, options } = props

  return (
    <ItemBlock style="half" title={title} errorState={Boolean(error)} errorMessage={errorMessage}>
      <Select
        {...omit(props, ['options'])}
        ref={ref}
        size="large"
        allowClear
        showSearch
        arrowIcon={<IconDown />}
        error={Boolean(error)}
        placeholder={placeholder}
        notFoundContent={<Empty description="N/A" />}
        loading={loading}
        disabled={disabled}
        // fix: 点击清除时未触发form更新
        onClear={() => props.onChange?.(undefined)}
        filterOption={(inputValue, option) => {
          return (
            option.props.value.toString()?.toLowerCase().indexOf(inputValue?.toLowerCase()) >= 0 ||
            option.props.extra.code?.toLowerCase().indexOf(inputValue?.toLowerCase()) >= 0
          )
        }}
      >
        {options.filter(Boolean).map((item) => {
          return (
            <Option key={item.geoname_id?.toString()} value={item.name} extra={item}>
              <span>{item.name}</span>
            </Option>
          )
        })}
      </Select>
    </ItemBlock>
  )
})
