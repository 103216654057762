import { Email, FixedInvisibleValue, HolderName, ItemInputNumber, PhoneNumber } from '@/pipo/pc/inner-components'
import { validator } from '@/pipo/utils'
import starling from '@/utils/starling'

import { IGetLocalBankTransferFormConfigProps, ILocalBankTransferConfigItem } from '../utils'

export const getUSFormConfig = ({ cardHolderInfo }: IGetLocalBankTransferFormConfigProps): ILocalBankTransferConfigItem => {
  return {
    allowAccountType: ['c', 'b'],
    formSchema: [
      {
        field: 'holder_name',
        widget: HolderName,
        widgetProps: {
          title: starling('funds.refund.comm.beneficiary_name'),
          placeholder: starling('funds.refund.comm.system_name_description'),
          maxLength: 120,
          selectList: cardHolderInfo?.holderNameList,
        },
        rules: { required: true, pattern: validator.usHolderName },
      },
      {
        field: 'routing_no',
        widget: ItemInputNumber,
        widgetProps: {
          title: starling('funds.refund.comm.system_bankaccount_routingcode_US'),
          placeholder: 'e.g. 000000000',
          maxLength: 9,
        },
        rules: { required: true, pattern: validator.routingNo },
        ruleMessages: { pattern: starling('cg.upay.exception.channel_id_num_error_message', { num: 9 }) },
      },
      {
        field: 'account_no',
        widget: ItemInputNumber,
        widgetProps: {
          title: starling('funds.refund.comm.system_bankaccount_account_number'),
          placeholder: starling('funds.refund.comm.system_bankaccount_account_number_placeholder'),
          maxLength: 17,
        },
        rules: { required: true, pattern: validator.usBankAccountNo },
      },
      {
        field: 'email',
        widget: Email,
        widgetProps: {
          title: starling('funds.refund.comm.system_email'),
          placeholder: starling('funds.refund.comm.system_email_placeholder'),
        },
        rules: { required: true, pattern: validator.emailRegex },
      },
      {
        field: 'bankaccount_country_currency',
        widget: FixedInvisibleValue,
        widgetProps: {
          value: 'USUSD',
        },
      },
    ],
  }
}
