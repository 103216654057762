import React from 'react'

function SvgShield(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="50" height="56" viewBox="0 0 50 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2.5 9.31955L25.0108 2.75L47.5 9.31955V22.7921C47.5 36.9527 38.4378 49.5243 25.0033 54.0006C11.5651 49.5244 2.5 36.95 2.5 22.7859V9.31955Z"
        fill="white"
        fillOpacity="0.2"
        stroke="white"
        strokeWidth="4"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgShield
