import { useCallback, useEffect, useRef } from 'react'

import utils from '@/utils'

import { hooksLogger } from '.'

type NewWindowT = [linkOpen: (url: string, currentPage?: string) => Promise<Window>, linkClose: () => void]

/**
 * 控制新窗口
 * @param onClose 用户主动关闭时触发
 * @returns [linkOpen, linkClose]: [打开新窗口, 关闭新窗口]
 */
const useNewWindow = (onClose?: () => void): NewWindowT => {
  const link = useRef<Window>()
  const timer = useRef<number>()
  useEffect(() => () => clearInterval(timer.current), [])

  const linkOpen = useCallback(
    async (url: string, currentPage?: string) => {
      hooksLogger.info(`openWindow`, { from: currentPage, to: url })
      clearInterval(timer.current)
      link.current?.close()
      // 开新窗口并进行状态轮询
      link.current = await utils.openWindowForce(url, `${currentPage}OPENwindow`)
      link.current && hooksLogger.info(`openWindowSuccess`, { from: currentPage, to: url })

      timer.current = window.setInterval(() => {
        // 轮询新打开的窗口是否关闭，若关闭，则关掉定时器
        if (link.current?.closed) {
          hooksLogger.info('3dsWindowClose', { from: currentPage, to: url })
          clearInterval(timer.current)
          onClose?.()
        }
      }, 300)

      return link.current
    },
    [onClose],
  )

  const linkClose = useCallback(() => {
    clearInterval(timer.current)
    link.current?.close()
  }, [])

  return [linkOpen, linkClose]
}

export default useNewWindow
