import { useContext } from 'react'

import { useRequest } from '@byted/hooks'

import { NEW_SDK_TERMINAL_EQUIP } from '@/constant'
import api from '@/services'
import { IPIPaymentListRes } from '@/services/types'
import { ParamsContext } from '@/stores'
import GlobalInfo from '@/stores/GlobalInfo'
import SafeJSON from '@/utils/SafeJSON'

interface IProps {
  onSuccess?: (data: IPIPaymentListRes | undefined) => void
}
export const usePaymentList = (props: IProps) => {
  const { onSuccess } = props
  const {
    urlQuery: { token, bizId },
    uPubSub,
  } = useContext(ParamsContext)

  const {
    data: { riskInfoObj },
  } = useContext(GlobalInfo)

  const {
    data: paymentListRes,
    loading: getPaymentListLoading,
    refresh: refreshPaymentList,
  } = useRequest(
    () =>
      api.getPayoutPaymentList(
        {
          token,
          riskInfo: SafeJSON.stringify(riskInfoObj),
          terminalEquip: NEW_SDK_TERMINAL_EQUIP,
        },
        {
          extraHandle: {
            uPubSub,
          },
          bizId,
        },
      ),
    {
      auto: true,
      onSuccess: (res) => onSuccess?.(res.data),
    },
  )

  return { paymentList: paymentListRes?.data || ({} as IPIPaymentListRes), getPaymentListLoading, refreshPaymentList }
}
