import { useContext, useEffect } from 'react'

import { StatusActionTypeEnum } from '@upay/utils/es/types'

import { PUBSUB_TYPES } from '@/constant'
import useRecorder, { RECORDER_EVENT_HUB } from '@/hooks/recorder'
import { ParamsContext } from '@/stores'

import { payLogger } from '..'

const usePayRecorder = () => {
  const { urlQuery, uPubSub } = useContext(ParamsContext)
  const { accountId } = urlQuery
  const { recorderStart, recorderStopAndSend } = useRecorder()
  useEffect(() => {
    recorderStart(accountId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    try {
      const subId = uPubSub.subscribe(PUBSUB_TYPES.MODAL_CANCEL, () => recorderStopAndSend(RECORDER_EVENT_HUB.USER_LEAVE))
      const subId1 = uPubSub.subscribe(PUBSUB_TYPES.STATUS_RESULT, (msg, data: Record<string, any>) => {
        let sendMsg = ''
        // 只对展示完结果后用户主动点击或者倒计时三秒后进行处理
        if (!data?.clickBtn) return
        if (data?.actionType === StatusActionTypeEnum.PAY) {
          // 只有在支付成功或者支付失败用户点击cancel的时候上报录制
          sendMsg = data?.status ? RECORDER_EVENT_HUB.PAY_SUCCESS : data?.retry ? '' : RECORDER_EVENT_HUB.PAY_FAIL
        }
        sendMsg && recorderStopAndSend(sendMsg)
      })
      return () => {
        uPubSub.unsubscribe(subId)
        uPubSub.unsubscribe(subId1)
      }
    } catch (e) {
      payLogger.warn('pay oolongTea recorder error', e)
    }
  })
}
export default usePayRecorder
