import React, { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react'

import { useRequest } from '@byted/hooks'

import { PAY_WAY_TYPE } from '@/constant'
import { TossForm } from '@/pipo/pc'
import { PipoComponentRef } from '@/pipo/utils/types'
import api from '@/services'
import { ParamsContext } from '@/stores'
import SafeJSON from '@/utils/SafeJSON'

import { IPaymentMethodsProps, IPaymentsMethodRef } from '../../interface'

const Toss = forwardRef<IPaymentsMethodRef, IPaymentMethodsProps>((props, ref): React.ReactElement => {
  const { changeNewPaymentStatus } = props
  const {
    urlQuery: { token },
  } = useContext(ParamsContext)

  const tossRef = useRef<PipoComponentRef>(null)
  const [tossValid, setTossValid] = useState(false)

  const { data: bankList } = useRequest(
    () => api.getBindElements({ token, payWay: PAY_WAY_TYPE.TOSS }).then((res) => res?.data?.elements?.[0]?.option_list || []),
    {
      auto: true,
    },
  )

  // 同步验证状态
  useEffect(() => {
    changeNewPaymentStatus(PAY_WAY_TYPE.TOSS, { valid: tossValid })
  }, [tossValid, changeNewPaymentStatus])

  useImperativeHandle(ref, () => ({
    processSubmitData: async (submitParams) => {
      if (tossRef.current?.validateSubmitData) {
        const { paymentMethod, paymentParams } = await tossRef.current?.validateSubmitData()
        const paymentMethodObj = {
          method_id: paymentMethod.paymentMethodId,
          payment_elements: paymentParams,
        }
        submitParams.channelParameter = SafeJSON.stringify(paymentMethodObj)
      }
      return submitParams
    },
  }))

  const onTossValid = useCallback((value: boolean) => {
    setTossValid(value)
  }, [])

  return <TossForm ref={tossRef} onValidate={onTossValid} bankList={bankList} />
})

export default Toss
