import { IBindCardRes, ISubmitOrderRes } from '@upay/utils/es/types'

import { EVENT_TYPE, EXTERNAL_RESPONSE_CODE } from '@/constant'
import { PaymentDetail, SupportPaymentMethodId } from '@/pipo/utils'
import apiHandlerGenerator, { ICustomRequestOptions, IRespData } from '@/utils/request'
import starling from '@/utils/starling'

import apiPayout from './payout-service'
import {
  BillInfoRes,
  CardDetail,
  CardListRes,
  ChannelRes,
  FailedChannelsRes,
  IBankList,
  IBindCardParams,
  IBindCardSupplementParams,
  ICardTransferRes,
  ICardUpdateReq,
  IGetBindResultParams,
  IGetContractAddressRes,
  IGetPayResultParams,
  IGetResultRes,
  IJudgeSdkVersionReq,
  IJudgeSdkVersionRes,
  ILoginAuthRes,
  IPIPaymentListRes,
  IPublicKeyRes,
  IRiskInfoRes,
  ISharkJudgeParams,
  ISharkJudgeRes,
  ISubmitExtraInfoParams,
  ISubmitOrderParams,
  ITransferableCardItem,
  PreOrderRes,
} from './types'

// 获取错误信息
const getLoginErrorMsg = (): string => starling('funds.refund.comm.system_login_error')

const api = {
  /**获取卡列表 */
  getCardList: apiHandlerGenerator<{ token: string; type?: string }, CardListRes>({
    url: '/card/list',
  }),
  /**设置初始卡 */
  primaryCard: apiHandlerGenerator<{ token: string; id: string }, null>({
    url: '/card/primary',
    method: 'POST',
  }),
  /**删除卡初始卡 */
  removeCard: apiHandlerGenerator<{ token: string; id: string }, null>({
    url: '/card/remove',
    method: 'POST',
  }),
  /** 更新卡信息 */
  updateCard: apiHandlerGenerator<ICardUpdateReq, IBindCardRes>({
    url: '/card/update',
    method: 'POST',
  }),
  // 预付下单接口查询
  getPreOrder: apiHandlerGenerator<{ token: string }, PreOrderRes>({
    url: `/payment/pre_order`,
    method: 'GET',
    withJWT: true,
  }),
  // 获取订单信息
  getBillInfo: apiHandlerGenerator<{ token: string }, BillInfoRes>({
    url: `/protocol/bill_info`,
    method: 'GET',
  }),
  // 支付渠道查询
  getPayWays: apiHandlerGenerator<{ token: string; eventType: EVENT_TYPE; riskInfo?: string; terminalEquip: number }, ChannelRes>({
    url: `/payment/payways`,
    method: 'POST',
    withJWT: true,
  }),
  // 获取商户号对应公钥
  getPublicKey: apiHandlerGenerator<any, IPublicKeyRes>({
    url: `/payment/merchant_info`,
    method: 'GET',
  }),
  // 预付选择已绑卡支付 & 非ccdc新支付方式支付
  submitOrder: apiHandlerGenerator<ISubmitOrderParams, ISubmitOrderRes>({
    url: `/payment/submit_order`,
    method: 'POST',
    withJWT: true,
  }),
  // 转发pipo pay接口，应用于验证码场景
  submitExtraInfo: apiHandlerGenerator<ISubmitExtraInfoParams, ISubmitOrderRes>({
    url: `/payment/submit_extra_info`,
    method: 'POST',
    withJWT: true,
    withError: false,
  }),
  // 用户登录态授权 调用用户中心的接口
  getLoginAuth: apiHandlerGenerator<{ aid: string }, ILoginAuthRes>({
    url: `/passport/auth/login_authorize/`,
    method: 'GET',
    withPrefix: false,
    checkResponse: (res) => {
      if (res?.message !== EXTERNAL_RESPONSE_CODE.LOGIN_SUCCESS) {
        return {
          code: res?.data?.error_code,
          msg: res?.data?.description || getLoginErrorMsg(),
        }
      }
    },
  }),
  // TODO 按照接入规范，web端调用应该使用此路径，后续全切换过来
  getWebLoginAuth: apiHandlerGenerator<{ aid: string }, ILoginAuthRes>({
    url: `/passport/web/auth/login_authorize/`,
    method: 'GET',
    withPrefix: false,
    checkResponse: (res) => {
      if (res?.message !== EXTERNAL_RESPONSE_CODE.LOGIN_SUCCESS) {
        return {
          code: res?.data?.error_code,
          msg: res?.data?.description || getLoginErrorMsg(),
        }
      }
    },
  }),
  // 将国际支付的错误码转成给用户的报错提示
  getErrorMsg: apiHandlerGenerator<{ errorCode: string; language: string }, string>({
    url: `/payment/error_translate`,
    method: 'GET',
  }),
  // 支付方式变更
  paymentTransfer: apiHandlerGenerator<any, any>({
    url: `/protocol/payment_transfer`,
    method: 'POST',
  }),
  // 支付方式变更提交
  transferSubmit: apiHandlerGenerator<any, any>({
    url: `/protocol/transfer_submit`,
    method: 'POST',
  }),
  // 绑卡提交合一接口
  bindCard: apiHandlerGenerator<IBindCardParams, IBindCardRes>({
    url: `/card/bind`,
    method: 'POST',
    withJWT: true,
  }),
  // 绑卡提交补充信息接口 如验证码等
  bindCardSupplement: apiHandlerGenerator<IBindCardSupplementParams, IBindCardRes>({
    url: `/card/bind_supplement`,
    method: 'POST',
    withJWT: true,
    withError: false,
  }),
  // 查询支付单信息
  getOrderInfo: apiHandlerGenerator<{ token: string }, PreOrderRes>({
    url: `/payment/order_info`,
    method: 'GET',
  }),
  // 查询卡信息
  getCardDetail: apiHandlerGenerator<{ cardId: string; token: string }, CardDetail>({
    url: `/card/detail`,
    method: 'GET',
    withError: false,
  }),
  /**获取故障信息提示 */
  getFailedChannels: apiHandlerGenerator<{ token: string }, FailedChannelsRes>({
    url: '/payment/failed_channels',
    withError: false,
  }),
  /** 查询支持迁移的卡列表 */
  getTransferableCardList: apiHandlerGenerator<{ token: string }, ITransferableCardItem[] | any>({
    url: '/card/get_transferable_card',
    method: 'POST',
  }),
  /** 提交迁移已绑卡 */
  postCardTransfer: apiHandlerGenerator<{ token: string; cardIds: string[] }, ICardTransferRes>({
    url: '/card/transfer',
    method: 'POST',
    withError: false,
  }),
  // 查询支付状态
  getPayResult: apiHandlerGenerator<IGetPayResultParams, IGetResultRes>({
    url: `/transaction/result`,
    method: 'GET',
  }),
  // 查询绑卡状态
  getBindResult: apiHandlerGenerator<IGetBindResultParams, IGetResultRes>({
    url: `/card/bind/result`,
    method: 'GET',
  }),
  /**查询bankTransfer绑定银行卡列表 */
  getBindElements: apiHandlerGenerator<{ token: string; payWay: number }, IBankList>({
    url: '/payment/get_bind_elements',
  }),
  /** 获取Direct Debit EU的mandate信息 */
  getMandate: apiHandlerGenerator<any, string>({
    url: '/parameter/mandate',
    method: 'GET',
  }),
  /** 查询paymentdetail */
  getPaymentDetail: apiHandlerGenerator<{ token: string; recordNo: string }, PaymentDetail>({
    url: '/payment/detail',
    method: 'GET',
  }),

  // 特殊：埋点请求
  postRecord: apiHandlerGenerator<any, any>({
    url: `/action/record/add`,
    method: 'POST',
  }),
  /** 查询该卡是否被风控 */
  sharkJudge: apiHandlerGenerator<ISharkJudgeParams, ISharkJudgeRes>({
    url: `/risk/shark_judge`,
    method: 'POST',
    withError: false,
  }),
  // 协议绑卡控制开关
  hitAgreement: apiHandlerGenerator<{ token: string }, boolean>({
    url: `/card/hit_agreement`,
    method: 'GET',
    withError: false,
  }),
  // 获取合同地址
  getContractAddress: apiHandlerGenerator<{ token: string }, IGetContractAddressRes>({
    url: `/parameter/billing`,
    method: 'GET',
  }),
  // 获取风控金额信息
  queryRiskAmount: apiHandlerGenerator<{ token: string; payWay: number; riskInfo: string; cardId: string }, IRiskInfoRes>({
    url: `/card/queryRiskAmount`,
    method: 'POST',
    withError: false,
  }),
  // 获取已绑卡详情
  getCardInfo: apiHandlerGenerator<{ token: string; id: string }, CardListRes>({
    url: `/card/query`,
    method: 'GET',
  }),
  /** PayNext 获取Payin支付方式列表 */
  getPIPaymentList: apiHandlerGenerator<{ token: string; riskInfo: string; terminalEquip: number }, IPIPaymentListRes>({
    url: `/pi/payment/list`,
    method: 'POST',
    withJWT: true,
  }),
  // 获取CCDC卡组信息
  getCardOrgList: apiHandlerGenerator<{ token: string }, SupportPaymentMethodId[]>({
    url: `/parameter/card_organization`,
    method: 'GET',
  }),
  /** 判断是否需要展示版本更新提示 */
  judgeSdkVersion: apiHandlerGenerator<IJudgeSdkVersionReq, IJudgeSdkVersionRes>({
    url: `/record/is_old_version`,
    method: 'GET',
  }),
  /** 上报报错信息 */
  reportFailReason: apiHandlerGenerator<
    {
      errorCode: string
      errorMessage: string
      refId: string
      refType: string
      token: string
    },
    any
  >({
    url: `/record/pipo/response`,
    method: 'POST',
  }),
  // 特殊：自定义创建一个api请求
  customAPI: <T>(config: {
    url: string
    method: 'GET' | 'POST'
  }): ((params?: any, options?: ICustomRequestOptions | undefined) => Promise<T | IRespData<any>>) =>
    apiHandlerGenerator<any, any>({
      url: config.url,
      method: config.method,
      withPrefix: false,
    }),
}

export default {
  ...api,
  ...apiPayout,
}
