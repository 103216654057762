import { useContext, useEffect, useState } from 'react'

import { Message } from '@arco-design/web-react'

import { EVENT_TYPE, NEW_SDK_TERMINAL_EQUIP } from '@/constant'
import api from '@/services'
import { ChannelRes } from '@/services/types'
import { ParamsContext } from '@/stores'
import GlobalInfo from '@/stores/GlobalInfo'
import SafeJSON from '@/utils/SafeJSON'

interface IProps {
  visible: boolean
  setErrorMsg: (msg: string) => void
}
export const useChangePayWays = (props: IProps) => {
  const { visible, setErrorMsg } = props
  const { urlQuery, uPubSub } = useContext(ParamsContext)
  const { token, bizId } = urlQuery
  const {
    data: { riskInfoObj },
  } = useContext(GlobalInfo)

  const [payWays, setPayWays] = useState<ChannelRes[]>([])
  const [payWaysReady, setPayWaysReady] = useState(false)

  useEffect(() => {
    visible && getPayWays()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  const getPayWays = async () => {
    api
      .getPayWays(
        { token, eventType: EVENT_TYPE.BIND, riskInfo: SafeJSON.stringify(riskInfoObj), terminalEquip: NEW_SDK_TERMINAL_EQUIP },
        {
          extraHandle: {
            uPubSub,
          },
          bizId,
        },
      ) // 1-支付 2-绑卡
      .then((res) => {
        setPayWaysReady(true)
        setPayWays(res.list || [])
      })
      .catch((e) => {
        setErrorMsg(e?.msg || SafeJSON.stringify(e))
        Message.error(e?.msg || SafeJSON.stringify(e))
      })
  }

  return { getPayWays, payWays, payWaysReady }
}
