import { BankListSelect, Email, HolderName, ItemInput, ItemInputNumber } from '@/pipo/pc/inner-components'
import { validator } from '@/pipo/utils'
import starling from '@/utils/starling'

import { IBankAccountFormConfigItem, IGetBankAccountFormConfigProps } from '../utils'

export const getKRFormConfig = ({ bankList, cardHolderInfo }: IGetBankAccountFormConfigProps): IBankAccountFormConfigItem => {
  return {
    allowAccountType: ['b', 'c'],
    needInfo: ['bank_list'],
    formSchema: [
      {
        field: 'bank_code',
        widget: BankListSelect,
        widgetProps: {
          title: starling('funds.check_out.comm.channel_bank'),
          placeholder: starling('funds.refund.comm.bank_selection'),
          bankList,
        },
        rules: { required: true },
      },
      {
        field: 'holder_name',
        widget: HolderName,
        widgetProps: {
          title: starling('funds.refund.comm.beneficiary_name'),
          placeholder: starling('funds.refund.comm.system_name_description'),
          maxLength: 35,
          selectList: cardHolderInfo?.holderNameList,
        },
        rules: { required: true, pattern: validator.nameRegex },
      },
      {
        field: 'account_no',
        widget: ItemInputNumber,
        widgetProps: {
          title: starling('funds.refund.comm.system_bankaccount_account_number'),
          placeholder: starling('funds.refund.comm.system_bankaccount_account_number_placeholder'),
          maxLength: 15,
        },
        rules: { required: true, pattern: validator.krBankAccountNo },
      },
      {
        field: 'email',
        widget: Email,
        widgetProps: {
          title: starling('funds.refund.comm.system_email'),
          placeholder: starling('funds.refund.comm.system_email_placeholder'),
        },
        rules: { required: true, pattern: validator.emailRegex },
      },
    ],
  }
}
