"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.encryptString = void 0;
var _publicEncrypt = require("public-encrypt");
// @ts-ignore
var encryptString = function encryptString(plaintext, publicKey) {
  if (!plaintext) return plaintext;
  var encrypted = (0, _publicEncrypt.publicEncrypt)(publicKey, Buffer.from(plaintext));
  return encrypted.toString('base64');
};
exports.encryptString = encryptString;