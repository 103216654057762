import { v4 as uuidv4 } from 'uuid'

interface RiskFields {
  user_agent: string
  referer: string
  cookie_enabled: boolean
  screen_width: number
  screen_height: number
  browser_language: string
  browser_platform: string
  browser_name: string
  browser_version: string
  browser_online: boolean
  timezone_name: string
  device_fingerprint_id: string
}

export const getRiskFields = (): RiskFields => {
  return {
    user_agent: navigator.userAgent,
    referer: document.referrer,
    cookie_enabled: navigator.cookieEnabled,
    screen_width: window.screen.width,
    screen_height: window.screen.height,
    browser_language: navigator.language,
    browser_platform: navigator.platform,
    browser_name: navigator.appCodeName,
    browser_version: navigator.appVersion,
    browser_online: navigator.onLine,
    timezone_name: new Intl.DateTimeFormat().resolvedOptions().timeZone,
    device_fingerprint_id: initCyberAndGetSessionId(),
  }
}

const initCyberAndGetSessionId = (): string => {
  const PREFIX = 'bytedance'
  const ORG_ID = 'k8vif92e'
  const SCRIPT_ID = 'upay_web_sdk_tags_js'
  let SESSION_ID = uuidv4()
  if (typeof window !== 'undefined') {
    const sessionScript = window.document.getElementById(SCRIPT_ID)
    if (!sessionScript) {
      const script = window.document.createElement('script')
      const head = window.document.getElementsByTagName('head')[0]
      script.setAttribute('data-session-id', SESSION_ID)
      script.id = SCRIPT_ID
      script.type = 'text/javascript'
      script.src = `https://h.online-metrix.net/fp/tags.js?org_id=${ORG_ID}&session_id=${PREFIX}${SESSION_ID}`
      head.appendChild(script)
    } else {
      SESSION_ID = sessionScript.getAttribute('data-session-id')!
    }
  }
  return SESSION_ID
}
