"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.getPostalCodeRules = getPostalCodeRules;
// TODO: 移到 use-postal-code 里
function getPostalCodeRules(code) {
  var postalCodeRules;
  switch (code) {
    // Brasil
    case 'BR':
      {
        postalCodeRules = '^[0-9]{8}$';
        break;
      }
    default:
      {
        postalCodeRules = '';
        break;
      }
  }
  return postalCodeRules;
}