import React, { MutableRefObject, useCallback, useRef, useState } from 'react'
import type { Control, FieldError, FieldErrors, UseFormSetValue, UseFormTrigger } from 'react-hook-form'

import { PipoProvider } from '@/pipo/utils'
import type { CCDCFormData, InputHandleRef } from '@/pipo/utils'
import starling from '@/utils/starling'

type IProps = {
  errors: FieldErrors<CCDCFormData>
  control: Control<CCDCFormData, any>
  trigger: UseFormTrigger<CCDCFormData>
  setValue?: UseFormSetValue<CCDCFormData>
}

type ErrorType = 'required' | 'twoDigits' | 'validMonth' | 'validYear' | 'notExpired'

type Validate = (value: string | undefined) => boolean

const nowYear = new Date().getFullYear() - 2000
const nowMonth = new Date().getMonth() + 1

export function useExpiryDate(props: IProps): {
  monthValidator: {
    errorYear: Validate
    twoDigits: Validate
    validMonth: Validate
    notExpired: Validate
  }
  yearValidator: {
    twoDigits: Validate
    validYear: Validate
    notExpired: Validate
  }
  monthHandleRef: MutableRefObject<InputHandleRef | undefined>
  yearHandleRef: MutableRefObject<InputHandleRef | undefined>
  monthInputRef: MutableRefObject<HTMLInputElement | null>
  yearInputRef: MutableRefObject<HTMLInputElement | null>
  focused: boolean
  handleMonthChange: (e: React.ChangeEvent<HTMLInputElement>, callback: (...event: unknown[]) => void) => void
  handleYearChange: (e: React.ChangeEvent<HTMLInputElement>, callback: (...event: unknown[]) => void) => void
  handleMonthKeyUp: (e: React.KeyboardEvent<HTMLInputElement>) => void
  handleYearKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void
  handleBlur: (e: React.FocusEvent<HTMLInputElement>, field: string) => void
  handleFocus: () => void
  handleClear: () => void
  getExpiryDateError: (monthError: FieldError | undefined, yearError: FieldError | undefined) => string
} {
  const { errors, setValue } = props
  const { t, getErrorText } = PipoProvider.useContext()
  const [focused, setFocused] = useState(false)
  // 光标正在两个input间移动
  const cursorMovingRef = useRef(false)
  const monthHandleRef = useRef<InputHandleRef>()
  const yearHandleRef = useRef<InputHandleRef>()
  const monthInputRef = useRef<HTMLInputElement>(null)
  const yearInputRef = useRef<HTMLInputElement>(null)
  const monthValueRef = useRef('')
  const yearValueRef = useRef('')
  const yearErrorRef = useRef<boolean>(false)

  const handleMonthChange = (e: React.ChangeEvent<HTMLInputElement>, callback: (...event: unknown[]) => void): void => {
    const newVal = e?.target?.value || ''
    // handle user paste actions
    if (newVal.length > 2) {
      const pasteMonth = newVal.slice(0, 2)
      const pasteYear = newVal.slice(2, 4)
      monthHandleRef.current?.setInnerValue?.(pasteMonth)
      yearHandleRef.current?.setInnerValue?.(pasteYear)
      monthValueRef.current = pasteMonth
      yearValueRef.current = pasteYear
      setValue?.('expiration_month', pasteMonth)
      setValue?.('expiration_year', pasteYear)
    } else {
      monthValueRef.current = newVal
      callback(newVal)
    }
  }
  const handleYearChange = (e: React.ChangeEvent<HTMLInputElement>, callback: (...event: unknown[]) => void): void => {
    const newVal = e?.target?.value || ''
    yearValueRef.current = newVal
    callback(newVal)
  }
  const handleMonthKeyUp = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if ((e.target as HTMLInputElement).value.length === 2 && e.keyCode !== 8 && yearInputRef.current) {
      cursorMovingRef.current = true
      yearInputRef.current.focus()
    }
  }
  const handleYearKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    // 退格键且光标在首位
    if (e.keyCode === 8 && (e.target as HTMLInputElement).selectionStart === 0 && monthInputRef.current) {
      cursorMovingRef.current = true
      monthInputRef.current.focus()
    }
  }

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>, field: string) => {
    // HACK 需要延迟，不然这里会导致清空图标隐藏，触发不了清空点击
    setTimeout(() => setFocused(false))
  }

  const handleFocus = () => {
    setTimeout(() => {
      setFocused(true)
      cursorMovingRef.current = false
    })
  }

  const handleClear = () => {
    if (monthHandleRef.current) {
      monthHandleRef.current.clear()
    }
    monthInputRef?.current?.focus()
  }

  const monthValidator = {
    errorYear: (value?: string) => {
      if (yearErrorRef.current && errors.expiration_year) {
        return false
      }
      return true
    },
    twoDigits: (value?: string) => {
      return value ? /^[0-9]{2}$/.test(value) : false
    },
    validMonth: (value?: string) => {
      const month = Number(value)
      return month >= 1 && month <= 12
    },
    notExpired: (value?: string) => {
      const month = Number(value)
      if (cursorMovingRef.current) {
        return true
      }
      const yearValue = Number(yearValueRef.current)
      if ((yearValue === nowYear && month < nowMonth) || yearValue < nowYear) {
        return false
      }
      return true
    },
  }

  const yearValidator = {
    twoDigits: (value?: string) => {
      if (cursorMovingRef.current) {
        yearErrorRef.current = false
        return true
      }
      return value ? /^[0-9]{2}$/.test(value) : false
    },
    validYear: (value?: string) => {
      if (cursorMovingRef.current) {
        yearErrorRef.current = false
        return true
      }
      const year = Number(value)
      if (year > 15 + nowYear) {
        yearErrorRef.current = true
        return false
      }
      return true
    },
    notExpired: (value?: string) => {
      if (cursorMovingRef.current) {
        yearErrorRef.current = false
        return true
      }
      const monthValue = Number(monthValueRef.current)
      const year = Number(value)
      if (year < nowYear || (year === nowYear && monthValue < nowMonth)) {
        yearErrorRef.current = true
        return false
      }
      yearErrorRef.current = false
      return true
    },
  }

  const getExpiryDateError = useCallback(
    (monthError: FieldError | undefined, yearError: FieldError | undefined): string => {
      const errorType = (yearError?.type || monthError?.type) as ErrorType
      switch (errorType) {
        case 'required':
          return getErrorText(errorType)
        case 'twoDigits':
          return getErrorText(errorType)
        case 'validMonth':
          return starling('funds.refund.comm.system_invalidformat')
        case 'validYear':
          return starling('funds.refund.comm.system_validitydate_toofar')
        case 'notExpired':
          return starling('funds.refund.comm.system_expired')
        default:
          return ''
      }
    },
    [getErrorText, t],
  )
  return {
    monthValidator,
    yearValidator,
    monthHandleRef,
    yearHandleRef,
    monthInputRef,
    yearInputRef,
    focused,
    getExpiryDateError,
    handleMonthChange,
    handleYearChange,
    handleMonthKeyUp,
    handleYearKeyDown,
    handleBlur,
    handleFocus,
    handleClear,
  }
}
