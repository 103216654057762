import React, { useEffect, useState } from 'react'

import { OrderStatus } from '@/pipo/utils'

import { DefaultVoucherProps } from '../..'
import { AmountCard } from '../../amount-card'
import { ExpiredMask } from '../../expired-mask'
import { QrCode } from '../../qr-code'
import { TradingStatus } from '../../trading-status'

export const PromptPayVoucher = (props: DefaultVoucherProps) => {
  const {
    amount,
    expiration_time: expirationTime,
    payment_method_info: paymentMethodInfo,
    present_to_shopper_details: presentToShopperDetails,
    upayResult,
    merchant_order_id,
  } = props.paymentDetail
  const { payment_method_id: paymentMethodId } = paymentMethodInfo!

  const { onExpire } = props

  const [orderStatus, setOrderStatus] = useState<OrderStatus>('init')
  useEffect(() => {
    setOrderStatus(props.paymentDetail.orderStatus)
  }, [props.paymentDetail.orderStatus])

  return (
    <div className="pipo-pc-voucher">
      <TradingStatus
        orderStatus={orderStatus}
        upayResult={upayResult}
        expirationTime={expirationTime}
        onTimeUp={onExpire}
        orderNo={merchant_order_id}
      />

      <ExpiredMask orderStatus={orderStatus}>
        <AmountCard title="PromptPay" amount={amount} iconUrl={presentToShopperDetails?.icon_url} />
        <QrCode size="large" paymentMethodId={paymentMethodId} shopperDetails={presentToShopperDetails} />
      </ExpiredMask>
    </div>
  )
}
