import React, { forwardRef, useEffect, useState } from 'react'
import { Controller, ControllerFieldState, useForm } from 'react-hook-form'

import { StatusActionTypeEnum } from '@upay/utils/es/types'
import classNames from 'clsx'

import FormBuilder from '@/components/FormBuilder'
import { PAY_WAY_TYPE } from '@/constant'
import {
  useAddressPC,
  useCCDCFormConfig,
  useCardNumber,
  usePipoRouter,
  usePostalCode,
  useProxyType,
  useSecurityCode,
  useSubmittingState,
  useTouchedErrors,
} from '@/pipo/hooks'
import {
  CCDCFormData,
  CCDCProps,
  CCDC_PAYMENT_PARAMS_MAP,
  PaymentParamsItem,
  PipoComponentRef,
  PipoProvider,
  PipoSubmitParams, // callingCode,
  SUPPORT_PAYMENT_METHODS_ID_MAP,
  cnpj,
  countryToISOCode,
  cpf,
  generateCommonPaymentParams,
  pickNumeric,
  validator,
} from '@/pipo/utils'
import starling from '@/utils/starling'
import teaClient from '@/utils/tea'

import { BillingAddress, CNPJ, CPF, CVV, CardNumber, Email, ExpiryDate, HolderName, PhoneNumber, ProxySelect, SaveBox } from '../../inner-components'
import CallingCodeSelect from '../../inner-components/calling-code-select'
import { getCCDCFormConfig } from './config/index'

const clsPrefix = 'pipo-bindcard-pc'

export const CCDC = forwardRef((props: CCDCProps, ref: React.ForwardedRef<PipoComponentRef>) => {
  const {
    onSubmit,
    onFetchDistrict,
    onValidate = () => {
      // do nothing.
    },
    onValidateCardBin,
    contractAddressList,
    submitType,
  } = props
  const form = useForm<CCDCFormData>({
    mode: 'all',
    defaultValues: {
      phone_country_code: '55',
    },
  })

  const {
    control,
    handleSubmit: handleFormSubmit,
    getValues,
    setValue,
    trigger,
    setError,
    clearErrors,
    watch,
    unregister,
    formState: { errors: formErrors, isValid: _isValid, touchedFields },
  } = form

  const errors = useTouchedErrors<CCDCFormData>({
    touchedFields,
    errors: formErrors,
  })
  const { state, isFlip, getErrorText } = PipoProvider.useContext()
  const { countryOrRegion = '', config = {} } = state

  const { selectedSaveBox = false } = config

  const {
    cardNumberInputRef,
    cardBrands,
    cardNoValidator,
    currentCardBrand,
    cardNoPlaceholder,
    cardNoMaxLength,
    getCardNoError,
    onCardNoChange,
    formatValue: formatCardNo,
    formatCursor,
    binRiskPass,
    setBinRiskPass,
  } = useCardNumber(props)

  const isValid = _isValid && binRiskPass

  const { defaultProxyType, proxyTypeTitle, onProxyTypeChange } = useProxyType(unregister)

  // Get phone country code
  // const phoneCountryCode = callingCode.list[countryOrRegion].code;
  const [storeCard, setStoreCard] = useState<boolean>(selectedSaveBox)
  const billingAddressProps = useAddressPC({
    onFetchDistrict,
    getValues,
    setValue,
    trigger,
    clearErrors,
    setError,
    watch,
    formErrors,
    countryOrRegion,
    contractAddressList,
  } as any)
  const { list, selectedAddressArr, selectedContractAddress, getFillAddressState } = billingAddressProps
  const postalCodeProps = usePostalCode({
    selectedAddressArr,
    watch,
    trigger,
  } as any)

  const { cvvPlaceholder, cvvValidator, cvvTitle, getCVVError } = useSecurityCode(currentCardBrand, trigger, getValues)

  const { hasSavingBox, hasCNPJ, hasPhoneNumber, hasEmail, hasProxyType, hasCPF, renderCNPJ, showBillingAddress } = useCCDCFormConfig(props, watch)

  const formConfig = getCCDCFormConfig({
    countryCode: countryOrRegion,
    postalCodeProps,
    billingAddressProps,
  })
  const formSchema = formConfig?.formSchema

  useEffect(() => {
    isValid && onValidate(true)
  }, [isValid, onValidate])

  const { isSubmitting, formSubmitState } = useSubmittingState()

  /**
   * 处理PIPO提交所需数据
   * @returns PipoSubmitParams
   */
  const processSubmitData = (data: CCDCFormData): PipoSubmitParams => {
    delete data.contract_address // 删除内部使用字段
    if (selectedContractAddress) {
      data.billing_street = selectedContractAddress.detailAddress
      data.billing_country_region = selectedContractAddress.countryCode
      data.billing_postal_code = selectedContractAddress.postcode
      data.billing_state = selectedContractAddress.level2Name
      data.billing_city = selectedContractAddress.level3Name
    } else if (data.billing_country_region && list[0].list) {
      data.billing_country_region = countryToISOCode(data.billing_country_region, list[0].list)
    }

    if (data.card_number) {
      data.card_number = pickNumeric(data.card_number)
    }
    if (data.identity) {
      data.identity = pickNumeric(data.identity)
    }
    if (data.company_id) {
      data.company_id = pickNumeric(data.company_id)
    }
    const paymentParams: PaymentParamsItem[] = generateCommonPaymentParams(data, CCDC_PAYMENT_PARAMS_MAP)
    const currentPaymentMethodId = currentCardBrand?.paymentMethod?.paymentMethodId || 'pm_pi_ccdc_visa_c_d'
    const { paymentMethod, paymentMethodId } =
      // @ts-ignore
      SUPPORT_PAYMENT_METHODS_ID_MAP[`${currentPaymentMethodId}`]?.paymentMethod

    return {
      formData: data,
      paymentMethod: {
        paymentMethod,
        paymentMethodId,
      },
      paymentMethodType:
        // @ts-ignore
        SUPPORT_PAYMENT_METHODS_ID_MAP[`${currentPaymentMethodId}`]?.paymentMethodType?.paymentMethodType,
      paymentParams,
      storeCard: storeCard && hasSavingBox,
      fillAddressState: showBillingAddress ? getFillAddressState() : undefined,
    }
  }

  /**
   * 校验后获取PIPO提交所需数据
   * @returns PipoSubmitParams
   */
  const validateSubmitData = () => {
    return new Promise((resolve: (value: PipoSubmitParams) => void, reject: (reason: typeof errors) => void) => {
      if (!isValid) {
        reject(errors)
      } else {
        const formData = getValues()
        resolve(processSubmitData(formData))
      }
    })
  }

  const handleSubmit = async (data: CCDCFormData): Promise<void> => {
    const submitData = processSubmitData(data)
    formSubmitState.setTrue()
    await onSubmit?.(submitData)
    formSubmitState.setFalse()
  }

  usePipoRouter(ref, {
    initPath: 'ccdc',
    initParams: {
      isValid,
      onValidate,
    },
    instanceMethods: {
      submit: handleFormSubmit(handleSubmit),
      validateSubmitData,
    },
  })

  const handleBlur = (scenario: string, fieldState: ControllerFieldState) => {
    const params = {
      pay_way: PAY_WAY_TYPE.CREATE_NEW_CARD,
      scenario,
      is_valid: !fieldState.error,
      invalid_reason: fieldState.error?.type,
    }
    if (submitType === StatusActionTypeEnum.PAY) {
      teaClient.sendPayPageFillIn(params)
    } else {
      teaClient.sendBindPageFillIn(params)
    }
  }

  return (
    <div className={classNames('pipo-pc', { 'pipo-pc-flip': isFlip })} dir={isFlip ? 'rtl' : 'ltr'} id="upay-form-ccdc">
      <div className={`${clsPrefix}-flex-container`}>
        <div className={`${clsPrefix}-form-wrapper`}>
          <div className={`${clsPrefix}-subform-wrapper`}>
            <Controller
              rules={{
                required: true,
                validate: cardNoValidator,
              }}
              name="card_number"
              control={control}
              render={({ field, fieldState }) => (
                <CardNumber
                  {...field}
                  ref={cardNumberInputRef}
                  title={starling('funds.refund.comm.system_Card_number')}
                  hasError={Boolean(errors.card_number)}
                  errorMessage={getCardNoError(errors.card_number)}
                  placeholder={cardNoPlaceholder}
                  maxLength={cardNoMaxLength}
                  cardBrands={cardBrands}
                  currentCardBrand={currentCardBrand}
                  formatValue={formatCardNo}
                  formatCursor={formatCursor}
                  onChange={(e) => onCardNoChange(e, field.onChange)}
                  formError={formErrors.card_number}
                  setError={setError}
                  clearErrors={clearErrors}
                  binRiskPass={binRiskPass}
                  setBinRiskPass={setBinRiskPass}
                  onValidateCardBin={onValidateCardBin}
                  onBlur={() => {
                    field.onBlur()
                    handleBlur('card_number', fieldState)
                  }}
                />
              )}
            />
            <ExpiryDate
              title={starling('funds.refund.comm.system_expiry_date')}
              errors={errors}
              control={control}
              trigger={trigger}
              setValue={setValue}
            />
            <Controller
              rules={cvvValidator}
              name="cvv"
              control={control}
              render={({ field, fieldState }) => (
                <CVV
                  {...field}
                  title={cvvTitle}
                  placeholder={cvvPlaceholder}
                  hasError={Boolean(errors.cvv)}
                  errorMessage={getCVVError(errors.cvv)}
                  onBlur={() => {
                    field.onBlur()
                    handleBlur('cvv', fieldState)
                  }}
                />
              )}
            />
            <Controller
              rules={{ required: true, pattern: validator.nameRegex }}
              name="holder_name"
              control={control}
              render={({ field, fieldState }) => (
                <HolderName
                  {...field}
                  error={errors.holder_name}
                  errorMessage={getErrorText(errors.holder_name?.type)}
                  title={starling('funds.refund.comm.system_Cardholder_name')}
                  placeholder={starling('funds.refund.comm.system_Cardholder_name_format')}
                  onBlur={() => {
                    field.onBlur()
                    handleBlur('holder_name', fieldState)
                  }}
                />
              )}
            />
            {hasPhoneNumber && (
              <Controller
                rules={{ required: true, pattern: validator.phoneRegex }}
                name="phone"
                control={control}
                render={({ field, fieldState }) => (
                  <PhoneNumber
                    {...field}
                    title={starling('funds.refund.comm.system_phonenumber')}
                    placeholder={starling('funds.refund.comm.system_phonenumber_placeholder')}
                    error={errors.phone}
                    errorMessage={getErrorText(errors.phone?.type)}
                    addBefore={<Controller name="phone_country_code" control={control} render={({ field }) => <CallingCodeSelect {...field} />} />}
                    phoneNumberStyle="half"
                    onBlur={() => {
                      field.onBlur()
                      handleBlur('phone', fieldState)
                    }}
                  />
                )}
              />
            )}
            {hasProxyType && (
              <Controller
                rules={{ required: true }}
                name="proxy_type"
                control={control}
                defaultValue={defaultProxyType}
                render={({ field }) => (
                  <ProxySelect {...field} show={hasCNPJ} title={proxyTypeTitle} onChange={(e: string) => onProxyTypeChange(e, field.onChange)} />
                )}
              />
            )}
            {hasCPF && (
              <Controller
                rules={{
                  required: true,
                  validate: {
                    cpf: (val) => cpf.isValid(val || ''),
                  },
                }}
                name="identity"
                control={control}
                defaultValue=""
                render={({ field, fieldState }) => (
                  <CPF
                    {...field}
                    title={starling('funds.refund.comm.system_ic_CPF')}
                    error={errors.identity}
                    errorMessage={getErrorText(errors.identity?.type)}
                    onBlur={() => {
                      field.onBlur()
                      handleBlur('identity', fieldState)
                    }}
                  />
                )}
              />
            )}
            {renderCNPJ && (
              <Controller
                rules={{
                  required: true,
                  validate: {
                    cnpj: (val) => cnpj.isValid(val || ''),
                  },
                }}
                name="company_id"
                control={control}
                render={({ field, fieldState }) => (
                  <CNPJ
                    {...field}
                    title={starling('funds.refund.comm.system_ic_CNPJ')}
                    error={errors.company_id}
                    errorMessage={getErrorText(errors.company_id?.type)}
                    onBlur={() => {
                      field.onBlur()
                      handleBlur('company_id', fieldState)
                    }}
                  />
                )}
              />
            )}
            {hasEmail && (
              <Controller
                rules={{ required: true, pattern: validator.emailRegex }}
                name="email"
                control={control}
                render={({ field, fieldState }) => (
                  <Email
                    {...field}
                    title={starling('funds.refund.comm.system_email')}
                    placeholder={starling('funds.refund.comm.system_email_placeholder')}
                    error={errors.email}
                    errorMessage={getErrorText(errors.email?.type)}
                    onBlur={() => {
                      field.onBlur()
                      handleBlur('email', fieldState)
                    }}
                  />
                )}
              />
            )}
            {/* 后续逐渐迁移至FormBuilder来渲染表单 */}
            {formSchema && <FormBuilder form={form} schema={formSchema} errors={errors} handleBlur={handleBlur} />}
          </div>
          {showBillingAddress && (
            <BillingAddress
              title={starling('funds.refund.comm.system_Billing_address')}
              control={control}
              errors={errors}
              postalCodeProps={postalCodeProps}
              {...billingAddressProps}
            />
          )}
          {hasSavingBox && (
            <SaveBox title={starling('funds.refund.comm.system_save_card_information')} storeCard={storeCard} setStoreCard={setStoreCard} />
          )}
        </div>
      </div>
    </div>
  )
})
