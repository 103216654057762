import React, { useState } from 'react'

import { Collapse } from '@/components'
import { CardListRes, ChannelRes } from '@/services/types'
import starling from '@/utils/starling'

import { connectRiskInfo } from '../utils'
import CardChannel from './CardChannel'
import PayWayChannel from './PayWayChannel'

export enum PanelKey {
  Card = 'card',
  PayWay = 'pawWay',
}

interface IProps {
  cards: CardListRes[]
  payWays: ChannelRes[]
  activeChannel: string
  onChannelChange: (panelKey: PanelKey, channelKey: string, data?: any) => void
  errorMsg: string | undefined
  renderPayWayExtra: (item: ChannelRes) => React.ReactNode
}
const ChangeChannels = (props: IProps) => {
  const { errorMsg, activeChannel, onChannelChange, payWays, cards, renderPayWayExtra } = props
  const [activePanel, setActivePanel] = useState<string[] | string>([PanelKey.Card, PanelKey.PayWay])

  const handleCardChannelChange = (key: string, data: any) => {
    const connectData = connectRiskInfo(data, payWays)
    onChannelChange(PanelKey.Card, String(key), connectData)
  }

  const getPayChannelConfig = () => {
    const baseConfig = [
      {
        show: cards?.length,
        title: starling('funds.refund.comm.select_payment_mothod'),
        key: PanelKey.Card,
        content: <CardChannel dataSource={cards} activeChannel={activeChannel} onChannelChange={handleCardChannelChange} errorMsg={errorMsg} />,
      },
      {
        show: payWays?.length,
        title: starling('funds.refund.comm.add_payment_mothod'),
        key: PanelKey.PayWay,
        content: (
          <PayWayChannel
            dataSource={payWays}
            activeChannel={activeChannel}
            onChannelChange={(key, data) => onChannelChange(PanelKey.PayWay, String(key), data)}
            errorMsg={errorMsg}
            renderPayWayExtra={renderPayWayExtra}
          />
        ),
      },
    ]

    return baseConfig.filter((item) => item.show)
  }

  return <Collapse config={getPayChannelConfig()} active={activePanel} onChange={setActivePanel} />
}

export default ChangeChannels
