import { useEffect, useMemo, useState } from 'react'
import { FieldErrors, UseFormClearErrors, UseFormGetValues, UseFormSetError, UseFormSetValue, UseFormTrigger, UseFormWatch } from 'react-hook-form'

import { useRequest, useStateRealtime } from '@byted/hooks'
import { filter } from 'lodash'

import type { BoletoFormData, CCDCFormData, FetchDistrictFunc, LocationItem } from '@/pipo/utils'
import { IGetContractAddressRes } from '@/services/types'
import starling from '@/utils/starling'

import { FILL_BY_POSTAL_CODE_REGION, useFillByPostalCode } from './use-fill-by-postal-code'

export interface IConfigItem {
  name?: string
  label?: string
  placeholder?: string
  starlingKey: string
}

export interface UseAddressPCProps {
  onFetchDistrict: FetchDistrictFunc
  getValues: UseFormGetValues<CCDCFormData | BoletoFormData>
  setValue: UseFormSetValue<CCDCFormData | BoletoFormData>
  trigger: UseFormTrigger<CCDCFormData | BoletoFormData>
  setError: UseFormSetError<CCDCFormData | BoletoFormData>
  clearErrors: UseFormClearErrors<CCDCFormData | BoletoFormData>
  watch: UseFormWatch<Omit<CCDCFormData, 'proxy_type'> | Omit<BoletoFormData, 'proxy_type'>>
  countryOrRegion: string
  options?: {
    region: 'billing_country_region'
    state: 'billing_state'
    city: 'billing_city'
    postalCode: 'billing_postal_code'
  }
  formErrors: FieldErrors<CCDCFormData | BoletoFormData>
  contractAddressList?: IGetContractAddressRes[]
}
const defaultOptions = {
  region: 'billing_country_region',
  state: 'billing_state',
  city: 'billing_city',
  postalCode: 'billing_postal_code',
} as UseAddressPCProps['options']

export const useAddressPC = (props: UseAddressPCProps) => {
  const { onFetchDistrict, getValues, setValue, trigger, clearErrors, watch, countryOrRegion, formErrors, contractAddressList } = props
  const [selectArr, setSelectArr, getSelectArr] = useStateRealtime<Array<LocationItem | undefined>>([])
  const nameOptions = useMemo(() => props.options || defaultOptions!, [props.options])
  const { region, city, state, postalCode } = nameOptions

  const {
    isFillByPostalCode,
    loadingAddress,
    postCodeResArr,
    handleCountryChange,
    getIsFillByPostalCode,
    handleSelectPostalCodeRes,
    handlePostalCodeBlur,
    handlePostalCodeChange,
  } = useFillByPostalCode({
    formErrors,
    getValues,
    nameOptions,
    setValue,
    trigger,
    watch,
  })

  // 合同地址相关
  const [selectedContractAddress, setSelectedContractAddress] = useState<IGetContractAddressRes | undefined>(undefined)

  const { data: countryList = [], loading: countryLoading } = useRequest<LocationItem[], any>(() => onFetchDistrict(''), {
    auto: true,
    formatResult: (data = []) => data?.filter(Boolean) || [],
  })
  const {
    data: provinceList = [],
    loading: provinceLoading,
    run: getProvinceList,
    changeData: changeProvinceList,
  } = useRequest<LocationItem[], any>(onFetchDistrict, {
    formatResult: (data = []) => data?.filter(Boolean) || [],
    onSuccess: (data = []) => {
      const provinceListTemp = provinceList.filter((item) => !(item.code === '' && item.name === '')).filter(Boolean)
      if (provinceListTemp.length === 0) {
        const temp = countryList.filter((item) => item.code === getValues()[region])[0]
        changeProvinceList([...provinceListTemp, temp].filter(Boolean))
      }
      setValue(state, undefined)
    },
  })
  const {
    data: cityList = [],
    loading: cityLoading,
    run: getCityList,
    changeData: changeCityList,
  } = useRequest<LocationItem[], any>(onFetchDistrict, {
    formatResult: (data = []) => data?.filter(Boolean) || [],
    onSuccess: (data = []) => {
      const cityListTemp = cityList.filter((item) => !(item.code === '' && item.name === '')).filter(Boolean)
      if (cityListTemp.length === 0) {
        const temp = provinceList.filter((item) => item.name === getValues()?.[state])[0]
        changeCityList([...cityListTemp, temp].filter(Boolean))
      }
      setValue(city, undefined)
      trigger(city)
    },
  })
  useEffect(() => {
    if (!countryList.length) return
    const selectedCountry = countryList.find((item) => item.code === countryOrRegion)
    const countryName = selectedCountry?.name || ''
    setValue(region, countryName)
    setSelectArr([selectedCountry])
    trigger(region)
    return () => {
      setValue(region, undefined)
      setValue(state, undefined)
      setValue(city, undefined)
    }
  }, [countryList])

  useEffect(() => {
    setValue(state, undefined)
    setValue(city, undefined)
    setValue(postalCode, undefined)
    changeProvinceList([])
    changeCityList([])
    const _regionValue = getValues()?.[region]
    if (_regionValue) {
      clearErrors([state, city])
      const selectedCountry = countryList?.find((item) => item.name === _regionValue)
      setSelectArr([selectedCountry])
      handleCountryChange(selectedCountry?.code || '')

      // fuck code
      getProvinceList(selectedCountry?.geoname_id.toString())
    }
  }, [watch(region)])

  useEffect(() => {
    if (getIsFillByPostalCode()) return

    setValue(city, undefined)
    changeCityList([])
    if (getValues()[state]) {
      clearErrors(city)
      const selecetedProvince = provinceList?.find((item) => item.name === getValues()?.[state])
      setSelectArr((preSelectArr) => {
        preSelectArr[1] = selecetedProvince
        preSelectArr.length = 2
        return preSelectArr
      })

      getCityList(selecetedProvince?.geoname_id.toString(), getSelectArr())
    }
  }, [watch(state), provinceList])

  const regionItem = {
    label: starling('funds.refund.comm.system_Country_Region'),
    placeholder: starling('funds.refund.comm.system_Country_Region_placeholder'),
    loading: countryLoading,
    list: countryList,
    disabled: false,
  }
  const provinceItem = {
    label: starling('funds.refund.comm.system_state_province'),
    placeholder: starling('funds.refund.comm.system_state_province_placeholder'),
    loading: provinceLoading,
    list: provinceList,
    disabled: !provinceLoading && !getValues()?.[region],
  }
  const cityItem = {
    label: starling('funds.refund.comm.system_city'),
    placeholder: starling('funds.refund.comm.system_city_placeholder'),
    loading: cityLoading,
    list: cityList,
    disabled: !cityLoading && !getValues()?.[state],
  }

  // 埋点设计 https://bytedance.feishu.cn/docx/doxcnuPHSPQEnlERAtXUoaadMCf#doxcnuAqE8wm60sSYuaVvltZkOe
  const getFillAddressState = () => {
    return {
      metrics: { postalCodeResLength: postCodeResArr.length, availableAddressLength: (contractAddressList || []).length },
      categories: {
        supportFillByPostalCode: FILL_BY_POSTAL_CODE_REGION[getSelectArr()[0]?.code || ''] ? 'true' : 'false',
        postalCode: getValues()?.[postalCode],
        selectedContractAddress: selectedContractAddress ? 'true' : 'false',
      },
    }
  }

  return {
    list: filter([regionItem, provinceItem, cityItem], Boolean),
    map: filter(
      {
        region: regionItem,
        province: provinceItem,
        city: cityItem,
      },
      Boolean,
    ),
    selectedAddressArr: selectArr,
    isFillByPostalCode,
    loadingAddress,
    postCodeResArr,
    contractAddressList,
    selectedContractAddress,
    countryOrRegion,
    handleSelectPostalCodeRes,
    handlePostalCodeBlur,
    handlePostalCodeChange,
    setSelectedContractAddress,
    getFillAddressState,
  }
}
