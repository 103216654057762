import React, { useEffect, useRef, useState } from 'react'
import { FormProvider, UseFormReturn, useForm } from 'react-hook-form'

import { Modal, ModalProps } from '@arco-design/web-react'
import { ConfirmProps } from '@arco-design/web-react/es/Modal/confirm'

export interface IUseFormModalProps {
  form?: UseFormReturn
}

interface IUseFormModalReturn {
  formModal: {
    open: (props: IModalProps) => void
    confirm: (props: IConfirmModalProps) => {
      close: () => void
      update: (configUpdate: IConfirmModalProps) => void
    }
  }
  context: React.ReactElement
  content: React.ReactNode
}

interface IModalProps extends Omit<ModalProps, 'onOk' | 'children'> {
  onOk?: (values: Record<string, any>) => void | Promise<void>
  content: React.ReactElement
}

interface IConfirmModalProps extends Omit<ConfirmProps, 'onOk'> {
  onOk?: (values: Record<string, any>) => void | Promise<void>
  content: React.ReactElement
}

export const useFormModal = (props?: IUseFormModalProps): IUseFormModalReturn => {
  const { form: outerForm } = props || {} // 外部传入的form实例
  const [visible, setVisible] = useState(false)
  const [posting, setPosting] = useState(false)
  const [modalProps, setModalProps] = useState<IModalProps & IConfirmModalProps>({} as IModalProps)
  const domRef = useRef<any>(null)

  const [modal, context] = Modal.useModal()
  const form = useForm({ mode: 'all' })
  const {
    formState: { isValid },
    getValues,
    reset,
  } = outerForm || form

  const getModalProps = (modalProps: IModalProps & IConfirmModalProps) => {
    return {
      ...modalProps,
      content: React.isValidElement(modalProps?.content) ? (
        <FormProvider {...form}>
          {React.cloneElement(modalProps.content, {
            form: form,
          } as any)}
        </FormProvider>
      ) : null,
      okButtonProps: {
        ...modalProps?.okButtonProps,
        disabled: !isValid,
        loading: posting,
      },
      onOk: async () => {
        try {
          setPosting(true)
          const values = getValues()
          await modalProps.onOk?.(values)
          setVisible(false)
          reset()
        } finally {
          setPosting(false)
        }
      },
      onCancel: () => {
        setVisible(false)
        reset()
        modalProps.onCancel?.()
      },
    }
  }

  useEffect(() => {
    domRef.current?.update?.({
      okButtonProps: {
        ...modalProps?.okButtonProps,
        disabled: !isValid,
        loading: posting,
      },
    })
  }, [isValid, posting, modalProps?.okButtonProps])

  const formModal = {
    open: (props: IModalProps) => {
      setVisible(true)
      setModalProps(props)
    },
    confirm: (props: IConfirmModalProps) => {
      setVisible(false)
      setModalProps(props)
      domRef.current = modal.confirm?.(getModalProps(props))
      return domRef.current
    },
  }

  const domRender = () => {
    if (!visible) return null

    const _modalProps = getModalProps(modalProps)
    return (
      <Modal {..._modalProps} visible={visible}>
        <FormProvider {...form}>{_modalProps.content}</FormProvider>
      </Modal>
    )
  }

  return { formModal, context, content: domRender() }
}
