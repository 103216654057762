import { useCallback } from 'react'
import type { UseFormUnregister } from 'react-hook-form'

import { PipoProvider } from '@/pipo/utils'
import starling from '@/utils/starling'

export function useProxyType(unregister: UseFormUnregister<any>): {
  defaultProxyType: 'individual' | 'company'
  proxyTypeTitle: string
  onProxyTypeChange: (val: string, callback: (...event: any[]) => void) => void
} {
  const { t } = PipoProvider.useContext()
  const defaultValue = 'individual'
  const title = starling('funds.refund.comm.system_ic_documenttype')
  const onProxyTypeChange = useCallback((val: string, callback: (...event: any[]) => void) => {
    if (val === 'individual') {
      unregister('company_id')
    } else if (val === 'company') {
      unregister('identity')
    }
    callback(val)
  }, [])
  return {
    defaultProxyType: defaultValue,
    proxyTypeTitle: title,
    onProxyTypeChange,
  }
}
