import { PaymentMethodItem } from '../../types'

export const TOUCHNGO_PAYMENT_METHOD: PaymentMethodItem = {
  paymentMethodType: {
    configuration: {},
    icon: {
      iconUrl: '',
    },
    starling_key: 'pipo_common_ewallet',
    paymentMethodType: 'E_WALLET',
    title: {
      payment: 'pipo_common_ewallet',
    },
  },
  paymentMethod: {
    configuration: {},
    paymentMethod: 'TOUCHNGO',
    paymentMethodId: 'pm_pi_ew_touchngo_c_r',
    displayName: 'Touch & Go',
    starling_key: 'pipo_payin_cashier_dropin_payment_TNG',
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/TNG_icon_d60fd3.svg',
      pngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/TNG_131b82.png',
      darkModePngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/TNG_131b82.png',
    },
    title: {
      payment: 'pipo_payin_cashier_dropin_payment_TNG',
    },
  },
}
