import React, { useEffect } from 'react'
import { FieldError } from 'react-hook-form'

import { Input, Select } from '@arco-design/web-react'
import { IconDown } from '@arco-design/web-react/icon'
import { omit } from 'lodash'

import { ItemBlock } from '../index'

interface IProps {
  len?: 'half' | 'full'
  maxLength?: number
  error?: FieldError
  value?: string
  title: string
  placeholder?: string
  errorMessage?: string
  selectList?: string[]
  name: string
  onChange: (e: any) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void
}

export const HolderName = React.forwardRef((props: IProps, ref: any) => {
  const { len = 'half', maxLength = 128, value, title, placeholder, error, errorMessage, selectList = [], onChange, onBlur } = props

  useEffect(() => {
    // 如果有值，默认选中第一个
    const defaultSelect = selectList[0]
    setTimeout(() => defaultSelect && onChange(defaultSelect), 0)
  }, [selectList])

  return (
    <ItemBlock style={len} title={title} errorState={Boolean(error)} errorMessage={errorMessage}>
      {selectList.length > 0 ? (
        <Select size="large" ref={ref} showSearch value={value} placeholder={placeholder} onChange={onChange} arrowIcon={<IconDown />}>
          {selectList.map((value) => (
            <Select.Option key={value} value={value}>
              <span>{value}</span>
            </Select.Option>
          ))}
        </Select>
      ) : (
        <Input
          {...omit(props, 'errorMessage')}
          ref={ref}
          allowClear
          size="large"
          placeholder={placeholder}
          error={Boolean(error)}
          maxLength={maxLength}
          onBlur={onBlur}
          className="oo-block"
        />
      )}
    </ItemBlock>
  )
})
