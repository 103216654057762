import { useContext, useEffect, useState } from 'react'

import { PUBSUB_TYPES } from '@/constant'
import { ParamsContext } from '@/stores'

export * from './useChangeCards'
export * from './useChangePayWays'
export * from './useQueryCardResult'
export * from './useQueryAccountResult'

/** 同步外层传入的显隐状态 */
export const useChangeVisible = (propsVisible: boolean | undefined) => {
  const { params, uPubSub } = useContext(ParamsContext)
  const defaultVisible = propsVisible ?? params.defaultVisible
  const [visible, setVisible] = useState(defaultVisible)

  useEffect(() => {
    setVisible(defaultVisible)
  }, [defaultVisible])

  useEffect(() => {
    // 告知业务方已经初始化完成 并监听业务方 OPEN_MODAL 事件
    const subId = uPubSub.subscribe(PUBSUB_TYPES.OPEN_MODAL, () => setVisible(true))
    uPubSub.publish(PUBSUB_TYPES.DID_MOUNT)
    return () => uPubSub.unsubscribe(subId)
  }, [uPubSub])

  return [visible, setVisible] as const
}
