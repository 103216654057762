import React from 'react'

import { Message } from '@arco-design/web-react'
import { useClipboardCopy } from '@byted/hooks'
import { Copy } from '@icon-park/react'

import starling from '@/utils/starling'

import './index.less'

const classPrefix = 'upay-voucher-info'

export interface IInfoCardItem {
  title: string
  content?: string
  contentLogo?: string
  needCopy?: boolean
  copyTitle?: string
  // 如未单独指定复制的内容，则同content
  copyContent?: string
  notice?: string | React.ReactNode
}

interface IInfoCardProps {
  infoList: IInfoCardItem[]
}

export const InfoCard = ({ infoList }: IInfoCardProps) => {
  const { copy } = useClipboardCopy({
    onSuccess() {
      Message.success(starling('funds.check_out.comm.toast_copied'))
    },
  })

  return (
    <div className={`${classPrefix}`}>
      {infoList.map((item, index) => (
        <div key={index} className={`${classPrefix}-item-container`}>
          <div className={`${classPrefix}-item`}>
            <div className={`${classPrefix}-item-title`}>{item.title}</div>
            <div className={`${classPrefix}-item-right`}>
              {item.contentLogo && <img className={`${classPrefix}-item-content-logo`} src={item.contentLogo} />}
              <div className={`${classPrefix}-item-content`}>{item.content}</div>
              {item.needCopy && (
                <Copy
                  theme="outline"
                  size="20"
                  title="Copy"
                  onClick={() => {
                    copy(item.copyContent || item.content)
                  }}
                />
              )}
            </div>
          </div>
          {item.notice && <div className={`${classPrefix}-item-tip`}>{item.notice}</div>}
        </div>
      ))}
    </div>
  )
}
