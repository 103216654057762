import React, { forwardRef, useEffect, useMemo, useState } from 'react'
import { Controller, ControllerFieldState, useForm } from 'react-hook-form'

import { Spin } from '@arco-design/web-react'

import { PAY_WAY_TYPE } from '@/constant'
import { useAddressPC, usePipoRouter, usePostalCode, useProxyType, useSubmittingState, useTouchedErrors } from '@/pipo/hooks'
import {
  BOLETO_PAYMENT_METHOD,
  BOLETO_PAYMENT_PARAMS_MAP,
  BoletoFormData,
  BoletoProps,
  PaymentParamsItem,
  PipoComponentRef,
  PipoProvider,
  PipoSubmitParams,
  VoucherProps,
  callingCode,
  cnpj,
  countryToISOCode,
  cpf,
  generateCommonPaymentParams,
  pickNumeric,
  validator,
} from '@/pipo/utils'
import starling from '@/utils/starling'
import teaClient from '@/utils/tea'

import { BillingAddress, CNPJ, CPF, CountryCode, Email, HolderName, PhoneNumber, ProxySelect, SaveBox } from '../../inner-components'

const clsPrefix = 'pipo-bindcard-pc'

// TODO 待确认
export const Boleto = forwardRef((props: BoletoProps, ref: React.ForwardedRef<PipoComponentRef>) => {
  const {
    showSavingBox = true,
    onFetchDistrict,
    onSubmit,
    onValidate = () => {
      // do nothing.
    },
    contractAddressList,
  } = props
  const {
    control,
    watch,
    handleSubmit: handleFormSubmit,
    getValues,
    setValue,
    trigger,
    setError,
    clearErrors,
    unregister,
    formState: { errors: formErrors, isValid, touchedFields },
  } = useForm<BoletoFormData>({
    mode: 'all',
  })
  const errors = useTouchedErrors<BoletoFormData>({
    touchedFields,
    errors: formErrors,
  })

  const { state, t, getErrorText, getPublicKeyAsync } = PipoProvider.useContext()
  const { countryOrRegion = '', config = {} } = state

  const { defaultProxyType, proxyTypeTitle, onProxyTypeChange } = useProxyType(unregister)

  const { boleto = {}, selectedSaveBox = false } = config
  const { hasCNPJ = true, hasEmail = false } = { ...boleto, ...props }

  const billingAddressProps = useAddressPC({
    onFetchDistrict,
    getValues,
    setValue,
    trigger,
    clearErrors,
    setError,
    watch,
    formErrors,
    countryOrRegion,
    contractAddressList,
  } as any)
  const { list, selectedAddressArr, selectedContractAddress, getFillAddressState } = billingAddressProps

  const postalCodeProps = usePostalCode({
    selectedAddressArr,
    watch,
    trigger,
  } as any)

  const { formSubmitState } = useSubmittingState()

  // Get phone country code
  const phoneCountryCode = callingCode.list[countryOrRegion].code
  // TODO: can be modified from outside
  const [storeCard, setStoreCard] = useState<boolean>(selectedSaveBox)

  useEffect(() => {
    isValid && onValidate(true)
  }, [isValid, onValidate])

  // Monitor select box value to render CPF or CNPJ
  const renderCPF = watch('proxy_type') === 'individual'
  const renderCNPJ = watch('proxy_type') === 'company'

  /**
   * 处理PIPO提交所需数据
   * @returns PipoSubmitParams
   */
  const processSubmitData = async (formData: BoletoFormData): Promise<PipoSubmitParams> => {
    delete formData.contract_address // 删除内部使用字段
    if (selectedContractAddress) {
      formData.billing_street = selectedContractAddress.detailAddress
      formData.billing_country_region = selectedContractAddress.countryCode
      formData.billing_postal_code = selectedContractAddress.postcode
      formData.billing_state = selectedContractAddress.level2Name
      formData.billing_city = selectedContractAddress.level3Name
    } else if (formData.billing_country_region && list[0].list) {
      formData.billing_country_region = countryToISOCode(formData.billing_country_region, list[0].list)
    }

    if (formData.identity) formData.identity = pickNumeric(formData.identity)
    if (formData.company_id) formData.company_id = pickNumeric(formData.company_id)

    const publicKey = await getPublicKeyAsync()
    const paymentParams: PaymentParamsItem[] = generateCommonPaymentParams(formData, BOLETO_PAYMENT_PARAMS_MAP, publicKey)

    const { paymentMethodType, paymentMethod } = BOLETO_PAYMENT_METHOD
    return {
      formData,
      paymentMethod: {
        paymentMethod: paymentMethod.paymentMethod,
        paymentMethodId: paymentMethod.paymentMethodId,
      },
      paymentMethodType: paymentMethodType.paymentMethodType,
      paymentParams,
      storeCard: storeCard && showSavingBox,
      fillAddressState: getFillAddressState(),
    }
  }

  /**
   * 校验后获取PIPO提交所需数据
   * @returns PipoSubmitParams
   */
  const validateSubmitData = () => {
    return new Promise(async (resolve: (value: PipoSubmitParams) => void, reject: (reason: typeof errors) => void) => {
      if (!isValid) {
        reject(errors)
      } else {
        const formData = getValues()
        resolve(await processSubmitData(formData))
      }
    })
  }

  const handleSubmit = async (formData: BoletoFormData): Promise<void> => {
    const submitData = await processSubmitData(formData)
    formSubmitState.setTrue()
    await onSubmit?.(submitData)
    formSubmitState.setFalse()
  }

  const { activePath, routerParams } = usePipoRouter(ref, {
    initPath: 'boleto',
    initParams: {
      isValid,
      onValidate,
    },
    instanceMethods: {
      submit: handleFormSubmit(handleSubmit),
      validateSubmitData,
    },
  })

  const handleBlur = (scenario: string, fieldState: ControllerFieldState) => {
    const params = {
      pay_way: PAY_WAY_TYPE.BOLETO,
      scenario,
      is_valid: !fieldState.error,
      invalid_reason: fieldState.error?.type,
    }
    teaClient.sendPayPageFillIn(params)
  }

  return (
    <div className="pipo-pc" id="upay-form-boleto">
      <div className={`${clsPrefix}-flex-container`}>
        <div className={`${clsPrefix}-form-wrapper`}>
          <div className={`${clsPrefix}-subform-wrapper`}>
            <Controller
              rules={{ required: true, pattern: validator.nameRegex }}
              name="holder_name"
              control={control}
              render={({ field, fieldState }) => (
                <HolderName
                  {...field}
                  error={errors.holder_name}
                  errorMessage={getErrorText(errors.holder_name?.type)}
                  title={starling('funds.refund.comm.system_name')}
                  placeholder={starling('funds.refund.comm.system_name_description')}
                  onBlur={() => {
                    field.onBlur()
                    handleBlur('holder_name', fieldState)
                  }}
                />
              )}
            />
            {/* Phone Number Block */}
            <Controller
              rules={{ required: true }}
              name="phone_country_code"
              control={control}
              render={({ field }) => <CountryCode countryCode={phoneCountryCode} onChange={field.onChange} />}
            />
            <Controller
              rules={{ required: true, pattern: validator.phoneRegex }}
              name="phone"
              control={control}
              render={({ field, fieldState }) => (
                <PhoneNumber
                  {...field}
                  title={starling('funds.refund.comm.system_phonenumber')}
                  placeholder={starling('funds.refund.comm.system_phonenumber_placeholder')}
                  error={errors.phone}
                  errorMessage={getErrorText(errors.phone?.type)}
                  countryCode={phoneCountryCode}
                  countryOrRegion={countryOrRegion}
                  phoneNumberStyle="half"
                  onBlur={() => {
                    field.onBlur()
                    handleBlur('phone', fieldState)
                  }}
                />
              )}
            />
            {/* Proxy Block */}
            <Controller
              rules={{ required: true }}
              name="proxy_type"
              control={control}
              defaultValue={defaultProxyType}
              render={({ field }) => (
                <ProxySelect {...field} show={hasCNPJ} title={proxyTypeTitle} onChange={(e) => onProxyTypeChange(e, field.onChange)} />
              )}
            />
            {renderCPF && (
              <Controller
                rules={{
                  required: true,
                  validate: {
                    cpf: (val) => cpf.isValid(val || ''),
                  },
                }}
                name="identity"
                control={control}
                render={({ field, fieldState }) => (
                  <CPF
                    {...field}
                    title={starling('funds.refund.comm.system_ic_CPF')}
                    error={errors.identity}
                    errorMessage={getErrorText(errors.identity?.type)}
                    onBlur={() => {
                      field.onBlur()
                      handleBlur('identity', fieldState)
                    }}
                  />
                )}
              />
            )}
            {renderCNPJ && (
              <Controller
                rules={{
                  required: true,
                  validate: {
                    cnpj: (val) => cnpj.isValid(val || ''),
                  },
                }}
                name="company_id"
                control={control}
                render={({ field, fieldState }) => (
                  <CNPJ
                    {...field}
                    title={starling('funds.refund.comm.system_ic_CNPJ')}
                    error={errors.company_id}
                    errorMessage={getErrorText(errors.company_id?.type)}
                    onBlur={() => {
                      field.onBlur()
                      handleBlur('company_id', fieldState)
                    }}
                  />
                )}
              />
            )}
            {/* Email Component */}
            {hasEmail && (
              <Controller
                rules={{ required: true, pattern: validator.emailRegex }}
                name="email"
                control={control}
                render={({ field, fieldState }) => (
                  <Email
                    {...field}
                    title={starling('funds.refund.comm.system_email')}
                    placeholder={starling('funds.refund.comm.system_email_placeholder')}
                    error={errors.email}
                    errorMessage={getErrorText(errors.email?.type)}
                    onBlur={() => {
                      field.onBlur()
                      handleBlur('email', fieldState)
                    }}
                  />
                )}
              />
            )}
          </div>
          <BillingAddress
            title={starling('funds.refund.comm.system_Billing_address')}
            control={control}
            errors={errors}
            postalCodeProps={postalCodeProps}
            onlyCurrentCountry={true}
            {...billingAddressProps}
          />
          {/* Save Box */}
          {showSavingBox && (
            <SaveBox title={starling('funds.refund.comm.system_save_card_information')} storeCard={storeCard} setStoreCard={setStoreCard} />
          )}
        </div>
      </div>
    </div>
  )
})
