import React, { useContext, useMemo } from 'react'

import { Button, Skeleton, Spin } from '@arco-design/web-react'
import { useRequest } from '@byted/hooks'

import { PAY_WAY_TYPE } from '@/constant'
import { VoucherBoardData } from '@/pipo/hooks/use-ui-voucher'
import { GET_SUPPORT_BANK_MAP, PipoProvider, SUPPORT_PAYMENT_METHODS_ID_MAP } from '@/pipo/utils'
import api from '@/services'
import { ParamsContext } from '@/stores'
import starling from '@/utils/starling'

import './index.less'

const classPrefix = 'pipo-pc-voucher-toss'
interface TossBankTransferCodeProps {
  data: VoucherBoardData
}

const TossBankTransferCode = (props: TossBankTransferCodeProps) => {
  const {
    urlQuery: { token },
  } = useContext(ParamsContext)
  const { data } = props
  const {
    type,
    paymentMethodInfo: { bankId },
    simple,
  } = data

  const { data: bank, loading } = useRequest(
    async () => {
      const bankList = await api.getBindElements({ token, payWay: PAY_WAY_TYPE.TOSS }).then((res) => res?.data?.elements?.[0]?.option_list || [])
      return bankList.find((bank) => bank.value === bankId)
    },
    {
      auto: true,
    },
  )

  return (
    <div className={classPrefix}>
      <div className={`${classPrefix}-dashboard`}>
        <Skeleton loading={loading} animation style={{ width: 120 }} text={{ rows: 2 }}>
          <div className={`${classPrefix}-dashboard-left`}>
            {bank?.icon_url && <img className={`${classPrefix}-dashboard-logo`} src={bank?.icon_url} />}
            <div className={`${classPrefix}-dashboard-name`}>
              {starling(`funds.check_out.comm.channel_bank_name_${bank?.value}` as any, {}, bank?.name)}
            </div>
          </div>
        </Skeleton>
        {type === 'simple' && (
          <div className={`${classPrefix}-dashboard-right`}>
            <div className={`${classPrefix}-dashboard-right-no`}>
              VA:{' '}
              {
                // @ts-ignore
                simple?.formatCode
              }
            </div>
            <div className={`${classPrefix}-dashboard-right-amount`}>
              <span>{starling('funds.refund.comm.total_amount')}: </span>
              <span>
                {
                  // @ts-ignore
                  simple?.amount
                }
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export { TossBankTransferCode }
