import React, { ForwardedRef, useContext, useEffect, useRef, useState } from 'react'
import { ControllerRenderProps, FieldError, UseFormClearErrors, UseFormSetError } from 'react-hook-form'

import { useDebounceFn } from '@byted/hooks'
import { CheckOne, Loading } from '@icon-park/react'
import { CardLogoPlaceHolder as CardLogoPlaceHolderIcon } from '@pipo-lib/icon'

import type { CCDCFormData, PaymentMethodItem } from '@/pipo/utils'
import { ISharkJudgeRes } from '@/services/types'

import { CustomInput, ItemBlock } from '../index'

const CardNumber = (
  props: ControllerRenderProps<CCDCFormData> & {
    hasError: boolean
    errorMessage: string
    placeholder: string
    cardBrands: PaymentMethodItem<any, any>[]
    value?: string
    maxLength: number
    title?: string
    currentCardBrand: PaymentMethodItem<any, any> | undefined
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    formatValue?: (value: string) => string
    formatCursor?: (oldVal: string, newVal: string, cursorPosition: number) => number
    formError: FieldError | undefined
    setError: UseFormSetError<CCDCFormData>
    clearErrors: UseFormClearErrors<CCDCFormData>
    binRiskPass: boolean
    setBinRiskPass: React.Dispatch<React.SetStateAction<boolean>>
    onValidateCardBin: (_cardNo?: string, cardBrand?: string) => Promise<ISharkJudgeRes>
  },
  ref: ForwardedRef<any>,
) => {
  const {
    title,
    hasError,
    errorMessage,
    placeholder,
    cardBrands,
    maxLength,
    currentCardBrand,
    value,
    onChange,
    formatValue,
    formatCursor,
    formError,
    setError,
    clearErrors,
    onBlur,
    setBinRiskPass,
    binRiskPass,
    onValidateCardBin,
  } = props

  const [binRiskChecking, setBinRiskChecking] = useState(false)
  const currentCardNo = useRef('')
  const checkRiskBinRef = useRef<{ promise: Promise<any> | null; cardNo: string }>({ promise: null, cardNo: '' })

  const { run: checkRiskBin } = useDebounceFn(async () => {
    if (!formError && value && currentCardBrand) {
      const cardNo = value.replaceAll(' ', '')
      // 缓存黑卡拦截结果 卡号验证通过和blur后都会触发请求 缓存同卡号结果以减少等待时间
      if (!checkRiskBinRef.current.promise || cardNo !== checkRiskBinRef.current.cardNo) {
        checkRiskBinRef.current = {
          promise: onValidateCardBin(value, currentCardBrand.paymentMethod.paymentMethod),
          cardNo,
        }
      }

      setBinRiskChecking(true)
      const checkRiskBinRes = await checkRiskBinRef.current.promise
      setBinRiskChecking(false)

      if (currentCardNo.current.replaceAll(' ', '') === cardNo) {
        if (!checkRiskBinRes.riskPassed) {
          setError('card_number', { type: 'ValidRiskBIN', message: checkRiskBinRes.riskMessage })
        } else {
          clearErrors('card_number')
          onBlur() // 触发再次验证
          setBinRiskPass(true)
        }
      }
    }
  }, 200)

  useEffect(() => {
    checkRiskBin()
  }, [formError, value])

  useEffect(() => {
    setBinRiskPass(false)
  }, [value])

  const handleBlur = () => {
    onBlur() // react-hook-form的表单事件 目的是先执行完form的验证逻辑
    checkRiskBin()
  }

  return (
    <ItemBlock
      style="full"
      title={
        <div className="pipo-pc-card-number-title">
          <span>{title}</span>
          <div className="pipo-pc-brand-group-wrapper">
            {cardBrands.map((cb) => {
              return (
                <img
                  className="pipo-pc-brand-group"
                  key={cb.paymentMethod.paymentMethod}
                  src={cb.paymentMethod.icon.iconUrl}
                  alt={cb.paymentMethod.displayName}
                />
              )
            })}
          </div>
        </div>
      }
      errorState={hasError}
      errorMessage={errorMessage}
    >
      <CustomInput
        {...props}
        ref={ref}
        allowClear
        size="large"
        type="number"
        error={hasError}
        placeholder={placeholder}
        maxLength={maxLength}
        prefix={
          currentCardBrand ? (
            <img className={`pipo-pc-card-logo ${currentCardBrand ? '' : 'hidden'}`} src={currentCardBrand?.paymentMethod.icon.iconUrl} />
          ) : (
            <CardLogoPlaceHolderIcon className="pipo-pc-card-logo" />
          )
        }
        suffix={
          <div className="upay-pc-card-number-status">
            {binRiskChecking ? <Loading spin={true} /> : binRiskPass ? <CheckOne theme="filled" /> : undefined}
          </div>
        }
        value={value}
        onChange={(value, e) => {
          currentCardNo.current = value
          onChange(e)
        }}
        formatValue={formatValue}
        formatCursor={formatCursor}
        onBlur={handleBlur}
      />
    </ItemBlock>
  )
}

export default React.forwardRef(CardNumber)
