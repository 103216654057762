import React from 'react'

import { Divider } from '@arco-design/web-react'

import { PipoProvider } from '@/pipo/utils'
import starling from '@/utils/starling'

export const ProtocolDirectDebitUS = () => {
  const { t } = PipoProvider.useContext()

  return (
    <React.Fragment>
      <Divider style={{ margin: '24px 0' }} />
      <div className="payment-pc-protocol">
        <div className="payment-pc-protocol-title">{starling('funds.refund.comm.makingpayment_directdebit')}</div>
        {starling('funds.refund.comm.makingpayment_directdebitus_account')
          .split('\\n')
          .map((text: string) => {
            return (
              <p key={text} className="payment-pc-protocol-content">
                {text}
              </p>
            )
          })}
      </div>
    </React.Fragment>
  )
}
