import React from 'react'

import { FROM_PAGE_ENUM } from '@/constant'
import Change from '@/pages/Change'
import Create from '@/pages/Create'
import Manager from '@/pages/Manager'
import PayNext from '@/pages/PayNext'
import PaymentCode from '@/pages/PaymentCode'
import PayoutInfo from '@/pages/Payout/Info'
import PayoutPay from '@/pages/Payout/Pay'
import Transfer from '@/pages/Transfer'
import { ChangeStatusEnum } from '@/stores'

export const PAGE_MAP = {
  create: <Create from={FROM_PAGE_ENUM.CREATE} />,
  manager: <Manager />,
  change: <Change from={FROM_PAGE_ENUM.CHANGE} />,
  paymentcode: <PaymentCode from={FROM_PAGE_ENUM.PAYMENT_CODE} />,
  transfer: <Transfer />,
  pay: <PayNext from={FROM_PAGE_ENUM.PAY_NEXT} />,
  'payout/info': <PayoutInfo />,
  'payout/pay': <PayoutPay from={FROM_PAGE_ENUM.PAYOUT} />,
}

export const getPid = (url: string) => {
  return (/wpay\/oversea\/(iframe|web|card)\/([a-zA-Z]+\/[a-zA-Z]+|[a-zA-Z]+)/.exec(url) || [])[2] as keyof typeof PAGE_MAP
}

export const getRenderPage = async (url: string, urlQuery: Record<string, string>) => {
  let page = getPid(url)
  if (/type=3/.test(url)) {
    // 【对旧模式的兼容】type = 3,如果路由不带change，即第一步，卡支付方式变更，如果带change，即第二步，账户支付方式变更
    // 增加标志位，逻辑收拢到新Change页面组件
    Object.assign(urlQuery, {
      changeStatus: /change/.test(url) ? ChangeStatusEnum.Pause : ChangeStatusEnum.Init,
    })
    page = 'change'
  }
  return { RenderContent: PAGE_MAP[page], pid: page }
}
