import React, { forwardRef, useImperativeHandle, useState } from 'react'

import { Checkbox } from '@arco-design/web-react'

import { IPaymentMethodsProps, IPaymentsMethodRef } from '@/payments/interface'
import starling from '@/utils/starling'

import './index.less'

const PayAndSaveCard = forwardRef<IPaymentsMethodRef, IPaymentMethodsProps>((props, ref): React.ReactElement => {
  const [saveAccount, setSaveAccount] = useState(true)
  useImperativeHandle(ref, () => ({
    // 提交处理逻辑
    processSubmitData: (submitParams) => {
      if (saveAccount) submitParams.bindAndPay = saveAccount
      return submitParams
    },
  }))

  return (
    <div className="upay-pay-and-save-card-container">
      <Checkbox style={{ paddingLeft: 0 }} checked={saveAccount} onChange={setSaveAccount}>
        {starling('funds.refund.comm.system_save_card_information')}
      </Checkbox>
    </div>
  )
})

export default PayAndSaveCard
