import React, { forwardRef, useEffect } from 'react'

interface IProps {
  countryCode?: string
  onChange: (e?: string) => void
}

export const CountryCode = forwardRef((props: IProps, ref) => {
  const { countryCode, onChange } = props

  useEffect(() => {
    onChange?.(countryCode)
  }, [countryCode])

  return <div style={{ display: 'none' }}></div>
})
