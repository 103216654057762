"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault")["default"];
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.chunkString = void 0;
var _map = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/map"));
var _chunk2 = _interopRequireDefault(require("lodash/chunk"));
/**
 * 分割卡号，付款码等
 *
 * chunkString("Aloha", 3) => ["Alo", "ha"]
 *
 * chunkString("41111111111", 4) => ["4111", "1111", "111"]
 */
var chunkString = function chunkString(value, size) {
  var _context;
  return (0, _map["default"])(_context = (0, _chunk2["default"])(value, size)).call(_context, function (chars) {
    return chars.join('');
  });
};
exports.chunkString = chunkString;