import React from 'react'
import { Control, Controller, FieldErrors } from 'react-hook-form'

import { PipoProvider, validator } from '@/pipo/utils'
import starling from '@/utils/starling'

import { HolderName } from '../../inner-components'
import { DirectDebitFormData } from './utils'

interface IProps {
  clsPrefix: string
  control: Control<DirectDebitFormData, any>
  errors: FieldErrors<DirectDebitFormData>
  isBusiness?: boolean
}
export const SignatureInfo = (props: IProps) => {
  const { control, errors, clsPrefix, isBusiness } = props
  const { getErrorText } = PipoProvider.useContext()

  return (
    <React.Fragment>
      <div className={`${clsPrefix}-subform-wrapper`}>
        <Controller
          rules={{
            required: true,
            pattern: validator.nameRegex,
          }}
          name="signature_name"
          control={control}
          render={({ field }) => (
            <HolderName
              {...field}
              error={errors.signature_name}
              errorMessage={getErrorText(errors.signature_name?.type)}
              // TODO:i18n
              title={starling('funds.check_out.comm.signature_name')}
              placeholder={starling('funds.check_out.comm.signature_name')}
            />
          )}
        />
        {isBusiness && (
          <Controller
            rules={{
              required: true,
              pattern: validator.nameRegex,
            }}
            name="authorized_signatory_full_name"
            control={control}
            render={({ field }) => (
              <HolderName
                {...field}
                error={errors.authorized_signatory_full_name}
                errorMessage={getErrorText(errors.authorized_signatory_full_name?.type)}
                // TODO:i18n
                title={starling('funds.check_out.comm.auth_name')}
                placeholder={starling('funds.check_out.comm.auth_name')}
              />
            )}
          />
        )}
        {isBusiness && (
          <Controller
            rules={{
              required: true,
              pattern: validator.nameRegex,
            }}
            name="authorized_signatory_title"
            control={control}
            render={({ field }) => (
              <HolderName
                {...field}
                error={errors.authorized_signatory_title}
                errorMessage={getErrorText(errors.authorized_signatory_title?.type)}
                // TODO:i18n
                title={starling('funds.check_out.comm.auth_title')}
                placeholder={starling('funds.check_out.comm.auth_title')}
              />
            )}
          />
        )}
      </div>
    </React.Fragment>
  )
}
