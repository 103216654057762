/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'

import { Skeleton } from '@arco-design/web-react'
import { useRequest } from '@byted/hooks'
import { IBindCardRes, ISubmitOrderRes, PipoRequestErrorObj, StatusActionTypeEnum, StatusTypeEnum } from '@upay/utils/es/types'

import { NEW_SDK_TERMINAL_EQUIP, PAYMENT, PAY_WAY_TYPE, PIPO_CODE } from '@/constant'
import { IPayinMethodItem, PaymentSubmitMethodEnum } from '@/pages/PayNext/interface'
import { paymentsLogger } from '@/payments'
import { CCDC } from '@/pipo/pc'
import { PipoComponentRef } from '@/pipo/utils'
import api from '@/services'
import { ISharkJudgeRes } from '@/services/types'
import GlobalInfo from '@/stores/GlobalInfo'
import utils from '@/utils'
import SafeJSON from '@/utils/SafeJSON'
import slardar from '@/utils/slardar'

import { IPaymentMethodsProps, IPaymentsMethodRef } from '../../interface'
import { IPaymentMethodList } from './interface'

import './index.less'

const CCDCForm = forwardRef<IPaymentsMethodRef, IPaymentMethodsProps>((props, ref): React.ReactElement => {
  const { paymentSubmitMethod, fromPage, changeNewPaymentStatus, getOrder, linkOpen, handleBillStatus, urlQuery, channel } = props
  const { type, token } = urlQuery
  const ccdcRef = useRef<PipoComponentRef>(null)
  const {
    PIPOApi: { fetchDistrict, paySubmitByPIPOForm, bindCCDCAuthorizeAgreement, storePaymentMethod },
    utils: { getContractAddressAsync },
    data: { sessionId, riskInfoObj },
  } = useContext(GlobalInfo)
  const [ccdcValid, setCcdcValid] = useState(false)
  const [paymentMethodsList, setPaymentMethodsList] = useState<IPaymentMethodList[]>()
  // 目前CCDC无depends场景
  const isBindSubmit = paymentSubmitMethod === PaymentSubmitMethodEnum.BIND

  const actionType = useMemo(() => (isBindSubmit ? StatusActionTypeEnum.BIND : StatusActionTypeEnum.PAY), [isBindSubmit])
  // TODO: 此处为临时放量逻辑，暂时仅预付费支付展示，后付费需要请求协议绑卡放量逻辑 老逻辑隐藏 协议绑卡展示
  // 协议绑卡并支付稳定后固定 pipoType === PIPO_TYPE.PAYMENT 支付场景即可展示
  const [hitAgreement, setHitAgreement] = useState(false)

  const handleJudgeAgreement = async () => {
    try {
      const res = await api.hitAgreement({ token })
      setHitAgreement(!!res.data)
    } catch (error) {
      paymentsLogger.warn('ccdc judge hit agreement failed', error)
    }
  }

  useEffect(() => {
    // 后付费支付 判断是否命中协议支付
    if (!isBindSubmit && type === PAYMENT.POST_PAY) {
      handleJudgeAgreement()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data: contractAddressList } = useRequest(getContractAddressAsync, { auto: true })

  // TODO: 暂时兼容两种逻辑，后续新UI全量后可梳理移除，由后端维护是否显示
  // 是否展示SaveBox
  const showSavingBox = useMemo(() => {
    // 仅支付场景展示SaveBox
    if (isBindSubmit) return false

    // PayNext 后端返回了supportPaymentAndBind 以后端为准
    const channelSupportBindAndPayFromBE = (channel as IPayinMethodItem).supportPaymentAndBind
    if (typeof channelSupportBindAndPayFromBE === 'boolean') return channelSupportBindAndPayFromBE

    // 后付费老逻辑 根据是否命中了协议绑卡并支付来判断
    if (urlQuery.type === PAYMENT.POST_PAY && !hitAgreement) return false

    return true
  }, [channel, hitAgreement, isBindSubmit, urlQuery.type])

  /**
   * 一步支付CCDC补充提交
   */
  const handleOneStepSupplySubmit = useCallback(
    async ({ submitOrderRes }: { submitOrderRes: ISubmitOrderRes }) => {
      try {
        if (ccdcRef.current?.validateSubmitData) {
          const pipoSubmitParams = await ccdcRef.current?.validateSubmitData()
          if (pipoSubmitParams.fillAddressState) {
            slardar.timer('fillAddressSubmitEvent', pipoSubmitParams.fillAddressState.metrics, {
              from: 'CCDC_PAY',
              ...pipoSubmitParams.fillAddressState.categories,
            })
          }
          const submitResult = await paySubmitByPIPOForm({
            pipoSubmitParams,
            order: getOrder!()!,
            fromPage,
            submitOrderRes,
          }) // 预付费进行到此处getOrder一定存在

          const redirectUrl = submitResult?.redirect_details?.url
          if (submitResult.result_code === PIPO_CODE.REDIRECT && redirectUrl) {
            // 跳转3DS
            linkOpen(redirectUrl as string, 'CCDC')
          } else if ([PIPO_CODE.SUCCESS, PIPO_CODE.SUCCEED].includes(submitResult.result_code)) {
            // 直接展示成果
            handleBillStatus({ actionType, status: submitResult.result_code })
          } else {
            // 正常应该拿到redirect，change pay会进success，其他情况一律按失败处理，根据国际支付的错误info，调用接口得到错误详情
            handleBillStatus({
              actionType,
              status: StatusTypeEnum.FAILED as StatusTypeEnum,
              errMsg: submitResult.error_message,
              pipoErrorCode: submitResult.error_code,
            })
          }
        }
      } catch (error: any) {
        paymentsLogger.fatal('pay ccdc supplement submit failed', error)
        handleBillStatus({
          actionType,
          status: StatusTypeEnum.FAILED as StatusTypeEnum,
          errMsg: (error as PipoRequestErrorObj).errorMessage || error.msg || error.message,
          pipoErrorCode: (error as PipoRequestErrorObj).errorCode,
        })
      }
    },
    [actionType, fromPage, getOrder, handleBillStatus, linkOpen, paySubmitByPIPOForm],
  )

  /**
   * 协议绑卡提交
   */
  const handleAgreementBindSubmit = useCallback(
    async ({ bindCardRes }: { bindCardRes: IBindCardRes }) => {
      const { agreementId } = bindCardRes
      try {
        if (ccdcRef.current?.validateSubmitData) {
          const pipoSubmitParams = await ccdcRef.current?.validateSubmitData()
          if (pipoSubmitParams.fillAddressState) {
            slardar.timer('fillAddressSubmitEvent', pipoSubmitParams.fillAddressState.metrics, {
              from: 'CCDC_BIND',
              ...pipoSubmitParams.fillAddressState.categories,
            })
          }
          let submitResult
          if (agreementId) {
            submitResult = await bindCCDCAuthorizeAgreement({
              pipoSubmitParams,
              fromPage,
              bindCardRes,
            })
          } else {
            submitResult = await storePaymentMethod({ pipoSubmitParams, fromPage, bindCardRes })
          }

          const redirectUrl = submitResult?.redirect_details?.url
          if (submitResult.result_code === PIPO_CODE.REDIRECT && redirectUrl) {
            // 跳转3DS
            linkOpen(redirectUrl as string, 'CCDC')
          } else if ([PIPO_CODE.SUCCESS, PIPO_CODE.SUCCEED].includes(submitResult.result_code)) {
            // 直接展示成果
            handleBillStatus({ actionType, status: submitResult.result_code })
          } else {
            // 其他情况一律按失败处理，根据国际支付的错误info，调用接口得到错误详情
            handleBillStatus({
              actionType,
              status: StatusTypeEnum.FAILED as StatusTypeEnum,
              errMsg: submitResult.error_message,
              pipoErrorCode: submitResult.error_code,
            })
          }
        }
      } catch (error: any) {
        paymentsLogger.fatal('bind ccdc supplement submit failed', error)
        handleBillStatus({
          actionType,
          status: StatusTypeEnum.FAILED as StatusTypeEnum,
          errMsg: (error as PipoRequestErrorObj).errorMessage || error.msg || error.message,
          pipoErrorCode: (error as PipoRequestErrorObj).errorCode,
        })
      }
    },
    [actionType, bindCCDCAuthorizeAgreement, fromPage, handleBillStatus, linkOpen, storePaymentMethod],
  )

  useImperativeHandle(ref, () => ({
    processSubmitData: async (submitParams) => {
      // 预请求参数处理
      if (ccdcRef.current?.validateSubmitData) {
        const { paymentMethod, storeCard, formData } = await ccdcRef.current?.validateSubmitData()

        if (!isBindSubmit) {
          const channelParameterObj = {
            paymentMethodId: paymentMethod.paymentMethodId,
            supportAgreementPaymentAndBind: true,
          }
          submitParams.channelParameter = SafeJSON.stringify(channelParameterObj)
          submitParams.bindAndPay = storeCard
        }

        if (isBindSubmit) {
          const card_brand = paymentMethod.paymentMethod
          const cardNo = formData.card_number

          submitParams.payWay = PAY_WAY_TYPE.EXISTED_CARD
          submitParams.paymentMethodId = paymentMethod.paymentMethodId
          submitParams.riskInfo = SafeJSON.stringify({
            ...SafeJSON.parse(submitParams.riskInfo),
            card_bin: cardNo.slice(0, 6),
            card_number: utils.getCardIdentityNo(cardNo),
            card_brand,
          })
        }
      }
      return submitParams
    },
    needHandleResult: () => true,
    handleResult: async (res) => {
      if (!isBindSubmit) {
        // 一步支付补充提交
        handleOneStepSupplySubmit({ submitOrderRes: res! as ISubmitOrderRes })
      }
      if (isBindSubmit) {
        // 协议绑卡补充提交
        handleAgreementBindSubmit({ bindCardRes: res! as IBindCardRes })
      }
    },
  }))

  const fetchPaymentMethodList = useCallback(
    () =>
      api.getCardOrgList({ token }).then((res) => {
        setPaymentMethodsList(res.data?.map((paymentMethodId) => ({ paymentMethodId })) || [])
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  useEffect(() => {
    fetchPaymentMethodList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 同步验证状态
  useEffect(() => {
    changeNewPaymentStatus(PAY_WAY_TYPE.CREATE_NEW_CARD, { valid: ccdcValid })
  }, [ccdcValid, changeNewPaymentStatus])

  const onCCDCValid = useCallback((value: boolean) => {
    setCcdcValid(value)
  }, [])

  const onValidateCardBin = async (_cardNo = '', cardBrand = '') => {
    const cardNo = _cardNo.replaceAll(' ', '')

    let sharkJudgeRes: ISharkJudgeRes = { riskPassed: true, riskMessage: '' }

    try {
      const res = await api.sharkJudge({
        payWay: PAY_WAY_TYPE.CREATE_NEW_CARD,
        token: token,
        source: 0,
        riskInfo: SafeJSON.stringify({
          ...riskInfoObj,
          card_bin: cardNo.slice(0, 6),
          card_number: utils.getCardIdentityNo(cardNo),
          card_brand: cardBrand,
        }),
        terminalEquip: NEW_SDK_TERMINAL_EQUIP,
        upaySessionId: sessionId.current,
      })
      if (res.data) {
        sharkJudgeRes = res.data
      }
    } catch (e) {
      paymentsLogger.warn('ccdc validate card bin failed', e)
    }
    return sharkJudgeRes
  }

  return (
    <>
      {!paymentMethodsList ? (
        <div className="upay-ccdc-loading-container">
          <Skeleton loading={true} animation image={false} text={{ rows: 4 }} />
        </div>
      ) : (
        <CCDC
          ref={ccdcRef}
          submitType={actionType}
          paymentMethodsList={paymentMethodsList}
          onFetchDistrict={fetchDistrict}
          onValidate={onCCDCValid}
          showSavingBox={showSavingBox}
          onValidateCardBin={onValidateCardBin}
          contractAddressList={contractAddressList}
        />
      )}
    </>
  )
})

export default CCDCForm
