import React from 'react'

import starling from '@/utils/starling'

import './index.less'

interface IProps {
  starlingKey: Parameters<typeof starling>[0]
  placeholders: { key: string; value: any }[]
  highlightKeys?: string[]
}
const HighlightStarlingKey = ({ starlingKey, placeholders }: IProps) => {
  const gard = '$-$'
  const placeholderObj = placeholders.reduce((ret, cur) => {
    const { key } = cur
    ret[key] = gard
    return ret
  }, {} as Record<string, string>)
  const starlingText = starling(starlingKey, placeholderObj)
  if (!starlingText) {
    return <div />
  }
  const tokens = starlingText.split(gard)

  return (
    <div className="high-light-continer">
      {tokens.map((text: string, index: number) => {
        return (
          <span className="item" key={text + index}>
            {text}
            <span className="high-light-item">{placeholders[index]?.value}</span>
          </span>
        )
      })}
    </div>
  )
}

export default HighlightStarlingKey
