import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'

import { Modal } from '@arco-design/web-react'
import { ISubmitOrderRes, StatusActionTypeEnum, StatusTypeEnum } from '@upay/utils/es/types'

import { PAY_WAY_TYPE } from '@/constant'
import { IPayinMethodItem, PaymentSubmitMethodEnum } from '@/pages/PayNext/interface'
import { BlikForm } from '@/pipo/pc'
import { PipoComponentRef } from '@/pipo/utils/types'
import SafeJSON from '@/utils/SafeJSON'
import starling from '@/utils/starling'

import { IPaymentMethodsProps, IPaymentsMethodRef } from '../../interface'

const Blik = forwardRef<IPaymentsMethodRef, IPaymentMethodsProps>((props, ref): React.ReactElement => {
  const { changeNewPaymentStatus, handleBillStatus, closeLoading, paymentSubmitMethod, channel } = props

  const [isValid, setIsValid] = useState(false)
  const formRef = useRef<PipoComponentRef>(null)
  const [modal, context] = Modal.useModal()
  const showSavingBox = paymentSubmitMethod === PaymentSubmitMethodEnum.PAYMENT && (channel as IPayinMethodItem).supportPaymentAndBind

  // 同步验证状态
  useEffect(() => {
    changeNewPaymentStatus(PAY_WAY_TYPE.BLIK, { valid: isValid })
  }, [isValid, changeNewPaymentStatus])

  useImperativeHandle(ref, () => ({
    processSubmitData: async (submitParams) => {
      if (formRef.current?.validateSubmitData) {
        const { paymentParams, storeCard, paymentMethod } = await formRef.current?.validateSubmitData()
        const paymentMethodObj = {
          payment_method_id: paymentMethod.paymentMethodId,
          payment_elements: paymentParams,
        }
        submitParams.channelParameter = SafeJSON.stringify(paymentMethodObj)
        submitParams.bindAndPay = storeCard
      }
      return submitParams
    },
    needHandleResult: () => true,
    handleResult: async (res) => {
      if ((res as ISubmitOrderRes)?.needWait) {
        modal.confirm?.({
          className: 'upay-modal upay-modal-simple',
          title: starling('funds.refund.comm.popup_GoPay_title'),
          content: starling('funds.check_out.comm.complete_payment_app_tip', {
            app_name: 'BLIK',
          }),
          okText: starling('funds.refund.comm.popup_GoPay_button_complete'),
          cancelText: starling('funds.refund.comm.try_close'),
          onOk: () => {
            handleBillStatus({ actionType: StatusActionTypeEnum.PAY, status: StatusTypeEnum.SUCCESS })
          },
          onCancel: () => {
            closeLoading?.()
          },
          maskClosable: false,
        })
      } else {
        handleBillStatus({ actionType: StatusActionTypeEnum.PAY, status: StatusTypeEnum.SUCCESS })
      }
    },
  }))

  const onValidChange = useCallback((value: boolean) => {
    setIsValid(value)
  }, [])

  return (
    <div className="upay-banktrans-container">
      <BlikForm ref={formRef} onValidate={onValidChange} showSavingBox={showSavingBox} />

      {context}
    </div>
  )
})

export default Blik
