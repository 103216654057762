import { useCallback, useContext, useState } from 'react'

import { StatusActionTypeEnum, StatusTypeEnum } from '@upay/utils/es/types'

import { IOpenStatusProps } from '@/components/Status/config'
import { PAY_WAY_TYPE, PUBSUB_TYPES } from '@/constant'
import { IPaymentCodeProps, IPaymentCodeQuery } from '@/pages/PaymentCode/interface'
import { ParamsContext } from '@/stores'
import starling from '@/utils/starling'

import { payLogger } from '..'
import { Order } from '../interface'

interface IUsePaymentCodeProps {
  getOrder: () => Order | undefined
  setPaying: (value: React.SetStateAction<boolean>) => void
  handleBillStatus: (props: IOpenStatusProps) => Promise<void>
}

export const usePaymentCode = (props: IUsePaymentCodeProps) => {
  const { getOrder, setPaying, handleBillStatus } = props
  const {
    urlQuery: { bizId, token, type, appId },
    uPubSub,
  } = useContext(ParamsContext)
  const [paymentCodeBasicProps, setPaymentCodeBasicProps] = useState<IPaymentCodeProps>({
    visible: false,
  })

  const openPaymentCode = useCallback(
    ({ payWay, query }: { payWay?: number; query?: IPaymentCodeQuery }) => {
      payLogger.info('openPaymentcode', { payWay, query })
      if (!query) {
        query = {
          bizId,
          token,
          type,
          appId,
          payway: `${payWay}`,
          recordNo: getOrder()?.charge_id,
          paymentReference: getOrder()?.payment_reference,
        }
      }
      setPaymentCodeBasicProps({ visible: true, payWay, query })
      setPaying(false)
    },
    [appId, bizId, getOrder, setPaying, token, type],
  )

  const handlePaymentCodeClose = useCallback(
    (payWay: number, openStatus = false) => {
      setPaymentCodeBasicProps((prePorps) => ({ ...prePorps, visible: false }))
      setPaying(false)
      if (!openStatus) {
        // 未查询到状态再走此处的处理逻辑

        if (payWay === PAY_WAY_TYPE.BOLETO) {
          // boleto paymentcode 返回后展示 processing
          handleBillStatus({
            actionType: StatusActionTypeEnum.PAY,
            status: StatusTypeEnum.PROCESSING,
            errMsg: starling('funds.check_out.comm.status_payment_processing_tips1'),
          })
        } else {
          // banktransfer paymentcode 返回后无后续提示，直接向业务方发送成功回调
          uPubSub.publish(PUBSUB_TYPES.STATUS_RESULT, {
            status: 1,
            clickBtn: true,
            actionType: 'pay',
            msg: 'Back From Paymentcode',
          })
        }
      }
    },
    [handleBillStatus, setPaying, uPubSub],
  )

  return { paymentCodeProps: { ...paymentCodeBasicProps, getOrder, onClose: handlePaymentCodeClose, handleBillStatus }, openPaymentCode }
}
