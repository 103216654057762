import { PaymentMethodItem, FetchDistrictFunc, PipoSubmitParams } from '@/pipo/utils'
import { IPaymentParamsMapItem } from '@/pipo/utils/types'
/**
 *  加载 DirectDebit 组件入参
 */
export interface DirectDebitProps {
  showSavingBox?: boolean
  onValidate?: (isValid: boolean) => void
  onSubmit?: (params: PipoSubmitParams) => Promise<void>
}
/**
 * DirectDebit 需要填写表单的位数
 */
export type DirectDebitFormData = {
  holder_name?: string
  account_no?: string
  email?: string
  routing_no?: string
}

export const DIRECT_DEBIT_PAYMENT_PARAMS_MAP: Record<string, IPaymentParamsMapItem> = {
  holder_name: { element: 'eg_pi_dd_bacsdirectdebit_c_d_local_gb_holder_name', is_encrypted: true },
  account_no: { element: 'eg_pi_dd_bacsdirectdebit_c_d_local_gb_account_no', is_encrypted: true },
  routing_no: { element: 'eg_pi_dd_bacsdirectdebit_c_d_local_gb_routing_no', is_encrypted: true },
  email: { element: 'eg_pi_dd_bacsdirectdebit_c_d_local_gb_email', is_encrypted: true },
}

/**
 * DirectDebit PaymentMethodType
 */
export const DIRECT_DEBIT_PAYMENT_METHOD: PaymentMethodItem = {
  paymentMethodType: {
    configuration: {},
    icon: {
      iconUrl: '',
    },
    paymentMethodType: 'BACS_DIRECT_DEBIT' as PaymentMethodItem['paymentMethodType']['paymentMethodType'],
    starling_key: 'pipo_common_payin_direct_debit',
  },
  paymentMethod: {
    configuration: {},
    paymentMethod: 'DIRECT_DEBIT',
    paymentMethodId: 'pm_pi_dd_bacsdirectdebit_c_d' as PaymentMethodItem['paymentMethod']['paymentMethodId'],
    starling_key: 'pipo_common_payin_direct_debit',
    icon: {
      // TODO 待补充
      iconUrl: '',
      pngIconUrl: '',
      darkModePngIconUrl: '',
      grayIconUrl: '',
      detailIconUrl: '',
    },
    displayName: 'DirectDebit',
    title: {
      // TODO 待补充
      payment: '',
      piListShort: '',
      piListLong: '',
    },
  },
}
