import { useRef } from 'react'

export type IUseRetryRetParams = [(task: () => Promise<any>, confirmCb: (taskResult: any) => void) => void, () => void, () => void]
const DEFAULT_COUNT = 10
const DEFAULT_TIME_SPAN = 1000
const DEFAULT_BLOCK = true
const useRetry = (retryTimes: number = DEFAULT_COUNT, timeSpan: number = DEFAULT_TIME_SPAN, block: boolean = DEFAULT_BLOCK): IUseRetryRetParams => {
  const timer = useRef<number | null>(null)
  const remainTimes = useRef<number>(retryTimes)
  const retryParamsRef = useRef<{
    task: () => Promise<any>
    confirmCb: (taskResult: any) => void
  } | null>(null)
  const startRetry = async (task: () => Promise<any>, confirmCb: (taskResult: any) => void) => {
    retryParamsRef.current = {
      task,
      confirmCb,
    }
    try {
      if (block) {
        confirmCb(await task())
      } else {
        confirmCb(task())
      }
      timer.current = window.setTimeout(() => {
        if (remainTimes.current > 1) {
          remainTimes.current -= 1
          startRetry(task, confirmCb)
        } else {
          stopRetry()
        }
      }, timeSpan)
    } catch (e) {
      console.error(e)
    }
  }

  const stopRetry = () => {
    timer.current && clearTimeout(timer.current)
    timer.current = null
    remainTimes.current = 0
  }

  const refresh = () => {
    remainTimes.current = retryTimes
    startRetry(retryParamsRef.current!.task, retryParamsRef.current!.confirmCb)
  }

  return [startRetry, stopRetry, refresh]
}

export default useRetry
