"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault")["default"];
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.errorHandler = void 0;
var _slice = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/slice"));
var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));
var _objectSpread2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/objectSpread2"));
var _isError2 = _interopRequireDefault(require("lodash/isError"));
var _types = require("../types");
var _utils = require("./utils");
var errorHandler = function errorHandler(params) {
  var defaultLevel = params.defaultLevel,
    scope = params.scope,
    describe = params.describe,
    _params$err = params.err,
    err = _params$err === void 0 ? new Error('') : _params$err,
    _params$defaultTags = params.defaultTags,
    defaultTags = _params$defaultTags === void 0 ? {} : _params$defaultTags,
    _initTime = params._initTime;
  var error = err;
  var messageFromError = error.message;
  var level = defaultLevel; // 错误等级
  var reason = messageFromError; // 错误详情，括号内内容
  var extra = (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, (0, _utils.formatContentToString)(defaultTags)), {}, {
    messageFromError: messageFromError
  }); // 错误描述 key-value 对象
  // 对网络错误进行特别处理
  if (err.ERROR_TYPE === 'REQUEST_ERROR') {
    var _context;
    level = 'causeByNetwork';
    var pathFormat = (0, _slice["default"])(_context = (err.url || '').split('/')).call(_context, -2).join('/');
    if (err.http_status >= 0 && err.http_status !== 200) {
      var _context2;
      reason = (0, _concat["default"])(_context2 = " <".concat(pathFormat, "> HTTP Status ")).call(_context2, err.http_status);
    } else if (err.code >= 0 && err.code !== _types.RESPONSE_CODE.SUCCESS) {
      var _context3;
      reason = (0, _concat["default"])(_context3 = " <".concat(pathFormat, "> API code ")).call(_context3, err.code);
    } else if (err.errorObjMsg) {
      var _context4;
      reason = (0, _concat["default"])(_context4 = " <".concat(pathFormat, "> ")).call(_context4, err.errorObjMsg);
    }
    // 修改错误堆栈
    var _tempE = new Error('Network Error');
    error.stack = _tempE.stack;
    extra.msg = err.msg;
    extra.messageFromError = err.errorObjMsg;
    extra.logid = err.logid;
    extra.fetchUrl = err.url;
    extra.code = err.code;
    extra.http_status = err.http_status;
  }
  // 对PIPO网络错误进行特别处理
  if (err.ERROR_TYPE === 'PIPO_REQUEST_ERROR') {
    var _context5;
    level = 'causeByPIPORequest';
    var _pathFormat = (0, _slice["default"])(_context5 = (err.url || '').split('/')).call(_context5, -2).join('/');
    if (err.http_status >= 0 && err.http_status !== 200) {
      var _context6;
      reason = (0, _concat["default"])(_context6 = " <".concat(_pathFormat, "> HTTP Status ")).call(_context6, err.http_status);
    } else if (err.resultCode || err.errorCode) {
      var _context7, _context8;
      reason = (0, _concat["default"])(_context7 = (0, _concat["default"])(_context8 = " <".concat(_pathFormat, ">")).call(_context8, err.resultCode ? " PIPO_RESULT_CODE:".concat(err.resultCode) : '')).call(_context7, err.errorCode ? " PIPO_ERROR_CODE:".concat(err.errorCode) : '');
    } else if (err.errorObjMsg) {
      var _context9;
      reason = (0, _concat["default"])(_context9 = " <".concat(_pathFormat, "> ")).call(_context9, err.errorObjMsg);
    }
    // 修改错误堆栈
    var _tempE2 = new Error('Network Error');
    error.stack = _tempE2.stack;
    extra.errorMessage = err.errorMessage;
    extra.messageFromError = err.errorObjMsg;
    extra.logid = err.logid;
    extra.fetchUrl = err.url;
    extra.resultCode = err.resultCode;
    extra.errorCode = err.errorCode;
    extra.http_status = err.http_status;
  }
  if (!(0, _isError2["default"])(err)) {
    error = new Error(_utils.SafeJSON.stringify(err));
  }
  extra.level = level;
  extra._scope = scope;
  extra._timeDiff = String(new Date().getTime() - _initTime);
  return {
    level: level,
    scope: scope,
    describe: describe,
    reason: reason,
    error: error,
    extra: extra
  };
};
exports.errorHandler = errorHandler;