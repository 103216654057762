import React, { useMemo } from 'react'

import { Button, List, Modal } from '@arco-design/web-react'

import { CardTransferStatusEnum, ICardTransferRes } from '@/services/types'
import { TransferError, TransferSuccess, Warning } from '@/static/icon'
import starling from '@/utils/starling'

import './index.less'

const TRANSFER_SUCCESS_CODE = CardTransferStatusEnum.Success

export interface ITransferStatusProps {
  visible: boolean
  data?: ICardTransferRes | undefined
  onOk: (data: ICardTransferRes | undefined) => void
  onCancel: (data: ICardTransferRes | undefined) => void
}

const TransferStatus: React.FC<ITransferStatusProps> = (props) => {
  const { visible, data, onOk, onCancel } = props
  const { status = TRANSFER_SUCCESS_CODE, details, errorMsg } = data || {}

  const innerStatus = useMemo(() => {
    return {
      isSuccess: status === TRANSFER_SUCCESS_CODE, // 部分成功也算成功
      isError: status === CardTransferStatusEnum.Error,
      successList: details?.filter((item) => item.status === TRANSFER_SUCCESS_CODE),
      errorList: details?.filter((item) => item.status !== TRANSFER_SUCCESS_CODE),
    }
  }, [status, details])
  const { isSuccess, isError, successList = [], errorList = [] } = innerStatus

  const statusConfig = useMemo(() => {
    const configMap = {
      [CardTransferStatusEnum.Empty]: {
        icon: <TransferSuccess />,
        title: starling('funds.refund.comm.transfer_noapplicable'),
        errorMsg: ' ',
      },
      [CardTransferStatusEnum.Success]: {
        icon: <TransferSuccess />,
        title: starling('funds.refund.comm.transfer_successful'),
        errorMsg: '',
      },
      [CardTransferStatusEnum.Error]: {
        icon: <TransferError />,
        title: starling('funds.refund.comm.transfer_failed'),
        errorMsg: errorMsg || '',
      },
    }
    return configMap[status]
  }, [status, errorMsg])

  return (
    <Modal className="upay-modal upay-modal-simple upay-transfer-status-modal" visible={visible} footer={null} closable={false}>
      <div className="upay-transfer-status-icon">{statusConfig?.icon}</div>

      <div className="upay-transfer-status-title">{statusConfig?.title}</div>

      {statusConfig?.errorMsg && <div className="upay-transfer-status-desc">{statusConfig.errorMsg}</div>}

      {/* 全部成功展示条数 */}
      {isSuccess && !errorList?.length && successList?.length > 1 && (
        <div className="upay-transfer-status-desc">
          {starling(`funds.refund.comm.transfer_success_detail_plurality`, { number: successList?.length || 0 })}
        </div>
      )}

      {/* 部分成功展示详情 */}
      {isSuccess && errorList?.length > 0 && successList?.length > 0 && (
        <>
          <div className="upay-transfer-status-list-wrap">
            <div className="upay-transfer-status-desc flex-center">
              {starling(`funds.refund.comm.transfer_success_detail${successList.length > 1 ? '_plurality' : ''}`, {
                number: successList?.length || 0,
              })}
            </div>
            <List
              className="upay-transfer-status-list"
              dataSource={successList}
              bordered={false}
              render={(item) => {
                return (
                  <div className="upay-transfer-status-list-item">
                    {item?.identityType || ''} {item?.identityNo} {item?.expire_time && <small>({item.expire_time})</small>}
                  </div>
                )
              }}
            />
          </div>

          <div className="upay-transfer-status-list-wrap-error">
            <div style={{ marginBottom: 4 }} className="upay-transfer-status-desc flex-center">
              <Warning />
              <span style={{ marginLeft: 4 }}>
                {starling(`funds.refund.comm.transfer_failure_detail${errorList.length > 1 ? '_plurality' : ''}`, { number: errorList?.length || 0 })}
              </span>
            </div>
            <List
              className="upay-transfer-status-list"
              dataSource={errorList}
              bordered={false}
              render={(item) => {
                return (
                  <div className="upay-transfer-status-list-item">
                    <div className="upay-item-title">
                      {item?.identityType || ''} {item?.identityNo} {item?.expire_time && <small>({item.expire_time})</small>}
                    </div>
                    <div className="upay-item-msg">{item.errorMsg}</div>
                  </div>
                )
              }}
            />
          </div>
        </>
      )}

      <div className="upay-transfer-status-action">
        {/* 仅在失败时展示关闭按钮 */}
        {isError && (
          <Button style={{ marginRight: 8 }} onClick={() => onCancel(data)}>
            {starling('funds.refund.comm.cancel')}
          </Button>
        )}
        <Button type="primary" onClick={() => onOk(data)}>
          {!isError ? starling('funds.refund.comm.get_id') : starling('funds.refund.comm.retry')}
        </Button>
      </div>
    </Modal>
  )
}

export default TransferStatus
