import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

import { Logger } from '@upay/utils'
import { StatusActionTypeEnum, StatusTypeEnum } from '@upay/utils/es/types'

import { COMMON_PROMPT, PUBSUB_TYPES, SHOW_HELP_CENTER_PLATFORM_MAP, TRADE_TYPE } from '@/constant'
import api from '@/services'
import { ParamsContext } from '@/stores'
import GlobalInfo from '@/stores/GlobalInfo'
import utils from '@/utils'
import { getLang } from '@/utils/getLang'
import { logger } from '@/utils/slardar'
import starling from '@/utils/starling'

import { IOpenStatusProps, IResultExtraInfo, IStatusCloseCallbackProps, PullStatusEnum, StatusT } from './config'

const useStatus = () => {
  const { urlQuery, uPubSub, params, pid } = useContext(ParamsContext)
  const {
    data: { lastBindCardId },
  } = useContext(GlobalInfo)
  const { type, token, bizId } = urlQuery
  const { showSuccessStatus, allowCancel } = params
  const [actionType, setActionType] = useState<StatusActionTypeEnum>(StatusActionTypeEnum.PAY)
  const [status, setStatus] = useState<StatusTypeEnum>(StatusTypeEnum.PROCESSING)
  const [errMsg, setErrMsg] = useState<string>('')
  const [extraInfo, setExtraInfo] = useState<IResultExtraInfo>()
  // 是否展示
  const [visible, setVisible] = useState<boolean>(false)
  // 是否在轮询中
  const [polling, setPolling] = useState<boolean>(false)
  // 轮询结果
  const [pollCount, setPollCount] = useState<number>(10)
  // 展示成功结果后剩余时间
  const [remainTime, setRemainTime] = useState<number>(3)
  const isSuccess = useMemo(() => [StatusTypeEnum.SUCCEED, StatusTypeEnum.SUCCESS].includes(status), [status])

  //标示status页面在processing状态时是否需要轮询
  const needPoolRef = useRef<boolean>(false)
  const payWayRef = useRef<number | null>(null)
  const recordNoRef = useRef<string | null>(null)
  const timerRef = useRef<number>()
  const onCloseCallback = useRef<(props: IStatusCloseCallbackProps) => void>(() => null)
  // 可通过此参数传值给onClose的extraPramas参数
  const onCloseExtraPramasRef = useRef<any>(null)
  // 到终态时发送埋点和告知业务方时间
  const handleFinalResult = useCallback(
    async (status: StatusTypeEnum, actionType: StatusActionTypeEnum, finalMsg = '') => {
      const payWay = payWayRef.current
      // 发送结果页埋点
      statusLogger.scenes.resultEvent({
        status,
        actionType,
        errMsg: finalMsg,
        payWay,
        recordNo: recordNoRef.current,
        cardId: actionType === StatusActionTypeEnum.BIND ? lastBindCardId.current : '',
      })

      // 支付页不返回非支付场景的事件
      if (pid === 'pay' && actionType !== StatusActionTypeEnum.PAY) return

      // 通知业务方结果
      uPubSub.publish(PUBSUB_TYPES.STATUS_RESULT, {
        status: getBrocastStatus(status),
        clickBtn: false,
        actionType: actionType,
        msg: finalMsg,
      })
    },
    [lastBindCardId, pid, uPubSub],
  )
  //清楚定时器
  const clearTimer = useCallback(() => {
    timerRef.current && clearInterval(timerRef.current)
    timerRef.current = 0
  }, [])
  //成功时不展示结果并且恢复到初始状态
  const clearStatus = useCallback(() => {
    setVisible(false)
    //将状态设置为初始状态
    setStatus(StatusTypeEnum.PROCESSING)
    //如果存在定时器清除定时器
    clearTimer()
    onCloseCallback.current({
      actionType,
      status: StatusTypeEnum.SUCCEED,
      payWay: payWayRef.current,
      extraParams: onCloseExtraPramasRef.current,
    })
  }, [actionType, clearTimer])

  const pollEnd = useCallback(
    (finalStatus: StatusTypeEnum, actionType: StatusActionTypeEnum, errorMsg = '', extraInfo?: IResultExtraInfo) => {
      //针对多个接口返回结果,只需要处理一次
      if (!needPoolRef.current) return
      needPoolRef.current = false
      clearTimer()
      setPolling(false)
      errorMsg && setErrMsg(errorMsg)
      setExtraInfo && setExtraInfo(extraInfo)
      setStatus(finalStatus)
      //如果 showSuccessStatus 参数为false 则在后付费绑卡成功时不展示状态页面
      if (finalStatus === StatusTypeEnum.SUCCESS && !showSuccessStatus) {
        clearStatus()
      }
      handleFinalResult(finalStatus, actionType)
    },
    [handleFinalResult, showSuccessStatus, clearTimer, clearStatus],
  )

  const pollStatus = useCallback(
    (requestId: string, actionType: StatusActionTypeEnum, recordNo: string | null) => {
      needPoolRef.current = true
      setPolling(true)
      setPollCount(10)
      setStatus(StatusTypeEnum.PROCESSING)
      const IS_BIND = [StatusActionTypeEnum.BIND, StatusActionTypeEnum.UPDATE].includes(actionType)
      const pullApi = IS_BIND ? api.getBindResult : api.getPayResult
      const commonParams = { token, language: getLang() }
      const params = IS_BIND ? { cardId: requestId } : { recordNo: recordNo || '' }
      timerRef.current = window.setInterval(() => {
        setPollCount((prevCount) => {
          if (prevCount <= 1) {
            // 超时
            clearTimer()
            setPolling(false)
            return prevCount
          } else {
            pullApi({
              ...params,
              ...commonParams,
            } as any)
              .then((res) => {
                const { status, errorMsg, tipMsg, tradeType, extraInfo } = res.data || {}
                if (status === PullStatusEnum.SUCCESS) {
                  pollEnd(StatusTypeEnum.SUCCESS, actionType, tipMsg, extraInfo)
                } else if (status === PullStatusEnum.FAIL) {
                  pollEnd(StatusTypeEnum.FAILED, actionType, errorMsg, extraInfo)
                } else if (status === PullStatusEnum.PENDING && tradeType === TRADE_TYPE.MONTHLY_PAY) {
                  pollEnd(StatusTypeEnum.PROCESSING, actionType, tipMsg, extraInfo)
                }
              })
              .catch(() => pollEnd(StatusTypeEnum.FAILED, actionType, COMMON_PROMPT().SYS_ERR))
            return prevCount - 1
          }
        })
      }, 1000)
    },
    [token, pollEnd, clearTimer],
  )

  const openStatus = useCallback(
    async (props: IOpenStatusProps) => {
      try {
        const { actionType, status, pipoErrorCode, errMsg = '', onCloseExtraPramas, onClose, payWay, recordNo, noPoll } = props
        statusLogger.scenes.statusPageView({
          actionType,
          status,
          pipoErrorCode,
          errMsg,
          payWay,
          recordNo,
          cardId: actionType === StatusActionTypeEnum.BIND ? lastBindCardId.current : '',
          noPoll,
        })

        // 展示状态类型
        setActionType(actionType)
        //记住基础值（发送事件埋点时使用)
        onClose ? (onCloseCallback.current = onClose) : (onCloseCallback.current = () => null)
        onCloseExtraPramas ? (onCloseExtraPramasRef.current = onCloseExtraPramas) : (onCloseExtraPramasRef.current = null)
        recordNo ? (recordNoRef.current = recordNo) : (recordNoRef.current = null)
        typeof payWay === 'number' ? (payWayRef.current = payWay) : (payWayRef.current = null)
        const isSuccess = [StatusTypeEnum.SUCCEED, StatusTypeEnum.SUCCESS].includes(status)
        //结果轮询
        if (isSuccess && !noPoll) {
          //打开状态页面
          setErrMsg('')
          setVisible(true)
          pollStatus(lastBindCardId.current, actionType, recordNoRef.current)
        } else {
          //先展示状态然后根据是否存在错误信息来展示错误信息
          setStatus(status)
          let finalMsg = errMsg
          if (pipoErrorCode) {
            finalMsg = await utils.getPIPOErrorDetail(pipoErrorCode, getLang())
          }
          setErrMsg(finalMsg)
          //对于预付费支付场景，如果当前状态是失败直接展示status，如果是成功根据showSucessStatus决定
          if (isSuccess && actionType === StatusActionTypeEnum.PAY && !showSuccessStatus) {
            clearStatus()
          } else {
            setVisible(true)
          }
          handleFinalResult(status, actionType, finalMsg)
        }
      } catch (error) {
        statusLogger.error('useStatus hook open status failed', error)
        throw error
      }
    },
    [pollStatus, lastBindCardId, showSuccessStatus, handleFinalResult, clearStatus],
  )

  const onClose = useCallback(
    (retry = true) => {
      statusLogger.scenes.statusPageClose()
      setVisible(false)
      //将状态设置为初始状态
      setStatus(StatusTypeEnum.PROCESSING)
      //如果存在定时器清除定时器
      clearTimer()
      onCloseCallback.current({ actionType, status, errMsg, payWay: payWayRef.current, extraParams: onCloseExtraPramasRef.current, retry })

      // 支付页不返回非支付场景的事件
      if (pid === 'pay' && actionType !== StatusActionTypeEnum.PAY) return

      // 通知业务方结果
      uPubSub.publish(PUBSUB_TYPES.STATUS_RESULT, {
        status: getBrocastStatus(status),
        clickBtn: true,
        actionType: actionType,
        msg: errMsg,
        retry,
      })
    },
    [clearTimer, actionType, status, errMsg, pid, uPubSub],
  )

  // 是否展示cancel按钮
  const showCancel = useMemo<boolean>(
    () => allowCancel && !isSuccess && actionType === StatusActionTypeEnum.PAY,
    [actionType, allowCancel, isSuccess],
  )
  //是否展示 needHelp 按钮(用户在支付失败并且showCancel为false并且要属于对应平台)
  const showNeedHelp = useMemo<boolean>(
    () => status === StatusTypeEnum.FAILED && actionType === StatusActionTypeEnum.PAY && !showCancel && SHOW_HELP_CENTER_PLATFORM_MAP.includes(bizId),
    [actionType, showCancel, bizId, status],
  )

  //根据状态渲染结果页面按钮
  const buttonInfo = useMemo(() => {
    let primaryButtonInfo = {
      buttonText: starling('funds.refund.comm.back'),
      disabled: false,
      handleClick: (...props: any) => {
        statusLogger.scenes.statusPageClick({ clickItem: 'back', buttonType: 'primary', buttonText: primaryButtonInfo.buttonText })
        onClose(...props)
      },
    }
    let cancelButtonInfo: any = undefined

    // 终态
    if (isSuccess) {
      // 成功 倒计时结束后自动关闭结果页
      primaryButtonInfo.buttonText = starling('funds.refund.comm.close', {
        number: remainTime,
      })
    } else if (status === StatusTypeEnum.PROCESSING) {
      // 处理中 适用于回调时间较长的支付方式，允许直接返回 部分支付方式支持下载额外信息
      if (extraInfo && extraInfo.mandateUrl) {
        primaryButtonInfo = {
          buttonText: starling('funds.check_out.comm.pdf_download'),
          disabled: false,
          handleClick: () => {
            statusLogger.scenes.statusPageClick({ clickItem: 'download', buttonType: 'primary', buttonText: primaryButtonInfo.buttonText })
            window.open(extraInfo.mandateUrl, '_blank')
          },
        }
        cancelButtonInfo = {
          buttonText: starling('funds.refund.comm.back'),
          disabled: false,
          handleClick: (...props: any) => {
            statusLogger.scenes.statusPageClick({ clickItem: 'back', buttonType: 'cancel', buttonText: cancelButtonInfo.buttonText })
            onClose(...props)
          },
          onClose,
        }
      } else {
        primaryButtonInfo.buttonText = starling('funds.refund.comm.back')
      }
    } else {
      // 失败 引导用户重试
      primaryButtonInfo.buttonText = starling('funds.refund.comm.try_again')
      // 可直接取消，关闭收银台
      if (allowCancel && actionType === StatusActionTypeEnum.PAY) {
        cancelButtonInfo = {
          buttonText: starling('funds.refund.comm.cancel'),
          disabled: false,
          handleClick: () => {
            statusLogger.scenes.statusPageClick({ clickItem: 'cancel', buttonType: 'cancel', buttonText: cancelButtonInfo.buttonText })
            onClose(false)
          },
        }
      }
    }

    // 轮训状态
    if (needPoolRef.current) {
      // 轮训过程中不展示cancel
      cancelButtonInfo = undefined

      if (polling) {
        primaryButtonInfo.buttonText = `${starling('funds.check_out.comm.Refresh')} ${pollCount} s`
        primaryButtonInfo.disabled = true
      } else {
        primaryButtonInfo.buttonText = starling('funds.check_out.comm.Refresh')
        primaryButtonInfo.disabled = false
        primaryButtonInfo.handleClick = () => {
          statusLogger.scenes.statusPageClick({ clickItem: 'refresh', buttonType: 'primary', buttonText: primaryButtonInfo.buttonText })
          pollStatus(lastBindCardId.current, actionType, recordNoRef.current)
        }
      }
    }
    return {
      primaryButtonInfo,
      cancelButtonInfo,
    }
  }, [onClose, isSuccess, status, remainTime, extraInfo, allowCancel, actionType, polling, pollCount, pollStatus, lastBindCardId])

  const statusProps = useMemo<StatusT>(
    () => ({ actionType, status, errMsg, visible, ...buttonInfo, showCancel, showNeedHelp }),
    [actionType, errMsg, visible, status, buttonInfo, showCancel, showNeedHelp],
  )

  useEffect(() => {
    if (!needPoolRef.current && isSuccess && visible && !timerRef.current) {
      timerRef.current = window.setInterval(() => {
        setRemainTime((prevTime) => {
          if (prevTime > 1) return --prevTime
          clearTimer()
          onClose()
          return 3
        })
      }, 1000)
    }
  }, [isSuccess, onClose, visible, clearTimer])

  return { statusProps, openStatus }
}

const getBrocastStatus = (status: StatusTypeEnum) => {
  // Boleto引入： PROCESSING 也向业务方通知成功结果
  return [StatusTypeEnum.SUCCEED, StatusTypeEnum.SUCCESS, StatusTypeEnum.PROCESSING].includes(status)
}
export default useStatus

export const statusLogger = new Logger('SDK_STATUS', logger)
