import { IGetContractAddressRes } from '@/services/types'
import { PaymentMethodItem, PipoSubmitParams, FetchDistrictFunc, IPaymentParamsMapItem } from '../../types'

/**
 *  加载 Pix 组件入参
 */
export interface PixProps {
  showSavingBox?: boolean
  hasCNPJ?: boolean
  hasBillingAddress?: boolean
  contractAddressList: IGetContractAddressRes[] | undefined
  onFetchDistrict: FetchDistrictFunc
  onSubmit?: (params: PipoSubmitParams) => Promise<void>
  onValidate?: (isValid: boolean) => void
}

/**
 * Pix 需要填写表单的位数
 */

export type PixFormData = {
  holder_name?: string
  email?: string
  phone?: string
  phone_country_code?: string
  proxy_type?: string
  identity?: string
  company_id?: string
  billing_address?: string
  billing_country_region?: string
  billing_state?: string
  billing_city?: string
  billing_postal_code?: string
  billing_street?: string
  contract_address?: string // 合同地址选择内部使用
}

export const PIX_PAYMENT_PARAMS_MAP: Record<string, IPaymentParamsMapItem> = {
  holder_name: { element: 'eg_pi_bap_pix_c_d_local_br_holder_name', is_encrypted: true },
  email: { element: 'eg_pi_bap_pix_c_d_local_br_email', is_encrypted: true },
  phone: { element: 'eg_pi_bap_pix_c_d_local_br_phone', is_encrypted: true },
  phone_country_code: { element: 'eg_pi_bap_pix_c_d_local_br_phone_country_code', is_encrypted: true },
  proxy_type: { element: 'eg_pi_bap_pix_c_d_local_br_proxy_type', is_encrypted: true },
  identity: { element: 'eg_pi_bap_pix_c_d_local_br_identity', is_encrypted: true },
  company_id: { element: 'eg_pi_bap_pix_c_d_local_br_company_id', is_encrypted: true },
  billing_country_region: { element: 'eg_pi_bap_pix_c_d_local_br_billing_country_region', is_encrypted: true },
  billing_state: { element: 'eg_pi_bap_pix_c_d_local_br_billing_state', is_encrypted: true },
  billing_city: { element: 'eg_pi_bap_pix_c_d_local_br_billing_city', is_encrypted: true },
  billing_postal_code: { element: 'eg_pi_bap_pix_c_d_local_br_billing_postal_code', is_encrypted: true },
  billing_street: { element: 'eg_pi_bap_pix_c_d_local_br_billing_street', is_encrypted: true },
}

/**
 * Pix PaymentMethodType
 */
export const PIX_PAYMENT_METHOD: PaymentMethodItem = {
  paymentMethodType: {
    configuration: {},
    icon: {
      iconUrl: '',
    },
    paymentMethodType: 'BANK_ACCOUNT_PROXY',
    starling_key: 'BANK_ACCOUNT_PROXY',
  },
  paymentMethod: {
    configuration: {},
    paymentMethod: 'PIX',
    paymentMethodId: 'pm_pi_bap_pix_c_d',
    starling_key: 'pipo_payin_payment_main_Pix',
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/pix-light-border_171cd8.svg',
      pngIconUrl: '',
      darkModePngIconUrl: '',
      grayIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/pix-gray_edcae2.svg',
      detailIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/pix-detail_97807d.svg',
    },
    displayName: 'Pix',
    title: {
      payment: 'pipo_payin_new_Pix_payment',
      piListShort: 'pipo_common_payin_pix_add',
      piListLong: 'pipo_common_payin_pix_add',
    },
  },
}
