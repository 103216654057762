import { useCallback, useContext, useRef } from 'react'

import { IGetContractAddressRes } from '@/services/types'
import { servicesUtils } from '@/services/utils'

import { ParamsContext } from '..'

export const useContractAddress = () => {
  const {
    urlQuery: { token },
  } = useContext(ParamsContext)
  const fetchContractAddressRef = useRef<Promise<IGetContractAddressRes[]>>()

  const fetchContractAddress = useCallback(async () => {
    fetchContractAddressRef.current = servicesUtils.getContractAddress(token)
  }, [token])

  const getContractAddressAsync = useCallback(async () => {
    if (!fetchContractAddressRef.current) {
      fetchContractAddress()
    }
    const res = await fetchContractAddressRef.current
    return res
  }, [fetchContractAddress])

  return getContractAddressAsync
}
