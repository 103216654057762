import React from 'react'

import { ItemBlock } from '../item-block'

interface IProps {
  dataSource: { title: React.ReactNode; value: React.ReactNode }[]
}
export const ItemBlockList = (props: IProps) => {
  const { dataSource } = props

  if (!dataSource?.length) return null

  return (
    <div className="pipo-pc-item-block-list">
      {dataSource.map((item, index) => {
        return (
          <ItemBlock key={index} style="half" title={item.title}>
            <span style={{ color: '#666666' }}>{item.value}</span>
          </ItemBlock>
        )
      })}
    </div>
  )
}
