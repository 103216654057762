import { Collector } from '@dp/byted-tea-sdk-oversea'
import { CONSTANT, createTeaClient } from '@upay/utils'
import { findKey } from 'lodash'

import { IS_DEV, IS_PROD } from '@/constant'

const teaInstance = new Collector('upay_sdk')
const teaClient = createTeaClient()

export const initTea = (params: { user_unique_id: string; channel: string; bizId: string; type: string; countryCode: string }) => {
  const { user_unique_id, channel, bizId, type, countryCode } = params

  teaInstance.init({
    app_id: 2777,
    channel: channel as any,
    channel_type: channel === 'oci' ? 'ttp' : 'tcpy',
    enable_ab_test: true,
    ab_channel_domain: `https://vmweb-${channel}.byteoversea.com`,
    log: IS_DEV,
  })

  teaInstance.config({
    _staging_flag: IS_PROD ? 0 : 1, // 0-正式表；1-测试表
    user_unique_id,
    evtParams: {
      user_id: user_unique_id,
      biz_platform: findKey(CONSTANT.BIZ_PLATFORM_MAP, (val) => val === bizId),
      pay_type: findKey(CONSTANT.PAYMENT_TYPE_MAP, (val) => val === type),
      country_code: countryCode,
      device_type: 'PC', // 'Mobile' / 'PC'
      device_source: '', // 'IOS' / 'Android'
      version: BUILD_VERSION,
      dropin_type: 'SDK',
    },
  })

  teaInstance.start()

  teaClient.init({
    event: teaInstance.event.bind(teaInstance),
  })
}

export default teaClient
