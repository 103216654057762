import { PaymentMethodItem } from '../../types'

export type USBanktransTransferFormData = {
  email?: string
  holder_name?: string
}

export const USBankTransfer_PAYMENT_PARAMS_MAP: Record<string, { element: string; is_encrypted?: boolean }> = {
  email: {
    element: 'eg_pi_bt_banktransfer_c_d_local_us_email',
    is_encrypted: true,
  },
  holder_name: {
    element: 'eg_pi_bt_banktransfer_c_d_local_us_holder_name',
    is_encrypted: true,
  },
}

export const USBankTransfer_PAYMENT_METHOD: PaymentMethodItem = {
  paymentMethodType: {
    configuration: {},
    icon: {
      iconUrl: '',
    },
    paymentMethodType: 'BANK_TRANSFER',
    starling_key: '',
  },
  paymentMethod: {
    configuration: {},
    paymentMethod: 'BANK_TRANSFER',
    paymentMethodId: 'pm_pi_bt_banktransfer_c_d',
    starling_key: '',
    icon: {
      iconUrl: '',
      pngIconUrl: '',
      darkModePngIconUrl: '',
      grayIconUrl: '',
      detailIconUrl: '',
    },
    displayName: 'BANK_TRANSFER',
    title: {
      payment: '',
      piListShort: '',
      piListLong: '',
    },
  },
}
