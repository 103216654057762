"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.BANKTRANSFER_MX_PAYMENT_PARAMS_MAP = exports.BANKTRANSFER_MX_PAYMENT_METHOD = void 0;
var BANKTRANSFER_MX_PAYMENT_PARAMS_MAP = {
  email: {
    element: 'eg_pi_bt_spei_c_d_local_mx_email',
    is_encrypted: true
  },
  holder_name: {
    element: 'eg_pi_bt_spei_c_d_local_mx_holder_name',
    is_encrypted: true
  }
};
exports.BANKTRANSFER_MX_PAYMENT_PARAMS_MAP = BANKTRANSFER_MX_PAYMENT_PARAMS_MAP;
var BANKTRANSFER_MX_PAYMENT_METHOD = {
  paymentMethodType: {
    configuration: {},
    icon: {
      iconUrl: ''
    },
    starling_key: '',
    paymentMethodType: 'BANK_TRANSFER'
  },
  paymentMethod: {
    configuration: {},
    paymentMethod: '',
    paymentMethodId: 'pm_pi_bt_spei_c_d',
    displayName: 'MX Bank Transfer',
    starling_key: '',
    icon: {
      iconUrl: '',
      voucherIconUrl: '',
      pngIconUrl: '',
      darkModePngIconUrl: '',
      detailIconUrl: ''
    },
    title: {
      payment: ''
    }
  }
};
exports.BANKTRANSFER_MX_PAYMENT_METHOD = BANKTRANSFER_MX_PAYMENT_METHOD;