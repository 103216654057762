import React from 'react'

import { Collapse, Radio } from '@arco-design/web-react'

import { IPayinMethodItem } from '@/pages/PayNext/interface'
import { CardListRes, ChannelRes } from '@/services/types'

import PopoverErrorTip from '../PopoverErrorTip'

import './index.less'

export type ChannelItem = {
  key: string | number
  name: string
  disable?: boolean
  content?: React.ReactNode
  disableTips?: string
  data?: ChannelRes | CardListRes | IPayinMethodItem
  renderTitle?: (c: ChannelItem) => JSX.Element
}

export interface IChannelProps {
  channels?: ChannelItem[]
  maxShowCount?: number
  activeChannel?: string
  onChange?: (key: string, data?: any) => void
}

const Channel = (props: IChannelProps): JSX.Element => {
  const { channels = [], activeChannel = '', onChange, maxShowCount } = props

  return (
    <div className="upay-channel">
      <Collapse accordion activeKey={activeChannel} bordered={false} lazyload={false}>
        {channels.map((i, index) => (
          <Collapse.Item
            className={!i.content ? 'upay-channel__no-content-panel' : ''}
            style={{ display: maxShowCount && maxShowCount <= index ? 'none' : 'block' }}
            showExpandIcon={false}
            key={String(i.key)}
            name={String(i.key)}
            disabled={i.disable}
            header={
              <div
                className="upay-channel__header"
                id={`upay-channel-${i.key}`}
                onClick={() => {
                  !i.disable && onChange?.(String(i.key), i.data)
                }}
              >
                <Radio checked={activeChannel === String(i.key)} disabled={i.disable}>
                  <React.Fragment>{i.renderTitle ? i.renderTitle(i) : <span className="upay-channel__name">{i.name}</span>}</React.Fragment>
                </Radio>
                {!!i.disableTips && <PopoverErrorTip message={i.disableTips} description={i.disableTips} />}
              </div>
            }
          >
            {i.content}
          </Collapse.Item>
        ))}
      </Collapse>
    </div>
  )
}

export default Channel
