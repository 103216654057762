import { IPaymentParamsMapItem, PaymentMethodItem, PipoSubmitParams } from '../../types'

export interface PayUProps {
  showSavingBox?: boolean
  onValidate?: (isValid: boolean) => void
  onSubmit?: (params: PipoSubmitParams) => Promise<void>
}

export type PayUFormData = {
  holder_name?: string
}

export const PAY_U_PAYMENT_PARAMS_MAP: Record<string, IPaymentParamsMapItem> = {
  holder_name: { element: 'eg_pi_ib_payu_c_r_local_cz_holder_name', is_encrypted: true },
}

export const PAY_U_PAYMENT_METHOD: PaymentMethodItem = {
  paymentMethodType: {
    configuration: {},
    icon: {
      iconUrl: '',
    },
    starling_key: 'INTERNET_BANKING',
    paymentMethodType: 'INTERNET_BANKING',
  },
  paymentMethod: {
    configuration: {},
    paymentMethod: 'PayU',
    paymentMethodId: 'pm_pi_ib_payu_c_r',
    displayName: 'PayU',
    starling_key: '',
    icon: {
      iconUrl: '',
      voucherIconUrl: '',
      pngIconUrl: '',
      darkModePngIconUrl: '',
      detailIconUrl: '',
    },
    title: {
      payment: '',
    },
  },
}
