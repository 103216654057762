import React, { useContext, useState } from 'react'

import { Message, Modal } from '@arco-design/web-react'

import { PUBSUB_TYPES } from '@/constant'
import PayoutUpdate from '@/pages/Payout/Update'
import api from '@/services'
import { CardListRes } from '@/services/types'
import { ParamsContext } from '@/stores'
import slardar from '@/utils/slardar'
import starling from '@/utils/starling'

import { ManageCardTypeEnum } from '../../types'
import CardBase from '../CardBase'

interface IProps {
  card: CardListRes
  refreshList: () => void
}

const PayoutCard: React.FC<IProps> = (props) => {
  const { card, refreshList } = props
  const { urlQuery, uPubSub } = useContext(ParamsContext)
  const { token } = urlQuery

  const [updateModalVisible, setUpdateModalVisible] = useState(false)

  const handleAction = (type: ManageCardTypeEnum) => {
    const handleMap: Record<ManageCardTypeEnum, () => void> = {
      [ManageCardTypeEnum.SetPrimary]: handleClickSetPrimary,
      [ManageCardTypeEnum.RemoveCard]: handleClickRemove,
      [ManageCardTypeEnum.EditCard]: handleClickEdit,
    }
    handleMap[type]?.()
  }

  const handleClickSetPrimary = () => {
    slardar.timer('managerSetPrimaryCardClick', 1, { currentCard: card })

    const ref = Modal.confirm({
      className: 'upay-modal upay-modal-simple upay-modal-primary-tip',
      title: starling('funds.refund.comm.make_primary'),
      content: starling('funds.refund.comm.replace_the_primary_card'),
      okText: starling('funds.refund.comm.confirm_button'),
      cancelText: starling('funds.refund.comm.cancel'),
      autoFocus: false,
      onOk: async () => {
        try {
          await api.setPayoutPrimaryCard({
            token,
            id: card?.id || '',
          })

          Message.success(starling('funds.refund.comm.replace_the_primary_card_successfully'))
          refreshList()
        } catch {
          ref.close()
        }
      },
    })
  }

  const handleClickRemove = () => {
    slardar.timer('managerRemoveCardClick', 1, { currentCard: card })

    const ref = Modal.confirm({
      className: 'upay-modal upay-modal-simple',
      title: starling('funds.refund.comm.delete_card'),
      content: starling('funds.refund.comm.remove_the_card'),
      okText: starling('funds.refund.comm.confirm_button'),
      cancelText: starling('funds.refund.comm.cancel'),
      autoFocus: false,
      onOk: async () => {
        try {
          await api.removePayoutCard({
            token,
            id: card?.id || '',
          })

          Message.success(starling('funds.refund.comm.removed_successfully'))
          refreshList()

          uPubSub.publish(PUBSUB_TYPES.REMOVE_CARD_SUCCESS)
        } catch {
          ref.close()
        }
      },
    })
  }

  const handleClickEdit = () => {
    setUpdateModalVisible(true)
  }

  const handleCloseEdit = (needRefresh?: boolean) => {
    needRefresh && refreshList()
    setUpdateModalVisible(false)
  }

  return (
    <>
      <CardBase card={card} onAction={handleAction} />

      <PayoutUpdate card={card} visible={updateModalVisible} onClose={handleCloseEdit} />
    </>
  )
}

export default PayoutCard
