import { useContext, useEffect, useMemo, useState } from 'react'

import GlobalInfo from '@/stores/GlobalInfo'
import starling from '@/utils/starling'

import createModel from './createModel'
import { defaultLocale } from './locale'
import { BoletoProps } from './payment-methods-config/boleto'
import { CCDCProps } from './payment-methods-config/ccdc'
import { PixProps } from './payment-methods-config/pix'
import { PaymentMethodsConfiguration, SupportPaymentMethodId } from './types'

export type LocalesType = Partial<typeof defaultLocale>

export type StarlingKey = keyof LocalesType

export interface PaymentAmount {
  value: string
  currency: string
}
interface Options {
  /**
   * Amount of the payment
   */
  amount?: PaymentAmount
  countryOrRegion?: string
  config?: {
    ccdc?: Partial<CCDCProps>
    // TODO: Change to payment method id
    boleto?: Partial<BoletoProps>
    pix?: Partial<PixProps>
    showReminder?: boolean
    showTitle?: boolean
    showPayBtn?: boolean
    selectedSaveBox?: boolean
  } & PaymentMethodsConfiguration
  locale?: string
  /**
   * 色彩主题
   *
   * 遵循 TUX 的规则：默认使用媒体查询自动切换，传入则覆盖此行为
   *
   * https://tux-dev.web.bytedance.net/?path=/story/best-practices-dark-mode--page
   *
   * https://developer.mozilla.org/zh-CN/docs/Web/CSS/@media/prefers-color-scheme
   */
  theme?: 'light' | 'dark'
  localeTranslation?: LocalesType
}

export const Context = (options: Options = {}) => {
  const {
    utils: { getPublicKeyAsync },
  } = useContext(GlobalInfo)
  const [state, setState] = useState<Options>(options)

  const mergedLocales: Record<string, string> = {
    ...defaultLocale,
    ...state?.localeTranslation,
  }

  const isFlip = useMemo(() => {
    return state?.locale?.toLocaleLowerCase() === 'ar'
  }, [state?.locale])

  useEffect(() => {
    setState(options)
  }, [options])

  const getErrorText = (type: string | undefined) => {
    if (!type) return ''

    switch (type) {
      case 'required':
        return starling('funds.refund.comm.system_mandatory_field')
      default:
        return starling('funds.refund.comm.system_invalidformat')
    }
  }

  const getPMConfig = (paymentMethodId: SupportPaymentMethodId): PaymentMethodsConfiguration[keyof PaymentMethodsConfiguration] => {
    const { config = {} } = state
    const shouldDefaultBindOnePmId = [
      'pm_pi_ew_dana_c_r',
      'pm_pi_ew_kakaopay_c_r',
      'pm_pi_ew_ovo_c_d',
      'pm_pi_ew_qiwi_c_r',
      'pm_pi_ew_ovo_c_r',
      'pm_pi_cp_oxxo_c_d',
    ]
    return config[paymentMethodId] || (shouldDefaultBindOnePmId.includes(paymentMethodId) ? { bindLimit: 1 } : {})
  }

  return {
    state,
    isFlip,
    theme: options.theme,
    t: (
      key: string,
      replaceItem?: {
        value: string
      },
    ) => {
      if (replaceItem && replaceItem.value) return mergedLocales[key].replace('{value}', replaceItem.value)
      return mergedLocales[key]
    },
    getErrorText,
    getPMConfig,
    getPublicKeyAsync,
  }
}

export const PipoProvider = createModel(Context)
