import { zip } from 'lodash'
import { chunk, pickNumeric } from './formatter'
import { cpf } from 'cpf-cnpj-validator'

// 11438374798000 => 114.383.747-98
// 11438374798    => 114.383.747-98
// 114383747      => 114.383.747
// 11438374       => 114.383.74
// 114383         => 114.383
function formatter(value = '') {
  const separators = ['', '.', '.', '-']

  const chunkSizes = [3, 3, 3, 2]

  const numeric = pickNumeric(value)
  const pairs = zip(separators, chunk(numeric, chunkSizes))
  const result = (pairs as Array<[string, string]>)
    .filter(([_, split]) => Boolean(split))
    .map(([separator, split]) => separator + split)
    .join('')

  return result
}

const isValid = cpf.isValid

const placeholder = '000.000.000-00'

export { formatter, isValid, placeholder }
