import { BrowserCommandClient, createBrowserClient } from '@slardar/web'
import { Logger } from '@upay/utils'

import { IS_DEV_OR_BOE } from '@/constant'

import SafeJSON from './SafeJSON'

interface ISlardarCategories {
  [key: string]: unknown
}

interface ISlardarContent {
  [key: string]: unknown
}

interface ISlardarMetrics {
  [key: string]: number
}

interface IInitSlardarProps {
  userId: string
  pid: string
  url: string
  appId: string
  bizId: string
  countryCode: string
  invokerId: string
}

export default {
  paramUrl: '',
  appId: '',
  bizId: '',
  countryCode: '',
  invokerId: '',
  Slardar: null as BrowserCommandClient | null,
  init({ userId, pid, url, appId, bizId, countryCode, invokerId }: IInitSlardarProps): void {
    try {
      const Slardar = createBrowserClient()
      Slardar('init', {
        bid: 'cg_fe_upay_sdk_web',
        env: IS_DEV_OR_BOE ? 'development' : 'production',
        pid,
        userId,
        release: BUILD_VERSION,
        pluginPathPrefix: 'https://sf16-website-login.neutral.ttwstatic.com/obj/tiktok_web_login_static/slardar/fe/sdk-web/plugins',
        plugins: {
          ajax: {
            ignoreUrls: ['maliva-mcs.byteoversea.com'],
          },
          fetch: {
            ignoreUrls: ['maliva-mcs.byteoversea.com'],
          },
          performance: {
            fp: false,
            fcp: false,
          },
        },
      })
      Slardar('start')
      // 初始化logger
      logger.init(Slardar, {
        commonParams: {
          pathname: window.location?.pathname,
          url: window.location?.href,
          paramUrl: url,
          appId,
          bizId,
          countryCode,
          invokerId,
          buildVersion: BUILD_VERSION,
        },
      })
      this.paramUrl = url
      this.appId = appId
      this.bizId = bizId
      this.countryCode = countryCode
      this.Slardar = Slardar
      this.invokerId = invokerId
    } catch (error) {
      console.error('slardar init error', error)
    }
  },
  performance(name: string, value: number): void {
    try {
      this.Slardar?.('sendCustomPerfMetric', {
        name,
        value,
      })
    } catch (error) {
      console.error('slardar performance error', error)
    }
  },
  timer(name: string, metrics?: ISlardarMetrics | number, categories?: ISlardarCategories): void {
    try {
      const metricsTemp: ISlardarMetrics = {}
      if (typeof metrics === 'undefined') {
        metricsTemp.count = 1
      } else if (typeof metrics === 'number') {
        metricsTemp.count = metrics
      } else if (typeof metrics === 'object') {
        Object.assign(metricsTemp, metrics)
      }
      // SafeJSON参数
      const extraCatefories: { [key: string]: any } = {}
      categories &&
        Object.keys(categories).forEach((key) => {
          if (categories[key] instanceof Object) {
            extraCatefories[key] = SafeJSON.stringify(categories[key])
            return
          }
          extraCatefories[key] = categories[key]
          return
        })
      this.Slardar?.('sendEvent', {
        name,
        metrics: metricsTemp,
        categories: {
          pathname: window.location?.pathname,
          url: window.location?.href,
          paramUrl: this.paramUrl,
          appId: this.appId,
          bizId: this.bizId,
          countryCode: this.countryCode,
          invokerId: this.invokerId,
          buildVersion: BUILD_VERSION,
          ...extraCatefories,
        },
      })
    } catch (error) {
      console.error('slardar timer error', error)
    }
  },
  logger(type: string, content: ISlardarContent, level: 'error' | 'info' = 'info', categories?: ISlardarCategories): void {
    try {
      // SafeJSON参数
      const extraCatefories: { [key: string]: any } = {}
      categories &&
        Object.keys(categories).forEach((key) => {
          if (categories[key] instanceof Object) {
            extraCatefories[key] = SafeJSON.stringify(categories[key])
            return
          }
          extraCatefories[key] = categories[key]
          return
        })
      this.Slardar?.('sendLog', {
        level,
        content: SafeJSON.stringify({ A_LOG_TYPE: type, CONTENT: content || {} }),
        extra: {
          type,
          pathname: window.location?.pathname,
          url: window.location?.href,
          paramUrl: this.paramUrl,
          appId: this.appId,
          bizId: this.bizId,
          countryCode: this.countryCode,
          invokerId: this.invokerId,
          buildVersion: BUILD_VERSION,
          ...extraCatefories,
        },
      })
    } catch (error) {
      console.error('slardar logger error', error)
    }
  },
}

export const logger = new Logger('SDK_BASE').initEnv(IS_DEV_OR_BOE ? 'dev' : 'prod')
