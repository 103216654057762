import { useContext, useRef } from 'react'

import { PAYMENT } from '@/constant'
import { useRetry } from '@/hooks'
import api from '@/services'
import { ParamsContext } from '@/stores'

const POST_COUNT = 10 // 每次任务最多执行10次

interface IProps {
  onPollingSuccess: () => void
  onPollingEnd: () => void
}
export const useQueryCardResult = (props: IProps) => {
  const { onPollingSuccess, onPollingEnd } = props
  const { urlQuery } = useContext(ParamsContext)
  const { token } = urlQuery
  const queryCount = useRef<number>(POST_COUNT) // 支付方式变更时【第一步，卡变更】，查询后付费绑卡结果的次数
  const [startQueryCardResult, stopQueryCardResult, refreshQueryCount] = useRetry(POST_COUNT, 1000, true)

  // 开始轮询接口，获取是否有已绑卡，查到或者查询次数到上限即结束
  const queryCardResult = async () => {
    if (!queryCount.current) {
      return
    }
    const res = await api.getCardList({
      token,
      type: PAYMENT.POST_PAY,
    })
    return res?.list || []
  }

  const handleQueryCardResult = (list: any[]) => {
    if (list.length > 0) {
      stopQueryCardResult()
      queryCount.current = POST_COUNT

      onPollingSuccess?.()
    } else {
      queryCount.current -= 1
      if (queryCount.current <= 0) {
        queryCount.current = POST_COUNT

        onPollingEnd()
      }
    }
  }

  const startQuery = () => {
    startQueryCardResult(queryCardResult, handleQueryCardResult)
  }

  const refreshQuery = () => {
    refreshQueryCount()
  }

  return { run: startQuery, refresh: refreshQuery }
}
