import { chunk } from 'lodash'

/**
 * 分割卡号，付款码等
 *
 * chunkString("Aloha", 3) => ["Alo", "ha"]
 *
 * chunkString("41111111111", 4) => ["4111", "1111", "111"]
 */
export const chunkString = (value: string, size: number) => {
  return chunk(value, size).map((chars) => chars.join(''))
}
