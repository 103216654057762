"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault")["default"];
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.getScenes = getScenes;
var _objectSpread2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/objectSpread2"));
var _baseScenes = require("./baseScenes");
var _payScenes = require("./payScenes");
var _paymentCodeScenes = require("./paymentCodeScenes");
var _pipoScenes = require("./pipoScenes");
var _statusScenes = require("./statusScenes");
function getScenes() {
  return (0, _objectSpread2["default"])((0, _objectSpread2["default"])((0, _objectSpread2["default"])((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _baseScenes.getBaseScenes.call(this)), _pipoScenes.getPipoScenes.call(this)), _payScenes.getPayScenes.call(this)), _statusScenes.getStatusScenes.call(this)), _paymentCodeScenes.getPaymentCodeScenes.call(this));
}