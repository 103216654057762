import { IResultExtraInfo, PullStatusEnum } from '@/components/Status/config'
import { IPayinMethodItem } from '@/pages/PayNext/interface'

export type CardDetail = {
  amount: string
  currency: string
  invalid: boolean
  unbound: boolean
  default?: boolean
}
export type PreOrderRes = {
  amount: string
  charge_id: string
  currency: string
  oneStep?: boolean
  payment_reference?: string
}
export type FailedChannelsRes = {
  channelErrorTitle?: string
  channelErrorDesc?: string
}
export type BillInfoRes = {
  amount: string
  billId: number
  currency: string
  customerId: number
  customerName: string
  status: number
  upayTradeType: string
  statusName: string
  displayAmount: string
}

export interface IPayWaysBoundListRes {
  bindRoutePayWayList: ChannelRes[]
  payRoutePayWayList: ChannelRes[]
  boundPayWayList: CardListRes[]
}

export interface IPIPaymentListRes {
  unfoldedPIList: IPayinMethodItem[]
  foldedPIList: IPayinMethodItem[]
}
export interface ISCard {
  isCard?: boolean
}

export type CardListRes = {
  id: string
  identityType: string
  identityNo: string
  allowUpdate: boolean
  status: number
  statusName: string
  bindStatus: number
  bindStatusName: string
  channelType: number
  channelTypeName: string
  countryCode: string
  currency: string
  primary: boolean
  cardOrganization: string
  expire_time: string
  expireTime: string
  expired: boolean
  valid: boolean
  invalidMessage: string
  imageUrl: string
  createTime: number
  modifyTime: number
  bankAssociation: string
  needCvv: boolean
  payToken: string
  payWay: number
  channelErrorTitle: string
  channelErrorDesc: string
  cardDetails?: CardDetail
  /** 在一步支付后保存的卡才有这个字段，如果强依赖需要单独请求card/query获取卡详情 */
  paymentMethodId?: string
}
export type ChannelRes = {
  hidePayWay: number | null
  payWay: number
  paymentsTitle: string
  paymentsTips: string
  disable: boolean
  exceedAmountLimitTips: string | null
  currency: string
  maxAmount: string
  paymentsIcon: string
  connectTitle: string
  paymentsLogo: string
  platformLogo: string
  isFirst: boolean
  needBindElements: boolean
  noPopUp: boolean
  ovoVersionTips: string | null
  channelErrorTitle?: string
  channelErrorDesc?: string
  /**
   * CCDC 卡组列表
   */
  cardOrgList: string[] | null
  riskAmount: string
  riskAmountCurrency: string
  displayRiskAmount: string
}
export type StringMap = {
  [name: string]: any
}
export type BindPayWayParams = {
  cardType: number
  token: string
  returnUrl: string
  payWay: number
  agreementId: string
  riskInfo: Record<string, any>
}

export interface IBankList {
  elements: IBankListElements[]
}

export interface IBankListElements {
  option_list: IBankListItem[]
}

export interface IBankListItem {
  key: string
  value: string
  name: string
  icon_url: string
  payment_method_id: string
}

export interface ILoginAuthRes {
  token: string
}
export interface IPageTypeRes {
  pageScheme: 1 | 2
}
export interface IPublicKeyRes {
  publicKeyPkcs1: string
  publicKeyPkcs8: string
}

export interface IBindCardParams {
  // required
  token: string
  returnUrl: string
  terminalEquip: number
  // 协议绑卡扣费需要 （CCDC PayPal）
  riskAmount?: string
  riskInfo: string
  // required CCDC 传99
  payWay: number
  paymentMethodId?: string
  channelParameter?: string
  // 绑卡提交埋点需要
  refId: string
  upaySessionId: string
  supportRiskAmount: true
  // 补充提交时需要
  cardId?: string
}

export interface IBindCardSupplementParams extends IBindCardParams {
  cardId: string
  otpCode?: string
}

export interface ISubmitOrderParams {
  token: string
  channelParameter?: string
  bindAndPay?: boolean
  payWay?: number | string
  returnUrl: string
  recordNo?: string
  cardId?: string
  bankCode?: string
  supportAgreementPaymentAndBind?: boolean
  saveActionByBackend?: boolean
  riskInfo?: string
  terminalEquip?: number
}

export interface IPayExtraInfo {
  otpCode: string
}

export interface ISubmitExtraInfoParams extends ISubmitOrderParams {
  extraInfo?: IPayExtraInfo
}

export interface IGetPayResultParams {
  token: string
  recordNo: string
  language: string
}

export interface IGetResultRes {
  status: PullStatusEnum
  errorMsg: string
  tipMsg?: string
  income?: boolean
  tradeType?: string
  extraInfo?: IResultExtraInfo
}

export interface IGetBindResultParams {
  token: string
  cardId: string
  language: string
}

export interface ITransferableCardItem extends CardListRes {
  /** 账号来源 */
  accountSource: string
  /** 账号来源描述 */
  accountSourceDesc: string
}

export interface ICardTransferRes {
  status: CardTransferStatusEnum
  errorCode?: string
  errorMsg?: string
  details: ICardTransferItemRes[]
}

export interface ICardTransferItemRes {
  cardId: string
  status: number
  errorCode: string
  errorMsg: string
  identityType?: string
  identityNo?: string
  expire_time?: string
}

export enum CardTransferStatusEnum {
  /** 内部错误码，表示可转移列表为空 */
  Empty = -1,
  Success = 2,
  Error = 3,
}

export interface ISharkJudgeParams {
  token: string
  // source 0 for web
  source: 0
  riskInfo: string
  payWay: number
  terminalEquip: number
  upaySessionId: string
}

export interface ISharkJudgeRes {
  riskPassed: boolean
  riskMessage: string
}

export interface IPostalCodeRes {
  info: string
  infoNum: number
  infocode: number
  post_infos: {
    postcode: number
    country_code: string
    adm1: { name: string; geoname_id: number }
    adm2: { name: string; geoname_id: number }
    adm3: { name: string; geoname_id: number }
    adm4: { name: string; geoname_id: number }
  }[]
  status: string
}

export interface IGetContractAddressRes {
  billingAddressId: string
  detailAddress: string
  postcode: string
  completeBillingAddress: string
  countryCode: string
  level1GeoId: string
  level2GeoId: string
  level3GeoId: string
  level4GeoId: string
  level1Name: string
  level2Name: string
  level3Name: string
  level4Name: string
}

export interface ICardUpdateReq {
  email?: string
  refId?: string
  token: string
  cardId: string
  payWay?: number
  riskInfo?: string
  riskAmount?: string
  terminalEquip?: number
  upaySessionId?: string
  paymentMethodId?: string
  channelParameter?: string
}

export interface IRiskInfoRes {
  riskAmount: string
  riskAmountCurrency: string
  displayRiskAmount: string
}

export interface IJudgeSdkVersionReq {
  token: string
  curVersion: string
  terminalEquip: number
}

export interface IJudgeSdkVersionRes {
  forceUpdateVersion: boolean
  showRefreshMsg: boolean
}
