import { Email, HolderName, ItemInput } from '@/pipo/pc/inner-components'
import { validator } from '@/pipo/utils'
import starling from '@/utils/starling'

import { IBankAccountFormConfigItem, IGetBankAccountFormConfigProps } from '../utils'

const IBAN_FORMAT_CONFIG: { [key: string]: { placeholder: string; maxLength: number; pattern: RegExp } } = {
  FR: {
    placeholder: 'FR0000000000000000000000000',
    maxLength: 27,
    pattern: validator.frIban,
  },
  IT: {
    placeholder: 'IT0000000000000000000000000',
    maxLength: 27,
    pattern: validator.itIban,
  },
  DE: {
    placeholder: 'DE00000000000000000000',
    maxLength: 22,
    pattern: validator.deIban,
  },
}

export const getBIC_IBANFormConfig = ({ countryCode, cardHolderInfo }: IGetBankAccountFormConfigProps): IBankAccountFormConfigItem => {
  const IBAN_CONFIG = IBAN_FORMAT_CONFIG[countryCode] || {}
  return {
    allowAccountType: ['b', 'c'],
    formSchema: [
      {
        field: 'holder_name',
        widget: HolderName,
        widgetProps: {
          title: starling('funds.refund.comm.system_name'),
          placeholder: starling('funds.refund.comm.system_name_description'),
          maxLength: 70,
          selectList: cardHolderInfo?.holderNameList,
        },
        rules: { required: true, pattern: validator.nameRegex },
      },
      {
        field: 'bic',
        widget: ItemInput,
        widgetProps: {
          title: starling('funds.refund.comm.swift_code'),
          placeholder: starling('funds.refund.comm.swift_code'),
          maxLength: 11,
        },
        rules: { required: true, pattern: validator.frBic, validate: { length: (val) => val.length === 8 || val.length === 11 } },
      },
      {
        field: 'iban',
        widget: ItemInput,
        widgetProps: {
          title: starling('cg.upay.exception.channel_youriban'),
          placeholder: IBAN_CONFIG.placeholder,
          maxLength: IBAN_CONFIG.maxLength,
        },
        rules: { required: true, pattern: IBAN_CONFIG.pattern },
        ruleMessages: { pattern: starling('cg.upay.exception.channel_IBAN_errormsg') },
      },
      {
        field: 'email',
        widget: Email,
        widgetProps: {
          title: starling('funds.refund.comm.system_email'),
          placeholder: starling('funds.refund.comm.system_email_placeholder'),
        },
        rules: { required: true, pattern: validator.emailRegex },
      },
    ],
  }
}
