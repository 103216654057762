import { useCallback, useContext, useState } from 'react'

import { StatusActionTypeEnum, StatusTypeEnum } from '@upay/utils/es/types'

import { IOpenStatusProps } from '@/components/Status/config'
import { ORDER_STATUS, ORDER_STATUS_MAP, PAYMENT, TRADE_TYPE } from '@/constant'
import api from '@/services'
import { BillInfoRes } from '@/services/types'
import { ParamsContext } from '@/stores'
import SafeJSON from '@/utils/SafeJSON'
import starling from '@/utils/starling'

import { payLogger } from '..'

interface IUseBillInfoProps {
  setErrorMsg: (msg: string) => void
  handleBillStatus: (props: IOpenStatusProps) => void
}

export const useBillInfo = ({ setErrorMsg, handleBillStatus }: IUseBillInfoProps) => {
  const {
    urlQuery: { token, type, billType },
  } = useContext(ParamsContext)
  // 账单信息
  const [billInfo, setBillInfo] = useState<BillInfoRes>()

  const getBillInfo = useCallback(() => {
    api
      .getBillInfo({ token })
      .then((res) => {
        const { status, statusName, upayTradeType } = res.data as BillInfoRes
        setBillInfo(res.data)

        if (upayTradeType === TRADE_TYPE.MONTHLY_PAY && [ORDER_STATUS.PAYING, ORDER_STATUS.PAY_SUCCESS].includes(status)) {
          // 处理 月账单 重复支付
          statusName && setErrorMsg(statusName)
          const billStatus = ORDER_STATUS_MAP[status]
          handleBillStatus({
            actionType: StatusActionTypeEnum.PAY,
            status: billStatus,
            errMsg: status === ORDER_STATUS.PAYING ? starling('funds.check_out.comm.notice_payment_processing') : '',
            noPoll: billStatus === StatusTypeEnum.SUCCESS, //月账单已经成功的场景不需要再次在结果页面轮询
            hideModal: true,
          })
        } else if (type === PAYMENT.POST_PAY && billType !== PAYMENT.PRE_PAY) {
          // 后付费进行失败账单支付时：pending状态【1】和成功状态【3】，提示错误
          if ([ORDER_STATUS.PAYING, ORDER_STATUS.PAY_SUCCESS].includes(status)) {
            setErrorMsg(statusName)
          }
        } else {
          // 预付费：超时状态【5】和成功状态【3】，提示错误
          if ([ORDER_STATUS.PAY_TIMEOUT, ORDER_STATUS.PAY_SUCCESS].includes(status)) {
            setErrorMsg(statusName)
          }
        }

        payLogger.scenes.payBillInfoFetched({ billInfoRes: res.data!, logid: res.logid! })
      })
      .catch((e) => {
        payLogger.fatal('pay bill info fetch error', e, { scene: 'payBillInfoFetched' })
        setErrorMsg(e?.msg || SafeJSON.stringify(e))
      })
  }, [billType, handleBillStatus, setErrorMsg, token, type])

  return { billInfo, refreshBillInfo: getBillInfo }
}
