import { PaymentMethodItem } from '../../types'

export const MINISTOP_PAYMENT_PARAMS_MAP: Record<string, string> = {
  email: 'eg_cvs_jp_email',
}

export const MINISTOP_PAYMENT_METHOD: PaymentMethodItem = {
  paymentMethodType: {
    configuration: {},
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/cvs-common_f99160.svg',
    },
    starling_key: '',
    paymentMethodType: 'CASH_PIN',
    title: {
      payment: 'pipo_common_payin_conveniencestore',
    },
  },
  paymentMethod: {
    configuration: {
      png_icon_url: '',
    },
    paymentMethod: 'MINISTOP',
    paymentMethodId: 'pm_pi_cp_ministop_c_d',
    displayName: 'Ministop',
    starling_key: 'pipo_payin_payment_main_ministop',
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/ministop-white_1685fa.svg',
      grayIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/ministop-gray_077197.svg',
      voucherIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/ministop-voucher_747909.svg',
    },
  },
}
