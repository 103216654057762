import { IPaymentParamsMapItem, PaymentMethodItem } from '../../types'

export interface PaycellFormProps {
  onValidate?: (isValid: boolean) => void
  showSavingBox?: boolean
}

export type PaycellFormData = {
  identity?: string
  mobile_phone?: string
  phone?: string
  phone_country_code?: string
}

export const PAYCELL_PAYMENT_PARAMS_MAP: Record<string, IPaymentParamsMapItem> = {
  identity: {
    element: 'eg_pi_ew_paycell_c_d_local_tr_identity',
    is_encrypted: true,
  },
  mobile_phone: {
    element: 'eg_pi_ew_paycell_c_d_local_tr_mobile_phone',
    is_encrypted: true,
  },
  phone: {
    element: 'eg_pi_ew_paycell_c_d_local_tr_phone',
    is_encrypted: true,
  },
  phone_country_code: {
    element: 'eg_pi_ew_paycell_c_d_local_tr_phone_country_code',
    is_encrypted: true,
  },
}

export const PAYCELL_PAYMENT_METHOD: PaymentMethodItem = {
  paymentMethodType: {
    configuration: {},
    icon: {
      iconUrl: '',
    },
    paymentMethodType: 'E_WALLET',
    starling_key: '',
  },
  paymentMethod: {
    configuration: {},
    paymentMethod: 'PAYCELL',
    paymentMethodId: 'pm_pi_ew_paycell_c_d',
    starling_key: '',
    icon: {
      iconUrl: '',
      pngIconUrl: '',
      darkModePngIconUrl: '',
      grayIconUrl: '',
      detailIconUrl: '',
    },
    displayName: 'Paycell',
    title: {
      payment: '',
      piListShort: '',
      piListLong: '',
    },
  },
}
