import { useCallback, useContext, useRef } from 'react'

import { PASSPORT_TICKET_REFRESH_TIME } from '@/constant'
import utils from '@/utils'

import { ParamsContext } from '..'

export const usePassportTicket = () => {
  const { urlQuery } = useContext(ParamsContext)
  const fetchPassportTicketRef = useRef<{ promise: Promise<string> | null; timestamp: number }>({ promise: null, timestamp: 0 })
  // 刷新passportTicket
  const refreshPassportTicket = useCallback(async () => {
    // 更新过期时间
    fetchPassportTicketRef.current.timestamp = Date.now()
    fetchPassportTicketRef.current.promise = utils.getPassprotTicket({ appId: urlQuery.appId, bizId: urlQuery.bizId }).catch((reason: any) => {
      // 请求失败，清除过期时间，下次调用时将会重新请求
      fetchPassportTicketRef.current.timestamp = 0
      throw reason
    })
  }, [urlQuery])

  const getPassportTicketAsync = useCallback(async () => {
    if (!fetchPassportTicketRef.current.promise || fetchPassportTicketRef.current.timestamp + PASSPORT_TICKET_REFRESH_TIME < Date.now()) {
      refreshPassportTicket()
    }
    const res = await fetchPassportTicketRef.current.promise
    return res
  }, [refreshPassportTicket])

  return getPassportTicketAsync
}
