import React, { ReactElement, forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { usePhoneValidator, useTouchedErrors } from '@/pipo/hooks'
import { PhoneNumber } from '@/pipo/pc/inner-components'
import { PaymentMethodFormData } from '@/pipo/utils'
import starling from '@/utils/starling'

import { PayoutComponentRef, PayoutSubmitParams } from '../../types'
import { OVODirectFormData, OVODirectFormProps } from './utils'

const OVOIdRegex = /^[0-9]{8,11}$/

export const OVODirectForm = forwardRef((props: OVODirectFormProps, ref: React.ForwardedRef<PayoutComponentRef>): ReactElement => {
  const { onChange } = props
  const {
    control,
    getValues,
    formState: { errors: formErrors, isValid, touchedFields },
  } = useForm<PaymentMethodFormData>({
    mode: 'all',
  })
  const errors = useTouchedErrors<OVODirectFormData>({
    touchedFields,
    errors: formErrors,
  })
  const { phoneValidate, errorMessage } = usePhoneValidator({
    phoneRegex: OVOIdRegex,
    options: {
      type: errors.wallet_id?.type || '',
      min: 8,
      max: 11,
    },
  })

  // 在表单状态和勾选绑卡时触发onChange
  useEffect(() => {
    onChange?.({
      isValid,
      storeCard: true,
    })
  }, [isValid, onChange])

  useImperativeHandle(ref, () => ({
    getSubmitData,
  }))

  const getSubmitData = (): PayoutSubmitParams => {
    const { wallet_id } = getValues()
    return {
      formData: {
        wallet_id: `08${wallet_id}`,
      },
    }
  }
  return (
    <div className="pipo-pc">
      <div className="pipo-pc-single-widget">
        <Controller
          rules={{
            required: true,
            validate: {
              phoneValidate,
            },
          }}
          name="wallet_id"
          control={control}
          render={({ field }) => (
            <PhoneNumber
              {...field}
              error={errors.wallet_id}
              phoneNumberStyle="full"
              maxLength={13}
              addBefore="08"
              title={starling('funds.refund.comm.ovoidtitle')}
              placeholder={starling('funds.refund.comm.ovoid')}
              errorMessage={errorMessage}
            />
          )}
        />
      </div>
    </div>
  )
})
