"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault")["default"];
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.prettyPrint = exports.level2color = exports.getUUID = exports.getPiItemKey = exports.formatContentToString = exports.SafeJSON = void 0;
var _forEach = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/for-each"));
var _keys = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/keys"));
var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));
var _objectSpread2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/objectSpread2"));
var _pickBy2 = _interopRequireDefault(require("lodash/pickBy"));
var _fastSafeStringify = _interopRequireDefault(require("fast-safe-stringify"));
var jsonStringifyReplacer = function jsonStringifyReplacer(_key, data) {
  // Remove the circular structure
  if (data === '[Circular]') {
    return;
  }
  // JsonStringify Error
  if (data instanceof Error) {
    return {
      errorName: data.name,
      errorMessage: data.message,
      errorStack: data.stack
    };
  }
  return data;
};
var SafeJSON = {
  stringify: function stringify(data) {
    try {
      return (0, _fastSafeStringify["default"])(data, jsonStringifyReplacer);
    } catch (error) {
      console.error('JSON stringify error', error);
      return '';
    }
  },
  /**
   * 解析JSON
   */
  parse: function parse(json) {
    try {
      return JSON.parse(json);
    } catch (e) {
      console.error('JSON parse error', e);
      return undefined;
    }
  }
};
exports.SafeJSON = SafeJSON;
var formatContentToString = function formatContentToString(keyValue) {
  var _context;
  var config = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
    keepNumber: true
  };
  var _keyValue = (0, _objectSpread2["default"])({}, keyValue);
  if (!config.keepNumber) {
    _keyValue = (0, _pickBy2["default"])(_keyValue, function (v) {
      return typeof v !== 'number';
    });
  }
  (0, _forEach["default"])(_context = (0, _keys["default"])(_keyValue)).call(_context, function (key) {
    if (typeof _keyValue[key] !== 'string') {
      _keyValue[key] = SafeJSON.stringify(_keyValue[key]);
    }
  });
  return _keyValue;
};
/**
 * 控制台高亮输出
 * @param info
 * @param args
 */
exports.formatContentToString = formatContentToString;
var prettyPrint = function prettyPrint(info) {
  var _console, _context2, _context3, _context4;
  var _info$level = info.level,
    level = _info$level === void 0 ? 'info' : _info$level,
    label = info.label,
    _info$bold = info.bold,
    bold = _info$bold === void 0 ? true : _info$bold,
    _info$color = info.color,
    color = _info$color === void 0 ? level2color(level) : _info$color,
    scope = info.scope;
  var colorStyle = color ? "color: ".concat(color, ";") : '';
  var boldStyle = bold ? 'font-weight: bold;' : '';
  for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key2 = 1; _key2 < _len; _key2++) {
    args[_key2 - 1] = arguments[_key2];
  }
  (_console = console).log.apply(_console, (0, _concat["default"])(_context2 = [(0, _concat["default"])(_context3 = "%c[UPAY Logger".concat(scope ? "(".concat(scope, ")") : '', "] ")).call(_context3, label), (0, _concat["default"])(_context4 = "".concat(colorStyle, " ")).call(_context4, boldStyle)]).call(_context2, args));
};
exports.prettyPrint = prettyPrint;
var level2color = function level2color(level) {
  switch (level) {
    case 'causeByNetwork':
      return '#f00';
    case 'causeByPIPORequest':
      return '#f00';
    case 'fatal':
      return '#f00';
    case 'error':
      return '#e33';
    case 'warn':
      return '#f4bd02';
    default:
      return '#0085f2';
  }
};
exports.level2color = level2color;
var getUUID = function getUUID(len) {
  var radix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 16;
  var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
  var value = [];
  var i = 0;
  var base = radix || chars.length;
  if (len) {
    // Compact form
    for (i = 0; i < len; i++) {
      value[i] = chars[0 | Math.random() * base];
    }
  } else {
    // rfc4122, version 4 form
    var r;
    // rfc4122 requires these characters
    /* eslint-disable-next-line */
    value[8] = value[13] = value[18] = value[23] = '-';
    value[14] = '4';
    // Fill in random data.  At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (i = 0; i < 36; i++) {
      if (!value[i]) {
        r = 0 | Math.random() * 16;
        value[i] = chars[i === 19 ? r & 0x3 | 0x8 : r];
      }
    }
  }
  return value.join('');
};
exports.getUUID = getUUID;
var getPiItemKey = function getPiItemKey(i) {
  var _context5, _context6;
  return (0, _concat["default"])(_context5 = (0, _concat["default"])(_context6 = "".concat(i.payWay, "_")).call(_context6, i.paymentsTitle)).call(_context5, i.cardId ? "_".concat(i.cardId) : '');
};
exports.getPiItemKey = getPiItemKey;