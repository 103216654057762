import { zip } from 'lodash'
import { chunk, pickNumeric } from './formatter'
import { cnpj } from 'cpf-cnpj-validator'

// 87239194000160888 => 87.239.194/0001-60
// 87239194000160    => 87.239.194/0001-60
// 872391940001      => 87.239.194/0001
// 8723919400        => 87.239.194/00
// 87239194          => 87.239.194
function formatter(value = '') {
  const separators = ['', '.', '.', '/', '-']
  const chunkSizes = [2, 3, 3, 4, 2]

  const numeric = pickNumeric(value)
  const pairs = zip(separators, chunk(numeric, chunkSizes))
  const result = (pairs as Array<[string, string]>)
    .filter(([_, split]) => Boolean(split))
    .map(([separator, split]) => separator + split)
    .join('')

  return result
}

const isValid = cnpj.isValid

const placeholder = '00.000.000/0000-00'

export { formatter, isValid, placeholder }
