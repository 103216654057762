import React from 'react'

import { CheckOne, CloseOne, LoadingFour, Round } from '@icon-park/react'
import { StatusTypeEnum } from '@upay/utils/es/types'

const StatusIcon: React.FC<{ status: StatusTypeEnum }> = ({ status }) => {
  const ICON_MAP = {
    [StatusTypeEnum.FAIL]: <CloseOne theme="filled" />,
    [StatusTypeEnum.FAILED]: <CloseOne theme="filled" />,
    [StatusTypeEnum.SUCCEED]: <CheckOne theme="filled" />,
    [StatusTypeEnum.SUCCESS]: <CheckOne theme="filled" />,
    [StatusTypeEnum.INIT]: <Round />,
    [StatusTypeEnum.PENDING]: <LoadingFour spin />,
    [StatusTypeEnum.TIMEOUT]: <LoadingFour spin />,
    [StatusTypeEnum.PROCESSING]: <LoadingFour spin />,
  }
  return ICON_MAP[status]
}
export default StatusIcon
