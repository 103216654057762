import { useContext, useMemo } from 'react'

import { getRiskFields } from '@/pipo/utils'

import { ParamsContext } from '..'

export const useRiskInfo = () => {
  const { urlQuery } = useContext(ParamsContext)
  const basicRiskInfo = useMemo(
    () => ({
      uid: '',
      aid: urlQuery.appId,
      app_id: urlQuery.appId,
      app_name: '',
      did: '0',
      device_platform: 'web',
      session_aid: urlQuery.appId,
      priority_region: urlQuery.countryCode || '',
      ...getRiskFields(),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )
  return basicRiskInfo
}
