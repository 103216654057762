import React, { FC } from 'react'

import { Popover } from '@arco-design/web-react'

import './style.less'

interface IPopoverErrorTip {
  // 在卡片中显示的错误信息
  message?: string
  // 鼠标悬浮显示的错误提示
  description?: string
  style?: React.CSSProperties
}

const PopoverErrorTip: FC<IPopoverErrorTip> = ({ message, description, style = {} }) => {
  const popoverStyle = { padding: '8px 16px', borderRadius: '4px', zIndex: 10050 }
  return message ? (
    <Popover
      content={description}
      title={null}
      triggerProps={{
        popupStyle: popoverStyle,
      }}
    >
      <div className="popover-error-tip" style={style}>
        {message}
      </div>
    </Popover>
  ) : (
    <></>
  )
}

export default PopoverErrorTip
