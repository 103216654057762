"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.EVENT_NAME_MAP = void 0;
var EVENT_NAME_MAP = {
  PAY_PAGE_VIEW: '3m_checkout_pay_page_view',
  PAY_PAGE_CLICK: '3m_checkout_pay_page_click',
  RESULT_PAGE_VIEW: '3m_checkout_result_page_view',
  RESULT_PAGE_CLICK: '3m_checkout_result_page_click',
  PAYMENT_CODE_PAGE_VIEW: '3m_checkout_pay_page_payment_code_view',
  PAYMENT_CODE_PAGE_CLICK: '3m_checkout_pay_page_payment_code_click',
  CREATE_PAGE_VIEW: '3m_checkout_pi_bind_page_view',
  CREATE_PAGE_CLICK: '3m_checkout_pi_bind_page_click',
  BIND_PAGE_FILL_IN: '3m_checkout_pi_bind_page_fill_in',
  PAY_PAGE_FILL_IN: '3m_checkout_pay_page_fill_in',
  PAYOUT_PAY_PAGE_FILL_IN: '3m_checkout_payout_pay_page_fill_in',
  MANAGER_PAGE_VIEW: '3m_checkout_pi_management_view',
  MANAGER_PAGE_CLICK: '3m_checkout_pi_management_click',
  UPDATE_PAGE_VIEW: '3m_checkout_pi_update_page_view',
  CHECKOUT_ERROR: '3m_checkout_error'
};
exports.EVENT_NAME_MAP = EVENT_NAME_MAP;