import { PaymentMethodItem } from '../../types'

export const YOOMONEY_PAYMENT_METHOD: PaymentMethodItem = {
  paymentMethodType: {
    configuration: {},
    icon: {
      iconUrl: '',
    },
    starling_key: 'pipo_common_ewallet',
    paymentMethodType: 'E_WALLET',
    title: {
      payment: 'pipo_common_ewallet',
    },
  },
  paymentMethod: {
    configuration: {
      png_icon_url: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/yoomoney_d96ceb.png',
      dark_mode_png_icon_url: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/yoomoney_161809.png',
    },
    paymentMethod: 'YOOMONEY',
    paymentMethodId: 'pm_pi_ew_yoomoney_c_r',
    displayName: 'YooMoney',
    starling_key: '',
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/yoomoney_d96ceb.png',
      grayIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/yoomoney_161809.png',
    },
  },
}
