import React, { useEffect } from 'react'

import { Select, SelectProps } from '@arco-design/web-react'
import { IconDown } from '@arco-design/web-react/icon'

import { callingCode } from '@/pipo/utils'
import { CallingCodeItem } from '@/pipo/utils/callingCode'

const formatter = ([countryOrRegionCode, { code, name }]: [string, CallingCodeItem]) => {
  return {
    label: `${countryOrRegionCode} +${code}`,
    value: code,
    // for search use
    extra: `${name} ${countryOrRegionCode} ${code}`,
  }
}

// TODO: refactor filter API
const filterer = ([countryOrRegionCode]: [string, CallingCodeItem], supportedCountryCodeList?: string[]) => {
  return supportedCountryCodeList?.includes(countryOrRegionCode)
}

interface IProps extends SelectProps {
  supportedCountryCodeList?: string[]
  disabled?: boolean
  onChange: (e: string) => void
}

function CallingCodeSelect(props: IProps, ref: any) {
  const { supportedCountryCodeList = ['BR'], disabled = false, value, onChange } = props
  const { list } = callingCode
  const optionsList = Object.entries(list)
    .filter((item) => filterer(item, supportedCountryCodeList))
    .map(formatter)

  useEffect(() => {
    // 默认选中第一个
    if (!value && optionsList?.[0]?.value) {
      onChange(optionsList?.[0]?.value)
    }
  }, [value, optionsList])

  return (
    <Select
      style={{ width: 108 }}
      ref={ref}
      showSearch
      filterOption={(inputValue, option: { props: { extra: string } }) => {
        return option.props.extra.toLowerCase().includes(inputValue.toLowerCase())
      }}
      disabled={disabled}
      value={value}
      onChange={onChange}
      options={optionsList}
      arrowIcon={<IconDown />}
    />
  )
}

export default React.forwardRef(CallingCodeSelect)
