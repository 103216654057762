import { useContext, useRef, useState } from 'react'

import { useImmer, useInterval, useRequest } from '@byted/hooks'
import { StatusActionTypeEnum, StatusTypeEnum } from '@upay/utils/es/types'

import { IOpenStatusProps, PullStatusEnum } from '@/components/Status/config'
import { COMMON_PROMPT, NEW_SDK_TERMINAL_EQUIP, PUBSUB_TYPES } from '@/constant'
import api from '@/services'
import { ParamsContext } from '@/stores'
import GlobalInfo from '@/stores/GlobalInfo'
import SafeJSON from '@/utils/SafeJSON'
import { getLang } from '@/utils/getLang'
import slardar from '@/utils/slardar'

interface ISimpleStatus {
  status: StatusTypeEnum
  msg: string
}
export interface ICombinationStatusProps {
  visible: boolean
  payStatus: ISimpleStatus
  bindStatus: ISimpleStatus
  onOk: () => void
  onRetry: () => void
  onCancel: () => void
  timeCount: number
}

const useCombinationStatus = () => {
  const {
    urlQuery: { token, type },
    uPubSub,
  } = useContext(ParamsContext)
  const {
    data: { lastBindCardId, riskInfoObj },
  } = useContext(GlobalInfo)
  const paramsRef = useRef<IOpenStatusProps>()
  const [visible, setVisible] = useState<boolean>(false)
  const [bindStatus, updateBindStatus] = useImmer<ISimpleStatus>({
    status: StatusTypeEnum.PROCESSING,
    msg: '',
  })
  const [payStatus, updatePayStatus] = useImmer<ISimpleStatus>({
    status: StatusTypeEnum.INIT,
    msg: '',
  })
  const [time, setTime] = useState(3)
  // 支付成功后倒计时三秒自动关闭
  const { run: startCountdown, cancel } = useInterval(
    () => {
      setTime((prev) => {
        if (prev > 0) {
          return prev - 1
        } else {
          cancel()
          onOk()
          return 0
        }
      })
    },

    1000 * 1,
    false,
  )
  // 轮询绑卡结果
  const { run: pollingBindResult, cancel: stopPolling } = useRequest(
    () => api.getBindResult({ token, language: getLang(), cardId: lastBindCardId.current }),
    {
      pollingInterval: 1000 * 1,

      onSuccess: (res) => {
        const { data } = res || {}
        const { errorMsg = COMMON_PROMPT().SYS_ERR, status } = data || {}
        if (status === PullStatusEnum.SUCCESS) {
          handleBindResult(StatusTypeEnum.SUCCESS)
        } else if (status === PullStatusEnum.FAIL) {
          handleBindResult(StatusTypeEnum.FAIL, errorMsg)
        }
      },
      onError: (e: any) => {
        handleBindResult(StatusTypeEnum.FAIL, e?.msg || e?.message || e)
      },
    },
  )
  const { run: pollPaymentResult, cancel: stopPollPaymentResult } = useRequest(
    (recordNo: string) => api.getPayResult({ token, language: getLang(), recordNo }),
    {
      pollingInterval: 1000 * 1,

      onSuccess: (res) => {
        const { data } = res || {}
        const { errorMsg = COMMON_PROMPT().SYS_ERR, status } = data || {}
        if (status === PullStatusEnum.SUCCESS) {
          handlePayResult(StatusTypeEnum.SUCCESS)
        } else if (status === PullStatusEnum.FAIL) {
          handlePayResult(StatusTypeEnum.FAIL, errorMsg)
        }
      },
      onError: (e: any) => {
        handlePayResult(StatusTypeEnum.FAIL, e?.msg || e?.message || e)
      },
    },
  )
  //绑卡成功后提交支付请求
  const { run: submitOrder } = useRequest(api.submitOrder, {
    onSuccess: () => {
      if (!paramsRef.current?.recordNo) return
      pollPaymentResult(paramsRef.current.recordNo)
    },
    onError: (e: any) => {
      handlePayResult(StatusTypeEnum.FAIL, e?.msg || e?.message || e)
    },
  })

  //处理绑卡轮询结果方法
  const handleBindResult = (status: StatusTypeEnum, msg?: string) => {
    stopPolling()
    if (!paramsRef.current) return
    const { recordNo, payWay, handleBindSuccess } = paramsRef.current
    if (status === StatusTypeEnum.SUCCESS) {
      updateBindStatus((prev) => {
        prev.status = StatusTypeEnum.SUCCESS
      })
      updatePayStatus((prev) => {
        prev.status = StatusTypeEnum.PROCESSING
      })
      handleBindSuccess?.()
      submitOrder({
        token,
        recordNo,
        riskInfo: SafeJSON.stringify(riskInfoObj),
        terminalEquip: NEW_SDK_TERMINAL_EQUIP,
        supportAgreementPaymentAndBind: true,
        saveActionByBackend: true,
        payWay,
        returnUrl: '',
        cardId: lastBindCardId.current,
      })
    }
    if (status === StatusTypeEnum.FAIL) {
      updateBindStatus((prev) => {
        prev.status = StatusTypeEnum.FAIL
        prev.msg = msg!
      })
    }
    // 发送结果页埋点
    slardar.timer(`resultEvent`, 1, { status, actionType: StatusActionTypeEnum.BIND, errMsg: msg || '', payWay, type })
  }
  //处理支付结果
  const handlePayResult = (status: StatusTypeEnum, msg?: string) => {
    stopPollPaymentResult()
    if (!paramsRef.current) return
    const { payWay } = paramsRef.current
    if (status === StatusTypeEnum.SUCCESS) {
      updatePayStatus((prev) => {
        prev.status = StatusTypeEnum.SUCCESS
      })
      startCountdown()
    }

    if (status === StatusTypeEnum.FAIL) {
      updatePayStatus((prev) => {
        prev.status = StatusTypeEnum.FAIL
        prev.msg = msg!
      })
    }
    // 通知业务方结果
    uPubSub.publish(PUBSUB_TYPES.STATUS_RESULT, {
      status: status === StatusTypeEnum.SUCCESS ? true : false,
      clickBtn: false,
      actionType: StatusActionTypeEnum.PAY,
      msg: msg || '',
    })
    // 发送结果页埋点
    slardar.timer(`resultEvent`, 1, { status, actionType: StatusActionTypeEnum.BIND, errMsg: msg || '', payWay, type })
  }
  //结果页面按钮点击 callback
  const onOk = () => {
    uPubSub.publish(PUBSUB_TYPES.STATUS_RESULT, {
      status: true,
      clickBtn: true,
      actionType: StatusActionTypeEnum.PAY,
      msg: '',
      retry: false,
    })
    clearStatus()
    paramsRef.current?.onClose?.({ status: StatusTypeEnum.SUCCESS, actionType: StatusActionTypeEnum.PAY, retry: false })
  }
  const onCancel = () => {
    uPubSub.publish(PUBSUB_TYPES.STATUS_RESULT, {
      status: false,
      clickBtn: true,
      actionType: StatusActionTypeEnum.PAY,
      msg: bindStatus.msg || payStatus.msg || COMMON_PROMPT().SYS_ERR,
      retry: false,
    })
    clearStatus()
    paramsRef.current?.onClose?.({
      status: StatusTypeEnum.FAIL,
      actionType: StatusActionTypeEnum.PAY,
      retry: false,
    })
  }
  const onRetry = () => {
    uPubSub.publish(PUBSUB_TYPES.STATUS_RESULT, {
      status: false,
      clickBtn: true,
      actionType: StatusActionTypeEnum.PAY,
      msg: bindStatus.msg || payStatus.msg || COMMON_PROMPT().SYS_ERR,
      retry: true,
    })
    clearStatus()
    paramsRef.current?.onClose?.({ status: StatusTypeEnum.FAIL, actionType: StatusActionTypeEnum.PAY, retry: true })
  }
  // 离开结果页面后回复到初始状态
  const clearStatus = () => {
    setVisible(false)
    updateBindStatus({
      status: StatusTypeEnum.PROCESSING,
      msg: '',
    })
    updatePayStatus({
      status: StatusTypeEnum.INIT,
      msg: '',
    })
    setTime(3)
  }
  // 提供给 UI 组件入参
  const combinationStatusProps: ICombinationStatusProps = {
    visible,
    payStatus,
    bindStatus,
    onOk,
    onRetry,
    onCancel,
    timeCount: time,
  }
  // 打开结果页面方法
  const openCombinationStatus = (params: IOpenStatusProps) => {
    paramsRef.current = params
    setVisible(true)
    pollingBindResult()
  }
  return {
    combinationStatusProps,
    openCombinationStatus,
  }
}
export default useCombinationStatus
