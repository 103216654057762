"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault")["default"];
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.getRiskFields = void 0;
var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));
var _uuid = require("uuid");
var getRiskFields = function getRiskFields() {
  return {
    user_agent: navigator.userAgent,
    referer: document.referrer,
    cookie_enabled: navigator.cookieEnabled,
    screen_width: window.screen.width,
    screen_height: window.screen.height,
    browser_language: navigator.language,
    browser_platform: navigator.platform,
    browser_name: navigator.appCodeName,
    browser_version: navigator.appVersion,
    browser_online: navigator.onLine,
    timezone_name: new Intl.DateTimeFormat().resolvedOptions().timeZone,
    device_fingerprint_id: initCyberAndGetSessionId()
  };
};
exports.getRiskFields = getRiskFields;
var initCyberAndGetSessionId = function initCyberAndGetSessionId() {
  var PREFIX = 'bytedance';
  var ORG_ID = 'k8vif92e';
  var SCRIPT_ID = 'upay_web_sdk_tags_js';
  var SESSION_ID = (0, _uuid.v4)();
  if (typeof window !== 'undefined') {
    var sessionScript = window.document.getElementById(SCRIPT_ID);
    if (!sessionScript) {
      var _context;
      var script = window.document.createElement('script');
      var head = window.document.getElementsByTagName('head')[0];
      script.setAttribute('data-session-id', SESSION_ID);
      script.id = SCRIPT_ID;
      script.type = 'text/javascript';
      script.src = (0, _concat["default"])(_context = "https://h.online-metrix.net/fp/tags.js?org_id=".concat(ORG_ID, "&session_id=").concat(PREFIX)).call(_context, SESSION_ID);
      head.appendChild(script);
    } else {
      SESSION_ID = sessionScript.getAttribute('data-session-id');
    }
  }
  return SESSION_ID;
};