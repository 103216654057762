import { PaymentDetail, PaymentMethodInfo, SUPPORT_PAYMENT_METHODS_ID_MAP, SupportPaymentMethodId } from '@/pipo/utils'

interface Props {
  formatCode: string
  amount: string
  currency?: string
  shopperDetails: NonNullable<PaymentDetail>['present_to_shopper_details']
  paymentMethodId: SupportPaymentMethodId
}

export interface VoucherBoardData {
  extraInfoUrl?: string
  type: 'simple' | 'complicated'
  paymentMethodInfo: PaymentMethodInfo
  simple: {
    formatCode: string
    amount: string
  }
  complicated: {
    formatCustomerNo?: string
    formatPaymentCode?: string
    formatOrderFee?: string
    formatTransactionFee?: string
    formatTotalFee?: string
  }
  shopperDetails: NonNullable<PaymentDetail>['present_to_shopper_details']
  hint?: string
  instructionUrl?: string
  config: {}
}

export const useUIVoucher = (props: Props): VoucherBoardData => {
  const { formatCode, amount, shopperDetails, paymentMethodId } = props

  const paymentMethod = SUPPORT_PAYMENT_METHODS_ID_MAP[paymentMethodId].paymentMethod.paymentMethod
  const paymentMethodType = SUPPORT_PAYMENT_METHODS_ID_MAP[paymentMethodId].paymentMethodType.paymentMethodType
  // 可在此处对数据进行处理，暂时移除了无需用到的支付码展示

  return {
    type: 'simple',
    paymentMethodInfo: {
      paymentMethodId,
      paymentMethod,
      paymentMethodType,
      bankId: shopperDetails.bank_code,
    },
    simple: {
      formatCode,
      amount,
    },
    shopperDetails,
    complicated: {},
    config: {},
  }
}
