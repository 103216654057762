"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault")["default"];
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.getPayScenes = getPayScenes;
var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));
var _map = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/map"));
var _filter = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/filter"));
var _includes = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/includes"));
var _objectSpread2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/objectSpread2"));
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/toConsumableArray"));
var _utils = require("../utils");
function getPayScenes() {
  var _this = this;
  return {
    payPageView: function payPageView() {
      return _this.info('payPageView');
    },
    payPMListFetched: function payPMListFetched(_ref) {
      var piPaymentListRes = _ref.piPaymentListRes,
        originPiPaymentListRes = _ref.originPiPaymentListRes,
        _ref$logid = _ref.logid,
        logid = _ref$logid === void 0 ? '' : _ref$logid;
      try {
        var _context, _context2, _context3, _context4;
        var _ref2 = piPaymentListRes || {},
          _ref2$unfoldedPIList = _ref2.unfoldedPIList,
          unfoldedPIList = _ref2$unfoldedPIList === void 0 ? [] : _ref2$unfoldedPIList,
          _ref2$foldedPIList = _ref2.foldedPIList,
          foldedPIList = _ref2$foldedPIList === void 0 ? [] : _ref2$foldedPIList;
        var _ref3 = originPiPaymentListRes || {},
          _ref3$unfoldedPIList = _ref3.unfoldedPIList,
          oUnfoldedPIList = _ref3$unfoldedPIList === void 0 ? [] : _ref3$unfoldedPIList,
          _ref3$foldedPIList = _ref3.foldedPIList,
          oFoldedPIList = _ref3$foldedPIList === void 0 ? [] : _ref3$foldedPIList;
        var totalList = (0, _concat["default"])(_context = []).call(_context, (0, _toConsumableArray2["default"])(unfoldedPIList), (0, _toConsumableArray2["default"])(foldedPIList));
        var unfoldPayWays = (0, _map["default"])(unfoldedPIList).call(unfoldedPIList, _utils.getPiItemKey);
        var safedPayWays = (0, _map["default"])(_context2 = (0, _filter["default"])(unfoldedPIList).call(unfoldedPIList, function (i) {
          return i.cardId;
        })).call(_context2, _utils.getPiItemKey);
        var foldPayWays = (0, _map["default"])(foldedPIList).call(foldedPIList, _utils.getPiItemKey);
        var disablePayWays = (0, _map["default"])(_context3 = (0, _filter["default"])(totalList).call(totalList, function (i) {
          return i.disable;
        })).call(_context3, function (i) {
          return {
            payWay: (0, _utils.getPiItemKey)(i),
            disableTips: i.disableTips
          };
        });
        var originTotalList = (0, _concat["default"])(_context4 = []).call(_context4, (0, _toConsumableArray2["default"])(oUnfoldedPIList), (0, _toConsumableArray2["default"])(oFoldedPIList));
        var originPayways = (0, _map["default"])(originTotalList).call(originTotalList, _utils.getPiItemKey);
        var content = {
          totalLength: totalList.length,
          unfoldLength: unfoldedPIList.length,
          foldLength: foldedPIList.length,
          disableLength: disablePayWays.length,
          useableLength: totalList.length - disablePayWays.length,
          safedLength: safedPayWays.length,
          newLength: totalList.length - safedPayWays.length,
          originLength: originPayways.length,
          unfoldPayWays: unfoldPayWays,
          foldPayWays: foldPayWays,
          disablePayWays: disablePayWays,
          originPayways: originPayways,
          logid: logid
        };
        _this.info('payPMListFetched', content);
      } catch (error) {
        _this.error('payPMListFetched log send error', error);
      }
    },
    payPreOrderFetched: function payPreOrderFetched(_ref4) {
      var preOrderRes = _ref4.preOrderRes,
        _ref4$logid = _ref4.logid,
        logid = _ref4$logid === void 0 ? '' : _ref4$logid;
      _this.info('payPreOrderFetched', (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, preOrderRes || {}), {}, {
        logid: logid
      }));
    },
    payBillInfoFetched: function payBillInfoFetched(_ref5) {
      var billInfoRes = _ref5.billInfoRes,
        _ref5$logid = _ref5.logid,
        logid = _ref5$logid === void 0 ? '' : _ref5$logid;
      _this.info('payBillInfoFetched', (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, billInfoRes || {}), {}, {
        logid: logid
      }));
    },
    payPiSelected: function payPiSelected(_ref6) {
      var selectedPiItem = _ref6.selectedPiItem,
        autoSelect = _ref6.autoSelect;
      try {
        _this.info('payPiSelected', {
          payWay: String(selectedPiItem.payWay),
          savedCard: !!selectedPiItem.cardId,
          piItemKey: (0, _utils.getPiItemKey)(selectedPiItem),
          autoSelect: autoSelect
        });
      } catch (error) {
        _this.error('payPiSelected log send error', error);
      }
    },
    paySubmitBtnClick: function paySubmitBtnClick(_ref7) {
      var selectedPiItem = _ref7.selectedPiItem,
        isBindSubmit = _ref7.isBindSubmit;
      try {
        _this.info('paySubmitBtnClick', {
          payWay: String(selectedPiItem.payWay),
          bindPayWay: String(selectedPiItem.bindPayWay),
          savedCard: !!selectedPiItem.cardId,
          piItemKey: (0, _utils.getPiItemKey)(selectedPiItem),
          isBindSubmit: isBindSubmit
        });
      } catch (error) {
        _this.error('paySubmitBtnClick log send error', error);
      }
    },
    /** 支付页的提交，也包括绑卡场景 */
    payPageSubmitResultFetched: function payPageSubmitResultFetched(_ref8) {
      var selectedPiItem = _ref8.selectedPiItem,
        isBindSubmit = _ref8.isBindSubmit,
        submitRes = _ref8.submitRes,
        logid = _ref8.logid;
      try {
        var _context5;
        _this.info('payPageSubmitResultFetched', {
          payWay: String(selectedPiItem.payWay),
          bindPayWay: String(selectedPiItem.bindPayWay),
          savedCard: !!selectedPiItem.cardId,
          piItemKey: (0, _utils.getPiItemKey)(selectedPiItem),
          isBindSubmit: isBindSubmit,
          needRedirect: !!submitRes.url,
          needShowPaymentcode: !!submitRes.url && (0, _includes["default"])(_context5 = submitRes.url).call(_context5, 'paymentcode'),
          needMore: submitRes.needMore,
          needOtpCode: submitRes.needOtpCode,
          logid: logid
        });
      } catch (error) {
        _this.error('paySubmitResultFetched log send error', error);
      }
    },
    /** 支付页提交失败上报，也包括绑卡场景 */
    payPageSubmitError: function payPageSubmitError(e, _ref9) {
      var selectedPiItem = _ref9.selectedPiItem,
        isBindSubmit = _ref9.isBindSubmit;
      try {
        _this.fatal("payPageSubmitError ".concat(isBindSubmit ? 'bind' : 'pay'), e, {
          scene: 'payPageSubmitResultFetched',
          payWay: String(selectedPiItem.payWay),
          bindPayWay: String(selectedPiItem.bindPayWay),
          savedCard: !!selectedPiItem.cardId,
          piItemKey: (0, _utils.getPiItemKey)(selectedPiItem),
          isBindSubmit: isBindSubmit
        });
      } catch (error) {
        _this.error('payPageSubmitError log send error', error);
      }
    },
    /** 支付页点击 */
    payPageClick: function payPageClick(_ref10) {
      var clickItem = _ref10.clickItem;
      _this.info("payPageClick_".concat(clickItem));
    },
    payBackShowFeelgood: function payBackShowFeelgood(params) {
      _this.info("payBackShowFeelgood", params);
    }
  };
}