"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.BANKTRANSFER_JP_PAYMENT_PARAMS_MAP = void 0;
var BANKTRANSFER_JP_PAYMENT_PARAMS_MAP = {
  email: {
    element: 'eg_pi_bt_banktransfer_c_d_local_jp_email',
    is_encrypted: true
  },
  holder_first_name: {
    element: 'eg_pi_bt_banktransfer_c_d_local_jp_holder_first_name',
    is_encrypted: true
  },
  holder_first_name_katakana: {
    element: 'eg_pi_bt_banktransfer_c_d_local_jp_holder_first_name_katakana',
    is_encrypted: true
  },
  holder_last_name: {
    element: 'eg_pi_bt_banktransfer_c_d_local_jp_holder_last_name',
    is_encrypted: true
  },
  holder_last_name_katakana: {
    element: 'eg_pi_bt_banktransfer_c_d_local_jp_holder_last_name_katakana',
    is_encrypted: true
  },
  phone: {
    element: 'eg_pi_bt_banktransfer_c_d_local_jp_phone',
    is_encrypted: true
  },
  phone_country_code: {
    element: 'eg_pi_bt_banktransfer_c_d_local_jp_phone_country_code',
    is_encrypted: true
  }
};
exports.BANKTRANSFER_JP_PAYMENT_PARAMS_MAP = BANKTRANSFER_JP_PAYMENT_PARAMS_MAP;