import Feelgood from '@ad/feelgood-sdk'

import { IS_DEV_OR_BOE } from '@/constant'

import { getLang } from '../getLang'

export const initFeelGood = async (accountId: string, bizId: string, countryCode: string, isBoe: boolean): Promise<void> => {
  Feelgood.init({
    appKey: 'd4a3cb410a9f215cf6925847fb72300cdc46a82e',
    channel: 'sg',
    userInfo: {
      user_id: accountId,
      biz_id: bizId,
      country: countryCode,
    },
    platform: 'desktop',
    language: getLang(),
    debug: IS_DEV_OR_BOE || isBoe,
    maskClosable: false,
  })
  Feelgood.start()
}
