"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.CASH_PLUS_PAYMENT_METHOD = void 0;
var CASH_PLUS_PAYMENT_METHOD = {
  paymentMethodType: {
    configuration: {},
    icon: {
      iconUrl: ''
    },
    starling_key: 'CASH_PIN',
    paymentMethodType: 'CASH_PIN'
  },
  paymentMethod: {
    configuration: {},
    paymentMethod: 'CASH_PLUS',
    paymentMethodId: 'pm_pi_cp_cashpincashplus_c_d',
    displayName: 'Cash Plus',
    starling_key: '',
    icon: {
      iconUrl: '',
      voucherIconUrl: '',
      pngIconUrl: '',
      darkModePngIconUrl: '',
      detailIconUrl: ''
    }
  }
};
exports.CASH_PLUS_PAYMENT_METHOD = CASH_PLUS_PAYMENT_METHOD;