import { IFormBuilderItemProps } from '@/components/FormBuilder'
import { useAddressPC } from '@/pipo/hooks'
import { IUsePostalCodeResult } from '@/pipo/hooks/use-postal-code'

import { getARFormConfig } from './ar'
import { getCLFormConfig } from './cl'
import { getCOFormConfig } from './co'
import { getECFormConfig } from './ec'
import { getPEFormConfig } from './pe'

export interface ICCDCConfigItem {
  needInfo?: 'contract_address_list'[]
  formSchema: IFormBuilderItemProps[]
}

export interface IGetCCDCFormConfigProps {
  countryCode: string
  postalCodeProps: IUsePostalCodeResult
  billingAddressProps: ReturnType<typeof useAddressPC>
}

export const getCCDCFormConfig = (props: IGetCCDCFormConfigProps): ICCDCConfigItem | null => {
  if (props.countryCode === 'CL') return getCLFormConfig(props)
  if (props.countryCode === 'CO') return getCOFormConfig(props)
  if (props.countryCode === 'AR') return getARFormConfig(props)
  if (props.countryCode === 'EC') return getECFormConfig(props)
  if (props.countryCode === 'PE') return getPEFormConfig(props)

  return null
}
