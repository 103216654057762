/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react'

import { Select } from '@arco-design/web-react'
import { IconDown } from '@arco-design/web-react/icon'

import { PipoProvider } from '@/pipo/utils'
import starling from '@/utils/starling'

import { ItemBlock } from '../index'

interface IProps {
  title?: string
  value?: string
  onChange: (e: string) => void
}

export const IdTypeSelect = React.forwardRef((props: IProps, ref: any) => {
  const { title, value, onChange } = props
  const { t } = PipoProvider.useContext()

  // hard-coded option list
  const optionList = [
    {
      label: starling('funds.refund.comm.system_bankaccount_idtype_description'),
      value: 'tin',
    },
  ]

  const handleChange = (e: string) => {
    onChange(e)
  }

  return (
    <ItemBlock style="half" title={title}>
      <Select
        size="large"
        ref={ref}
        showSearch
        value={value}
        placeholder={starling('funds.refund.comm.system_bankaccount_account_number_placeholder_placeholder')}
        onChange={(e: string) => handleChange(e)}
        arrowIcon={<IconDown />}
      >
        {optionList.map((option) => (
          <Select.Option key={option.value} value={option.value}>
            <span>{option.label}</span>
          </Select.Option>
        ))}
      </Select>
    </ItemBlock>
  )
})
