import React, { useMemo } from 'react'

import starling from '@/utils/starling'

import { ItemText } from '../../inner-components'

interface IProps {
  clsPrefix: string
  mandateInfo: any[]
}
export const PaymentAndPayee = (props: IProps) => {
  const { clsPrefix, mandateInfo } = props
  const mandateInfoMap = useMemo(() => {
    return mandateInfo?.reduce((ret, cur) => {
      const { attribute_object, mandate_element_param_name } = cur
      ret[mandate_element_param_name] = {
        ...attribute_object,
        value: attribute_object[attribute_object.source],
      }
      return ret
    }, {})
  }, [mandateInfo])

  return (
    <React.Fragment>
      {/** Payment details */}
      <div style={{ width: '100%', color: '#666666' }}>
        <div
          className={`${clsPrefix}-subtitle`}
          style={{
            marginTop: '20px',
          }}
        >
          {starling('funds.check_out.comm.payment_details')}
        </div>
        <div className={`${clsPrefix}-subform-wrapper`}>
          <ItemText title="Payment amount" value={mandateInfoMap.payment_amount_type?.value} style="half" />
          <ItemText title="Payment frequency" value={mandateInfoMap.payment_frequency_type?.value} style="half" />
        </div>
      </div>

      {/** Payee information */}
      <div style={{ width: '100%', color: '#666666' }}>
        <div
          className={`${clsPrefix}-subtitle`}
          style={{
            marginTop: '20px',
          }}
        >
          {starling('funds.check_out.comm.payee_info')}
        </div>
        <div className={`${clsPrefix}-subform-wrapper`}>
          <ItemText title={starling('funds.check_out.comm.company_name')} value={mandateInfoMap.pipo_company_name?.value} style="half" />
          <ItemText title={starling('funds.check_out.comm.company_number')} value={mandateInfoMap.pipo_account_no?.value} style="half" />
          <ItemText title={starling('funds.check_out.comm.company_address')} value={mandateInfoMap.pipo_company_address?.value} style="half" />
          <ItemText title={starling('funds.check_out.comm.company_email')} value={mandateInfoMap.pipo_company_email?.value} style="half" />
        </div>
      </div>
    </React.Fragment>
  )
}
