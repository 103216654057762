"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault")["default"];
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.validateStatus = exports.noEmptyProperty = exports.generateCommonPaymentParams = exports.encryptFormData = exports.boldText = void 0;
var _some = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/some"));
var _values = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/values"));
var _reduce = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/reduce"));
var _entries = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/entries"));
var _slice = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/slice"));
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/slicedToArray"));
var _typeof2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/typeof"));
var _isNil2 = _interopRequireDefault(require("lodash/isNil"));
var _publicEncrypt = require("public-encrypt");
/**
 * Verify the obj that all attribute values are empty
 *
 * validateEmptyObject({ a: 1, b: null })
 * // => true
 *
 * validateEmptyObject({ a: 0, b: 0 })
 * // => true
 *
 * validateEmptyObject({ a: undefined, b: null })
 * // => false
 *
 * validateEmptyObject({ a: '', b: '' })
 * // => false
 *
 * @param obj
 */
var noEmptyProperty = function noEmptyProperty(obj) {
  var _context;
  if (!obj) return false;
  // @ts-ignore
  return (0, _some["default"])(_context = (0, _values["default"])(obj)).call(_context, function (val) {
    return !(0, _isNil2["default"])(val) && val !== '';
  });
};
exports.noEmptyProperty = noEmptyProperty;
var encryptedString = function encryptedString(publicKey, plaintext) {
  if (!plaintext) return plaintext;
  var encrypted = (0, _publicEncrypt.publicEncrypt)(publicKey, Buffer.from(plaintext));
  return encrypted.toString('base64');
};
var encryptFormData = function encryptFormData(formData, publicKey) {
  if ((0, _typeof2["default"])(formData) !== 'object') return {};
  var out = {};
  for (var field in formData) {
    out[field] = encryptedString(publicKey, formData[field]);
  }
  return out;
};
exports.encryptFormData = encryptFormData;
var generateCommonPaymentParams = function generateCommonPaymentParams(data, PAYMENT_PARAMS_MAP, publicKey) {
  var _context2;
  var paymentParams = (0, _reduce["default"])(_context2 = (0, _entries["default"])(data)).call(_context2, function (arr, _ref) {
    var _ref2 = (0, _slicedToArray2["default"])(_ref, 2),
      key = _ref2[0],
      value = _ref2[1];
    if (!PAYMENT_PARAMS_MAP[key]) return arr;
    var paymentParam = typeof PAYMENT_PARAMS_MAP[key] === 'string' ? {
      element: PAYMENT_PARAMS_MAP[key]
    } : PAYMENT_PARAMS_MAP[key];
    var obj = {
      element: paymentParam.element,
      param_name: key,
      param_value: !!(paymentParam !== null && paymentParam !== void 0 && paymentParam.is_encrypted) && publicKey ? encryptedString(publicKey || '', value) : value,
      is_encrypted: !!(paymentParam !== null && paymentParam !== void 0 && paymentParam.is_encrypted)
    };
    arr.push(obj);
    return arr;
  }, []);
  return paymentParams;
};
exports.generateCommonPaymentParams = generateCommonPaymentParams;
var validateStatus = function validateStatus(params) {
  var items = params.items,
    errors = params.errors,
    getValues = params.getValues;
  return (0, _reduce["default"])(items).call(items, function (status, item) {
    // @ts-ignore
    var itemStatus = Boolean(getValues(item) && !errors[item]);
    return status && itemStatus;
  }, true);
};
exports.validateStatus = validateStatus;
var boldText = function boldText(str) {
  return str === null || str === void 0 ? void 0 : str.replace(/\{.*?\}/g, function (match) {
    return "<b>".concat((0, _slice["default"])(match).call(match, 1, match.length - 1), "</b>");
  });
};
exports.boldText = boldText;