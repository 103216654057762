import React, { useMemo } from 'react'
import { Control, FieldErrors } from 'react-hook-form'

import { Spin } from '@arco-design/web-react'

import { useAddressPC } from '@/pipo/hooks'
import { IUsePostalCodeResult } from '@/pipo/hooks/use-postal-code'
import starling from '@/utils/starling'

import { BillingAddress } from '../../inner-components'
import { DirectDebitFormData } from './utils'

interface IProps {
  clsPrefix: string
  control: Control<DirectDebitFormData, any>
  errors: FieldErrors<DirectDebitFormData>
  postalCodeProps: IUsePostalCodeResult
  billingAddressProps: ReturnType<typeof useAddressPC>
  handleBlur: (...args: any[]) => void
}
export const PersonalAccount = (props: IProps) => {
  const { control, errors, clsPrefix, postalCodeProps, billingAddressProps: originAddressProps, handleBlur } = props

  // JPM Country 只有 US 一个选项
  const list = useMemo(() => {
    originAddressProps.list[0].list = originAddressProps.list[0].list.filter((item) => item.code === 'US')
    return originAddressProps.list
  }, [originAddressProps.list])
  const billingAddressProps = { ...originAddressProps, list }

  return (
    <React.Fragment>
      <div
        className={`${clsPrefix}-subtitle`}
        style={{
          marginTop: '20px',
        }}
      >
        {starling('funds.refund.comm.system_Billing_address')}
        {!billingAddressProps.contractAddressList && <Spin />}
      </div>
      <BillingAddress
        control={control}
        errors={errors}
        postalCodeProps={postalCodeProps}
        {...billingAddressProps}
        nameOptions={{ street: 'addressline1' }}
        handleBlur={handleBlur}
      />
    </React.Fragment>
  )
}
