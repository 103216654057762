import React, { forwardRef, useImperativeHandle } from 'react'

import SafeJSON from '@/utils/SafeJSON'
import slardar from '@/utils/slardar'
import { VENMO_PROFILEID } from '@/utils/venmo'

import { IPaymentMethodsProps, IPaymentsMethodRef } from '../../interface'

const Venmo = forwardRef<IPaymentsMethodRef, IPaymentMethodsProps>((props, ref): React.ReactElement => {
  useImperativeHandle(ref, () => ({
    processSubmitData: async (submitParams) => {
      try {
        // @ts-ignore
        const payload = await window.pipoVenmoInstance?.tokenize()
        submitParams.channelParameter = SafeJSON.stringify({
          clientMetadataId: VENMO_PROFILEID,
          accessToken: payload?.nonce,
        })
        const riskInfo = SafeJSON.parse(submitParams.riskInfo)
        //@ts-ignore
        const extraRiskInfo = SafeJSON.parse(window.pipoVenmoDataCollectorInstance?.deviceData)
        submitParams.riskInfo = SafeJSON.stringify({
          ...riskInfo,
          ...extraRiskInfo,
        })
        return submitParams
      } catch (e: any) {
        console.log('venmo fail', e?.message)
        slardar.timer('venmoVertifyFaild', { message: e?.message })
        return Promise.reject(e?.message)
      }
    },
  }))

  return <></>
})

export default Venmo
