import React from 'react'
import ReactHtmlParser from 'react-html-parser'

import { starlingClient } from '@upay/utils'

const starling = starlingClient.t

export default starling

// 约定starling中 **加粗**
export const upayBoldText = (str: string) => {
  return str?.replace(/\*\*.*?\*\*/g, (match) => {
    return `<b>${match.slice(2, match.length - 2)}</b>`
  })
}

export const formatter = (str: string) => {
  if (!str) return null
  const formatText = upayBoldText(str)
  // 在此处进行更多文本处理

  return (
    <div className="formatted-text" key={str}>
      {ReactHtmlParser(formatText)}
    </div>
  )
}
