import React, { FC, useContext, useEffect, useState } from 'react'

import { Skeleton } from '@arco-design/web-react'
import { IconPlus, IconSwap } from '@arco-design/web-react/icon'
import { useRequest } from '@byted/hooks'

import { oneTimeHoc } from '@/HOC/oneTimeHoc'
import { FROM_PAGE_ENUM, PUBSUB_TYPES } from '@/constant'
import api from '@/services'
import { ParamsContext } from '@/stores'
import starling from '@/utils/starling'

import Create from '../Payout/Create'
import PayoutCard from './components/PayoutCard'
import { PaySwitchEnum } from './types'

interface IProps {
  onChangeType: () => void
}
const PayoutManager: FC<IProps> = (props) => {
  const { onChangeType } = props
  const { params, urlQuery, uPubSub } = useContext(ParamsContext)
  const { hideTitle } = params
  const { token, paySwitch } = urlQuery

  const [createModalVisible, setCreateModalVisible] = useState(false)

  const {
    data: cardList,
    loading,
    refresh: refreshList,
  } = useRequest(() => api.getPayoutCardList({ token }).then((res) => res?.list), {
    auto: true,
  })
  useEffect(() => {
    const subId = uPubSub.subscribe(PUBSUB_TYPES.REFRESH_MANAGER_LIST, refreshList)

    return () => uPubSub.unsubscribe(subId)
  }, [refreshList, uPubSub])

  const openCreateModal = () => {
    setCreateModalVisible(true)
  }

  const closeCreateModal = (needRefreshList = false) => {
    needRefreshList && refreshList()
    setCreateModalVisible(false)
  }

  return (
    <div className="upay-payout-manager">
      <div className="upay-manager-header">
        {/* Title */}
        <div className="upay-manager-title">{hideTitle ? '' : starling('funds.refund.comm.managewithdraw_paymentmethods')}</div>

        {/* 切换按钮 */}
        {paySwitch === PaySwitchEnum.Show && (
          <div className="upay-manager-switch" onClick={() => onChangeType()}>
            <IconSwap />
            {starling('funds.refund.comm.manage_payment_mothod')}
          </div>
        )}
      </div>

      {/* 卡列表 */}
      <Skeleton loading={loading} animation text={{ rows: 5 }} style={{ height: 143, overflow: 'hidden' }}>
        <div className="upay-manager-card-list">
          {cardList?.map((card) => {
            return <PayoutCard key={card.id} card={card} refreshList={refreshList} />
          })}

          <div className="upay-manager-card-add" onClick={openCreateModal}>
            <IconPlus fontSize={18} />
            {starling('funds.refund.comm.add_withdraw_paymentmethods')}
          </div>
        </div>
      </Skeleton>

      <Create visible={createModalVisible} onClose={closeCreateModal} from={FROM_PAGE_ENUM.CREATE_IN_MANAGER} />
    </div>
  )
}

export default oneTimeHoc(PayoutManager)
