import { PaymentMethodItem } from '../../types'

export const KAKAOPAY_PAYMENT_METHOD: PaymentMethodItem = {
  paymentMethodType: {
    configuration: {},
    icon: {
      iconUrl: '',
    },
    starling_key: 'pipo_common_ewallet',
    paymentMethodType: 'E_WALLET',
    title: {
      payment: 'pipo_common_ewallet',
    },
  },
  paymentMethod: {
    configuration: {},
    paymentMethod: 'KAKAOPAY',
    paymentMethodId: 'pm_pi_ew_kakaopay_c_r',
    displayName: 'KAKAOPAY',
    starling_key: 'pipo_payin_payment_main_KakaoPay',
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/kakaopay_ebd3ca.svg',
      pngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/kakaopay_8df397.png',
      darkModePngIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/kakaopay_8df397.png',
      grayIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/kakaopay_ebd3ca.svg',
      detailIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/kakaopay-detail_9d5dba.svg',
    },
    title: {
      piListShort: 'pipo_payin_payment_main_KakaoPay',
      piListLong: 'pipo_payin_payment_main_KakaoPay',
    },
  },
}
