import { getCookie } from '@upay/utils/es/cookie'

export const DEFAULT_LANG = 'en'

/** 预加载时取的语言 */
export const getInitLang = (): string => {
  return getCookie('lang_type') || getCookie('lang') || DEFAULT_LANG
}

/** 运行时真正使用的语言，有可能与预加载的不一致 */
const runtimeLang = {
  lang: getInitLang(),
}

export const getLang = (): string => {
  return runtimeLang.lang
}
export const setLang = (lang: string) => {
  runtimeLang.lang = lang
}
