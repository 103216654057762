import React from 'react'
import { FieldError } from 'react-hook-form'

import { CustomInput, ItemBlock } from '../index'

interface IProps {
  title?: string
  placeholder?: string
  error?: FieldError
  errorMessage?: string
  value?: string
  countryOrRegion?: string
  countryCode?: string
  onChange: (val: string) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void
  phoneNumberStyle: 'half' | 'full'
  addBefore?: string | React.ReactNode
  maxLength?: number
}

export const PhoneNumber = React.forwardRef((props: IProps, ref: any) => {
  const {
    title,
    placeholder,
    error,
    errorMessage,
    value,
    countryOrRegion = '',
    countryCode = '',
    onChange,
    onBlur,
    phoneNumberStyle,
    addBefore,
    maxLength,
  } = props

  return (
    <ItemBlock style={phoneNumberStyle} title={title} errorState={Boolean(error)} errorMessage={errorMessage}>
      <CustomInput
        {...props}
        ref={ref}
        size="large"
        allowClear
        value={value}
        type="number"
        error={Boolean(error)}
        addBefore={addBefore || `${countryOrRegion} +${countryCode}`}
        placeholder={placeholder}
        maxLength={maxLength}
        onChange={(val) => onChange(val)}
        onBlur={onBlur}
      />
    </ItemBlock>
  )
})
