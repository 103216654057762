import { PaymentMethodItem, FetchDistrictFunc, PipoSubmitParams } from '@/pipo/utils'
import { IPaymentParamsMapItem } from '@/pipo/utils/types'
import { IGetContractAddressRes } from '@/services/types'
/**
 *  加载 DirectDebit 组件入参
 */
export interface DirectDebitProps {
  showSavingBox?: boolean
  contractAddressList: IGetContractAddressRes[] | undefined
  onFetchDistrict: FetchDistrictFunc
  onFetchMandate: (params: any) => Promise<any[]>
  onValidate?: (isValid: boolean) => void
  onSubmit?: (params: PipoSubmitParams) => Promise<void>
}
/**
 * DirectDebit 需要填写表单的位数
 */
export type DirectDebitFormData = {
  phone_country_code?: string // 电话区号
  account_type?: string
  holder_name?: string
  holder_first_name?: string
  holder_last_name?: string
  address_details?: string
  billing_city?: string
  billing_state?: string
  billing_country_region?: string
  billing_postal_code?: string
  mobile_phone?: string
  account_no?: string
  routing_no?: string
  bank_name?: string
  bank_address_details?: string
  bank_address_city?: string
  bank_address_state?: string
  bank_address_postal_code?: string
  payment_amount_type?: string
  payment_frequency_type?: string

  pipo_company_name?: string
  pipo_account_no?: string
  pipo_company_address?: string
  pipo_company_email?: string

  signature_name?: string
  authorized_signatory_full_name?: string
  authorized_signatory_title?: string

  contract_address?: string // 合同地址选择内部使用
}

export const DIRECT_DEBIT_B_D_PAYMENT_PARAMS_MAP: Record<string, IPaymentParamsMapItem> = {
  holder_name: { element: 'eg_pi_dd_achdirectdebit_b_d_local_ca_holder_name', is_encrypted: true },
  routing_no: { element: 'eg_pi_dd_achdirectdebit_b_d_local_ca_routing_no', is_encrypted: true },
  account_no: { element: 'eg_pi_dd_achdirectdebit_b_d_local_ca_account_no', is_encrypted: true },
}

export const DIRECT_DEBIT_C_D_PAYMENT_PARAMS_MAP: Record<string, IPaymentParamsMapItem> = {
  holder_name: { element: 'eg_pi_dd_achdirectdebit_c_d_local_ca_holder_name', is_encrypted: true },
  holder_first_name: { element: 'eg_pi_dd_achdirectdebit_c_d_local_ca_holder_first_name', is_encrypted: true },
  holder_last_name: { element: 'eg_pi_dd_achdirectdebit_c_d_local_ca_holder_last_name', is_encrypted: true },
  routing_no: { element: 'eg_pi_dd_achdirectdebit_c_d_local_ca_routing_no', is_encrypted: true },
  account_no: { element: 'eg_pi_dd_achdirectdebit_c_d_local_ca_account_no', is_encrypted: true },
}

/**
 * DirectDebit PaymentMethodType
 */
export const DIRECT_DEBIT_PAYMENT_METHOD: PaymentMethodItem = {
  paymentMethodType: {
    configuration: {},
    icon: {
      iconUrl: '',
    },
    paymentMethodType: 'ACH_DIRECT_DEBIT' as PaymentMethodItem['paymentMethodType']['paymentMethodType'],
    starling_key: 'pipo_common_payin_direct_debit',
  },
  paymentMethod: {
    configuration: {},
    paymentMethod: 'DIRECT_DEBIT',
    paymentMethodId: '' as PaymentMethodItem['paymentMethod']['paymentMethodId'],
    starling_key: 'pipo_common_payin_direct_debit',
    icon: {
      // TODO 待补充
      iconUrl: '',
      pngIconUrl: '',
      darkModePngIconUrl: '',
      grayIconUrl: '',
      detailIconUrl: '',
    },
    displayName: 'DirectDebit',
    title: {
      // TODO 待补充
      payment: '',
      piListShort: '',
      piListLong: '',
    },
  },
}

export const DIRECT_DEBIT_B_D_PAYMENT_METHOD: PaymentMethodItem = {
  ...DIRECT_DEBIT_PAYMENT_METHOD,
  paymentMethod: {
    ...DIRECT_DEBIT_PAYMENT_METHOD.paymentMethod,
    paymentMethodId: 'pm_pi_dd_achdirectdebit_b_d' as PaymentMethodItem['paymentMethod']['paymentMethodId'],
  },
}

export const DIRECT_DEBIT_C_D_PAYMENT_METHOD: PaymentMethodItem = {
  ...DIRECT_DEBIT_PAYMENT_METHOD,
  paymentMethod: {
    ...DIRECT_DEBIT_PAYMENT_METHOD.paymentMethod,
    paymentMethodId: 'pm_pi_dd_achdirectdebit_c_d' as PaymentMethodItem['paymentMethod']['paymentMethodId'],
  },
}

export const generateMandateInfo = (mandateInfo: any[], formData: DirectDebitFormData & Record<string, string>) => {
  return mandateInfo
    ?.map((item) => {
      const itemName: string = item.mandate_element_param_name
      let itemValue = item.attribute_object?.valid_value || formData[itemName]
      // if (itemName === 'address') {
      //   itemValue = [
      //     formData.billing_country_region,
      //     formData.billing_state,
      //     formData.billing_city,
      //     formData.billing_street,
      //     formData.billing_postal_code,
      //   ].join('|')
      // }
      return {
        mandate_element_param_name: itemName,
        mandate_element_param_value: itemValue,
      }
    })
    .filter((item) => !!item.mandate_element_param_value)
}
