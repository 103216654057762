import React, { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useState } from 'react'

import BankList from '@/components/BankList'
import api from '@/services'
import { IBankListItem } from '@/services/types'
import { ParamsContext } from '@/stores'

import { IPaymentMethodsProps, IPaymentsMethodRef } from '../../interface'

import './style.less'

/**
 * 使用类似BankTransfer的支付方式
 */
const NeedBindElements = forwardRef<IPaymentsMethodRef, IPaymentMethodsProps>((props, ref): React.ReactElement => {
  const { changeNewPaymentStatus, channel } = props
  // 基本参数部分相关变量
  const {
    urlQuery: { token },
  } = useContext(ParamsContext)

  const [bankTransList, setBankTransList] = useState<IBankListItem[]>([])
  const [bankTransLoading, setLoading] = useState(false)
  const [bankTransItem, setBankTransItem] = useState<IBankListItem | null>(null)

  useImperativeHandle(ref, () => ({
    // 提交处理逻辑
    processSubmitData: (submitParams) => {
      if (!channel.needBindElements) {
        return submitParams
      }
      if (bankTransItem) {
        submitParams.channelParameter = bankTransItem.value
        submitParams.bankCode = bankTransItem.value
      }
      return submitParams
    },
  }))

  const getBankTransferList = useCallback(async () => {
    setLoading(true)
    const res = await api.getBindElements({ token, payWay: channel.payWay })
    setBankTransList(res?.data?.elements?.[0]?.option_list || [])
    setLoading(false)
  }, [channel.payWay, token])

  // 当遇到需要加载银行的支付方式时加载
  useEffect(() => {
    if (channel.needBindElements) {
      getBankTransferList()
    }
  }, [channel.needBindElements, getBankTransferList])

  // 更新Pay提交验证状态
  useEffect(() => {
    if (!channel.needBindElements || bankTransItem) {
      // 已选择银行或无需选择银行时验证通过
      changeNewPaymentStatus(channel.payWay, { valid: true })
    } else {
      changeNewPaymentStatus(channel.payWay, { valid: false })
    }
  }, [bankTransItem, changeNewPaymentStatus, channel.needBindElements, channel.payWay])

  return channel.needBindElements ? (
    <div className="upay-banktrans-container">
      <BankList selectedKey={bankTransItem?.key} loading={bankTransLoading} list={bankTransList} onChange={setBankTransItem} />
    </div>
  ) : (
    <></>
  )
})

export default NeedBindElements
