import { PaymentMethodItem } from '../../types'

/**
 * ShopeePay 需要填写表单的位数
 */

export type ShopeePayFormData = {
  phone?: string
  phone_country_code?: string
}

export const SHOPEEPAY_PAYMENT_PARAMS_MAP: Record<string, string> = {
  phone: 'eg_pi_ew_shopeepay_c_r_local_id_phone',
  phone_country_code: 'eg_pi_ew_shopeepay_c_r_local_id_phone_country_code',
}

export const SHOPEEPAY_PAYMENT_METHOD: PaymentMethodItem = {
  paymentMethodType: {
    configuration: {},
    icon: {
      iconUrl: '',
    },
    starling_key: 'pipo_common_ewallet',
    paymentMethodType: 'E_WALLET',
    title: {
      payment: 'pipo_common_ewallet',
    },
  },
  paymentMethod: {
    configuration: {},
    paymentMethod: 'SHOPEEPAY',
    paymentMethodId: 'pm_pi_ew_shopeepay_c_r',
    displayName: 'ShopeePay',
    starling_key: '',
    icon: {
      iconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/shopeepay_light_3_16ac60.png',
      detailIconUrl: 'https://lf16-co.g-p-static.com/obj/pipo-sgcompliance/sky/shopeepay-circle_eab722.svg',
    },
    piListForm: true,
  },
}
