import React from 'react'

import { Checkbox, Tooltip } from '@arco-design/web-react'

import starling from '@/utils/starling'

interface IProps {
  disabled?: boolean
  title: string
  authorizeCard: boolean
  setAuthorizeCard: React.Dispatch<React.SetStateAction<boolean>>
  marginTopStyle?: string
}

export const AuthorizeBox = (props: IProps) => {
  const { title, authorizeCard, disabled, setAuthorizeCard, marginTopStyle } = props
  return (
    <div
      style={{
        display: 'flex',
        margin: '24px 0',
      }}
    >
      {/* TODO: padding? */}
      <Checkbox
        disabled={disabled}
        style={{ paddingLeft: 0 }}
        onChange={(value) => {
          setAuthorizeCard(value)
        }}
        checked={authorizeCard}
      />
      <Tooltip disabled={!disabled} content={starling('funds.checkout.comm.system_mandate_fillin')}>
        <div style={{ marginLeft: '8px', color: '#8A8A8A' }}>{title}</div>
      </Tooltip>
    </div>
  )
}
