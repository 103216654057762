import React, { Fragment, useMemo } from 'react'
import { Controller, FieldError } from 'react-hook-form'

import { LocationItem, PipoProvider } from '@/pipo/utils'

import { City } from '../city'
import { Country } from '../country'
import { Province } from '../province'

export interface ISelectRegionProps {
  control: any
  errors: { [key: string]: FieldError | undefined }
  onlyCurrentCountry?: boolean
  regionDisabled?: boolean
  nameOptions?: { region?: string; state?: string; city?: string }
  level: 1 | 2 | 3
  // from useAddressPC
  list: {
    label: any
    placeholder: any
    loading: boolean
    list: LocationItem[]
    disabled: boolean
  }[]
  selectedAddressArr: (LocationItem | undefined)[]
  countryOrRegion: string
}

export const SelectRegion = React.forwardRef((props: ISelectRegionProps, ref: any) => {
  const {
    list: originList,
    control,
    errors,
    selectedAddressArr,
    nameOptions = {},
    onlyCurrentCountry,
    countryOrRegion,
    regionDisabled = false,
    level,
  } = props
  const { region = 'billing_country_region', state = 'billing_state', city = 'billing_city' } = nameOptions

  // 一些支付方式需仅显示当前国家，默认选择已在useAddressPC hook中实现
  const list = useMemo(() => {
    if (onlyCurrentCountry) {
      originList[0].list = originList[0].list.filter((item) => item.code === countryOrRegion)
    }
    return originList
  }, [originList, onlyCurrentCountry, countryOrRegion])

  const { getErrorText } = PipoProvider.useContext()

  return (
    <Fragment>
      <Controller
        rules={{ required: true }}
        name={region}
        control={control}
        render={({ field }) => (
          <Country
            {...field}
            title={list[0].label}
            placeholder={list[0].placeholder}
            error={errors[region]}
            errorMessage={getErrorText(errors[region]?.type)}
            loading={list[0].loading}
            options={list[0].list}
            disabled={regionDisabled || list[0].disabled}
          />
        )}
      />
      {level >= 2 && (
        <Controller
          rules={{ required: true }}
          name={state}
          key={state}
          control={control}
          render={({ field }) => (
            <Province
              {...field}
              title={list[1].label}
              placeholder={list[1].placeholder}
              error={errors[state]}
              errorMessage={getErrorText(errors[state]?.type)}
              loading={list[1].loading}
              options={list[1].list}
              disabled={list[1].disabled}
            />
          )}
        />
      )}
      {level >= 3 && (
        <Controller
          rules={{ required: true }}
          name={city}
          key={city}
          control={control}
          render={({ field }) => (
            <City
              {...field}
              title={list[2].label}
              placeholder={list[2].placeholder}
              error={errors[city]}
              errorMessage={getErrorText(errors[city]?.type)}
              loading={list[2].loading}
              options={list[2].list}
              disabled={list[2].disabled}
              selectedAddressArr={selectedAddressArr}
            />
          )}
        />
      )}
    </Fragment>
  )
})
